import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import { BankList } from '@C/Utils';
import { authHeader } from '@H';

import { B2BtransferList } from './B2BTransferList';

class B2BTranfer extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      debit_bank_id: '',
      credit_bank_id: '',
      amount: 0,
      payment_method: 'IMPS',
      bank_reference_id: '',
      product_type: 'BankToBank',
      transaction_source: 'SELF',
      time_stamp: dayjs().format('YYYY-MM-DD'),
      updated: false,
      editMode: false,
      isSubmitting: false,
      reference_number: '',
    };
  }
  changeDate = (e) => {
    this.setState({ time_stamp: e.target.value });
  };

  handleDebitBankChange = (e) => {
    this.setState({ debit_bank_id: e?.value });
  };

  handleCreditBankChange = (e) => {
    this.setState({ credit_bank_id: e?.value });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({ isSubmitting: true });
    const formData = this.state;
    const headers = authHeader(1);
    axios
      .post(`${process.env.API_URL}/payment/bank-to-bank-transfer/`, formData, {
        headers,
      })
      .then(() => {
        toast.success('Transcation added successfully');
        window.scrollTo({
          behavior: 'smooth',
          top: this.myRef.current.offsetTop - 150,
        });
        this.setState({
          debit_bank_id: '',
          credit_bank_id: '',
          amount: 0,
          payment_method: 'IMPS',
          bank_reference_id: '',
          product_type: 'BankToBank',
          reference_number: '',
          transaction_source: 'SELF',
          time_stamp: dayjs().format('YYYY-MM-DD'),
          updated: !this.state.updated,
          isSubmitting: false,
        });
        this.validator.hideMessages();
        this.forceUpdate();
      })
      .catch((err) => {
        toast.error(err?.data?.message);
        this.setState({ isSubmitting: false });
      });
  };
  render() {
    return (
      <>
        <section className="widget full-width" ref={this.myRef}>
          <header>
            <h3> Bank to Bank transfer </h3>
          </header>
          <main className="sub-wrapper">
            <form className="inline">
              <div className="input-wrapper with-label ">
                <label> Date:</label>
                <input
                  type="date"
                  name="selectDate"
                  onChange={this.changeDate}
                  value={this.state.time_stamp}
                />
              </div>
              <div className="input-wrapper with-label ">
                <label>Amount debit from A/C: </label>
                <BankList
                  id="settlement_bank"
                  handleBankChange={this.handleDebitBankChange}
                  value={this.state.debit_bank_id}
                />
              </div>
              <div className="input-wrapper with-label ">
                <label> Amount credit to A/C: </label>
                <BankList
                  id="settlement_bank_2"
                  handleBankChange={this.handleCreditBankChange}
                  value={this.state.credit_bank_id}
                />
              </div>
              <div className="input-wrapper with-label ">
                <label> Amount: </label>
                <input
                  type="text"
                  value={this.state.amount}
                  name="amount"
                  onChange={this.handleChange}
                />
              </div>

              <div className="input-wrapper with-label ">
                <label> Notes </label>
                <input
                  type="text"
                  value={this.state.reference_number}
                  name="reference_number"
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-actions">
                <button
                  className={`submit ${
                    this.state.isSubmitting ? 'loading' : ''
                  }}`}
                  onClick={(e) => this.submitForm(e)}
                >
                  Submit
                </button>
              </div>
            </form>
          </main>
        </section>
        <B2BtransferList key={this.state.updated} />
      </>
    );
  }
}

export { B2BTranfer };
