import React, { useEffect, useState } from 'react';

import SMSList from './smsList';

const ActivateSMS = () => {
  const [sms, setSms] = useState({
    dealer_name: '',
    dealer_mobile: '',
    select_packages: '',
    amount: '',
    no_of_sms: '',
  });

  useEffect(() => {});

  const handleChange = () => {};

  const activateForm = () => {};

  const cancelForm = () => {};

  return (
    <>
      <section className="widget form-card full-width">
        <header>
          <h3> Activate SMS </h3>
        </header>
        <main>
          <form className="inline">
            <div className="input-wrapper with-label">
              <label>Dealer mobile no.</label>
              <input
                type="text"
                onChange={handleChange}
                value={''}
                name="dealer_mobile"
              />
            </div>
            <div className="input-wrapper with-label">
              <label>Dealer name</label>
              <input
                type="text"
                onChange={handleChange}
                value={''}
                name="dealer_name"
              />
            </div>
            <div className="input-wrapper with-label">
              <label>Select package</label>
              <input
                type="text"
                onChange={handleChange}
                value={''}
                name="select_packages"
              />
            </div>
            <div className="input-wrapper with-label">
              <label>No. of SMS </label>
              <input
                type="text"
                onChange={handleChange}
                value={''}
                name="no_of_sms"
              />
            </div>
            <div className="item-actions">
              <button className="submit" onClick={activateForm}>
                Activate
              </button>
              <button className="red-btn" onClick={cancelForm}>
                Cancel
              </button>
            </div>
          </form>
        </main>
      </section>
      <SMSList />
    </>
  );
};

export { ActivateSMS };
