import axios from 'axios';

import { authHeader } from '@H';

export default {
  getDealersList: (account_id) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/users/dealers-list/?account_id=${account_id}`,
    }),
};
