import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { UserBunk, UserList } from '@C/Admin/Users/RouteComponents';

const RouteSetup = () => {
  return (
    <Switch>
      <Route path="/admin/users/list" component={UserList} />
      <Route path="/admin/users/bunk/:id" component={UserBunk} />
      <Route path="/admin/users/*">
        <Redirect to={`/admin/users/list`} />
      </Route>
    </Switch>
  );
};

export { RouteSetup };
