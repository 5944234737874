import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  B2BTranfer,
  CashDeposit,
  POSLedger,
  WalletLedger,
  WithdrawalFromBunk,
} from './RouteComponents';

const RouteSetup = () => {
  return (
    <Switch>
      <Route path="/account/banking/cash-deposit" component={CashDeposit} />
      <Route path="/account/banking/b2b-transfer" component={B2BTranfer} />
      <Route path="/account/banking/pos-settlement" component={POSLedger} />
      <Route
        path="/account/banking/wallet-settlement"
        component={WalletLedger}
      />
      <Route
        path="/account/banking/withdrawal-from-bunk"
        component={WithdrawalFromBunk}
      />
      <Route path="/account/banking/*">
        <Redirect to={`/account/banking/cash-deposit`} />
      </Route>
    </Switch>
  );
};

export { RouteSetup };
