import React, { Component } from 'react';
import dayjs from 'dayjs';

import { TableGrid } from '@C/TableGrid';

const col = [
  {
    name: 'Financial year',
    key: '',
  },
  {
    name: 'File description',
    key: '',
  },
];

class BackupList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backupList: [],
      year: dayjs().format('YYYY'),
      loading: true,
      limit: 20,
      offset: 1,
      count: 0,
    };
    this.limitInput = React.createRef();
  }

  componentDidMount() {}

  handleDateChange = (e) => {
    this.setState({
      year: e.target.value,
    });
  };

  render() {
    const { backupList } = this.state;
    return (
      <>
        <section className="widget full-width  list-table">
          <header>
            <h3> All back up files </h3>
          </header>
          <main>
            <div className="table-filters">
              <div className="filter-wrapper">
                <div className="filter-group">
                  <label> Financial year:</label>
                  <input
                    className="cstm-date-input"
                    type="year"
                    value={this.state.year}
                    max={dayjs().format('YYYY')}
                    name="year"
                    onChange={this.handleDateChange}
                  />
                </div>
              </div>
            </div>
            <main className="content-wrapper">
              <TableGrid
                col={col}
                row={this.state.backupList}
                count={this.state.count}
                hideActions={true}
              />
            </main>
          </main>
        </section>
      </>
    );
  }
}

export { BackupList };
