import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { authService } from '@S';

export const AuthRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const currentUser = authService?.currentUserValue;
      if (!currentUser) {
        // not logged in so redirect to login page with the return url
        return (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        );
      }

      const role = currentUser?.data.user_info.role.role;
      const lTime = authService?.loginTime;
      const currrentDate = Date.now();
      const validity = authService?.currentLoginValidity || 0;

      if (currrentDate > lTime + validity * 1000) {
        alert('Your session expired. Please login again');
        authService.logout();
        window.location = '/';
      }

      // check if route is restricted by role
      if (roles && roles.indexOf(role) === -1) {
        // role not authorised so redirect to home page
        return <Redirect to={{ pathname: '/unauthorized' }} />;
      }

      // authorised so return component
      return <Component {...props} roles={roles} />;
    }}
  />
);
