import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import services from '@C/Settings/_utils/data';
import { authHeader } from '@H';

const ProfitMargin = () => {
  const [marginList, setMarginList] = useState([
    {
      product_uuid: '',
      product: '',
      margin_value: 0,
    },
  ]);

  const [updated, setUpdated] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validator = new SimpleReactValidator({
    autoForceUpdate: { forceUpdate: () => {} },
    element: (message) => <div className="input-error">{message}</div>,
  });

  useEffect(() => {
    getProfitMargin();
  }, []);

  const getProfitMargin = () => {
    services
      .getProfitMargin()
      .then((response) => {
        setMarginList(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (e, i) => {
    const updatedMarginList = [...marginList];
    updatedMarginList[i][e.target.name] = e.target.value;
    setMarginList(updatedMarginList);
  };

  const submitForm = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (!validator.allValid()) {
      validator.showMessages();
      validator.forceUpdate();
      return;
    }

    const tmp = marginList.map((item) => ({
      product_uuid: item.product_uuid,
      product: item.product,
      margin_value: item.margin_value,
    }));

    const formData = {
      margin_list: tmp,
    };

    setIsSubmitting(true);
    const headers = authHeader(1);
    axios
      .post(`${process.env.API_URL}/bunk/fuel-profit-margin/`, formData, {
        headers,
      })
      .then(() => {
        toast.success('Profit margin added successfully');
        setUpdated(!updated);
        setIsSubmitting(false);
        validator.hideMessages();
      })
      .catch((err) => {
        toast.error(err.response?.data?.message);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <section className="widget full-width form-table">
        <main>
          <div>
            <p> Margin setting for fuels </p>
          </div>
        </main>
        <main>
          <div className="form-content">
            <table>
              <thead>
                <tr>
                  <th>Fuel</th>
                  <th>Margin</th>
                </tr>
              </thead>
              <tbody>
                {marginList.map((item, i) => (
                  <tr key={i}>
                    <td>
                      <div className="input-wrapper">
                        <span> {item.product} </span>
                      </div>
                    </td>
                    <td>
                      <div className="input-wrapper">
                        <input
                          type="text"
                          value={item.margin_value}
                          name="margin_value"
                          onChange={(event) => handleChange(event, i)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="form-actions">
              <button
                className="submit"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Submit
              </button>
            </div>
          </div>
        </main>
      </section>
    </>
  );
};

export { ProfitMargin };
