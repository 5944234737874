import React from 'react';

import { Header, Navbar, NavPills } from '@C';
import ErrorBoundary from '@C/Error';
import { RouteSetup } from '@C/Purchases';

const routes = [
  {
    name: 'Vendors/Suppliers',
    path: '/account/purchases/vendors',
  },
  {
    name: 'Fuel Purchases',
    path: '/account/purchases/fuel',
  },
  {
    name: 'Lube / Products Purchases',
    path: '/account/purchases/lubes',
  },
  {
    name: 'Vendor Payments',
    path: '/account/purchases/vendor-payments',
  },
];

const Purchases = () => {
  return (
    <main>
      <div className="page-wrapper">
        <Header title="Lubes" />
        <Navbar />
        <div className="page-content">
          <section className="page-components">
            <div className="page-title">
              <h1>Purchases</h1>
            </div>
            <ErrorBoundary>
              <NavPills routes={routes} />
            </ErrorBoundary>
            <ErrorBoundary>
              <RouteSetup />
            </ErrorBoundary>
          </section>
        </div>
      </div>
    </main>
  );
};

export { Purchases };
