import React, { Component } from 'react';

import './styles.scoped.scss';

class FuelSummary extends Component {
  constructor() {
    super();
  }

  render() {
    const summary = this.props.summary;
    return (
      <>
        <section className=" widget dsr-table grid">
          <header></header>
          <main>
            <table className="dsr" data-name="Fuel Summary">
              <thead>
                <tr>
                  <th colSpan={3} className="alert-brand">
                    Fuel Summary
                  </th>
                </tr>
                <tr data-header={true}>
                  <th>Product</th>
                  <th>Quantity</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {/* {summary.map((val, key) => {
                  return (
                    <tr key={key}>
                      <td>{key + 1}</td>
                      <td>{val.name}</td>
                      <td> {val.cashier.first_name + val.cashier.last_name} </td>
                      <td>
                        {new Intl.NumberFormat('en-IN', {
                          style: 'currency',
                          currency: 'INR',
                        }).format(val.total_amount.toFixed(2) || 0)}
                      </td>
                    </tr>
                  );
                })}
                <tr className="summary-row">
                  <td colSpan="3">Total </td>
                  <td>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      summary.reduce((a, b) => a + (b['total_amount'] || 0), 0) ||
                      0,
                    )}
                  </td>
                </tr>*/}
              </tbody>
            </table>
          </main>
        </section>
      </>
    );
  }
}

export default FuelSummary;
