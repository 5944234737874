import React, { Component } from 'react';

import { TableGrid } from '@C/TableGrid';

const col = [
  {
    name: 'Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Customer name',
    key: '',
  },
  {
    name: 'Amount taken in cash',
    key: '',
  },
  {
    name: 'Transaction mode',
    key: '',
  },
  {
    name: 'Ref no.',
    key: '',
  },
  {
    name: 'Staff name',
    key: '',
  },
];

export default class WithdrawalList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      withdrawalList: [],
      offset: 1,
      count: 0,
      limit: 20,
    };
  }

  render() {
    const { withdrawalList } = this.state;

    return (
      <>
        <section className="widget full-width">
          <header>
            <h3> Withdrawal From Bunk List </h3>
          </header>
          <main className="content-wrapper">
            <TableGrid
              col={col}
              row={withdrawalList}
              count={this.state.count}
            />
          </main>
        </section>
      </>
    );
  }
}
