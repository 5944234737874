import React, { Component } from 'react';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { formatCurrency } from '@C/TableGrid';
import { Fields, Modes } from '@C/Banking/';
import services from '@C/CashiersOnDuty/_utils/data';
import { authHeader } from '@H';

class TempCredit extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      cashier_duty_id: this.props.match.params.duty,
      cashier_id: this.props.match.params.staff,
      cash_list: [
        {
          amount: '',
          customer_name: '',
        },
      ],
      tab: 1,
      list: [],
      isSubmitting: false,
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
  }

  componentDidMount() {
    this.getTempCredit();
  }

  handlePaymentSelect = (mode, i) => {
    const list = [...this.state.list];
    list[i].mode = mode;
    this.setState({ list });
  };

  handleChange = (e, i) => {
    const list = [...this.state.list];
    list[i].p_amount = e.target.value;
    this.setState({ list });
  };

  onDataChange = (e, i) => {
    const list = [...this.state.list];
    list[i].bank_id = e.bank_id;
    list[i].bank_reference_id = e.bank_reference_id;
    list[i].check_date = e.check_date;
    list[i].payment_method = e.payment_method;
    list[i].transaction_source = e.transaction_source;

    this.setState({ list });
  };

  confirmPay = (i) => {
    const list = [...this.state.list];
    if (
      list[i].p_amount <= 0 ||
      list[i].p_amount == '' ||
      list[i].p_amount == undefined ||
      isNaN(list[i].p_amount)
    ) {
      toast.error('Enter the amount more than 0');
      this.forceUpdate();
      return;
    }
    this.setState({ isSubmitting: true });
    const formData = {
      payment_list: [
        {
          amount: parseFloat(list[i].p_amount),
          bank_id: list[i].bank_id ? list[i].bank_id : null,
          payment_method: list[i].payment_method
            ? list[i].payment_method
            : 'OFFLINE',
          transaction_source: list[i].transaction_source
            ? list[i].transaction_source
            : 'CASH',
          product_type: 'Cashier',
          product_uuid: list[i].uuid,
        },
      ],
      staff_id: this.props.match.params.staff,
      cashier_duty_id: this.props.match.params.duty,
    };
    const headers = authHeader(1);
    axios
      .post(`${process.env.API_URL}/payment/`, formData, { headers })
      .then(() => {
        toast.success('Temp credit repayment successfully');
        list[i].mode = 'cash';
        list[i].p_amount = '';
        this.setState({ list, isSubmitting: false });
        this.getTempCredit();
        this.props.onDataChange();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        this.setState({ isSubmitting: false });
      });
  };

  getTempCredit = () => {
    services
      .getTempCredit(this.props.match.params.staff, this.state.cashier_duty_id)
      .then((response) => {
        this.setState({ list: response.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  toggleTab = (i) => {
    this.setState({ tab: i });
  };

  onInputChange = (e) => {
    const cash_list = [...this.state.cash_list];
    cash_list[0][e.target.name] = e.target.value;
    this.setState({ cash_list: cash_list });
  };

  submitForm = (event) => {
    event.preventDefault();

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState({ isSubmitting: true });
    const formData = {
      cashier_id: this.props.match.params.staff,
      cashier_duty_id: this.props.match.params.duty,
      time_stamp: this.props.match.params.date,
      cash_list: this.state.cash_list,
    };
    const headers = authHeader(1);
    axios
      .post(`${process.env.API_URL}/bunk/temp-credit/`, formData, {
        headers,
      })
      .then(() => {
        toast.success('Temp credit added successfully');
        window.scrollTo({
          behavior: 'smooth',
          top: this.myRef.current.offsetTop - 100,
        });
        this.getTempCredit();
        this.props.onDataChange();
        this.setState({
          isSubmitting: false,
          cash_list: [
            {
              amount: '',
              customer_name: '',
            },
          ],
        });
      })
      .catch((err) => {
        this.setState({ isSubmitting: false });
        toast.error(err.response.data.message);
      });
  };

  confirmDelete = (id) => {
    confirmAlert({
      title: 'Confirm transaction deletion',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteItem(id),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  deleteItem = (id) => {
    const headers = authHeader(1);
    axios
      .delete(`${process.env.API_URL}/bunk/temp-credit/?temp_id=${id}`, {
        headers,
      })
      .then(() => {
        toast.success('Transaction deleted successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        this.getTempCredit();
        this.props.onDataChange();
        this.props.match.params.updateGrandTotal(
          new Date().toISOString().split('T')[0],
        );
      });
  };

  render() {
    const { list, isSubmitting } = this.state;
    return (
      <>
        <section className="widget form-card" ref={this.myRef}>
          <header>
            <h3>Temp Credit</h3>
          </header>
          <main>
            <form className="inline three-col" onSubmit={this.submitForm}>
              <div className="input-wrapper with-label">
                <label>Customer name</label>
                {this.validator.message(
                  'customer_name',
                  this.state.cash_list[0].customer_name,
                  'required',
                )}
                <input
                  type="text"
                  name="customer_name"
                  onChange={this.onInputChange}
                  value={this.state.cash_list[0].customer_name}
                />
              </div>
              <div className="input-wrapper with-label">
                <label>Amount</label>
                {this.validator.message(
                  'customer_name',
                  this.state.cash_list[0].amount,
                  'required',
                )}
                <input
                  type="text"
                  name="amount"
                  onChange={this.onInputChange}
                  value={this.state.cash_list[0].amount}
                />
              </div>
              <div className="cta-wrapper">
                <button
                  className={`submit ${isSubmitting ? 'loading' : ''}`}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
            <div className="table-wrapper mt-20">
              <table>
                <thead>
                  <tr>
                    <th>
                      <span>Name</span>
                    </th>
                    <th>
                      <span>Total amt</span>
                    </th>
                    <th>
                      <span>Pending amt</span>
                    </th>
                    <th>
                      <span>Received amt</span>
                    </th>
                    <th>
                      <span>Payment mode</span>
                    </th>
                    <th>
                      <span>Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {list.map((item, i) => (
                    <tr
                      key={i}
                      className={item.pending_amount > 0 ? '' : 'd-none'}
                    >
                      <td>{item.customer_name}</td>
                      <td>{formatCurrency(item.amount)}</td>
                      <td>{formatCurrency(item.pending_amount)}</td>
                      <td>
                        <div className="input-wrapper">
                          <input
                            type="text"
                            name="amount"
                            value={item.p_amount}
                            onChange={() => this.handleChange(event, i)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper payment-wrapper">
                          <Modes
                            did={i}
                            handleModeChange={this.handlePaymentSelect}
                            value={item.mode || 'cash'}
                          />
                          <Fields
                            did={i}
                            method={item.mode}
                            onDataChange={this.onDataChange}
                          />
                        </div>
                      </td>
                      <td className="item-actions">
                        <button
                          className="actions __pay"
                          disabled={this.state.isSubmitting}
                          onClick={() => this.confirmPay(i)}
                        >
                          Pay
                        </button>
                        <button
                          className="actions __delete"
                          onClick={() => this.confirmDelete(item.uuid)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </main>
        </section>
      </>
    );
  }
}

export default TempCredit;
