import React, { Component } from 'react';

export class LubeProductSalesSummary extends Component {
  constructor() {
    super();
    this.state = {
      tab: 1,
    };
  }

  render() {
    const summary = this.props.summary;
    return (
      <>
        <section className="widget dsr-table">
          <header className="title-wrapper"></header>
          <main>
            <table className="dsr" data-name="Lube & Product sales summary">
              <thead>
                <tr>
                  <th colSpan={3} className="alert-brand">
                    Lube / Product sales summary
                  </th>
                </tr>
                <tr data-header={true}>
                  <th>Product</th>
                  <th>Quantity</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {summary.map((item, i) => (
                  <tr key={i}>
                    <td>{item.product}</td>
                    <td>
                     {item.total_sale_in_liters.toFixed(2)}
                    </td>
                    <td>
                      {new Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        currency: 'INR',
                      }).format(item.total_sale_value.toFixed(2) || 0)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </main>
        </section>
      </>
    );
  }
}
