/* eslint-disable camelcase */
import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import { ProductList } from '@C/Lubes/_utils/';
import services from '@C/Lubes/_utils/data';
import { TaxList, VendorList } from '@C/Utils';
import { authHeader } from '@H';

import ListPurchase from './ListPurchase';

class LubePurchases extends Component {
  constructor() {
    super();
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });

    this.myRef = React.createRef();
  }

  state = {
    invoice_number: '',
    time_stamp: dayjs().format('YYYY-MM-DD'),
    tcs_amount: '',
    paid_amount: '',
    notes: '',
    supplier: {
      uuid: '',
    },
    payment: {
      bank_uuid: '',
      payment_type: 'OFFLINE',
      source: 'CASH',
      check_date: '',
      check_number: '',
      mode: '',
    },
    lubes_list: [
      {
        lube_uuid: '',
        number_of_units: 0,
        purchased_price: 0,
        discount_type: 'amount',
        discount: 0,
        tax: [],
        taxName: '',
        total_amount: 0,
        taxMultiplier: 0,
        amount: 0,
      },
    ],
    updated: false,
    editMode: false,
    isSubmitting: false,
  };

  addRow = () => {
    this.setState((prevState) => ({
      lubes_list: [
        ...prevState.lubes_list,
        {
          lube_uuid: '',
          number_of_units: 0,
          purchased_price: 0,
          discount_type: 'amount',
          discount: 0,
          tax: [],
          taxMultiplier: 0,
          taxName: '',
          total_amount: 0,
          amount: 0,
        },
      ],
    }));
  };

  deleteRow = (i) => {
    this.setState({
      lubes_list: this.state.lubes_list.filter((s, sindex) => i !== sindex),
    });
  };

  handlePaymentSelect = (mode) => {
    const list = { ...this.state.payment };
    list.mode = mode;
    if (mode == 'cash') {
      list.payment_type = 'OFFLINE';
      list.source = 'CASH';
    }
    this.setState({ payment: list });
  };

  onDataChange = (e) => {
    const list = { ...this.state.payment };
    list.bank_uuid = e.bank_id;
    list.check_number = e.bank_reference_id;
    list.check_date = e.check_date;
    list.payment_type = e.payment_method;
    list.source = e.transaction_source;
    this.setState({ payment: list });
  };

  changeDate = (value) => {
    const d = new Date(value).toISOString();
    this.setState({ time_stamp: d, time: value });
  };

  handleInvoiceChange = (e) => {
    if (e.target.type == 'number') {
      this.setState({ [e.target.name]: parseFloat(e.target.value) });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  handleVendorChange = (value) => {
    const supplier = { ...this.state.supplier };
    supplier.uuid = value.value;
    this.setState({ supplier });
  };

  handleProductChange = (e, i) => {
    const list = [...this.state.lubes_list];
    list[i].lube_uuid = e.value;
    this.setState({ lubes_list: list }, function () {
      this.reCalculateAmount(i);
    });
  };

  handleTaxChange = (e, l, i, m) => {
    const list = [...this.state.lubes_list];
    list[i].tax = e;
    list[i].taxName = l;
    list[i].taxMultiplier = m;
    this.setState({ lubes_list: list }, function () {
      this.reCalculateAmount(i);
    });
  };

  onInputChange = (e, i) => {
    const list = [...this.state.lubes_list];
    list[i][e.target.name] = e.target.value;

    this.setState({ lubes_list: list }, function () {
      this.reCalculateAmount(i);
    });
  };

  editMode = (item) => {
    this.setState({ editMode: false });
    window.scrollTo({ behavior: 'smooth', top: this.myRef.current.offsetTop });
    services
      .getLubePurchaseItem(item.uuid)
      .then((response) => {
        const tmp = response.data.data;
        const tmpArray = [];
        const tmpLubesList = tmp.lubes_list;
        for (let i = 0; i < tmpLubesList.length; i++) {
          tmpArray.push({
            lube_uuid: tmpLubesList[i].lube.uuid,
            number_of_units: tmpLubesList[i].details.number_of_units,
            purchased_price: tmpLubesList[i].details.purchased_price,
            discount: tmpLubesList[i].details.discount,
            taxName: tmpLubesList[i].details.tax,
            discount_type: 'amount',
            tax: [],
            total_amount: tmpLubesList[i].details.after_tax,
            amount: tmpLubesList[i].details.amount,
          });
        }
        this.setState({
          uuid: item.uuid,
          lubes_list: tmpArray,
          time_stamp: dayjs(tmp.time_stamp).format('YYYY-MM-DD'),
          editMode: true,
          invoice_number: tmp.invoice_number,
          tcs_amount: tmp.tcs_amount,
          paid_amount: tmp.payment.amount,
          notes: tmp.notes,
          supplier: {
            uuid: tmp.supplier.uuid,
          },
          payment: {
            bank_uuid: tmp.payment?.bank?.uuid,
            payment_type: tmp.payment?.payment_method,
            source: tmp.payment?.source,
            check_date: tmp.payment?.check_date,
            check_number: tmp.payment?.bank_reference_id,
            mode: tmp.payment?.source.toLowerCase(),
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  cancelEdit = () => {
    this.setState({
      invoice_number: '',
      time_stamp: dayjs().format('YYYY-MM-DD'),
      tcs_amount: '',
      paid_amount: '',
      notes: '',
      supplier: {
        uuid: '',
      },
      payment: {
        bank_uuid: '',
        payment_type: 'OFFLINE',
        source: 'CASH',
        check_date: '',
        check_number: '',
        mode: '',
      },
      lubes_list: [
        {
          lube_uuid: '',
          number_of_units: 0,
          purchased_price: 0,
          discount_type: 'amount',
          discount: 0,
          tax: [],
          taxName: '',
          total_amount: 0,
          taxMultiplier: 0,
          amount: 0,
        },
      ],
      updated: false,
      editMode: false,
      isSubmitting: false,
    });
  };

  reCalculateAmount = (i) => {
    const list = [...this.state.lubes_list];
    const qty = list[i].number_of_units;
    const price = list[i].purchased_price;
    const discount = list[i].discount;
    const taxMultiplier = list[i].taxMultiplier;
    const t = parseFloat(qty) * parseFloat(price) - parseFloat(discount);

    const taxAmount = t * (taxMultiplier / 100);
    const amt = t + taxAmount;
    list[i].amount = t.toFixed(2);
    list[i].total_amount = parseFloat(amt.toFixed(2));
    this.setState({ lubes_list: list });
  };

  submitForm = () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState({ isSubmitting: true });
    const formData = this.state;
    const headers = authHeader(1);
    if (this.state.editMode) {
      axios
        .put(`${process.env.API_URL}/lubes/bunk/vendor/purchase/`, formData, {
          headers,
        })
        .then(() => {
          toast.success('Transaction updated successfully', {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          window.scrollTo({
            behavior: 'smooth',
            top: this.myRef.current.offsetTop - 150,
          });
          this.setState({
            invoice_number: '',
            time_stamp: dayjs().format('YYYY-MM-DD'),
            tcs_amount: 0,
            paid_amount: 0,
            notes: '',
            supplier: {
              uuid: '',
            },
            payment: {
              bank_uuid: '',
              payment_type: 'OFFLINE',
              source: 'CASH',
              check_date: '',
              check_number: '',
            },
            lubes_list: [
              {
                lube_uuid: '',
                number_of_units: 0,
                purchased_price: 0,
                discount_type: 'amount',
                discount: 0,
                tax: [],
                taxName: null,
                total_amount: 0,
                amount: '',
              },
            ],
            updated: !this.state.updated,
            isSubmitting: false,
          });
          this.validator.hideMessages();
        })
        .catch((error) => {
          toast.error(error.response.data.error, {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          this.setState({ isSubmitting: false });
        });
    } else {
      axios
        .post(`${process.env.API_URL}/lubes/bunk/vendor/purchase/`, formData, {
          headers,
        })
        .then(() => {
          toast.success('Lube purchase added successfully', {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.setState({
            invoice_number: '',
            time_stamp: dayjs().format('YYYY-MM-DD'),
            tcs_amount: 0,
            paid_amount: 0,
            notes: '',
            supplier: {
              uuid: '',
            },
            payment: {
              bank_uuid: '',
              payment_type: 'OFFLINE',
              source: 'CASH',
              check_date: '',
              check_number: '',
            },
            lubes_list: [
              {
                lube_uuid: '',
                number_of_units: 0,
                purchased_price: 0,
                discount_type: 'amount',
                discount: 0,
                tax: [],
                taxName: null,
                total_amount: 0,
                amount: '',
              },
            ],
            updated: !this.state.updated,
            isSubmitting: false,
          });
          this.validator.hideMessages();
        })
        .catch((error) => {
          toast.error(error.response.data.error, {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          this.setState({ isSubmitting: false });
        });
    }
  };

  render() {
    const lubesList = this.state.lubes_list;
    const invoiceData = this.state;
    const { editMode } = this.state;
    const total_lube_amount =
      lubesList.reduce((a, b) => a + (b['total_amount'] || 0), 0) || 0;
    return (
      <>
        <section className="widget full-width grid-form" ref={this.myRef}>
          <header>
            <h3>Invoice details</h3>
          </header>
          <main>
            <div className="form-content">
              <div className="input-wrapper">
                {this.validator.message(
                  'supplier',
                  invoiceData.supplier.uuid,
                  'required',
                )}
                <VendorList
                  onChange={this.handleVendorChange}
                  value={invoiceData.supplier.uuid}
                  disabled={editMode}
                />
              </div>
              <div className="input-wrapper">
                {this.validator.message(
                  'date',
                  invoiceData.time_stamp,
                  'required',
                )}
                <input
                  type="date"
                  value={invoiceData.time_stamp}
                  name="time_stamp"
                  onChange={this.handleInvoiceChange}
                  disabled={editMode}
                />
              </div>
              <div className="input-wrapper with-label">
                {this.validator.message(
                  'invoice_number',
                  invoiceData.invoice_number,
                  'required',
                )}
                <label>Invoice number</label>
                <input
                  type="text"
                  value={invoiceData.invoice_number}
                  name="invoice_number"
                  onChange={this.handleInvoiceChange}
                  disabled={editMode}
                />
              </div>
              {/* <div className="input-wrapper with-label">
                {this.validator.message(
                  'paid_amount',
                  invoiceData.paid_amount,
                  'required|numeric|min:0,num',
                )}
                <label>Amount paid</label>
                <input
                  type="text"
                  value={invoiceData.paid_amount}
                  name="paid_amount"
                  onChange={this.handleInvoiceChange}
                  disabled={editMode}
                />
              </div> */}
              {/* <div className="input-wrapper payment-wrapper">
                <Modes
                  handleModeChange={this.handlePaymentSelect}
                  value={invoiceData.payment.mode || 'cash'}
                  disabled={editMode}
                />
                <Fields
                  method={invoiceData.payment.mode}
                  onDataChange={this.onDataChange}
                  disabled={editMode}
                />
              </div> */}
              <div className="input-wrapper with-label">
                {this.validator.message(
                  'tcs_amount',
                  invoiceData.tcs_amount,
                  'required|numeric|min:0,num',
                )}
                <label>TCS amount</label>
                <input
                  type="text"
                  value={invoiceData.tcs_amount}
                  name="tcs_amount"
                  onChange={this.handleInvoiceChange}
                  disabled={editMode}
                />
              </div>
              <div className="input-wrapper --full">
                <textarea
                  value={invoiceData.notes}
                  name="notes"
                  onChange={this.handleInvoiceChange}
                  placeholder="Additional notes"
                  disabled={editMode}
                />
              </div>
            </div>
          </main>
        </section>
        <section className="widget full-width form-table">
          <header>
            <h3>Item Details</h3>
            <div className="actions">
              <button className="add-row" onClick={() => this.addRow()}>
                Add row
              </button>
            </div>
          </header>
          <main>
            <div className="form-content">
              <table>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th># of Units</th>
                    <th>Purchase Price</th>
                    <th>Discount</th>
                    <th>Amount Before Tax</th>
                    <th>Tax</th>
                    <th>Total Amount</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {lubesList.map((item, i) => (
                    <tr key={i}>
                      <td>
                        <div className="input-wrapper mw-250">
                          {this.validator.message(
                            'product',
                            lubesList[i].lube_uuid,
                            'required',
                          )}
                          <ProductList
                            did={i}
                            handleDropdownChange={this.handleProductChange}
                            value={lubesList[i].lube_uuid}
                            disabled={editMode}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper">
                          {this.validator.message(
                            'quantity',
                            lubesList[i].number_of_units,
                            'required|numeric|min:1,num',
                          )}
                          <input
                            type="text"
                            value={item.number_of_units}
                            placeholder="Quantity"
                            name="number_of_units"
                            min={0}
                            onChange={() => this.onInputChange(event, i)}
                            disabled={editMode}
                          />
                        </div>
                      </td>

                      <td>
                        <div className="input-wrapper">
                          {this.validator.message(
                            'purchased_price',
                            lubesList[i].purchased_price,
                            'required|numeric|min:0,num',
                          )}
                          <input
                            type="text"
                            value={item.purchased_price}
                            placeholder="Price"
                            name="purchased_price"
                            onChange={() => this.onInputChange(event, i)}
                            disabled={editMode}
                          />
                        </div>
                      </td>

                      <td>
                        <div className="input-wrapper">
                          {this.validator.message(
                            'discount',
                            item.discount,
                            'required|numeric',
                          )}
                          <input
                            type="text"
                            value={item.discount}
                            placeholder="Discount"
                            name="discount"
                            onChange={() => this.onInputChange(event, i)}
                            disabled={editMode}
                          />
                        </div>
                      </td>

                      <td>
                        <div className="input-wrapper">
                          <input
                            type="text"
                            disabled
                            value={item.amount}
                            placeholder="Total"
                          />
                        </div>
                      </td>

                      <td>
                        <div className="input-wrapper">
                          <TaxList
                            did={i}
                            onChange={this.handleTaxChange}
                            value={item.taxName}
                            disabled={editMode}
                          />
                        </div>
                      </td>

                      <td>
                        <div className="input-wrapper">
                          <input
                            type="text"
                            disabled
                            value={item.total_amount}
                            placeholder="Total"
                          />
                        </div>
                      </td>
                      <td>
                        {' '}
                        <div className="input-wrapper _actions">
                          {i > 0 ? (
                            <button
                              className="delete-row icon-only"
                              onClick={() => this.deleteRow(i)}
                            >
                              Delete row
                            </button>
                          ) : (
                            ''
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <ul className="table-summary">
                <li>
                  <label>Total purchase amount: </label>
                  <span>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(total_lube_amount)}
                  </span>
                </li>
                <li>
                  <label>TCS amount: </label>
                  <span>
                    {' '}
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(invoiceData.tcs_amount)}
                  </span>
                </li>
                <li>
                  <label>Grand total: </label>
                  <span>
                    {' '}
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(+total_lube_amount + +invoiceData.tcs_amount)}
                  </span>
                </li>
              </ul>
            </div>
            <div className="form-actions">
              <button
                className="submit"
                type="submit"
                disabled={this.state.isSubmitting}
                onClick={this.submitForm}
              >
                Submit
              </button>
              {editMode && (
                <button className="red-btn" onClick={this.cancelEdit}>
                  Cancel
                </button>
              )}
            </div>
          </main>
        </section>
        <ListPurchase key={this.state.updated} onEdit={this.editMode} />
      </>
    );
  }
}

export { LubePurchases };
