import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { ShiftsList } from '@C/Utils';

const AttendanceFields = (props) => {
  const salaryType = props.salary ? props.salary.salary_type.name : null;

  const handleData = (data) => {
    props.onDataChange(data);
  };

  if (salaryType === null || salaryType === '') {
    return (
      <>
        <div className="notification-alert notification-alert__error">
          Salary type not set for the selected staff
        </div>
      </>
    );
  }
  switch (salaryType) {
    case 'Fixed':
      return <MonthlyFields onDataChange={handleData} simpleValidator={props.simpleValidator} />;
    case 'Hourly':
      return <HourlyFields onDataChange={handleData} simpleValidator={props.simpleValidator}/>;
    default:
      return <ShiftFields onDataChange={handleData} simpleValidator={props.simpleValidator} />;
  }
};

const MonthlyFields = (props) => {
  const [attendance, setAttendance] = React.useState(null);
  const options = [
    {
      label: 'Full day',
      value: 'FULL',
    },
    {
      label: 'Half day',
      value: 'HALF',
    },
    {
      label: 'Absent',
      value: 'ABSENT',
    },
    {
      label: 'Paid leave',
      value: 'PAID LEAVE',
    },
  ];

  useEffect(() => {
    return () => {
      props.simpleValidator.current.purgeFields();
    };
  }, []);

  const handleChange = (selectedOption) => {
    setAttendance(selectedOption.value);
    props.onDataChange(selectedOption);
  };

  return (
    <>
      <div className="input-wrapper with-label">
        <label>Mark</label>
        {props.simpleValidator.current.message(`attendance`, attendance, 'required')}
        <Select
          isDisabled={props.disabled}
          onChange={handleChange}
          options={options}
          className="select-box-container"
          value={options.filter(({ value }) => value === attendance)}
        />
      </div>
    </>
  );
};

const HourlyFields = (props) => {
  const [hours, setHours] = React.useState(0);

  const handleChange = (e) => {
    setHours(e.target.value);
  };

  useEffect(() => {
    props.onDataChange(hours);
  }, [hours]);

  useEffect(() => {
    return () => {
      props.simpleValidator.current.purgeFields();
    };
  }, []);

  return (
    <>
      <div className="input-wrapper with-label">
        <label>Hours</label>
        {props.simpleValidator.current.message(`hours`, hours, 'required')}
        <input type="text" value={hours} onChange={handleChange} />
      </div>
    </>
  );
};

const ShiftFields = (props) => {
  const [state, setState] = useState([
    {
      uuid: '',
    },
  ]);

  useEffect(() => {
    props.onDataChange(state);
  }, [state]);

  useEffect(() => {
    return () => {
      props.simpleValidator.current.purgeFields();
    };
  }, []);

  const handleShiftChange = (e, i) => {
    const value = e?.value || null;
    const list = [...state];
    list[i].uuid = value;
    setState(list);
  };

  const deleteRow = (i) => {
    const list = [...state];
    list.splice(i, 1);
    setState(list);
  };

  return (
    <>
      {state.map((item, index) => (
        <div className="form-group" key={index}>
          <div className="input-wrapper with-label">
            <label>Shift</label>
            {props.simpleValidator.current.message(`item.uuid`, item.uuid, 'required')}
            <ShiftsList
              onChange={handleShiftChange}
              value={item.uuid}
              did={index}
            />
            {index > 0 && (
              <button className="red-btn" onClick={() => deleteRow(index)}>
                Delete
              </button>
            )}
          </div>
        </div>
      ))}
      {state.length < 4 && (
        <div className="add-more-btn">
          <button
            type="button"
            className="blue-btn"
            onClick={() =>
              setState((prevState) => [...prevState, { shift_id: '' }])
            }
          >
            Add shift
          </button>
        </div>
      )}
    </>
  );
};

export { AttendanceFields };