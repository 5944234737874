/* eslint-disable new-cap */
import React, { Component } from 'react';
import axios from 'axios';
import Geonames from 'geonames.js';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import { CountryList, OMCList } from '@C/Utils';
import { authHeader } from '@H';

const geonames = Geonames({
  username: 'hectonetworks',
  lan: 'en',
  encoding: 'JSON',
});

class DealerForm extends Component {
  constructor() {
    super();
    this.state = {
      showPassword: false,
      loading: false,
      isSubmitting: false,
      username: '',
      password: '',
      email: '',
      mobile: '',
      user_role: 101,
      first_name: '',
      last_name: '',
      subscription: {
        plan_code: 200,
        bunk: {
          ro_name: '',
          omc_uuid: '',
          address: {
            city: '',
            state: '',
            country: '',
            pincode: '',
            country_code: '',
          },
        },
      },
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
  }
  onTogglePasswd = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleBunkChange = (e) => {
    const subscription = { ...this.state.subscription };
    subscription['bunk'][e.target.name] = e.target.value;
    this.setState({ subscription });
  };

  handleAddressChange = (e) => {
    const subscription = { ...this.state.subscription };
    subscription['bunk']['address'][e.target.name] = e.target.value;
    subscription['bunk']['address']['country_code'] = '';
    this.setState({ subscription });
    if (e.target.name == 'pincode') {
      const postalcode = this.state.subscription.bunk.address.pincode;
      if (postalcode.length > 5) {
        this.setState({ loading: true });
        geonames
          .postalCodeSearch({ postalcode: `${postalcode}` })
          .then((response) => {
            if (response.postalCodes.length > 0) {
              subscription['bunk']['address'].city =
                response.postalCodes[0].adminName2;
              subscription['bunk']['address'].state =
                response.postalCodes[0].adminName1;
              subscription['bunk']['address'].country_code =
                response.postalCodes[0].countryCode;
              this.setState({ subscription, loading: false });
            } else {
              toast.error('Invalid Pincode');
              this.setState({ loading: false });
            }
          })
          .catch((error) => {
            this.setState({ loading: false });
            toast.error('Invalid Pincode');
          });
      }
    }
  };

  handleOMCChange = (e) => {
    const subscription = { ...this.state.subscription };
    subscription['bunk'].omc_uuid = e;
    this.setState({ subscription });
  };

  handleCountrySelect = (e) => {
    const subscription = { ...this.state.subscription };
    subscription['bunk']['address'].country = e?.value;
    this.setState({ subscription });
  };

  validateUser = (e) => {
    e.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState({ isSubmitting: true });
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/users/validate-dealer/?mobile_number=${this.state.mobile}&user_role=${'101'}`,
    })
      .then((response) => {
        if (response.data.data.is_status) {
          toast.error(response.data.message, {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          response.data.data.is_dealer_number
            ? this.confirmRegistration(e)
            : null;
        } else {
          this.submitData(e);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        this.setState({ isSubmitting: false });
      });
  };

  confirmRegistration = (e) => {
    confirmAlert({
      title: 'A bunk already exsists for this dealer',
      message:
        'The new bunk will be added to the existing dealer. Do you want to continue?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.submitData(e),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  submitData = (e) => {
    e.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState({ isSubmitting: true });
    const formData = this.state;
    const headers = authHeader();
    axios
      .post(`${process.env.API_URL}/users/register/`, formData, { headers })
      .then((response) => {
        if (response.status != 201) {
          toast.error(response.data.message, {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        } else {
          toast.success('Account created successfully');
          this.setState({
            username: '',
            password: '',
            email: '',
            mobile: '',
            user_role: 101,
            first_name: '',
            last_name: '',
            isSubmitting: false,
            subscription: {
              plan_code: 200,
              bunk: {
                ro_name: '',
                omc_uuid: '',
                address: {
                  city: '',
                  state: '',
                  country: '',
                  pincode: '',
                },
              },
            },
          });
          this.validator.hideMessages();
          this.forceUpdate();
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        this.setState({ isSubmitting: false });
      });
  };

  render() {
    const userData = this.state;

    return (
      <section className="widget full-width">
        <header>
          <h3>Create Dealer</h3>
        </header>
        <main className="sub-wrapper">
          <form className="inline">
            <div className="input-wrapper with-label">
              <label>First name</label>
              {this.validator.message(
                'first name',
                userData.first_name,
                'required',
              )}
              <input
                type="text"
                onChange={this.handleChange}
                value={userData.first_name}
                name="first_name"
              />
            </div>
            <div className="input-wrapper with-label">
              <label>Last name</label>
              <input
                type="text"
                onChange={this.handleChange}
                value={userData.last_name}
                name="last_name"
              />
            </div>
            <div className="input-wrapper with-label">
              <label>Bunk name</label>
              {this.validator.message(
                'bunk name',
                userData.subscription.bunk.ro_name,
                'required',
              )}
              <input
                type="text"
                onChange={this.handleBunkChange}
                value={userData.subscription.bunk.ro_name}
                name="ro_name"
              />
            </div>
            <div className="input-wrapper with-label">
              <label>OMC</label>
              {this.validator.message(
                'omc',
                userData.subscription.bunk.omc_uuid,
                'required',
              )}
              <OMCList
                handleOMCChange={this.handleOMCChange}
                value={userData.subscription.bunk.omc_uuid}
              />
            </div>
            <div className="input-wrapper with-label">
              <label>Mobile</label>
              {this.validator.message(
                'mobile',
                userData.mobile,
                'required|numeric|min:10,array|max:10,array',
              )}
              <input
                type="number"
                onChange={this.handleChange}
                value={userData.mobile}
                name="mobile"
              />
            </div>
            <div className="input-wrapper with-label">
              <label>Username</label>
              <input
                type="text"
                onChange={this.handleChange}
                value={userData.username}
                name="username"
              />
            </div>
            <div
              className={
                this.state.showPassword
                  ? 'input-wrapper with-label visible'
                  : 'input-wrapper with-label'
              }
            >
              <label>Password</label>
              {this.validator.message(
                'password',
                userData.password,
                'required',
              )}
              <input
                type={this.state.showPassword ? 'text' : 'password'}
                onChange={this.handleChange}
                value={userData.password}
                name="password"
              />
              <span
                className="show-psswd"
                onClick={() => this.onTogglePasswd()}
              />
            </div>
            <div className="input-wrapper with-label">
              <label>Email</label>
              <input
                type="email"
                onChange={this.handleChange}
                value={userData.email}
                name="email"
              />
            </div>
            <div
              className={
                this.state.loading
                  ? 'input-wrapper with-label loading'
                  : 'input-wrapper with-label'
              }
            >
              <label>Pincode</label>
              {this.validator.message(
                'pincode',
                userData.subscription.bunk.address.pincode,
                'required',
              )}
              <input
                type="text"
                onChange={this.handleAddressChange}
                value={userData.subscription.bunk.address.pincode}
                name="pincode"
                disabled={this.state.loading ? 'true' : ''}
              />
            </div>
            <div className="input-wrapper with-label">
              <label>City</label>
              {this.validator.message(
                'city',
                userData.subscription.bunk.address.city,
                'required',
              )}
              <input
                type="text"
                onChange={this.handleAddressChange}
                value={userData.subscription.bunk.address.city}
                name="city"
                disabled={this.state.loading ? 'true' : ''}
              />
            </div>
            <div className="input-wrapper with-label">
              <label>State</label>
              {this.validator.message(
                'state',
                userData.subscription.bunk.address.state,
                'required',
              )}
              <input
                type="text"
                onChange={this.handleAddressChange}
                value={userData.subscription.bunk.address.state}
                name="state"
                disabled={this.state.loading ? 'true' : ''}
              />
            </div>
            <div className="input-wrapper with-label">
              <label>Country</label>
              {this.validator.message(
                'country',
                userData.subscription.bunk.address.country,
                'required',
              )}
              <CountryList
                handleCountryChange={this.handleCountrySelect}
                value={userData.subscription.bunk.address.country}
                code={userData.subscription.bunk.address.country_code}
              />
            </div>
            <div className="form-actions">
              <button
                className="submit"
                disabled={this.state.isSubmitting}
                onClick={this.validateUser}
              >
                Register
              </button>
            </div>
          </form>
        </main>
      </section>
    );
  }
}

export { DealerForm };
