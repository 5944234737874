import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import services from '@C/CreditCustomers/_utils/data';
import { formatCurrency } from '@C/TableGrid';
import { StaffList } from '@C/Utils';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { authHeader } from '@H';

import AcceptedPurchaseOrdersList from './AcceptedPurchaseOrdersList';

const LivePurchaseOrders = (props) => {
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }),
  );
  const [list, setList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [updateKey, setUpdateKey] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    getIndentList();
  }, []);

  const getIndentList = () => {
    services
      .getIndentList('OPEN')
      .then((response) => {
        const tmp = response.data.data.results;
        // add one more field to the list
        tmp.forEach((item) => {
          item.cashier_id = null;
          item.update_notes = '';
        });
        setList(tmp);
        setLoading(false);
        setUpdateKey(!updateKey);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleStaffChange = (i, e) => {
    const tmp = [...list];
    tmp[i].cashier_id = e.value;
    setList(tmp);
  };

  const handleNotes = (e, i) => {
    const tmp = [...list];
    tmp[i].update_notes = e.target.value;
    setList(tmp);
  };

  const assignIndent = (i) => {
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    setIsSubmitting(true);
    const headers = authHeader(1);
    const tmp = [...list];
    const item = tmp[i];
    const formData = {
      order_id: item.uuid,
      cashier_id: item.cashier_id,
      time_stamp: dayjs().format('YYYY-MM-DD'),
    };

    axios({
      method: 'post',
      url: `${process.env.API_URL}/credit-customer/bunk/purchase-allocation/`,
      data: formData,
      headers: headers,
    })
      .then(() => {
        toast.success('Order assigned successfully');
        setIsSubmitting(false);
        getIndentList();
        simpleValidator.current.hideMessages();
      })
      .catch((error) => {
        setIsSubmitting(false);
        toast.error(error.response.data.message);
      });
  };

  const rejectIndent = (i) => {
    const headers = authHeader(1);
    const tmp = [...list];
    const item = tmp[i];
    const formData = {
      order_uuid: item.uuid,
      notes: item.update_notes,
    };

    axios({
      method: 'put',
      url: `${process.env.API_URL}/credit-customer/bunk/purchase-order-reject/`,
      data: formData,
      headers: headers,
    })
      .then(() => {
        toast.success('Order rejected successfully');
        setIsSubmitting(false);
        getIndentList();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setIsSubmitting(false);
      });
  };

  if (loading) {
    return <DropLoaderLarge />;
  } else {
    return (
      <>
        <section className="widget full-width list-table inverted">
          <header>
            <h3> Pending purchase orders </h3>
          </header>
          <main>
            <table>
              <thead>
                <tr>
                  <th>
                    <span> Date </span>
                  </th>
                  <th>
                    <span> Indent # </span>
                  </th>
                  <th>
                    <span>Customer</span>
                  </th>
                  <th>
                    <span>Quotation #</span>
                  </th>
                  <th>
                    <span>Vehicle #</span>
                  </th>
                  <th>
                    <span>Product</span>
                  </th>
                  <th>
                    <span>Value</span>
                  </th>
                  <th>
                    <span>Notes</span>
                  </th>
                  <th>
                    <span>Actions </span>
                  </th>
                  {/* <th>
                          <span>Comments</span>
                        </th> */}
                </tr>
              </thead>
              <tbody>
                {list.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <span>
                          {dayjs(item.time_stamp).format('DD/MM/YYYY')}
                        </span>
                      </td>
                      <td>
                        <span>{item.order_no}</span>
                      </td>
                      <td>
                        <span>{item.quotation.customer.name}</span>
                      </td>
                      <td>
                        <span>{item.quotation.quotation_no}</span>
                      </td>
                      <td>
                        <span>{item.vehicle.vehicle_number || '--'}</span>
                      </td>
                      <td>
                        <span>{item.product_obj.product_name}</span>
                      </td>
                      <td>
                        <span>{formatCurrency(item.amount)}</span>
                      </td>
                      <td>
                        <span>{item.notes}</span>
                      </td>
                      <td>
                        <div className="input-wrapper with-label with-btn">
                          <label>Staff</label>
                          {simpleValidator.current.message(
                            'cashier_id',
                            item.cashier_id,
                            'required',
                          )}
                          <StaffList
                            onChange={(e) => handleStaffChange(i, e)}
                            value={item.cashier_id}
                            staffType={['Cashiers', 'Managers']}
                          />
                          <button
                            className="green-btn"
                            onClick={() => assignIndent(i)}
                          >
                            Assign
                          </button>
                          <button
                            className={`green-btn ${isSubmitting ? 'loading' : ''}`}
                            onClick={() => {
                              assignIndent(i);
                            }}
                          >
                            Assign
                          </button>
                        </div>

                        <div className="input-wrapper with-label with-btn mt-20">
                          <label>Notes</label>
                          <input
                            type="text"
                            name="update_notes"
                            value={item.update_notes}
                            onChange={(e) => handleNotes(e, i)}
                          />
                          <button
                            className="red-btn "
                            onClick={() => rejectIndent(i)}
                          >
                            Reject
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </main>
        </section>
        <AcceptedPurchaseOrdersList
          key={updateKey}
          onUpdate={() => getIndentList()}
        />
      </>
    );
  }
};

export { LivePurchaseOrders };
