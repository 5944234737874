import React, { Component } from 'react';
import dayjs from 'dayjs';
import Geonames from 'geonames.js';
import { toast } from 'react-toastify';

import { CountryList, OMCList } from '@C/Utils';

const geonames = Geonames({
  username: 'hectonetworks',
  lan: 'en',
  encoding: 'JSON',
});

class UserBunk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dealer_name: '',
      username: '',
      mobile: '',
      subscription: {
        plan_code: 200,
        bunk: {
          ro_name: '',
          omc_uuid: '',
          address: {
            city: '',
            state: '',
            country: '',
            pincode: '',
          },
        },
      },
      tagged_executive_name: '',
      notes: '',
      time_stamp: dayjs().format('YYYY-MM-DD'),
      active_status: '',
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleBunkChange = (e) => {
    const subscription = { ...this.state.subscription };
    subscription['bunk'][e.target.name] = e.target.value;
    this.setState({ subscription });
  };

  handleAddressChange = (e) => {
    const subscription = { ...this.state.subscription };
    subscription['bunk']['address'][e.target.name] = e.target.value;
    this.setState({ subscription });
    if (e.target.name == 'pincode') {
      const postalcode = this.state.subscription.bunk.address.pincode;
      if (postalcode.length > 5) {
        this.setState({ loading: true });
        geonames
          .postalCodeSearch({ postalcode: `${postalcode}` })
          .then((response) => {
            if (response.postalCodes.length > 0) {
              subscription['bunk']['address'].city =
                response.postalCodes[0].adminName2;
              subscription['bunk']['address'].state =
                response.postalCodes[0].adminName1;
              this.setState({ subscription, loading: false });
            } else {
              toast.error('Invalid Pincode');
              this.setState({ loading: false });
            }
          })
          .catch((error) => {
            this.setState({ loading: false });
            toast.error('Invalid Pincode');
          });
      }
    }
  };

  handleOMCChange = (e) => {
    const subscription = { ...this.state.subscription };
    subscription['bunk'].omc_uuid = e;
    this.setState({ subscription });
  };

  handleCountrySelect = (e) => {
    const subscription = { ...this.state.subscription };
    subscription['bunk']['address'].country = e;
    this.setState({ subscription });
  };

  render() {
    return (
      <section className="widget full-width">
        <main className="sub-wrapper">
          <form className="inline">
            <div className="input-wrapper with-label">
              <label>Bunk name</label>
              <input
                type="text"
                onChange={this.handleBunkChange}
                value={this.state.subscription.bunk.ro_name}
                name="ro_name"
              />
            </div>

            <div className="input-wrapper with-label">
              <label> Dealer name </label>

              <input
                type="dealer_name"
                onChange={this.handleChange}
                value={this.state.dealer_name}
                name="dealer_name"
              />
            </div>
            <div className="input-wrapper with-label">
              <label>OMC</label>
              <OMCList handleOMCChange={this.handleOMCChange} />
            </div>
            <div className="input-wrapper with-label">
              <label>Username</label>
              <input
                type="text"
                onChange={this.handleChange}
                value={this.state.username}
                name="username"
              />
            </div>
            <div className="input-wrapper with-label">
              <label> Contact no.</label>
              <input
                type="text"
                onChange={this.handleChange}
                value={this.state.mobile}
                name="mobile"
              />
            </div>
            <div className="input-wrapper with-label">
              <label> Address</label>
              <textarea
                onChange={this.handleChange}
                value={this.state.address}
                name="address"
              />
            </div>
            <div
              className={
                this.state.loading
                  ? 'input-wrapper with-label loading'
                  : 'input-wrapper with-label'
              }
            >
              <label>Pincode</label>
              <input
                type="text"
                onChange={this.handleAddressChange}
                value={this.state.subscription.bunk.address.pincode}
                name="pincode"
                disabled={this.state.loading ? 'true' : ''}
              />
            </div>
            <div className="input-wrapper with-label">
              <label>City</label>
              <input
                type="text"
                onChange={this.handleAddressChange}
                value={this.state.subscription.bunk.address.city}
                name="city"
                disabled={this.state.loading ? 'true' : ''}
              />
            </div>
            <div className="input-wrapper with-label">
              <label>State</label>
              <input
                type="text"
                onChange={this.handleAddressChange}
                value={this.state.subscription.bunk.address.state}
                name="state"
                disabled={this.state.loading ? 'true' : ''}
              />
            </div>
            <div className="input-wrapper with-label">
              <label>Country</label>
              <CountryList
                handleCountryChange={this.handleCountrySelect}
                value={this.state.subscription.bunk.address.country}
              />
            </div>
            <div className="input-wrapper with-label">
              <label> Tagged to executive name </label>
              <input
                type="text"
                onChange={this.handleChange}
                value={this.state.tagged_executive_name}
                name="tagged_executive_name"
              />
            </div>
            <div className="input-wrapper with-label ">
              <label> Notes </label>
              <textarea
                value={this.state.notes}
                name="notes"
                onChange={this.handleChange}
              />
            </div>
            <div className="input-wrapper with-label">
              <label> Expiry date </label>
              <input
                className="cstm-date-input"
                type="date"
                value={this.state.time_stamp}
                onChange={this.handleDate}
              />
            </div>
            <div className="input-wrapper with-label">
              <label> Active status </label>
              <input
                type="text"
                value={this.state.active_status}
                onChange={this.handleChange}
                name="active_status"
              />
            </div>
            <div className="form-actions">
              <button className="submit" onClick={this.submitData}>
                Register
              </button>
              <button className="red-btn" onClick={this.cancelData}>
                {' '}
                Cancel{' '}
              </button>
            </div>
          </form>
        </main>
      </section>
    );
  }
}
export { UserBunk };
