import React, { Component } from 'react';
import { authService } from '@S';
let isSelectedArray = [];
let isSelectedMulti = [];

class GunsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.gunsList,
      isSelected: [],
      isSelectedMulti: [],
    };
  }

  componentDidMount() {
    this.clearSelection();
  }

  groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue,
      );
      return result;
    }, {});
  };

  onSelect = (event, id, name, tank, opening, status) => {
    if (
      (isSelectedArray.includes(id) && event.target.type === 'text') ||
      !status
    ) {
      return;
    }

    const isInputClicked = event.target.tagName.toLowerCase() === 'input';
    const targetValue = isInputClicked ? event.target.value : opening;

    if (isInputClicked) {
      addItemToSelected(id, name, tank, targetValue);
    } else {
      toggleSelected(id);
    }

    this.setState({ isSelectedMulti }, () => {
      this.props.onChange(this.state.isSelectedMulti);
    });

    function toggleSelected(id) {
      if (isSelectedArray.includes(id)) {
        removeItemFromSelected(id);
      } else {
        addItemToSelected(id, name, tank, targetValue);
      }
    }

    function removeItemFromSelected(id) {
      isSelectedArray.splice(isSelectedArray.indexOf(id), 1);
      isSelectedMulti = isSelectedMulti.filter((i) => i.gun_id !== id);
    }

    function addItemToSelected(id, name, tank, value) {
      isSelectedArray.push(id);
      isSelectedMulti.push({
        gun_id: id,
        tank_id: tank,
        name: name,
        opening_reading: parseFloat(value),
      });
    }
  };

  onReadingChange = (event, id) => {
    const objIndex = isSelectedMulti.findIndex((obj) => obj.gun_id == id);
    isSelectedMulti[objIndex].opening_reading = parseFloat(event.target.value);
    const list = [...this.state.data];
    if (event.target.value == '') {
      isSelectedMulti[objIndex].hasError = true;
      list.filter((item) => item.uuid == id)[0].hasError = true;
    } else {
      isSelectedMulti[objIndex].hasError = false;
      list.filter((item) => item.uuid == id)[0].hasError = false;
    }
    this.setState(
      { isSelectedMulti: isSelectedMulti, data: list },
      function () {
        this.props.onChange(this.state.isSelectedMulti);
      },
    );
  };

  handleInputFocus = (event, id, name, tank, opening, status) => {
    if (status) {
      this.onSelect(event, id, name, tank, opening, status);
    }
  };

  handleInputBlur = (event, id, name, tank, opening, status) => {
    if (status) {
      this.onSelect(event, id, name, tank, opening, status);
    }
  };

  clearSelection = () => {
    isSelectedArray = [];
    isSelectedMulti = [];
    this.setState({ isSelectedMulti: isSelectedMulti }, function () {
      this.props.onChange(this.state.isSelectedMulti);
    });
  };

  render() {
    const { data } = this.state;
    let r_array = this.groupBy(data, 'tank_name');
    r_array = Object.keys(r_array).map(function (key) {
      return { tank: key, guns: r_array[key] };
    });
    const role = authService.currentUserRole;
    return (
      <>
        <section className="widget" ref={this.props.cstmRef}>
          <header>
            <h3>Select Guns</h3>
          </header>
          <main>
            <div className="selectable-list-container">
              <div className="selectable-list-wrapper">
                {isSelectedArray.length > 0 && (
                  <div className="clear-btn">
                    <button
                      className="clr"
                      onClick={() => {
                        this.clearSelection();
                      }}
                    >
                      Clear Selection
                    </button>
                  </div>
                )}

                <ul className="parent-node">
                  {r_array.map((list, i) => {
                    return (
                      <li key={i}>
                        <div className="node-title">
                          <h4>{list.tank}</h4>
                          {!list.guns[0].price_updated && (
                            <div className="notification-alert notification-alert__error">
                              Fuel price not updated for the selected date
                            </div>
                          )}
                        </div>
                        <ul className="child-node">
                          {list.guns.map((item, i) => {
                            return (
                              <li
                                key={i}
                                className={
                                  isSelectedArray.includes(item.uuid)
                                    ? 'list-item selected'
                                    : !item.status
                                    ? 'list-item disabled'
                                    : 'list-item'
                                }
                                onClick={(e) =>
                                  this.onSelect(
                                    e,
                                    item.uuid,
                                    item.label,
                                    item.tank,
                                    item.opening,
                                    item.status,
                                  )
                                }
                              >
                                <span className="with-input">
                                  {item.label}{' '}
                                  <input
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.onSelect(
                                        e,
                                        item.uuid,
                                        item.label,
                                        item.tank,
                                        item.opening,
                                        item.status,
                                      );
                                    }}
                                    onFocus={(e) =>
                                      this.handleInputFocus(
                                        e,
                                        item.uuid,
                                        item.label,
                                        item.tank,
                                        item.opening,
                                        item.status,
                                      )
                                    }
                                    onBlur={(e) =>
                                      this.handleInputBlur(
                                        e,
                                        item.uuid,
                                        item.label,
                                        item.tank,
                                        item.opening,
                                        item.status,
                                      )
                                    }
                                    onChange={(e) =>
                                      this.onReadingChange(
                                        e,
                                        item.uuid,
                                        item.label,
                                        item.tank,
                                      )
                                    }
                                    type="text"
                                    defaultValue={item.opening}
                                    disabled={role !== 'Dealer' || !item.status}
                                    className={item.hasError ? 'has-error' : ''}
                                  />
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </main>
        </section>
      </>
    );
  }
}

export { GunsList };

