import React from 'react';

const PrivacyPolicy = () => {
  return (
    <>
      <section>
        <h1> Privacy Policy </h1>
        <div className="petrometerLogo"></div>
        <h6> Your privacy is very important to us. </h6>
        <p>
          &#8226; We collect your personal information, we will identify the
          purposes for which information is being collected.
        </p>
        <p>
          &#8226; We will collect and use of personal information solely with
          the objective of fulfilling those purposes specified by us and for
          other compatible purposes, unless we obtain the consent of the
          individual concerned or as required by law.
        </p>

        <p>
          &#8226; We will only retain personal information as long as necessary
          for the fulfillment of those purposes.
        </p>
        <p>
          &#8226; We will collect personal information by lawful and fair means
          and, where appropriate, with the knowledge or consent of the
          individual concerned.
        </p>
        <p>
          &#8226; Personal data should be relevant to the purposes for which it
          is to be used, and, to the extent necessary for those purposes, should
          be accurate, complete, and up-to-date.
        </p>
        <p>
          &#8226; We will protect personal information by reasonable security
          safeguards against loss or theft, as well as unauthorized access,
          disclosure, copying, use or modification.
        </p>
        <p>
          &#8226; We limit access to your personal information to our employees
          and contractors who have a legitimate need to use it.
        </p>
        <p>
          &#8226; If we share your information with other parties (like
          developers, service providers, domain registrars, and reselling
          partners), they must have appropriate security measures and a valid
          reason for using your information, typically to serve you.
        </p>
        <p>
          &#8226; We will make readily available to customers information about
          our policies and practices relating to the management of personal
          information.
        </p>
        <p>
          &#8226; We are committed to conducting our business in accordance with
          these principles in order to ensure that the confidentiality of
          personal information is protected and maintained.
        </p>
        <div className="hectoLogo"></div>
      </section>
    </>
  );
};

export { PrivacyPolicy };
