import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';

import { TableGrid } from '@C/TableGrid';

const col = [
  {
    name: 'Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Party name',
    key: '',
  },
  {
    name: 'Loan given',
    key: '',
  },
  {
    name: 'Loan received',
    key: '',
  },
  {
    name: 'Balance',
    key: '',
  },
  {
    name: 'Notes',
    key: '',
  },
];

const LoanLedger = () => {
  const [state, setState] = useState({
    loanList: [],
    start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    end_date: dayjs().format('YYYY-MM-DD'),
    party_name: '',
    limit: 20,
    offset: 1,
    count: 0,
    loading: true,
  });

  const limitInput = useRef();

  useEffect(() => {
    // Add your componentDidMount logic here
  }, []);

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  return (
    <>
      <section className="widget full-width list-table">
        <header>
          <h3>Loan Ledger </h3>
        </header>
        <main>
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group">
                <label>From date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={state.start_date}
                  name="start_date"
                  onChange={handleDateChange}
                />
              </div>
              <div className="filter-group">
                <label>To date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={state.end_date}
                  min={state.start_date}
                  onChange={handleDateChange}
                  name="end_date"
                />
              </div>
              <div className="filter-group">
                <label>Party name:</label>
                <input
                  type="text"
                  value={state.party_name}
                  name="party_name"
                  onChange={(e) =>
                    setState({ ...state, party_name: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <main className="content-wrapper">
            <TableGrid
              col={col}
              row={state.loanList}
              count={state.count}
              hideActions={true}
            />
          </main>
          <div className="mini-summary">
            <ul>
              <li>
                <span> Total outstanding balance: </span>
                <span>{/* Add the value here */}</span>
              </li>
            </ul>
          </div>
        </main>
      </section>
    </>
  );
};

export { LoanLedger };
