import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import { Fields, Modes } from '@C/Banking/';
import services from '@C/CashiersOnDuty/_utils/data';
import { CustomerList } from '@C/CreditCustomers/_utils';
import { PaymentMethodInfo } from '@C/Utils';
import { authHeader } from '@H';

import './styles.scoped.scss';

const CreditCollection = (props) => {
  const editRef = useRef(null);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [, forceUpdate] = useState();
  const [collectionList, setCollectionList] = useState([]);
  const [data, setData] = useState({
    payment_list: [
      {
        mode: '',
        bank_id: null,
        credit_collection: {
          amount: '',
          credit_customer_id: '',
        },
        payment_method: 'OFFLINE',
        transaction_source: 'CASH',
        product_type: 'CreditCustomer',
        product_uuid: null,
        bill: '',
      },
    ],
    staff_id: props.match.params.staff,
    cashier_duty_id: props.match.params.duty,
    updated: false,
  });

  const handleAmountChange = (e) => {
    setData({
      ...data,
      payment_list: [
        {
          ...data.payment_list[0],
          credit_collection: {
            ...data.payment_list[0].credit_collection,
            amount: +e.target.value,
          },
        },
      ],
    });
  };

  const handlePaymentSelect = (mode) => {
    setData({
      ...data,
      payment_list: [{ ...data.payment_list[0], payment_method: mode, mode }],
    });
    if (mode == 'cash') {
      setData({
        ...data,
        payment_list: [
          {
            ...data.payment_list[0],
            transaction_source: 'CASH',
            payment_method: 'OFFLINE',
            mode,
          },
        ],
      });
    }
  };

  const onDataChange = (e) => {
    setData({
      ...data,
      payment_list: [
        {
          ...data.payment_list[0],
          bank_id: e.bank_id,
          bank_reference_id: e.bank_reference_id,
          check_date: e.check_date,
          payment_method: e.payment_method,
          transaction_source: e.transaction_source,
        },
      ],
    });
  };

  const handleCustomerSelection = (e) => {
    setData({
      ...data,
      payment_list: [
        {
          ...data.payment_list[0],
          credit_collection: {
            ...data.payment_list[0].credit_collection,
            credit_customer_id: e.value,
          },
        },
      ],
    });
  };

  useEffect(() => {
    getCreditCollection();
  }, []);

  const getCreditCollection = () => {
    services
      .getCreditCollection(props.match.params.staff, props.match.params.duty)
      .then((response) => {
        const tmp = response.data.data.results;
        const collectionList = tmp;
        setCollectionList(tmp);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const confirmDelete = (id, duty, source) => {
    confirmAlert({
      title: 'Confirm Credit Collection List deletion',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteItem(id, duty, source),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  const deleteItem = (id, duty, source) => {
    const headers = authHeader(1);
    axios
      .delete(
        `${process.env.API_URL}/payment/?transaction_id=${id}&cashier_duty_id=${duty}&source=${source}`,
        {
          headers,
        },
      )
      .then(() => {
        toast.success('Credit collection deleted successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        props.onDataChange();
        getCreditCollection();
        props.onCreditCollectionUpdate();
      });
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    setIsSubmitting(true);
    const formData = data;
    const headers = authHeader(1);

    const url = `${process.env.API_URL}/payment/`;
    const method = 'POST';
    axios({
      method,
      url,
      data: formData,
      headers,
    })
      .then(() => {
        toast.success('Credit collection added successfully');
        window.scrollTo({
          behavior: 'smooth',
          top: editRef.current.offsetTop - 100,
        });
        props.onDataChange();
        simpleValidator.current.hideMessages();
        setIsSubmitting(false);
        setData({
          payment_list: [
            {
              mode: '',
              bank_id: null,
              credit_collection: {
                amount: '',
                credit_customer_id: '',
              },
              payment_method: 'OFFLINE',
              transaction_source: 'CASH',
              product_type: 'CreditCustomer',
              product_uuid: null,
              bill: '',
              notes: '',
            },
          ],
          staff_id: props.match.params.staff,
          cashier_duty_id: props.match.params.duty,
          updated: !data.updated,
        });
        getCreditCollection();
        props.onCreditCollectionUpdate();
      })
      .catch((err) => {
        toast.error(err.data.message);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <section className="widget form-card " ref={editRef}>
        <header>
          <h3> Credit Collection </h3>
        </header>
        <main>
          <div className="form-content form-contents">
            <div className="input-wrapper with-label">
              <label>Customer</label>
              {simpleValidator.current.message(
                'Customer Name',
                data.payment_list[0].credit_collection.credit_customer_id,
                'required',
              )}
              <CustomerList
                onCustomerChange={handleCustomerSelection}
                value={
                  data.payment_list[0].credit_collection.credit_customer_id
                }
              />
            </div>
            <div className="input-group">
              <div className="input-wrapper with-label">
                <label> Payment mode</label>
                <Modes
                  did={0}
                  handleModeChange={handlePaymentSelect}
                  value={data.payment_list[0].mode || 'cash'}
                />
              </div>
              <Fields
                did={0}
                method={data.payment_list[0].mode}
                onDataChange={onDataChange}
                bank={data.payment_list[0].bank}
                bank_reference_id={data.payment_list[0].bank_reference_id}
                check_date={data.payment_list[0].check_date}
              />
            </div>

            <div className="input-wrapper with-label">
              <label>Amount</label>
              {simpleValidator.current.message(
                'Customer Name',
                data.payment_list[0].credit_collection.amount,
                'required',
              )}
              <input
                type="text"
                name="amount"
                onChange={handleAmountChange}
                value={data.payment_list[0].credit_collection.amount}
              />
            </div>
            <div className="form-actions action">
              <button className="submit" onClick={submitForm}>
                Submit
              </button>
            </div>
          </div>
          <div className="table-wrapper mt-20 wt-20">
            <table>
              <thead>
                <tr>
                  <th>
                    <span>Customer name</span>
                  </th>
                  <th>
                    <span>Payment mode </span>
                  </th>
                  <th>
                    <span> Amount </span>
                  </th>
                  <th>
                    <span>Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {collectionList.map((item, i) => (
                  <tr key={i}>
                    <td>
                      {' '}
                      {item.payment_for.customer.first_name}{' '}
                      {item.payment_for.customer.last_name}{' '}
                    </td>
                    <td>
                      {' '}
                      <PaymentMethodInfo
                        source={item.source}
                        method={item.payment_method}
                        bank={item.bank}
                        wallet={item.other_wallet}
                      />
                    </td>
                    <td> {item.after_tax} </td>
                    <td className="item-actions">
                      <button
                        className="actions __delete"
                        onClick={() =>
                          confirmDelete(
                            item.uuid,
                            item.cashier_duty.cashier_duty_id,
                            item.source,
                          )
                        }
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </main>
      </section>
    </>
  );
};

export default CreditCollection;
