import React, { Component } from 'react';

export class DSRSalary extends Component {
  constructor() {
    super();
    this.state = {
      tab: 1,
    };
  }

  render() {
    const summary = this.props.summary;
    return (
      <>
        <section className="widget dsr-table">
          <header></header>
          <main className="content-wrapper">
            <table className="dsr" data-name="Salary">
              <thead>
                <tr>
                  <th colSpan={7} className="alert-brand">
                    {' '}
                    Staff Salary{' '}
                  </th>
                </tr>
                <tr data-header={true}>
                  <th>#</th>
                  <th> Staff </th>
                  <th> Type</th>
                  <th> Given by</th>
                  <th> Amount </th>
                </tr>
              </thead>
              <tbody>
                {summary.map((val, key) => {
                  return (
                    <tr key={key}>
                      <td>{key + 1}</td>
                      <td>{`${val.staff_first_name} ${val.staff_last_name}`}</td>
                      <td>{val.type}</td>
                      <td>{`${val.given_received_by_staff_first_name} ${val.given_received_by_staff_last_name}`}</td>
                      <td>{val.amount}</td>
                    </tr>
                  );
                })}
                <tr className="summary-row">
                  <td colSpan="4">Total </td>
                  <td>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      summary.reduce((a, item) => {
                        if (item.type === 'AdvanceRepayment') {
                          return a - (item.amount || 0);
                        }
                        return a + (item.amount || 0);
                      }, 0) || 0
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </main>
        </section>
      </>
    );
  }
}
