import React, { Component, useState } from 'react';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import { AccessControl } from '@C/Utils';
import { authHeader } from '@H';
import { authService } from '@S';

import BankAccountType from './BankAccountType';
import BankList from './BankList';
import services from './data';

const BankSetup = () => {
  const [updated, setUpdated] = useState(false);

  const updateList = () => {
    setUpdated(!updated);
  };
  return (
    <>
      <AccessControl allowedRoles={['Dealer']}>
        <BankSetupForm updateList={updateList} />
      </AccessControl>
      <ExsitingBankList key={updated} />
    </>
  );
};

class BankSetupForm extends Component {
  constructor() {
    super();
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
    this.myRef = React.createRef();
  }
  state = {
    bankDetails: [
      {
        index: Math.random(),
        bank_uuid: '',
        account_type_uuid: '',
        bank_name: '',
        account_type_name: '',
      },
    ],
    idx: 0,
    bank_uuid: '',
    bank_name: 'Select bank',
    account_type_uuid: '',
    account_type_name: 'Select account type',
    updated: false,
  };

  handleBankChange = (e, f) => {
    const bankDetails = [...this.state.bankDetails];
    bankDetails[f]['bank_uuid'] = e.value;
    bankDetails[f]['bank_name'] = e.label;
    this.setState({ bank_uuid: e.value, bank_name: e.label });
  };

  handleAccountTypeChange = (e, f) => {
    const bankDetails = [...this.state.bankDetails];
    bankDetails[f]['account_type_uuid'] = e.value;
    bankDetails[f]['account_type_name'] = e.label;
    this.setState({ account_type_uuid: e.value, account_type_name: e.label });
  };

  addItem = (e) => {
    window.scrollTo({
      behavior: 'smooth',
      top: this.myRef.current.offsetTop - 150,
    });
    e.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState((prevState) => ({
      bankDetails: [
        ...prevState.bankDetails,
        {
          index: Math.random(),
          bank_uuid: '',
          account_type_uuid: '',
          bank_name: '',
          account_type_name: '',
        },
      ],
      idx: this.state.idx + 1,
      bank_uuid: '',
      bank_name: 'Select bank',
      account_type_uuid: '',
      account_type_name: 'Select account type',
      updated: false,
    }));
    this.validator.hideMessages();
  };

  deleteItem = (index) => {
    this.setState({
      bankDetails: this.state.bankDetails.filter(
        (s, sindex) => index !== sindex,
      ),
      idx: this.state.idx - 1,
    });
  };

  submitForm = () => {
    const bankData = this.state.bankDetails;
    const formData = {
      banks_list: bankData.filter((item) => item.bank_uuid !== ''),
      associate_account: authService.currentAccountID,
    };
    const headers = authHeader(1);

    axios
      .post(`${process.env.API_URL}/bunk/map-bank/`, formData, { headers })
      .then(() => {
        toast.success('Banks added successfully');
        window.scrollTo({
          behavior: 'smooth',
          top: this.myRef.current.offsetTop - 150,
        });
        this.setState({
          bankDetails: [
            {
              index: Math.random(),
              bank_uuid: '',
              account_type_uuid: '',
              bank_name: '',
              account_type_name: '',
            },
          ],
          idx: 0,
          bank_uuid: '',
          bank_name: 'Select bank',
          account_type_uuid: '',
          account_type_name: 'Select account type',
          updated: !this.state.updated,
        });
        this.props.updateList();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        this.setState({
          bankDetails: [
            {
              index: Math.random(),
              bank_uuid: '',
              account_type_uuid: '',
              bank_name: '',
              account_type_name: '',
            },
          ],
          idx: 0,
          bank_uuid: '',
          bank_name: 'Select bank',
          account_type_uuid: '',
          account_type_name: 'Select account type',
          updated: !this.state.updated,
        });
      });
  };

  render() {
    const { bankDetails } = this.state;

    return (
      <>
        <section className="widget form-card" ref={this.myRef}>
          <header>
            <h3>Add Bank Details</h3>
          </header>
          <main>
            <form>
              <div className="input-wrapper">
                {this.validator.message(
                  'account',
                  this.state.bank_uuid,
                  'required',
                )}
                <BankList
                  did={this.state.idx}
                  id="product_uuid"
                  handleBankChange={this.handleBankChange}
                  value={this.state.bank_uuid}
                />
              </div>
              <div className="input-wrapper">
                {this.validator.message(
                  'account type',
                  this.state.account_type_uuid,
                  'required',
                )}
                <BankAccountType
                  did={this.state.idx}
                  id="product_uuid"
                  handleAccTypeChange={this.handleAccountTypeChange}
                  value={{
                    value: this.state.account_type_uuid,
                    label: this.state.account_type_name,
                  }}
                />
              </div>
              <div className="cta-wrapper">
                <button className="submit" onClick={this.addItem}>
                  Add
                </button>
              </div>
            </form>
          </main>
        </section>
        <section className="widget list-card list-card__minimal">
          <header>
            <h3>Account List</h3>
          </header>
          <main>
            <ul>
              {bankDetails.map((item, i) => {
                if (i < bankDetails.length - 1) {
                  return (
                    <li key={i}>
                      <div className="card-image">
                        <img src={item.logo} />
                      </div>
                      <div className="card-title">
                        <label>Bank:</label>
                        <h4>{item.bank_name}</h4>
                      </div>
                      <div className="card-info">
                        <span>
                          <label>Account type:</label>
                          {item.account_type_name}
                        </span>
                      </div>
                      <div className="card-actions">
                        <button
                          className="actions __delete "
                          onClick={() => this.deleteItem(i)}
                        >
                          Delete
                        </button>
                      </div>
                    </li>
                  );
                }
              })}
            </ul>
            {bankDetails.length > 1 && (
              <div className="cta-wrapper">
                <button
                  className="submit"
                  type="submit"
                  onClick={this.submitForm}
                >
                  Submit
                </button>
              </div>
            )}
          </main>
        </section>
      </>
    );
  }
}

class ExsitingBankList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bankList: [],
    };
  }
  componentDidMount() {
    this.getbankList();
  }

  getbankList = () => {
    services
      .getMappedBankList()
      .then((response) => {
        this.setState({ bankList: response.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  confirmDelete = (id, bankName) => {
    confirmAlert({
      title: 'Confirm bank deletion',
      message: `Are you sure you want to delete "${bankName}"?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteItem(id),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  deleteItem = (id) => {
    const headers = authHeader(1);
    axios
      .delete(`${process.env.API_URL}/bunk/map-bank/?bunk_bank_uuid=${id}`, {
        headers,
      })
      .then(() => {
        toast.success('Bank deleted successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        this.getbankList();
      });
  };

  render() {
    const { bankList } = this.state;
    return (
      <section className="widget list-card list-card__minimal">
        <header>
          <h3>Existing Banks</h3>
        </header>
        <main>
          <ul>
            {bankList.map((item, i) => {
              return (
                <li key={i}>
                  <div className="card-title">
                    <label>Bank:</label>
                    <h4>{item.mapped_bank.bank_name}</h4>
                  </div>
                  <div className="card-info">
                    <span>
                      <label>Account type:</label>
                      {item.account_type}
                    </span>
                  </div>
                  <div className="card-info">
                    <span>
                      <label> Name:</label>
                      {item.name}
                    </span>
                  </div>

                  <div className="card-actions">
                    <AccessControl allowedRoles={['Dealer']}>
                      <button
                        className="actions __delete "
                        onClick={() =>
                          this.confirmDelete(
                            item.bunk_bank_uuid,
                            item.mapped_bank.bank_name,
                          )
                        }
                      >
                        Delete
                      </button>
                    </AccessControl>
                  </div>
                </li>
              );
            })}
          </ul>
        </main>
      </section>
    );
  }
}
export { BankSetup };
