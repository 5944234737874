import React, { Component } from 'react';

import { TableGrid } from '@C/TableGrid';

const col = [
  {
    name: 'Inspection date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Ref. number',
    key: '',
  },
  {
    name: 'Notes',
    key: 'notes',
  },
];

export default class PhysicalStockList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stockList: [],
      offset: 1,
      count: 0,
      limit: 20,
    };
  }

  render() {
    const { stockList } = this.state;

    return (
      <>
        <section className="widget full-width">
          <header>
            <h3> Physical Stock List </h3>
          </header>
          <main className="content-wrapper">
            <TableGrid col={col} row={stockList} count={this.state.count} />
          </main>
        </section>
      </>
    );
  }
}
