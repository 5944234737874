import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import services from '@C/CashiersOnDuty/_utils/data';
import { authHeader } from '@H';

class OnDuty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: new Date(),
      staffs: [],
    };
  }

  componentDidMount() {
    this.getDuties();
  }

  getDuties = () => {
    services
      .getActiveDutyList(this.props)
      .then((response) => {
        this.setState({ staffs: response.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  endDuty = (id, staff, name, date) => {
    this.props.onEndDuty(id, staff, name, date);
  };

  confirmCancel = (id, duty) => {
    confirmAlert({
      title: 'Confirm duty cancellation',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.cancelDuty(id, duty),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
    // this.getDuties();
    // this.getStaffList();
  };

  cancelDuty = (id, duty) => {
    const formData = {
      staff_id: id,
      cashier_duty_id: duty,
    };
    // this.getDuties();

    const headers = authHeader(1);
    axios
      .put(`${process.env.API_URL}/bunk/cancel-cashier-duty/`, formData, {
        headers,
      })
      .then(() => {
        toast.success('Duty cancelled successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        window.location.reload();
        // window.location.reload();
        // this.getStaffList();
        // this.getGunsList();
      })
      .catch(() => {
        toast.error(response.data.message, {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    // this.getDuties();
    //   this.getStaffList();
    //   this.getGunsList();
  };

  render() {
    const { staffs } = this.state;

    return (
      <>
        <section className="widget full-width duty-list">
          <header>
            <h3>Existing Duties</h3>
          </header>
          <main>
            <ul>
              {staffs.map((item, i) => {
                return (
                  <li key={i}>
                    <div className="item-info">
                      <a href="#">{`${item.staff_details.first_name} ${item.staff_details.last_name}`}</a>
                      <span
                        title="Cashier is already on duty"
                        className="status --on-duty"
                      >
                        On duty
                      </span>
                      <span className="shift-status --on-duty">
                        {item.shift.shift}
                      </span>
                      <span
                        title={`Duty date - ${dayjs(item.duty_time_stamp).format('YYYY-MM-DD')}`}
                        className="shift-status --on-duty"
                      >
                        {new Date(item.duty_time_stamp).toDateString()}
                      </span>
                    </div>
                    <div className="cta-wrapper">
                      <Link
                        to={`/cashiers-on-duty/end-duty/${item.cashier_duty_id}/${dayjs(item.duty_time_stamp).format('YYYY-MM-DD')}/${item.uuid}/update/fuel-sales`}
                        className="end-cta"
                      >
                        End Duty
                      </Link>
                      <button
                        className="cancel-cta"
                        onClick={() =>
                          this.confirmCancel(item.uuid, item.cashier_duty_id)
                        }
                      >
                        Cancel Duty
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          </main>
        </section>
      </>
    );
  }
}

export { OnDuty };
