import axios from 'axios';

import { authHeader } from '@H';

export default {
  getMappedPOSList: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/map-swipe-machines/list/`,
    }),
};
