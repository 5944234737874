import React, { Component } from 'react';

class LubePurchase extends Component {
  constructor() {
    super();
    this.state = {
      list: [],
    };
  }

  render() {
    const summary = this.props.summary;
    return (
      <>
        <section className="widget dsr-table">
          <header></header>
          <main>
            <table className="dsr" data-name="Lube Purchase">
              <thead>
                <tr>
                  <th colSpan={3} className="alert-brand">
                    {' '}
                    Lube Purchase
                  </th>
                </tr>
                <tr data-header={true}>
                  <th> Category </th>
                  <th>Quantity </th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {summary.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td>{item.category}</td>
                      <td>{item.lube_purchase_total_quantity.toFixed(2)} </td>
                      <td>
                        {new Intl.NumberFormat('en-IN', {
                          style: 'currency',
                          currency: 'INR',
                        }).format(
                          item.lube_purchase_total_amount.toFixed(2) || 0,
                        )}
                      </td>
                    </tr>
                  );
                })}
                <tr className="summary-row">
                  <td colSpan="2">Total </td>
                  <td>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      summary.reduce(
                        (a, b) => a + (b['lube_purchase_total_amount'] || 0),
                        0,
                      ) || 0,
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </main>
        </section>
      </>
    );
  }
}

export default LubePurchase;
