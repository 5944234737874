import React, { Component, useEffect, useState } from 'react';
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';

import { ShiftsList } from '@C/Utils';
import services from '@C/Utils/data';

let salary = [];
class SalaryType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    salary = [];
    this.getSalaryType();
  }

  getSalaryType = () => {
    services
      .getSalaryType()
      .then((response) => {
        const tmp = response.data.data;

        for (let i = 0; i < tmp.length; i++) {
          salary.push({
            value: tmp[i].uuid,
            label: tmp[i].name,
          });
        }
        this.setState({ options: salary });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleChange = (selectedOption) => {
    this.props.handleModeChange(selectedOption);
  };

  render() {
    const options = this.state.options;
    return (
      <Select
        isDisabled={this.props.disabled}
        onChange={this.handleChange}
        options={options}
        className="select-box-container"
        defaultValue={{ label: '', value: '' }}
        value={options.filter(({ value }) => value === this.props.value)}
      />
    );
  }
}

class SalaryFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      method: this.props.method,
      data: this.props.data,
    };
  }

  handleData = (e) => {
    this.props.onDataChange(e);
  };

  componentDidMount() {}

  render() {
    const method = this.props.method;
    switch (method) {
      case 'Fixed':
        return (
          <MonthlyFixed {...this.props} handleMonthlyChange={this.handleData} />
        );
      case 'Shift Wise':
        return (
          <ShiftWise {...this.props} handleShiftChange={this.handleData} />
        );
      case 'Hourly':
        return <Hourly {...this.props} handleHourlyChange={this.handleData} />;
      default:
        return <></>;
    }
  }
}

class MonthlyFixed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: this.props.data.amount || 0,
      incentive: this.props.data.incentive || 0,
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, function () {
      this.props.handleMonthlyChange(this.state);
    });
  };

  render() {
    return (
      <div className="dependent-form-container">
        <div className="form-group">
          <div className="input-wrapper with-label">
            <label> Salary Per Month</label>
            <input
              name="amount"
              type="text"
              value={this.state.amount}
              onChange={this.handleChange}
              disabled={this.props.disabled}
            />
          </div>
        </div>
      </div>
    );
  }
}

const ShiftWise = (props) => {
  const [state, setState] = useState({
    shift_wise_list:
      props.data.shift_wise_list && props.data.shift_wise_list.length > 0
        ? props.data.shift_wise_list
        : [
            {
              shift_id: '',
              amount: 0,
              incentive: 0,
            },
          ],
  });

  useEffect(() => {
    props.handleShiftChange(state);
  }, [state.shift_wise_list]);

  const handleChange = (e, i) => {
    const { name, value } = e.target;
    const list = [...state.shift_wise_list];
    list[i][name] = +value;
    setState({ shift_wise_list: list });
  };

  const handleShiftChange = (e, i) => {
    const { value } = e;
    const list = [...state.shift_wise_list];
    list[i].shift_id = value;
    setState({ shift_wise_list: list });
  };

  const deleteRow = (i) => {
    const list = [...state.shift_wise_list];
    list.splice(i, 1);
    setState({ shift_wise_list: list });
  };

  return (
    <div className="dependent-form-container">
      {state.shift_wise_list.map((item, index) => (
        <div className="form-group" key={index}>
          <div className="input-wrapper with-label">
            <label>Shift</label>
            <ShiftsList
              onChange={handleShiftChange}
              value={item.shift_id}
              did={index}
              disabled={props.disabled}
            />
          </div>
          <div className="input-wrapper with-label">
            <label> Salary per shift</label>
            <input
              name="amount"
              type="text"
              value={item.amount}
              onChange={() => handleChange(event, index)}
              disabled={props.disabled}
            />
          </div>
          {index > 0 && !props.disabled && (
            <button className="red-btn" onClick={() => deleteRow(index)}>
              Delete shift
            </button>
          )}
        </div>
      ))}
      {!props.disabled && state.shift_wise_list.length < 4 && (
        <div className="add-more-btn">
          <button
            type="button"
            className="blue-btn"
            onClick={() =>
              setState({
                shift_wise_list: [
                  ...state.shift_wise_list,
                  {
                    shift_id: '',
                    amount: 0,
                    incentive: 0,
                  },
                ],
              })
            }
          >
            Add shift
          </button>
        </div>
      )}
    </div>
  );
};

class Hourly extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: this.props.data.amount || 0,
      incentive: this.props.data.incentive || 0,
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, function () {
      this.props.handleHourlyChange(this.state);
    });
  };

  render() {
    return (
      <div className="dependent-form-container">
        <div className="form-group">
          <div className="input-wrapper with-label">
            <label> Salary Per Hour</label>
            <input
              name="amount"
              type="text"
              value={this.state.amount}
              onChange={this.handleChange}
              disabled={this.props.disabled}
            />
          </div>
        </div>
      </div>
    );
  }
}

export { SalaryFields, SalaryType };
