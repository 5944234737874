import React, { Component } from 'react';
import dayjs from 'dayjs';
import ExcelJS from 'exceljs';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

import services from '@C/Reports/_utils/data';
import { formatCurrency, InterFont } from '@C/TableGrid';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { getBase64FromUrl } from '@C/Utils/ExportUtils';
import { authService } from '@S';

// import ExcelJS
// import FileSaver
import './styles.scoped.scss';

class PLTAReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter1: '',
      reportData: {},
      start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
      loading: true,
      data: null,
      exportingPdf: false,
      exportingExcel: false,
    };
  }

  componentDidMount() {
    this.getProfitLoss();
  }

  export2PDF = () => {
    // eslint-disable-next-line new-cap
    this.setState({ exportingPdf: true });
    const that = this;
    const doc = new jsPDF();
    const reportData = this.state.reportData;
    const start_date = this.state.start_date;
    const end_date = this.state.end_date;
    const expenseArr = [];
    const incomeArr = [];
    reportData.trading_data.expense_particulars.fuel_opening_stock.map(
      (item) => {
        expenseArr.push([
          `Opening stock - ${item.product}`,
          formatCurrency(item.stock),
        ]);
      },
    );

    reportData.trading_data.income_particulars.fuel_closing_stock.map(
      (item) => {
        incomeArr.push([
          `Closing stock - ${item.product}`,
          formatCurrency(item.stock),
        ]);
      },
    );

    const exportOptions = {
      fileName: `Proft & Loss - ${start_date} to ${end_date} Report.pdf`,
      reportName: 'Profit & Loss Report',
      reportDate: `Date - ${dayjs(start_date).format('D MMM YY')} to ${dayjs(end_date).format('D MMM YY')}`,
    };

    expenseArr.push([
      'Opening stock - Lubes',
      formatCurrency(
        reportData.trading_data.expense_particulars.lube_opening_stock,
      ),
    ]);
    expenseArr.push([
      'Total Fuel Purchase:',
      formatCurrency(reportData.trading_data.expense_particulars.fuel_purchase),
    ]);
    expenseArr.push([
      'Total Lubes Purchase:',
      formatCurrency(reportData.trading_data.expense_particulars.lube_purchase),
    ]);

    incomeArr.push([
      'Closing stock - Lubes',
      formatCurrency(
        reportData.trading_data.income_particulars.lube_closing_stock,
      ),
    ]);
    incomeArr.push([
      'Total Fuel Sales:',
      formatCurrency(reportData.trading_data.income_particulars.fuel_sale),
    ]);
    incomeArr.push([
      'Total Lubes Sales:',
      formatCurrency(reportData.trading_data.income_particulars.lube_sale),
    ]);

    const bunkLogo = getBase64FromUrl(authService.currentOMCWebLogo);
    const bunkAddress = authService.currentBunkAddress;

    bunkLogo.then((result) => {
      const img = new Image();
      img.onload = function () {
        doc.addFileToVFS('Inter-Regular.ttf', InterFont);
        doc.addFont('Inter-Regular.ttf', 'Inter', 'normal');
        doc.setFont('Inter');
        const aspectRatio = img.width / img.height;
        const width = 25;
        const height = width / aspectRatio;
        try {
          doc.addImage(result, 'PNG', 13, 10, width, height);
          autoTable(doc, {
            body: [
              [
                {
                  content: authService.currentBunkName,
                  styles: {
                    halign: 'right',
                    fontSize: 15,
                    textColor: '#000000',
                  },
                },
              ],
              [
                {
                  content:
                    bunkAddress.city +
                    ', ' +
                    bunkAddress.location +
                    ',' +
                    bunkAddress.country.country +
                    ' - ' +
                    bunkAddress.pincode,
                  styles: {
                    halign: 'right',
                    fontSize: 12,
                    textColor: '#000000',
                  },
                },
              ],
            ],
            theme: 'plain',
          });
          autoTable(doc, {
            body: [
              [
                {
                  content: 'Profit & Loss Report',
                  styles: {
                    halign: 'left',
                    fontSize: 15,
                    textColor: '#000000',
                    fontStyle: 'bold',
                  },
                },
                {
                  content: `${dayjs(start_date).format('D MMM YY')} to ${dayjs(end_date).format('D MMM YY')}`,
                  styles: {
                    halign: 'right',
                    fontSize: 12,
                    textColor: '#000000',
                    fontStyle: 'bold',
                  },
                },
              ],
            ],
            theme: 'plain',
          });
          doc.setFontSize(12);
          autoTable(doc, {
            head: [['Particulars', 'Expense']],
            body: expenseArr,
            theme: 'striped',
            startY: 55,
            headStyles: {
              fillColor: '#000000',
            },
            styles: {
              font: 'Inter',
              fontStyle: 'normal',
              fontSize: 11,
            },
            margin: { right: 105 },
          });
          autoTable(doc, {
            head: [['Particulars', 'Income']],
            body: incomeArr,
            theme: 'striped',
            startY: 55,
            headStyles: {
              fillColor: '#000000',
            },
            styles: {
              font: 'Inter',
              fontStyle: 'normal',
              fontSize: 11,
            },
            margin: { left: 105 },
          });
          autoTable(doc, {
            body: [
              [
                {
                  content: `Total Purchase & Opening Stock: ${formatCurrency(reportData.trading_data.Expense)}`,
                  styles: {
                    halign: 'left',
                    fontSize: 11,
                    textColor: '#ffffff',
                    font: 'Inter',
                  },
                },

                {
                  content: `Total Sales & Closing Stock: ${formatCurrency(reportData.trading_data.income)}`,
                  styles: {
                    halign: 'left',
                    fontSize: 11,
                    textColor: '#ffffff',
                    font: 'Inter',
                  },
                },
              ],
            ],
            theme: 'plain',
            styles: {
              fillColor: '#000000',
            },
          });
          autoTable(doc, {
            body: [
              [
                {
                  content: `Gross profit/loss:`,
                  styles: {
                    halign: 'left',
                    fontSize: 11,
                    textColor: '#ffffff',
                    fontStyle: 'bold',
                    font: 'Inter',
                  },
                },

                {
                  content: `${formatCurrency(reportData.trading_data.income - reportData.trading_data.Expense)}`,
                  styles: {
                    halign: 'right',
                    fontSize: 11,
                    textColor: '#ffffff',
                    fontStyle: 'bold',
                    font: 'Inter',
                  },
                },
              ],
            ],
            theme: 'plain',
            styles: {
              fillColor: `${reportData.trading_data.income - reportData.trading_data.Expense > 0 ? '#00b300' : '#ff0000'}`,
            },
          });
          doc.save(exportOptions.fileName);
          that.setState({ exportingPdf: false });
        } catch (e) {
          console.log(e);
        }
      };
      img.src = result;
    });
  };

  export2Excel = () => {
    this.setState({ exportingExcel: true });
    const that = this;
    // use exceljs to export data to excel
    const reportData = this.state.reportData;
    const start_date = this.state.start_date;
    const end_date = this.state.end_date;
    const expenseArr = [];
    const incomeArr = [];
    reportData.trading_data.expense_particulars.fuel_opening_stock.map(
      (item) => {
        expenseArr.push([
          `Opening stock - ${item.product}`,
          formatCurrency(item.stock),
        ]);
      },
    );

    reportData.trading_data.income_particulars.fuel_closing_stock.map(
      (item) => {
        incomeArr.push([
          `Closing stock - ${item.product}`,
          formatCurrency(item.stock),
        ]);
      },
    );

    expenseArr.push([
      'Opening stock - Lubes',
      formatCurrency(
        reportData.trading_data.expense_particulars.lube_opening_stock,
      ),
    ]);
    expenseArr.push([
      'Total Fuel Purchase:',
      formatCurrency(reportData.trading_data.expense_particulars.fuel_purchase),
    ]);
    expenseArr.push([
      'Total Lubes Purchase:',
      formatCurrency(reportData.trading_data.expense_particulars.lube_purchase),
    ]);
    incomeArr.push([
      'Closing stock - Lubes',
      formatCurrency(
        reportData.trading_data.income_particulars.lube_closing_stock,
      ),
    ]);
    incomeArr.push([
      'Total Fuel Sales:',
      formatCurrency(reportData.trading_data.income_particulars.fuel_sale),
    ]);
    incomeArr.push([
      'Total Lubes Sales:',
      formatCurrency(reportData.trading_data.income_particulars.lube_sale),
    ]);
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('P&L - Trading Account');
      worksheet.getRow(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '000000' },
      };
      worksheet.getRow(1).font = {
        color: { argb: 'ffffff' },
      };
      worksheet.columns = [
        { header: 'Particulars', key: 'particulars', width: 30 },
        { header: 'Expense', key: 'expense', width: 30 },
        { header: 'Particulars', key: 'particulars', width: 30 },
        { header: 'Income', key: 'income', width: 30 },
      ];

      // set expense data in first 2 columns
      expenseArr.map((item, index) => {
        worksheet.addRow([item[0], item[1], '', '']);
      });

      // set income data in last 2 columns of the same row
      incomeArr.map((item, index) => {
        worksheet.getRow(index + 2).getCell(3).value = item[0];
        worksheet.getRow(index + 2).getCell(4).value = item[1];
      });

      // set total purchase and sales
      worksheet.addRow([
        'Total Purchase & Opening Stock:',
        formatCurrency(reportData.trading_data.Expense),
        'Total Sales & Closing Stock:',
        formatCurrency(reportData.trading_data.income),
      ]);
      worksheet.addRow([
        'Gross profit/loss:',
        formatCurrency(
          reportData.trading_data.income - reportData.trading_data.Expense,
        ),
        '',
        '',
      ]);

      const rows = worksheet.getColumn(1);
      const rowsCount = rows['_worksheet']['_rows'].length;
      worksheet.getRow(rowsCount - 1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '000000' },
      };
      worksheet.getRow(rowsCount - 1).font = {
        color: { argb: 'ffffff' },
      };

      // set gross profit color
      if (
        reportData.trading_data.income - reportData.trading_data.Expense >
        0
      ) {
        worksheet.getRow(rowsCount).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '00b300' },
        };
      } else {
        worksheet.getRow(rowsCount).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'ff0000' },
        };
      }

      worksheet.getRow(rowsCount).font = {
        color: { argb: 'ffffff' },
      };

      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `Profit & Loss Report - trading account - ${start_date} to ${end_date}.xlsx`,
        );
        that.setState({ exportingExcel: false });
      });
    } catch (e) {
      console.log(e);
    }
  };

  getProfitLoss = () => {
    services
      .getPLTAReport(this.state)
      .then((response) => {
        this.setState({
          reportData: response.data.data,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  changeFilter = (e) => {
    this.setState({ filter1: e });
  };

  handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date' && dayjs(value).isAfter(this.state.end_date)) {
      this.setState({ [name]: value, end_date: value });
    } else if (
      name === 'end_date' &&
      dayjs(value).isBefore(this.state.start_date)
    ) {
      this.setState({ [name]: value, start_date: value });
    } else {
      this.setState({ [name]: value });
    }
  };

  resetFilter = () => {
    this.setState(
      {
        start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
        loading: true,
      },
      function () {
        this.getProfitLoss();
      },
    );
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, function () {
      this.getProfitLoss();
    });
  };
  render() {
    const { loading, reportData } = this.state;
    return (
      <section className="widget full-width list-table inverted">
        <header>
          <h3>Profit & Loss Trading Account</h3>
        </header>
        <main>
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group">
                <label>From date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.start_date}
                  name="start_date"
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="filter-group">
                <label>To date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.end_date}
                  min={this.state.start_date}
                  onChange={this.handleDateChange}
                  name="end_date"
                />
              </div>
              <div className="filter-group reset-filter">
                <button onClick={this.resetFilter}>Reset filters</button>
              </div>
              <div className="filter-group">
                  <button
                    className="submit"
                    type="submit"
                    onClick={this.submitForm}
                  >
                    Apply filter
                  </button>
                </div>
            </div>
          </div>
          <div className="tg-wrapper">
            {(() => {
              if (this.state.loading) {
                return <DropLoaderLarge />;
              } else {
                return (
                  <>
                    <div className="export-btns">
                      <button
                        onClick={this.export2Excel}
                        className={`blue-btn btn-with-icon btn-with-icon__excel ${this.state.exportingExcel ? 'loading' : ''}`}
                      >
                        Export to Excel
                      </button>
                      <button
                        onClick={this.export2PDF}
                        className={`blue-btn btn-with-icon btn-with-icon__pdf ${this.state.exportingPdf ? 'loading' : ''}`}
                      >
                        Export to PDF
                      </button>
                    </div>
                    <div className="table-wrapper">
                      <table>
                        <thead>
                          <tr
                            className="tg-header"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <th className="th-cell">
                              <span>Particulars</span>
                            </th>
                            <th className="th-cell">
                              <span>Expense</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {reportData.trading_data.expense_particulars.fuel_opening_stock.map(
                            (item, i) => (
                              <tr
                                key={i}
                                className="tg-body"
                                style={{ borderBottom: '0px solid white' }}
                              >
                                <td className="tg-cell">
                                  <span>{`Opening Stock - ${item.product || ''}`}</span>
                                </td>
                                <td className="tg-cell">
                                  <span>{`${formatCurrency(item.stock || 0)}`}</span>
                                </td>
                              </tr>
                            ),
                          )}
                          <tr className="tg-body">
                            <td className="tg-cell">
                              <span>{`Opening Stock - Lubes:`}</span>
                            </td>
                            <td className="tg-cell">
                              <span>{`${formatCurrency(reportData.trading_data.expense_particulars.lube_opening_stock)}`}</span>
                            </td>
                          </tr>
                          <tr className="tg-body">
                            <td className="tg-cell">
                              <span>{`Total Fuel Purchase:`}</span>
                            </td>
                            <td className="tg-cell">
                              <span>{`${formatCurrency(reportData.trading_data.expense_particulars.fuel_purchase)}`}</span>
                            </td>
                          </tr>
                          <tr className="tg-body">
                            <td className="tg-cell">
                              <span>{`Total Lubes Purchase:`}</span>
                            </td>
                            <td className="tg-cell">
                              <span>{`${formatCurrency(reportData.trading_data.expense_particulars.lube_purchase)}`}</span>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr className="tg-footer">
                            <th className="tg-cell">
                              <span>{`Total Purchase & Opening Stock`}</span>
                            </th>
                            <th className="tg-cell">
                              <span>{`${formatCurrency(reportData.trading_data.Expense)}`}</span>
                            </th>
                          </tr>
                        </tfoot>
                      </table>

                      <table>
                        <thead>
                          <tr
                            className="tg-header"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <th className="th-cell">
                              <span>Particulars</span>
                            </th>
                            <th className="th-cell">
                              <span>Income</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {reportData.trading_data.income_particulars.fuel_closing_stock.map(
                            (item, i) => (
                              <tr
                                key={i}
                                className="tg-body"
                                style={{ borderBottom: '0px solid white' }}
                              >
                                <td className="tg-cell">
                                  <span>{`Closing Stock - ${item.product || ''}`}</span>
                                </td>
                                <td className="tg-cell">
                                  <span>{`${formatCurrency(item.stock || 0)}`}</span>
                                </td>
                              </tr>
                            ),
                          )}

                          <tr className="tg-body">
                            <td className="tg-cell">
                              <span>{`Closing Stock - Lubes:`}</span>
                            </td>
                            <td className="tg-cell">
                              <span>{`${formatCurrency(reportData.trading_data.income_particulars.lube_closing_stock)}`}</span>
                            </td>
                          </tr>
                          <tr className="tg-body">
                            <td className="tg-cell">
                              <span>{`Total Fuel Sales:`}</span>
                            </td>
                            <td className="tg-cell">
                              <span>{`${formatCurrency(reportData.trading_data.income_particulars.fuel_sale)}`}</span>
                            </td>
                          </tr>
                          <tr className="tg-body">
                            <td className="tg-cell">
                              <span>{`Total Lubes Sales:`}</span>
                            </td>
                            <td className="tg-cell">
                              <span>{`${formatCurrency(reportData.trading_data.income_particulars.lube_sale)}`}</span>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr className="tg-footer">
                            <th className="tg-cell">
                              <span>{`Total Sales & Stock Value`}</span>
                            </th>
                            <th className="tg-cell">
                              <span>{`${formatCurrency(reportData.trading_data.income)}`}</span>
                            </th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>

                    <div className="gross-profit">
                      <span className="gross-profit__label">
                        Gross profit / loss:{' '}
                      </span>
                      <span
                        className={`gross-profit__value ${reportData.trading_data.income - reportData.trading_data.Expense > 0 ? '--green' : '--red'}`}
                      >
                        {`${formatCurrency(reportData.trading_data.income - reportData.trading_data.Expense)}`}
                      </span>
                    </div>
                  </>
                );
              }
            })()}
          </div>
        </main>
      </section>
    );
  }
}

export { PLTAReport };
