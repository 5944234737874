import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Expense, Income, ManageSource } from './RouteComponents';

const RouteSetup = () => {
  return (
    <Switch>
      <Route
        path="/account/other-income-expense/source"
        component={ManageSource}
      />
      <Route
        path="/account/other-income-expense/add/income"
        component={Income}
      />
      <Route
        path="/account/other-income-expense/add/expense"
        component={Expense}
      />
      <Route path="/account/other-income-expense/*">
        <Redirect to={`/account/other-income-expense/add/income`} />
      </Route>
    </Switch>
  );
};

export { RouteSetup };
