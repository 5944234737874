import React, { Component } from 'react';

export class CashBook extends Component {
  constructor() {
    super();
    this.state = {
      tab: 1,
    };
  }

  render() {
    const summary = this.props.summary;
    return (
      <>
        <section className="widget dsr-table">
          <header />
          <main>
            <table className="dsr" data-name="Cash Book">
              <thead>
                <tr data-header={true}>
                  <th className="alert-brand" colSpan="2">
                    <label className="printCheckbox">
                      <input
                        type="checkbox"
                        name="enablePrint"
                        defaultChecked="true"
                      />
                    </label>
                    Cash Book
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="--bold">Opening cash balance</td>
                  <td className="--bold">
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(summary.opening_balance || 0)}
                  </td>
                </tr>
                <tr>
                  <td>Cash received from duties</td>
                  <td>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(summary.cash_received_from_duties || 0)}
                  </td>
                </tr>
                <tr>
                  <td>Office Incomes</td>
                  <td>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(summary.all_incomes || 0)}
                  </td>
                </tr>
                <tr>
                  <td>Office Expenses</td>
                  <td>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(summary.all_expenses || 0)}
                  </td>
                </tr>
                <tr>
                  <td>Cash deposit to bank</td>
                  <td>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(summary.cash_deposit_to_bank || 0)}
                  </td>
                </tr>
                <tr>
                  <td className="--bold">Closing cash balance</td>
                  <td className="--bold">
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(summary.closing_cash_balance || 0)}
                  </td>
                </tr>
              </tbody>
            </table>
            <p className="table-notice">
              * Cashbook remains the same irrespective of staff / shift wise
              filter
            </p>
          </main>
        </section>
      </>
    );
  }
}
