import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';

import { CustomerList } from '@C/CreditCustomers/_utils/';
import services from '@C/CreditCustomers/_utils/data';
import { formatCurrency, TableGrid } from '@C/TableGrid';
import { PaymentMethodInfo } from '@C/Utils';
import { StaffList } from '@C/Utils';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { authHeader } from '@H';

const col = [
  {
    name: 'Date',
    key: 'time_stamp',
    type: 'date',
    sortable: true,
  },
  {
    name: 'Customer',
    key: 'payment_for',
    formatter(props) {
      return (
        <>
          {props.customer.first_name} {props.customer.last_name}{' '}
        </>
      );
    },
  },
  {
    name: 'Staff',
    key: 'cashier',
    formatter(props) {
      return (
        <>
          {props.first_name} {props.last_name}{' '}
        </>
      );
    },
  },
  {
    name: 'Amount',
    key: 'all',
    formatter(props) {
      return <>{formatCurrency(props.after_tax)}</>;
    },
  },
  // {
  //   name: 'Discount',
  //   key: '',
  // },
  // {
  //   name: 'Interest',
  //   key: '',
  // },
  // {
  //   name: 'Amount paid',
  //   key: '',
  // },
  {
    name: 'Payment mode',
    key: 'payment_method',
    type: 'payment',
    formatter(props) {
      return (
        <>
          <PaymentMethodInfo
            source={props.source}
            method={props.payment_method}
            bank={props.bank}
            wallet={props.other_wallet}
          />
        </>
      );
    },
  },
  {
    name: 'Notes',
    key: 'notes',
  },
];

const enabledActions = [
  {
    action: 'Delete',
    roles: ['Manager', 'Dealer'],
  },
];

class CreditCollectionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collectionList: [],
      staff_id: this.props.cod ? this.props.staff : '',
      start_date: this.props.cod
        ? this.props.date
        : dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: this.props.cod ? this.props.date : dayjs().format('YYYY-MM-DD'),
      credit_customer_id: '',
      loading: true,
      offset: 1,
      limit: 20,
      count: 0,
    };

    this.limitInput = React.createRef();
  }

  componentDidMount() {
    this.getCreditCollectionList();
  }

  getCreditCollectionList = () => {
    services
      .getCreditCollection(this.state)
      .then((response) => {
        this.setState({
          collectionList: response.data.data.results,
          count: response.data.data.count,
          loading: false,
        });
      })

      .catch((error) => {
        console.log(error.response.data.error);
      });
  };

  handlePagination = (e) => {
    this.setState({ offset: e, loading: true }, function () {
      this.getCreditCollectionList();
    });
  };
  handleStaffChange = (e) => {
    this.setState({ staff_id: e.value });
  };

  handleCustomerChange = (e) => {
    this.setState({ credit_customer_id: e.value });
  };

  handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date' && dayjs(value).isAfter(this.state.end_date)) {
      this.setState({ [name]: value, end_date: value });
    } else if (
      name === 'end_date' &&
      dayjs(value).isBefore(this.state.start_date)
    ) {
      this.setState({ [name]: value, start_date: value });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleQuickSearch = (e) => {
    e.preventDefault();
    if (
      this.limitInput.current.value === '' ||
      this.limitInput.current.value === '0'
    ) {
      return;
    }
    this.setState({ limit: this.limitInput.current.value, offset: 1 }, () => {
      this.getCreditCollectionList();
    });
  };

  resetFilter = () => {
    this.setState(
      {
        staff_id: '',
        start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),

        credit_customer_id: '',

        loading: true,
      },
      function () {
        this.getCreditCollectionList();
      },
    );
  };

  confirmDelete = (item) => {
    confirmAlert({
      title: 'Confirm Credit Collection List deletion',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteItem(item),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  deleteItem = (item) => {
    const headers = authHeader(1);
    axios
      .delete(
        `${process.env.API_URL}/payment/?transaction_id=${item.uuid}&staff_id=${item.cashier.uuid}&cashier_duty_id=${item.cashier_duty.cashier_duty_id}&source=${item.source}`,
        {
          headers,
        },
      )
      .then(() => {
        toast.success('Credit collection deleted successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        this.getCreditCollectionList();
      });
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, function () {
      this.getCreditCollectionList();
    });
  };

  render() {
    const { collectionList } = this.state;
    return (
      <>
        <section className="widget full-width list-table">
          <header className="list-header">
            <h3> Credit Collection List</h3>
          </header>
          <main>
            <div className="table-filters">
              <div className="filter-wrapper">
                <div className="filter-group with-icon">
                  <label>
                    Results to display - {this.state.limit || 20} (Total:{' '}
                    {this.state.count})
                  </label>
                  <form onSubmit={this.handleQuickSearch}>
                    <input type="text" name="limit" ref={this.limitInput} />
                    <button
                      type="submit"
                      className="search-icon"
                      onClick={this.handleQuickSearch}
                    />
                  </form>
                </div>
                <div className="filter-group">
                  <label>From date:</label>
                  <input
                    className="cstm-date-input"
                    type="date"
                    value={this.state.start_date}
                    name="start_date"
                    onChange={this.handleDateChange}
                  />
                </div>
                <div className="filter-group">
                  <label>To date:</label>
                  <input
                    className="cstm-date-input"
                    type="date"
                    value={this.state.end_date}
                    min={this.state.start_date}
                    onChange={this.handleDateChange}
                    name="end_date"
                  />
                </div>
                <div className="filter-group">
                  <label>Staff:</label>
                  <StaffList
                    value={this.state.staff_id}
                    onChange={this.handleStaffChange}
                    staffType={['Cashiers', 'Managers', 'Dealer']}
                  />
                </div>
                <div className="filter-group">
                  <label>Customer:</label>
                  <CustomerList
                    onCustomerChange={this.handleCustomerChange}
                    value={this.state.credit_customer_id}
                  />
                </div>

                <div className="filter-group reset-filter">
                  <button onClick={this.resetFilter}>Reset filters</button>
                </div>
                <div className="filter-group">
                  <button
                    className="submit"
                    type="submit"
                    onClick={this.submitForm}
                  >
                    Apply filter
                  </button>
                </div>
              </div>
            </div>
            <div className="table-wrapper">
              {(() => {
                if (this.state.loading) {
                  return <DropLoaderLarge />;
                } else {
                  return (
                    <>
                      <TableGrid
                        col={col}
                        row={collectionList}
                        offset={this.state.offset}
                        count={this.state.count}
                        limit={this.state.limit}
                        handlePagination={this.handlePagination}
                        onEdit={this.props.onEdit}
                        onDelete={this.confirmDelete}
                        enabledActions={enabledActions}
                        exportOptions={{
                          fileName: `Credit Collection - ${this.state.start_date} to ${this.state.end_date}.pdf`,
                          reportName: 'Credit Collection Report',
                          reportDate: `Date - ${dayjs(
                            this.state.start_date,
                          ).format(
                            'D MMM YY',
                          )} to ${dayjs(this.state.end_date).format('D MMM YY')}`,
                          reportTotal: `Total credit collection amount -  ${collectionList.reduce(
                            (a, b) => a + (b['after_tax'] || 0),
                            0,
                          ) || 0
                            }`,
                          reportQuantity: '',
                          reportSales: '',
                          reportTesting: '',
                          reportBowser: '',
                          reportCustomerName: '',
                          reportGst: '',
                          reportOpeningBalance: '',
                          reportClosingBalance: '',
                          reportSaleTotal: '',
                          reportTCSTotal: '',
                        }}
                      />
                    </>
                  );
                }
              })()}
              <div className="mini-summary">
                <ul>
                  <li>
                    <span>Total credit collection amount: </span>
                    <span>
                      {new Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        currency: 'INR',
                      }).format(
                        collectionList.reduce(
                          (a, b) => a + (b['after_tax'] || 0),
                          0,
                        ) || 0,
                      )}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </main>
        </section>
      </>
    );
  }
}
export { CreditCollectionList };
