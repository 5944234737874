import axios from 'axios';

import { authHeader } from '@H';

export default {
  getDealersList: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(),
      url: `${process.env.API_URL}/users/dealers-list/?limit=${data.limit}&offset=${data.offset}&mobile_number=${data.mobile_number || ''}&created_by_id=${data.created_by_id || ''}&country_id=${data.country_id || ''}&state=${data.state || ''}&city=${data.city || ''}&first_name=${data.first_name || ''}&last_name=${data.last_name || ''}&bunk_name=${data.bunk_name || ''}`,
    }),
  getDipCalibrationList: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(),
      url: `${process.env.API_URL}/dip-calibration/?omc_id=${data.omc_id}&limit=${data.limit}&offset=${data.offset}`,
    }),
};
