import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { first } from 'rxjs/operators';

import CCservices from '@C/CreditCustomers/_utils/data';
import Utilservices from '@C/Utils/data';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { AuthRoute, Role } from '@H';
import {
  Banking,
  BunkSetup,
  CashiersOnDuty,
  CreditCustomers,
  Dashboard,
  DataBackUp,
  DipReading,
  Dsr,
  LiveMemo,
  Loans,
  Login,
  Lubes,
  MyAccountPage,
  OtherIE,
  PrivacyPolicy,
  Purchases,
  ReferralProgram,
  Reports,
  ServerMaintenance,
  Settings,
  StaffManagement,
  TallyExport,
  UnauthorizedPage,
} from '@P';
import {
  CreateDealerPage,
  DipCalibrationPage,
  GeneratePurchaseOrderPage,
  SmsSetup,
  UsersPage,
} from '@P/Admin';
import {
  CreditLedgerPage,
  CreditSalePage,
  InvoicePage,
  MyProfile,
  PaymentsPage,
  PurchaseOrderPage,
  QuotationPage,
  RemindersPage,
} from '@P/CreditCustomerProfile';
import { authService } from '@S';
import { useGlobalStore } from '@stores/globalStore';

import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

const Logout = () => {
  authService.logout();
  window.location = '/';
  return true;
};

const Core = () => {
  const setCreditCustomers = useGlobalStore(
    (state) => state.setCreditCustomers,
  );

  const setProductList = useGlobalStore((state) => state.setProductList);
  const setTaxList = useGlobalStore((state) => state.setTaxList);
  const setStaffList = useGlobalStore((state) => state.setStaffList);
  const date = dayjs().format('YYYY-MM-DD');
  const [loading, setLoading] = useState(false);
  const [serverMaintenance, setServerMaintenance] = useState(false);
  const fetchNavigationData = useGlobalStore(
    (state) => state.fetchNavigationData,
  );
  const [fetchStatus, setFetchStatus] = useState({
    creditCustomers: false,
    productList: false,
    taxList: false,
    staffList: false,
  });

  const fetchData = async () => {
    try {
      // read the user info from the auth service
      const user = await authService.currentUser.pipe(first()).toPromise();

      const role = user.data.user_info.role.code;
      let promises = [];

      setLoading(true);

      if (role === 100) {
        promises = [fetchNavigationData()];
      } else {
        promises = [
          fetchCreditCustomers(),
          fetchProductList(),
          fetchTaxList(),
          fetchNavigationData(),
        ];
      }

      if (role !== 104 && role !== 100) {
        promises.push(fetchStaffList());
      }

      await Promise.all(promises);

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCreditCustomers = () => {
    if (fetchStatus.creditCustomers) return Promise.resolve(true);
    setFetchStatus((prev) => ({ ...prev, creditCustomers: true }));
    return new Promise((resolve, reject) => {
      CCservices.getCreditCustomerList(9999999, 1)
        .then((response) => {
          setCreditCustomers(response.data.data.results);
          resolve(true);
        })
        .catch((error) => {
          handleFetchError(error);
          reject(error);
        });
    });
  };

  const fetchProductList = () => {
    if (fetchStatus.productList) return Promise.resolve(true);
    setFetchStatus((prev) => ({ ...prev, productList: true }));
    return new Promise((resolve, reject) => {
      CCservices.getProductList(date)
        .then((response) => {
          setProductList(response.data.data);
          resolve(true);
        })
        .catch((error) => {
          handleFetchError(error);
          reject(error);
        });
    });
  };

  const fetchTaxList = () => {
    if (fetchStatus.taxList) return Promise.resolve(true);
    setFetchStatus((prev) => ({ ...prev, taxList: true }));
    return new Promise((resolve, reject) => {
      Utilservices.getTaxDetails()
        .then((response) => {
          setTaxList(response.data.data);
          resolve(true);
        })
        .catch((error) => {
          handleFetchError(error);
          reject(error);
        });
    });
  };

  const fetchStaffList = () => {
    if (fetchStatus.staffList) return Promise.resolve(true);
    setFetchStatus((prev) => ({ ...prev, staffList: true }));
    return new Promise((resolve, reject) => {
      Utilservices.getStaffList()
        .then((response) => {
          const tmp = response.data.data;
          const newData = tmp.cashiers;
          const cashiers = newData.map((item) => ({
            value: item.uuid,
            label: `${item.first_name} ${item.last_name}`,
          }));
          const newOtherData = tmp.other_staff;
          const other_staff = newOtherData.map((item) => ({
            value: item.uuid,
            label: `${item.first_name} ${item.last_name}`,
          }));

          Utilservices.getFilteredStaffList('2077-01-01')
            .then((response) => {
              const tmp = response.data.data.duties_list;
              const dealers = [];
              const managers = [];

              tmp.forEach((item, i) => {
                if (item.role_type === 101) {
                  dealers.push({
                    value: item.staff_uuid,
                    label: `${tmp[i].first_name} ${tmp[i].last_name}`,
                  });
                } else {
                  managers.push({
                    value: item.staff_uuid,
                    label: `${tmp[i].first_name} ${tmp[i].last_name}`,
                  });
                }
              });

              const options = [
                {
                  label: 'Cashiers',
                  options: cashiers,
                },
                {
                  label: 'Dealer',
                  options: dealers,
                },
                {
                  label: 'Managers',
                  options: managers,
                },
                {
                  label: 'other_staff',
                  options: other_staff,
                },
              ];

              setStaffList(options);
              resolve(true);
            })
            .catch((error) => {
              handleFetchError(error);
              reject(error);
            });
        })
        .catch((error) => {
          handleFetchError(error);
          reject(error);
        });
    });
  };

  const handleFetchError = (error) => {
    if (error.response === undefined) {
      setServerMaintenance(true);
    }
    console.log(error);
  };

  useEffect(() => {
    const subscription = authService.currentBunk.subscribe((y) => {
      const role = authService.currentUserRoleCode;

      if ((y && y.data[0]?.account_id) || role === 100) {
        fetchData();
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  if (serverMaintenance) {
    return <ServerMaintenance />;
  }

  if (loading) {
    return <DropLoaderLarge />;
  }

  if (!loading) {
    return (
      <Router>
        <ToastContainer position="bottom-center" autoClose={5000} />
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/unauthorized" component={UnauthorizedPage} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <AuthRoute
            path="/account/banking"
            roles={[Role.Dealer, Role.Manager]}
            component={Banking}
          />
          <AuthRoute
            path="/dashboard"
            roles={[
              Role.Admin,
              Role.Dealer,
              Role.Manager,
              Role.Cashier,
              Role.CreditCustomer,
            ]}
            component={Dashboard}
          />
          <AuthRoute
            path="/cashiers-on-duty"
            roles={[Role.Dealer, Role.Manager, Role.Cashier]}
            component={CashiersOnDuty}
          />
          <AuthRoute
            path="/account/referral-program"
            roles={[Role.Admin, Role.Dealer, Role.Manager]}
            component={ReferralProgram}
          />
          <AuthRoute
            path="/admin/add/dealer"
            roles={Role.Admin}
            component={CreateDealerPage}
          />
          <AuthRoute
            path="/admin/users"
            roles={Role.Admin}
            component={UsersPage}
          />
          <AuthRoute
            path="/admin/dip-calibration"
            roles={Role.Admin}
            component={DipCalibrationPage}
          />
          <AuthRoute
            path="/admin/generate-purchase-order"
            roles={Role.Admin}
            component={GeneratePurchaseOrderPage}
          />
          <AuthRoute
            path="/admin/sms-setup"
            roles={Role.Admin}
            component={SmsSetup}
          />
          <AuthRoute
            path="/account/setup/bunk"
            roles={[Role.Dealer, Role.Manager]}
            component={BunkSetup}
          />
          <AuthRoute
            path="/account/other-income-expense"
            roles={[Role.Dealer, Role.Manager]}
            component={OtherIE}
          />
          <AuthRoute
            path="/account/lubes"
            roles={[Role.Dealer, Role.Manager]}
            component={Lubes}
          />
          <AuthRoute
            path="/account/staff-management"
            roles={[Role.Dealer, Role.Manager]}
            component={StaffManagement}
          />
          <AuthRoute
            path="/account/loan"
            roles={[Role.Dealer, Role.Manager]}
            component={Loans}
          />
          <AuthRoute
            path="/account/credit-customers"
            roles={[Role.Dealer, Role.Manager]}
            component={CreditCustomers}
          />
          <AuthRoute
            path="/account/tally-export"
            roles={[Role.Dealer, Role.Manager]}
            component={TallyExport}
          />
          <AuthRoute
            path="/account/data-backup"
            roles={[Role.Dealer, Role.Manager]}
            component={DataBackUp}
          />
          <AuthRoute
            path="/creditcustomer/credit-sale"
            roles={[Role.CreditCustomer]}
            component={CreditSalePage}
          />
          <AuthRoute
            path="/creditcustomer/credit-ledger"
            roles={[Role.CreditCustomer]}
            component={CreditLedgerPage}
          />
          <AuthRoute
            path="/creditcustomer/quotation"
            roles={[Role.CreditCustomer]}
            component={QuotationPage}
          />
          <AuthRoute
            path="/creditcustomer/live-purchase-order"
            roles={[Role.CreditCustomer]}
            component={PurchaseOrderPage}
          />
          <AuthRoute
            path="/creditcustomer/invoice"
            roles={[Role.CreditCustomer]}
            component={InvoicePage}
          />
          <AuthRoute
            path="/creditcustomer/payments"
            roles={[Role.CreditCustomer]}
            component={PaymentsPage}
          />
          <AuthRoute
            path="/creditcustomer/reminders"
            roles={[Role.CreditCustomer]}
            component={RemindersPage}
          />
          <AuthRoute
            path="/account/dip-reading"
            roles={[Role.Dealer, Role.Manager]}
            component={DipReading}
          />
          <AuthRoute
            path="/account/purchases"
            roles={[Role.Dealer, Role.Manager]}
            component={Purchases}
          />
          <AuthRoute
            path="/account/setup/app"
            roles={[Role.Dealer, Role.Manager]}
            component={Settings}
          />
          <AuthRoute
            path="/account/live-memo"
            roles={[Role.Dealer, Role.Manager]}
            component={LiveMemo}
          />
          <AuthRoute
            path="/account/dsr/:date"
            roles={[Role.Dealer, Role.Manager]}
            component={Dsr}
          />
          <Route path="/account/dsr/">
            <Redirect to={`/account/dsr/${date}`} />
          </Route>
          <AuthRoute
            path="/account/reports"
            roles={[Role.Dealer, Role.Manager]}
            component={Reports}
          />
          <AuthRoute
            path="/account/creditcustomer"
            roles={[Role.CreditCustomer]}
            component={MyProfile}
          />
          <AuthRoute
            path="/account"
            roles={[Role.Dealer]}
            component={MyAccountPage}
          />

          <Route path="*" exact={true}>
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    );
  }
};

export default Core;
