import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  BlackList,
  CreditCollection,
  CreditCustomerList,
  CreditSlips,
  CustomerLedger,
  CustomerLedgerList,
  GenerateInvoice,
  GenerateQuotation,
  LivePurchaseOrders,
} from './RouteComponents';

const RouteSetup = () => {
  return (
    <Switch>
      <Route
        path="/account/credit-customers/list"
        component={CreditCustomerList}
      />
      <Route path="/account/credit-customers/slips" component={CreditSlips} />
      <Route
        path="/account/credit-customers/credit-collection"
        component={CreditCollection}
      />
      <Route
        path="/account/credit-customers/customer-ledger"
        component={CustomerLedger}
      />
      <Route
        path="/account/credit-customers/purchase-orders"
        component={LivePurchaseOrders}
      />
      <Route
        path="/account/credit-customers/black-list"
        component={BlackList}
      />
      <Route
        path="/account/credit-customers/generate-invoice"
        component={GenerateInvoice}
      />
      <Route
        path="/account/credit-customers/generate-quotation"
        component={GenerateQuotation}
      />
      <Route
        path="/account/credit-customers/credit-customer-ledger/:uuid"
        component={CustomerLedgerList}
      />
      <Route path="/account/credit-customers/*">
        <Redirect to={`/account/credit-customers/list`} />
      </Route>
    </Switch>
  );
};

export { RouteSetup };
