import React, { Component } from 'react';

import { TableGrid } from '@C/TableGrid';

const col = [
  {
    name: 'Pump name',
    key: 'pump_name',
  },
  {
    name: 'Dealer name',
    key: 'dealer_name',
  },
  {
    name: 'OMC',
    key: 'omc',
  },
  {
    name: 'Contact',
    key: 'contact',
  },
  {
    name: 'Dealer registation date',
    key: 'dealer_registation_date',
  },
  {
    name: 'Buyer status',
    key: 'buyer_status',
  },
  {
    name: 'Reward status',
    key: 'reward_status',
  },
  {
    name: 'Referral date',
    key: 'referral_date',
  },
  {
    name: 'Notes',
    key: 'notes',
  },
];

export default class ReferralList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      referralList: [],
      showModal: false,
      selectedData: '',
      offset: 0,
      count: 0,
    };
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {};

  render() {
    const { referralList } = this.state;

    return (
      <>
        <section className="widget full-width">
          <header>
            <h3> Your Referral Details </h3>
          </header>
          <main className="content-wrapper">
            <TableGrid
              col={col}
              row={referralList}
              count={this.state.count}
              onEdit={this.props.onEdit}
              onDelete={this.confirmDelete}
            />
          </main>
        </section>
      </>
    );
  }
}
