import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';

import { ToggleSwitch } from '@C/FormElements';
import services from '@C/MyAccount/_utils/data';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { authHeader } from '@H';
import { authService } from '@S';

const ViewAccount = () => {
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [profile, setProfile] = useState([
    {
      account_id: '',
      mobile: '',
      bunk_name: '',
      gst_no: '',
      email: '',
      first_name: '',
      last_name: '',
      username: '',
      password: '',
      is_password_update: false,
      address: {
        city: '',
        state: '',
        pincode: '',
        full_address: '',
        country: '',
      },
    },
  ]);
  const account_id = authService.currentAccountID;
  useEffect(() => {
    getDealersList();
  }, []);

  const getDealersList = () => {
    services
      .getDealersList(account_id)
      .then((response) => {
        const tmp = response.data.data.results;
        setProfile(tmp);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const toggleEdit = (item) => {
    setEdit({ edit: !edit });
  };

  const togglePasswordUpdate = () => {
    const tmp = [...profile];
    tmp[0].is_password_update = !tmp[0].is_password_update;
    setProfile(tmp);
  };

  const handleChange = (e) => {
    const tmp = [...profile];
    tmp[0][e.target.name] = e.target.value;
    setProfile(tmp);
  };

  const cancelEdit = () => {
    getDealersList();
    setEdit(false);
  };

  const confirmSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (profile[0].is_password_update) {
      confirmPasswordUpdate(e);
    } else {
      updateDealerProfile(e);
    }
  };

  const confirmPasswordUpdate = (e) => {
    confirmAlert({
      title: 'Confirm password update',
      message: `You are updating your password which will log you out from this device once updated. Are you sure you want to continue?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => updateDealerProfile(e),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  const updateDealerProfile = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const formData = {
      account_id: profile[0].account_id,
      mobile: profile[0].contact,
      bunk_name: profile[0].name,
      gst_no: profile[0].gst_no,
      email: profile[0].email,
      first_name: profile[0].dealer_first_name,
      last_name: profile[0].dealer_last_name,
      username: profile[0].username,
      is_password_update: profile[0].is_password_update,
      password: profile[0].password,
      address: {
        city: profile[0].city,
        state: profile[0].state,
        pincode: profile[0].pincode,
        full_address: profile[0].full_address,
        country: profile[0].country,
      },
    };
    const headers = authHeader(1);
    axios
      .put(`${process.env.API_URL}/users/update-dealer/`, formData, {
        headers,
      })
      .then((res) => {
        toast.success('Account details updated successfully, logout and login again for reflecting the changes');
        setEdit(false);
        setIsSubmitting(false);
        if (formData.is_password_update) {
          authService.logout();
          window.location = '/';
          return true;
        } else {
          getDealersList();
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        console.log(error);
      });
  };

  if (loading) {
    return <DropLoaderLarge />;
  } else {
    return (
      <>
        <section className="widget form-card full-width">
          <header>
            <div className="actions space-between">
              <button
                className={
                  !edit
                    ? `white-btn btn-with-icon btn-with-icon__edit`
                    : `red-btn`
                }
                onClick={() => {
                  edit ? cancelEdit() : toggleEdit();
                }}
              >
                {`${edit ? 'Cancel' : 'Edit'}`}
              </button>
            </div>
          </header>
          <main>
            <form className="inline">
              {profile.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="input-wrapper with-label">
                      <label>First name</label>
                      <input
                        type="text"
                        onChange={handleChange}
                        value={item.dealer_first_name}
                        name="dealer_first_name"
                        disabled={!edit}
                      />
                    </div>
                    <div className="input-wrapper with-label">
                      <label>Last name</label>
                      <input
                        type="text"
                        onChange={handleChange}
                        value={item.dealer_last_name}
                        name="dealer_last_name"
                        disabled={!edit}
                      />
                    </div>
                    <div className="input-wrapper with-label">
                      <label>Email</label>
                      <input
                        type="email"
                        onChange={handleChange}
                        value={item.email}
                        name="email"
                        disabled={!edit}
                      />
                    </div>
                    <div className="input-wrapper with-label">
                      <label>Mobile</label>
                      <input
                        type="number"
                        value={item.contact}
                        name="contact"
                        disabled={true}
                      />
                    </div>
                    <div className="input-wrapper with-label">
                      <label>Username</label>
                      <input
                        type="text"
                        onChange={handleChange}
                        value={item.username}
                        name="username"
                        disabled={!edit}
                      />
                    </div>

                    <div className="input-wrapper with-label">
                      <label>GST </label>
                      <input
                        type="text"
                        onChange={handleChange}
                        value={item.gst_no}
                        name="gst_no"
                        disabled={!edit}
                      />
                    </div>
                    <div className="input-wrapper with-label">
                      <label>OMC</label>
                      <input
                        disabled={true}
                        value={item.omc_name}
                        name="omc_name"
                      />
                    </div>
                    <div className="input-wrapper with-label">
                      <label>Bunk name</label>
                      <input
                        type="text"
                        onChange={handleChange}
                        value={item.name}
                        name="name"
                        disabled={!edit}
                      />
                    </div>
                    <div className="input-wrapper with-label">
                      <label>Pincode</label>
                      <input
                        value={item.pin_code}
                        name="pin_code"
                        disabled={true}
                      />
                    </div>
                    <div className="input-wrapper with-label">
                      <label>City</label>
                      <input value={item.city} name="city" disabled={true} />
                    </div>
                    <div className="input-wrapper with-label">
                      <label>State</label>
                      <input value={item.state} name="state" disabled={true} />
                    </div>
                    <div className="input-wrapper with-label">
                      <label>Country</label>
                      <input
                        value={item.country}
                        disabled={true}
                        name="country"
                      />
                    </div>
                    <div className="input-wrapper with-label">
                      <label>Full address </label>
                      <input
                        type="text"
                        onChange={handleChange}
                        value={item.full_address}
                        name="full_address"
                        disabled={!edit}
                      />
                    </div>
                    <div className="input-wrapper  toggle-element">
                      <span className="label"> Update password: </span>
                      <ToggleSwitch
                        did={0}
                        id="updatePassword"
                        name="updatePassword"
                        disabled={!edit}
                        checked={item.is_password_update}
                        onChange={togglePasswordUpdate}
                      />
                    </div>
                    {item.is_password_update && edit && (
                      <div
                        className={
                          showPassword
                            ? 'input-wrapper visible with-label'
                            : 'input-wrapper with-label'
                        }
                      >
                        <label> Password </label>
                        <input
                          type={showPassword ? 'text' : 'password'}
                          value={item.password}
                          onChange={handleChange}
                          name="password"
                        />
                        <span
                          className="show-psswd"
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
              {edit && (
                <div className="cta-wrapper">
                  <button
                    className={`submit ${isSubmitting ? 'loading' : ''}`}
                    onClick={(e) => confirmSubmit(e)}
                  >
                    Submit
                  </button>
                </div>
              )}
            </form>
          </main>
        </section>
      </>
    );
  }
};
export { ViewAccount };
