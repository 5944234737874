import { authService } from '@S';

export function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if ([401, 403].indexOf(response.status) !== -1) {
        authService.logout();
      }
      const error = data.message;
      return Promise.reject(error);
    }

    return data;
  });
}
