import React, { Component } from 'react';
import dayjs from 'dayjs';

import { ToggleSwitch } from '@C/FormElements';
import services from '@C/Lubes/_utils/data';
import { TableGrid } from '@C/TableGrid';
import { StaffList, VendorList } from '@C/Utils';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';

import './styles.scoped.scss';

const Reminder = () => {
  return (
    <>
      <AddReminder />
      <ReminderType />
      <ReminderList />
    </>
  );
};

class AddReminder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opening_balance: '',
      time_stamp: dayjs().format('YYYY-MM-DD'),
    };
  }

  handleVendorChange = () => {};

  render() {
    return (
      <>
        <section className="widget form-card">
          <header>
            <h3> Add reminder </h3>
          </header>
          <main>
            <form>
              <div className="input-wrapper with-label">
                <label> Reminder About </label>
                <input
                  type="text"
                  value={this.state.opening_balance}
                  name="opening_balance"
                  onChange={this.handleChange}
                  placeholder="Credit customer payment collection"
                />
              </div>
              <div className="input-wrapper with-label">
                <label> Vendor/ Supplier name</label>

                <VendorList
                  onChange={this.handleVendorChange}
                  value={this.state.supplier}
                />
              </div>
              <div className="input-wrapper with-label">
                <label>Description </label>
                <input
                  type="text"
                  value={this.state.opening_balance}
                  name="opening_balance"
                  onChange={this.handleChange}
                  placeholder="Description"
                />
              </div>
              <div className="input-wrapper with-label">
                <label>Due date</label>
                <input
                  type="date"
                  value={this.state.time_stamp}
                  name="time_stamp"
                  onChange={this.handleDate}
                />
              </div>
              <div className="input-wrapper with-label">
                <label> Reminder date </label>
                <input
                  type="date"
                  value={this.state.time_stamp}
                  name="time_stamp"
                  onChange={this.handleDate}
                />
              </div>
              <div className="two-col">
                <div className="input-wrapper toggle-element">
                  <span> Reminder alert : </span>
                  <ToggleSwitch
                    did={this.state.idx}
                    id="is_bowser"
                    name="is_bowser"
                    checked={this.state.is_bowser}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="input-wrapper with-label">
                  <label> Recurrence </label>
                  <input
                    type="text"
                    value={this.state.opening_balance}
                    name="opening_balance"
                    onChange={this.handleChange}
                    placeholder="Description"
                  />
                </div>
                <div className="input-wrapper with-label"> </div>

                <div className="input-wrapper with-label">
                  <label> Till date </label>
                  <input
                    type="date"
                    value={this.state.time_stamp}
                    name="time_stamp"
                    onChange={this.handleDate}
                  />
                </div>
              </div>
              <div className="input-wrapper with-label">
                <label> Notes </label>
                <input
                  type="text"
                  value={this.state.opening_balance}
                  name="opening_balance"
                  onChange={this.handleChange}
                  placeholder="amount"
                />
              </div>
              <div className="cta-wrapper">
                <button className="submit" onClick={this.submitForm}>
                  Submit
                </button>
              </div>
            </form>
          </main>
        </section>
      </>
    );
  }
}

class ReminderType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      remindertype: '',
    };
  }

  render() {
    return (
      <>
        <section className="widget form-card">
          <header>
            <h3> Reminder type </h3>
          </header>
          <main>
            <form>
              <div className="input-wrapper with-label">
                <label> Add new reminder type</label>
                <input
                  type="text"
                  value={this.state.remindertype}
                  name="remindertype"
                  onChange={this.handleDate}
                />
              </div>
              <div className="cta-wrapper">
                <button className="submit" onClick={this.submitForm}>
                  Submit
                </button>
              </div>
            </form>
          </main>
          <AvailableReminder />
        </section>
      </>
    );
  }
}

class AvailableReminder extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <section
        className="widget list-card list-card__minimal"
        style={{ 'border-bottom': 'none' }}
      >
        <header>
          <h3> Available Reminder</h3>
        </header>
        <main>
          <ul>
            <li>
              <span> Reminder type: </span>{' '}
              <span> Credit customer payment collection </span>
              <div className="card-actions">
                <button
                  className="actions __edit"
                  onClick={() => this.props.editItem(item)}
                >
                  Delete
                </button>
                <button
                  className="actions __delete"
                  onClick={() => this.confirmDelete(item.uuid)}
                >
                  Delete
                </button>
              </div>
            </li>
            <li>
              {' '}
              <span> Reminder type: </span> <span> Supplier payment</span>
              <div className="card-actions">
                <button
                  className="actions __edit"
                  onClick={() => this.props.editItem(item)}
                >
                  Delete
                </button>
                <button
                  className="actions __delete"
                  onClick={() => this.confirmDelete(item.uuid)}
                >
                  Delete
                </button>
              </div>
            </li>
            <li>
              {' '}
              <span> Reminder type: </span> <span> Gun stamping </span>
              <div className="card-actions">
                <button
                  className="actions __edit"
                  onClick={() => this.props.editItem(item)}
                >
                  Delete
                </button>
                <button
                  className="actions __delete"
                  onClick={() => this.confirmDelete(item.uuid)}
                >
                  Delete
                </button>
              </div>
            </li>
          </ul>
        </main>
      </section>
    );
  }
}

const col = [
  {
    name: 'Reminder date ',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },

  {
    name: 'Reminder type',
    key: 'payment_for',
    formatter(props) {
      return <>{props.bill_number}</>;
    },
  },
  {
    name: 'Customer name',
    key: 'customer',
    formatter(props) {
      return (
        <>
          {props.first_name} {props.last_name}
        </>
      );
    },
  },
  {
    name: 'Description',
    key: 'payment_for',
    formatter(props) {
      return <>{props.bill_number}</>;
    },
  },
  {
    name: 'Due date ',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
];

class ReminderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ReminderTypeList: [],
      selectedId: '',
      showModal: false,
      staff_id: this.props.cod ? this.props.staff : '',
      start_date: this.props.cod
        ? this.props.date
        : dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: this.props.cod ? this.props.date : dayjs().format('YYYY-MM-DD'),
      product_uuid: '',
      lube_category: '',
      bill_number: '',
      loading: true,
      limit: 20,
      offset: 1,
      count: 0,
    };
    this.limitInput = React.createRef();
  }

  componentDidMount() {
    this.getLubeSaleList();
  }

  getLubeSaleList = () => {
    services
      .getLubeSales(this.state)
      .then((response) => {
        this.setState({
          saleList: response.data.data.results,
          loading: false,
          count: response.data.data.count,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handlePagination = (e) => {
    this.setState({ offset: e, loading: true }, function () {
      this.getLubeSaleList();
    });
  };
  handleDateChange = (e) => {
    this.setState(
      { [e.target.name]: e.target.value, loading: true },
      function () {
        this.getLubeSaleList();
      },
    );
  };

  handleStaffChange = (e) => {
    this.setState({ staff_id: e.value, loading: true }, function () {
      this.getLubeSaleList();
    });
  };

  handleQuickSearch = (e) => {
    e.preventDefault();
    if (
      this.limitInput.current.value === '' ||
      this.limitInput.current.value === '0'
    ) {
      return;
    }
    this.setState(
      { limit: this.limitInput.current.value, offset: 1 },
      function () {
        this.getLubeSaleList();
      },
    );
  };

  editItem = (item) => {
    this.props.onEdit(item.uuid);
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  resetFilter = () => {
    this.setState(
      {
        staff_id: '',
        customer_id: '',
        start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
        loading: true,
      },
      function () {
        this.getLubeSaleList();
      },
    );
  };

  render() {
    const { ReminderTypeList } = this.state;
    return (
      <section
        className={`widget full-width list-table ${this.props.cod ? 'inverted' : ''}`}
      >
        <header>
          <h3> Active Reminder List </h3>
        </header>
        <main>
          {!this.props.cod && (
            <div className="table-filters">
              <div className="filter-wrapper">
                <div className="filter-group with-icon">
                  <label>
                    Results to display- {this.state.limit || 20} (Total:{' '}
                    {this.state.count})
                  </label>
                  <form onSubmit={this.handleQuickSearch}>
                    <input type="text" name="limit" ref={this.limitInput} />
                    <button
                      type="submit"
                      className="search-icon"
                      onClick={this.handleQuickSearch}
                    />
                  </form>
                </div>
                <div className="filter-group">
                  <label>From date:</label>
                  <input
                    className="cstm-date-input"
                    type="date"
                    value={this.state.start_date}
                    name="start_date"
                    onChange={this.handleDateChange}
                  />
                </div>
                <div className="filter-group">
                  <label>To date:</label>
                  <input
                    className="cstm-date-input"
                    type="date"
                    value={this.state.end_date}
                    onChange={this.handleDateChange}
                    name="end_date"
                  />
                </div>
                <div className="filter-group">
                  <label>Supplier:</label>
                  <VendorList
                    value={this.state.vendor_id}
                    onChange={this.handleVendorChange}
                  />
                </div>
                <div className="filter-group">
                  <label> Staff Name:</label>
                  <StaffList
                    value={this.state.staff_id}
                    onChange={this.handleStaffChange}
                    staffType={['Cashiers', 'Managers', 'Dealer']}
                  />
                </div>
                <div className="filter-group reset-filter">
                  <button onClick={this.resetFilter}>Reset filters</button>
                </div>
              </div>
            </div>
          )}

          <div className="table-wrapper">
            {(() => {
              if (this.state.loading) {
                return <DropLoaderLarge />;
              } else {
                return (
                  <>
                    <TableGrid
                      col={col}
                      row={ReminderTypeList}
                      offset={this.state.offset}
                      count={this.state.count}
                      limit={this.state.limit}
                      handlePagination={this.handlePagination}
                      onEdit={this.props.onEdit}
                      onDelete={this.confirmDelete}
                    />
                  </>
                );
              }
            })()}
          </div>
        </main>
      </section>
    );
  }
}

export { Reminder };
