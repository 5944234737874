import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import services from '@C/DipReading/_utils/data';
import { authService } from '@S';

import './styles.scoped.scss';

class DipChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      omc_id: authService.currentOMC,
      name: '',
      capacity: '',
      dipList: [],
      loading: true,
      limit: 20,
      offset: 1,
      count: 0,
    };
  }

  handleOMCChange = (e) => {
    this.setState({ omc_id: e }, function () {
      this.getDipCalibrationList();
    });
  };

  componentDidMount() {
    this.getDipCalibrationList();
  }

  getDipCalibrationList = () => {
    services
      .getDipCalibrationList(this.state)
      .then((response) => {
        this.setState({
          dipList: response.data.data.results,
          loading: false,
          count: response.data.data.count,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    const dipList = this.state.dipList;
    return (
      <>
        <section className="widget full-width grid-form" ref={this.myRef}>
          <header>
            <h3> Available Dip Chart </h3>
          </header>
          <main className="sub-wrapper">
            <div className="cards-container">
              <ul>
                {dipList.map((item, i) => {
                  return (
                    <li className="data-card" key={i}>
                      <div className="card-wrapper">
                        <div className="card-info">
                          <span> {i + 1} </span>
                          <span>
                            <label> Chat name:-</label>
                            <Link
                              to={`/account/dip-reading/dip-calibration-chart/${item.uuid}`}
                            >
                              {' '}
                              {item.name}{' '}
                            </Link>
                          </span>
                          <span>
                            <label> OMC:-</label>
                            {item.omc.name}
                          </span>
                          <span>
                            <label> Capacity:-</label>
                            {item.capacity}
                          </span>
                          <span>
                            <label> Diameter(CMs):-</label>
                            {item.diameter}
                          </span>
                          <span>
                            <label> Int length(CM):-</label>
                            {item.internal_length}
                          </span>
                          <span>
                            <label> Notes:-</label>
                            {item.notes}
                          </span>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </main>
        </section>
      </>
    );
  }
}

export { DipChart };
