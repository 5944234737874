import React, { useRef, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import { Fields, getPaymentModes, Modes } from '@C/Banking/';
import { CustomerList } from '@C/CreditCustomers/_utils';
import { CreditCollectionList } from '@C/CreditCustomers/RouteComponents/CreditCollection/CollectionList';
import { DutyList } from '@C/Utils';
import { authHeader } from '@H';

const CreditCollection = () => {
  const editRef = useRef(null);
  const simpleValidator = useRef(new SimpleReactValidator());

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [, forceUpdate] = useState();
  const [data, setData] = useState({
    payment_list: [
      {
        mode: '',
        bank_id: null,
        credit_collection: {
          amount: '',
          credit_customer_id: '',
        },
        payment_method: 'OFFLINE',
        transaction_source: 'CASH',
        product_type: 'CreditCustomer',
        product_uuid: null,
        bill: '',
        notes: '',
        discount: '',
        interest: '',
        amount_paid: '',
      },
    ],
    staff_id: null,
    cashier_duty_id: null,
    time_stamp: dayjs().format('YYYY-MM-DD'),
    updated: false,
    editMode: false,
  });

  const handleStaffChange = (e) => {
    setData({ ...data, cashier_duty_id: e.value });
  };

  const handleDate = (e) => {
    setData({ ...data, time_stamp: e.target.value });
  };

  const handleAmountChange = (e) => {
    setData({
      ...data,
      payment_list: [
        {
          ...data.payment_list[0],
          credit_collection: {
            ...data.payment_list[0].credit_collection,
            amount: +e.target.value,
          },
        },
      ],
    });
  };

  const handlePaymentSelect = (mode) => {
    setData({
      ...data,
      payment_list: [{ ...data.payment_list[0], payment_method: mode, mode }],
    });
    if (mode == 'cash') {
      setData({
        ...data,
        payment_list: [
          {
            ...data.payment_list[0],
            transaction_source: 'CASH',
            payment_method: 'OFFLINE',
            mode,
          },
        ],
      });
    }
  };

  const onDataChange = (e) => {
    setData({
      ...data,
      payment_list: [
        {
          ...data.payment_list[0],
          bank_id: e.bank_id,
          bank_reference_id: e.bank_reference_id,
          check_date: e.check_date,
          payment_method: e.payment_method,
          transaction_source: e.transaction_source,
        },
      ],
    });
  };

  const handleCustomerSelection = (e) => {
    setData({
      ...data,
      payment_list: [
        {
          ...data.payment_list[0],
          credit_collection: {
            ...data.payment_list[0].credit_collection,
            credit_customer_id: e.value,
          },
        },
      ],
    });
  };

  const handleNotesChange = (e) => {
    setData({
      ...data,
      payment_list: [
        { ...data.payment_list[0], [e.target.name]: e.target.value },
      ],
    });
  };

  const editMode = (item) => {
    const paymentMode = getPaymentModes(item.source, item.payment_method);

    editRef.current.scrollIntoView({ behavior: 'smooth' });

    setData({
      payment_list: [
        {
          mode: paymentMode,
          bank: item.bank,
          bank_id: item.bank?.uuid || null,
          bank_reference_id: item.bank_reference_id,
          check_date: item.check_date,
          payment_method: item.payment_method,
          transaction_source: item.source,
          credit_collection: {
            amount: item.after_tax,
            credit_customer_id: item.payment_for.customer.uuid,
          },
          product_type: 'CreditCustomer',
          product_uuid: null,
          bill: '',
          notes: item.notes,
        },
      ],
      cashier_duty_id: item.cashier_duty.cashier_duty || null,
      time_stamp: dayjs(item.time_stamp).format('YYYY-MM-DD'),
      editMode: true,
      transaction_uuid: item.uuid,
    });
  };

  const cancelEdit = () => {
    setData({
      payment_list: [
        {
          mode: '',
          bank_id: null,
          credit_collection: {
            amount: '',
            credit_customer_id: '',
          },
          payment_method: 'OFFLINE',
          transaction_source: 'CASH',
          product_type: 'CreditCustomer',
          product_uuid: null,
          bill: '',
          notes: '',
          discount: '',
          interest: '',
          amount_paid: '',
        },
      ],
      staff_id: null,
      cashier_duty_id: null,
      time_stamp: dayjs().format('YYYY-MM-DD'),
      updated: false,
      editMode: false,
      staffLabel: '',
    });
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    setIsSubmitting(true);
    const formData = data;
    const headers = authHeader(1);

    const url = `${process.env.API_URL}/payment/`;
    let method = 'POST';
    if (data.editMode) {
      method = 'PUT';
      formData.payment_list[0].transaction_id = data.transaction_uuid;
      formData.payment_list[0].amount =
        +data.payment_list[0].credit_collection.amount;
    }

    axios({
      method,
      url,
      data: formData,
      headers,
    })
      .then(() => {
        toast.success('Credit collection added successfully');
        window.scrollTo({
          behavior: 'smooth',
          top: editRef.current.offsetTop - 150,
        });
        simpleValidator.current.hideMessages();
        setIsSubmitting(false);
        setData({
          payment_list: [
            {
              mode: '',
              bank_id: null,
              credit_collection: {
                amount: '',
                credit_customer_id: '',
              },
              payment_method: 'OFFLINE',
              transaction_source: 'CASH',
              product_type: 'CreditCustomer',
              product_uuid: null,
              bill: '',
              notes: '',
              discount: '',
              interest: '',
              amount_paid: '',
            },
          ],
          staff_id: null,
          cashier_duty_id: '',
          time_stamp: dayjs().format('YYYY-MM-DD'),
          updated: !data.updated,
          editMode: false,
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <section className="widget full-width form-table" ref={editRef}>
        <header className="form-header">
          <h3> {data.editMode ? 'Edit' : ''} Credit Collection </h3>
        </header>
        <main>
          <form className="inline">
            <div className="input-wrapper with-label">
              <label>Date</label>
              <input
                className="cstm-date-input"
                type="date"
                value={data.time_stamp}
                onChange={handleDate}
              />
            </div>
            <div className="input-wrapper with-label">
              <label>Staff</label>
              {simpleValidator.current.message(
                'Customer Name',
                data.cashier_duty_id,
                'required',
              )}
              <DutyList
                onChange={handleStaffChange}
                value={data.cashier_duty_id}
                date={data.time_stamp}
                key={data.time_stamp}
              />
            </div>

            <div className="input-wrapper with-label">
              <label>Customer</label>
              {simpleValidator.current.message(
                'Customer Name',
                data.payment_list[0].credit_collection.credit_customer_id,
                'required',
              )}
              <CustomerList
                onCustomerChange={handleCustomerSelection}
                value={
                  data.payment_list[0].credit_collection.credit_customer_id
                }
              />
            </div>
            <div className="input-wrapper with-label">
              <label>Amount</label>
              {simpleValidator.current.message(
                'Customer Name',
                data.payment_list[0].credit_collection.amount,
                'required',
              )}
              <input
                type="number"
                name="amount"
                onChange={handleAmountChange}
                value={data.payment_list[0].credit_collection.amount}
              />
            </div>
            {/* <div className="input-wrapper with-label ">
              <label> Discount </label>
              <input
                type="text"
                value={data.payment_list[0].discount}
                name="discount"
                onChange={handleNotesChange}
              />
            </div>
            <div className="input-wrapper with-label ">
              <label> Interest </label>
              <input
                type="text"
                value={data.payment_list[0].interest}
                name="interest"
                onChange={handleNotesChange}
              />
            </div> */}
            <div className="input-group">
              <div className="input-wrapper with-label">
                <label> Payment mode</label>
                <Modes
                  did={0}
                  handleModeChange={handlePaymentSelect}
                  value={data.payment_list[0].mode || 'cash'}
                />
              </div>
              <Fields
                did={0}
                method={data.payment_list[0].mode}
                onDataChange={onDataChange}
                bank={data.payment_list[0].bank}
                bank_reference_id={data.payment_list[0].bank_reference_id}
                check_date={data.payment_list[0].check_date}
              />
            </div>
            {/* <div className="input-wrapper with-label ">
              <label> Amount paid </label>
              <input
                type="text"
                value={data.payment_list[0].amount_paid}
                name="amount_paid"
                onChange={handleNotesChange}
                disabled
              />
            </div> */}
            <div className="input-wrapper with-label ">
              <label> Notes </label>
              <input
                type="text"
                value={data.payment_list[0].notes}
                name="notes"
                onChange={handleNotesChange}
              />
            </div>
            <div className="form-actions">
              <button
                className={`submit ${isSubmitting ? 'loading' : ''}`}
                onClick={submitForm}
              >
                Submit
              </button>
              {data.editMode && (
                <button className="red-btn" onClick={cancelEdit}>
                  Cancel
                </button>
              )}
            </div>
          </form>
        </main>
      </section>
      <CreditCollectionList key={data.updated} onEdit={editMode} />
    </>
  );
};

export { CreditCollection };
