import React, { Component } from 'react';

import { Header, Navbar, NavPills } from '@C';
import { RouteSetup } from '@C/Admin/DipCalibration';
import ErrorBoundary from '@C/Error';
import { authService } from '@S';

const routes = [
  {
    name: 'Dip Calibration',
    path: '/admin/dip-calibration/list',
  },
];

class DipCalibrationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const role = authService.currentUserRole;
    return (
      <main>
        <div className="page-wrapper">
          <Header title="Referral program" />
          <Navbar />
          <div className="page-content">
            <section className="page-components">
              <div className="page-title">
                <h1> Dip Calibration </h1>
              </div>
              <ErrorBoundary>
                <NavPills routes={routes} />
              </ErrorBoundary>
              <ErrorBoundary>
                {role === 'Site Admin' && <RouteSetup />}
              </ErrorBoundary>
            </section>
          </div>
        </div>
      </main>
    );
  }
}

export { DipCalibrationPage };
