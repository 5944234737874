import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import { Fields, getPaymentModes, Modes } from '@C/Banking/';
import { ProductList } from '@C/Lubes/_utils/';
import ListSales from '@C/Lubes/RouteComponents/Sales/ListSales';
import { DutyList, getTaxMultiplier, TaxList } from '@C/Utils';
import { authHeader } from '@H';

let cod = false;
class LubeSales extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
      validators: {
        stock: {
          message:
            'The quanity must be less than or equal to the available stock',
          rule: (val) => {
            if (val[0] == 0) {
              return false;
            }
            return true;
          },
          required: true,
        },
      },
    });
    this.myRef = React.createRef();
    cod = this.props.cod || false;
    this.state = {
      payment_list: [
        {
          amount: 0,
          mode: '',
          bank_id: null,
          lubes_sale_details: {
            discount: 0,
            price: 0,
            quantity: 0,
            discount_type: 'amount',
            tax: [],
            taxName: '',
            taxMultiplier: 0,
            available: '',
            bill_number: '',
          },
          payment_method: 'OFFLINE',
          transaction_source: 'CASH',
          product_type: 'Lubes',
          product_uuid: '',
          bill: '',
        },
      ],
      staff_id: cod ? this.props.match.params.staff : null,
      cashier_duty_id: cod ? this.props.match.params.duty : '',
      time_stamp: cod
        ? this.props.match.params.date
        : dayjs().format('YYYY-MM-DD'),
      update: false,
      editMode: false,
      isSubmitting: false,
      updateProductList: false,
    };
  }

  addRow = () => {
    this.setState((prevState) => ({
      payment_list: [
        ...prevState.payment_list,
        {
          amount: 0,
          mode: '',
          bank_id: null,
          lubes_sale_details: {
            discount: 0,
            price: 0,
            quantity: 0,
            discount_type: 'amount',
            tax: [],
            bill_number: '',
            taxName: '',
          },
          payment_method: 'OFFLINE',
          transaction_source: 'CASH',
          product_type: 'Lubes',
          product_uuid: '',
        },
      ],
    }));
  };

  deleteRow = (i) => {
    this.setState({
      payment_list: this.state.payment_list.filter((s, sindex) => i !== sindex),
    });
  };

  handlePaymentSelect = (mode, i) => {
    const list = [...this.state.payment_list];
    list[i].mode = mode;
    if (mode == 'cash') {
      list[i].transaction_source = 'CASH';
      list[i].payment_method = 'OFFLINE';
    }
    this.setState({ payment_list: list });
  };

  onDataChange = (e, i) => {
    const list = [...this.state.payment_list];
    list[i].bank_id = e.bank_id;
    list[i].bank_reference_id = e.bank_reference_id;
    list[i].check_date = e.check_date;
    list[i].payment_method = e.payment_method;
    list[i].transaction_source = e.transaction_source;

    this.setState({ payment_list: list });
  };

  handleStaffChange = (e) => {
    this.setState({
      staff_id: null,
      cashier_duty_id: e.value,
    });
  };

  handleDate = (e) => {
    this.setState({ time_stamp: e.target.value });
  };

  handleProductChange = (e, i) => {
    const list = [...this.state.payment_list];
    list[i].product_uuid = e.value;
    list[i]['lubes_sale_details'].price = e.price;
    list[i]['lubes_sale_details'].stock = e.stock;
    this.setState({ payment_list: list }, function () {
      this.reCalculateAmount(i);
    });
  };

  handleTaxChange = (e, l, i, m) => {
    const list = [...this.state.payment_list];
    list[i]['lubes_sale_details'].tax = e;
    list[i]['lubes_sale_details'].taxName = l;
    list[i]['lubes_sale_details'].taxMultiplier = m;
    this.setState({ payment_list: list }, function () {
      this.reCalculateAmount(i);
    });
  };

  onInputChange = (e, i) => {
    const list = [...this.state.payment_list];
    list[i]['lubes_sale_details'][e.target.name] = e.target.value;

    this.setState({ payment_list: list }, function () {
      this.reCalculateAmount(i);
    });
  };

  reCalculateAmount = (i) => {
    const list = [...this.state.payment_list];
    const qty = +list[i]['lubes_sale_details'].quantity;
    const price = +list[i]['lubes_sale_details'].price;
    const discount = +list[i]['lubes_sale_details'].discount;
    const taxMultiplier = parseFloat(
      list[i]['lubes_sale_details'].taxMultiplier || 0,
    );

    const t = parseFloat(qty) * parseFloat(price) - parseFloat(discount);
    const taxAmount = t * (taxMultiplier / 100);
    const amt = t + taxAmount;
    list[i].amount = amt.toFixed(2);
    this.setState({ payment_list: list });
  };

  editMode = (item) => {
    this.setState({ editMode: false });
    window.scrollTo({ behavior: 'smooth', top: this.myRef.current.offsetTop });
    const tmp = item;
    const paymentList = [...this.state.payment_list];
    const paymentMode = getPaymentModes(tmp.source, tmp.payment_method);
    paymentList[0].mode = paymentMode;
    paymentList[0].bank = tmp.bank;
    paymentList[0].bank_id = tmp.bank?.uuid;
    paymentList[0].bank_reference_id = tmp.bank_reference_id;
    paymentList[0].check_date = tmp.check_date;
    paymentList[0].payment_method = tmp.payment_method;
    paymentList[0].transaction_source = tmp.source;
    paymentList[0].product_uuid = tmp.payment_for.uuid;
    paymentList[0]['lubes_sale_details'].bill_number =
      tmp.payment_for.bill_number;
    paymentList[0]['lubes_sale_details'].discount = tmp.payment_for.discount;
    paymentList[0]['lubes_sale_details'].price = tmp.payment_for.selling_price;
    paymentList[0]['lubes_sale_details'].quantity = tmp.payment_for.quantity;
    paymentList[0]['lubes_sale_details'].stock = tmp.payment_for.no_of_units;
    paymentList[0]['lubes_sale_details'].taxName =
      tmp.payment_for.tax_group_name;
    paymentList[0]['lubes_sale_details'].tax = getTaxMultiplier(
      tmp.payment_for.tax_group_name,
    ).value;
    paymentList[0]['lubes_sale_details'].taxMultiplier = getTaxMultiplier(
      tmp.payment_for.tax_group_name,
    ).multiplier;
    this.setState(
      {
        payment_list: paymentList,
        cashier_duty_id: tmp.cashier_duty.cashier_duty,
        time_stamp: dayjs(tmp.time_stamp).format('YYYY-MM-DD'),
        editMode: true,
        transaction_uuid: tmp.uuid,
      },
      function () {
        this.reCalculateAmount(0);
      },
    );
  };

  cancelEdit = () => {
    this.setState({
      payment_list: [
        {
          amount: 0,
          mode: '',
          bank_id: null,
          lubes_sale_details: {
            discount: 0,
            price: 0,
            quantity: 0,
            discount_type: 'amount',
            tax: [],
            taxName: '',
            taxMultiplier: 0,
            available: '',
            bill_number: '',
          },
          payment_method: 'OFFLINE',
          transaction_source: 'CASH',
          product_type: 'Lubes',
          product_uuid: '',
          bill: '',
        },
      ],
      staff_id: cod ? this.props.match.params.staff : '',
      cashier_duty_id: cod ? this.props.match.params.duty : '',
      time_stamp: cod
        ? this.props.match.params.date
        : dayjs().format('YYYY-MM-DD'),
      update: false,
      editMode: false,
      isSubmitting: false,
      transaction_uuid: '',
    });
  };

  submitForm = () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState({ isSubmitting: true });

    const formData = this.state;
    const headers = authHeader(1);
    let method = 'post';

    if (this.state.editMode) {
      method = 'put';
      formData.payment_list[0].transaction_id = this.state.transaction_uuid;
    }

    axios({
      method: method,
      url: `${process.env.API_URL}/payment/`,
      data: formData,
      headers: headers,
    })
      .then((response) => {
        if (response.status == 206) {
          toast.error(response.data.error[0].error);
        } else {
          toast.success('Sale added successfully');
          this.scrollToTop();
          this.setState({
            payment_list: [
              {
                amount: 0,
                mode: '',
                bank_id: null,
                lubes_sale_details: {
                  discount: 0,
                  price: 0,
                  quantity: 0,
                  tax: [],
                  bill_number: '',
                  discount_type: 'amount',
                },
                payment_method: 'OFFLINE',
                transaction_source: 'CASH',
                product_type: 'Lubes',
                product_uuid: null,
              },
            ],
            cashier_id: cod ? this.props.match.params.staff : null,
            cashier_duty_id: cod ? this.props.match.params.duty : '',
            time_stamp: cod
              ? this.props.match.params.date
              : dayjs().format('YYYY-MM-DD'),
            updated: !this.state.updated,
            isSubmitting: false,
            editMode: false,
            updateProductList: !this.state.updateProductList,
          });
          if (cod) {
            this.props.onDataChange();
          }
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        this.setState({ isSubmitting: false });
      });
  };

  scrollToTop = () => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  };

  render() {
    const paymentList = this.state.payment_list;
    const editMode = this.state.editMode;
    return (
      <>
        <section className="widget full-width form-table" ref={this.myRef}>
          <header>
            <h3>{editMode ? 'Edit' : ''} Lube / Products Sales</h3>

            <div className="actions">
              <button
                className={`add-row ${editMode ? 'hidden' : ''}`}
                onClick={() => this.addRow()}
              >
                Add row
              </button>
            </div>
          </header>
          <main>
            {!cod && (
              <div className="form-header">
                <div className="input-wrapper">
                  <input
                    className="cstm-date-input"
                    type="date"
                    value={this.state.time_stamp}
                    onChange={this.handleDate}
                    disabled={editMode}
                  />
                </div>
                <div className="input-wrapper">
                  {this.validator.message(
                    'staff',
                    this.state.cashier_duty_id,
                    'required',
                  )}
                  <DutyList
                    onChange={this.handleStaffChange}
                    value={this.state.cashier_duty_id}
                    date={this.state.time_stamp}
                    key={this.state.time_stamp}
                    disabled={editMode}
                  />
                </div>
              </div>
            )}

            <div className="form-content">
              <table>
                <thead>
                  <tr>
                    <th>Bill Number</th>
                    <th>Product</th>
                    <th> Available stock </th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Discount</th>
                    <th>Tax</th>
                    <th>Payment mode</th>
                    <th>Total</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentList.map((item, i) => (
                    <tr key={i} className={`${editMode ? 'highlight-bg' : ''}`}>
                      <td>
                        <div className="input-wrapper">
                          <input
                            name="bill_number"
                            type="text"
                            onChange={() => this.onInputChange(event, i)}
                            placeholder="Bill no."
                            value={item.lubes_sale_details.bill_number}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper">
                          {this.validator.message(
                            'product',
                            paymentList[i].product_uuid,
                            'required',
                          )}
                          <ProductList
                            did={i}
                            handleDropdownChange={this.handleProductChange}
                            value={paymentList[i].product_uuid}
                            key={this.state.updateProductList}
                            disabled={editMode}
                            isShowDeleted={false}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper">
                          <input
                            name="number_of_units"
                            type="text"
                            disabled
                            placeholder="Available stock"
                            value={(item.lubes_sale_details.stock || 0).toFixed(
                              2,
                            )}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper">
                          {this.validator.message(
                            'stock',
                            [
                              item.lubes_sale_details.quantity,
                              item.lubes_sale_details.stock,
                            ],
                            'stock',
                          )}
                          <input
                            type="text"
                            value={item.lubes_sale_details.quantity}
                            placeholder="Quantity"
                            name="quantity"
                            min={0}
                            onChange={() => this.onInputChange(event, i)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper">
                          <input
                            type="text"
                            value={item.lubes_sale_details.price}
                            disabled
                            placeholder="Price"
                            name="price"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper">
                          {this.validator.message(
                            'discount',
                            item.lubes_sale_details.discount,
                            'required|numeric',
                          )}
                          <input
                            type="text"
                            value={item.lubes_sale_details.discount}
                            placeholder="Discount"
                            name="discount"
                            onChange={() => this.onInputChange(event, i)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper">
                          <TaxList
                            did={i}
                            onChange={this.handleTaxChange}
                            value={item.lubes_sale_details.taxName}
                            key={this.state.editMode}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper payment-wrapper">
                          <Modes
                            did={i}
                            handleModeChange={this.handlePaymentSelect}
                            value={item.mode || 'cash'}
                            disabled={editMode}
                          />
                          <Fields
                            did={i}
                            method={item.mode}
                            onDataChange={this.onDataChange}
                            bank={item.bank}
                            bank_reference_id={item.bank_reference_id}
                            check_date={item.check_date}
                            disabled={editMode}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper">
                          <input
                            type="text"
                            disabled
                            value={item.amount}
                            placeholder="Total"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper _actions">
                          {i > 0 ? (
                            <button
                              className="delete-row icon-only"
                              onClick={() => this.deleteRow(i)}
                            >
                              Delete row
                            </button>
                          ) : (
                            ''
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="form-actions">
              <button
                className="submit"
                type="submit"
                disabled={this.state.isSubmitting}
                onClick={this.submitForm}
              >
                Submit
              </button>
              {editMode && (
                <button className="red-btn" onClick={this.cancelEdit}>
                  Cancel
                </button>
              )}
            </div>
          </main>
        </section>
        <ListSales
          key={this.state.updated}
          onEdit={this.editMode}
          cod={cod}
          staff={this.state.staff_id}
          date={this.state.time_stamp}
          duty={this.props.match.params.duty}
          onDataChange={this.props.onDataChange}
        />
      </>
    );
  }
}

export { LubeSales };
