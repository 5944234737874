import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ViewAccount } from '@C/MyAccount/RouteComponents';

const RouteSetup = () => {
  return (
    <Switch>
      <Route path="/account" component={ViewAccount} />
      <Route path="/account*">
        <Redirect to={`/account`} />
      </Route>
    </Switch>
  );
};

export { RouteSetup };
