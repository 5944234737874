import React from 'react';
import Select from 'react-select';

import { useGlobalStore } from '@stores/globalStore';

let tmpTaxList = [];

const TaxList = (props) => {
  const taxList = useGlobalStore((state) => state.taxList);

  tmpTaxList = taxList.map(({ value, label, multiplier }) => ({
    value: [...value.map((item) => item.uuid)],
    label,
    multiplier,
  }));

  const handleChange = (selectedOption) => {
    const tax = selectedOption.value;
    const a = tax.map((item) => item.uuid);
    props.onChange(
      a,
      selectedOption.label,
      props.did,
      selectedOption.multiplier,
    );
  };
  return (
    <Select
      onChange={handleChange}
      options={taxList}
      className="select-box-container tax-selector-container"
      classNamePrefix="tax-selector"
      name="tax 1"
      placeholder="Select tax"
      value={taxList.filter(({ label }) => label === props.value)}
    />
  );
};

const getTaxMultiplier = (tax) => {
  const taxMultiplier = tmpTaxList.filter(({ label }) => label === tax);
  if (taxMultiplier.length > 0) {
    return taxMultiplier[0];
  }
  return { value: [], label: '', multiplier: 0 };
};

export { getTaxMultiplier, TaxList };
