import React from 'react';
import Select from 'react-select';

import { useGlobalStore } from '@stores/globalStore';

const CustomerList = (props) => {
  const creditCustomersOptions = useGlobalStore(
    (state) => state.creditCustomersOptions,
  );

  const handleChange = (selectedOption) => {
    const id = props.did ? props.did : 0;
    let option = selectedOption;
    if (selectedOption == null) {
      option = { value: '', label: '' };
    }
    props.onCustomerChange(option, id);
  };

  const selectStyles = {
    menu: () => ({
      zIndex: 50,
      position: 'fixed',
    }),
  };

  return (
    <Select
      captureMenuScroll={true}
      onChange={handleChange}
      isClearable={true}
      options={creditCustomersOptions}
      className="select-box-container customer-selector-container"
      classNamePrefix="customer-selector"
      name="customer_id"
      placeholder="Select customer"
      value={creditCustomersOptions.filter(
        ({ value }) => value === props.value,
      )}
      isDisabled={props.disabled || false}
    />
  );
};

export { CustomerList };
