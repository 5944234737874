import React, { Component } from 'react';

import services from '@C/CreditCustomers/_utils/data';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { authService } from '@S';

import CreditCustomerDsr from './CreditCustomerDsr';

import './styles.scoped.scss';

class CreditCustomerDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileList: {},
      loading: true,
      customer_id: authService.currentUserId,
    };
  }

  componentDidMount() {
    this.getCreditCustomer();
  }
  getCreditCustomer = () => {
    services
      .getCreditCustomer(this.state)
      .then((response) => {
        this.setState({
          profileList: response.data.data,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { profileList, loading } = this.state;
    if (loading) {
      return <DropLoaderLarge />;
    }

    return (
      <>
        <section className="widget full-width">
          <main>
            <form className="inline">
              <div className="input-wrapper with-label ">
                <label className="size"> Payment Pending :</label>
                <input
                  type="text"
                  name="outstanding"
                  style={{ fontSize: '20px' }}
                  className={
                    profileList.billing_cycle?.balance < 0
                      ? '--green --bold'
                      : '--red --bold'
                  }
                  value={parseFloat(
                    profileList.billing_cycle?.balance || 0,
                  ).toFixed(2)}
                />
              </div>
              <div className="input-wrapper with-label">
                <label className="size"> Current credit limit :</label>
                <input
                  type="text"
                  className="size"
                  name="payment_range"
                  value={profileList.billing_cycle?.payment_range}
                />
              </div>
            </form>
          </main>
        </section>
        <CreditCustomerDsr />
      </>
    );
  }
}

export { CreditCustomerDashboard };
