import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';

import services from '@C/Lubes/_utils/data';
import { formatCurrency, TableGrid } from '@C/TableGrid';
import { FilteredStaffList, PaymentMethodInfo, VendorList } from '@C/Utils';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { authHeader } from '@H';

const col = [
  {
    name: 'Date',
    key: 'payment',
    sortable: true,
    formatter(props) {
      return <>{dayjs(props.time_stamp).format('DD/MM/YYYY')}</>;
    },
  },
  {
    name: 'Vendor',
    key: 'invoice',
    formatter(props) {
      return <>{props?.vendor?.first_name}</>;
    },
  },
  {
    name: 'Invoice',
    key: 'invoice',
    formatter(props) {
      return <>{props?.invoice_number}</>;
    },
  },
  {
    name: 'Amount',
    key: 'payment',
    formatter(props) {
      return <>{formatCurrency(props.amount)}</>;
    },
  },
  {
    name: 'Payment mode',
    key: 'payment',
    formatter(props) {
      return (
        <>
          <PaymentMethodInfo
            source={props.source}
            method={props.payment_method}
            bank={props.bank}
            wallet={props.other_wallet}
          />
        </>
      );
    },
  },
  {
    name: 'Staff',
    key: 'payment',
    formatter(props) {
      return (
        <>
          {props.staff.first_name} {props.staff.last_name}
        </>
      );
    },
  },
  {
    name: 'Notes',
    key: 'payment',
    formatter(props) {
      return <>{props.notes}</>;
    },
  },
];

const enabledActions = [
  {
    action: 'Delete',
    roles: ['Manager', 'Dealer'],
  },
];

export default class PaymentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentList: [],
      selectedId: '',
      start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
      limit: 20,
      offset: 1,
      count: 0,
      vendor_id: '',
      showModal: false,
      loading: true,
      staff_id: '',
    };

    this.limitInput = React.createRef();
  }

  componentDidMount() {
    this.getVendorPayment();
  }

  getVendorPayment = () => {
    services
      .getVendorPayment(this.state)
      .then((response) => {
        this.setState({
          paymentList: response.data.data.results,
          loading: false,
          count: response.data.data.count,
        });
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  };

  confirmDelete = (item) => {
    confirmAlert({
      title: 'Confirm Payment List deletion',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteItem(item),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  deleteItem = (item) => {
    const headers = authHeader(1);
    axios
      .delete(
        `${process.env.API_URL}/lubes/bunk/vendor/payments/?payment_id=${item.uuid}`,
        {
          headers,
        },
      )
      .then(() => {
        toast.success('Transaction deleted successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        this.getVendorPayment();
      });
  };
  handleQuickSearch = (e) => {
    e.preventDefault();
    if (
      this.limitInput.current.value === '' ||
      this.limitInput.current.value === '0'
    ) {
      return;
    }
    this.setState(
      { limit: this.limitInput.current.value, offset: 1 },
      function () {
        this.getVendorPayment();
      },
    );
  };

  handleLimitChange = (e) => {
    this.setState({ limit: e.target.value, offset: 1 });
  };

  handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date' && dayjs(value).isAfter(this.state.end_date)) {
      this.setState({ [name]: value, end_date: value });
    } else if (
      name === 'end_date' &&
      dayjs(value).isBefore(this.state.start_date)
    ) {
      this.setState({ [name]: value, start_date: value });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleStaffChange = (e) => {
    this.setState({ staff_id: e.value });
  };

  handlePagination = (e) => {
    this.setState({ offset: e }, function () {
      this.getVendorPayment();
    });
  };
  handleVendorChange = (e) => {
    this.setState({ vendor_id: e.value });
  };

  resetFilter = () => {
    this.setState(
      {
        start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
        limit: 20,
        offset: 1,
        count: 0,
        vendor_id: '',
        staff_id: '',
      },
      function () {
        this.getVendorPayment();
      },
    );
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, function () {
      this.getVendorPayment();
    });
  };

  render() {
    const { paymentList } = this.state;
    return (
      <section className="widget full-width list-table">
        <header>
          <h3>Payment List</h3>
        </header>
        <main>
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group with-icon">
                <label>Results to display - (Total: {this.state.count})</label>
                <form onSubmit={this.handleQuickSearch}>
                  <input type="text" name="limit" ref={this.limitInput} />
                  <button
                    type="submit"
                    className="search-icon"
                    onClick={this.handleQuickSearch}
                  />
                </form>
              </div>
              <div className="filter-group">
                <label>From date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.start_date}
                  name="start_date"
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="filter-group">
                <label>To date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.end_date}
                  min={this.state.start_date}
                  onChange={this.handleDateChange}
                  name="end_date"
                />
              </div>
              <div className="filter-group">
                <label>Supplier:</label>
                <VendorList
                  value={this.state.vendor_id}
                  onChange={this.handleVendorChange}
                />
              </div>
              <div className="filter-group">
                <label> Staff Name:</label>
                <FilteredStaffList
                  value={this.state.staff_id}
                  onChange={this.handleStaffChange}
                />
              </div>
              <div className="filter-group reset-filter">
                <button onClick={this.resetFilter}>Reset filters</button>
              </div>
              <div className="filter-group">
                  <button
                    className="submit"
                    type="submit"
                    onClick={this.submitForm}
                  >
                    Apply filter
                  </button>
                </div>
            </div>
          </div>
          <div className="table-wrapper">
            {(() => {
              if (this.state.loading) {
                return <DropLoaderLarge />;
              } else {
                return (
                  <>
                    <TableGrid
                      col={col}
                      row={paymentList}
                      offset={this.state.offset}
                      count={this.state.count}
                      limit={this.state.limit}
                      handlePagination={this.handlePagination}
                      onEdit={this.props.onEdit}
                      onDelete={this.confirmDelete}
                      enabledActions={enabledActions}
                    />
                  </>
                );
              }
            })()}
          </div>
        </main>
      </section>
    );
  }
}
