import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';

class Pagination extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {}
  handleChange = (event) => {
    this.props.handlePagination(event.selected + 1);
  };

  render() {
    const { offset, limit, count } = this.props;
    const pageCount = Math.ceil(count / (limit || 20));
    if (pageCount <= 1) return null;
    return (
      <div className="pagination-wrapper">
        <ReactPaginate
          breakLabel="..."
          nextLabel="next"
          onPageChange={this.handleChange}
          pageRangeDisplayed={2}
          pageCount={pageCount}
          previousLabel="previous"
          renderOnZeroPageCount={null}
          forcePage={offset - 1}
        />
      </div>
    );
  }
}

export { Pagination };
