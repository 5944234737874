import React, { Component } from 'react';

import services from '@C/CreditCustomers/_utils/data';
import { TableGrid } from '@C/TableGrid';
import { authService } from '@S';
const col = [
  {
    name: 'Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Quote no.',
    key: 'quotation_no',
  },
  {
    name: 'Notes',
    key: 'notes',
  },

  {
    name: 'Status',
    key: 'status',
  },
];

class QuotationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quotationList: [],
      customer_id: authService.currentUserId,
      offset: 1,
      count: 0,
      limit: 20,
    };
  }

  componentDidMount() {
    this.getCreditCustomerQuotation();
  }

  getCreditCustomerQuotation = () => {
    services
      .getCreditCustomerQuotation(this.state)
      .then((response) => {
        this.setState({
          quotationList: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { quotationList } = this.state;
    return (
      <>
        <section className="widget full-width">
          <header>
            <h3> All quotations list </h3>
          </header>
          <main className="content-wrapper">
            <TableGrid
              col={col}
              row={quotationList}
              count={this.state.count}
              hideActions={true}
            />
          </main>
        </section>
      </>
    );
  }
}
export { QuotationList };
