import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import services from './data';

let CategoryOptions;

const QuotationList = (props) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    services
      .getQuotationList()
      .then((response) => {
        const tmp = response.data.data;
        CategoryOptions = [];
        for (let i = 0; i < tmp.length; i++) {
          CategoryOptions.push({
            value: tmp[i].uuid,
            label: tmp[i].quotation_no,
          });
        }
        setOptions(CategoryOptions);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleChange = (selectedOption) => {
    const id = props.did ? props.did : 0;
    props.handleQuotation(selectedOption, id);
  };

  return (
    <Select
      onChange={handleChange}
      isClearable={true}
      options={options}
      className="select-box-container"
      classNamePrefix="raw-select"
      name="quotation_id"
      placeholder="Select quotation id"
      value={options.filter(({ value }) => value === props.value)}
      isDisabled={props.disabled || false}
    />
  );
};

export { QuotationList };
