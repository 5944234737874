import React, { Component } from 'react';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import services from '@C/Utils/data';
import { authHeader } from '@H';

import './styles.scoped.scss';

class ManageSource extends Component {
  render() {
    return (
      <>
        <IncomeComponent />
        <ExpenseComponent />
      </>
    );
  }
}

export class IncomeComponent extends Component {
  constructor() {
    super();
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
    this.myRef = React.createRef();
  }

  state = {
    income_source: '',
    update: '1',
    editMode: false,
    isSubmitting: false,
  };

  handleChange = (e) => {
    this.setState({ income_source: e.target.value });
  };

  editMode = (item) => {
    const tmp = item;
    window.scrollTo({
      behavior: 'smooth',
      top: this.myRef.current.offsetTop - 100,
    });
    this.setState({
      income_source: tmp.other_income,
      uuid: tmp.uuid,
      editMode: true,
    });
  };

  submitForm = (e) => {
    e.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState({ isSubmitting: true });
    let method = 'post';
    let formData = {};
    if (this.state.editMode) {
      method = 'put';
      formData = {
        other_income_list: [
          {
            other_income_source: this.state.income_source,
            source_uuid: this.state.uuid,
          },
        ],
      };
    } else {
      formData = {
        other_income_list: [
          {
            other_income_source: this.state.income_source,
          },
        ],
      };
    }

    const headers = authHeader(1);

    axios({
      method: method,
      url: `${process.env.API_URL}/bunk/other-income-setup/`,
      data: formData,
      headers,
    })
      .then(() => {
        toast.success('Income sources added successfully');
        window.scrollTo({
          behavior: 'smooth',
          top: this.myRef.current.offsetTop - 150,
        });
        this.setState({
          income_source: '',
          isSubmitting: false,
          update: Math.random().toString(36).substr(2, 5),
        });
        this.validator.hideMessages();
      })
      .catch(() => {
        toast.error('Failed to add source. Please try again');
        this.setState({ isSubmitting: false });
      });
  };

  render() {
    return (
      <>
        <section className="widget form-card" ref={this.myRef}>
          <header>
            <h3>Manage Income Source</h3>
          </header>
          <main>
            <form>
              <div className="input-wrapper">
                {this.validator.message(
                  'income_source',
                  this.state.income_source,
                  'required|alpha_num_dash_space',
                )}
                <input
                  type="text"
                  placeholder="Source name"
                  name="income_source"
                  onChange={this.handleChange}
                  value={this.state.income_source}
                />
              </div>
              <div className="cta-wrapper">
                <button
                  className="submit"
                  type="submit"
                  disabled={this.state.isSubmitting}
                  onClick={this.submitForm}
                >
                  Submit
                </button>
              </div>
            </form>
            <IncomeList editItem={this.editMode} key={this.state.update} />
          </main>
        </section>
      </>
    );
  }
}

class IncomeList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      incomeList: [],
    };
  }
  componentDidMount() {
    this.getIncomeList();
  }

  getIncomeList = () => {
    services
      .getIncomeList()
      .then((response) => {
        this.setState({ incomeList: response.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  confirmDelete = (id) => {
    confirmAlert({
      title: 'Confirm income source deletion',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteItem(id),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  deleteItem = (id) => {
    const headers = authHeader(1);
    axios
      .delete(
        `${process.env.API_URL}/bunk/other-income-setup/?other_uuid=${id}`,
        { headers },
      )
      .then(() => {
        toast.success('Income source deleted successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        this.getIncomeList();
      });
  };

  render() {
    const { incomeList } = this.state;
    return (
      <>
        <div className="inline-list-card full-width">
          <ul>
            {incomeList.map((item, i) => (
              <li key={i}>
                <div className="card-title">
                  <h4>{item.other_income}</h4>
                </div>
                <div className="card-actions">
                  <button
                    className="actions __edit"
                    onClick={() => this.props.editItem(item)}
                  >
                    Delete
                  </button>
                  <button
                    className="actions __delete"
                    onClick={() => this.confirmDelete(item.uuid)}
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  }
}

class ExpenseComponent extends Component {
  constructor() {
    super();
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
    this.myRef = React.createRef();
  }

  state = {
    expenses: '',
    updated: '1',
    isSubmitting: false,
  };

  handleChange = (e) => {
    this.setState({ expenses: e.target.value });
  };

  editMode = (item) => {
    const tmp = item;
    window.scrollTo({
      behavior: 'smooth',
      top: this.myRef.current.offsetTop - 100,
    });
    this.setState({
      expenses: tmp.expense,
      uuid: tmp.uuid,
      editMode: true,
    });
  };

  submitForm = (e) => {
    e.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState({ isSubmitting: true });
    let method = 'post';
    let formData = {};
    if (this.state.editMode) {
      method = 'put';
      formData = {
        expenses_list: [
          {
            expenses: this.state.expenses,
            source_uuid: this.state.uuid,
          },
        ],
      };
    } else {
      formData = {
        expenses_list: [
          {
            expenses: this.state.expenses,
          },
        ],
      };
    }
    const headers = authHeader(1);

    axios({
      method: method,
      url: `${process.env.API_URL}/bunk/expenses-setup/`,
      data: formData,
      headers,
    })
      .then(() => {
        toast.success('Expense source added successfully');
        window.scrollTo({
          behavior: 'smooth',
          top: this.myRef.current.offsetTop - 150,
        });
        this.setState({
          expenses: '',
          isSubmitting: false,
          updated: Math.random().toString(36).substr(2, 5),
        });
        this.validator.hideMessages();
      })
      .catch(() => {
        toast.error('Failed to add source. Please try again');
        this.setState({ isSubmitting: false });
      });
  };

  render() {
    return (
      <>
        <section className="widget form-card" ref={this.myRef}>
          <header>
            <h3>Manage Expense Source</h3>
          </header>
          <main>
            <form>
              <div className="input-wrapper">
                {this.validator.message(
                  'expenses',
                  this.state.expenses,
                  'required|alpha_num_dash_space',
                )}
                <input
                  type="text"
                  placeholder="Source name"
                  name="expenses"
                  onChange={this.handleChange}
                  value={this.state.expenses}
                />
              </div>
              <div className="cta-wrapper">
                <button
                  className="submit"
                  type="submit"
                  onClick={this.submitForm}
                  disabled={this.state.isSubmitting}
                >
                  Submit
                </button>
              </div>
            </form>
            <ExpenseList editItem={this.editMode} key={this.state.updated} />
          </main>
        </section>
      </>
    );
  }
}

class ExpenseList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expenseList: [],
    };
  }
  componentDidMount() {
    this.getExpenseList();
  }

  getExpenseList = () => {
    services
      .getExpenseList()
      .then((response) => {
        this.setState({ expenseList: response.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  confirmDelete = (id) => {
    confirmAlert({
      title: 'Confirm expense source deletion',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteItem(id),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  deleteItem = (id) => {
    const headers = authHeader(1);
    axios
      .delete(`${process.env.API_URL}/bunk/expenses-setup/?expenses_id=${id}`, {
        headers,
      })
      .then(() => {
        toast.success('Expense source deleted successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        this.getExpenseList();
      });
  };

  render() {
    const { expenseList } = this.state;
    return (
      <>
        <div className="inline-list-card full-width">
          <ul>
            {expenseList.map((item, i) => (
              <li key={i}>
                <div className="card-title">
                  <h4>{item.expense}</h4>
                </div>
                <div className="card-actions">
                  <button
                    className="actions __edit"
                    onClick={() => this.props.editItem(item)}
                  >
                    Delete
                  </button>
                  <button
                    className="actions __delete"
                    onClick={() => this.confirmDelete(item.uuid)}
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  }
}

export { ManageSource };
