import React, { Component } from 'react';

export class FuelSummary extends Component {
  constructor() {
    super();
  }

  render() {
    const gun_wise = this.props.gun_wise.sort(
      (a, b) => b.gun_name - a.gun_name,
    );
    return (
      <>
        <section className="widget full-width dsr-table">
          <header></header>
          <main className="content-wrapper">
            <table className="dsr" data-name="Fuel Sales">
              <thead>
                <tr>
                  <th className="alert-brand" colSpan={10}>
                    Fuel Sales Summary
                  </th>
                </tr>
                <tr data-header={true}>
                  <th>Gun</th>
                  <th>Staff</th>
                  <th>Shift</th>
                  <th>Opening</th>
                  <th>Closing</th>
                  <th>Testing</th>
                  <th>Bowser</th>
                  <th>Litres sold</th>
                  <th>Rate/litre</th>
                  <th>Total amount</th>
                </tr>
              </thead>
              <tbody>
                {gun_wise.map((val, key) => {
                  return (
                    <tr key={key}>
                      <td>{val.gun_name}</td>
                      <td>{`${
                        val.cashier_first_name || '--'
                      } ${val.cashier_last_name || ''}`}</td>
                      <td>{val.shift}</td>
                      <td>{val.opening_reading}</td>
                      <td>{val.closing_reading}</td>
                      <td>{val.testing_liters}</td>
                      <td>{val.bowser_total}</td>
                      <td>
                        {val.litters_sold > 0
                          ? val.litters_sold.toFixed(2)
                          : 0.0}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-IN', {
                          style: 'currency',
                          currency: 'INR',
                        }).format(val.price || 0)}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-IN', {
                          style: 'currency',
                          currency: 'INR',
                        }).format(val.total_amount || 0)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </main>
        </section>
      </>
    );
  }
}
