import React, { Component } from 'react';

export class DipSummary extends Component {
  constructor() {
    super();
  }

  render() {
    const dipSummary = this.props.dipSummary;
    return (
      <>
        <section className="widget full-width dsr-table">
          <header></header>
          <main className="content-wrapper">
            <table className="dsr" data-name="Dip Summary">
              <thead>
                <tr>
                  <th className="alert-brand" colSpan={10}>
                    <label className="printCheckbox">
                      <input
                        type="checkbox"
                        name="enablePrint"
                        defaultChecked="true"
                      />
                    </label>
                    Dip Summary
                  </th>
                </tr>
                <tr data-header={true}>
                  <th>Product</th>
                  <th>Tank</th>
                  <th>Opening Stock</th>
                  <th>Closing Stock</th>
                  <th>Purchase</th>
                  <th>Dip Sales</th>
                  <th>Gun Sales</th>
                  <th>Testing Litres</th>
                  <th>Variation</th>
                </tr>
              </thead>
              <tbody>
                {dipSummary.map((val, key) => {
                  return (
                    <tr key={key}>
                      <td>{val.tank.product}</td>
                      <td>{val.tank.name}</td>
                      <td>{val.opening_stock.toFixed(2)}</td>
                      <td>{val.closing_stock.toFixed(2)}</td>
                      <td>{val.purchase.toFixed(2)}</td>
                      <td>{val.dip_sales.toFixed(2)}</td>
                      <td>{val.total_sale.toFixed(2)}</td>
                      <td> {val.testing_litters.toFixed(2)}</td>
                      <td>{val.variation.toFixed(2)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </main>
        </section>
      </>
    );
  }
}
