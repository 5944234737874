import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { DataBackUp } from '@C/DataBackUp/RouteComponents';

const RouteSetup = () => {
  return (
    <Switch>
      <Route path="/account/data-backup" component={DataBackUp} />
      <Route path="/account/data-backup/*">
        <Redirect to={`/account/data-backup`} />
      </Route>
    </Switch>
  );
};

export { RouteSetup };
