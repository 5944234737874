import React from 'react';
import { createRoot } from 'react-dom/client';

import Core from './core';

import './assets/scss/style.scss';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

// LogRocket.init('rdqekt/petrometer');

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Core />);
