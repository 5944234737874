import React from 'react';

import { Header, Navbar } from '@C';
import { CreditLedger } from '@C/CreditCustomerProfile/CreditLedger';
import ErrorBoundary from '@C/Error';

const CreditLedgerPage = () => {
  return (
    <main>
      <div className="page-wrapper">
        <Header title="Credit Ledger" />
        <Navbar />
        <div className="page-content">
          <section className="page-components">
            <ErrorBoundary>
              <CreditLedger />
            </ErrorBoundary>
          </section>
        </div>
      </div>
    </main>
  );
};

export { CreditLedgerPage };
