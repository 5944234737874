import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';

import { TableGrid } from '@C/TableGrid';

const col = [
  {
    name: 'Party name',
    key: '',
  },
  {
    name: 'Phone',
    key: '',
  },
  {
    name: 'Total outstanding amount',
    key: '',
  },
];

const LoanList = () => {
  const [state, setState] = useState({
    loanList: [],
    time_stamp: dayjs().format('YYYY-MM-DD'),
    limit: 20,
    offset: 1,
    count: 0,
    loading: true,
  });

  const limitInput = useRef();

  useEffect(() => {}, []);

  const handleDate = (e) => {
    setState({
      ...state,
      time_stamp: e.target.value,
    });
  };

  const { loanList, time_stamp, count } = state;

  return (
    <>
      <section className="widget full-width list-table">
        <header>
          <h3>Loan Parties List</h3>
        </header>
        <main>
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group">
                <label>Date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={time_stamp}
                  name="time_stamp"
                  onChange={handleDate}
                />
              </div>
            </div>
          </div>
          <main className="content-wrapper">
            <TableGrid col={col} row={loanList} count={count} />
          </main>
          <div className="mini-summary">
            <ul>
              <li>
                <span> Total outstanding balance: </span>
                <span>{/* Add the value here */}</span>
              </li>
            </ul>
          </div>
        </main>
      </section>
    </>
  );
};

export default LoanList;
