import React, { Component } from 'react';
import dayjs from 'dayjs';
import ExcelJS from 'exceljs';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

import services from '@C/Reports/_utils/data';
import { formatCurrency, InterFont, InterFontBold } from '@C/TableGrid';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { getBase64FromUrl } from '@C/Utils/ExportUtils';
import { authService } from '@S';

// import ExcelJS
// import FileSaver
import './styles.scoped.scss';

class PLReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter1: '',
      reportData: {},
      start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
      loading: true,
      data: null,
      exportingPdf: false,
      exportingExcel: false,
      otherExpense: [],
      otherIncome: [],
    };
  }

  componentDidMount() {
    this.getProfitLoss();
  }

  export2PDF = () => {
    // eslint-disable-next-line new-cap
    this.setState({ exportingPdf: true });
    const that = this;
    const doc = new jsPDF();
    const reportData = this.state.reportData;
    const start_date = this.state.start_date;
    const end_date = this.state.end_date;
    const expenseArr = [];
    const incomeArr = [];

    const exportOptions = {
      fileName: `Proft & Loss - ${start_date} to ${end_date} Report.pdf`,
      reportName: 'Profit & Loss Report',
      reportDate: `Date - ${dayjs(start_date).format('D MMM YY')} to ${dayjs(
        end_date,
      ).format('D MMM YY')}`,
    };
    reportData.trading_data.expense_particulars.fuel_opening_stock.map(
      (item) => {
        expenseArr.push([
          `Opening stock - ${item.product}`,
          formatCurrency(item.stock),
        ]);
      },
    );

    reportData.trading_data.income_particulars.fuel_closing_stock.map(
      (item) => {
        incomeArr.push([
          `Closing stock - ${item.product}`,
          formatCurrency(item.stock),
        ]);
      },
    );

    expenseArr.push([
      'Opening stock - Lubes',
      formatCurrency(
        reportData.trading_data.expense_particulars.lube_opening_stock,
      ),
    ]);
    expenseArr.push([
      'Total Fuel Purchase:',
      formatCurrency(reportData.trading_data.expense_particulars.fuel_purchase),
    ]);
    expenseArr.push([
      'Total Lubes Purchase:',
      formatCurrency(reportData.trading_data.expense_particulars.lube_purchase),
    ]);
    // expenseArr.push([
    //   'Total Purchases',
    //   formatCurrency(reportData.total_purchase),
    // ]);

    // expenseArr.push([
    //   'Gross Loss',
    //   formatCurrency(
    //     reportData.trading_gross_loss > 0 ? reportData.trading_gross_loss : 0,
    //   ),
    // ]);

    expenseArr.push(['Other Expenses', '']);
    reportData.expenses.map((item) => {
      expenseArr.push([item.name, formatCurrency(item.total)]);
    });

    expenseArr.push(['Tax paid', '']);

    reportData.tax_paid.map((item) => {
      expenseArr.push([item.name, formatCurrency(item.total)]);
    });

    expenseArr.push(['Salary', '']);

    reportData.salary.map((item) => {
      expenseArr.push([
        `${item.first_name} ${item.last_name}`,
        formatCurrency(item.total),
      ]);
    });
    // expenseArr.push([
    //   'Gross Expense',
    //   formatCurrency(reportData.gross_expenses),
    // ]);
    expenseArr.push([
      'Total Expenses',
      formatCurrency(reportData.total_expenses),
    ]);

    incomeArr.push([
      'Closing stock - Lubes',
      formatCurrency(
        reportData.trading_data.income_particulars.lube_closing_stock,
      ),
    ]);
    incomeArr.push([
      'Total Fuel Sales:',
      formatCurrency(reportData.trading_data.income_particulars.fuel_sale),
    ]);
    incomeArr.push([
      'Total Lubes Sales:',
      formatCurrency(reportData.trading_data.income_particulars.lube_sale),
    ]);

    // incomeArr.push(['Total Sales', formatCurrency(reportData.total_sales)]);

    // incomeArr.push([
    //   'Gross Profit',
    //   formatCurrency(
    //     reportData.trading_gross_profit > 0 ?
    //       reportData.trading_gross_profit :
    //       0,
    //   ),
    // ]);

    incomeArr.push(['Other Income', '']);

    reportData.income_particulars.map((item) => {
      if (item.name === 'v-hidden') {
        incomeArr.push(['', '']);
      } else {
        incomeArr.push([item.name, formatCurrency(item.total)]);
      }
    });

    incomeArr.push(['Tax Deductible', '']);

    reportData.tax_deductible.map((item) => {
      if (item.name === 'v-hidden') {
        incomeArr.push(['', '']);
      } else {
        incomeArr.push([item.name, formatCurrency(item.total)]);
      }
    });

    // incomeArr.push(['Gross Income', formatCurrency(reportData.gross_income)]);
    incomeArr.push(['Total Income', formatCurrency(reportData.total_income)]);

    const bunkLogo = getBase64FromUrl(authService.currentOMCWebLogo);
    const bunkAddress = authService.currentBunkAddress;

    bunkLogo.then((result) => {
      const img = new Image();
      img.onload = function () {
        doc.addFileToVFS('Inter-Regular.ttf', InterFont);
        doc.addFileToVFS('Inter-Bold-bold.ttf', InterFontBold);
        doc.addFont('Inter-Regular.ttf', 'Inter', 'normal');
        doc.addFont('Inter-Bold-bold.ttf', 'Inter', 'bold');
        doc.setFont('Inter');
        const aspectRatio = img.width / img.height;
        const width = 25;
        const height = width / aspectRatio;
        try {
          doc.addImage(result, 'PNG', 13, 10, width, height);
          autoTable(doc, {
            body: [
              [
                {
                  content: authService.currentBunkName,
                  styles: {
                    halign: 'right',
                    fontSize: 15,
                    textColor: '#000000',
                  },
                },
              ],
              [
                {
                  content:
                    bunkAddress.city +
                    ', ' +
                    bunkAddress.location +
                    ',' +
                    bunkAddress.country.country +
                    ' - ' +
                    bunkAddress.pincode,
                  styles: {
                    halign: 'right',
                    fontSize: 12,
                    textColor: '#000000',
                  },
                },
              ],
            ],
            theme: 'plain',
          });
          autoTable(doc, {
            body: [
              [
                {
                  content: 'Profit & Loss Report',
                  styles: {
                    halign: 'left',
                    fontSize: 15,
                    textColor: '#000000',
                    fontStyle: 'bold',
                  },
                },
                {
                  content: `${dayjs(start_date).format('D MMM YY')} to ${dayjs(
                    end_date,
                  ).format('D MMM YY')}`,
                  styles: {
                    halign: 'right',
                    fontSize: 12,
                    textColor: '#000000',
                    fontStyle: 'bold',
                  },
                },
              ],
            ],
            theme: 'plain',
          });
          doc.setFontSize(12);
          autoTable(doc, {
            head: [['Particulars', 'Expense']],
            body: expenseArr,
            theme: 'striped',
            startY: 55,
            headStyles: {
              fillColor: '#000000',
            },
            willDrawCell: function (data) {
              // if (
              //    data.cell.raw === 'Gross Loss' ||
              //   data.cell.raw === 'Gross Expense'
              // ) {
              //   doc.setFont('Inter', 'bold');
              //   doc.setTextColor('#ffffff'); // Red
              //   doc.setFillColor('#004474'); // Brand Blue

              //   data.row.cells[1].styles.fillColor = '#004474';
              //   data.row.cells[1].styles.textColor = '#ff0000';
              //   data.row.cells[1].styles.fontStyle = 'bold';
              // }

              if (
                // data.cell.raw === 'Total Purchases' ||
                data.cell.raw === 'Other Expenses' ||
                data.cell.raw === 'Tax paid' ||
                data.cell.raw === 'Salary' ||
                data.cell.raw === 'Taxes Paid' ||
                data.cell.raw === 'Total Expenses'
              ) {
                doc.setFont('Inter', 'bold');
                doc.setTextColor('#000000');

                data.row.cells[1].styles.textColor = '#000000';
                data.row.cells[1].styles.fontStyle = 'bold';
              }
            },
            styles: {
              font: 'Inter',
              fontStyle: 'normal',
              fontSize: 11,
            },
          });
          autoTable(doc, {
            head: [['Particulars', 'Income']],
            body: incomeArr,
            theme: 'striped',
            willDrawCell: function (data) {
              // if (
              //    data.cell.raw === 'Gross Profit' ||
              //   data.cell.raw === 'Gross Income'
              // ) {
              //   doc.setFont('Inter', 'bold');
              //   doc.setTextColor('#ffffff');
              //   doc.setFillColor('#004474'); // Brand Blue

              //   data.row.cells[1].styles.fillColor = '#004474';
              //   data.row.cells[1].styles.textColor = '#2dc096';
              //   data.row.cells[1].styles.fontStyle = 'bold';
              // }

              if (
                // data.cell.raw === 'Total Sales' ||
                data.cell.raw === 'Other Income' ||
                data.cell.raw === 'Tax Deductible' ||
                data.cell.raw === 'Total Income'
              ) {
                doc.setFont('Inter', 'bold');
                doc.setTextColor('#000000');

                data.row.cells[1].styles.textColor = '#000000';
                data.row.cells[1].styles.fontStyle = 'bold';
              }
            },
            headStyles: {
              fillColor: '#000000',
            },
            styles: {
              font: 'Inter',
              fontStyle: 'normal',
              fontSize: 11,
            },
          });
          autoTable(doc, {
            body: [
              [
                {
                  content: `${
                    reportData.gross_profit >= 0 ? 'Net Profit' : 'Net Loss'
                  }`,
                  styles: {
                    halign: 'left',
                    fontSize: 11,
                    textColor: '#ffffff',
                    fontStyle: 'bold',
                    font: 'Inter',
                  },
                },
                {
                  content: `${formatCurrency(
                    reportData.gross_profit >= 0
                      ? reportData.gross_profit
                      : reportData.gross_loss,
                  )}`,
                  styles: {
                    halign: 'right',
                    fontSize: 11,
                    textColor: '#ffffff',
                    fontStyle: 'bold',
                    font: 'Inter',
                  },
                },
              ],
            ],
            theme: 'plain',
            styles: {
              fillColor: `${
                reportData.gross_profit >= 0 ? '#2dc096' : '#ff0000'
              }`,
            },
          });
          doc.save(exportOptions.fileName);
          that.setState({ exportingPdf: false });
        } catch (e) {
          console.log(e);
        }
      };
      img.src = result;
    });
  };

  export2Excel = () => {
    this.setState({ exportingExcel: true });
    const that = this;
    // use exceljs to export data to excel
    const reportData = this.state.reportData;
    const start_date = this.state.start_date;
    const end_date = this.state.end_date;
    const expenseArr = [];
    const incomeArr = [];
    const tradingExpenseArr = [];
    const tradingIncomeArr = [];
    const salaryArr = [];
    const taxExpenseArr = [];
    const taxIncomeArr = [];

    this.state.otherExpense.map((item) => {
      if (item.name == 'v-hidden') {
        expenseArr.push(['', '']);
      } else {
        expenseArr.push([item.name, formatCurrency(item.total)]);
      }
    });

    this.state.otherIncome.map((item) => {
      if (item.name == 'v-hidden') {
        incomeArr.push(['', '']);
      } else {
        incomeArr.push([item.name, formatCurrency(item.total)]);
      }
    });

    // merge expense and income arrays so that they can be added to worksheet in the same row
    const mergedArr = expenseArr.map(function (e, i) {
      return [e[0], e[1], incomeArr[i][0], incomeArr[i][1]];
    });

    reportData.salary.map((item) => {
      salaryArr.push([
        `${item.first_name} ${item.last_name}`,
        formatCurrency(item.total),
      ]);
    });

    reportData.tax_paid.map((item) => {
      taxExpenseArr.push([`${item.name}`, formatCurrency(item.total)]);
    });

    reportData.tax_deductible.map((item) => {
      taxIncomeArr.push([`${item.name}`, formatCurrency(item.total)]);
    });

    reportData.trading_data.expense_particulars.fuel_opening_stock.map(
      (item) => {
        tradingExpenseArr.push([
          `Opening stock - ${item.product}`,
          formatCurrency(item.stock),
        ]);
      },
    );

    reportData.trading_data.income_particulars.fuel_closing_stock.map(
      (item) => {
        tradingIncomeArr.push([
          `Closing stock - ${item.product}`,
          formatCurrency(item.stock),
        ]);
      },
    );

    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('P&L Report');
      worksheet.getRow(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '000000' },
      };
      worksheet.getRow(1).font = {
        color: { argb: 'ffffff' },
      };
      worksheet.columns = [
        { header: 'Particulars', key: 'particulars', width: 30 },
        { header: 'Expense', key: 'expense', width: 30 },
        { header: 'Particulars', key: 'particulars', width: 30 },
        { header: 'Income', key: 'income', width: 30 },
      ];

      tradingExpenseArr.map((item, index) => {
        worksheet.addRow([item[0], item[1], '', '']);
      });

      tradingIncomeArr.map((item, index) => {
        worksheet.getRow(index + 2).getCell(3).value = item[0];
        worksheet.getRow(index + 2).getCell(4).value = item[1];
      });

      worksheet.addRow([
        'Opening stock - Lubes',
        formatCurrency(
          reportData.trading_data.expense_particulars.lube_opening_stock,
        ),
        'Closing stock - Lubes',
        formatCurrency(
          reportData.trading_data.income_particulars.lube_closing_stock,
        ),
      ]);

      worksheet.addRow([
        'Total Fuel Purchase:',
        formatCurrency(
          reportData.trading_data.expense_particulars.fuel_purchase,
        ),
        'Total Fuel Sales:',
        formatCurrency(reportData.trading_data.income_particulars.fuel_sale),
      ]);
      worksheet.addRow([
        'Total Lubes Purchase:',
        formatCurrency(
          reportData.trading_data.expense_particulars.lube_purchase,
        ),
        'Total Lubes Sales:',
        formatCurrency(reportData.trading_data.income_particulars.lube_sale),
      ]);

      let customRow = worksheet.addRow([
        // 'Total Purchase:',
        // formatCurrency(reportData.total_purchase),
        // 'Total Sales:',
        // formatCurrency(reportData.total_sales),
      ]);

      // customRow.eachCell(function(cell) {
      //   cell.fill = {
      //     type: 'pattern',
      //     pattern: 'solid',
      //     fgColor: { argb: 'd3d3d3' },
      //   };
      //   cell.font = {
      //     color: { argb: '000000' },
      //     bold: true,
      //   };
      // });

      // customRow = worksheet.addRow([
      //   'Gross Loss:',
      //   formatCurrency(
      //     reportData.trading_gross_loss > 0 ? reportData.trading_gross_loss : 0,
      //   ),
      //   'Gross Profit:',
      //   formatCurrency(
      //     reportData.trading_gross_profit > 0 ?
      //       reportData.trading_gross_profit :
      //       0,
      //   ),
      // ]);

      customRow.eachCell(function (cell) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '004474' },
        };

        if (cell._column.number == 2) {
          cell.font = {
            color: { argb: 'ff0000' },
            bold: true,
          };
        } else if (cell._column.number == 4) {
          cell.font = {
            color: { argb: '2dc096' },
            bold: true,
          };
        } else {
          cell.font = {
            color: { argb: 'ffffff' },
            bold: true,
          };
        }
      });

      customRow = worksheet.addRow(['Other Expense', '', 'Other Income', '']);

      customRow.eachCell(function (cell) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '000000' },
        };
        cell.font = {
          color: { argb: 'ffffff' },
          bold: true,
        };
      });

      mergedArr.map((item, index) => {
        worksheet.addRow([item[0], item[1], item[2], item[3]]);
      });

      customRow.eachCell(function (cell) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '004474' },
        };

        if (cell._column.number == 2) {
          cell.font = {
            color: { argb: 'ff0000' },
            bold: true,
          };
        } else if (cell._column.number == 4) {
          cell.font = {
            color: { argb: '2dc096' },
            bold: true,
          };
        } else {
          cell.font = {
            color: { argb: 'ffffff' },
            bold: true,
          };
        }
      });

      // customRow = worksheet.addRow(['Tax paid Expenses', '', 'Tax Paid Incomes', '']);

      // customRow.eachCell(function(cell) {
      //   cell.fill = {
      //     type: 'pattern',
      //     pattern: 'solid',
      //     fgColor: { argb: '000000' },
      //   };
      //   cell.font = {
      //     color: { argb: 'ffffff' },
      //     bold: true,
      //   };
      // });

      // taxExpenseArr.map((item, index) => {
      //   worksheet.addRow([item[0], item[1], '', '']);
      // });
      // taxIncomeArr.map((item, index) => {
      //   worksheet.addRow([item[0], item[1], '', '']);
      // });

      const mergedTaxArr = taxExpenseArr.map((item, index) => [
        item[0],
        item[1],
        taxIncomeArr[index] ? taxIncomeArr[index][0] : '',
        taxIncomeArr[index] ? taxIncomeArr[index][1] : '',
      ]);

      customRow = worksheet.addRow(['Tax Paid ', '', 'Tax Deductible', '']);

      customRow.eachCell(function (cell) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '000000' },
        };
        cell.font = {
          color: { argb: 'ffffff' },
          bold: true,
        };
      });

      mergedTaxArr.map((item) => {
        worksheet.addRow([item[0], item[1], item[2], item[3]]);
      });

      customRow = worksheet.addRow(['Salary', '', '', '']);

      customRow.eachCell(function (cell) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '000000' },
        };
        cell.font = {
          color: { argb: 'ffffff' },
          bold: true,
        };
      });

      salaryArr.map((item, index) => {
        worksheet.addRow([item[0], item[1], '', '']);
      });

      // customRow = worksheet.addRow([
      //   'Gross Expense:',
      //   formatCurrency(
      //     reportData.gross_expenses > 0 ? reportData.gross_expenses : 0,
      //   ),
      //   'Gross Income:',
      //   formatCurrency(
      //     reportData.gross_income > 0 ?
      //       reportData.gross_income :
      //       0,
      //   ),
      // ]);

      customRow.eachCell(function (cell) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '004474' },
        };

        if (cell._column.number == 2) {
          cell.font = {
            color: { argb: 'ff0000' },
            bold: true,
          };
        } else if (cell._column.number == 4) {
          cell.font = {
            color: { argb: '2dc096' },
            bold: true,
          };
        } else {
          cell.font = {
            color: { argb: 'ffffff' },
            bold: true,
          };
        }
      });

      customRow = worksheet.addRow([
        'Total Expense:',
        formatCurrency(reportData.total_expenses),
        'Total Income:',
        formatCurrency(reportData.total_income),
      ]);

      customRow.eachCell(function (cell) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'd3d3d3' },
        };
        cell.font = {
          color: { argb: '000000' },
          bold: true,
        };
      });

      customRow = worksheet.addRow([
        'Net Loss:',
        formatCurrency(reportData.gross_loss > 0 ? reportData.gross_loss : 0),
        'Net Profit:',
        formatCurrency(
          reportData.gross_profit > 0 ? reportData.gross_profit : 0,
        ),
      ]);

      customRow.eachCell(function (cell) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '004474' },
        };

        if (cell._column.number == 2) {
          cell.font = {
            color: { argb: 'ff0000' },
            bold: true,
          };
        } else if (cell._column.number == 4) {
          cell.font = {
            color: { argb: '2dc096' },
            bold: true,
          };
        } else {
          cell.font = {
            color: { argb: 'ffffff' },
            bold: true,
          };
        }
      });

      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `Profit & Loss Report - ${start_date} to ${end_date}.xlsx`,
        );
        that.setState({ exportingExcel: false });
      });
    } catch (e) {
      console.log(e);
    }
  };

  getProfitLoss = () => {
    services
      .getPLReport(this.state)
      .then((response) => {
        this.setState({
          reportData: response.data.data,
        });
        const otherExpense = response.data.data.expenses;
        const otherIncome = response.data.data.income_particulars;

        // compare length of income and expense and add empty objects to make them equal
        if (otherExpense.length > otherIncome.length) {
          const diff = otherExpense.length - otherIncome.length;
          for (let i = 0; i < diff; i++) {
            otherIncome.push({ name: 'v-hidden', total: '' });
          }
        }
        if (otherExpense.length < otherIncome.length) {
          const diff = otherIncome.length - otherExpense.length;
          for (let i = 0; i < diff; i++) {
            otherExpense.push({ name: 'v-hidden', total: '' });
          }
        }
        this.setState({
          otherExpense: otherExpense,
          otherIncome: otherIncome,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  changeFilter = (e) => {
    this.setState({ filter1: e });
  };

  handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date' && dayjs(value).isAfter(this.state.end_date)) {
      this.setState({ [name]: value, end_date: value });
    } else if (
      name === 'end_date' &&
      dayjs(value).isBefore(this.state.start_date)
    ) {
      this.setState({ [name]: value, start_date: value });
    } else {
      this.setState({ [name]: value });
    }
  };

  resetFilter = () => {
    this.setState(
      {
        start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
        loading: true,
      },
      function () {
        this.getProfitLoss();
      },
    );
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, function () {
      this.getProfitLoss();
    });
  };

  render() {
    const { loading, reportData, otherExpense, otherIncome } = this.state;
    return (
      <section className="widget full-width list-table inverted">
        <header>
          <h3>Profit & Loss Report</h3>
        </header>
        <main>
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group">
                <label>From date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.start_date}
                  name="start_date"
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="filter-group">
                <label>To date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.end_date}
                  min={this.state.start_date}
                  onChange={this.handleDateChange}
                  name="end_date"
                />
              </div>
              <div className="filter-group reset-filter">
                <button onClick={this.resetFilter}>Reset filters</button>
              </div>
              <div className="filter-group">
                <button
                  className="submit"
                  type="submit"
                  onClick={this.submitForm}
                >
                  Apply filter
                </button>
              </div>
            </div>
          </div>
          <div className="tg-wrapper">
            {(() => {
              if (this.state.loading) {
                return <DropLoaderLarge />;
              } else {
                return (
                  <>
                    <div className="export-btns">
                      <button
                        onClick={this.export2Excel}
                        className={`blue-btn btn-with-icon btn-with-icon__excel ${
                          this.state.exportingExcel ? 'loading' : ''
                        }`}
                      >
                        Export to Excel
                      </button>
                      <button
                        onClick={this.export2PDF}
                        className={`blue-btn btn-with-icon btn-with-icon__pdf ${
                          this.state.exportingPdf ? 'loading' : ''
                        }`}
                      >
                        Export to PDF
                      </button>
                    </div>
                    <div className="table-wrapper">
                      <table>
                        <thead>
                          <tr
                            className="tg-header"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <th className="th-cell">
                              <span>Expenses</span>
                            </th>
                            <th className="th-cell">
                              <span>Amount</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {reportData.trading_data.expense_particulars.fuel_opening_stock.map(
                            (item, i) => (
                              <tr
                                key={i}
                                className="tg-body"
                                style={{ borderBottom: '0px solid white' }}
                              >
                                <td className="tg-cell">
                                  <span>{`Opening Stock - ${
                                    item.product || ''
                                  }`}</span>
                                </td>
                                <td className="tg-cell">
                                  <span>{`${formatCurrency(
                                    item.stock || 0,
                                  )}`}</span>
                                </td>
                              </tr>
                            ),
                          )}
                          <tr className="tg-body">
                            <td className="tg-cell">
                              <span>{`Opening Stock - Lubes:`}</span>
                            </td>
                            <td className="tg-cell">
                              <span>{`${formatCurrency(
                                reportData.trading_data.expense_particulars
                                  .lube_opening_stock,
                              )}`}</span>
                            </td>
                          </tr>
                          <tr className="tg-body">
                            <td className="tg-cell">
                              <span>{`Total Fuel Purchase:`}</span>
                            </td>
                            <td className="tg-cell">
                              <span>{`${formatCurrency(
                                reportData.trading_data.expense_particulars
                                  .fuel_purchase,
                              )}`}</span>
                            </td>
                          </tr>
                          <tr className="tg-body">
                            <td className="tg-cell">
                              <span>{`Total Lubes Purchase:`}</span>
                            </td>
                            <td className="tg-cell">
                              <span>{`${formatCurrency(
                                reportData.trading_data.expense_particulars
                                  .lube_purchase,
                              )}`}</span>
                            </td>
                          </tr>
                          {/* <tr
                            className="tg-body"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <td className="tg-cell --bold">
                              <span>Total Purchase</span>
                            </td>
                            <td className="tg-cell">
                              <span className="--bold">
                                {formatCurrency(reportData.total_purchase)}
                              </span>
                            </td>
                          </tr> */}
                          {/* <tr
                            className="tg-body"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <td className="tg-cell --bold alert-brand">
                              <span className="--white">Gross Loss</span>
                            </td>
                            <td className="tg-cell alert-brand">
                              <span className="--red --bold">
                                {formatCurrency(reportData.trading_gross_loss < 0 ? 0 : reportData.trading_gross_loss)}
                              </span>
                            </td>
                          </tr> */}

                          <tr
                            className="tg-body"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <td className="tg-cell --bold">
                              <span>Other Expense</span>
                            </td>
                            <td className="tg-cell --bold">
                              <span />
                            </td>
                          </tr>
                          {otherExpense.map((item, i) => (
                            <tr
                              key={i}
                              className={`tg-body ${
                                item.name !== 'v-hidden' ? '' : 'v-hidden'
                              }`}
                              style={{ borderBottom: '0px solid white' }}
                            >
                              <td className="tg-cell">
                                <span>{`${item.name || ''}`}</span>
                              </td>
                              <td className="tg-cell">
                                <span>
                                  {item.total >= 0
                                    ? `${formatCurrency(item.total || 0)}`
                                    : ''}
                                </span>
                              </td>
                            </tr>
                          ))}
                          <tr
                            className="tg-body"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <td className="tg-cell --bold">
                              <span>Tax Paid </span>
                            </td>
                            <td className="tg-cell --bold">
                              <span />
                            </td>
                          </tr>
                          {reportData.tax_paid.map((item, i) => (
                            <tr
                              key={i}
                              className={`tg-body ${
                                item.name !== 'v-hidden' ? '' : 'v-hidden'
                              }`}
                              style={{ borderBottom: '0px solid white' }}
                            >
                              <td className="tg-cell">
                                <span>{`${item.name || ''}`}</span>
                              </td>
                              <td className="tg-cell">
                                <span>{`${formatCurrency(
                                  item.total || 0,
                                )}`}</span>
                              </td>
                            </tr>
                          ))}
                          <tr
                            className="tg-body"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <td className="tg-cell --bold">
                              <span>Salary</span>
                            </td>
                            <td className="tg-cell --bold">
                              <span />
                            </td>
                          </tr>
                          {reportData.salary.map((item, i) => (
                            <tr
                              key={i}
                              className="tg-body"
                              style={{ borderBottom: '0px solid white' }}
                            >
                              <td className="tg-cell">
                                <span>{`${item.first_name} ${item.last_name}`}</span>
                              </td>
                              <td className="tg-cell">
                                <span>{`${formatCurrency(
                                  item.total || 0,
                                )}`}</span>
                              </td>
                            </tr>
                          ))}
                          {/* <tr
                            className="tg-body"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <td className="tg-cell --bold alert-brand">
                              <span className="--white">Gross Expenses</span>
                            </td>
                            <td className="tg-cell alert-brand">
                              <span className="--bold --red">
                                {formatCurrency(reportData.gross_expenses)}
                              </span>
                            </td>
                          </tr> */}
                          <tr
                            className="tg-body"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <td className="tg-cell --bold">
                              <span>Total Expenses</span>
                            </td>
                            <td className="tg-cell">
                              <span className="--red --bold">
                                {formatCurrency(reportData.total_expenses)}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr className="tg-footer">
                            <th className="tg-cell alert-brand">
                              <span className="--white">{`Net loss`}</span>
                            </th>
                            <th className="tg-cell alert-brand">
                              <span className="--red">{`${formatCurrency(
                                reportData.gross_loss > 0
                                  ? reportData.gross_loss
                                  : 0,
                              )}`}</span>
                            </th>
                          </tr>
                        </tfoot>
                      </table>

                      <table>
                        <thead>
                          <tr
                            className="tg-header"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <th className="th-cell">
                              <span>Income</span>
                            </th>
                            <th className="th-cell">
                              <span>Amount</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {reportData.trading_data.income_particulars.fuel_closing_stock.map(
                            (item, i) => (
                              <tr
                                key={i}
                                className="tg-body"
                                style={{ borderBottom: '0px solid white' }}
                              >
                                <td className="tg-cell">
                                  <span>{`Closing Stock - ${
                                    item.product || ''
                                  }`}</span>
                                </td>
                                <td className="tg-cell">
                                  <span>{`${formatCurrency(
                                    item.stock || 0,
                                  )}`}</span>
                                </td>
                              </tr>
                            ),
                          )}

                          <tr className="tg-body">
                            <td className="tg-cell">
                              <span>{`Closing Stock - Lubes:`}</span>
                            </td>
                            <td className="tg-cell">
                              <span>{`${formatCurrency(
                                reportData.trading_data.income_particulars
                                  .lube_closing_stock,
                              )}`}</span>
                            </td>
                          </tr>
                          <tr className="tg-body">
                            <td className="tg-cell">
                              <span>{`Total Fuel Sales:`}</span>
                            </td>
                            <td className="tg-cell">
                              <span>{`${formatCurrency(
                                reportData.trading_data.income_particulars
                                  .fuel_sale,
                              )}`}</span>
                            </td>
                          </tr>
                          <tr className="tg-body">
                            <td className="tg-cell">
                              <span>{`Total Lubes Sales:`}</span>
                            </td>
                            <td className="tg-cell">
                              <span>{`${formatCurrency(
                                reportData.trading_data.income_particulars
                                  .lube_sale,
                              )}`}</span>
                            </td>
                          </tr>
                          {/* <tr
                            className="tg-body"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <td className="tg-cell --bold">
                              <span>Total Sales</span>
                            </td>
                            <td className="tg-cell">
                              <span className="--bold">
                                {formatCurrency(reportData.total_sales)}
                              </span>
                            </td>
                          </tr> */}
                          {/* <tr
                            className="tg-body"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <td className="tg-cell --bold alert-brand">
                              <span className="--white">Gross Profit</span>
                            </td>
                            <td className="tg-cell alert-brand">
                              <span className="--bold --green">
                                {formatCurrency(
                                  reportData.trading_gross_profit < 0 ? 0 : reportData.trading_gross_profit,
                                )}
                              </span>
                            </td>
                          </tr> */}
                          <tr
                            className="tg-body"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <td className="tg-cell --bold">
                              <span>Other Income</span>
                            </td>
                            <td className="tg-cell">
                              <span />
                            </td>
                          </tr>
                          {otherIncome.map((item, i) => (
                            <tr
                              key={i}
                              className={`tg-body ${
                                item.name !== 'v-hidden' ? '' : 'v-hidden'
                              }`}
                              style={{ borderBottom: '0px solid white' }}
                            >
                              <td className="tg-cell">
                                <span>{`${item.name
                                  .replace(/_/g, ' ')
                                  .replace(/\w\S*/g, function (txt) {
                                    return (
                                      txt.charAt(0).toUpperCase() +
                                      txt.substr(1).toLowerCase()
                                    );
                                  })}`}</span>
                              </td>
                              <td className="tg-cell">
                                <span>
                                  {item.total >= 0
                                    ? `${formatCurrency(item.total || 0)}`
                                    : ''}
                                </span>
                              </td>
                            </tr>
                          ))}
                          <tr
                            className="tg-body"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <td className="tg-cell --bold">
                              <span>Tax Deductible</span>
                            </td>
                            <td className="tg-cell --bold">
                              <span />
                            </td>
                          </tr>
                          {reportData.tax_deductible.map((item, i) => (
                            <tr
                              key={i}
                              className={`tg-body ${
                                item.name !== 'v-hidden' ? '' : 'v-hidden'
                              }`}
                              style={{ borderBottom: '0px solid white' }}
                            >
                              <td className="tg-cell">
                                <span>{`${item.name || ''}`}</span>
                              </td>
                              <td className="tg-cell">
                                <span>{`${formatCurrency(
                                  item.total || 0,
                                )}`}</span>
                              </td>
                            </tr>
                          ))}
                          <tr
                            className="tg-body v-hidden"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <td className="tg-cell --bold">
                              <span>Salary</span>
                            </td>
                            <td className="tg-cell --bold">
                              <span />
                            </td>
                          </tr>
                          {reportData.salary.map((item, i) => (
                            <tr
                              key={i}
                              className="tg-body v-hidden"
                              style={{ borderBottom: '0px solid white' }}
                            >
                              <td className="tg-cell">
                                <span>{`${item.first_name} ${item.last_name}`}</span>
                              </td>
                              <td className="tg-cell">
                                <span>{`${formatCurrency(
                                  item.total || 0,
                                )}`}</span>
                              </td>
                            </tr>
                          ))}
                          {/* <tr
                            className="tg-body"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <td className="tg-cell --bold alert-brand">
                              <span className="--white">Gross Income</span>
                            </td>
                            <td className="tg-cell alert-brand">
                              <span className="--bold --green">
                                {formatCurrency(reportData.gross_income)}
                              </span>
                            </td>
                          </tr> */}
                          <tr
                            className="tg-body"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <td className="tg-cell --bold">
                              <span>Total Income</span>
                            </td>
                            <td className="tg-cell">
                              <span className="--green --bold">
                                {formatCurrency(reportData.total_income)}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr className="tg-footer">
                            <th className="tg-cell alert-brand">
                              <span className="--white">{`Net profit`}</span>
                            </th>
                            <th className="tg-cell alert-brand">
                              <span className="--green">{`${formatCurrency(
                                reportData.gross_profit >= 0
                                  ? reportData.gross_profit
                                  : 0,
                              )}`}</span>
                            </th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </>
                );
              }
            })()}
          </div>
        </main>
      </section>
    );
  }
}

export { PLReport };
