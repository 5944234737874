import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import services from '@C/ManageBunk/RouteComponents/OpeningBalanceSetup/data';
import { formatCurrency, TableGrid } from '@C/TableGrid';
import { AccessControl } from '@C/Utils';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { authHeader } from '@H';

import './styles.scoped.scss';

const col = [
  {
    name: 'Date',
    key: 'dsr_date',
    type: 'date',
  },
  {
    name: 'Opening balance',
    key: 'all',
    formatter(props) {
      return <>{formatCurrency(props.opening_balance)}</>;
    },
  },
  {
    name: 'Closing balance',
    key: 'all',
    formatter(props) {
      return <>{formatCurrency(props.closing_balance)}</>;
    },
  },
];

class OpeningCashBalance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opening_balance: '',
      time_stamp: dayjs().format('YYYY-MM-DD'),
      is_submitting: false,
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
    this.myRef = React.createRef();
  }

  handleDate = (e) => {
    this.setState({ time_stamp: e.target.value });
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitForm = (e) => {
    e.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState({ is_submitting: true });
    const formData = this.state;
    const headers = authHeader(1);
    axios
      .post(`${process.env.API_URL}/payment/setup-cashbook/`, formData, {
        headers,
      })
      .then(() => {
        toast.success('Opening cash balance added successfully');
        this.setState({
          opening_balance: '',
          time_stamp: dayjs().format('YYYY-MM-DD'),
          updated: !this.state.updated,
          is_submitting: false,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.error);
        this.setState({ is_submitting: false });
      });
  };

  render() {
    return (
      <>
        <AccessControl allowedRoles={['Dealer']}>
          <section className="widget full-width">
            <header>
              <h3> Setting Opening Cash Balance</h3>
            </header>
            <main>
              <form className="inline">
                <div className="input-wrapper with-label">
                  <label>Date</label>
                  <input
                    type="date"
                    value={this.state.time_stamp}
                    name="time_stamp"
                    onChange={this.handleDate}
                  />
                </div>
                <div className="input-wrapper with-label">
                  <label>Amount</label>
                  {this.validator.message(
                    'opening_balance',
                    this.state.opening_balance,
                    'required|numeric',
                  )}
                  <input
                    type="text"
                    value={this.state.opening_balance}
                    name="opening_balance"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-actions">
                  <button
                    type="submit"
                    className={`submit ${this.state.is_submitting ? 'loading' : ''}`}
                    onClick={this.submitForm}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </main>
          </section>
        </AccessControl>
        <OpeningBalanceList key={this.state.updated} />
      </>
    );
  }
}

class OpeningBalanceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openingBalanceList: {},
      loading: true,
    };
  }

  componentDidMount() {
    this.getCashBookBalance();
  }

  getCashBookBalance = () => {
    services
      .getCashBookBalance()
      .then((response) => {
        let tmp = [];
        if (response.data?.data) {
          tmp.push({
            dsr_date: response.data?.data?.dsr_date,
            opening_balance: response?.data?.data?.opening_balance || '',
            closing_balance: response.data?.data?.closing_balance || '',
          });
        } else {
          tmp = [];
        }

        this.setState({
          openingBalanceList: tmp,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    const { openingBalanceList } = this.state;

    return (
      <>
        <section className="widget full-width list-table">
          <header>
            <h3> Opening Balance List </h3>
          </header>
          <main className="list-content">
            <div className="table-wrapper --full">
              {(() => {
                if (this.state.loading) {
                  return <DropLoaderLarge />;
                } else {
                  return (
                    <>
                      <TableGrid
                        col={col}
                        row={openingBalanceList}
                        hideActions={true}
                        pagination="disabled"
                      />
                    </>
                  );
                }
              })()}
            </div>
          </main>
        </section>
      </>
    );
  }
}
export { OpeningCashBalance };
