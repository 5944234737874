import React from 'react';

import { useGlobalStore } from '@stores/globalStore';

import SalesSummary from './SalesSummary';

const FuelSummary = (props) => {
  const { syncCODCollectionData } = useGlobalStore((state) => ({
    syncCODCollectionData: state.syncCODCollectionData,
  }));

  const updateTotalSummary = () => {
    syncCODCollectionData(props.match.params.duty, props.match.params.staff);
  };

  return <SalesSummary {...props} onDataChange={updateTotalSummary} />;
};

export { FuelSummary };
