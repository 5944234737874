import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import Select from 'react-select';

import {
  BalanceSheet,
  BankLedger,
  BusinessSummaryReport,
  CashBookReport,
  CashBookShiftWiseReport,
  CashierShortageReport,
  CreditSales,
  CreditSalesBowser,
  CumulativeCreditReport,
  CustomerLedger,
  DenominationReport,
  ExpenseReport,
  FuelPriceReport,
  FuelPurchaseReport,
  FuelsSalesReport,
  GSTExpenseReport,
  GSTIncomeReport,
  GSTRReport,
  IncomeReport,
  LubePriceReport,
  LubesPurchase,
  LubesSales,
  MonthlyReport,
  PLReport,
  PLTAReport,
  POSLedger,
  ProfitMargin,
  SalesSummaryReport,
  TemporaryCreditLedger,
  VendorPayment,
  WalletLedger,
  VendorLedger,
} from './RouteComponents';

const RouteSetup = () => {
  const history = useHistory();
  const [selectedReport, setSelectedReport] = React.useState('');

  const env = process.env.ENVIRONMENT;

  const options = [
    {
      value: 'bank-ledger',
      label: 'Bank Ledger',
    },
    {
      value: 'credit-sales',
      label: 'Credit Sales',
    },
    {
      value: 'credit-customer-ledger',
      label: 'Credit Customer Ledger',
    },
    {
      value: 'expense-report',
      label: 'Expense Report',
    },
    {
      value: 'income-report',
      label: 'Income Report',
    },
    {
      value: 'lubes-sales',
      label: ' Lube / Products Sales',
    },
    {
      value: 'lubes-purchase',
      label: 'Lube / Products Purchases',
    },
    {
      value: 'monthly-sales-report',
      label: 'Monthly Sales Report',
    },
    {
      value: 'temporary-credit-ledger',
      label: 'Temporary Credit Ledger',
    },
    {
      value: 'profit-loss-report',
      label: 'Profit & Loss Report',
    },
    {
      value: 'profit-loss-trading-account',
      label: 'Profit & Loss Trading Account',
    },
    {
      value: 'fuel-price',
      label: 'Fuel Price History',
    },
    {
      value: 'fuel-purchase',
      label: 'Fuel Purchase Report',
    },
    {
      value: 'wallet-ledger',
      label: 'Wallet Ledger',
    },
    {
      value: 'POS-ledger',
      label: 'POS Ledger',
    },
    {
      value: 'cashier-shortage',
      label: 'Cashier Shortage Report',
    },
    {
      value: 'cash-book',
      label: 'Cash Book Report',
    },
    {
      value: 'fuels-sales-report',
      label: 'Fuels Sales Report',
    },
    {
      value: 'cash-book-shiftwise',
      label: 'Cash Book Shift Wise Report',
    },
    {
      value: 'lube-price',
      label: 'Lube / Products Price',
    },
    {
      value: 'gst-income-report',
      label: 'GST Income Report',
    },
    {
      value: 'gst-expense-report',
      label: 'GST Expense Report',
    },
    {
      value: 'gstr-3b-report',
      label: 'GSTR 3B Report',
      environment: ['development'],
    },
    {
      value: 'sales-summary',
      label: 'Sales Summary Report',
      environment: ['development'],
    },
    {
      value: 'denomination-report',
      label: 'Denomination Report',
    },
    {
      value: 'business-summary-report',
      label: 'Business Summary Report',
    },
    {
      value: 'balance-sheet-report',
      label: 'Balance Sheet Report',
    },
    {
      value: 'vendor-payment-report',
      label: 'Vendor Payment Report',
    },
    {
      value: 'cumulative-credit-report',
      label: 'Cumulative Credit Report',
    },
    {
      value: 'profit-margin',
      label: 'Profit Margin',
    },
    {
      value: 'vendor-ledger-report',
      label: 'Vendor Ledger Report',
      environment: ['development'],
    },
    {
      value: 'credit-sales-bowser-report',
      label: 'Credit Sales - Bowser',
    },
  ];

  const filteredOptions = options.filter((option) => {
    // If the 'environment' field is present for an option, check if 'env' is in the array
    if (option.environment && option.environment.includes(env)) {
      return true;
    }
    // If the 'environment' field is not present, display the option
    return !option.environment;
  });

  const handleChange = (selectedOption) => {
    // push history to selected option
    history.push(`/account/reports/${selectedOption.value}`);
  };

  // useEffect to set dropdown value to current route
  useEffect(() => {
    const currentRoute = history.location.pathname.split('/')[3];
    setSelectedReport(currentRoute);
  }, [history.location.pathname]);

  return (
    <>
      <section className="widget full-width">
        <header>
          <h3> Select Report </h3>{' '}
        </header>{' '}
        <main>
          <div className="input-wrapper">
            <Select
              onChange={handleChange}
              options={filteredOptions}
              className="select-box-container report-selector"
              placeholder="Select report"
              value={filteredOptions.filter(
                ({ value }) => value === selectedReport,
              )}
            />{' '}
          </div>{' '}
        </main>{' '}
      </section>{' '}
      <Switch>
        <Route path="/account/reports/bank-ledger" component={BankLedger} />{' '}
        <Route path="/account/reports/credit-sales" component={CreditSales} />{' '}
        <Route
          path="/account/reports/credit-customer-ledger"
          component={CustomerLedger}
        />{' '}
        <Route
          path="/account/reports/expense-report"
          component={ExpenseReport}
        />{' '}
        <Route path="/account/reports/income-report" component={IncomeReport} />{' '}
        <Route
          path="/account/reports/lubes-purchase"
          component={LubesPurchase}
        />{' '}
        <Route path="/account/reports/lubes-sales" component={LubesSales} />{' '}
        <Route
          path="/account/reports/monthly-sales-report"
          component={MonthlyReport}
        />{' '}
        <Route
          path="/account/reports/temporary-credit-ledger"
          component={TemporaryCreditLedger}
        />{' '}
        <Route
          path="/account/reports/profit-loss-report"
          component={PLReport}
        />{' '}
        <Route
          path="/account/reports/profit-loss-trading-account"
          component={PLTAReport}
        />{' '}
        <Route path="/account/reports/fuel-price" component={FuelPriceReport} />{' '}
        <Route
          path="/account/reports/fuel-purchase"
          component={FuelPurchaseReport}
        />{' '}
        <Route path="/account/reports/wallet-ledger" component={WalletLedger} />{' '}
        <Route path="/account/reports/pos-ledger" component={POSLedger} />{' '}
        <Route
          path="/account/reports/cashier-shortage"
          component={CashierShortageReport}
        />{' '}
        <Route path="/account/reports/cash-book" component={CashBookReport} />{' '}
        <Route
          path="/account/reports/sales-summary"
          component={SalesSummaryReport}
        />{' '}
        <Route
          path="/account/reports/fuels-sales-report"
          component={FuelsSalesReport}
        />{' '}
        <Route
          path="/account/reports/cash-book-shiftwise"
          component={CashBookShiftWiseReport}
        />{' '}
        <Route path="/account/reports/lube-price" component={LubePriceReport} />{' '}
        <Route
          path="/account/reports/gst-income-report"
          component={GSTIncomeReport}
        />{' '}
        <Route
          path="/account/reports/gst-expense-report"
          component={GSTExpenseReport}
        />{' '}
        <Route path="/account/reports/gstr-3b-report" component={GSTRReport} />{' '}
        */
        <Route
          path="/account/reports/denomination-report"
          component={DenominationReport}
        />{' '}
        <Route
          path="/account/reports/business-summary-report"
          component={BusinessSummaryReport}
        />{' '}
        <Route
          path="/account/reports/balance-sheet-report"
          component={BalanceSheet}
        />{' '}
        <Route
          path="/account/reports/vendor-payment-report"
          component={VendorPayment}
        />{' '}
        <Route
          path="/account/reports/cumulative-credit-report"
          component={CumulativeCreditReport}
        />{' '}
        <Route path="/account/reports/profit-margin" component={ProfitMargin} />{' '}
        <Route path="/account/reports/vendor-ledger-report" component={VendorLedger} />{' '}
        <Route path="/account/reports/credit-sales-bowser-report" component={CreditSalesBowser} />{' '}
        <Route path="*">
          <Redirect to="/account/reports/bank-ledger" />
        </Route>{' '}
      </Switch>{' '}
    </>
  );
};

export { RouteSetup };
