import React, { Component } from 'react';
import dayjs from 'dayjs';
import ExcelJS from 'exceljs';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

import services from '@C/Reports/_utils/data';
import { formatCurrency, InterFont, InterFontBold } from '@C/TableGrid';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { getBase64FromUrl } from '@C/Utils/ExportUtils';
import { authService } from '@S';

import './styles.scoped.scss';

class BalanceSheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter1: '',
      reportData: {},
      start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
      loading: true,
      data: null,
      exportingPdf: false,
      exportingExcel: false,
    };
  }

  componentDidMount() {
    this.getBalanceSheet();
  }

  export2PDF = () => {
    // eslint-disable-next-line new-cap
    this.setState({ exportingPdf: true });
    const that = this;
    const doc = new jsPDF();
    const reportData = this.state.reportData;
    const start_date = this.state.start_date;
    const end_date = this.state.end_date;
    const liabilitiesArr = [];
    const assetsArr = [];

    const exportOptions = {
      fileName: `Balance sheet - ${start_date} to ${end_date} Report.pdf`,
      reportName: 'Balance Sheet Report',
      reportDate: `Date - ${dayjs(start_date).format('D MMM YY')} to ${dayjs(
        end_date,
      ).format('D MMM YY')}`,
    };

    liabilitiesArr.push(['Net profit', '']);
    reportData.liabilities.current_liabilities.net_profit.map((item) => {
      liabilitiesArr.push([` ${item.name}`, formatCurrency(item.amount)]);
    });

    liabilitiesArr.push(['Current liabilities - Accounts payable', '']);
    reportData.liabilities.current_liabilities.accounts_payable.map((item) => {
      liabilitiesArr.push([
        `${item.user__first_name}${item.user__last_name}`,
        formatCurrency(item.outstanding),
      ]);
    });

    liabilitiesArr.push(['Duties and Taxes', '']);
    reportData.liabilities.current_liabilities.duties_and_taxes.input_tax.map(
      (item) => {
        liabilitiesArr.push([
          `${item.input_tax}`,
          formatCurrency(item.input_tax_total_amount),
        ]);
      },
    );
    reportData.liabilities.current_liabilities.duties_and_taxes.output_tax.map(
      (item) => {
        liabilitiesArr.push([
          `${item.output_tax}`,
          formatCurrency(item.output_tax_total_amount),
        ]);
      },
    );

    liabilitiesArr.push(['Staff account', '']);
    reportData.liabilities.current_liabilities.staff_accounts.staff_account.map(
      (item) => {
        liabilitiesArr.push([
          `${item.staff_first_name}${item.staff_last_name}`,
          formatCurrency(item.outstanding),
        ]);
      },
    );

    liabilitiesArr.push([
      'Total liabilities',
      formatCurrency(
        reportData.liabilities.current_liabilities.total_liabilities,
      ),
    ]);

    assetsArr.push(['Current assets - Stock', '']);
    reportData.assets.current_assets.stock.fuel_stock.map((item) => {
      assetsArr.push([` Stock ${item.product}`, formatCurrency(item.stock)]);
    });
    assetsArr.push([
      'Stock Lubes',
      formatCurrency(reportData.assets.current_assets.stock.lube_stock),
    ]);

    assetsArr.push(['Accounts receivables (Debtors)', '']);
    reportData.assets.current_assets.account_receivables.map((item) => {
      assetsArr.push([
        ` ${item.customer__customer__first_name}${item.customer__customer__last_name}`,
        formatCurrency(item.balance),
      ]);
    });

    assetsArr.push(['Cash', '']);
    reportData.assets.current_assets.cash_balance.map((item) => {
      assetsArr.push([`Cash in hand`, formatCurrency(item.cash)]);
    });

    assetsArr.push(['Bank', '']);
    reportData.assets.current_assets.bank.map((item) => {
      assetsArr.push([` ${item.bank_name}`, formatCurrency(item.balance)]);
    });

    assetsArr.push([
      'Total assets',
      formatCurrency(reportData.assets.current_assets.total_assets),
    ]);

    const bunkLogo = getBase64FromUrl(authService.currentOMCWebLogo);
    const bunkAddress = authService.currentBunkAddress;

    bunkLogo.then((result) => {
      const img = new Image();
      img.onload = function () {
        doc.addFileToVFS('Inter-Regular.ttf', InterFont);
        doc.addFileToVFS('Inter-Bold-bold.ttf', InterFontBold);
        doc.addFont('Inter-Regular.ttf', 'Inter', 'normal');
        doc.addFont('Inter-Bold-bold.ttf', 'Inter', 'bold');
        doc.setFont('Inter');
        const aspectRatio = img.width / img.height;
        const width = 25;
        const height = width / aspectRatio;
        try {
          doc.addImage(result, 'PNG', 13, 10, width, height);
          autoTable(doc, {
            body: [
              [
                {
                  content: authService.currentBunkName,
                  styles: {
                    halign: 'right',
                    fontSize: 15,
                    textColor: '#000000',
                  },
                },
              ],
              [
                {
                  content:
                    bunkAddress.city +
                    ', ' +
                    bunkAddress.location +
                    ',' +
                    bunkAddress.country.country +
                    ' - ' +
                    bunkAddress.pincode,
                  styles: {
                    halign: 'right',
                    fontSize: 12,
                    textColor: '#000000',
                  },
                },
              ],
            ],
            theme: 'plain',
          });
          autoTable(doc, {
            body: [
              [
                {
                  content: 'Balance Sheet Report',
                  styles: {
                    halign: 'left',
                    fontSize: 15,
                    textColor: '#000000',
                    fontStyle: 'bold',
                  },
                },
                {
                  content: `${dayjs(start_date).format('D MMM YY')} to ${dayjs(
                    end_date,
                  ).format('D MMM YY')}`,
                  styles: {
                    halign: 'right',
                    fontSize: 12,
                    textColor: '#000000',
                    fontStyle: 'bold',
                  },
                },
              ],
            ],
            theme: 'plain',
          });
          doc.setFontSize(12);
          autoTable(doc, {
            head: [['Liabilities', 'Amount']],
            body: liabilitiesArr,
            theme: 'striped',
            startY: 55,
            headStyles: {
              fillColor: '#000000',
            },
            willDrawCell: function (data) {
              if (data.cell.raw === 'Total liabilities') {
                doc.setFont('Inter', 'bold');
                doc.setTextColor('#ffffff'); // Red
                doc.setFillColor('#004474'); // Brand Blue

                data.row.cells[1].styles.fillColor = '#004474';
                data.row.cells[1].styles.textColor = '#ff0000';
                data.row.cells[1].styles.fontStyle = 'bold';
              }
              if (
                data.cell.raw === 'Net profit' ||
                data.cell.raw === 'Current liabilities - Accounts payable' ||
                data.cell.raw === 'Duties and Taxes' ||
                data.cell.raw === 'Staff account'
              ) {
                doc.setFont('Inter', 'bold');
                doc.setTextColor('#ffffff');
                doc.setFillColor('#004474'); // Brand Blue

                data.row.cells[1].styles.fillColor = '#004474';
                data.row.cells[1].styles.textColor = '#2dc096';
                data.row.cells[1].styles.fontStyle = 'bold';
              }
            },
            styles: {
              font: 'Inter',
              fontStyle: 'normal',
              fontSize: 11,
            },
          });
          autoTable(doc, {
            head: [['Assets', 'Amount']],
            body: assetsArr,
            theme: 'striped',
            willDrawCell: function (data) {
              if (data.cell.raw === 'Total assets') {
                doc.setFont('Inter', 'bold');
                doc.setTextColor('#ffffff'); // Red
                doc.setFillColor('#004474'); // Brand Blue

                data.row.cells[1].styles.fillColor = '#004474';
                data.row.cells[1].styles.textColor = '#ff0000';
                data.row.cells[1].styles.fontStyle = 'bold';
              }
              if (
                data.cell.raw === 'Current assets - Stock' ||
                data.cell.raw === 'Accounts receivables (Debtors)' ||
                data.cell.raw === 'Cash' ||
                data.cell.raw === 'Bank' ||
                data.cell.row === 'Total assets'
              ) {
                doc.setFont('Inter', 'bold');
                doc.setTextColor('#ffffff');
                doc.setFillColor('#004474'); // Brand Blue

                data.row.cells[1].styles.fillColor = '#004474';
                data.row.cells[1].styles.textColor = '#2dc096';
                data.row.cells[1].styles.fontStyle = 'bold';
              }
            },
            headStyles: {
              fillColor: '#000000',
            },
            styles: {
              font: 'Inter',
              fontStyle: 'normal',
              fontSize: 11,
            },
          });
          doc.save(exportOptions.fileName);
          that.setState({ exportingPdf: false });
        } catch (e) {
          console.log(e);
        }
      };
      img.src = result;
    });
  };

  export2Excel = () => {
    this.setState({ exportingExcel: true });
    const that = this;
    // use exceljs to export data to excel
    const reportData = this.state.reportData;
    const start_date = this.state.start_date;
    const end_date = this.state.end_date;
    const liabilitiesArr = [];
    const assetsArr = [];
    const payableArr = [];
    const receivableArr = [];
    const dutiesArr = [];
    const cashArr = [];
    const staffArr = [];
    const bankArr = [];

    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Balance Sheet');
      worksheet.getRow(1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '000000' },
      };
      worksheet.getRow(1).font = {
        color: { argb: 'ffffff' },
      };
      worksheet.columns = [
        { header: 'Liabilities', key: 'liabilities', width: 40 },
        { header: 'Amount', key: 'amount', width: 40 },
        { header: 'Assets', key: 'assets', width: 40 },
        { header: 'Amount', key: 'amount', width: 40 },
      ];

      customRow = worksheet.addRow([
        'Net profit',
        '',
        'Current assets-Stock',
        '',
      ]);
      customRow.eachCell(function (cell) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '000000' },
        };
        cell.font = {
          color: { argb: 'ffffff' },
          bold: true,
        };
      });

      reportData.liabilities.current_liabilities.net_profit.map((item) => {
        liabilitiesArr.push([` ${item.name}`, formatCurrency(item.amount)]);
      });

      reportData.assets.current_assets.stock.fuel_stock.map((item) => {
        assetsArr.push([` Stock ${item.product}`, formatCurrency(item.stock)]);
      });
      assetsArr.push([
        'Stock Lubes',
        formatCurrency(reportData.assets.current_assets.stock.lube_stock),
      ]);
      const mergedArr = liabilitiesArr.map(function (e, i) {
        return [e[0], e[1], assetsArr[i][0], assetsArr[i][1]];
      });
      liabilitiesArr.map((item, index) => {
        worksheet.addRow([item[0], item[1], '', '']);
      });

      assetsArr.map((item, index) => {
        worksheet.getRow(index + 3).getCell(3).value = item[0];
        worksheet.getRow(index + 3).getCell(4).value = item[1];
      });

      let customRow = worksheet.addRow([
        'Current liabilities - Accounts payable',
        '',
        'Accounts receivables (Debtors)',
        '',
      ]);

      customRow.eachCell(function (cell) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '000000' },
        };
        cell.font = {
          color: { argb: 'ffffff' },
          bold: true,
        };
      });
      reportData.liabilities.current_liabilities.accounts_payable.map(
        (item) => {
          payableArr.push([
            `${item.user__first_name}${item.user__last_name}`,
            formatCurrency(item.outstanding),
          ]);
        },
      );

      reportData.assets.current_assets.account_receivables.map((item) => {
        receivableArr.push([
          ` ${item.customer__customer__first_name}${item.customer__customer__last_name}`,
          formatCurrency(item.balance),
        ]);
      });

      const combineArr = payableArr.map(function (e, i) {
        return [e[0], e[1], receivableArr[i][0], receivableArr[i][1]];
      });

      combineArr.map((item, index) => {
        worksheet.addRow([item[0], item[1], item[2], item[3]]);
      });

      reportData.liabilities.current_liabilities.duties_and_taxes.input_tax.map(
        (item) => {
          dutiesArr.push([
            `${item.input_tax}`,
            formatCurrency(item.input_tax_total_amount),
          ]);
        },
      );
      reportData.liabilities.current_liabilities.duties_and_taxes.output_tax.map(
        (item) => {
          dutiesArr.push([
            `${item.output_tax}`,
            formatCurrency(item.output_tax_total_amount),
          ]);
        },
      );

      reportData.assets.current_assets.cash_balance.map((item) => {
        cashArr.push([`Cash in hand`, formatCurrency(item.cash)]);
      });

      reportData.liabilities.current_liabilities.staff_accounts.staff_account.map(
        (item) => {
          staffArr.push([
            `${item.staff_first_name}${item.staff_last_name}`,
            formatCurrency(item.outstanding || 0),
          ]);
        },
      );

      reportData.assets.current_assets.bank.map((item) => {
        bankArr.push([` ${item.bank_name}`, formatCurrency(item.balance)]);
      });

      const mergedDutiesArr = dutiesArr.map((item, index) => [
        item[0],
        item[1],
        cashArr[index] ? cashArr[index][0] : '',
        cashArr[index] ? cashArr[index][1] : '',
      ]);

      const mergedStaffArr = staffArr.map((item, index) => [
        item[0],
        item[1],
        bankArr[index] ? bankArr[index][0] : '',
        bankArr[index] ? bankArr[index][1] : '',
      ]);

      customRow = worksheet.addRow(['Duties and Taxes', '', 'Cash', '']);

      customRow.eachCell(function (cell) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '000000' },
        };
        cell.font = {
          color: { argb: 'ffffff' },
          bold: true,
        };
      });

      mergedDutiesArr.map((item) => {
        worksheet.addRow([item[0], item[1], item[2], item[3]]);
      });

      customRow = worksheet.addRow(['Staff account', '', 'Bank', '']);

      customRow.eachCell(function (cell) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '000000' },
        };
        cell.font = {
          color: { argb: 'ffffff' },
          bold: true,
        };
      });

      mergedStaffArr.map((item) => {
        worksheet.addRow([item[0], item[1], item[2], item[3]]);
      });

      customRow = worksheet.addRow([
        'Total liabilities',
        formatCurrency(
          reportData.liabilities.current_liabilities.total_liabilities,
        ),
        'Total assets',
        formatCurrency(reportData.assets.current_assets.total_assets),
      ]);

      customRow.eachCell(function (cell) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '004474' },
        };
        cell.font = {
          color: { argb: 'ffffff' },
          bold: true,
        };
      });

      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `Balance sheet - ${start_date} to ${end_date}.xlsx`,
        );
        that.setState({ exportingExcel: false });
      });
    } catch (e) {
      console.log(e);
    }
  };

  getBalanceSheet = () => {
    services
      .getBalanceSheet(this.state)
      .then((response) => {
        this.setState({
          reportData: response.data.data,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  changeFilter = (e) => {
    this.setState({ filter1: e });
  };

  handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date' && dayjs(value).isAfter(this.state.end_date)) {
      this.setState({ [name]: value, end_date: value });
    } else if (
      name === 'end_date' &&
      dayjs(value).isBefore(this.state.start_date)
    ) {
      this.setState({ [name]: value, start_date: value });
    } else {
      this.setState({ [name]: value });
    }
  };

  resetFilter = () => {
    this.setState(
      {
        start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
        loading: true,
      },
      function () {
        this.getBalanceSheet();
      },
    );
  };
  submitForm = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, function () {
      this.getBalanceSheet();
    });
  };

  render() {
    const { loading, reportData } = this.state;
    return (
      <section className="widget full-width list-table inverted">
        <header>
          <h3> Balance Sheet Report </h3>
        </header>
        <main>
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group">
                <label>From date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.start_date}
                  name="start_date"
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="filter-group">
                <label>To date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.end_date}
                  min={this.state.start_date}
                  onChange={this.handleDateChange}
                  name="end_date"
                />
              </div>
              <div className="filter-group reset-filter">
                <button onClick={this.resetFilter}>Reset filters</button>
              </div>
              <div className="filter-group">
                  <button
                    className="submit"
                    type="submit"
                    onClick={this.submitForm}
                  >
                    Apply filter
                  </button>
                </div>
            </div>
          </div>
          <div className="tg-wrapper">
            {(() => {
              if (this.state.loading) {
                return <DropLoaderLarge />;
              } else {
                return (
                  <>
                    <div className="export-btns">
                      <button
                        onClick={this.export2Excel}
                        className={`blue-btn btn-with-icon btn-with-icon__excel ${
                          this.state.exportingExcel ? 'loading' : ''
                        }`}
                      >
                        Export to Excel
                      </button>
                      <button
                        onClick={this.export2PDF}
                        className={`blue-btn btn-with-icon btn-with-icon__pdf ${
                          this.state.exportingPdf ? 'loading' : ''
                        }`}
                      >
                        Export to PDF
                      </button>
                    </div>
                    <div className="table-wrapper">
                      <table>
                        <thead>
                          <tr
                            className="tg-header"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <th className="th-cell">
                              <span>Liabilities</span>
                            </th>
                            <th className="th-cell">
                              <span>Amount</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            className="tg-body"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <td className="tg-cell --bold">
                              <span>Net profit</span>
                            </td>
                          </tr>
                          {reportData.liabilities.current_liabilities.net_profit.map(
                            (item, i) => (
                              <tr
                                key={i}
                                className="tg-body"
                                style={{ borderBottom: '0px solid white' }}
                              >
                                <td className="tg-cell">
                                  <span>{`${item.name}`}</span>
                                </td>
                                <td className="tg-cell">
                                  <span>{`${formatCurrency(
                                    item.amount || 0,
                                  )}`}</span>
                                </td>
                              </tr>
                            ),
                          )}
                          <tr
                            className="tg-body"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <td className="tg-cell --bold">
                              <span>Current liabilities</span>
                            </td>
                            <td className="tg-cell --bold">
                              <span />
                            </td>
                          </tr>
                          <tr
                            className="tg-body"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <td className="tg-cell --bold">
                              <span>Accounts payable</span>
                            </td>
                            <td className="tg-cell --bold">
                              <span />
                            </td>
                          </tr>
                          {reportData.liabilities.current_liabilities.accounts_payable.map(
                            (item, i) => (
                              <tr
                                key={i}
                                className="tg-body"
                                style={{ borderBottom: '0px solid white' }}
                              >
                                <td className="tg-cell">
                                  <span>{`${item.user__first_name}${item.user__last_name}`}</span>
                                </td>
                                <td className="tg-cell">
                                  <span>{`${formatCurrency(
                                    item.outstanding || 0,
                                  )}`}</span>
                                </td>
                              </tr>
                            ),
                          )}
                          <tr
                            className="tg-body"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <td className="tg-cell --bold">
                              <span>Duties and Taxes</span>
                            </td>
                            <td className="tg-cell --bold">
                              <span />
                            </td>
                          </tr>
                          {reportData.liabilities.current_liabilities.duties_and_taxes.input_tax.map(
                            (item, i) => (
                              <tr
                                key={i}
                                className="tg-body"
                                style={{ borderBottom: '0px solid white' }}
                              >
                                <td className="tg-cell">
                                  <span>{`${item.input_tax}`}</span>
                                </td>
                                <td className="tg-cell">
                                  <span>{`${formatCurrency(
                                    item.input_tax_total_amount || 0,
                                  )}`}</span>
                                </td>
                              </tr>
                            ),
                          )}
                          {reportData.liabilities.current_liabilities.duties_and_taxes.output_tax.map(
                            (item, i) => (
                              <tr
                                key={i}
                                className="tg-body"
                                style={{ borderBottom: '0px solid white' }}
                              >
                                <td className="tg-cell">
                                  <span>{`${item.output_tax}`}</span>
                                </td>
                                <td className="tg-cell">
                                  <span>{`${formatCurrency(
                                    item.output_tax_total_amount || 0,
                                  )}`}</span>
                                </td>
                              </tr>
                            ),
                          )}
                          <tr
                            className="tg-body"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <td className="tg-cell --bold">
                              <span>Staff account</span>
                            </td>
                            <td className="tg-cell --bold">
                              <span />
                            </td>
                          </tr>
                          {reportData.liabilities.current_liabilities.staff_accounts.staff_account.map(
                            (item, i) => (
                              <tr
                                key={i}
                                className="tg-body"
                                style={{ borderBottom: '0px solid white' }}
                              >
                                <td className="tg-cell">
                                  <span>{`${item.staff_first_name}${item.staff_last_name}`}</span>
                                </td>
                                <td className="tg-cell">
                                  <span>{`${formatCurrency(
                                    item.outstanding || 0,
                                  )}`}</span>
                                </td>
                              </tr>
                            ),
                          )}
                        </tbody>
                        <tfoot>
                          <tr className="tg-footer">
                            <th className="tg-cell alert-brand">
                              <span className="--white">{`Total liabilities`}</span>
                            </th>
                            <th className="tg-cell alert-brand">
                              <span className="--red">{`${formatCurrency(
                                reportData.liabilities.current_liabilities
                                  .total_liabilities,
                              )}`}</span>
                            </th>
                          </tr>
                        </tfoot>
                      </table>
                      <table>
                        <thead>
                          <tr
                            className="tg-header"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <th className="th-cell">
                              <span>Assets</span>
                            </th>
                            <th className="th-cell">
                              <span>Amount</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            className="tg-body"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <td className="tg-cell --bold">
                              <span> Current Assets </span>
                            </td>
                            <td className="tg-cell --bold">
                              <span />
                            </td>
                          </tr>
                          <tr
                            className="tg-body"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <td className="tg-cell --bold">
                              <span> Stock </span>
                            </td>
                            <td className="tg-cell --bold">
                              <span />
                            </td>
                          </tr>
                          {reportData.assets.current_assets.stock.fuel_stock.map(
                            (item, i) => (
                              <tr
                                key={i}
                                className="tg-body"
                                style={{ borderBottom: '0px solid white' }}
                              >
                                <td className="tg-cell">
                                  <span>{`Stock ${item.product || ''} `}</span>
                                </td>
                                <td className="tg-cell">
                                  <span>{`${formatCurrency(
                                    item.stock || 0,
                                  )}`}</span>
                                </td>
                              </tr>
                            ),
                          )}
                          <tr className="tg-body">
                            <td className="tg-cell">
                              <span>{`Stock Lubes`}</span>
                            </td>
                            <td className="tg-cell">
                              <span>{`${formatCurrency(
                                reportData.assets.current_assets.stock
                                  .lube_stock,
                              )}`}</span>
                            </td>
                          </tr>
                          <tr
                            className="tg-body"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <td className="tg-cell --bold">
                              <span>Accounts receivables (Debtors)</span>
                            </td>
                            <td className="tg-cell --bold">
                              <span />
                            </td>
                          </tr>
                          {reportData.assets.current_assets.account_receivables.map(
                            (item, i) => (
                              <tr
                                key={i}
                                className="tg-body"
                                style={{ borderBottom: '0px solid white' }}
                              >
                                <td className="tg-cell">
                                  <span>{`${item.customer__customer__first_name}${item.customer__customer__last_name}`}</span>
                                </td>
                                <td className="tg-cell">
                                  <span>{`${formatCurrency(
                                    item.balance || 0,
                                  )}`}</span>
                                </td>
                              </tr>
                            ),
                          )}
                          <tr
                            className="tg-body"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <td className="tg-cell --bold">
                              <span>Cash</span>
                            </td>
                            <td className="tg-cell --bold">
                              <span />
                            </td>
                          </tr>
                          {reportData.assets.current_assets.cash_balance.map(
                            (item, i) => (
                              <tr
                                key={i}
                                className="tg-body"
                                style={{ borderBottom: '0px solid white' }}
                              >
                                <td className="tg-cell">
                                  <span>{`Cash in hand `}</span>
                                </td>
                                <td className="tg-cell">
                                  <span>{`${formatCurrency(
                                    item.cash || 0,
                                  )}`}</span>
                                </td>
                              </tr>
                            ),
                          )}
                          <tr
                            className="tg-body"
                            style={{ borderBottom: '0px solid white' }}
                          >
                            <td className="tg-cell --bold">
                              <span>Bank</span>
                            </td>
                            <td className="tg-cell --bold">
                              <span />
                            </td>
                          </tr>
                          {reportData.assets.current_assets.bank.map(
                            (item, i) => (
                              <tr
                                key={i}
                                className="tg-body"
                                style={{ borderBottom: '0px solid white' }}
                              >
                                <td className="tg-cell">
                                  <span>{`${item.bank_name}`}</span>
                                </td>
                                <td className="tg-cell">
                                  <span>{`${formatCurrency(
                                    item.balance || 0,
                                  )}`}</span>
                                </td>
                              </tr>
                            ),
                          )}
                        </tbody>
                        <tfoot>
                          <tr className="tg-footer">
                            <th className="tg-cell alert-brand">
                              <span className="--white">{`Total assets`}</span>
                            </th>
                            <th className="tg-cell alert-brand">
                              <span className="--red">{`${formatCurrency(
                                reportData.assets.current_assets.total_assets,
                              )}`}</span>
                            </th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </>
                );
              }
            })()}
          </div>
        </main>
      </section>
    );
  }
}

export { BalanceSheet };
