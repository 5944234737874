import React, { Component } from 'react';
import axios from 'axios';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';

import DropLoader from '@C/Utils/DropLoader';
import { authHeader } from '@H';

import services from './data';

const Option = (props) => {
  const deleteVehicle = (e, id, customer) => {
    e.stopPropagation();
    props.selectProps.selectProps.delete(e, id, customer);
  };

  return (
    <>
      <components.Option {...props}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div>{props.children}</div>
          {!props.data.__isNew__ && (
            <div>
              <button
                className="actions __delete"
                onClick={(e) =>
                  deleteVehicle(e, props.data.value, props.data.customer)
                }
              >
                Delete
              </button>
            </div>
          )}
        </div>
      </components.Option>
    </>
  );
};

class VehicleList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleOptions: [],
      selectedVehicle: '',
      isLoading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.getCusVehicleList = this.getCusVehicleList.bind(this);
  }
  componentDidMount() {
    this.getCusVehicleList();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.selectedCustomerId !== this.props.selectedCustomerId) {
      this.getCusVehicleList(this.props.selectedCustomerId);
    }
  }
  getCusVehicleList() {
    let a = this.props.selectedCustomerId;
    if (!a) {
      a = '';
    }
    const url = `${process.env.API_URL}/credit-customer/bunk/vehicle/?credit_customer_id=${a}`;
    services
      .get(url)
      .then((response) => {
        const tmp = response.data.data;
        const CategoryOptions = [];
        for (let i = 0; i < tmp.length; i++) {
          CategoryOptions.push({
            value: tmp[i].uuid,
            label: tmp[i].vehicle_no,
            customer: tmp[i].owner__uuid,
          });
        }
        this.setState({ vehicleOptions: CategoryOptions });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleChange = (e) => {
    this.setState({ selectedVehicle: e });
    const id = this.props.did ? this.props.did : 0;
    this.props.handleDropdownChange(e, id);
  };

  createOption = (label) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
  });

  handleCreate = (inputValue) => {
    this.setState({ isLoading: true });
    const formData = {
      credit_customer_id: this.props.selectedCustomerId,
      vehicle_list: [
        {
          vehicle_no: inputValue.toUpperCase(),
          driver_name: `Random ${Math.random().toString(36).substr(2, 5)}`,
        },
      ],
    };
    const headers = authHeader(1);
    axios
      .post(`${process.env.API_URL}/credit-customer/bunk/vehicle/`, formData, {
        headers,
      })
      .then((response) => {
        toast.success('Vehicle added successfully');
        this.getCusVehicleList(this.props.selectedCustomerId);
        const tmp = {
          value: response.data.data.id,
          label: inputValue.toUpperCase(),
          customer: this.props.selectedCustomerId,
        };
        this.setState({ selectedVehicle: tmp });
        const id = this.props.did ? this.props.did : 0;
        this.props.handleDropdownChange(tmp, id);
        this.setState({ isLoading: false });
      })
      .catch(() => {
        toast.error('Failed to add vehicle. Please try again');
        this.setState({ isLoading: false });
      });
  };

  deleteVehicle = (e, id, customer) => {
    e.stopPropagation();
    const url = `${process.env.API_URL}/credit-customer/bunk/vehicle?vehicle_id=${id}&credit_customer_id=${customer}`;
    const headers = authHeader(1);
    axios
      .delete(url, { headers })
      .then(() => {
        toast.success('Vehicle deleted successfully');
        this.getCusVehicleList(this.props.selectedCustomerId);
      })
      .catch(() => {
        toast.error('Failed to delete vehicle. Please try again');
      });
  };

  render() {
    const optionList = this.state.vehicleOptions;
    return (
      <div className="">
        <CreatableSelect
          onChange={(e) => this.handleChange(e)}
          options={optionList}
          selectProps={{
            delete: this.deleteVehicle,
          }}
          className="select-box-container vehicle-selector"
          classNamePrefix="vehicle-selector"
          name="credit_slip_type"
          placeholder="Select vehicle"
          isClearable={!this.props.disabled}
          isDisabled={this.state.isLoading}
          isLoading={this.state.isLoading}
          onCreateOption={this.handleCreate}
          components={{ LoadingIndicator: DropLoader, Option }}
          value={optionList.filter(({ value }) => value === this.props.value)}
          formatCreateLabel={(userInput) => `Add  ${userInput}`}
        />
      </div>
    );
  }
}

export { VehicleList };
