import axios from 'axios';

import { authHeader } from '@H';
import { authService } from '@S';

export default {
  getLubesCategoryList: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/lubes/bunk/category/`,
    }),
  getVendorList: () => {
    // alert(222)
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/lubes/bunk/vendor/`,
    });
  },
  getProductList: (date) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/products/list/?date=${date}`,
    }),

  getCreditCustomerList: (limit, offset, credit_customer) => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/credit-customer/bunk/customer/?limit=${limit}&offset=${offset}&credit_customer=${credit_customer}`,
    });
  },
  getCreditCustomer: (data) => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/credit-customer/bunk/customer/?credit_customer_id=${data.customer_id}`,
    });
  },
  getCreditSlips: (data) => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/credit-customer/bunk/credit-slip/?staff_id=${data.staff_id}&cashier_duty_id=${data.cashier_duty_id}&limit=${data.limit}&offset=${data.offset}&start_date=${data.start_date}&end_date=${data.end_date}&credit_customer_id=${data.credit_customer_id}&vehicle_number=${data.vehicle_number}&product_id=${data.product_id}&slip_number=${data.slip_no}&shift_id=${data.shift_id}`,
    });
  },
  getCreditCustomerInvoices: (data) => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/credit-customer/bunk/credit-slip/?staff_id=${data.staff_id}&limit=${data.limit}&offset=1&start_date=${data.start_date}&end_date=${data.end_date}&credit_customer_id=${data.credit_customer_id}&vehicle_id=${data.vehicle_number}&product_id=${data.product_id}&slip_number=${data.slip_no}`,
    });
  },
  getCreditCustomerSlips: (data) => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/credit-customer/bunk/credit-slip/?credit_customer_id=${data.customer_id}&limit=${data.limit}&offset=${data.offset}&start_date=${data.start_date}&end_date=${data.end_date}&vehicle_id=${data.vehicle_number}&product_id=${data.product_id}`,
    });
  },
  getSingleCreditSlip: (slip) => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/credit-customer/bunk/credit-slip/?slip_id=${slip}`,
    });
  },
  getQuotation: (status) => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/credit-customer/bunk/quotation/?by_status=${status}`,
    });
  },
  getIndentList: (status) => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/credit-customer/bunk/indent/list/?by_status=${status}`,
    });
  },
  getCreditCustomerQuotation: (data) => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/credit-customer/bunk/quotation/?customer_id=${data.customer_id}&limit=${data.limit}&offset=${data.offset}`,
    });
  },
  getLivePurchaseOrder: (data) => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/credit-customer/bunk/indent/?credit_customer_id=${data.credit_customer_id}`,
    });
  },
  getStaffList: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/staff/associate-staff/`,
    }),
  assignCashierToPurchaseOrder: (body) => {
    const headers = authHeader(1);
    return axios.post(
      `${process.env.API_URL}/credit-customer/bunk/purchase-allocation/`,
      body,
      { headers },
    );
  },
  rejectPurchaseOrder: (body) => {
    const headers = authHeader(1);
    return axios.put(
      `${process.env.API_URL}/credit-customer/bunk/purchase-order-reject/`,
      body,
      { headers },
    );
  },
  getBlackList: (data) => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/credit-customer/bunk/block-list/?&limit=${data.limit}&offset=${data.offset}&city=${data.city}&state=${data.state}`,
    });
  },
  getLubesList: () => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/lubes/bunk/lube/list/`,
    });
  },
  getLubesDetailList: () => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/lubes/bunk/lube/`,
    });
  },
  getFuelWithPrice: (date) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/fuel-prices/?date=${date}`,
    }),
  getCreditLedger: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/credit-customer/bunk/customer-ledger/?&limit=${data.limit}&offset=${data.offset}&staff_id=${data.staff_id}&from_date=${data.start_date}&to_date=${data.end_date}&customer_id=${data.customer_id}&vehicle_id=${data.vehicle_number}&product_id=${data.product_id}&slip_number=${data.slip_no}&shift_id=${data.shift_id}`,
    }),

  getCreditLedgerList: (data, uuid) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/credit-customer/bunk/customer-ledger/?&limit=${data.limit}&offset=${data.offset}&staff_id=${data.staff_id}&from_date=${data.start_date}&to_date=${data.end_date}&customer_id=${uuid}&vehicle_id=${data.vehicle_number}&product_id=${data.product_id}&slip_number=${data.slip_no}&shift_id=${data.shift_id}`,
    }),

  getCreditCollection: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/transactions/?product_type=CreditCustomer&limit=${data.limit}&offset=${data.offset}&staff_id=${data.staff_id}&start_date=${data.start_date}&end_date=${data.end_date}&customer_id=${data.credit_customer_id}`,
    }),
  getPaymentList: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/transactions/?product_type=CreditCustomer&customer_id=${data.customer_id}&limit=${data.limit}&offset=${data.offset}&start_date=${data.start_date}&end_date=${data.end_date}&source=${data.payment.source}&by_bank_uuid=${data.payment.bank_id}&by_payment_type=${data.payment.payment_method}`,
    }),
  getCashiers: () => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/staff/associate-staff/`,
    });
  },
  delete: (url) => {
    return axios({
      method: 'DELETE',
      // 'params': { ID: 12345 },
      headers: authHeader(1),
      url: url,
    });
  },
  get: (url) => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: url,
    });
  },
  post: (url, body) => {
    const headers = authHeader(1);
    return axios.post(url, body, { headers });
  },
  getTaxDetails: () =>
    axios({
      method: 'GET',
      headers: authHeader(),
      url: `${process.env.API_URL}/utils/tax/${authService.currentBunkCountryUUID}/?by_group=1`,
    }),
};
