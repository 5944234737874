import axios from 'axios';

import { authHeader } from '@H';
import { authService } from '@S';

export default {
  getPastDutyList: (date) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/cashier/cashier-duties/?date=${date}&is_end_duty=1`,
    }),
  getActiveDutyList: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/cashier/associate-cashier/?is_available=1&staff_id=${data?.uuid || ''}`,
    }),
  getStaffList: (date) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/cashier/associate-cashier/?date=${date}`,
    }),
  getGunsList: (date) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/associate-guns/?date=${date}`,
    }),
  getAllocatedGuns: (staff, duty_id) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/gun-readings/?staff_id=${staff}&cashier_duty_id=${duty_id}`,
    }),
  getIncomeSource: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/other-income-setup`,
    }),
  getExpenseSource: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/expenses-setup/`,
    }),
  getMachineList: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/map-swipe-machines/list/`,
    }),
  getWalletList: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/mapped-wallets/list/`,
    }),
  getCreditCustomerList: () => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/credit-customer/bunk/customer/`,
    });
  },
  getOMCProducts: () => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/omc/product/${authService.currentOMC}/`,
    });
  },
  getLubesList: () => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/lubes/bunk/lube/list/`,
    });
  },
  getGrandTotal: (staff, duty_id) => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/to-be-collected/?staff_id=${staff}&cashier_duty_id=${duty_id}`,
    });
  },
  getTotalCollection: (staff, duty_id) => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/?staff_id=${staff}&cashier_duty_id=${duty_id}`,
    });
  },
  getNeftCollection: (staff, duty_id) => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/?staff_id=${staff}&cashier_duty_id=${duty_id}`,
    });
  },
  getLubeSales: (staff, date, limit, offset) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/transactions/?product_type=Lubes&limit=${limit}&offset=${offset}&start_date=${date}&end_date=${date}&staff_id=${staff}`,
    }),
  getCreditSlips: (staff, date, limit, offset) => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/credit-customer/bunk/credit-slip/?limit=${limit}&offset=${offset}&staff_id=${staff}&start_date=${date}&end_date=${date}`,
    });
  },
  getCashSummary: (staff, date, duty_id) => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/cash-management/?staff_id=${staff}&time_stamp=${date}&cashier_duty_id=${duty_id}`,
    });
  },

  getTempCredit: (staff, duty_id) => {
    return axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/temp-credit/?staff_id=${staff}&cashier_duty_id=${duty_id}`,
    });
  },

  getCreditCollection: (staff, duty_id) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/transactions/?product_type=CreditCustomer&staff_id=${staff}&cashier_duty_id=${duty_id}`,
    }),

  getIncomeData: (time_stamp, staff_id, limit, offset) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/transactions/?product_type=Other&limit=${limit}&offset=${offset}&date=${time_stamp}&staff_id=${staff_id}`,
    }),
  getExpenseData: (time_stamp, staff_id, limit, offset) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/transactions/?product_type=Expenses&limit=${limit}&offset=${offset}&date=${time_stamp}&staff_id=${staff_id}`,
    }),

  getWalletSummaryList: (staff_id, duty, uuid) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/transactions/?staff_id=${staff_id}&source=WALLET&by_bank_uuid=${uuid}&cashier_duty_id=${duty}`,
    }),
  getSwipeSummaryList: (staff_id, duty, uuid) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/transactions/?staff_id=${staff_id}&source=SWIPE&by_bank_uuid=${uuid}&cashier_duty_id=${duty}`,
    }),
  getNeftSummaryList: (staff_id, duty, uuid) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/transactions/?staff_id=${staff_id}&source=SELF&by_bank_uuid=${uuid}&cashier_duty_id=${duty}&product_type=FUEL`,
    }),
  getSalaryList: (duty) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/staff-management/bunk/cashier-duty-advance/?cashier_duty_id=${duty}`,
    }),
  getProductList: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/products/list/`,
    }),
  getTaxDetails: () =>
    axios({
      method: 'GET',
      headers: authHeader(),
      url: `${process.env.API_URL}/utils/tax/${authService.currentBunkCountryUUID}/?by_group=1`,
    }),
  getDSRDates: (month, year) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/dsr/dsr-date/?month=${month}&year=${year}`,
    }),
  getDenomination: (duty_id, cash_code) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/cash-denomination/?cashier_duty_id=${duty_id}&cash_code=${cash_code}`,
    }),

  getBowserList: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/associate-tanks/?is_bowser=1`,
    }),
};
