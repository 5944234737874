import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import { formatCurrency, TableGrid } from '@C/TableGrid';
import { AccessControl, BankList } from '@C/Utils';
import { authHeader } from '@H';

import services from './data';

const col = [
  {
    name: 'Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Bank',
    key: 'bank',
    formatter(props) {
      return <>{props.bank_name}</>;
    },
  },
  {
    name: 'Balance',
    key: 'all',
    formatter(props) {
      return <>{formatCurrency(props.balance)}</>;
    },
  },
  // {
  //   name: 'Staff',
  //   key: 'cashier',
  //   formatter(props) {
  //     return (
  //       <>
  //         {props.first_name} {props.last_name}
  //       </>
  //     );
  //   },
  // },
];

class OpeningBalanceSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payment_list: [
        {
          bank_id: '',
          amount: '',
          payment_method: 'IMPS',
          bank_reference_id: '',
          check_date: '',
          transaction_source: 'SELF',
          product_type: 'InitialBalance',
        },
      ],
      time_stamp: dayjs().format('YYYY-MM-DD'),
      update: false,
      editMode: false,
      isSubmitting: false,
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
    this.myRef = React.createRef();
  }

  handleDate = (e) => {
    if (this.state.editMode) {
      return;
    }
    this.setState({ time_stamp: e.target.value });
  };

  handleBankChange = (e) => {
    const list = [...this.state.payment_list];
    list[0].bank_id = e?.value || '';
    this.setState({ payment_list: list });
  };

  handleAmountChange = (e) => {
    const list = [...this.state.payment_list];
    list[0].amount = e.target.value;
    this.setState({ payment_list: list });
  };
  editMode = (item) => {
    this.setState({ editMode: false });
    this.setState({ isSubmitting: true });
    window.scrollTo({
      behavior: 'smooth',
      top: this.myRef.current.offsetTop - 100,
    });
    const tmp = item;
    const paymentList = [...this.state.payment_list];
    paymentList[0].bank = tmp.bank;
    paymentList[0].bank_id = tmp.bank?.uuid;
    paymentList[0].bank_reference_id = tmp.bank_reference_id;
    paymentList[0].check_date = tmp.check_date;
    paymentList[0].payment_method = tmp.payment_method;
    paymentList[0].transaction_source = tmp.source;
    paymentList[0].amount = tmp.balance;
    this.setState({
      payment_list: paymentList,
      time_stamp: dayjs(tmp.time_stamp).format('YYYY-MM-DD'),
      editMode: true,
      transaction_id: tmp.uuid,
    });
  };
  cancelEdit = () => {
    this.setState({
      payment_list: [
        {
          bank_id: '',
          amount: '',
          payment_method: 'IMPS',
          bank_reference_id: '',
          check_date: '',
          transaction_source: 'SELF',
          product_type: 'InitialBalance',
        },
      ],
      time_stamp: dayjs().format('YYYY-MM-DD'),
      updated: false,
      editMode: false,
      isSubmitting: false,
    });
  };

  submitForm = (e) => {
    e.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState({ isSubmitting: true });
    const formData = this.state;
    const headers = authHeader(1);
    if (this.state.editMode) {
      formData.payment_list[0].transaction_id = this.state.transaction_id;
      axios
        .put(`${process.env.API_URL}/payment/setup-bank-balance/`, formData, {
          headers,
        })
        .then(() => {
          toast.success('Opening balance updated successfully');
          window.scrollTo({
            behavior: 'smooth',
            top: this.myRef.current.offsetTop - 150,
          });
          this.setState({
            payment_list: [
              {
                bank_id: '',
                amount: '',
                payment_method: 'IMPS',
                bank_reference_id: '',
                check_date: '',
                transaction_source: 'SELF',
                product_type: 'InitialBalance',
              },
            ],
            time_stamp: dayjs().format('YYYY-MM-DD'),
            updated: !this.state.updated,
            isSubmitting: false,
          });
          this.validator.hideMessages();
        })
        .catch((error) => {
          toast.error(error.response.data.error);
          this.setState({ isSubmitting: false });
        });
    } else {
      axios
        .post(`${process.env.API_URL}/payment/setup-bank-balance/`, formData, {
          headers,
        })
        .then(() => {
          toast.success('Opening balance added successfully');
          window.scrollTo({
            behavior: 'smooth',
            top: this.myRef.current.offsetTop - 150,
          });
          this.setState({
            payment_list: [
              {
                bank_id: '',
                amount: '',
                payment_method: 'IMPS',
                bank_reference_id: '',
                check_date: '',
                transaction_source: 'SELF',
                product_type: 'InitialBalance',
              },
            ],
            time_stamp: dayjs().format('YYYY-MM-DD'),
            updated: !this.state.updated,
            isSubmitting: false,
          });
          this.validator.hideMessages();
        })
        .catch((error) => {
          toast.error(error.response.data.error);
          this.setState({ isSubmitting: false });
        });
    }
  };

  render() {
    const editMode = this.state.editMode;
    return (
      <>
        <AccessControl allowedRoles={['Dealer']}>
          <section className="widget full-width" ref={this.myRef}>
            <header>
              <h3>{editMode ? 'Edit' : ''} Opening Bank Balance</h3>
            </header>
            <main>
              <form className="inline">
                <div className="input-wrapper  with-label">
                  <label>Date</label>
                  <input
                    type="date"
                    value={this.state.time_stamp}
                    name="time_stamp"
                    onChange={this.handleDate}
                    disabled={editMode}
                  />
                </div>
                <div className="input-wrapper  with-label">
                  <label>Bank</label>
                  {this.validator.message(
                    'bank_id',
                    this.state.payment_list[0].bank_id,
                    'required',
                  )}
                  <BankList
                    id="settlement_bank"
                    handleBankChange={this.handleBankChange}
                    value={this.state.payment_list[0].bank_id}
                  />
                </div>
                <div className="input-wrapper  with-label">
                  <label>Amount</label>
                  {this.validator.message(
                    'amount',
                    this.state.payment_list[0].amount,
                    'required|numeric',
                  )}
                  <input
                    type="text"
                    value={this.state.payment_list[0].amount}
                    name="amount"
                    onChange={this.handleAmountChange}
                    placeholder="amount"
                  />
                </div>
                <div className="form-actions">
                  <button
                    className="submit"
                    type="submit"
                    disabled={this.state.isSubmitting}
                    onClick={this.submitForm}
                  >
                    Submit
                  </button>
                  {editMode && (
                    <button className="red-btn" onClick={this.cancelEdit}>
                      Cancel
                    </button>
                  )}
                </div>
              </form>
            </main>
          </section>
        </AccessControl>
        <AccountOpeningBalanceList
          key={this.state.updated}
          onEdit={this.editMode}
        />
      </>
    );
  }
}

const enabledActions = [
  {
    action: 'Edit',
    roles: ['Site Admin', 'Dealer'],
  },
  {
    action: 'Delete',
    roles: ['Site Admin', 'Dealer'],
  },
];

class AccountOpeningBalanceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountOpeningBalanceList: [],
      loading: true,
      limit: 20,
      offset: 1,
      count: 0,
    };
  }

  componentDidMount() {
    this.getAccountBalance();
  }

  getAccountBalance = () => {
    services
      .getAccountBalance(this.state)
      .then((response) => {
        this.setState({
          accountOpeningBalanceList: response.data.data.results,
          count: response.data.data.count,
        });
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  };
  handlePagination = (e) => {
    this.setState({ offset: e, loading: true }, function () {
      this.getAccountBalance();
    });
  };

  editItem = (item) => {
    this.props.onEdit(item.uuid);
  };
  confirmDelete = (item) => {
    confirmAlert({
      title: 'Confirm Opening Balance deletion',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteItem(item),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  deleteItem = (item) => {
    const headers = authHeader(1);
    axios
      .delete(
        `${process.env.API_URL}/payment/setup-bank-balance/?transaction_id=${item.uuid}`,
        {
          headers,
        },
      )
      .then(() => {
        toast.success('Opening balance deleted successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        this.getAccountBalance();
      });
  };

  render() {
    const { accountOpeningBalanceList } = this.state;
    return (
      <>
        <section className="widget full-width">
          <header>
            <h3> Opening Bank Balance List</h3>
          </header>
          <main className="content-wrapper">
            <TableGrid
              col={col}
              row={accountOpeningBalanceList}
              count={this.state.count}
              offset={this.state.offset}
              limit={this.state.limit}
              handlePagination={this.handlePagination}
              onEdit={this.props.onEdit}
              onDelete={this.confirmDelete}
              enabledActions={enabledActions}
            />
          </main>
        </section>
      </>
    );
  }
}
export { OpeningBalanceSetup };
