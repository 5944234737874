import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import services from '@C/CashiersOnDuty/_utils/data';
import { authHeader } from '@H';

import './styles.scoped.scss';

class EditCashSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cashier_id: this.props.cashier_id,
      cashier_duty_id: this.props.cashier_duty_id,
      cash_code: this.props.cash_code,
      time_stamp: this.props.time_stamp,
      denominationList: [],
      isSubmitting: false,
    };
  }

  componentDidMount() {
    this.getDenomination();
  }

  getDenomination = () => {
    const { cashier_duty_id, cash_code } = this.state;

    services
      .getDenomination(cashier_duty_id, cash_code)
      .then((response) => {
        const tmp = response.data.data;
        tmp.cash_denomination.forEach((item) => {
          (item.cash_code = this.state.cash_code),
            (item.uuid = item.denomination_details__uuid);
        });
        this.setState({ denominationList: tmp });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onInputChange = (e, i) => {
    const denominationList = this.state.denominationList;
    denominationList.cash_denomination[i] = {
      ...denominationList.cash_denomination[i],
      uuid: denominationList.cash_denomination[i].denomination_details__uuid,
      count: +e.target.value,
      amount:
        e.target.value *
        denominationList.cash_denomination[i].denomination_details__value,
      cash_code: this.state.cash_code,
      value: denominationList.cash_denomination[i].denomination_details__value,
    };
    this.setState({ denominationList: denominationList });
  };

  clearForm = () => {
    const clearedDenominationList =
      this.state.denominationList.cash_denomination.map((item) => ({
        ...item,
        count: 0,
        amount: 0,
      }));

    this.setState({
      denominationList: {
        ...this.state.denominationList,
        cash_denomination: clearedDenominationList,
        isSubmitting: false,
      },
    });
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({ isSubmitting: true });
    const formData = {
      cashier_id: this.state.cashier_id,
      cashier_duty_id: this.state.cashier_duty_id,
      time_stamp: this.state.time_stamp,
      cash_list: this.state.denominationList.cash_denomination,
    };
    const headers = authHeader(1);
    axios
      .post(`${process.env.API_URL}/bunk/cash-denomination/`, formData, {
        headers,
      })
      .then(() => {
        toast.success('Cash details added successfully');
        this.setState({ isSubmitting: false });
        this.props.onRequestClose();
        this.props.updateDenomination();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        this.setState({ isSubmitting: false });
      });
  };

  render() {
    const { denominationList } = this.state;
    const totalAmount = denominationList.cash_denomination?.reduce(
      (a, b) => a + b['amount'],
      0,
    );
    const { showModal } = this.props;
    return (
      <>
        <section className="widget full-width form-table form-wrap">
          <header>
            <h3> Denomination </h3>
          </header>
          <main>
            <div className="form-content">
              <table>
                <thead>
                  <tr>
                    <th> Denomination</th>
                    <th> Count </th>
                    <th> Amount </th>
                  </tr>
                </thead>
                <tbody>
                  {denominationList.cash_denomination &&
                    denominationList.cash_denomination.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <div className="input-wrapper">
                              <img
                                className="img-input"
                                src={item.denomination_icon}
                                alt="Money"
                              />{' '}
                              {item.denomination_details__value}
                            </div>
                          </td>
                          <td>
                            <div className="input-wrapper --wrap">
                              <input
                                type="text"
                                value={item.count}
                                name="count"
                                onChange={(e) => this.onInputChange(e, i)}
                                disabled={showModal}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="input-wrapper --wrap">
                              <input
                                type="text"
                                value={item.amount}
                                name="amount"
                                disabled={showModal}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div className="form-actions">
              <button className="submit" type="submit" onClick={this.clearForm}>
                Clear
              </button>

              <button className="red-btn" onClick={this.props.onRequestClose}>
                Close
              </button>
              <button
                className={`submit ${this.state.isSubmitting ? 'loading' : ''}`}
                type="submit"
                onClick={this.submitForm}
              >
                Submit
              </button>
            </div>
          </main>
          <div className="table-wrapper">
            <div className="mini-summary">
              <ul>
                <li>
                  <span>{`${denominationList.cash_management_name}`}: </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(denominationList.cash_management_amount || 0)}
                  </span>
                </li>
                <li>
                  <span>Denomination total: </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(totalAmount || 0)}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default EditCashSummary;
