import React from 'react';

import { Header, Navbar, NavPills } from '@C';
import ErrorBoundary from '@C/Error';
import { RouteSetup } from '@C/ManageBunk';

const routes = [
  {
    name: 'Tank Setup',
    path: '/account/setup/bunk/tank',
  },
  {
    name: 'Bank Setup',
    path: '/account/setup/bunk/bank',
  },
  {
    name: 'Wallet Setup',
    path: '/account/setup/bunk/wallet',
  },
  {
    name: 'POS Setup',
    path: '/account/setup/bunk/pos',
  },
  {
    name: 'Staff Setup',
    path: '/account/setup/bunk/staff',
  },

  {
    name: 'Opening Bank Balance',
    path: '/account/setup/bunk/account-opening-balance',
  },
  {
    name: ' Opening Cash Balance',
    path: '/account/setup/bunk/opening-cash-balance',
  },
  {
    name: ' SMS Setup',
    path: '/account/setup/bunk/sms-setup',
    display: false,
  },
];

const BunkSetup = () => {
  return (
    <main>
      <div className="page-wrapper">
        <Header title="Bunk Setup" />
        <Navbar />
        <div className="page-content">
          <section className="page-components">
            <div className="page-title">
              <h1>Bunk Setup</h1>
            </div>
            <ErrorBoundary>
              <NavPills routes={routes} />
              <RouteSetup />
            </ErrorBoundary>
          </section>
        </div>
      </div>
    </main>
  );
};

export { BunkSetup };
