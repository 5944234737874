import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';

import { TableGrid } from '@C/TableGrid';

const col = [
  {
    name: 'Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Party name',
    key: '',
  },
  {
    name: 'Staff name',
    key: '',
  },
  {
    name: 'Amount taken',
    key: '',
  },
  {
    name: 'Amount return',
    key: '',
  },
  {
    name: 'Interest',
    key: '',
  },
  {
    name: 'Total amount',
    key: '',
  },
  {
    name: 'Principal amount',
    key: '',
  },
  {
    name: 'Outstanding',
    key: '',
  },
  {
    name: 'Payment mode',
    key: '',
  },
  {
    name: 'Notes',
    key: '',
  },
];

const BorrowList = () => {
  const [state, setState] = useState({
    loanList: [],
    time_stamp: dayjs().format('YYYY-MM-DD'),
    party_name: '',
    limit: 20,
    offset: 1,
    count: 0,
    loading: true,
  });

  const limitInput = useRef();

  useEffect(() => {
    // Add your componentDidMount logic here
  }, []);

  return (
    <>
      <section className="widget full-width list-table">
        <header>
          <h3> Amount given/Lend List </h3>
        </header>
        <main>
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group">
                <label>Date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={state.time_stamp}
                  name="time_stamp"
                  onChange={(e) =>
                    setState({ ...state, time_stamp: e.target.value })
                  }
                />
              </div>
              <div className="filter-group">
                <label>Party name:</label>
                <input
                  type="text"
                  value={state.party_name}
                  name="party_name"
                  onChange={(e) =>
                    setState({ ...state, party_name: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <main className="content-wrapper">
            <TableGrid
              col={col}
              row={state.loanList}
              count={state.count}
              hideActions={true}
            />
          </main>
        </main>
      </section>
    </>
  );
};

export default BorrowList;
