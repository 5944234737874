import React, { Component } from 'react';
import dayjs from 'dayjs';
import Select from 'react-select';

import services from './data';

let CategoryOptions;

class FilteredStaffList extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      options: [],
      date: dayjs('2077-01-01').format('YYYY-MM-DD'),
    };
  }
  componentDidMount() {
    services
      .getFilteredStaffList(this.state.date)
      .then((response) => {
        const tmp = response.data.data.duties_list;
        CategoryOptions = [];
        const dealers = [];
        const managers = [];
        tmp.map((item, i) => {
          if (item.role_type == 101) {
            dealers.push({
              value: item.staff_uuid,
              label: `${tmp[i].first_name} ${tmp[i].last_name}`,
            });
          } else {
            managers.push({
              value: item.staff_uuid,
              label: `${tmp[i].first_name} ${tmp[i].last_name}`,
            });
          }
        });

        CategoryOptions = [
          {
            label: 'Dealer',
            options: dealers,
          },
          {
            label: 'Managers',
            options: managers,
          },
        ];
        this.setState({ options: CategoryOptions });
        // this.props.onChange({ value: null, duty: null });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleChange = (selectedOption) => {
    this.props.onChange(selectedOption);
  };

  render() {
    const { options } = this.state;
    return (
      <Select
        onChange={this.handleChange}
        options={options}
        className="select-box-container staff-selector-container"
        classNamePrefix="staff-selector"
        placeholder="Select staff"
        value={options
          .flatMap((ind) => ind.options)
          .filter((item) => item.value === this.props.value)}
        isDisabled={this.props.disabled || false}
      />
    );
  }
}

export { FilteredStaffList };
