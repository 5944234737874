import React, { Component } from 'react';
import dayjs from 'dayjs';

import services from '@C/Reports/_utils/data';
import { TableGrid } from '@C/TableGrid';
import { TankList } from '@C/Utils';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';

const col = [
  {
    name: 'Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Tank',
    key: 'tank',
    formatter(props) {
      return <>{props.name}</>;
    },
  },
  {
    name: 'Opening Stock',
    key: 'opening_stock',
    formatter(props) {
      return <>{props.toFixed(2)}</>;
    },
  },
  {
    name: 'Closing Stock',
    key: 'closing_stock',
    formatter(props) {
      return <>{props.toFixed(2)}</>;
    },
  },
  {
    name: 'Receipt/ Purchase',
    key: 'purchase',
    formatter(props) {
      return <>{props.toFixed(2)}</>;
    },
  },
  {
    name: 'Total Stock',
    key: 'total_stock',
    formatter(props) {
      return <>{props.toFixed(2)}</>;
    },
  },
  {
    name: 'Sales By Meter',
    key: 'sales_by_meter',
    formatter(props) {
      return <>{props.toFixed(2)}</>;
    },
  },
  {
    name: 'Sales by Dip',
    key: 'sales_by_dip',
    formatter(props) {
      return <>{props.toFixed(2)}</>;
    },
  },
  {
    name: 'Testing',
    key: 'testing',
    formatter(props) {
      return <>{props.toFixed(2)}</>;
    },
  },
  {
    name: 'Bowser Filling/Tank Filling',
    key: 'bowser_filling',
    formatter(props) {
      return <>{props.toFixed(2)}</>;
    },
  },
  {
    name: 'Net Sales By Meter',
    key: 'net_sales_by_meter',
    formatter(props) {
      return <>{props.toFixed(2)}</>;
    },
  },
  {
    name: 'Cumulative Sales',
    key: 'cumulative_sale',
    formatter(props) {
      return <>{props.toFixed(2)}</>;
    },
  },
  {
    name: 'Daily Variation',
    key: `variation`,
    formatter(props) {
      return <>{props.daily.toFixed(2)}</>;
    },
  },
  {
    name: 'Cumulative Variation',
    key: `variation`,
    formatter(props) {
      return <>{props.cumulative.toFixed(2)}</>;
    },
  },
];

class MonthlyReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slipList: [],
      selectedId: '',
      showModal: false,
      staff_id: '',
      start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
      tank_id: '',
      slip_no: '',
      vehicle_number: '',
      loading: false,
      productList: this.props.products,
      tankList: this.props.tank,
      limit: 20,
      offset: 1,
      count: 0,
      month: dayjs().format('YYYY-MM'),
    };
    this.limitInput = React.createRef();
  }

  componentDidMount() {
    this.getMonthlyReport();
    const observer = new IntersectionObserver(this.handleObserver.bind(this), {
      threshold: 0.5,
    });
    this.setState({ observer });
    observer.observe(this.loader);
  }

  componentWillUnmount() {
    const { observer } = this.state;
    if (observer) {
      observer.disconnect();
    }
  }

  handleObserver(entities) {
    const { loading, count, limit, offset } = this.state;
    const loadMore = entities[0];
    if (loadMore.isIntersecting && !loading && count > offset * limit) {
      this.setState(
        (prevState) => ({ offset: prevState.offset + 1, loading: true }),
        () => this.getMonthlyReport(),
      );
    }
  }

  handleQuickSearch = (e) => {
    e.preventDefault();
    if (
      this.limitInput.current.value === '' ||
      this.limitInput.current.value === '0'
    ) {
      return;
    }
    this.setState(
      { limit: this.limitInput.current.value, offset: 1 },
      function () {
        this.getMonthlyReport();
      },
    );
  };

  getMonthlyReport = () => {
    const { tank_id, start_date, end_date, limit, offset } = this.state;
    this.setState({ loading: true });
    services
      .getMonthlyReport({ tank_id, start_date, end_date, limit, offset })
      .then((response) => {
        this.setState((prevState) => ({
          slipList:
            offset === 1
              ? response.data.data.results
              : [...prevState.slipList, ...response.data.data.results],
          count: response.data.data.count,
          loading: false,
        }));
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  handleDateChange = (e) => {
    const start = `${e.target.value}-01`;
    const end = `${e.target.value}-31`;
    this.setState({
      month: e.target.value,
      start_date: start,
      end_date: end,
    });
  };

  handleTankChange = (e) => {
    this.setState({ tank_id: e.value });
  };

  resetFilter = () => {
    this.limitInput.current.value = 20;
    this.setState(
      {
        staff_id: '',
        start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
        month: dayjs().format('YYYY-MM'),
        product_uuid: '',
        tank_id: '',
        loading: true,
        slip_number: '',
        limit: 20,
        offset: 1,
      },
      function () {
        this.getMonthlyReport();
      },
    );
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({ offset: 1 }, function () {
      this.getMonthlyReport();
    });
  };

  render() {
    const { slipList } = this.state;

    return (
      <section className="widget full-width list-table inverted">
        <header>
          <h3>Monthly Sales Report</h3>
        </header>
        <main>
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group">
                <label>Month:</label>
                <input
                  className="cstm-date-input"
                  type="month"
                  value={this.state.month}
                  max={dayjs().format('YYYY-MM')}
                  name="start_date"
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="filter-group">
                <label>Tank:</label>
                <TankList
                  onChange={this.handleTankChange}
                  value={this.state.tank_id}
                  options={this.state.tankList}
                />
              </div>
              <div className="filter-group with-icon">
                <label>
                  Results to display- {this.state.limit || 20} (Total:{' '}
                  {this.state.count})
                </label>
                <form onSubmit={this.handleQuickSearch}>
                  <input type="text" name="limit" ref={this.limitInput} />
                  <button
                    type="submit"
                    className="search-icon"
                    onClick={this.handleQuickSearch}
                  />
                </form>
              </div>
              <div className="filter-group reset-filter">
                <button onClick={this.resetFilter}>Reset filters</button>
              </div>
              <div className="filter-group">
                <button
                  className="submit"
                  type="submit"
                  onClick={this.submitForm}
                >
                  Apply filter
                </button>
              </div>
            </div>
          </div>
          <div className="table-wrapper">
            {(() => {
              if (this.state.loading) {
                return <DropLoaderLarge />;
              } else {
                return (
                  <TableGrid
                    col={col}
                    row={slipList}
                    offset={this.state.offset}
                    count={this.state.count}
                    limit={this.state.limit}
                    pagination="disabled"
                    hideActions={true}
                    exportOptions={{
                      fileName: `Monthly Report - ${this.state.start_date} to ${this.state.end_date}.pdf`,
                      reportName: 'Monthly Report',
                      reportDate: `Date - ${dayjs(this.state.start_date).format(
                        'D MMM YY',
                      )} to ${dayjs(this.state.end_date).format('D MMM YY')}`,
                      reportTotal: '',
                      reportQuantity: '',
                      reportSales: '',
                      reportTesting: '',
                      reportBowser: '',
                      reportCustomerName: '',
                      reportGst: '',
                      reportOpeningBalance: '',
                      reportClosingBalance: '',
                      reportSaleTotal: '',
                      reportTCSTotal: '',
                    }}
                  />
                );
              }
            })()}
            <div
              ref={(ref) => {
                this.loader = ref;
              }}
            ></div>
          </div>
        </main>
      </section>
    );
  }
}

export { MonthlyReport };
