import axios from 'axios';

import { authHeader } from '@H';

export default {
  getCashDepositBook: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/setup-bank-balance/`,
    }),

  getCashBookBalance: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/setup-cashbook/`,
    }),
  getAccountBalance: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/transactions/?product_type=InitialBalance`,
    }),
};
