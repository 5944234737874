import React, { useState, useEffect } from 'react';
import { TableGrid } from '@C/TableGrid';
import axios from 'axios';
import { toast } from 'react-toastify';
import { authHeader } from '@H';
import { confirmAlert } from 'react-confirm-alert';
import services from '@C/Admin/SmsSetup/_utils/data';

const col = [
  {
    name: 'Date',
    key: 'created_on',
    type: 'date',
  },
  {
    name: 'Dealer',
    key: 'bunk',
    formatter(props) {
      return <>{props.name}</>;
    },
  },
  {
    name: 'mobile',
    key: 'bunk',
    formatter(props) {
      return <>{props.mobile_number}</>;
    },
  },
  {
    name: 'Package requested',
    key: 'package',
    formatter(props) {
      return <>{props.name}</>;
    },
  },
  {
    name: 'Package Price',
    key: 'paid_amount',
    type: 'currency',
  },
  {
    name: 'Request Status',
    key: 'recharge_status',
  },
  {
    name: 'SMS Status',
    key: 'all',
    formatter(props) {
      return (
        <>
          {props.remaining_sms_count} / {props.package.sms}
        </>
      );
    },
  },
];

const SMSList = (props) => {
  const [list, setList] = React.useState([]);
  const [state, setState] = useState({
    offset: 1,
    count: 0,
    limit: 20,
  });

  useEffect(() => {
    getSMSRequestList();
  }, [, state]);

  const getSMSRequestList = () => {
    services.getSMSrequestList(state).then((response) => {
      setList(response.data.data.results);
    });
  };

  const handlePagination = (e) => {
    setState((prev) => ({ ...prev, offset: e }));
  };

  const activateSMS = (e, action) => {
    const formData = {
      bunk_id: e.bunk.account_id,
      uuid: e.uuid,
      recharge_status: action,
    };
    const headers = authHeader(1);

    axios
      .put(`${process.env.API_URL}/sms-recharge/`, formData, { headers })
      .then(() => {
        toast.success(
          `SMS request ${action == 'ACTIVE' ? 'activated' : 'rejected'} successfully`,
        );
        getSMSRequestList();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const confirmSMSAction = (e, mode) => {
    confirmAlert({
      title: `${mode == 'ACTIVE' ? 'Activate SMS Request' : 'Reject SMS Request'}`,
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => activateSMS(e, mode),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  const enabledActions = [
    {
      action: 'Approve',
      roles: ['Site Admin'],
      icon: 'approve',
      callback: (e) => {
        confirmSMSAction(e, 'ACTIVE');
      },
    },
    {
      action: 'Reject',
      roles: ['Site Admin'],
      icon: 'reject',
      callback: (e) => {
        confirmSMSAction(e, 'DECLINE');
      },
    },
  ];

  return (
    <>
      <section className="widget full-width">
        <header className="title-wrapper">
          <h3> Pending Requests </h3>
        </header>
        <main className="content-wrapper">
          <TableGrid
            col={col.filter((e) => e.name !== 'SMS Status')}
            row={list.filter((e) => e.recharge_status === 'PENDING')}
            offset={state.offset}
            count={state.count}
            limit={state.limit}
            handlePagination={handlePagination}
            enabledActions={enabledActions}
          />
        </main>
      </section>
      <section className="widget full-width">
        <header className="title-wrapper">
          <h3> Active Plans </h3>
        </header>
        <main className="content-wrapper">
          <TableGrid
            col={col}
            row={list.filter((e) => e.recharge_status === 'ACTIVE')}
            offset={state.offset}
            count={state.count}
            limit={state.limit}
            handlePagination={handlePagination}
            enabledActions={enabledActions.filter((e) => e.action === 'Reject')}
          />
        </main>
      </section>
      <section className="widget full-width">
        <header className="title-wrapper">
          <h3> Rejected Requests </h3>
        </header>
        <main className="content-wrapper">
          <TableGrid
            col={col.filter((e) => e.name !== 'SMS Status')}
            row={list.filter((e) => e.recharge_status === 'DECLINE')}
            offset={state.offset}
            count={state.count}
            limit={state.limit}
            handlePagination={handlePagination}
            enabledActions={enabledActions.filter(
              (e) => e.action === 'Approve',
            )}
          />
        </main>
      </section>
    </>
  );
};

export default SMSList;
