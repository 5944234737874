import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';

import services from '@C/Admin/SmsSetup/_utils/data';
import { TableGrid } from '@C/TableGrid';
import { authHeader } from '@H';

const col = [
  {
    name: 'Package name',
    key: 'name',
  },
  {
    name: 'No. of SMS',
    key: 'sms',
  },
  {
    name: 'Price',
    key: 'amount',
  },
];

const enabledActions = [
  {
    action: 'Delete',
    roles: ['Site Admin'],
  },
  {
    action: 'Edit',
    roles: ['Site Admin'],
  },
];

const PackageList = (props) => {
  const [packageData, setPackageData] = useState({
    offset: 1,
    count: 0,
    limit: 20,
    uuid: '',
  });
  const [packageList, setPackageList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPackageList();
  }, []);

  const getPackageList = () => {
    services
      .getPackageList(packageData)
      .then((response) => {
        const tmp = response.data.data.results;
        const packageList = tmp;
        setPackageList(tmp);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePagination = (e) => {
    setPackageData({ ...packageData, offset: e });
  };

  const confirmDelete = (item) => {
    confirmAlert({
      title: 'Confirm Package deletion',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteItem(item),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  const deleteItem = (item) => {
    const headers = authHeader();
    axios
      .delete(
        `${process.env.API_URL}/utils/sms_package/?sms_pack_id=${item.uuid}`,
        {
          headers,
        },
      )
      .then(() => {
        toast.success('Package deleted successfully');
        getPackageList();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <section className="widget full-width">
        <header className="title-wrapper">
          <h3> SMS Package List </h3>
        </header>
        <main className="content-wrapper">
          <TableGrid
            col={col}
            row={packageList}
            offset={packageData.offset}
            count={packageData.count}
            limit={packageData.limit}
            onEdit={props.onEdit}
            onDelete={confirmDelete}
            handlePagination={handlePagination}
            enabledActions={enabledActions}
          />
        </main>
      </section>
    </>
  );
};

export { PackageList };
