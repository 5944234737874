import axios from 'axios';

import { authHeader } from '@H';

export default {
  getAllWallets: () =>
    axios({
      method: 'GET',
      headers: authHeader(),
      url: `${process.env.API_URL}/utils/wallets/`,
    }),
  getMappedWallets: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/mapped-wallets/list/`,
    }),
};
