import React, { Component } from 'react';
import Select from 'react-select';

import services from './data';

let CategoryOptions;

class AllPosList extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      options: [],
    };
  }
  componentDidMount() {
    services
      .getAllPos()
      .then((response) => {
        const tmp = response.data.data;
        CategoryOptions = [];
        for (let i = 0; i < tmp.length; i++) {
          CategoryOptions.push({
            value: tmp[i].uuid,
            label: tmp[i].bank_name,
          });
        }
        this.setState({ options: CategoryOptions });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleChange = (selectedOption) => {
    this.props.onChange(selectedOption);
  };

  render() {
    const { options } = this.state;
    return (
      <Select
        onChange={this.handleChange}
        options={options}
        className="select-box-container"
        classNamePrefix="raw-select"
        name="oml"
        placeholder="Select POS machine"
        value={this.props.value}
        isDisabled={this.props.disabled || false}
      />
    );
  }
}

export { AllPosList };
