import React from 'react';

import { Expense } from '@C/ManageIE/RouteComponents/';
import { useGlobalStore } from '@stores/globalStore';

const Expenses = (props) => {
  const { syncCODCollectionData } = useGlobalStore((state) => ({
    syncCODCollectionData: state.syncCODCollectionData,
  }));

  const updateTotalSummary = () => {
    syncCODCollectionData(props.match.params.duty, props.match.params.staff);
  };

  return (
    <>
      <Expense {...props} cod={true} onDataChange={updateTotalSummary} />
    </>
  );
};

export { Expenses };
