import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import { authHeader } from '@H';

import './styles.scoped.scss';

class ResetForm extends Component {
  constructor() {
    super();
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
  }

  state = {
    otp: '',
    isSubmitting: false,
  };

  componentDidMount() {
    const formData = {
      from_app: 'RESET',
    };
    const headers = authHeader(1);
    axios
      .post(`${process.env.API_URL}/two-step-auth/`, formData, { headers })
      .then(() => {
        toast.success('Generated OTP successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })

      .catch((err) => {
        toast.error(err.data.message, {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  handleChange = (e) => {
    this.setState({ otp: e.target.value });
  };

  submitForm = (event) => {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    const formData = {
      otp: +this.state.otp,
    };
    const headers = authHeader(1);
    axios
      .delete(
        `${process.env.API_URL}/bunk/bunk_account_reset/?reset=complete&otp=${this.state.otp}`,
        { headers },
      )
      .then(() => {
        toast.success('Account reset completely successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.validator.hideMessages();
        window.location.reload();
        this.setState({ isSubmitting: false });
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        this.setState({ isSubmitting: false });
      });
  };

  cancelForm = () => {
    window.location.reload();
    this.setState({
      otp: '',
      isSubmitting: false,
    });
  };

  render() {
    return (
      <>
        <section className="widget form-card form-length">
          <main className="form-content">
            <form className="inline">
              <div className="input-wrapper with-label">
                {this.validator.message('otp', this.state.otp, 'required')}
                <label> Enter the OTP</label>
                <input
                  type="text"
                  id="otp"
                  name="otp"
                  onChange={this.handleChange}
                  value={this.state.otp}
                />
              </div>
              <div className="cta-wrapper">
                <button
                  className="submit"
                  type="submit"
                  disabled={this.state.isSubmitting}
                  onClick={this.submitForm}
                >
                  Reset Account
                </button>
                <button className="red-btn" onClick={this.cancelForm}>
                  Cancel
                </button>
              </div>
            </form>
          </main>
        </section>
      </>
    );
  }
}
export default ResetForm;
