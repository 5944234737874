import React, { useState } from 'react';

import LoanList from '@C/Loans/RouteComponents/Parties/LoanList';

const Parties = () => {
  const [state, setState] = useState({
    party_name: '',
    mobile_no: '',
    editMode: false,
    updated: false,
    isSubmitting: false,
  });

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const cancelEdit = () => {
    setState({
      party_name: '',
      mobile_no: '',
      updated: false,
      editMode: false,
      isSubmitting: false,
    });
  };

  const submitForm = (e) => {
    // Add your form submission logic here
  };

  const { editMode } = state;

  return (
    <>
      <section className="widget full-width">
        <header>
          <h3> Add new loan party name </h3>
        </header>
        <main>
          <form className="inline">
            <div className="input-wrapper with-label ">
              <label>Party name </label>
              <input
                type="text"
                onChange={handleChange}
                value={state.party_name}
                name="party_name"
              />
            </div>
            <div className="input-wrapper with-label ">
              <label> Mobile no. </label>
              <input
                type="text"
                value={state.mobile_no}
                name="mobile_no"
                onChange={handleChange}
              />
            </div>
            <div className="form-actions">
              <button
                className="submit"
                disabled={state.isSubmitting}
                onClick={submitForm}
              >
                Submit
              </button>
              {editMode && (
                <button className="red-btn" onClick={cancelEdit}>
                  Cancel
                </button>
              )}
            </div>
          </form>
        </main>
      </section>
      <LoanList
        onEdit={() => setState({ ...state, editMode: true })}
        key={state.updated}
      />
    </>
  );
};

export { Parties };
