import React, { Component } from 'react';

import { Header, Navbar, NavPills } from '@C';
import { RouteSetup } from '@C/Admin/SmsSetup';
import ErrorBoundary from '@C/Error';
import { authService } from '@S';

const routes = [
  {
    name: 'SMS Requests',
    path: '/admin/sms-setup/activate-sms',
  },
  {
    name: 'Manage Packages',
    path: '/admin/sms-setup/manage-packages',
  },
];

class SmsSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const role = authService.currentUserRole;
    return (
      <main>
        <div className="page-wrapper">
          <Header title="Referral program" />
          <Navbar />
          <div className="page-content">
            <section className="page-components">
              <div className="page-title">
                <h1> SMS Setup </h1>
              </div>
              <ErrorBoundary>
                <NavPills routes={routes} />
              </ErrorBoundary>
              <ErrorBoundary>
                {role === 'Site Admin' && <RouteSetup />}
              </ErrorBoundary>
            </section>
          </div>
        </div>
      </main>
    );
  }
}

export { SmsSetup };
