import React from 'react';

import { Header, Navbar } from '@C';
import { OnDuty } from '@C/CashiersOnDuty/RouteComponents/AssignDuty';
import { CreditCustomerDashboard } from '@C/CreditCustomerProfile/DashboardProfile';
import {
  CashierActiveDuty,
  DashboardCharts,
  DashboardFuelPrice,
  MiniDsr,
} from '@C/Dashboard/Components';
import { SummaryStatus } from '@C/DipReading/RouteComponents';
import ErrorBoundary from '@C/Error';
import { authService } from '@S';

import './dashboard.scoped.scss';

const Dashboard = () => {
  const role = authService.currentUserRole;
  const userId = authService.currentUserId;

  return (
    <main>
      <div className="page-wrapper">
        <Header title="Dashboard" />
        <Navbar />
        <div className="page-content">
          <section className="page-components">
            <div className="page-title">
              <h1>Dashboard</h1>
            </div>

            <ErrorBoundary>
              {(role === 'Dealer' || role === 'Manager') && (
                <DashboardFuelPrice />
              )}
              {(role === 'Dealer' || role === 'Manager') && (
                <SummaryStatus className="asd" />
              )}
              {(role === 'Dealer' || role === 'Manager') && (
                <OnDuty uuid={userId} />
              )}
              {role === 'Cashier' && <CashierActiveDuty uuid={userId} />}
              {(role === 'Dealer' || role === 'Manager') && <MiniDsr />}
              {(role === 'Dealer' || role === 'Manager') && <DashboardCharts />}
              {role === 'Credit Customer' && <CreditCustomerDashboard />}
            </ErrorBoundary>
          </section>
        </div>
      </div>
    </main>
  );
};

export { Dashboard };
