/* eslint-disable new-cap */
import React, { Component } from 'react';

class SmsSetup extends Component {
  constructor() {
    super();
    this.state = {
      active_sms: '',
      total_sms: '',
      request_sms: '',
      amount: '',
      isSubmitting: false,
    };
  }

  handleChange = () => {};

  submitForm = () => {};
  render() {
    return (
      <section className="widget full-width">
        <main className="sub-wrapper">
          <form className="inline">
            <div className="input-wrapper with-label">
              <label> Activate SMS </label>
              <input
                type="text"
                onChange={this.handleChange}
                value={''}
                name="active_sms"
              />
            </div>
            <div className="input-wrapper with-label">
              <label> Total SMS </label>
              <input
                type="text"
                onChange={this.handleChange}
                value={''}
                name="total_sms"
              />
            </div>
            <div className="input-wrapper with-label">
              <label> Request for SMS (Select Package) </label>
              <input
                type="text"
                onChange={this.handleChange}
                value={''}
                name="request_sms"
              />
            </div>
            <div className="input-wrapper with-label">
              <label> Amount to pay </label>
              <input
                type="text"
                onChange={this.handleChange}
                value={''}
                name="amount"
              />
            </div>
            <div className="form-actions">
              <button className="submit" onClick={this.submitForm}>
                Submit
              </button>
            </div>
          </form>
        </main>
      </section>
    );
  }
}

export { SmsSetup };
