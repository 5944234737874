import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import { ToggleSwitch } from '@C/FormElements';
import { ChartList } from '@C/Utils';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { authHeader } from '@H';

import services from './data';
import ProductList from './ProductList';

const TankDetails = (props) => {
  const [, forceUpdate] = useState();
  const [loading, setLoading] = useState(true);
  const simpleValidator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }),
  );
  const [state, setState] = useState({
    edit: false,
    tank: {},
    product: {},
    calibration_chart: {},
    guns: [],
  });

  useEffect(() => {
    getTankDetails();
  }, []);

  const getTankDetails = () => {
    services
      .getTank(props.tankId)
      .then((response) => {
        const tank = response.data.data;
        setState((prevState) => ({
          ...prevState,
          tank,
          product: tank.product,
          guns: tank.guns,
          calibration_chart: tank.calibration_chart,
        }));
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleProductChange = (e) => {
    setState({ ...state, product: { ...state.product, uuid: e.value } });
  };

  const handleChartChange = (e) => {
    setState({
      ...state,
      calibration_chart: { ...state.calibration_chart, uuid: e.value },
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, tank: { ...state.tank, [name]: value } });
  };

  const handleBowserChange = (e) => {
    setState({
      ...state,
      tank: { ...state.tank, is_bowser: e.target.checked },
    });
  };

  const handleGunChange = (e, id) => {
    const { value } = e.target;
    const { guns } = state;
    guns[id].name = value;
    setState({ ...state, guns });
  };

  const addGun = () => {
    const { guns } = state;
    guns.push({ name: '' });
    setState({ ...state, guns });
  };

  const deleteStaticGun = (id) => {
    const { guns } = state;
    guns.splice(id, 1);
    setState({ ...state, guns });
  };

  const toggleEdit = (refresh) => {
    setState({ ...state, edit: !state.edit });
    if (refresh) {
      getTankDetails();
    }
  };

  const updateTank = () => {
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const formData = {
      tanks: [
        {
          tank_uuid: state.tank.uuid,
          product_uuid: state.product.uuid,
          calibration_chart_id: state.calibration_chart.uuid || null,
          capacity: state.tank.capacity,
          name: state.tank.name,
          gun_list: state.guns,
          number_of_guns: state.guns.length,
          is_bowser: state.tank.is_bowser,
        },
      ],
    };

    const headers = authHeader(1);
    axios
      .put(`${process.env.API_URL}/bunk/tank-mapping/`, formData, { headers })
      .then(() => {
        toast.success('Tank updated successfully');
        toggleEdit(true);
        getTankDetails();
        props.onUpdate();
        window.location.reload();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };
  const confirmDelete = (tankName) => {
    confirmAlert({
      title: 'Confirm tank deletion',
      message: `Are you sure you want to delete "${tankName}"?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteItem(),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  const confirmGunDelete = (id) => {
    confirmAlert({
      title: 'Confirm gun deletion',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteGun(id),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  const deleteItem = () => {
    const headers = authHeader(1);
    axios
      .delete(
        `${process.env.API_URL}/bunk/tank-mapping/?uuid=${state.tank.uuid}&delete_type=tank`,
        { headers },
      )
      .then(() => {
        toast.success('Tank deleted successfully');
        props.onUpdate();
      })
      .catch((error) => {
        toast.error('Error deleting tank');
      });
  };

  const deleteGun = (id) => {
    const headers = authHeader(1);
    axios
      .delete(
        `${process.env.API_URL}/bunk/tank-mapping/?uuid=${id}&delete_type=gun`,
        { headers },
      )
      .then(() => {
        toast.success('Gun deleted successfully');
        getTankDetails();
        props.onUpdate();
      })
      .catch((error) => {
        toast.error('Error deleting gun');
      });
  };

  if (loading) {
    return <DropLoaderLarge />;
  }

  return (
    <>
      <section className="widget form-card">
        <header>
          <div className="actions space-between">
            <button
              className={
                !state.edit
                  ? `white-btn btn-with-icon btn-with-icon__edit`
                  : `red-btn`
              }
              onClick={() => {
                state.edit ? toggleEdit(true) : toggleEdit(false);
              }}
            >
              {`${state.edit ? 'Cancel' : 'Edit tank'}`}
            </button>
            <button
              className="red-btn"
              onClick={() => confirmDelete(state.tank.name)}
            >
              Delete tank
            </button>
            <button
              className="trigger-close"
              onClick={() => props.collapseCard()}
            >
              Close
            </button>
          </div>
        </header>
        <main className="two-col">
          <div className="col">
            <div className="col-header">
              <h4>Tank Details</h4>
            </div>
            <div className="input-wrapper with-label">
              <label> Name </label>
              {simpleValidator.current.message(
                'Tank Name',
                state.tank.name,
                'required',
              )}
              <input
                type="text"
                disabled={!state.edit}
                value={state.tank.name}
                name="name"
                onChange={(event) => handleChange(event)}
              />
            </div>
            <div className="input-wrapper with-label">
              <label> Capacity </label>
              {simpleValidator.current.message(
                'Capacity',
                state.tank.capacity,
                'required|integer|min:1,num',
              )}
              <input
                type="text"
                disabled={!state.edit}
                value={state.tank.capacity}
                name="capacity"
                onChange={(event) => handleChange(event)}
              />
            </div>
            <div className="input-wrapper with-label">
              <label> Product </label>
              {simpleValidator.current.message(
                'Product',
                state.product.uuid,
                'required',
              )}
              <ProductList
                handleProductChange={handleProductChange}
                value={state.product.uuid}
                disabled={!state.edit}
              />
            </div>
            <div className="input-wrapper with-label">
              <label> Link tank with appropriate calibration chart </label>
              <ChartList
                handleChartChange={handleChartChange}
                // value={state.calibration_chart.name}
                value={state.calibration_chart.uuid}
                disabled={!state.edit}
              />
            </div>
            <div className="input-wrapper  toggle-element">
              <span className="label">is bowser: </span>
              <ToggleSwitch
                id="edit_is_bowser"
                name="is_bowser"
                checked={state.tank.is_bowser}
                onChange={handleBowserChange}
                disabled={!state.edit}
              />
            </div>
          </div>
          <div className="col">
            <div className="col-header">
              <h4>Guns List</h4>
            </div>
            <div className="inline-input-group">
              {state.guns.map((item, i) => {
                if (!item.is_deleted) {
                  return (
                    <div className="input-wrapper with-cta" key={i}>
                      {simpleValidator.current.message(
                        'Gun name',
                        state.guns[i].name,
                        'required',
                      )}
                      <input
                        type="text"
                        disabled={!state.edit}
                        value={item.name}
                        onChange={() => handleGunChange(event, i)}
                      />
                      <button
                        disabled={!state.edit}
                        className={
                          item.gun_uuid
                            ? 'actions __delete'
                            : 'actions __cross-mark-red'
                        }
                        onClick={
                          item.gun_uuid
                            ? () => confirmGunDelete(item.gun_uuid)
                            : () => deleteStaticGun(i)
                        }
                      >
                        {' '}
                      </button>
                    </div>
                  );
                }
              })}
              {state.edit &&
                state.guns.filter((item) => !item.is_deleted).length < 21 && (
                  <button className="blue-btn" onClick={addGun}>
                    Add gun
                  </button>
                )}
            </div>
          </div>
          {state.edit && (
            <div className="widget-actions">
              <button
                className={`submit ${loading ? 'loading' : ''}`}
                onClick={updateTank}
              >
                Update
              </button>
            </div>
          )}
        </main>
      </section>
    </>
  );
};

export { TankDetails };
