import React, { Component } from 'react';

export class CreditSlipsSummary extends Component {
  constructor() {
    super();
    this.state = {
      tab: 1,
    };
  }

  render() {
    const summary = this.props.summary;
    return (
      <>
        <section className="widget dsr-table">
          <header></header>
          <main className="content-wrapper">
            <table className="dsr" data-name="Credit Slips">
              <thead>
                <tr>
                  <th colSpan={7} className="alert-brand">
                    Credit Sales
                  </th>
                </tr>
                <tr data-header={true}>
                  <th>#</th>
                  <th>Customer name</th>
                  <th>Slip count</th>
                  <th>Product</th>
                  <th>Product type</th>
                  <th>Quantity</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {summary
                  .slice()
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((val, key) => {
                    return (
                      <tr key={key}>
                        <td>{key + 1}</td>
                        <td>{val.name}</td>
                        <td>{val.slip_count}</td>
                        <td>{val.product}</td>
                        <td>{val.product_type}</td>
                        <td>{val.total_quantity.toFixed(2)}</td>
                        <td>
                          {new Intl.NumberFormat('en-IN', {
                            style: 'currency',
                            currency: 'INR',
                          }).format(val.total_amount.toFixed(2) || 0)}
                        </td>
                      </tr>
                    );
                  })}
                <tr className="summary-row">
                  <td colSpan="5">Total </td>
                  <td>
                    {parseFloat(
                      summary.reduce(
                        (a, b) => a + (b['total_quantity'] || 0),
                        0,
                      ),
                    ).toFixed(2) || 0}
                  </td>
                  <td>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      summary.reduce(
                        (a, b) => a + (b['total_amount'] || 0),
                        0,
                      ) || 0,
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </main>
        </section>
      </>
    );
  }
}
