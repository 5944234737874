import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import { CustomerList } from '@C/CreditCustomers/_utils/';
import GenerateQuotationList from '@C/CreditCustomers/RouteComponents/GenerateQuotation/GenerateQuotationList';
import { authHeader } from '@H';

class GenerateQuotation extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      time_stamp: dayjs().format('YYYY-MM-DD'),
      quotation_no: '',
      credit_customer_uuid: '',
      notes: '',
      update: false,
      editMode: false,
      isSubmitting: false,
      status: 'OPEN',
    };
  }

  changeDate = (e) => {
    this.setState({ time_stamp: e.target.value });
  };

  handleCustomerChange = (e) => {
    this.setState({ credit_customer_uuid: e?.value });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  editMode = (item) => {
    this.setState({ editMode: false });
    window.scrollTo({
      behavior: 'smooth',
      top: this.myRef.current.offsetTop - 100,
    });
    this.setState({
      time_stamp: dayjs(item.time_stamp).format('YYYY-MM-DD'),
      quotation_no: item.quotation_no,
      credit_customer_uuid: item.customer.uuid,
      notes: item.notes,
      status: item.status,
      editMode: true,
      uuid: item.uuid,
    });
  };

  cancelEdit = () => {
    this.setState({
      time_stamp: dayjs().format('YYYY-MM-DD'),
      quotation_no: '',
      credit_customer_uuid: '',
      notes: '',
      status: 'OPEN',
      update: false,
      editMode: false,
      isSubmitting: false,
    });
  };

  submitForm = (e) => {
    e.preventDefault();
    const formData = this.state;
    const headers = authHeader(1);
    if (this.state.editMode) {
      axios
        .put(
          `${process.env.API_URL}/credit-customer/bunk/quotation/`,
          formData,
          { headers },
        )
        .then(() => {
          toast.success('Quatation updated successfully', {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          window.scrollTo({
            behavior: 'smooth',
            top: this.myRef.current.offsetTop - 150,
          });
          this.setState({
            time_stamp: dayjs().format('YYYY-MM-DD'),
            quotation_no: '',
            credit_customer_uuid: '',
            notes: '',
            status: 'OPEN',
            updated: !this.state.updated,
            isSubmitting: false,
          });
        })
        .catch((err) => {
          toast.error(err.message, {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.setState({ isSubmitting: false });
        });
    } else {
      axios
        .post(
          `${process.env.API_URL}/credit-customer/bunk/quotation/`,
          formData,
          {
            headers,
          },
        )
        .then(() => {
          toast.success('Quatation added successfully');
          window.scrollTo({
            behavior: 'smooth',
            top: this.myRef.current.offsetTop - 150,
          });
          this.setState({
            time_stamp: dayjs().format('YYYY-MM-DD'),
            quotation_no: '',
            credit_customer_uuid: '',
            notes: '',
            status: 'OPEN',
            updated: !this.state.updated,
            isSubmitting: false,
            editMode: false,
          });
        })

        .catch((error) => {
          console.log(error);
        });
    }
  };

  render() {
    const { editMode } = this.state;
    return (
      <>
        <section className="widget full-width form-table" ref={this.myRef}>
          <header className="form-header">
            <h3> {editMode ? 'Edit' : ''} Generate Quotation </h3>
          </header>
          <main>
            <form className="inline">
              <div className="input-wrapper with-label ">
                <label> Date:</label>
                <input
                  type="date"
                  placeholder="Date"
                  name="selectDate"
                  onChange={this.changeDate}
                  value={this.state.time_stamp}
                  disabled={editMode}
                />
              </div>

              <div className="input-wrapper with-label ">
                <label>Quote no: </label>
                <input
                  type="text"
                  placeholder="Quatation no"
                  onChange={this.handleChange}
                  value={this.state.quotation_no}
                  name="quotation_no"
                  disabled={editMode}
                />
              </div>
              <div className="input-wrapper with-label ">
                <label> Customer name: </label>
                <CustomerList
                  onCustomerChange={this.handleCustomerChange}
                  value={this.state.credit_customer_uuid}
                  disabled={editMode}
                />
              </div>

              <div className="input-wrapper with-label ">
                <label> Notes </label>
                <input
                  type="text"
                  value={this.state.notes}
                  name="notes"
                  onChange={this.handleChange}
                  placeholder="Notes"
                />
              </div>
              <div className="form-actions">
                <button
                  className="submit"
                  disabled={this.state.isSubmitting}
                  onClick={this.submitForm}
                >
                  Submit
                </button>
                {editMode && (
                  <button className="red-btn" onClick={this.cancelEdit}>
                    Cancel
                  </button>
                )}
              </div>
            </form>
          </main>
        </section>
        <GenerateQuotationList
          key={this.state.updated}
          onEdit={this.editMode}
        />
      </>
    );
  }
}
export { GenerateQuotation };
