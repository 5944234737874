import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import { Fields, getPaymentModes, Modes } from '@C/Banking/';
import { DutyList, getTaxMultiplier, TaxList } from '@C/Utils';
import IncomeList from '@C/Utils/IncomeList';
import { authHeader } from '@H';

import IncomeSalesList from './SalesList';

let cod = false;
class Income extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
    this.myRef = React.createRef();
    cod = this.props.cod || false;
    this.state = {
      payment_list: [
        {
          amount: 0,
          amount_tax: 0,
          mode: '',
          bank_id: null,
          tax: [],
          taxMultiplier: 0,
          taxName: '',
          payment_method: 'OFFLINE',
          transaction_source: 'CASH',
          product_type: 'Other',
          product_uuid: '',
          notes: '',
        },
      ],

      staff_id: cod ? this.props.match.params.staff : null,
      cashier_duty_id: cod ? this.props.match.params.duty : '',

      time_stamp: cod
        ? this.props.match.params.date
        : dayjs().format('YYYY-MM-DD'),
      update: false,
      editMode: false,
      isSubmitting: false,
    };
  }

  addRow = () => {
    this.setState((prevState) => ({
      payment_list: [
        ...prevState.payment_list,
        {
          amount: 0,
          amount_tax: 0,
          mode: '',
          bank_id: null,
          tax: [],
          taxMultiplier: 0,
          taxName: '',
          payment_method: 'OFFLINE',
          transaction_source: 'CASH',
          product_type: 'Other',
          product_uuid: '',
          notes: '',
        },
      ],
    }));
  };

  deleteRow = (i) => {
    this.setState({
      payment_list: this.state.payment_list.filter((s, sindex) => i !== sindex),
    });
  };

  handlePaymentSelect = (mode, i) => {
    const list = [...this.state.payment_list];
    list[i].mode = mode;
    if (mode == 'cash') {
      list[i].transaction_source = 'CASH';
      list[i].payment_method = 'OFFLINE';
    }
    this.setState({ payment_list: list });
  };

  handleStaffChange = (e) => {
    this.setState({
      cashier_duty_id: e.value,
    });
  };

  handleDate = (e) => {
    this.setState({ time_stamp: e.target.value });
  };

  onDataChange = (e, i) => {
    const list = [...this.state.payment_list];
    list[i].bank_id = e.bank_id;
    list[i].bank_reference_id = e.bank_reference_id;
    list[i].check_date = e.check_date;
    list[i].payment_method = e.payment_method;
    list[i].transaction_source = e.transaction_source;

    this.setState({ payment_list: list });
  };

  handleProductChange = (e, i) => {
    const list = [...this.state.payment_list];
    list[i].product_uuid = e.value;
    this.setState({ payment_list: list });
  };

  handleTaxChange = (e, l, i, m) => {
    const list = [...this.state.payment_list];
    list[i].tax = e;
    list[i].taxName = l;
    list[i].taxMultiplier = m;
    this.setState({ payment_list: list }, function () {
      this.reCalculateAmount(i);
    });
  };

  onInputChange = (e, i) => {
    const list = [...this.state.payment_list];
    list[i][e.target.name] = e.target.value;
    this.setState({ payment_list: list }, function () {
      this.reCalculateAmount(i);
    });
  };

  reCalculateAmount = (i) => {
    const list = [...this.state.payment_list];
    const taxMultiplier = parseFloat(list[i].taxMultiplier || 0);
    const t = parseFloat(list[i].amount);
    const taxAmount = t * (taxMultiplier / 100);

    const amt = t + taxAmount;

    list[i].amount_tax = parseFloat(amt).toFixed(2);
    this.setState({ payment_list: list });
  };
  editMode = (item) => {
    this.setState({ editMode: false });
    window.scrollTo({ behavior: 'smooth', top: this.myRef.current.offsetTop });
    const tmp = item;
    const incomeData = [...this.state.payment_list];
    const paymentMode = getPaymentModes(tmp.source, tmp.payment_method);
    incomeData[0].amount = tmp.balance;
    incomeData[0].mode = paymentMode;
    incomeData[0].bank = tmp.bank;
    incomeData[0].bank_id = tmp.bank?.uuid;
    incomeData[0].bank_reference_id = tmp.bank_reference_id;
    incomeData[0].check_date = tmp.check_date;
    incomeData[0].payment_method = tmp.payment_method;
    incomeData[0].transaction_source = tmp.source;
    incomeData[0].product_uuid = tmp.payment_for.uuid;
    incomeData[0].taxName = tmp.payment_for.tax_group_name;
    incomeData[0].taxMultiplier = getTaxMultiplier(
      tmp.payment_for.tax_group_name,
    ).multiplier;
    incomeData[0].tax = getTaxMultiplier(tmp.payment_for.tax_group_name).value;
    this.setState(
      {
        payment_list: incomeData,
        staff_id: cod ? this.props.match.params.staff : null,
        cashier_duty_id: tmp.cashier_duty.cashier_duty,
        time_stamp: dayjs(tmp.cashier_duty.duty_date || tmp.time_stamp).format(
          'YYYY-MM-DD',
        ),
        editMode: true,
        transaction_uuid: item.uuid,
      },
      function () {
        this.reCalculateAmount(0);
      },
    );
  };

  cancelEdit = () => {
    this.setState({
      payment_list: [
        {
          amount: 0,
          amount_tax: 0,
          mode: '',
          bank_id: null,
          tax: [],
          taxMultiplier: 0,
          taxName: '',
          payment_method: 'OFFLINE',
          transaction_source: 'CASH',
          product_type: 'Other',
          product_uuid: '',
          notes: '',
        },
      ],

      staff_id: cod ? this.props.match.params.staff : null,
      cashier_duty_id: cod ? this.props.match.params.duty : '',

      time_stamp: cod
        ? this.props.match.params.date
        : dayjs().format('YYYY-MM-DD'),
      update: false,
      editMode: false,
      isSubmitting: false,
    });
  };

  submitForm = () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }

    this.setState({ isSubmitting: true });

    const formData = this.state;

    const headers = authHeader(1);
    let method = 'post';

    if (this.state.editMode) {
      method = 'put';
      formData.payment_list[0].transaction_id = this.state.transaction_uuid;
    }

    axios({
      method: method,
      url: `${process.env.API_URL}/payment/`,
      data: formData,
      headers: headers,
    })
      .then((response) => {
        toast.success('Transaction updated successfully');
        this.scrollToTop();
        this.setState({
          payment_list: [
            {
              amount: 0,
              amount_tax: 0,
              mode: '',
              bank_id: null,
              tax: [],
              taxName: '',
              payment_method: 'OFFLINE',
              transaction_source: 'CASH',
              product_type: 'Other',
              product_uuid: '',
              notes: '',
            },
          ],
          cashier_id: cod ? this.props.match.params.staff : null,
          cashier_duty_id: cod ? this.props.match.params.duty : '',
          time_stamp: cod
            ? this.props.match.params.date
            : dayjs().format('YYYY-MM-DD'),
          updated: !this.state.updated,
          isSubmitting: false,
          editMode: false,
        });
        this.validator.hideMessages();
        if (cod) {
          this.props.onDataChange();
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        this.setState({ isSubmitting: false });
      });
  };

  scrollToTop = () => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  };

  render() {
    const incomeList = this.state.payment_list;
    const editMode = this.state.editMode;
    return (
      <>
        <section className="widget full-width form-table" ref={this.myRef}>
          <header>
            <h3>{editMode ? 'Edit' : ''} Other Income</h3>

            <div className="actions">
              <button
                className={`add-row ${editMode ? 'hidden' : ''}`}
                onClick={() => this.addRow()}
              >
                Add row
              </button>
            </div>
          </header>
          <main>
            {!cod && (
              <div className="form-header">
                <div className="input-wrapper">
                  <input
                    className="cstm-date-input"
                    type="date"
                    value={this.state.time_stamp}
                    onChange={this.handleDate}
                    disabled={editMode}
                  />
                </div>
                <div className="input-wrapper">
                  {this.validator.message(
                    'staff',
                    this.state.cashier_duty_id,
                    'required',
                  )}
                  <DutyList
                    onChange={this.handleStaffChange}
                    value={this.state.cashier_duty_id}
                    date={this.state.time_stamp}
                    key={this.state.time_stamp}
                    disabled={editMode}
                  />
                </div>
              </div>
            )}

            <div className="form-content">
              <table>
                <thead>
                  <tr>
                    <th>Income Source</th>
                    <th>Amount before tax</th>
                    <th>Tax</th>
                    <th>Payment mode</th>
                    <th>Amount after tax</th>
                    <th>Notes</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {incomeList.map((item, i) => (
                    <tr key={i} className={`${editMode ? 'highlight-bg' : ''}`}>
                      <td>
                        <div className="input-wrapper">
                          {this.validator.message(
                            'income',
                            item.product_uuid,
                            'required',
                          )}
                          <IncomeList
                            did={i}
                            onChange={this.handleProductChange}
                            value={item.product_uuid}
                            disabled={editMode}
                            isShowDeleted={false}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper">
                          {this.validator.message(
                            'amount',
                            item.amount,
                            'required|numeric',
                          )}
                          <input
                            type="text"
                            value={item.amount}
                            placeholder="Amount before"
                            name="amount"
                            min={0}
                            onChange={() => this.onInputChange(event, i)}
                          />
                        </div>
                      </td>

                      <td>
                        <div className="input-wrapper">
                          <TaxList
                            did={i}
                            onChange={this.handleTaxChange}
                            value={item.taxName}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper payment-wrapper">
                          <Modes
                            did={i}
                            handleModeChange={this.handlePaymentSelect}
                            value={item.mode || 'cash'}
                            disabled={editMode}
                          />
                          <Fields
                            did={i}
                            method={item.mode}
                            onDataChange={this.onDataChange}
                            bank={item.bank}
                            bank_reference_id={item.bank_reference_id}
                            check_date={item.check_date}
                            disabled={editMode}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper">
                          <input
                            type="text"
                            disabled
                            value={item.amount_tax || 0}
                            placeholder="Amount after tax"
                            name="amount_tax"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper">
                          <textarea
                            value={item.notes}
                            placeholder="Notes"
                            name="notes"
                            onChange={() => this.onInputChange(event, i)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper _actions">
                          {i > 0 ? (
                            <button
                              className="delete-row icon-only"
                              onClick={() => this.deleteRow(i)}
                            >
                              Delete row
                            </button>
                          ) : (
                            ''
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="form-actions">
              <button
                className="submit"
                type="submit"
                disabled={this.state.isSubmitting}
                onClick={this.submitForm}
              >
                Submit
              </button>
              {editMode && (
                <button className="red-btn" onClick={this.cancelEdit}>
                  Cancel
                </button>
              )}
            </div>
          </main>
        </section>
        <IncomeSalesList
          key={this.state.updated}
          onEdit={this.editMode}
          cod={cod}
          staff={this.state.staff_id}
          date={this.state.time_stamp}
          duty={this.props.match.params.duty}
          onDataChange={this.props.onDataChange}
        />
      </>
    );
  }
}

export { Income };
