import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import { authHeader } from '@H';

import { PackageList } from './packageList';

const ManagePackages = () => {
  const [editMode, setEditMode] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [smspackages, setSmsPackages] = useState({
    package_name: '',
    no_of_sms: '',
    price: '',
    loading: false,
    updated: false,
  });

  useEffect(() => {});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSmsPackages((smspackages) => ({
      ...smspackages,
      [name]: value,
    }));
  };

  const submitForm = (e) => {
    e.preventDefault();
    const formData = smspackages;
    const headers = authHeader();

    if (editMode) {
      axios
        .put(`${process.env.API_URL}/utils/sms_package/`, formData, { headers })
        .then((response) => {
          toast.success(`SMS Packages updated successfully`);
          setIsSubmitting(false);
          setSmsPackages((smspackages) => ({
            ...smspackages,
            updated: !smspackages.updated,
            package_name: '',
            no_of_sms: '',
            price: '',
          }));
          setEditMode(true);
        })
        .catch((error) => {
          setIsSubmitting(false);
          toast.error(error.response.data.message);
        });
    } else {
      axios
        .post(`${process.env.API_URL}/utils/sms_package/`, formData, {
          headers,
        })
        .then((response) => {
          toast.success(`SMS Packages added successfully`);
          setIsSubmitting(false);
          setSmsPackages((smspackages) => ({
            ...smspackages,
            updated: !smspackages.updated,
            package_name: '',
            no_of_sms: '',
            price: '',
          }));
          setEditMode(false);
        })
        .catch((error) => {
          setIsSubmitting(false);
          toast.error(error.response.data.message);
        });
    }
  };

  const handleEditMode = (item) => {
    setSmsPackages({
      uuid: item.uuid,
      package_name: item.name,
      no_of_sms: item.sms,
      price: item.amount,
    });
    setEditMode(true);
  };

  const cancelForm = () => {
    setSmsPackages({
      loading: false,
      updated: false,
      package_name: '',
      no_of_sms: '',
      price: '',
    });
    setEditMode(false);
  };

  return (
    <>
      <section className="widget form-card full-width">
        <header>
          <h3> Add New Package </h3>
        </header>
        <main>
          <form className="inline">
            <div className="input-wrapper with-label">
              <label>Package name</label>
              <input
                type="text"
                onChange={handleChange}
                value={smspackages.package_name}
                name="package_name"
              />
            </div>
            <div className="input-wrapper with-label">
              <label>No. of SMS </label>
              <input
                type="text"
                onChange={handleChange}
                value={smspackages.no_of_sms}
                name="no_of_sms"
              />
            </div>
            <div className="input-wrapper with-label">
              <label>Price</label>
              <input
                type="text"
                onChange={handleChange}
                value={smspackages.price}
                name="price"
              />
            </div>
            <div className="item-actions">
              <button
                className={`submit ${isSubmitting ? 'loading' : ''}`}
                onClick={submitForm}
              >
                Submit
              </button>
              {editMode && (
                <button className="red-btn" onClick={cancelForm}>
                  Cancel
                </button>
              )}
            </div>
          </form>
        </main>
      </section>
      <PackageList key={smspackages.updated} onEdit={handleEditMode} />
    </>
  );
};

export { ManagePackages };
