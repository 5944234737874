import React from 'react';

import { Header, Navbar, NavPills } from '@C';
import ErrorBoundary from '@C/Error';
import { RouteSetup } from '@C/Lubes';

const routes = [
  {
    name: 'Inventory',
    path: '/account/lubes/inventory',
  },
  {
    name: 'Sales',
    path: '/account/lubes/sales',
  },
  {
    name: 'Physical Stock Inspection',
    path: '/account/lubes/physical-stock-inspection',
    display: false,
  },
];

const Lubes = () => {
  return (
    <main>
      <div className="page-wrapper">
        <Header title="Lubes" />
        <Navbar />
        <div className="page-content">
          <section className="page-components">
            <div className="page-title">
              <h1>Lube / Products</h1>
            </div>
            <ErrorBoundary>
              <NavPills routes={routes} />
            </ErrorBoundary>
            <ErrorBoundary>
              <RouteSetup />
            </ErrorBoundary>
          </section>
        </div>
      </div>
    </main>
  );
};

export { Lubes };
