import React, { Component } from 'react';
import dayjs from 'dayjs';
import { formatCurrency, TableGrid } from '@C/TableGrid';
import { CustomerList, ProductList } from '@C/CreditCustomers/_utils/';
import services from '@C/Reports/_utils/data';
import { ShiftsList, StaffList } from '@C/Utils';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { BowserList } from '@C/Reports/_utils/BowserList';

const col = [
  {
    name: 'Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Slip #',
    key: 'slip_no',
  },
  {
    name: 'Customer',
    key: 'credit_customer',
    formatter(props) {
      return <>{props.name}</>;
    },
  },
  {
    name: 'Vehicle',
    key: 'vehicle.vehicle_no',
  },
  {
    name: 'Product',
    key: 'product',
    formatter(props) {
      return <>{props?.name || 'Cash given'}</>;
    },
  },
  {
    name: 'Tank name',
    key: 'product',
    formatter(props) {
      return <>{props?.tank}</>;
    },
  },
  {
    name: 'Rate',
    key: 'product',
    formatter(props) {
      return <>{props?.price || '---'}</>;
    },
  },
  {
    name: 'Qty',
    key: 'quantity',
    formatter(props) {
      return <>{props.toFixed(2)}</>;
    },
  },
  {
    name: 'Amount',
    key: 'all',
    formatter(props) {
      return (
        <>
          {formatCurrency(
            props?.after_discount + props.discount - props.cash_given || 0,
          ) || '--'}
        </>
      );
    },
  },
  {
    name: 'Disc',
    key: 'all',
    type: 'currency',
    formatter(props) {
      return <>{formatCurrency(props.discount)}</>;
    },
  },
  {
    name: 'Amount (Excl Tax)',
    key: 'all',
    type: 'currency',
    formatter(props) {
      return <>{formatCurrency(props.after_discount)}</>;
    },
  },
  {
    name: 'Cash given',
    key: 'all',
    type: 'currency',
    formatter(props) {
      return <>{formatCurrency(props.cash_given)}</>;
    },
  },
  {
    name: 'Amount (Inc Tax)',
    key: 'all',
    type: 'currency',
    formatter(props) {
      return <>{formatCurrency(props.after_tax)}</>;
    },
  },
  {
    name: 'Staff',
    key: 'cashier',
    formatter(props) {
      return <>{`${props.first_name} ${props.last_name}`}</>;
    },
  },
  {
    name: 'Notes',
    key: 'notes',
  },
];

class CreditSalesBowser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slipList: [],
      selectedId: '',
      showModal: false,
      staff_id: '',
      start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
      cashier_duty_id: this.props.duty,
      product_id: '',
      credit_customer_id: '',
      tank_id: '',
      slip_no: '',
      shift_id: '',
      vehicle_number: '',
      loading: true,
      limit: 20,
      offset: 1,
      count: 0,
    };
    this.limitInput = React.createRef();
  }

  componentDidMount() {
    this.getBowserList();
  }

  getCreditSlipsBowser = () => {
    services
      .getCreditSlipsBowser(this.state)
      .then((response) => {
        this.setState({
          slipList: response.data.data.results,
          count: response.data.data.count,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getBowserList = () => {
    services
      .getBowserList()
      .then((response) => {
        const tmp = response.data.data;
        const CategoryOptions = [];
        for (let i = 0; i < tmp.length; i++) {
          CategoryOptions.push({
            value: tmp[i].uuid,
            label: tmp[i].name,
          });
        }
        this.setState(
          {
            slipList: CategoryOptions,
            tank_id: CategoryOptions[0].value,
          },
          function () {
            this.getCreditSlipsBowser();
          },
        );
      })

      .catch((error) => {
        console.log(error);
      });
  };

  handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date' && dayjs(value).isAfter(this.state.end_date)) {
      this.setState({ [name]: value, end_date: value });
    } else if (
      name === 'end_date' &&
      dayjs(value).isBefore(this.state.start_date)
    ) {
      this.setState({ [name]: value, start_date: value });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleLimitChange = (e) => {
    this.setState({ limit: e.target.value });
  };
  handlePagination = (e) => {
    this.setState({ offset: e }, function () {
      this.getCreditSlipsBowser();
    });
  };
  handleShiftChange = (e) => {
    this.setState({ shift_id: e.value });
  };

  handleBowserChange = (e) => {
    this.setState({ tank_id: e?.value || '' });
  };
  handleStaffChange = (e) => {
    this.setState({ staff_id: e.value });
  };

  handleBillChange = (e) => {
    this.setState({ slip_no: e.target.value });
  };

  handleProductChange = (e) => {
    this.setState({ product_id: e?.value || '' });
  };

  handleQuickSearch = (e) => {
    e.preventDefault();
    if (
      this.limitInput.current.value === '' ||
      this.limitInput.current.value === '0'
    ) {
      return;
    }
    this.setState(
      { limit: this.limitInput.current.value, offset: 1 },
      function () {
        this.getCreditSlipsBowser();
      },
    );
  };

  handleCustomerChange = (e) => {
    this.setState({ credit_customer_id: e?.value });
  };

  handleCategoryChange = (e) => {
    this.setState({ credit_customer_id: e });
  };

  resetFilter = () => {
    const { slipList } = this.state;
    const firstBowser = slipList.length > 0 ? slipList[0].value : '';
    this.limitInput.current.value = 20;
    this.setState(
      {
        staff_id: '',
        start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
        product_id: '',
        credit_customer_id: '',
        tank_id: '',
        cashier_duty_id: '',
        loading: true,
        slip_number: '',
        shift_id: '',
        limit: 20,
        offset: 1,
      },
      function () {
        this.getCreditSlipsBowser();
        if (this.BowserListRef) {
          this.BowserListRef.handleReset();
        }
      },
    );
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, function () {
      this.getCreditSlipsBowser();
    });
  };

  render() {
    const { slipList } = this.state;
    return (
      <section className="widget list-table full-width inverted">
        <header className="list-header">
          <h3>Credit Sales- Bowser</h3>
        </header>
        <main>
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group">
                <label>From date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.start_date}
                  name="start_date"
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="filter-group">
                <label>To date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.end_date}
                  min={this.state.start_date}
                  onChange={this.handleDateChange}
                  name="end_date"
                />
              </div>

              <div className="filter-group">
                <label>Staff:</label>
                <StaffList
                  value={this.state.staff_id}
                  onChange={this.handleStaffChange}
                  staffType={['Cashiers', 'Managers', 'Dealer']}
                />
              </div>
              <div className="filter-group">
                <label>Shift:</label>
                <ShiftsList
                  value={this.state.shift_id}
                  onChange={this.handleShiftChange}
                />
              </div>
              <div className="filter-group">
                <label>Product:</label>
                <ProductList
                  onProductChange={this.handleProductChange}
                  value={this.state.product_id}
                  options={this.props.products}
                />
              </div>
              <div className="filter-group">
                <label>Bowser:</label>
                <BowserList
                  value={this.state.tank_id}
                  onChange={this.handleBowserChange}
                  ref={(ref) => {
                    this.BowserListRef = ref;
                  }}
                />
              </div>
              <div className="filter-group">
                <label>Customer:</label>
                <CustomerList
                  onCustomerChange={this.handleCustomerChange}
                  value={this.state.credit_customer_id}
                  options={this.props.customers}
                />
              </div>
              <div className="filter-group with-icon">
                <label>
                  Results to display- {this.state.limit || 20} (Total:{' '}
                  {this.state.count})
                </label>
                <form onSubmit={this.handleQuickSearch}>
                  <input type="text" name="limit" ref={this.limitInput} />
                  <button
                    type="submit"
                    className="search-icon"
                    onClick={this.handleQuickSearch}
                  />
                </form>
              </div>
              <div className="filter-group reset-filter">
                <button onClick={this.resetFilter}>Reset filters</button>
              </div>
              <div className="filter-group">
                <button
                  className="submit"
                  type="submit"
                  onClick={this.submitForm}
                >
                  Apply filter
                </button>
              </div>
            </div>
          </div>
          <div className="table-wrapper">
            <div className="mini-summary">
              <ul>
                <li>
                  <span>Total amount: </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      slipList.reduce(
                        (a, b) =>
                          a +
                          (b['after_discount'] +
                            b['discount'] -
                            b['cash_given'] || 0),
                        0,
                      ) || 0,
                    )}
                  </span>
                </li>
                <li>
                  <span>Total discount: </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      slipList.reduce((a, b) => a + (b['discount'] || 0), 0) ||
                        0,
                    )}
                  </span>
                </li>
                <li>
                  <span>Total amount(Excl Tax): </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      slipList.reduce(
                        (a, b) =>
                          a + (b['after_discount'] - b['cash_given'] || 0),
                        0,
                      ) || 0,
                    )}
                  </span>
                </li>
                <li>
                  <span>Total Cash given: </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      slipList.reduce(
                        (a, b) => a + (b['cash_given'] || 0),
                        0,
                      ) || 0,
                    )}
                  </span>
                </li>
                <li>
                  <span>Total amount (Inc tax): </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      slipList.reduce((a, b) => a + (b['after_tax'] || 0), 0) ||
                        0,
                    )}
                  </span>
                </li>
              </ul>
            </div>
            {(() => {
              if (this.state.loading) {
                return <DropLoaderLarge />;
              } else {
                return (
                  <TableGrid
                    col={col}
                    row={slipList}
                    offset={this.state.offset}
                    count={this.state.count}
                    limit={this.state.limit}
                    handlePagination={this.handlePagination}
                    hideActions={true}
                    exportOptions={{
                      fileName: `Credit Sales - ${this.state.start_date} to ${this.state.end_date}.pdf`,
                      reportName: 'Credit Sales Report',
                      reportDate: `Date - ${dayjs(this.state.start_date).format(
                        'D MMM YY',
                      )} to ${dayjs(this.state.end_date).format('D MMM YY')}`,
                      reportTotal: `Total amount -  ${
                        slipList.reduce(
                          (a, b) =>
                            a +
                            (b['after_discount'] +
                              b['discount'] -
                              b['cash_given'] || 0),
                          0,
                        ) ||
                        (0).toFixed(2) ||
                        0
                      }`,
                      reportQuantity: `Total discount -  ${
                        slipList
                          .reduce((a, b) => a + (b['discount'] || 0), 0)
                          .toFixed(2) || 0
                      }`,
                      reportSales: `Total amount(Excl Tax) -  ${
                        slipList.reduce(
                          (a, b) =>
                            a + (b['after_discount'] - b['cash_given'] || 0),
                          0,
                        ) ||
                        (0).toFixed(2) ||
                        0
                      }`,
                      reportTesting: `Total Cash given -  ${
                        slipList
                          .reduce((a, b) => a + (b['cash_given'] || 0), 0)
                          .toFixed(2) || 0
                      }`,
                      reportBowser: `Total amount (Inc tax) -  ${
                        slipList
                          .reduce((a, b) => a + (b['after_tax'] || 0), 0)
                          .toFixed(2) || 0
                      }`,
                      reportCustomerName: '',
                      reportGst: '',
                      reportOpeningBalance: '',
                      reportClosingBalance: '',
                      reportSaleTotal: '',
                      reportTCSTotal: '',
                    }}
                  />
                );
              }
            })()}
          </div>
        </main>
      </section>
    );
  }
}

export { CreditSalesBowser };
