import axios from 'axios';

import { authHeader } from '@H';

export default {
  getCreditLedger: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/credit-customer/bunk/customer-ledger/?&limit=${data.limit}&offset=${data.offset}&staff_id=${data.staff_id}&from_date=${data.start_date}&to_date=${data.end_date}&customer_id=${data.customer_id}&vehicle_id=${data.vehicle_number}&product_id=${data.product_id}&slip_number=${data.slip_no}`,
    }),
};
