import React, { Component } from 'react';
import Select from 'react-select';

import services from './data';

let CategoryOptions = [];

class OMCList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
    };
  }
  componentDidMount() {
    services
      .getOMCList()
      .then((response) => {
        const tmp = response.data.data;
        CategoryOptions = [];
        for (let i = 0; i < tmp.length; i++) {
          CategoryOptions.push({
            value: tmp[i].uuid,
            label: tmp[i].omc,
          });
        }
        this.setState({ options: CategoryOptions });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleChange = (selectedOption) => {
    this.props.handleOMCChange(selectedOption.value);
  };

  render() {
    const { options } = this.state;
    return (
      <Select
        onChange={this.handleChange}
        options={options}
        className="select-box-container"
        classNamePrefix="raw-select"
        name="oml"
        placeholder="Select OMC"
        value={options.filter(({ value }) => value === this.props.value)}
        isDisabled={this.props.disabled || false}
      />
    );
  }
}

export { CategoryOptions, OMCList };
