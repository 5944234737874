import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';

import { Header, Navbar } from '@C';
import services from '@C/Dsr/_utils/data';
import {
  BusinessSummary,
  CashBook,
  CreditCollection,
  CreditSlipsSummary,
  DsrActions,
  DSRSalary,
  FuelSummary,
  ProductSalesSummary,
  Purchases,
  ShortageExcess,
  TempCreditDsr,
  LubeProductSalesSummary,
} from '@C/Dsr/Components';
import ErrorBoundary from '@C/Error';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';

import './styles.scoped.scss';

const Dsr = (props) => {
  const history = useHistory();
  const [showButton, setShowButton] = useState(false);
  const [state, setState] = useState({
    date: props.match.params.date || dayjs().format('YYYY-MM-DD'),
    business_summary: [],
    cash_book: [],
    product_wise: [],
    shortage: [],
    gun_wise: [],
    credit_slips: [],
    purchases: [],
    credit_collection: [],
    temp_ledger: [],
    salary_module: [],
    dsr_lube_sale_summary: [],
    loading: true,
    staff: '',
    shift: '',
    lockStatus: false,
    highlightedDates: [],
  });

  useEffect(() => {
    history.push(`/account/dsr/${state.date}`);

    getDSR();
  }, [state.date, state.staff, state.shift]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getDSRDates = () => {
    services
      .getDSRDates(dayjs(state.date).month() + 1, dayjs(state.date).year())
      .then((response) => {
        const tmp = response.data.data;
        const eventsArr = [
          {
            dsr_locked: [],
          },
          {
            dsr_unlocked: [],
          },
        ];
        tmp.forEach((item) => {
          if (item.lock_status) {
            eventsArr[0]['dsr_locked'].push(new Date(item.time_stamp));
          } else {
            eventsArr[1]['dsr_unlocked'].push(new Date(item.time_stamp));
          }
        });
        setState((prevState) => ({
          ...prevState,
          highlightedDates: eventsArr,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDSR = () => {
    services
      .getDSRData(state)
      .then((response) => {
        const tmp = response.data.data;
        setState({
          ...state,
          business_summary: tmp.business_summery,
          cash_book: tmp.cash_book,
          product_wise: tmp.product_wise,
          shortage: tmp.shortage,
          gun_wise: tmp.gun_wise,
          credit_slips: tmp.credit_slip_wise,
          purchases: tmp.purchase,
          credit_collection: tmp.credit_collection,
          temp_ledger: tmp.temp_ledger,
          salary_module: tmp.salary_module,
          dsr_lube_sale_summary: tmp.dsr_lube_sale_summary,
          loading: false,
          lockStatus: tmp.dsr_lock,
        });
        getDSRDates();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onDateChange = (d, s, sh) => {
    setState({
      ...state,
      date: dayjs(d).format('YYYY-MM-DD'),
      staff: s,
      shift: sh,
      loading: true,
    });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (state.loading) {
    return <DropLoaderLarge />;
  }

  return (
    <main>
      <div className="page-wrapper">
        <Header title="Dashboard" />
        <Navbar />
        <div className="page-content">
          <section className="page-components dsr-container">
            <div className="page-title">
              <h1>DSR</h1>
            </div>

            <ErrorBoundary>
              <DsrActions
                onChange={onDateChange}
                date={state.date}
                lockStatus={state.lockStatus}
                shift={state.shift}
                staff={state.staff}
                highlightedDates={state.highlightedDates}
              />

              {(() => {
                if (state.loading) {
                  return <DropLoaderLarge />;
                } else {
                  return (
                    <>
                      <FuelSummary gun_wise={state.gun_wise} />
                      <ProductSalesSummary summary={state.product_wise} />
                      <CreditSlipsSummary summary={state.credit_slips} />
                      <LubeProductSalesSummary summary={state.dsr_lube_sale_summary} />
                      <ShortageExcess summary={state.shortage} />
                      <CreditCollection summary={state.credit_collection} />
                      <CashBook summary={state.cash_book} />
                      <Purchases summary={state.purchases} />
                      <BusinessSummary summary={state.business_summary} />
                      <TempCreditDsr summary={state.temp_ledger} />
                      <DSRSalary summary={state.salary_module} />
                      {showButton && (
                        <button className="scroll-to-top" onClick={scrollToTop}>
                          Scroll to Top
                        </button>
                      )}
                    </>
                  );
                }
              })()}
            </ErrorBoundary>
          </section>
        </div>
      </div>
    </main>
  );
};

export { Dsr };
