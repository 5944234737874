import { authService } from '@S';

export function authHeader(e, param) {
  const currentUser = authService.currentUserValue;
  const userToken = authService.currentUserToken;

  if (currentUser && userToken && e && param) {
    return {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
      associateAccount: authService.currentAccountID,
      [param.name]: param.value,
    };
  } else if (currentUser && userToken && e) {
    return {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
      associateAccount: authService.currentAccountID,
    };
  } else if (currentUser && userToken) {
    return {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
    };
  } else {
    return {};
  }
}
