/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import {
  CustomerList,
  DynamicProductList,
  VehicleList,
} from '@C/CreditCustomers/_utils';
import services from '@C/CreditCustomers/_utils/data';
import SlipList from '@C/CreditCustomers/RouteComponents/CreditSlips/SlipList';
import { DutyList, getTaxMultiplier, TaxList } from '@C/Utils';
import { authHeader } from '@H';

let cod;

class CreditSlips extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
      validators: {
        stock: {
          message:
            'The quanity must be less than or equal to the available stock',
          rule: (val) => {
            if (val[2] == 'Fuel') {
              return true;
            }
            if (val[0] == 0) {
              return true;
            }
            return val[0] <= val[1];
          },
          required: true,
        },
      },
    });
    cod = this.props.cod || false;
    this.myRef = React.createRef();

    let i = 0;
    const arr = [];
    while (i < 5) {
      arr.push({
        slip_number: '',
        credit_customer_id: '',
        payment_slip_url: '',
        vehicle_number: '',
        amount: 0,
        discount: 0,
        discount_type: 'amount',
        tax: [],
        taxMultiplier: 0,
        taxName: '',
        product_type: 'CashGiven',
        product_id: '',
        amount_given: 0,
        quantity: 0,
        totalAmount: 0,
        customer: '',
        price: [],
        fuel_price_id: null,
        price_value: 0,
        vehicle: '',
        stock: '',
        unalteredAmount: 0,
      });
      i++;
    }

    this.state = {
      rows: 5,
      credit_slips_list: arr,
      productList: [],
      customerList: [],
      taxList: this.props.tax,
      cashier_id: cod ? this.props.match.params.staff : null,
      cashier_duty_id: cod ? this.props.match.params.duty : '',
      time_stamp: cod
        ? this.props.match.params.date
        : dayjs().format('YYYY-MM-DD'),
      update: false,
      editMode: false,
      isSubmitting: false,
    };
  }

  addRow = () => {
    const n = this.state.rows;
    const arr = [];
    let i = 0;
    while (i < n) {
      arr.push({
        slip_number: '',
        credit_customer_id: '',
        payment_slip_url: '',
        vehicle_number: '',
        amount: 0,
        discount: 0,
        discount_type: 'amount',
        tax: [],
        taxMultiplier: 0,
        taxName: '',
        product_type: 'CashGiven',
        product_id: '',
        amount_given: 0,
        quantity: 0,
        notes: '',
        totalAmount: 0,
        customer: '',
        price: [],
        fuel_price_id: null,
        price_value: 0,
        vehicle: '',
        stock: '',
        unalteredAmount: 0,
      });
      i++;
    }

    this.setState((prevState) => ({
      credit_slips_list: [...prevState.credit_slips_list, ...arr],
    }));
  };

  componentDidMount() {
    this.getProductList();
  }

  getProductList = () => {
    services
      .getProductList(this.state.time_stamp)
      .then((response) => {
        const tmp = response.data.data;
        const CategoryOptions = [];

        for (let i = 0; i < tmp.length; i++) {
          CategoryOptions.push({
            value: tmp[i].uuid,
            label: tmp[i].product || tmp[i].name,
            price: tmp[i].price.map((p) => {
              return {
                value: p.uuid || p.price,
                label: p.price,
              };
            }),
            product_type: tmp[i].hasOwnProperty('number_of_units')
              ? 'Lubes'
              : 'Fuel',
            stock: tmp[i].hasOwnProperty('number_of_units')
              ? tmp[i].number_of_units
              : 'Available',
          });
        }
        const isLubes = (element) => element.product_type == 'Lubes';
        CategoryOptions.splice(CategoryOptions.findIndex(isLubes), 0, {
          value: '',
          label: '------',
          isDisabled: true,
        });
        this.setState({ productList: CategoryOptions });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteRow = (i) => {
    this.setState({
      credit_slips_list: this.state.credit_slips_list.filter(
        (s, sindex) => i !== sindex,
      ),
    });
  };

  handleStaffChange = (e) => {
    this.setState({
      cashier_duty_id: e.value,
    });
  };

  handleDate = (e) => {
    const product = {
      value: '',
      label: '------',
      isDisabled: true,
      product_type: 'CashGiven',
      price: [],
      fuel_price_id: null,
      price_value: 0,
    };
    this.handleProductChange(product, 0);
    this.setState({ time_stamp: e.target.value }, function () {
      this.getProductList();
    });
  };

  handleRowChange = (e) => {
    let n = e.target.value;
    if (n > 20) {
      n = 20;
    }
    this.setState({ rows: n });
  };

  handleCustomerSelection = (e, i) => {
    const list = [...this.state.credit_slips_list];
    list.map((item, j) => {
      if (j >= i) {
        item.credit_customer_id = e?.value;
      }
    });
    this.setState({ credit_slips_list: list });
  };

  handleProductChange = (e, i) => {
    const list = [...this.state.credit_slips_list];
    list.map((item, j) => {
      if (j >= i) {
        if (e.product_type == 'Fuel') {
          item.taxName = '';
        }
        item.product_id = e.value;
        item.price = e.price || [];
        item.fuel_price_id = e.price[0]?.value || null;
        item.price_value = e.price[0]?.label || 0;
        item.product_type = e.product_type;
        item.stock = e.stock;
        item.amount = (item.quantity * item.price_value).toFixed(2);
      }
    });

    this.setState({ credit_slips_list: list }, function () {
      this.reCalculateAmount(i);
    });
  };

  handleTaxChange = (e, l, i, m) => {
    const list = [...this.state.credit_slips_list];
    if (list[i].product_type == 'Fuel') {
      return;
    } else {
      list[i].tax = e;
      list[i].taxName = l;
      list[i].taxMultiplier = m;
    }

    this.setState({ credit_slips_list: list }, function () {
      this.reCalculateAmount(i);
    });
  };

  handlePriceChange = (e, i) => {
    const list = [...this.state.credit_slips_list];
    list[i].fuel_price_id = e.value;
    list[i].price_value = e.label;
    list[i].amount = (list[i].quantity * list[i].price_value).toFixed(2);
    list[i].discount = 0;
    list[i].unalteredAmount = list[i].amount;
    this.setState({ credit_slips_list: list }, function () {
      this.reCalculateAmount(i);
    });
  };

  handleVehicleChange = (e, i) => {
    const list = [...this.state.credit_slips_list];
    list[i].vehicle_id = e?.value;
    list[i].vehicle = e || '';
    list.map((item, j) => {
      if (j >= i) {
        item.credit_customer_id = e?.customer;
      }
    });

    list[i].credit_customer_id = e?.customer;
    this.setState({ credit_slips_list: list });
  };

  onInputChange = (e, i) => {
    const list = [...this.state.credit_slips_list];
    if (e.target.name == 'slip_number') {
      list[i][e.target.name] = e.target.value;
    } else if (e.target.name == 'amount') {
      list[i][e.target.name] = e.target.value;
      list[i].unalteredAmount = e.target.value;
      list[i].discount = 0;
      list[i].quantity = (list[i].amount / list[i].price_value).toFixed(2);
    } else if (e.target.name == 'quantity') {
      list[i][e.target.name] = e.target.value;
      list[i].amount = (
        list[i].quantity * list[i].price_value -
        list[i].discount
      ).toFixed(2);
      list[i].unalteredAmount = list[i].amount;
    } else {
      list[i][e.target.name] = e.target.value;
      list[i].amount = (
        list[i].unalteredAmount - (list[i].discount || 0)
      ).toFixed(2);
    }

    this.setState({ credit_slips_list: list }, function () {
      this.reCalculateAmount(i);
    });
  };

  handleCashGiven = (e, i) => {
    const list = [...this.state.credit_slips_list];
    list[i].amount_given = e.target.value;
    if (list[i].product_id && list[i].product_type === 'Lubes') {
      list[i].product_type = 'Lubes';
    } else if (list[i].product_id && list[i].product_type === 'Fuel') {
      list[i].product_type = 'Fuel';
    } else {
      list[i].product_type = 'CashGiven';
    }

    this.setState({ credit_slips_list: list }, () => {
      this.reCalculateAmount(i);
    });
  };

  handleNotesChange = (e, i) => {
    const list = [...this.state.credit_slips_list];
    list[i][e.target.name] = e.target.value;
    this.setState({ credit_slips_list: list });
  };

  reCalculateAmount = (i) => {
    const list = [...this.state.credit_slips_list];
    const amount = parseFloat(list[i].amount);
    const taxMultiplier = parseFloat(list[i].taxMultiplier || 0);
    const cashGiven = parseFloat(list[i].amount_given || 0);

    const tax = amount * (taxMultiplier / 100);
    const totalAmount = parseFloat(amount + tax + cashGiven).toFixed(2);
    list[i].totalAmount = totalAmount;
    this.setState({ credit_slips_list: list });
  };

  editMode = (item) => {
    this.setState({ editMode: false });
    const tmp = item;
    const slipList = [{}];
    slipList[0].slip_number = tmp.slip_no;
    slipList[0].amount = parseFloat(tmp.after_discount - tmp.cash_given);
    slipList[0].unalteredAmount = slipList[0].amount + tmp.discount;
    slipList[0].credit_customer_id = tmp.credit_customer.uuid;
    slipList[0].vehicle_id = tmp.vehicle?.uuid;
    slipList[0].discount = tmp.discount;
    slipList[0].product_id = tmp.product?.uuid || '';
    slipList[0].taxName = tmp.tax.tax_group_name;
    slipList[0].taxMultiplier = getTaxMultiplier(
      tmp.tax.tax_group_name,
    ).multiplier;
    slipList[0].tax = getTaxMultiplier(tmp.tax.tax_group_name).value;
    slipList[0].amount_given = tmp.cash_given;
    slipList[0].product_type = tmp.product_type;
    slipList[0].quantity = tmp.quantity;
    slipList[0].fuel_price_id = tmp.product?.price_uuid || tmp.product?.price;
    slipList[0].price = [
      {
        label: tmp.product?.price || 0,
        value: tmp.product?.price_uuid || tmp.product?.price,
      },
    ];
    slipList[0].price_value = tmp.product?.price;
    slipList[0].totalAmount = tmp.after_tax;
    this.setState(
      {
        credit_slips_list: slipList,
        cashier_duty_id: tmp.cashier_duty.cashier_duty,
        time_stamp: dayjs(tmp.cashier_duty.duty_date || tmp.time_stamp).format(
          'YYYY-MM-DD',
        ),
        transaction_uuid: tmp.uuid,
        editMode: true,
      },
      function () {
        this.reCalculateAmount(0);
      },
    );
  };

  cancelEdit = () => {
    this.setState({
      credit_slips_list: [
        {
          slip_number: '',
          credit_customer_id: '',
          payment_slip_url: '',
          vehicle_number: '',
          amount: 0,
          discount: 0,
          discount_type: 'amount',
          tax: [],
          taxName: null,
          product_type: 'CashGiven',
          product_id: null,
          amount_given: 0,
          quantity: 0,
          notes: '',
          totalAmount: 0,
          customer: '',
          price: 0,
          vehicle: '',
          price: [],
          price_value: 0,
          fuel_price_id: null,
          unalteredAmount: 0,
        },
      ],
      cashier_duty_id: cod ? this.props.match.params.duty : '',
      time_stamp: cod
        ? this.props.match.params.date
        : dayjs().format('YYYY-MM-DD'),
      updated: !this.state.updated,
      editMode: false,
      isSubmitting: false,
    });
  };

  submitForm = () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState({ isSubmitting: true });
    const list = [...this.state.credit_slips_list];
    const a = list.filter((item) => item.amount > 0 || item.amount_given > 0);
    if (a.length < 1) {
      toast.error('Please add sale details');
      this.setState({ isSubmitting: false });
      return;
    }

    // this.setState({cashier_duty_id: null});

    this.setState({ credit_slips_list: a }, function () {
      const { customerList, productList, rows, taxList, ...formData } =
        this.state;
      const headers = authHeader(1);
      let method = 'post';
      if (this.state.editMode) {
        method = 'put';
        formData.credit_slips_list[0].uuid = this.state.transaction_uuid;
      }
      axios({
        method: method,
        url: `${process.env.API_URL}/credit-customer/bunk/credit-slip/`,
        data: formData,
        headers: headers,
      })
        .then(() => {
          toast.success('Credit Slip updated successfully');
          this.scrollToTop();
          this.setState({
            credit_slips_list: [
              {
                slip_number: '',
                credit_customer_id: '',
                payment_slip_url: '',
                vehicle_number: '',
                amount: 0,
                discount: 0,
                discount_type: 'amount',
                tax: [],
                taxName: null,
                product_type: 'CashGiven',
                product_id: null,
                amount_given: 0,
                quantity: 0,
                notes: '',
                totalAmount: 0,
                customer: '',
                price: 0,
                vehicle: '',
                price: [],
                price_value: 0,
                fuel_price_id: null,
                unalteredAmount: 0,
              },
            ],
            cashier_id: cod ? this.props.match.params.staff : null,
            cashier_duty_id: cod ? this.props.match.params.duty : '',
            time_stamp: cod
              ? this.props.match.params.date
              : dayjs().format('YYYY-MM-DD'),
            updated: !this.state.updated,
            isSubmitting: false,
            editMode: false,
          });
          this.validator.hideMessages();
          if (cod) {
            this.props.onDataChange();
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          this.setState({ isSubmitting: false, updated: !this.state.updated });
        });
    });
  };

  scrollToTop = () => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  };

  render() {
    const creditSlipList = this.state.credit_slips_list;
    const editMode = this.state.editMode;
    return (
      <>
        <section className="widget full-width form-table" ref={this.myRef}>
          <header>
            <h3>{editMode ? 'Edit' : ''} Credit Slips</h3>
            <div className="actions">
              <div className={` ${editMode ? 'hidden' : 'input-wrapper'}`}>
                <input
                  type="number"
                  max={20}
                  name="rows"
                  onChange={this.handleRowChange}
                  value={this.state.rows}
                />
              </div>
              <button
                className={`add-row ${editMode ? 'hidden' : ''}`}
                onClick={() => this.addRow()}
              >
                Add row
              </button>
            </div>
          </header>
          <main>
            {!cod && (
              <div className="form-header">
                <div className="input-wrapper">
                  <input
                    className="cstm-date-input"
                    type="date"
                    value={this.state.time_stamp}
                    onChange={this.handleDate}
                  />
                </div>
                <div className="input-wrapper">
                  {this.validator.message(
                    'staff',
                    this.state.cashier_duty_id,
                    'required',
                  )}
                  <DutyList
                    onChange={this.handleStaffChange}
                    value={this.state.cashier_duty_id}
                    date={this.state.time_stamp}
                    key={this.state.time_stamp}
                  />
                </div>
              </div>
            )}

            <div className="form-content scrollable">
              <table>
                <thead>
                  <tr>
                    <th>Slip #</th>
                    <th>Customer</th>
                    <th>Vehicle Number</th>
                    <th>Product</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Amount</th>
                    <th>Discount</th>
                    <th>Tax</th>
                    <th>Cash given</th>
                    <th>Total Amount</th>
                    <th>Notes</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {creditSlipList.map((item, i) => (
                    <tr key={i} className={`${editMode ? 'highlight-bg' : ''}`}>
                      <td className="w-130">
                        <div className="input-wrapper">
                          <input
                            name="slip_number"
                            type="text"
                            onChange={() => this.onInputChange(event, i)}
                            value={item.slip_number}
                            placeholder="Slip no."
                            disabled={editMode}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper w-130">
                          {this.validator.message(
                            'customer',
                            creditSlipList[i].credit_customer_id,
                            'required',
                          )}
                          <CustomerList
                            onCustomerChange={this.handleCustomerSelection}
                            did={i}
                            value={item.credit_customer_id}
                            options={this.state.customerList}
                            disabled={editMode}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper w-130">
                          <VehicleList
                            did={i}
                            handleDropdownChange={this.handleVehicleChange}
                            value={item.vehicle_id}
                            selectedCustomerId={item.credit_customer_id}
                            disabled={editMode}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper w-130">
                          <DynamicProductList
                            onProductChange={this.handleProductChange}
                            value={item.product_id}
                            did={i}
                            options={this.state.productList}
                            disabled={editMode}
                            cod={cod}
                          />
                        </div>
                      </td>
                      <td className="w-130">
                        <div className="input-wrapper">
                          <Select
                            onChange={(selectedOption) =>
                              this.handlePriceChange(selectedOption, i)
                            }
                            options={item.price}
                            className="select-box-container price-selector-container"
                            classNamePrefix="price-selector"
                            placeholder="Price"
                            value={item.price.filter(
                              ({ value }) => value === item.fuel_price_id,
                            )}
                          />
                        </div>
                      </td>
                      <td className="w-130">
                        <div className="input-wrapper">
                          <input
                            type="text"
                            value={item.quantity}
                            placeholder="Quantity"
                            name="quantity"
                            onChange={() => this.onInputChange(event, i)}
                          />
                        </div>
                      </td>
                      <td className="w-130">
                        <div className="input-wrapper">
                          <input
                            type="text"
                            value={item.amount}
                            placeholder="Amount"
                            name="amount"
                            onChange={() => this.onInputChange(event, i)}
                            disabled={!item.product_id}
                          />
                        </div>
                      </td>
                      <td className="w-130">
                        <div className="input-wrapper">
                          {this.validator.message(
                            'discount',
                            item.discount,
                            'required|numeric',
                          )}
                          <input
                            type="text"
                            value={item.discount}
                            placeholder="Discount"
                            name="discount"
                            onChange={() => this.onInputChange(event, i)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper">
                          <TaxList
                            options={this.state.taxList}
                            did={i}
                            onChange={this.handleTaxChange}
                            value={item.taxName}
                          />
                        </div>
                      </td>
                      <td className="w-130">
                        <div className="input-wrapper">
                          <input
                            type="text"
                            name="amount_given"
                            value={item.amount_given}
                            placeholder="Cash given"
                            onChange={() => this.handleCashGiven(event, i)}
                          />
                        </div>
                      </td>
                      <td className="w-130">
                        <div className="input-wrapper">
                          <input
                            type="text"
                            disabled
                            value={item.totalAmount}
                            placeholder="Total"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper">
                          <textarea
                            value={item.notes}
                            placeholder="Notes"
                            name="notes"
                            onChange={() => this.handleNotesChange(event, i)}
                          />
                        </div>
                      </td>
                      <td className="w-30">
                        {i > 0 ? (
                          <button
                            className="delete-row icon-only"
                            onClick={() => this.deleteRow(i)}
                          >
                            Delete row
                          </button>
                        ) : (
                          ''
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="form-actions">
              <button
                className="submit"
                type="submit"
                disabled={this.state.isSubmitting}
                onClick={this.submitForm}
              >
                Submit
              </button>

              {editMode && (
                <button className="red-btn" onClick={this.cancelEdit}>
                  Cancel
                </button>
              )}
            </div>
          </main>
        </section>
        <SlipList
          customers={this.state.customerList}
          products={this.state.productList}
          key={this.state.updated}
          onEdit={this.editMode}
          cod={cod}
          staff={this.state.cashier_id}
          date={this.state.time_stamp}
          duty={this.props.match.params.duty}
          onDataChange={this.props.onDataChange}
        />
      </>
    );
  }
}

export { CreditSlips };
