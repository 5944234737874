import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  DipCalibrationChart,
  DipChart,
  DipReadingSummary,
  DipSummaryReport,
  SummaryStatus,
} from '@C/DipReading/RouteComponents';

const RouteSetup = () => {
  return (
    <Switch>
      <Route
        path="/account/dip-reading/reading"
        component={DipReadingSummary}
      />
      <Route
        path="/account/dip-reading/summary-report"
        component={DipSummaryReport}
      />
      <Route path="/account/dip-reading/summary" component={SummaryStatus} />
      <Route path="/account/dip-reading/dip-chart" component={DipChart} />
      <Route
        path="/account/dip-reading/dip-calibration-chart/:uuid"
        component={DipCalibrationChart}
      />
      <Route path="/account/dip-reading/*">
        <Redirect to={`/account/dip-reading/reading`} />
      </Route>
    </Switch>
  );
};

export { RouteSetup };
