import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

import Parser from './parser';

const TableToExcelExport = (function (Parser) {
  const methods = {};

  methods.initWorkBook = function () {
    const wb = new ExcelJS.Workbook();
    return wb;
  };

  methods.initSheet = function (wb, sheetName, isRtl) {
    const ws = wb.addWorksheet(sheetName);
    ws.views = [{ rightToLeft: isRtl }];
    return ws;
  };

  methods.save = function (wb, fileName) {
    wb.xlsx.writeBuffer().then(function (buffer) {
      saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        fileName,
      );
    });
  };

  methods.tableToSheet = function (wb, table, opts) {
    let ws = this.initSheet(wb, opts.sheet.name, opts.sheet.rtl);
    ws = Parser.parseDomToTable(ws, table, opts);
    return wb;
  };

  methods.tableToBook = function (table, opts, imgOpts) {
    let wb = this.initWorkBook();
    wb = this.tableToSheet(wb, table, opts, imgOpts);
    return wb;
  };

  methods.convert = function (table, opts = {}) {
    const defaultOpts = {
      name: 'export.xlsx',
      autoStyle: false,
      sheet: {
        name: 'Sheet 1',
        rtl: false,
      },
    };
    opts = { ...defaultOpts, ...opts };
    const wb = this.tableToBook(table, opts);
    this.save(wb, opts.name);
  };

  return methods;
})(Parser);

export default TableToExcelExport;
