import React, { Component } from 'react';
import Select from 'react-select';

import services from './data';

let CategoryOptions = [];

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      options: [],
    };
  }
  componentDidMount() {
    const { isShowDeleted } = this.props;
    services
      .getProductList(isShowDeleted)
      .then((response) => {
        const tmp = response.data.data;
        CategoryOptions = [];

        for (let i = 0; i < tmp.length; i++) {
          CategoryOptions.push({
            value: tmp[i].uuid,
            label: tmp[i].name,
            price: tmp[i].selling_price,
            stock: tmp[i].number_of_units,
          });
        }

        this.setState({ options: CategoryOptions });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleChange = (selectedOption) => {
    const id = this.props.did ? this.props.did : 0;
    let option = selectedOption;
    if (selectedOption == null) {
      option = { value: '', label: '', price: 0, stock: '' };
    }
    this.props.handleDropdownChange(option, id);
  };

  render() {
    const { options } = this.state;
    return (
      <Select
        onChange={this.handleChange}
        options={options}
        isClearable={true}
        className="select-box-container"
        classNamePrefix="raw-select"
        placeholder="Select lube"
        value={options.filter(({ value }) => value === this.props.value)}
        isDisabled={this.props.disabled || false}
      />
    );
  }
}

export { ProductList };
