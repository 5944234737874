import axios from 'axios';

import { authHeader } from '@H';

export default {
  getDSRSummary: (date) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/dsr/summary/?dsr_date=${date}`,
    }),
  getDSRDates: (month, year) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/dsr/dsr-date/?month=${month}&year=${year}`,
    }),
};
