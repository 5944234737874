import React from 'react';

import { Header, Navbar } from '@C';

import './styles.scoped.scss';

const UnauthorizedPage = () => {
  return (
    <main>
      <div className="page-wrapper no-access">
        <Header title="Dashboard" />
        <Navbar />
        <div className="page-content">
          <section className="page-components">
            <div className="access-forbidden">
              <h1>Access Forbidden</h1>
              <p>You are not authorized to access this page.</p>
              <img src="/unauthorized.png" alt="unauthorized" />
            </div>
          </section>
        </div>
      </div>
    </main>
  );
};

export { UnauthorizedPage };
