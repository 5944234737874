import { create } from 'zustand';
import services from '@C/CashiersOnDuty/_utils/data';
import storeApis from '@stores/_utils/data';

export const useGlobalStore = create((set, get) => ({
  creditCustomers: [],
  creditCustomersOptions: [],
  productList: [],
  productListOptions: [],
  taxList: [],
  staffList: [],
  staffListOptions: [],
  codGrandTotal: 0,
  codTotalCollected: 0,
  codStaff: '',
  codShift: '',
  shortage: 0,
  navigationData: [],
  isLoading: false,
  isFetchingNavigation: false,
  fetchNavigationData: async () => {
    const { isFetchingNavigation } = get();
    if (isFetchingNavigation) return; // If isFetching is true, return early
    set({ isFetchingNavigation: true }); // Set isFetching to true before API call
    try {
      const response = await storeApis.getNavigationData();
      const sortedData = response.data.data.sort((a, b) => a.weight - b.weight);
      set({ navigationData: sortedData, isFetchingNavigation: false }); // Set isFetching to false after API call
    } catch (error) {
      console.log(error);
      set({ isFetchingNavigation: false }); // Set isFetching to false after API call
    }
  },
  setCreditCustomers: (creditCustomers) =>
    set({
      creditCustomers: creditCustomers,
      creditCustomersOptions: creditCustomers.map((item) => ({
        value: item.uuid,
        label: `${item.basic_details.first_name} ${item.basic_details.last_name}`,
        gst_no: item.gstn,
      })),
    }),
  setProductList: (productList) =>
    set({
      productList: productList,
      productListOptions: productList.map((item) => ({
        value: item.uuid,
        label: item.product || item.name,
        price: item.price,
        product_type: item.hasOwnProperty('number_of_units') ? 'Lubes' : 'Fuel',
        stock: item.hasOwnProperty('number_of_units')
          ? item.number_of_units
          : 'Available',
      })),
    }),
  setTaxList: (taxList) =>
    set({
      taxList: taxList.map((item) => {
        const taxPercentage = item.tax_list.reduce(
          (a, b) => a + (b['percentage'] || 0),
          0,
        );
        return {
          value: [...(item.tax_list || [])],
          label: item.name,
          multiplier: taxPercentage,
        };
      }),
    }),
  setCODGrandTotal: async (duty, staff) => {
    try {
      const response = await services.getGrandTotal(staff, duty);
      set({
        codStaff: `${response.data.data.cashier_duty.cashier.first_name} ${response.data.data.cashier_duty.cashier.last_name}`,
        codShift:
          response.data.data.cashier_duty.cashier.duty_details.shift_name,
      });
    } catch (error) {
      console.log(error);
    }
  },
  syncCODCollectionData: async (duty, staff) => {
    try {
      const response = await services.getTotalCollection(staff, duty);
      set({
        codGrandTotal: response.data.data.expected_amount,
        codTotalCollected: response.data.data.actual_amount,
        shortage: response.data.data.shortage,
      });
    } catch (error) {
      console.log(error);
    }
  },
  setStaffList: (staffList) => set({ staffList: staffList }),
}));
