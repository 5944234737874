import React from 'react';

import { CreditSlips } from '@C/CreditCustomers/RouteComponents';
import { useGlobalStore } from '@stores/globalStore';

const CreditSaleSummary = (props) => {
  const { syncCODCollectionData } = useGlobalStore((state) => ({
    syncCODCollectionData: state.syncCODCollectionData,
  }));

  const updateTotalSummary = () => {
    syncCODCollectionData(props.match.params.duty, props.match.params.staff);
  };

  return (
    <CreditSlips {...props} cod={true} onDataChange={updateTotalSummary} />
  );
};

export { CreditSaleSummary };
