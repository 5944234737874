import React, { Component } from 'react';
import dayjs from 'dayjs';

import { Fields, Modes } from '@C/Banking/';
import services from '@C/CreditCustomers/_utils/data';
import { TableGrid } from '@C/TableGrid';
import { PaymentMethodInfo } from '@C/Utils';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { authService } from '@S';

const col = [
  {
    name: 'Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Amount',
    key: 'after_tax',
  },

  {
    name: 'Payment mode',
    key: 'payment_method',
    type: 'payment',
    formatter(props) {
      return (
        <>
          <PaymentMethodInfo
            source={props.source}
            method={props.payment_method}
            bank={props.bank}
            wallet={props.other_wallet}
          />
        </>
      );
    },
  },
  {
    name: 'Notes',
    key: 'notes',
  },
];

class PaymentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentList: [],
      customer_id: authService.currentUserId,
      offset: 1,
      count: 0,
      limit: 20,
      start_date: this.props.cod
        ? this.props.date
        : dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: this.props.cod ? this.props.date : dayjs().format('YYYY-MM-DD'),
      loading: true,
      payment: {
        bank_id: '',
        payment_method: 'OFFLINE',
        source: 'CASH',
        check_date: '',
        check_number: '',
        mode: 'cash',
      },
    };
    this.limitInput = React.createRef();
  }
  componentDidMount() {
    this.getPaymentList();
  }

  getPaymentList = () => {
    services
      .getPaymentList(this.state)
      .then((response) => {
        this.setState({
          paymentList: response.data.data.results,
          count: response.data.data.count,
          loading: false,
        });
      })

      .catch((error) => {
        console.log(error.response.data.error);
      });
  };

  handlePagination = (e) => {
    this.setState({ offset: e, loading: true }, function () {
      this.getPaymentList();
    });
  };

  handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date' && dayjs(value).isAfter(this.state.end_date)) {
      this.setState({ [name]: value, end_date: value, loading: true }, () =>
        this.getPaymentList(),
      );
    } else if (
      name === 'end_date' &&
      dayjs(value).isBefore(this.state.start_date)
    ) {
      this.setState({ [name]: value, start_date: value, loading: true }, () =>
        this.getPaymentList(),
      );
    } else {
      this.setState({ [name]: value, loading: true }, () =>
        this.getPaymentList(),
      );
    }
  };

  handleModeChange = (e) => {
    this.setState(
      { by_payment_type: e?.value || '', loading: true },
      function () {
        this.getPaymentList();
      },
    );
  };

  handlePaymentSelect = (mode, i) => {
    const list = { ...this.state.payment };
    list.mode = mode;
    if (mode == 'cash') {
      list.payment_method = 'OFFLINE';
      list.source = 'CASH';
    } else if (mode == 'wallet') {
      list.payment_method = 'IMPS';
      list.source = 'WALLET';
    } else if (mode == 'pos') {
      list.payment_method = 'IMPS';
      list.source = 'SWIPE';
    } else if (mode == 'cheque') {
      list.payment_method = 'CHECK';
      list.source = 'SELF';
    } else {
      list.payment_method = mode;
      list.source = 'SELF';
    }
    this.setState({ payment: list, loading: true }, function () {
      this.getPaymentList();
    });
  };

  onDataChange = (e, i) => {
    console.log(e);
    const list = { ...this.state.payment };
    list.bank_id = e.bank_id;
    list.check_number = e.bank_reference_id;
    list.check_date = e.check_date;
    // list.payment_method = e.payment_method;
    // list.source = e.transaction_source;
    this.setState({ payment: list, loading: true }, function () {
      this.getPaymentList();
    });
  };

  handleQuickSearch = (e) => {
    e.preventDefault();
    if (
      this.limitInput.current.value === '' ||
      this.limitInput.current.value === '0'
    ) {
      return;
    }
    this.setState(
      { limit: this.limitInput.current.value, offset: 1 },
      function () {
        this.getPaymentList();
      },
    );
  };

  render() {
    const { paymentList } = this.state;
    return (
      <>
        <section className="widget full-width list-table">
          <header>
            <h3> Payment list </h3>
          </header>
          <main>
            <div className="table-filters">
              <div className="filter-wrapper">
                <div className="filter-group">
                  <label>From date:</label>
                  <input
                    className="cstm-date-input"
                    type="date"
                    value={this.state.start_date}
                    name="start_date"
                    onChange={this.handleDateChange}
                  />
                </div>
                <div className="filter-group">
                  <label>To date:</label>
                  <input
                    className="cstm-date-input"
                    type="date"
                    value={this.state.end_date}
                    onChange={this.handleDateChange}
                    name="end_date"
                  />
                </div>
                <div className="filter-group">
                  <label>Payment mode:</label>
                  <div>
                    <Modes
                      handleModeChange={this.handlePaymentSelect}
                      value={this.state.payment.mode || 'cash'}
                    />
                  </div>
                  <Fields
                    method={this.state.payment.mode}
                    onDataChange={this.onDataChange}
                    bank={this.state.payment.bank}
                    bank_reference_id={this.state.payment.bank_reference_id}
                    check_date={this.state.payment.check_date}
                  />
                </div>
                <div className="filter-group with-icon">
                  <label>
                    Results to display- {this.state.limit || 20} (Total:{' '}
                    {this.state.count})
                  </label>
                  <form onSubmit={this.handleQuickSearch}>
                    <input type="text" name="limit" ref={this.limitInput} />
                    <button
                      type="submit"
                      className="search-icon"
                      onClick={this.handleQuickSearch}
                    />
                  </form>
                </div>
                <div className="filter-group reset-filter">
                  <button onClick={this.resetFilter}>Reset filters</button>
                </div>
              </div>
            </div>
            <div className="table-wrapper">
              {(() => {
                if (this.state.loading) {
                  return <DropLoaderLarge />;
                } else {
                  return (
                    <TableGrid
                      col={col}
                      row={paymentList}
                      offset={this.state.offset}
                      hideActions={true}
                      count={this.state.count}
                      limit={this.state.limit}
                      handlePagination={this.handlePagination}
                    />
                  );
                }
              })()}
            </div>
          </main>
        </section>
      </>
    );
  }
}
export { PaymentList };
