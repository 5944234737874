/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import services from '@C/DipReading/_utils/data';
import { FuelPurchaseList } from '@C/Purchases/RouteComponents/Fuel/ListPurchases';
import { formatCurrency } from '@C/TableGrid';
import { FuelList, TankList, VendorList } from '@C/Utils';
import { authHeader } from '@H';

import './styles.scoped.scss';

class FuelPurchase extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
    this.myRef = React.createRef();
  }

  state = {
    quantityError: false,
    editMode: false,
    updateList: false,
    tankList: [],
    fuelList: [],
    vendorList: [],
    updated: false,
    invoice_number: '',
    invoice_date: dayjs().format('YYYY-MM-DD'),
    time_stamp: dayjs().format('YYYY-MM-DD'),
    vendor_id: '',
    vehicle_number: '',
    notes: '',
    product_list: [
      {
        uuid: '',
        price: 0,
        vat: 0,
        additional_vat: 0,
        cess: 0,
        social_sec_cess: 0,
        reverse_delay_commission: 0,
        recovery_for_lfr_sslf: 0,
        tcs_amount: 0,
        quantity: 0,
        discount: 0,
        tanks: [
          {
            quantity: 0,
            tank_id: '',
          },
        ],
        base_amount: 0,
        amount: 0,
      },
    ],
    restrictTanks: 10,
    isSubmitting: false,
  };
  componentDidMount() {
    this.getTankList();
  }

  getTankList = () => {
    services
      .getTankList()
      .then((response) => {
        const tmp = response.data.data;
        const CategoryOptions = [];
        for (let i = 0; i < tmp.length; i++) {
          CategoryOptions.push({
            value: tmp[i].uuid,
            label: tmp[i].name,
            product: tmp[i].product.uuid,
          });
        }
        this.setState({
          tankList: CategoryOptions,
        });
      })

      .catch((error) => {
        console.log(error);
      });
  };

  transferCalculation = (index) => {
    const { product_list } = this.state;
    const { quantity, tanks } = product_list[index];

    // tanks quantity combined should not be greater than product quantity
    let totalQuantity = 0;
    for (let i = 0; i < tanks.length; i++) {
      totalQuantity += parseFloat(tanks[i].quantity);
    }
    if (totalQuantity > quantity) {
      this.setState({ quantityError: true });
      return;
    } else if (totalQuantity < quantity) {
      this.setState({ quantityError: true });
      return;
    } else {
      this.setState({ quantityError: false });
    }
  };

  handleInvoiceChange = (e) => {
    if (e.target.type == 'number') {
      this.setState({ [e.target.name]: parseFloat(e.target.value) });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  handleProductChange = (e, i) => {
    const list = [...this.state.product_list];
    list[i].uuid = e.value;
    // get number of tanks for the product
    const { tankList } = this.state;
    const tankCount = tankList.filter((item) => item.product == e.value).length;
    // remove extra tanks
    if (e.value && tankCount < list[i].tanks.length) {
      list[i].tanks.splice(tankCount, list[i].tanks.length - tankCount);
    }

    this.setState({ product_list: list, restrictTanks: tankCount });
  };

  handleDataChange = (e, i) => {
    const list = [...this.state.product_list];
    list[i][e.target.name] = e.target.value;
    this.setState({ product_list: list }, function () {
      this.reCalculateAmount(i);
      if (e.target.name == 'quantity') {
        this.transferCalculation(i);
      }
    });
  };
  handleTankChange = (e, i, j) => {
    const product_list = [...this.state.product_list];
    const tanks = [...product_list[i].tanks];
    tanks[j].tank_id = e.value;
    this.setState({ product_list: product_list });
  };

  handleTankDataChange = (e, i, j) => {
    if (e.target.name == 'quantity') {
      const product_list = [...this.state.product_list];
      const tanks = [...product_list[i].tanks];
      tanks[j].quantity = e.target.value;
      this.setState({ product_list: product_list }, function () {
        this.transferCalculation(i);
      });
    } else {
      const product_list = [...this.state.product_list];
      const tanks = [...product_list[i].tanks];
      tanks[j].quantity = e.target.value;
      this.setState({ product_list: product_list });
    }
  };

  handleVendorChange = (e) => {
    this.setState({ vendor_id: e.value });
  };

  getFuelPurchase = () => {
    services
      .getFuelPurchase()
      .then((response) => {
        this.setState({ productList: response.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addProductRow = () => {
    this.setState((prevState) => ({
      product_list: [
        ...prevState.product_list,
        {
          uuid: '',
          price: 0,
          vat: 0,
          additional_vat: 0,
          cess: 0,
          social_sec_cess: 0,
          reverse_delay_commission: 0,
          recovery_for_lfr_sslf: 0,
          tcs_amount: 0,
          quantity: 0,
          discount: 0,
          tanks: [
            {
              quantity: 0,
              tank_id: '',
            },
          ],
          base_amount: 0,
          amount: 0,
        },
      ],
    }));
  };

  addTankRow = (i) => {
    this.setState((prevState) => {
      const product_list = [...prevState.product_list];
      const tanks = [...product_list[i].tanks, { quantity: 0, tank_id: '' }];
      product_list[i] = { ...product_list[i], tanks };
      return { product_list };
    });
  };

  deleteTankRow = (i, j) => {
    this.setState((prevState) => {
      const product_list = [...prevState.product_list];
      const tanks = [...product_list[i].tanks].filter(
        (s, sindex) => j !== sindex,
      );
      product_list[i] = { ...product_list[i], tanks };
      return { product_list };
    });
  };

  deleteProductRow = (i) => {
    this.setState(
      {
        product_list: this.state.product_list.filter(
          (s, sindex) => i !== sindex,
        ),
      },
      function () { },
    );
  };

  editMode = (item) => {
    this.setState({ editMode: false });
    window.scrollTo({
      behavior: 'smooth',
      top: this.myRef.current.offsetTop - 100,
    });
    services
      .getFuelPurchaseItem(item.uuid)
      .then((response) => {
        const tmp = response.data.data.invoice;
        const tmpProductArray = [];
        const tmpProductList = tmp.product;
        for (let i = 0; i < tmpProductList.length; i++) {
          tmpProductArray.push({
            uuid: tmpProductList[i].uuid,
            price: tmpProductList[i].fuel_price,
            vat: tmpProductList[i].vat,
            social_sec_cess: tmpProductList[i].social_sec_cess,
            cess: tmpProductList[i].cess,
            additional_vat: tmpProductList[i].additional_vat,
            reverse_delay_commission:
              tmpProductList[i].reverse_delay_commission,
            recovery_for_lfr_sslf: tmpProductList[i].recovery_for_lfr_sslf,
            tcs_amount: tmpProductList[i].tcs_amount,
            discount: tmpProductList[i].discount,
            quantity: tmpProductList[i].quantity,
            base_amount: tmpProductList[i].base_amount,
            amount: tmpProductList[i].amount,
            tanks: tmpProductList[i].tanks.map((item) => {
              return {
                quantity: item.transfer_quantity || 0,
                tank_id: item.tank.uuid || '',
              };
            }),
          });
        }
        this.setState({
          uuid: item.uuid,
          product_list: tmpProductArray,
          time_stamp: dayjs(tmp.time_stamp).format('YYYY-MM-DD'),
          editMode: true,
          invoice_number: tmp.invoice_number,
          invoice_date: tmp.invoice_date,
          vendor_id: tmp.vendor.uuid,
          vehicle_number: tmp.vehicle_number,
          notes: tmp.notes,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  cancelEdit = () => {
    this.setState({
      editMode: false,
      updateList: false,
      isSubmitting: false,
      tankList: [],
      fuelList: [],
      vendorList: [],
      updated: false,
      invoice_number: '',
      invoice_date: dayjs().format('YYYY-MM-DD'),
      time_stamp: dayjs().format('YYYY-MM-DD'),
      vendor_id: '',
      vehicle_number: '',
      notes: '',
      product_list: [
        {
          uuid: '',
          price: 0,
          vat: 0,
          additional_vat: 0,
          cess: 0,
          social_sec_cess: 0,
          reverse_delay_commission: 0,
          recovery_for_lfr_sslf: 0,
          tcs_amount: 0,
          quantity: 0,
          discount: 0,
          tanks: [
            {
              quantity: 0,
              tank_id: '',
            },
          ],
          base_amount: 0,
          amount: 0,
        },
      ],
      restrictTanks: 10,
    });
  };

  reCalculateAmount = (i) => {
    const list = [...this.state.product_list];
    const base_amount = list[i].base_amount || 0;
    const qty = list[i].quantity || 0;
    const discount = list[i].discount || 0;
    const vat = parseFloat(list[i].vat || 0);
    const additional_vat = parseFloat(list[i].additional_vat || 0);
    const cess = parseFloat(list[i].cess || 0);
    const social_sec_cess = parseFloat(list[i].social_sec_cess || 0);
    const reverse_delay_commission = +list[i].reverse_delay_commission || 0;
    const recovery_for_lfr_sslf = +list[i].recovery_for_lfr_sslf || 0;
    const tcs_amount = parseFloat(list[i].tcs_amount || 0);
    const amt =
      base_amount -
      discount +
      vat +
      additional_vat -
      reverse_delay_commission +
      recovery_for_lfr_sslf +
      cess +
      social_sec_cess +
      tcs_amount;
    list[i].amount = amt.toFixed(2);
    list[i].price = parseFloat(amt / qty || 0).toFixed(2);
    this.setState({ product_list: list });
  };

  handleSubmit = () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState({ isSubmitting: true });

    if (this.state.quantityError) {
      return;
    }
    const { tankList, fuelList, vendorList, updated, ...formData } = this.state;
    const headers = authHeader(1);

    if (this.state.editMode) {
      axios
        .put(`${process.env.API_URL}/bunk/fuel-purchase/`, formData, {
          headers,
        })
        .then(() => {
          toast.success('Fuel purchase added successfully');
          window.scrollTo({
            behavior: 'smooth',
            top: this.myRef.current.offsetTop - 150,
          });
          this.setState({
            editMode: false,
            isSubmitting: false,
            updated: !this.state.updated,
            invoice_number: '',
            invoice_date: dayjs().format('YYYY-MM-DD'),
            time_stamp: dayjs().format('YYYY-MM-DD'),
            vendor_id: '',
            vehicle_number: 0,
            notes: '',
            product_list: [
              {
                uuid: '',
                price: 0,
                vat: 0,
                additional_vat: 0,
                cess: 0,
                social_sec_cess: 0,
                reverse_delay_commission: 0,
                recovery_for_lfr_sslf: 0,
                tcs_amount: 0,
                quantity: 0,
                tanks: [
                  {
                    quantity: 0,
                    tank_id: '',
                  },
                ],
                base_amount: 0,
                amount: 0,
              },
            ],
            restrictTanks: 10,
          });
          this.validator.hideMessages();
          this.forceUpdate();
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          this.setState({ isSubmitting: false });
        });
    } else {
      axios
        .post(`${process.env.API_URL}/bunk/fuel-purchase/`, formData, {
          headers,
        })
        .then(() => {
          toast.success('Fuel purchase added successfully', {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.setState({
            editMode: false,
            isSubmitting: false,
            updated: !this.state.updated,
            invoice_number: '',
            invoice_date: dayjs().format('YYYY-MM-DD'),
            time_stamp: dayjs().format('YYYY-MM-DD'),
            vendor_id: '',
            vehicle_number: 0,
            notes: '',
            product_list: [
              {
                uuid: '',
                price: 0,
                vat: 0,
                additional_vat: 0,
                cess: 0,
                social_sec_cess: 0,
                reverse_delay_commission: 0,
                recovery_for_lfr_sslf: 0,
                tcs_amount: 0,
                quantity: 0,
                discount: 0,
                tanks: [
                  {
                    quantity: 0,
                    tank_id: '',
                  },
                ],
                base_amount: 0,
                amount: 0,
              },
            ],
            restrictTanks: 10,
          });
          this.validator.hideMessages();
          this.forceUpdate();
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.setState({ isSubmitting: false });
        });
    }
  };

  render() {
    const { editMode } = this.state;
    const invoiceData = this.state;
    const productList = this.state.product_list;
    const total_fuel_amount = productList.reduce(
      (a, b) => a + parseFloat(b['amount'] > 0 ? b['amount'] : 0),
      0,
    );
    return (
      <>
        <section className="widget full-width grid-form" ref={this.myRef}>
          <header>
            <h3>Invoice details</h3>
          </header>
          <main>
            <div className="form-content">
              <div className="input-wrapper with-label">
                <label> Date of Supply</label>
                <input
                  type="date"
                  value={invoiceData.time_stamp}
                  name="time_stamp"
                  onChange={this.handleInvoiceChange}
                  disabled={editMode}
                />
              </div>
              <div className="input-wrapper with-label">
                <label> Invoice Date</label>
                {this.validator.message(
                  'invoice_date',
                  invoiceData.invoice_date,
                  'required',
                )}
                <input
                  type="date"
                  value={invoiceData.invoice_date}
                  name="invoice_date"
                  onChange={this.handleInvoiceChange}
                  disabled={editMode}
                />
              </div>
              <div className="input-wrapper with-label">
                <label> Invoice number</label>
                {this.validator.message(
                  'invoice_number',
                  invoiceData.invoice_number,
                  'required',
                )}
                <input
                  type="text"
                  name="invoice_number"
                  value={invoiceData.invoice_number}
                  onChange={this.handleInvoiceChange}
                />
              </div>
              <div className="input-wrapper with-label">
                <label>
                  <span>Supplier Name</span>
                </label>
                {this.validator.message(
                  'vendor_id',
                  invoiceData.vendor_id,
                  'required',
                )}
                <VendorList
                  onChange={this.handleVendorChange}
                  value={invoiceData.vendor_id}
                  disabled={editMode}
                />
              </div>

              <div className="input-wrapper with-label">
                <label> Vehicle No</label>
                <input
                  type="text"
                  name="vehicle_number"
                  onChange={this.handleInvoiceChange}
                  value={invoiceData.vehicle_number}
                />
              </div>

              <div className="input-wrapper with-label">
                <label className="label"> Notes</label>
                <input
                  type="text"
                  name="notes"
                  value={invoiceData.notes}
                  onChange={this.handleInvoiceChange}
                />
              </div>
              {this.state.product_list.length < 10 && !editMode && (
                <div className="form-actions">
                  <button
                    className="submit"
                    onClick={() => this.addProductRow()}
                  >
                    Add product
                  </button>
                </div>
              )}
            </div>
          </main>
        </section>
        <section className="widget full-width box-form">
          <header>
            <h3>Products</h3>
          </header>
          <main>
            <div className="form-content full-width">
              {this.state.quantityError && (
                <div className="notification-alert notification-alert__error">
                  Combined tank quantity should be equal to product quantity
                </div>
              )}
              {productList.map((item, i) => (
                <div className="form-item" key={i}>
                  <div className="form-row">
                    <div className="input-wrapper with-label">
                      <label>
                        <span>Product</span>
                      </label>
                      {this.validator.message('uuid', item.uuid, 'required')}
                      <FuelList
                        onChange={this.handleProductChange}
                        did={i}
                        value={item.uuid}
                        disabled={editMode}
                      />
                    </div>
                    <div className="input-wrapper with-label">
                      <label>
                        <span>Total quantity</span>
                      </label>
                      {this.validator.message(
                        'quantity',
                        item.quantity,
                        'required|numeric|min:0.1,num',
                      )}
                      <input
                        name="quantity"
                        type="text"
                        value={item.quantity}
                        onChange={() => this.handleDataChange(event, i)}
                      />
                    </div>
                    <div className="inner-row">
                      {item.tanks.map((tank, j) => (
                        <div className="row-wrapper" key={j}>
                          <div className="input-wrapper with-label">
                            <label>
                              <span>Tank</span>
                            </label>
                            {this.validator.message(
                              'tank_id',
                              tank.tank_id,
                              'required',
                            )}
                            <TankList
                              options={this.state.tankList}
                              value={tank.tank_id}
                              did={i}
                              jid={j}
                              onChange={this.handleTankChange}
                              isDependent={item.uuid}
                            />
                          </div>
                          <div className="input-wrapper with-label">
                            <label>
                              <span>Quantity transferred</span>
                            </label>
                            {this.validator.message(
                              'quantity_transferred',
                              tank.quantity,
                              'required|numeric|min:0.1,num',
                            )}
                            <input
                              name="quantity"
                              type="text"
                              value={tank.quantity}
                              onChange={() =>
                                this.handleTankDataChange(event, i, j)
                              }
                            />
                          </div>
                          {j == 0 &&
                            item.tanks.length < this.state.restrictTanks && (
                              <button
                                className="add-row-icon"
                                onClick={() => this.addTankRow(i)}
                              >
                                Add row
                              </button>
                            )}
                          {j > 0 ? (
                            <button
                              className="delete-row icon-only"
                              onClick={() => this.deleteTankRow(i, j)}
                            >
                              Delete row
                            </button>
                          ) : (
                            ''
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="input-wrapper with-label">
                      <label>
                        <span> Amount</span>
                      </label>
                      {this.validator.message(
                        'base_amount',
                        item.base_amount,
                        'required|numeric|min:0,num',
                      )}
                      <input
                        name="base_amount"
                        type="text"
                        onChange={() => this.handleDataChange(event, i)}
                        value={item.base_amount}
                        disabled={editMode}
                      />
                    </div>
                    <div className="input-wrapper with-label">
                      <label>
                        <span>Discount</span>
                      </label>
                      {this.validator.message(
                        'discount',
                        item.discount,
                        'numeric|min:0,num',
                      )}
                      <input
                        name="discount"
                        type="text"
                        onChange={() => this.handleDataChange(event, i)}
                        value={item.discount}
                      />
                    </div>
                    <div className="input-wrapper with-label">
                      <label>
                        <span>VAT</span>
                      </label>
                      {this.validator.message(
                        'VAT',
                        item.vat,
                        'numeric|min:0,num',
                      )}
                      <input
                        name="vat"
                        type="text"
                        value={item.vat}
                        onChange={() => this.handleDataChange(event, i)}
                        disabled={editMode}
                      />
                    </div>
                    <div className="input-wrapper with-label">
                      <label>
                        <span>Additional VAT</span>
                      </label>
                      {this.validator.message(
                        'AVAT',
                        item.additional_vat,
                        'numeric|min:0,num',
                      )}
                      <input
                        name="additional_vat"
                        type="text"
                        value={item.additional_vat}
                        onChange={() => this.handleDataChange(event, i)}
                        disabled={editMode}
                      />
                    </div>
                    <div className="input-wrapper with-label">
                      <label>
                        <span>Cess</span>
                      </label>
                      <input
                        name="cess"
                        type="text"
                        value={item.cess}
                        onChange={() => this.handleDataChange(event, i)}
                        disabled={editMode}
                      />
                    </div>
                    <div className="input-wrapper with-label">
                      <label>
                        <span> Social sec cess</span>
                      </label>
                      <input
                        name="social_sec_cess"
                        type="text"
                        value={item.social_sec_cess}
                        onChange={() => this.handleDataChange(event, i)}
                        disabled={editMode}
                      />
                    </div>
                    <div className="input-wrapper with-label">
                      <label>
                        <span>Reverse dealer commission</span>
                      </label>
                      {this.validator.message(
                        'rdc',
                        item.reverse_delay_commission,
                        'numeric|min:0,num',
                      )}
                      <input
                        name="reverse_delay_commission"
                        type="text"
                        value={item.reverse_delay_commission}
                        onChange={() => this.handleDataChange(event, i)}
                        disabled={editMode}
                      />
                    </div>
                    <div className="input-wrapper with-label">
                      <label>
                        <span>Recovery for LFR/SSLF</span>
                      </label>
                      {this.validator.message(
                        'rf_lfr_sslf',
                        item.recovery_for_lfr_sslf,
                        'numeric|min:0,num',
                      )}
                      <input
                        name="recovery_for_lfr_sslf"
                        type="text"
                        value={item.recovery_for_lfr_sslf}
                        onChange={() => this.handleDataChange(event, i)}
                        disabled={editMode}
                      />
                    </div>
                    <div className="input-wrapper with-label">
                      <label>
                        <span>TCS amount</span>
                      </label>
                      {this.validator.message(
                        'item_tcs_amount',
                        item.tcs_amount,
                        'numeric|min:0,num',
                      )}
                      <input
                        name="tcs_amount"
                        type="text"
                        value={item.tcs_amount}
                        onChange={() => this.handleDataChange(event, i)}
                        disabled={editMode}
                      />
                    </div>
                    <div className="input-wrapper with-label">
                      <label>
                        <span>Price / liter</span>
                      </label>
                      <input
                        name="price"
                        type="text"
                        value={item.price}
                        disabled
                      />
                    </div>
                    <div className="input-wrapper with-label">
                      <label>
                        <span> Total amount </span>
                      </label>
                      <input type="text" disabled value={item.amount} />
                    </div>
                  </div>
                  {i > 0 && (
                    <div className="form-actions">
                      <button
                        className="white-btn"
                        onClick={() => this.deleteProductRow(i)}
                      >
                        Remove product
                      </button>
                    </div>
                  )}
                </div>
              ))}
              <div className="mini-summary">
                <div className="summary-row">
                  <span>Actual Cost: </span>
                  <span>
                    {formatCurrency(
                      total_fuel_amount > 0 ? total_fuel_amount : 0 || 0,
                    )}
                  </span>
                </div>
              </div>
              <div className="form-actions">
                <button
                  className={`submit ${this.state.isSubmitting ? 'loading' : ''
                    }`}
                  onClick={this.handleSubmit}
                >
                  Submit
                </button>
                {editMode && (
                  <button className="red-btn" onClick={this.cancelEdit}>
                    Cancel
                  </button>
                )}
              </div>
            </div>
          </main>
        </section>
        <FuelPurchaseList
          key={this.state.updated}
          tankList={this.state.tankList}
          onEdit={this.editMode}
        />
      </>
    );
  }
}

export { FuelPurchase };
