import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import RoleList from '@C/Utils/RoleList';
import { authHeader } from '@H';
import { authService } from '@S';

class StaffSetup extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
    this.myRef = React.createRef();
  }

  state = {
    username: '',
    password: '',
    email: '',
    mobile: '',
    user_role: '',
    first_name: '',
    last_name: '',
    currRole: this.props.role,
    showPassword: false,
  };

  handleChange = (e) => {
    if (['name', 'capacity'].includes(e.target.name)) {
      const staffDetails = [...this.state.staffDetails];
      staffDetails[e.target.dataset.id][e.target.name] = e.target.value;
      this.setState({ [e.target.name]: e.target.value });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  handleRoleChange = (e) => {
    this.setState({ user_role: e });
  };

  onChange = (field) => {
    return (event) => {
      const state = {};
      state[field] = event.target.value;
      this.setState(state);
    };
  };

  onTogglePasswd = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  submitForm = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      const formData = {
        username: this.state.username,
        password: this.state.password,
        email: this.state.email,
        mobile: this.state.mobile,
        user_role: this.state.user_role,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
      };

      const headers = authHeader(authService.currentAccountID);
      axios
        .post(`${process.env.API_URL}/users/register/`, formData, { headers })
        .then((response) => {
          toast.success('Staff added successfully');
          window.scrollTo({
            behavior: 'smooth',
            top: this.myRef.current.offsetTop - 150,
          });
          this.setState({
            first_name: '',
            last_name: '',
            email: '',
            mobile: '',
            username: '',
            password: '',
          });
          if (this.props.onUpdate) {
            this.props.onUpdate();
          }
          this.validator.hideMessages();
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
  };

  render() {
    return (
      <>
        <section className="widget form-card full-width" ref={this.myRef}>
          <header>
            <h3>Add staff</h3>
          </header>
          <main>
            <form className="inline">
              <div className="input-wrapper with-label">
                {this.validator.message(
                  'first name',
                  this.state.first_name,
                  'required|alpha_num_space',
                )}
                <label htmlFor="fname">First name</label>
                <input
                  type="text"
                  id="fname"
                  name="first_name"
                  onChange={this.handleChange}
                  value={this.state.first_name}
                />
              </div>
              <div className="input-wrapper with-label">
                {this.validator.message(
                  'last name',
                  this.state.last_name,
                  'alpha_num_space',
                )}
                <label htmlFor="lname">Last name</label>
                <input
                  type="text"
                  id="lname"
                  name="last_name"
                  onChange={this.handleChange}
                  value={this.state.last_name}
                />
              </div>
              {/* <div className="input-wrapper with-label">
                {this.validator.message(
                  'username',
                  this.state.username,
                  'alpha_num_dash_space',
                )}
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  onChange={this.handleChange}
                  value={this.state.username}
                />
              </div> */}
              <div className="input-wrapper with-label">
                {this.validator.message(
                  'mobile',
                  this.state.mobile,
                  'required|phone|numeric|min:10,array|max:10,array',
                )}
                <label htmlFor="mobile">Mobile</label>
                <input
                  type="text"
                  id="mobile"
                  name="mobile"
                  onChange={this.handleChange}
                  value={this.state.mobile}
                />
              </div>
              <div
                className={
                  this.state.showPassword
                    ? 'input-wrapper visible with-label'
                    : 'input-wrapper with-label'
                }
              >
                {this.validator.message(
                  'password',
                  this.state.password,
                  'required',
                )}
                <label htmlFor="password">Password</label>
                <input
                  type={this.state.showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  onChange={this.onChange('password')}
                  className={this.state.error ? 'error' : ''}
                  value={this.state.password}
                />
                <span
                  className="show-psswd"
                  onClick={() => this.onTogglePasswd()}
                />
              </div>

              <div className="input-wrapper with-label">
                {this.validator.message('email', this.state.email, 'email')}
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  onChange={this.handleChange}
                  value={this.state.email}
                />
              </div>
              <div className="input-wrapper">
                {this.validator.message(
                  'role',
                  this.state.user_role,
                  'required',
                  { messages: { required: 'Please select a role' } },
                )}
                <RoleList
                  did={this.state.idx}
                  id="user_role"
                  handleRoleChange={this.handleRoleChange}
                  role={this.state.currRole}
                />
              </div>
              <div className="cta-wrapper">
                <button className="submit" onClick={this.submitForm}>
                  Add staff
                </button>
              </div>
            </form>
          </main>
        </section>
      </>
    );
  }
}
export { StaffSetup };
