import React, { Component } from 'react';
import Select from 'react-select';

import services from './data';

let CategoryOptions;

class ShiftsList extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      options: [],
    };
  }
  componentDidMount() {
    services
      .getShiftsList()
      .then((response) => {
        const tmp = response.data.data;
        CategoryOptions = [];
        for (let i = 0; i < tmp.length; i++) {
          CategoryOptions.push({
            value: tmp[i].uuid,
            label: tmp[i].shift,
          });
        }
        this.setState({ options: CategoryOptions });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleChange = (selectedOption) => {
    const id = this.props.did ? this.props.did : 0;
    let option = selectedOption;
    if (selectedOption == null) {
      option = { value: '', label: '' };
    }
    this.props.onChange(option, id);
  };

  render() {
    const { options } = this.state;
    return (
      <Select
        onChange={this.handleChange}
        isClearable={true}
        options={options}
        className="select-box-container shift-selector-container"
        classNamePrefix="shift-selector"
        name="oml"
        placeholder="Select shift"
        value={options.filter(({ value }) => value === this.props.value)}
        isDisabled={this.props.disabled || false}
      />
    );
  }
}

export { ShiftsList };
