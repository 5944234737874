import React, { Component } from 'react';
import dayjs from 'dayjs';
import Select from 'react-select';

import services from './data';

let CategoryOptions;

class DutyList extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      options: [],
      date: this.props.date || dayjs().format('YYYY-MM-DD'),
    };
  }
  componentDidMount() {
    services
      .getDutyList(this.state.date)
      .then((response) => {
        const tmp = response.data.data.duties_list;
        CategoryOptions = [];
        const dealers = [];
        const managers = [];
        const cashiers = [];
        tmp.map((item, i) => {
          if (item.role_type == 101) {
            dealers.push({
              label: `${item.first_name} ${item.last_name}`,
              value: item.cashier_duty_id || null,
              role: item.role_type,
            });
          } else if (item.role_type == 102) {
            managers.push({
              label: `${item.first_name} ${item.last_name}`,
              value: item.cashier_duty_id || null,
              role: item.role_type,
            });
          } else {
            cashiers.push({
              label: `${item.first_name} ${item.last_name} - ${item.shift_name} `,
              value: item.cashier_duty_id || null,
              role: item.role_type,
            });
          }
        });
        CategoryOptions = [
          {
            label: 'Cashiers',
            options: cashiers,
          },
          {
            label: 'Dealer',
            options: dealers,
          },
          {
            label: 'Managers',
            options: managers,
          },
        ];
        this.setState({ options: CategoryOptions });
        // this.props.onChange({ value: null, duty: null });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleChange = (selectedOption) => {
    this.props.onChange(selectedOption);
  };

  render() {
    const { options } = this.state;
    return (
      <Select
        onChange={this.handleChange}
        options={options}
        className="select-box-container staff-selector-container"
        classNamePrefix="staff-selector"
        placeholder="Select staff"
        value={options
          .flatMap((ind) => ind.options)
          .filter((item) => item.value === this.props.value)}
        isDisabled={this.props.disabled || false}
      />
    );
  }
}

export { DutyList };
