import axios from 'axios';

import { authHeader } from '@H';

export default {
  getAccountTypeList: () =>
    axios({
      method: 'GET',
      headers: authHeader(),
      url: `${process.env.API_URL}/utils/account-types/`,
    }),
};
