import React, { Component } from 'react';
import dayjs from 'dayjs';

import services from '@C/Reports/_utils/data';
import { TableGrid } from '@C/TableGrid';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';

const col = [
  {
    name: 'Date',
    key: 'datetime_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Product',
    key: 'product_name',
  },
  {
    name: 'Price',
    key: 'price',
  },
];

class FuelPriceReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fuelPriceList: [],
      date: dayjs().format('YYYY-MM-DD'),
      product_id: '',
      loading: false,
      limit: 20,
      offset: 1,
      count: 0,
    };
    this.limitInput = React.createRef();
  }

  componentDidMount() {
    this.getFuelPrice();
  }

  handleDateChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  getFuelPrice = () => {
    services
      .getFuelPrice(this.state)
      .then((response) => {
        this.setState({
          fuelPriceList: response.data.data.fuel_prices,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleQuickSearch = (e) => {
    e.preventDefault();
    if (
      this.limitInput.current.value === '' ||
      this.limitInput.current.value === '0'
    ) {
      return;
    }
    this.setState({ limit: this.limitInput.current.value }, () => {
      this.getFuelPrice();
    });
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, function () {
      this.getFuelPrice();
    });
  };

  resetFilter = () => {
    this.setState(
      {
        date: dayjs().format('YYYY-MM-DD'),
        loading: true,
      },
      function () {
        this.getFuelPrice();
      },
    );
  };

  render() {
    const { fuelPriceList } = this.state;

    return (
      <section className="widget full-width list-table inverted">
        <header>
          <h3> Fuel Price History </h3>
        </header>
        <main>
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group">
                <label>Date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.date}
                  name="date"
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="filter-group with-icon">
                <label>
                  Results to display- {this.state.limit || 20} (Total:{' '}
                  {this.state.count})
                </label>
                <form onSubmit={this.handleQuickSearch}>
                  <input type="text" name="limit" ref={this.limitInput} />
                  <button
                    type="submit"
                    className="search-icon"
                    onClick={this.handleQuickSearch}
                  />
                </form>
              </div>
              <div className="filter-group reset-filter">
                <button onClick={this.resetFilter}>Reset filters</button>
              </div>
              <div className="filter-group">
                  <button
                    className="submit"
                    type="submit"
                    onClick={this.submitForm}
                  >
                    Apply filter
                  </button>
                </div>
            </div>
          </div>
          <div className="table-wrapper">
            {(() => {
              if (this.state.loading) {
                return <DropLoaderLarge />;
              } else {
                return (
                  <TableGrid
                    col={col}
                    row={fuelPriceList}
                    offset={this.state.offset}
                    count={this.state.count}
                    limit={this.state.limit}
                    pagination="disabled"
                    hideActions={true}
                    exportOptions={{
                      fileName: ` Fuel Price History - ${this.state.start_date} to ${this.state.end_date}.pdf`,
                      reportName: 'Fuel Price History Report',
                      reportDate: `Date - ${dayjs(this.state.date).format('D MMM YY')}`,
                      reportTotal: '',
                      reportQuantity: '',
                      reportSaleTotal: '',
                      reportTCSTotal: '',
                      reportSales: '',
                      reportTesting: '',
                      reportBowser: '',
                      reportCustomerName: '',
                      reportGst: '',
                      reportOpeningBalance: '',
                      reportClosingBalance: '',
                    }}
                  />
                );
              }
            })()}
          </div>
        </main>
      </section>
    );
  }
}

export { FuelPriceReport };
