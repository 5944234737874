import React, { Component } from 'react';
import Select from 'react-select';

import services from './data';

const CategoryOptions = [];

class BankList extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    if (!CategoryOptions.length > 0) {
      services
        .getBankList()
        .then((response) => {
          const tmp = response.data.data;

          for (let i = 0; i < tmp.length; i++) {
            CategoryOptions.push({
              value: tmp[i].uuid,
              label: tmp[i].bank_name,
              logo: tmp[i].logo,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  handleChange = (selectedOption) => {
    this.props.handleBankChange(selectedOption, this.props.did);
  };

  render() {
    return (
      <Select
        onChange={this.handleChange}
        options={CategoryOptions}
        className="select-box-container"
        classNamePrefix="raw-select"
        name="bank"
        placeholder="Select bank"
        value={CategoryOptions.filter((obj) => obj.value === this.props.value)}
        isDisabled={this.props.disabled || false}
      />
    );
  }
}

export default BankList;
