import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import services from './data';

let CategoryOptions;

const CountryList = (props) => {
  const [options, setOptions] = useState([]);
  const [currentSelection, setCurrentSelection] = useState([]);

  useEffect(() => {
    services
      .getCountries()
      .then((response) => {
        const tmp = response.data.data;
        CategoryOptions = [];
        for (let i = 0; i < tmp.length; i++) {
          CategoryOptions.push({
            value: tmp[i].uuid,
            label: tmp[i].country,
            code: tmp[i].country_code,
          });
        }
        setOptions(CategoryOptions);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const selected = options.filter(({ code }) => code === props.code);
    props.handleCountryChange(selected[0]);
  }, [props.code]);

  const handleChange = (selectedOption) => {
    let option = selectedOption;
    if (selectedOption === null) {
      option = { value: '', label: '', code: '' };
    }
    setCurrentSelection(option);
    props.handleCountryChange(option);
  };

  return (
    <Select
      onChange={handleChange}
      isClearable={true}
      options={options}
      className="select-box-container"
      classNamePrefix="raw-select"
      name="country"
      placeholder="Select Country"
      value={options.filter(({ value }) => value === props.value)}
      isDisabled={props.disabled || false}
    />
  );
};

export { CountryList };
