import React from 'react';

import { useGlobalStore } from '@stores/globalStore';

import SalesSummary from './SalesSummary';

const FuelSummary = (props) => {
  const { syncCODCollectionData } = props;

  const updateTotalSummary = () => {
    syncCODCollectionData(props.match.params.duty, props.match.params.staff);
  };

  return <SalesSummary {...props} onDataChange={updateTotalSummary} />;
};

export { FuelSummary };
