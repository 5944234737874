import React, { Component } from 'react';
import Select from 'react-select';

import services from './data';

class WalletList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
    };
  }
  componentDidMount() {
    this.getWallets();
  }

  getWallets = () => {
    services
      .getMappedWallets()
      .then((response) => {
        const tmp = response.data.data;
        const wallets = [...this.state.options];
        for (let i = 0; i < tmp.length; i++) {
          wallets.push({
            value: tmp[i].bunk_wallet_uuid,
            label: `${tmp[i].wallet.bank_name}(${tmp[i].settlement_bank.settlement_bank_name})`,
          });
        }
        this.setState({ options: wallets });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleChange = (selectedOption) => {
    let option = selectedOption;
    if (selectedOption == null) {
      option = { value: '', label: '' };
    }
    this.props.handleBankChange(option);
  };

  render() {
    const options = this.state.options;

    return (
      <Select
        onChange={this.handleChange}
        isClearable={true}
        options={options}
        className="select-box-container"
        classNamePrefix="raw-select"
        name="payment_mode"
        placeholder="Select Wallet"
        value={options.filter((obj) => obj.value === this.props.value)}
        isDisabled={this.props.disabled || false}
      />
    );
  }
}

export { WalletList };
