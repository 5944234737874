import React, { PureComponent } from 'react';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import { AccessControl, BankList, SettlementOptions } from '@C/Utils';
import { authHeader } from '@H';
import { authService } from '@S';

import services from './dataWallets';

import './wallet-setup.scoped.scss';

class WalletSetup extends PureComponent {
  constructor() {
    super();
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
    this.myRef = React.createRef();
    this.state = {
      wallets: [],
      swipe_or_wallet_uuid: '',
      settlement_bank: '',
      settlement_bank_name: 'Select Bank',
      updated: false,
      settlement_options: 'TODAY',
      isSubmitting: false,
    };
  }

  componentDidMount() {
    services
      .getAllWallets()
      .then((response) => {
        this.setState({ wallets: response.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleBankChange = (e) => {
    this.setState({ settlement_bank: e.value, settlement_bank_name: e.label });
  };

  handleSettlementChange = (e) => {
    this.setState({ settlement_options: e.value });
  };

  handleSubmit = (event, item) => {
    event.preventDefault();
    if (!this.validator.allValid() || !this.state.settlement_bank) {
      this.validator.showMessages();
      this.forceUpdate();
      this.scrollToTop();
      return;
    }
    this.setState({ isSubmitting: true });
    const walletData = [];
    walletData.push({
      swipe_or_wallet_uuid: item.uuid,
      settlement_options: this.state.settlement_options,
      settlement_bank: this.state.settlement_bank,
    });
    const formData = {
      wallet_list: walletData,
    };
    const headers = authHeader(authService.currentAccountID);
    axios
      .post(`${process.env.API_URL}/bunk/map-wallets/`, formData, { headers })
      .then((response) => {
        toast.success('Wallet added successfully');
        this.scrollToTop();
        this.setState({
          settlement_bank: '',
          swipe_or_wallet_uuid: '',
          updated: !this.state.updated,
          settlement_options: 'TODAY',
          isSubmitting: false,
        });
        this.validator.hideMessages();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        this.setState({ isSubmitting: false });
      });
  };

  scrollToTop = () => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  };

  render() {
    const { wallets } = this.state;
    return (
      <>
        <AccessControl allowedRoles={['Dealer']}>
          <section className="widget form-card" ref={this.myRef}>
            <header>
              <h3>Add wallet</h3>
            </header>
            <main>
              <form className=" inline">
                <div className="input-wrapper with-label">
                  <label>Bank</label>
                  {this.validator.message(
                    'settlement_bank',
                    this.state.settlement_bank,
                    'required',
                  )}

                  <BankList
                    id="settlement_bank"
                    handleBankChange={this.handleBankChange}
                    value={this.state.settlement_bank}
                  />
                </div>
                <div className="input-wrapper with-label">
                  <label>Settlement option</label>
                  {this.validator.message(
                    'settlement_options',
                    this.state.settlement_options,
                    'required',
                  )}
                  <SettlementOptions
                    onChange={this.handleSettlementChange}
                    value={this.state.settlement_options}
                  />
                </div>
              </form>
              <ul>
                {wallets.map((item, i) => {
                  return (
                    <li key={i}>
                      <div className="item-logo">
                        <img src={item.logo} alt="" />
                      </div>

                      <div className="sub-cta-wrapper">
                        <button
                          className="submit"
                          disabled={this.state.isSubmitting}
                          onClick={() => this.handleSubmit(event, item)}
                        >
                          Add
                        </button>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </main>
          </section>
        </AccessControl>
        <ExsitingWalletList key={this.state.updated} />
      </>
    );
  }
}

class ExsitingWalletList extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      WalletList: [],
    };
  }
  componentDidMount() {
    this.getWalletList();
  }

  getWalletList = () => {
    services
      .getMappedWallets()
      .then((response) => {
        this.setState({ WalletList: response.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  confirmDelete = (id, walletName) => {
    confirmAlert({
      title: 'Confirm wallet deletion',
      message: `Are you sure you want to delete "${walletName}"?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteItem(id),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  deleteItem = (id) => {
    const headers = authHeader(1);
    axios
      .delete(`${process.env.API_URL}/bunk/map-wallets/?bunk_bank_uuid=${id}`, {
        headers,
      })
      .then(() => {
        toast.success('Wallet deleted successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        this.getWalletList();
      });
  };

  render() {
    const { WalletList } = this.state;
    return (
      <section className="widget list-card list-card__minimal">
        <header>
          <h3>Existing wallets</h3>
        </header>
        <main>
          <ul>
            {WalletList.map((item, i) => {
              return (
                <li key={i}>
                  <div className="card-title">
                    <label>Name:</label>
                    <h4>{item.wallet.bank_name}</h4>
                  </div>
                  <div className="card-info">
                    <span>
                      <label>Settlement bank:</label>
                      {item.settlement_bank.settlement_bank_name} -{' '}
                      {item.wallet_account_type}
                    </span>
                  </div>
                  <div className="card-info">
                    <span>
                      <label>Settlement day:</label>
                      {item.settlement_type}
                    </span>
                  </div>
                  <div className="card-actions">
                    <AccessControl allowedRoles={['Dealer']}>
                      <button
                        className="actions __delete "
                        onClick={() =>
                          this.confirmDelete(
                            item.bunk_wallet_uuid,
                            item.wallet.bank_name,
                          )
                        }
                      >
                        Delete
                      </button>
                    </AccessControl>
                  </div>
                </li>
              );
            })}
          </ul>
        </main>
      </section>
    );
  }
}

export { WalletSetup };
