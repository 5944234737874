import React, { Component } from 'react';

export class OtherIESummary extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { incomeSummary, expenseSummary } = this.props;

    return (
      <>
        <section className="widget dsr-table">
          <header className="title-wrapper"></header>
          <main>
            <table className="dsr" data-name="Other Income Summary">
              <thead>
                <tr>
                  <th colSpan={3} className="alert-brand">
                    <label className="printCheckbox">
                      <input
                        type="checkbox"
                        name="enablePrint"
                        defaultChecked="true"
                      />
                    </label>
                    Other Income summary
                  </th>
                </tr>
                <tr data-header={true}>
                  <th>Product</th>
                  <th>Staff</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {incomeSummary.map((item, i) => (
                  <tr key={i}>
                    <td>{item.earning__addition_earning}</td>
                    <td>{`${item.staff__user__first_name} ${item.staff__user__last_name}`}</td>
                    <td>
                      {new Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        currency: 'INR',
                      }).format(item.after_tax.toFixed(2) || 0)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </main>
        </section>
        <section className="widget dsr-table">
          <header className="title-wrapper"></header>
          <main>
            <table className="dsr" data-name="Other Expense Summary">
              <thead>
                <tr>
                  <th colSpan={3} className="alert-brand">
                    <label className="printCheckbox">
                      <input
                        type="checkbox"
                        name="enablePrint"
                        defaultChecked="true"
                      />
                    </label>
                    Other Expense summary
                  </th>
                </tr>
                <tr data-header={true}>
                  <th>Product</th>
                  <th>Staff</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {expenseSummary.map((item, i) => (
                  <tr key={i}>
                    <td>{item.expenses__expense}</td>
                    <td>{`${item.staff__user__first_name} ${item.staff__user__last_name}`}</td>
                    <td>
                      {new Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        currency: 'INR',
                      }).format(item.after_tax.toFixed(2) || 0)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </main>
        </section>
      </>
    );
  }
}
