import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';
import { read, utils } from 'xlsx';

import services from '@C/Admin/DipCalibration/_utils/data';
import { CategoryOptions, OMCList } from '@C/Utils';
import { authHeader } from '@H';

import DipCalibrationList from './dipList';

import './styles.scoped.scss';

class DipCalibration extends Component {
  constructor() {
    super();
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });

    this.myRef = React.createRef();
  }
  state = {
    omc_id: '',
    name: '',
    capacity: '',
    diameter: '',
    notes: '',
    internal_length: '',
    dip_volumes_list: [
      {
        dip_in_cm: '',
        volume: '',
        difference: '',
      },
    ],
    updated: false,
    editMode: false,
    isSubmitting: false,
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleOMCChange = (e) => {
    this.setState({ omc_id: e });
  };
  handleData = (e, i) => {
    const list = this.state.dip_volumes_list;
    if (e.target.name == 'dip_in-cm') {
      list[i][e.target.name] = e.target.value;
    } else {
      list[i][e.target.name] = e.target.value;
    }
    this.setState({ dip_volumes_list: list });
  };

  addRow = () => {
    const n = this.state.diameter;
    let i = 1;
    while (i < n) {
      this.setState((prevState) => ({
        dip_volumes_list: [
          ...prevState.dip_volumes_list,
          {
            dip_in_cm: '',
            volume: '',
            difference: '',
          },
        ],
      }));
      i++;
    }
  };

  deleteRow = (i) => {
    this.setState({
      dip_volumes_list: this.state.dip_volumes_list.filter(
        (s, sindex) => i !== sindex,
      ),
    });
  };

  editMode = (item) => {
    this.setState({ editMode: false });
    window.scrollTo({ behavior: 'smooth', top: this.myRef.current.offsetTop });
    services.getDipCalibrationItem(item.uuid);
    const tmp = item;
    this.setState({
      omc_id: tmp.omc.uuid,
      name: tmp.name,
      capacity: tmp.capacity,
      diameter: tmp.diameter,
      notes: tmp.notes,
      internal_length: tmp.internal_length,
      dip_volumes_list: item.dip_volumes_list,
      editMode: true,
      uuid: item.uuid,
    });
  };
  cancelEdit = () => {
    this.setState({
      editMode: false,
      updateList: false,
      isSubmitting: false,
      omc_id: '',
      name: '',
      capacity: '',
      diameter: '',
      notes: '',
      internal_length: '',
      dip_volumes_list: [
        {
          dip_in_cm: '',
          volume: '',
          difference: '',
        },
      ],
    });
  };
  submitForm = () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }

    this.setState({ isSubmitting: true });

    const formData = this.state;
    const headers = authHeader();
    let method = 'POST';

    if (this.state.editMode) {
      method = 'PUT';
    }

    axios({
      method: method,
      url: `${process.env.API_URL}/dip-calibration/`,
      data: formData,
      headers: headers,
    })
      .then((response) => {
        toast.success('Dip calibration charts added successfully');
        this.setState({
          name: '',
          capacity: '',
          diameter: '',
          notes: '',
          omc_id: '',
          internal_length: '',
          dip_volumes_list: [
            {
              dip_in_cm: '',
              volume: '',
              difference: '',
            },
          ],
          updated: !this.state.updated,
          isSubmitting: false,
          editMode: false,
        });
        // clear uploaded file
        document.getElementById('file-id').value = '';
        this.validator.hideMessages();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        this.setState({ isSubmitting: false });
      });
  };

  handleFileUpload = (e) => {
    const files = e.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const omcRows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          const dipRows = utils.sheet_to_json(wb.Sheets[sheets[1]]);
          const omcData = omcRows.map((item) => {
            return {
              name: item['Chart Name'],
              capacity: item.Capacity,
              omc_id: CategoryOptions.filter(
                (option) => option.label === item.OMC,
              )[0].value,
              diameter: item.Diameter,
              notes: item.Notes,
              internal_length: item.Length,
            };
          });
          const dipData = dipRows.map((item) => {
            return {
              dip_in_cm: item.Dip,
              volume: item.Volume.toFixed(2),
              difference: item.Difference.toFixed(2),
            };
          });
          this.setState({ dip_volumes_list: dipData, ...omcData[0] });
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  render() {
    const dip_volumes_list = this.state.dip_volumes_list;
    const editMode = this.state.editMode;
    return (
      <>
        <section className="widget full-width grid-form" ref={this.myRef}>
          <header>
            <h3> Add New Chart </h3>
          </header>
          <main className="sub-wrapper">
            <form className="inline">
              <div className="input-wrapper with-label">
                {this.validator.message('name', this.state.name, 'required')}
                <label> Chart name </label>
                <input
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.name}
                  name="name"
                />
              </div>
              <div className="input-wrapper with-label">
                {this.validator.message(
                  'omc_id',
                  this.state.omc_id,
                  'required',
                )}
                <label>OMC</label>
                <OMCList
                  handleOMCChange={this.handleOMCChange}
                  value={this.state.omc_id}
                />
              </div>
              <div className="input-wrapper with-label">
                {this.validator.message(
                  'capacity',
                  this.state.capacity,
                  'required',
                )}
                <label> Capacity </label>
                <input
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.capacity}
                  name="capacity"
                />
              </div>
              <div className="input-wrapper with-label">
                {this.validator.message(
                  'diameter',
                  this.state.diameter,
                  'required',
                )}
                <label> Diameter(CM's) </label>
                <input
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.diameter}
                  name="diameter"
                />
              </div>
              <div className="input-wrapper with-label">
                {this.validator.message(
                  'internal_length',
                  this.state.internal_length,
                  'required',
                )}
                <label> Int. length (CM) </label>
                <input
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.internal_length}
                  name="internal_length"
                />
              </div>
              <div className="input-wrapper with-label ">
                <label> Notes </label>
                <textarea
                  value={this.state.notes}
                  name="notes"
                  onChange={this.handleChange}
                />
              </div>
            </form>
          </main>
        </section>
        <section className="widget full-width form-table">
          <header>
            <h3>Dip calibration chart details</h3>
            <div className="actions">
              <div className={`input-wrapper file-upload `}>
                <div
                  className="input-group"
                  style={{
                    display: 'flex',
                  }}
                >
                  <input
                    type="file"
                    id="file-id"
                    name="pmFile"
                    onChange={this.handleFileUpload}
                    accept=".xlsx, .xls"
                    placeholder="Import Excel"
                  />
                </div>
              </div>
              {this.state.dip_volumes_list.length > 1 && (
                <button
                  className="btn-with-icon btn-with-icon__cancel red-btn"
                  onClick={() =>
                    this.setState({
                      name: '',
                      capacity: '',
                      omc_id: null,
                      diameter: '',
                      notes: '',
                      internal_length: '',
                      dip_volumes_list: [
                        {
                          dip_in_cm: '',
                          volume: '',
                          difference: '',
                        },
                      ],
                    })
                  }
                >
                  Clear all
                </button>
              )}

              <button className="add-row" onClick={() => this.addRow()}>
                Add row
              </button>
            </div>
          </header>
          <main>
            <div className="form-content">
              <table>
                <thead>
                  <tr>
                    <th>Dip </th>
                    <th>Volume </th>
                    <th>Difference</th>
                    <th> Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {dip_volumes_list.map((item, i) => (
                    <tr key={i}>
                      <td className="w-105">
                        <div className="input-wrapper">
                          <input
                            name="dip_in_cm"
                            type="text"
                            value={item.dip_in_cm}
                            onChange={() => this.handleData(event, i)}
                          />
                        </div>
                      </td>
                      <td className="w-105">
                        <div className="input-wrapper">
                          <input
                            name="volume"
                            type="text"
                            value={item.volume}
                            onChange={() => this.handleData(event, i)}
                          />
                        </div>
                      </td>
                      <td className="w-105">
                        <div className="input-wrapper">
                          <input
                            type="text"
                            value={item.difference}
                            name="difference"
                            onChange={() => this.handleData(event, i)}
                          />
                        </div>
                      </td>
                      <td className="w-30">
                        {i > 0 ? (
                          <button
                            className="delete-row icon-only"
                            onClick={() => this.deleteRow(i)}
                          >
                            Delete row
                          </button>
                        ) : (
                          ''
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </main>
          <div className="form-actions">
            <button
              className="submit"
              type="submit"
              disabled={this.state.isSubmitting}
              onClick={this.submitForm}
            >
              Submit
            </button>
            {editMode && (
              <button className="red-btn" onClick={this.cancelEdit}>
                Cancel
              </button>
            )}
          </div>
        </section>
        <DipCalibrationList key={this.state.updated} onEdit={this.editMode} />
      </>
    );
  }
}

export { DipCalibration };
