import React, { Component } from 'react';
import dayjs from 'dayjs';
import Select from 'react-select';

import services from '@C/Reports/_utils/data';
import { formatCurrency, TableGrid } from '@C/TableGrid';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';

const sortOptions = [
  { value: 'all', label: 'All' },
  { value: '&other_expense=1', label: 'Other expense ' },
  { value: '&lube_purchase=1', label: 'Lube purchase' },
];

const col = [
  {
    name: 'Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Item name',
    key: 'item_name',
  },
  {
    name: 'Type of expense',
    key: 'type_name',
  },
  {
    name: 'SGST',
    key: 'tax',
    formatter(props) {
      return <>{formatCurrency(props.SGST || 0)}</>;
    },
  },
  {
    name: 'CGST',
    key: 'tax',
    formatter(props) {
      return <>{formatCurrency(props.CGST || 0)}</>;
    },
  },
  {
    name: 'UTGST',
    key: 'tax',
    formatter(props) {
      return <>{formatCurrency(props.UTGST || 0)}</>;
    },
  },
  {
    name: 'IGST',
    key: 'tax',
    formatter(props) {
      return <>{formatCurrency(props.IGST || 0)}</>;
    },
  },
  {
    name: 'Amount',
    key: 'all',
    type: 'currency',
    formatter(props) {
      return <>{formatCurrency(props.amount)}</>; 
    },
  },
];

class GSTExpenseReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gstList: [],
      start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
      loading: false,
      queryParam: 'all',
      expense_type: '',
      limit: 20,
      offset: 1,
      count: 0,
    };
    this.limitInput = React.createRef();
  }

  componentDidMount() {
    this.getGSTExpense();
  }

  getGSTExpense = () => {
    services
      .getGSTExpense(this.state)
      .then((response) => {
        this.setState({
          gstList: response.data.data.results,
          count: response.data.data.count,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date' && dayjs(value).isAfter(this.state.end_date)) {
      this.setState({ [name]: value, end_date: value });
    } else if (
      name === 'end_date' &&
      dayjs(value).isBefore(this.state.start_date)
    ) {
      this.setState({ [name]: value, start_date: value });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleSortOrderChange = (e) => {
    this.setState({ queryParam: e.value }, function () {
      this.getGSTExpense();
    });
  };

  handlePagination = (e) => {
    this.setState({ offset: e }, function () {
      this.getGSTExpense();
    });
  };

  handleQuickSearch = (e) => {
    e.preventDefault();
    if (
      this.limitInput.current.value === '' ||
      this.limitInput.current.value === '0'
    ) {
      return;
    }
    this.setState({ limit: this.limitInput.current.value, offset: 1 }, () => {
      this.getGSTExpense();
    });
  };

  resetFilter = () => {
    this.setState(
      {
        date: dayjs().format('YYYY-MM-DD'),
        loading: true,
      },
      function () {
        this.getGSTExpense();
      },
    );
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, function () {
      this.getGSTExpense();
    });
  };

  render() {
    const { gstList } = this.state;

    return (
      <section className="widget full-width list-table inverted">
        <header>
          <h3>GST Expense Report</h3>
        </header>
        <main>
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group">
                <label>From date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.start_date}
                  name="start_date"
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="filter-group">
                <label>To date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.end_date}
                  min={this.state.start_date}
                  onChange={this.handleDateChange}
                  name="end_date"
                />
              </div>

              <div className="filter-group">
                <label>Type of expense:</label>
                <Select
                  name="sort_order"
                  options={sortOptions}
                  onChange={this.handleSortOrderChange}
                  value={sortOptions.filter(
                    ({ value }) => value === this.state.queryParam,
                  )}
                  defaultValue={{ value: 'all', label: 'All' }}
                />
              </div>

              <div className="filter-group with-icon">
                <label>
                  Results to display- {this.state.limit || 20} (Total:{' '}
                  {this.state.count})
                </label>
                <form onSubmit={this.handleQuickSearch}>
                  <input type="text" name="limit" ref={this.limitInput} />
                  <button
                    type="submit"
                    className="search-icon"
                    onClick={this.handleQuickSearch}
                  />
                </form>
              </div>
              <div className="filter-group reset-filter">
                <button onClick={this.resetFilter}>Reset filters</button>
              </div>
              <div className="filter-group">
                  <button
                    className="submit"
                    type="submit"
                    onClick={this.submitForm}
                  >
                    Apply filter
                  </button>
                </div>
            </div>
          </div>
          <div className="table-wrapper">
            {(() => {
              if (this.state.loading) {
                return <DropLoaderLarge />;
              } else {
                return (
                  <TableGrid
                    col={col}
                    row={gstList}
                    offset={this.state.offset}
                    count={this.state.count}
                    limit={this.state.limit}
                    handlePagination={this.handlePagination}
                    hideActions={true}
                    exportOptions={{
                      fileName: `GST Expense - ${this.state.start_date} to ${this.state.end_date}.pdf`,
                      reportName: 'GST Expense Report',
                      reportDate: `Date - ${dayjs(this.state.start_date).format(
                        'D MMM YY',
                      )} to ${dayjs(this.state.end_date).format('D MMM YY')}`,
                      reportQuantity: `Total SGST for the period -  ${
                        gstList
                          .reduce((a, b) => a + (b['tax']['SGST'] || 0), 0)
                          .toFixed(2) || 0
                      }`,
                      reportSales: `Total CGST for the period -  ${
                        gstList
                          .reduce((a, b) => a + (b['tax']['CGST'] || 0), 0)
                          .toFixed(2) || 0
                      }`,
                      reportTesting: `Total IGST for the period -  ${
                        gstList
                          .reduce((a, b) => a + (b['tax']['IGST'] || 0), 0)
                          .toFixed(2) || 0
                      }`,
                      reportBowser: `Total UTGST for the period -  ${
                        gstList
                          .reduce((a, b) => a + (b['tax']['UTGST'] || 0), 0)
                          .toFixed(2) || 0
                      }`,
                      reportTotal: '',
                      reportCustomerName: '',
                      reportGst: '',
                      reportOpeningBalance: '',
                      reportClosingBalance: '',
                      reportSaleTotal: '',
                      reportTCSTotal: '',
                    }}
                  />
                );
              }
            })()}
            <div className="mini-summary">
              <ul>
                <li>
                  <span>Total SGST for the period: </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {}).format(
                      gstList
                        .reduce((a, b) => a + (b['tax']['SGST'] || 0), 0)
                        .toFixed(2) || 0,
                    )}
                  </span>
                </li>
                <li>
                  <span>Total CGST for the period: </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {}).format(
                      gstList
                        .reduce((a, b) => a + (b['tax']['CGST'] || 0), 0)
                        .toFixed(2) || 0,
                    )}
                  </span>
                </li>
                <li>
                  <span>Total IGST for the period: </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {}).format(
                      gstList
                        .reduce((a, b) => a + (b['tax']['IGST'] || 0), 0)
                        .toFixed(2) || 0,
                    )}
                  </span>
                </li>
                <li>
                  <span>Total UTGST for the period: </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {}).format(
                      gstList
                        .reduce((a, b) => a + (b['tax']['UTGST'] || 0), 0)
                        .toFixed(2) || 0,
                    )}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </main>
      </section>
    );
  }
}

export { GSTExpenseReport };
