import React, { Component } from 'react';

import CashierShortage from '@C/Reports/RouteComponents/SalesSummaryReport/CashierShortage';
import CashSummary from '@C/Reports/RouteComponents/SalesSummaryReport/CashSummary';
import CollectionSummary from '@C/Reports/RouteComponents/SalesSummaryReport/CollectionSummary';
import CreditSalesSummary from '@C/Reports/RouteComponents/SalesSummaryReport/CreditSalesSummary';
import FuelSummary from '@C/Reports/RouteComponents/SalesSummaryReport/FuelSummary';
import LubesSummary from '@C/Reports/RouteComponents/SalesSummaryReport/LubesSummary';
import OtherIncomeExpenses from '@C/Reports/RouteComponents/SalesSummaryReport/OtherIncomeExpenses';

class SalesSummaryReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fuel_summary: [],
      lubes_summary: [],
      other_income_expenses: [],
      collection_summary: [],
      credit_sales_summary: [],
      cash_summary: [],
      cashier_shortage: [],
    };
  }

  render() {
    return (
      <section className="widget list-table full-width ">
        <header>
          <h3> Sales Summary Report / Business Summary Report</h3>
        </header>
        <main>
          <FuelSummary summary={this.state.fuel_summary} />
          <LubesSummary summary={this.state.lubes_summary} />
          <OtherIncomeExpenses summary={this.state.other_income_expenses} />
          <CollectionSummary summary={this.state.collection_summary} />
          <CreditSalesSummary summary={this.state.credit_sales_summary} />
          <CashSummary summary={this.state.cash_summary} />
          <CashierShortage summary={this.state.cashier_shortage} />
        </main>
      </section>
    );
  }
}

export { SalesSummaryReport };
