import React, { Component } from 'react';
import axios from 'axios';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';

import DropLoader from '@C/Utils/DropLoader';
import { authHeader } from '@H';

import services from './data';

let CategoryOptions;

class ExpenseList extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      options: [],
      isLoading: false,
    };
  }
  componentDidMount() {
    this.getExpenseList();
  }

  getExpenseList = () => {
    const { isShowDeleted } = this.props;
    services
      .getExpenseList(isShowDeleted)
      .then((response) => {
        const tmp = response.data.data;
        CategoryOptions = [];
        for (let i = 0; i < tmp.length; i++) {
          CategoryOptions.push({
            value: tmp[i].uuid,
            label: tmp[i].expense,
          });
        }
        this.setState({ options: CategoryOptions });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleChange = (selectedOption) => {
    const id = this.props.did ? this.props.did : 0;
    let option = selectedOption;
    if (selectedOption == null) {
      option = { value: '', label: '' };
    }
    this.props.onChange(option, id);
  };

  createOption = (label) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
  });

  handleCreate = (inputValue) => {
    this.setState({ isLoading: true });
    const formData = {
      expenses_list: [
        {
          expenses: inputValue,
        },
      ],
    };
    const headers = authHeader(1);
    axios
      .post(`${process.env.API_URL}/bunk/expenses-setup/`, formData, {
        headers,
      })
      .then((response) => {
        toast.success('Expense source added successfully');
        const tmp = {
          value: response.data.data.id,
          label: inputValue.toUpperCase(),
        };
        const id = this.props.did ? this.props.did : 0;
        this.props.onChange(tmp, id);
        this.getExpenseList();
        this.setState({
          isLoading: false,
        });
      })
      .catch(() => {
        toast.error('Failed to add source. Please try again');
        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    const { options } = this.state;
    return (
      <CreatableSelect
        onChange={(e) => this.handleChange(e)}
        options={options}
        className="expense-selector-container"
        classNamePrefix="expense-selector"
        name="expense_source"
        placeholder="Select expense"
        isClearable
        isDisabled={this.state.isLoading || this.props.disabled || false}
        isLoading={this.state.isLoading}
        onCreateOption={this.handleCreate}
        components={{ LoadingIndicator: DropLoader }}
        value={options.filter(({ value }) => value === this.props.value)}
      />
    );
  }
}

export default ExpenseList;
