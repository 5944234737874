import React, { Component, useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import DatePicker from 'react-datepicker';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import services from '@C/Dsr/_utils/data';
import { InterFont } from '@C/TableGrid';
import TableToExcelExport from '@C/TableToExcelExport';
import { BankList, ShiftsList, StaffList } from '@C/Utils';
import { getBase64FromUrl } from '@C/Utils/ExportUtils';
import { authHeader } from '@H';
import { authService } from '@S';

import './styles.scoped.scss';

const export2PDF = (date) => {
  const doc = new jsPDF();
  doc.addFileToVFS('Inter-Regular.ttf', InterFont);
  doc.addFont('Inter-Regular.ttf', 'Inter', 'normal');
  doc.setFont('Inter');

  const exportOptions = {
    fileName: `${authService.currentBunkName} - DSR Report - ${dayjs(date).format('D MMM YY')}.pdf`,
    reportName: 'DSR',
    reportDate: `Date - ${dayjs(date).format('D MMM YY')}`,
  };

  const omcLogo = getBase64FromUrl(authService.currentOMCWebLogo);
  const bunkAddress = authService.currentBunkAddress;

  omcLogo.then((result) => {
    const img = new Image();
    img.onload = function () {
      doc.addFileToVFS('Inter-Regular.ttf', InterFont);
      doc.addFont('Inter-Regular.ttf', 'Inter', 'normal');
      doc.setFont('Inter');
      const aspectRatio = img.width / img.height;
      const width = 25;
      const height = width / aspectRatio;
      try {
        doc.addImage(result, 'PNG', 13, 10, width, height);
        autoTable(doc, {
          body: [
            [
              {
                content: authService.currentBunkName,
                styles: {
                  halign: 'right',
                  fontSize: 10,
                  textColor: '#000000',
                  fontStyle: 'bold',
                },
              },
            ],
            [
              {
                content:
                  bunkAddress.city +
                  ', ' +
                  bunkAddress.location +
                  ',' +
                  bunkAddress.country.country +
                  ' - ' +
                  bunkAddress.pincode,
                styles: {
                  halign: 'right',
                  fontSize: 10,
                  textColor: '#000000',
                },
              },
            ],
            [
              {
                content: 'DSR',
                styles: {
                  halign: 'right',
                  fontSize: 10,
                  textColor: '#000000',
                  fontStyle: 'bold',
                },
              },
            ],
            [
              {
                content: `${dayjs(date).format('D MMM YY')}`,
                styles: {
                  halign: 'right',
                  fontSize: 10,
                  textColor: '#000000',
                  fontStyle: 'bold',
                },
              },
            ],
          ],
          theme: 'plain',
        });
        const tables = document.querySelectorAll(
          'table:has(input[type="checkbox"]:checked)',
        );
        tables.forEach((table, i) => {
          autoTable(doc, {
            html: table,
            startY: doc.lastAutoTable.finalY + 5,
            pageBreak: 'auto',

            didParseCell: function (data) {
              const hClass = data.row.raw[0]._element.classList;
              if (hClass.length > 0 && hClass.contains('alert-brand')) {
                data.cell.styles.fillColor = [0, 0, 0];
                data.cell.styles.textColor = [255, 255, 255];
                data.cell.styles.halign = 'center';
              }

              // check if text contains currency symbol and remove the character
            },
            theme: 'grid',
            styles: {
              font: 'Inter',
              overflow: 'linebreak',
              fontSize: 8,
              cellWidth: 'auto',
            },
            margin: { top: 5, right: 5, bottom: 5, left: 5 },
          });
        });
        doc.save(exportOptions.fileName);
      } catch (e) {
        console.log(e);
      }
    };
    img.src = result;
  });
};

const export2Excel = (date) => {
  let book;
  const tables = document.querySelectorAll('table');
  tables.forEach((table, i) => {
    // get table data attribute
    const sheetName = table.getAttribute('data-name');

    if (i === 0) {
      book = TableToExcelExport.tableToBook(table, {
        sheet: { name: sheetName },
      });
    } else {
      TableToExcelExport.tableToSheet(book, table, {
        sheet: { name: sheetName },
      });
    }
  });
  TableToExcelExport.save(
    book,
    `${authService.currentBunkName} - DSR - ${dayjs(date).format(
      'DD-MM-YYYY',
    )}.xlsx`,
  );
};

// convert DSRActions to functional component
const DsrActions = (props) => {
  const [date, setDate] = useState(
    dayjs(useParams().date).format('YYYY-MM-DD'),
  );
  const [staff, setStaff] = useState(props.staff);
  const [shift, setShift] = useState(props.shift);
  const [showModal, setShowModal] = useState(false);
  const [showDepositModal, setshowDepositModal] = useState(false);
  const [lockStatus] = useState(props.lockStatus);
  const [highlightedDates, setHighlightedDates] = useState(
    props.highlightedDates,
  );

  const changeDate = (newDate) => {
    const newDateString = dayjs(newDate).format('YYYY-MM-DD');
    if (newDateString !== date) {
      setDate(newDateString);
      props.onChange(newDateString, staff, shift);
      getDSRDates();
    }
  };

  useEffect(() => {
    setHighlightedDates(props.highlightedDates);
  }, [props.highlightedDates]);

  const getDSRDates = (date) => {
    services
      .getDSRDates(dayjs(date).month() + 1, dayjs(date).year())
      .then((response) => {
        const tmp = response.data.data;
        const eventsArr = [
          {
            dsr_locked: [],
          },
          {
            dsr_unlocked: [],
          },
        ];
        tmp.forEach((item) => {
          if (item.lock_status) {
            eventsArr[0]['dsr_locked'].push(new Date(item.time_stamp));
          } else {
            eventsArr[1]['dsr_unlocked'].push(new Date(item.time_stamp));
          }
        });
        setHighlightedDates(eventsArr);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const openDepositModal = () => {
    setshowDepositModal(true);
  };
  const closeDepositModal = () => {
    window.location.reload();
    setshowDepositModal(false);
  };

  const handleShiftChange = (value) => {
    setShift(value.value);

    props.onChange(date, staff, value.value);
  };

  const handleStaffChange = (e) => {
    setStaff(e.value);
    props.onChange(date, e.value, shift);
  };

  const handleReset = () => {
    setStaff('');
    setShift('');
    props.onChange(date, '', '');
    window.location.reload();
  };

  const handleLock = () => {
    const formData = {
      dsr_date: date,
    };
    const headers = authHeader(1);
    axios
      .post(`${process.env.API_URL}/bunk/dsr/lock/`, formData, { headers })
      .then(() => {
        toast.success('DSR locked successfully');
        window.location.reload();
      })

      .catch((err) => {
        toast.error(err.data.message);
      });
  };

  return (
    <>
      <section className="widget full-width actions-widget">
        <main>
          <div className="input-wrapper no-margin grgrgrg">
            <DatePicker
              selected={new Date(date)}
              dateFormat="d MMM yyyy"
              onChange={changeDate}
              maxDate={new Date()}
              highlightDates={highlightedDates}
              onMonthChange={getDSRDates}
              popperPlacement="bottom-start"
              popperModifiers={[]}
            />
          </div>
          <div className="input-wrapper no-margin">
            <ShiftsList value={shift} onChange={handleShiftChange} />
          </div>
          <div className="input-wrapper no-margin">
            <StaffList
              value={staff}
              onChange={handleStaffChange}
              staffType={['Cashiers', 'Managers', 'Dealer']}
            />
          </div>
          <button className="white-btn" onClick={handleReset}>
            Clear filters
          </button>
          {lockStatus ? (
            <button className="green-btn" onClick={openModal}>
              Unlock DSR
            </button>
          ) : (
            <button className="red-btn" onClick={handleLock}>
              Lock DSR
            </button>
          )}

          <button className="blue-btn" onClick={openDepositModal}>
            Deposit to bank
          </button>
          <button
            className="blue-btn btn-with-icon btn-with-icon__excel "
            onClick={() => export2Excel(date)}
          >
            Export to Excel
          </button>
          <button
            className="blue-btn btn-with-icon btn-with-icon__pdf "
            onClick={() => export2PDF(date)}
          >
            Export to PDF
          </button>
        </main>
      </section>
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={true}
        className="sub-xl-modal-container"
        overlayClassName="xl-modal-overlay"
        parentSelector={() => document.querySelector('.dsr-container')}
      >
        <div className="modal-component">
          <UnlockDsr date={date} onRequestClose={closeModal} />
        </div>
      </Modal>
      <Modal
        isOpen={showDepositModal}
        onRequestClose={closeDepositModal}
        contentLabel="Cash Deposit Modal"
        shouldCloseOnOverlayClick={true}
        className="sub-xl-modal-container"
        overlayClassName="xl-modal-overlay"
        parentSelector={() => document.querySelector('.dsr-container')}
      >
        <div className="modal-component">
          <CashDeposit date={date} onRequestClose={closeDepositModal} />
        </div>
      </Modal>
      <div
        className="notification-alert notification-alert__success"
        style={{ marginBottom: '0px' }}
      >
        If you wish to exclude any table from pdf file while exporting, please
        untick the checkbox of that table. By default all tables are included in
        the pdf file while exporting.
      </div>
    </>
  );
};

class UnlockDsr extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
    this.state = {
      otp: '',
      date: this.props.date,
    };
  }

  sendSMS = () => {
    const formData = {
      dsr_date: this.props.date,
      sms_type: 'DSR_UNLOCK',
    };
    const headers = authHeader(1);

    axios
      .post(`${process.env.API_URL}/bunk/send-sms/`, formData, { headers })
      .then(() => {
        toast.success(`OTP sent successfully`);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  componentDidMount() {
    const formData = {
      from_app: 'DSR',
    };
    const headers = authHeader(1);
    axios
      .post(`${process.env.API_URL}/two-step-auth/`, formData, { headers })
      .then(() => {
        toast.success('Generated OTP successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.sendSMS();
      })
      .catch((err) => {
        toast.error(err.data.message, {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  handleChange = (e) => {
    this.setState({ otp: e.target.value });
  };

  submitForm = (event) => {
    event.preventDefault();
    const formData = {
      otp: +this.state.otp,
      dsr_date: this.state.date,
    };
    const headers = authHeader(1);
    axios
      .put(`${process.env.API_URL}/bunk/dsr/lock/`, formData, { headers })
      .then((response) => {
        toast.success('DSR Unlocked successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        window.location.reload();
      })

      .catch((err) => {
        toast.error(err.data.message, {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  render() {
    return (
      <section className="widget form-card">
        <header>
          <h3>Unlock DSR</h3>
        </header>
        <main className="form-content">
          <form className="inline">
            <div className="input-wrapper with-label">
              {this.validator.message('otp', this.state.otp, 'required')}
              <label> Enter the OTP</label>
              <input
                type="text"
                id="otp"
                name="otp"
                onChange={this.handleChange}
                value={this.state.otp}
              />
            </div>
            <div className="form-actions">
              <button className="submit" onClick={this.submitForm}>
                Unlock
              </button>
            </div>
          </form>
        </main>
      </section>
    );
  }
}

class CashDeposit extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
    this.state = {
      payment_list: [
        {
          bank_id: '',
          payment_method: 'NEFT',
          transaction_source: 'SELF',
          product_type: 'CashDeposit',
          product_uuid: null,
          amount: '',
          notes: '',
        },
      ],
      staff_id: this.props.staff_id,
      time_stamp: dayjs(this.props.date).format('YYYY-MM-DD'),
      // time_stamp: new Date().toISOString().split('T')[0],
      updated: false,
      isSubmitting: false,
    };
  }

  handleAmountChange = (e) => {
    const list = [...this.state.payment_list];
    list[0].amount = e.target.value;
    this.setState({ payment_list: list });
  };

  handleBankChange = (e) => {
    const list = [...this.state.payment_list];
    list[0].bank_id = e?.value || '';
    this.setState({ payment_list: list });
  };

  handleNotesChange = (e) => {
    const list = [...this.state.payment_list];
    list[0].notes = e.target.value;
    this.setState({ payment_list: list });
  };

  submitForm = (e) => {
    e.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState({ isSubmitting: true });
    const formData = this.state;
    const headers = authHeader(1);
    axios
      .post(`${process.env.API_URL}/payment/cash-deposit/`, formData, {
        headers,
      })
      .then(() => {
        toast.success('Transaction added successfully');

        this.setState({
          payment_list: [
            {
              bank_id: '',
              payment_method: 'NEFT',
              transaction_source: 'SELF',
              product_type: 'CashDeposit',
              product_uuid: null,
              amount: '',
              notes: '',
            },
          ],
          time_stamp: '',
          updated: !this.state.updated,
          isSubmitting: false,
        });
        this.props.onRequestClose();
        window.location.reload();
      })

      .catch((err) => {
        toast.error('Bank not selected');
        this.setState({ isSubmitting: false });
      });
  };

  render() {
    return (
      <section className="widget form-card">
        <header>
          <h3>Cash deposit to bank</h3>
        </header>
        <main className="form-content">
          <form className="inline">
            <div className="input-wrapper with-label">
              <label>Bank</label>
              <BankList
                id="settlement_bank"
                handleBankChange={this.handleBankChange}
                value={this.state.payment_list[0].bank_id}
              />
            </div>

            <div className="input-wrapper with-label">
              <label>Amount</label>
              {this.validator.message(
                'amount',
                this.state.payment_list[0].amount,
                'required|numeric',
              )}
              <input
                type="text"
                name="amount"
                value={this.state.payment_list[0].amount}
                onChange={this.handleAmountChange}
              />
            </div>
            <div className="input-wrapper with-label">
              <label>Notes</label>
              <input
                type="text"
                name="notes"
                value={this.state.payment_list[0].notes}
                onChange={this.handleNotesChange}
              />
            </div>
            <div className="form-actions">
              <button
                className="submit"
                disabled={this.state.isSubmitting}
                onClick={this.submitForm}
              >
                Submit
              </button>
            </div>
          </form>
        </main>
      </section>
    );
  }
}

export { DsrActions };
