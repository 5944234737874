import React, { Component } from 'react';
import dayjs from 'dayjs';

import services from '@C/DipReading/_utils/data';
import { TableGrid } from '@C/TableGrid';
import { FuelList, TankList } from '@C/Utils';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';

const col = [
  {
    name: 'Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Product',
    key: 'tank',
    formatter(props) {
      return <>{props.product}</>;
    },
  },
  {
    name: 'Tank',
    key: 'tank',
    formatter(props) {
      return <>{props.name}</>;
    },
  },
  {
    name: 'Opening stock',
    key: 'opening_stock',
  },
  {
    name: 'Closing stock',
    key: 'closing_stock',
  },
  {
    name: 'Purchase',
    key: 'purchase',
  },
  {
    name: 'Dip sales',
    key: 'dip_sales',
  },
  {
    name: 'Gun sales',
    key: 'total_sale',
  },
  {
    name: 'Testing liters',
    key: 'testing_litters',
  },
  {
    name: 'Variation',
    key: 'variation',
  },
];

class DipSummaryReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tankList: [],
      start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
      summaryReportList: [],
      product_id: '',
      tank_id: '',
      loading: true,
      limit: 20,
      offset: 1,
      count: 0,
      direction: 0,
    };
    this.limitInput = React.createRef();
  }

  componentDidMount() {
    this.getTankList();
    this.getDipSummaryReport();
  }

  getDipSummaryReport = () => {
    services
      .getDipSummaryReport(this.state)
      .then((response) => {
        this.setState({
          summaryReportList: response.data.data.results,
          loading: false,
          count: response.data.data.count,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getTankList = () => {
    services
      .getTankList()
      .then((response) => {
        const tmp = response.data.data;
        const CategoryOptions = [];
        for (let i = 0; i < tmp.length; i++) {
          CategoryOptions.push({
            value: tmp[i].uuid,
            label: tmp[i].name,
            product_id: tmp[i].product.uuid,
          });
        }
        this.setState({
          tankList: CategoryOptions,
        });
      })

      .catch((error) => {
        console.log(error);
      });
  };

  handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date' && dayjs(value).isAfter(this.state.end_date)) {
      this.setState({ [name]: value, end_date: value });
    } else if (
      name === 'end_date' &&
      dayjs(value).isBefore(this.state.start_date)
    ) {
      this.setState({ [name]: value, start_date: value });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleLimitChange = (e) => {
    e.preventDefault();
    this.setState({ limit: e.target.value, offset: 1 });
  };
  handlePagination = (e) => {
    this.setState({ offset: e, loading: true }, function () {
      this.getDipSummaryReport();
    });
  };

  handleQuickSearch = (e) => {
    e.preventDefault();
    if (
      this.limitInput.current.value === '' ||
      this.limitInput.current.value === '0'
    ) {
      return;
    }
    this.setState(
      { limit: +this.limitInput.current.value, offset: 1 },
      function () {
        this.getDipSummaryReport();
      },
    );
  };

  handleProductChange = (e, i) => {
    this.setState({ product_id: e.value });
  };

  handleTankChange = (e, i) => {
    this.setState({ tank_id: e.value });
  };
  resetFilter = () => {
    this.setState(
      {
        tankList: [],
        start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
        summaryReportList: [],
        product_id: '',
        tank_id: '',
        loading: true,
      },
      function () {
        this.getDipSummaryReport();
      },
    );
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, function () {
      this.getDipSummaryReport();
    });
  };

  render() {
    const { summaryReportList } = this.state;
    const TableElement = (
      <TableGrid
        col={col}
        row={summaryReportList}
        offset={this.state.offset}
        count={this.state.count}
        limit={this.state.limit}
        handlePagination={this.handlePagination}
        onEdit={this.props.onEdit}
        onDelete={this.confirmDelete}
      />
    );
    return (
      <>
        <section className="widget full-width  list-table">
          <header>
            <h3>Summary Report </h3>
          </header>
          <main>
            <div className="table-filters">
              <div className="filter-wrapper">
                <div className="filter-group with-icon">
                  <label>
                    Results to display- {this.state.limit || 20} (Total:{' '}
                    {this.state.count})
                  </label>
                  <form onSubmit={this.handleQuickSearch}>
                    <input type="text" name="limit" ref={this.limitInput} />
                    <button
                      type="submit"
                      className="search-icon"
                      onClick={this.handleQuickSearch}
                    />
                  </form>
                </div>
                <div className="filter-group">
                  <label> Start date:</label>
                  <input
                    className="cstm-date-input"
                    type="date"
                    value={this.state.start_date}
                    name="start_date"
                    onChange={this.handleDateChange}
                  />
                </div>
                <div className="filter-group">
                  <label>Till date:</label>
                  <input
                    className="cstm-date-input"
                    type="date"
                    value={this.state.end_date}
                    min={this.state.start_date}
                    onChange={this.handleDateChange}
                    name="end_date"
                  />
                </div>
                <div className="filter-group">
                  <label> Fuel Type</label>
                  <FuelList
                    value={this.state.product_id}
                    onChange={this.handleProductChange}
                  />
                </div>
                <div className="filter-group">
                  <label> Tank Name </label>
                  <TankList
                    onChange={this.handleTankChange}
                    value={this.state.tank_id}
                    options={this.state.tankList}
                    isDependent={this.state.product_id}
                  />
                </div>

                <div className="filter-group reset-filter">
                  <button onClick={this.resetFilter}>Reset filters</button>
                </div>
                <div className="filter-group">
                  <button
                    className="submit"
                    type="submit"
                    onClick={this.submitForm}
                  >
                    Apply filter
                  </button>
                </div>
              </div>
            </div>
            <div className="table-wrapper">
              {(() => {
                if (this.state.loading) {
                  return <DropLoaderLarge />;
                } else {
                  return (
                    <>
                      <TableGrid
                        col={col}
                        row={summaryReportList}
                        offset={this.state.offset}
                        count={this.state.count}
                        limit={this.state.limit}
                        handlePagination={this.handlePagination}
                        hideActions={true}
                        exportOptions={{
                          fileName: `DipSummaryReport - ${this.state.start_date} to ${this.state.end_date}.pdf`,
                          reportName: 'Dip Summary Report',
                          reportDate: `Date - ${dayjs(
                            this.state.start_date,
                          ).format('D MMM YY')} to ${dayjs(
                            this.state.end_date,
                          ).format('D MMM YY')}`,
                          reportTotal: '',
                          reportQuantity: '',
                          reportSales: '',
                          reportTesting: '',
                          reportBowser: '',
                          reportCustomerName: '',
                          reportGst: '',
                          reportOpeningBalance: '',
                          reportClosingBalance: '',
                          reportSaleTotal: '',
                          reportTCSTotal: '',
                        }}
                      />
                    </>
                  );
                }
              })()}
            </div>
          </main>
        </section>
      </>
    );
  }
}

export { DipSummaryReport };
