import axios from 'axios';

import { authHeader } from '@H';

export default {
  getLiveMemoList: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/credit-customer/bunk/live-memo/?start_date=${data.start_date}&end_date=${data.end_date}`,
    }),
  getLiveMemoItem: (id) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/credit-customer/bunk/live-memo/?invoice_id=${id}`,
    }),
};
