import React, { Component } from 'react';

import './styles.scoped.scss';

class CreditSalesSummary extends Component {
  constructor() {
    super();
    this.state = {
      tab: 1,
    };
  }

  render() {
    const summary = this.props.summary;
    return (
      <>
        <section className="widget dsr-table grid">
          <header />
          <main>
            <table className="dsr" data-name="Other Income/Expenses">
              <thead>
                <tr data-header={true}>
                  <th className="alert-brand" colSpan="2">
                    Credit Sales Summary
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Total credit sales </td>
                  <td>
                    {/* {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(summary.all_incomes || 0)} */}
                  </td>
                </tr>
                <tr>
                  <td>Total credit collection </td>
                  <td>
                    {/* {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(summary.all_expenses || 0)} */}
                  </td>
                </tr>
              </tbody>
            </table>
          </main>
        </section>
      </>
    );
  }
}
export default CreditSalesSummary;
