import React, { Component } from 'react';
import axios from 'axios';
import Geonames from 'geonames.js';
import { toast } from 'react-toastify';

import services from '@C/CreditCustomers/_utils/data';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { authHeader } from '@H';
import { authService } from '@S';

const geonames = Geonames({
  username: 'hectonetworks',
  lan: 'en',
  encoding: 'JSON',
});

class MyProfileList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: this.props.uuid,
      profileList: {},
      customer_id: authService.currentUserId,
      loading: true,
      edit: false,
      disabled: this.props.disabled ? this.props.disabled : false,
      updated: false,
    };
  }
  componentDidMount() {
    this.getCreditCustomer();
  }
  getCreditCustomer = () => {
    services
      .getCreditCustomer(this.state, this.props.uuid)
      .then((response) => {
        this.setState({
          profileList: response.data.data,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  toggleEdit = () => {
    this.setState({ edit: !this.state.edit });
  };
  handleChange = (e) => {
    if (e.target.type === 'company_name') {
      const profileList = { ...this.state.profileList };
      profileList['basic_details'][e.target.name] = e.target.value;
      this.setState({ profileList: profileList });
    } else {
      const profileList = { ...this.state.profileList };
      profileList['basic_details'][e.target.name] = e.target.value;
      this.setState({ profileList: profileList });
    }
  };

  handleChangeData = (e) => {
    const profileList = { ...this.state.profileList };
    profileList[e.target.name] = e.target.value;
    this.setState({ profileList: profileList });
  };
  handleAddressChange = (e) => {
    if (e.target.type === 'city') {
      const profileList = { ...this.state.profileList };
      profileList['address'][e.target.name] = e.target.value;
      this.setState({ profileList: profileList });
    } else {
      const profileList = { ...this.state.profileList };
      profileList['address'][e.target.name] = e.target.value;
      this.setState({ profileList: profileList });
    }
  };

  handlePincodeChange = (e) => {
    const profileList = { ...this.state.profileList };
    profileList.address.pincode = e.target.value;
    profileList.address.country_code = '';
    this.setState({ profileList: profileList });
    const postalcode = e.target.value;
    if (postalcode.length > 5) {
      this.setState({ loading: true });
      geonames
        .postalCodeSearch({ postalcode: `${postalcode}` })
        .then((response) => {
          if (response.postalCodes.length > 0) {
            profileList.address.city = response.postalCodes[0].adminName2;
            profileList.address.state = response.postalCodes[0].adminName1;
            (profileList.address.country_code =
              response.postalCodes[0].countryCode),
              this.setState({ profileList: profileList, loading: false });
          } else {
            toast.error('Invalid Pincode');
            this.setState({ loading: false });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
          toast.error('Invalid Pincode');
        });
    }
  };

  updateCreditCustomer = () => {
    const formData = {
      company_name: this.state.profileList.basic_details.company_name,
      first_name: this.state.profileList.basic_details.first_name,
      gstn: this.state.profileList.gstn,
      address: {
        pincode: this.state.profileList.address.pincode,
        city: this.state.profileList.address.city,
        state: this.state.profileList.address.state,
        country: this.state.profileList.address.country.country,
      },
      uuid: this.state.profileList.uuid,
    };

    const headers = authHeader(1);
    axios
      .put(`${process.env.API_URL}/credit-customer/bunk/customer/`, formData, {
        headers,
      })
      .then(() => {
        toast.success('Updated Credit Customer successfully');
        this.setState({ edit: !this.state.edit });
        this.props.onUpdate();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { profileList, loading } = this.state;
    if (loading) {
      return <DropLoaderLarge />;
    }
    return (
      <>
        <section className="widget full-width form-card">
          <header>
            <h3> My Profile </h3>
            <div className="actions space-between">
              {/* <button
                className={`${
                  this.state.edit ?
                    'red-btn btn-with-icon btn-with-icon__cancel' :
                    'white-btn btn-with-icon btn-with-icon__edit'
                }`}
                onClick={() => this.toggleEdit()}
              >
                {this.state.edit ? 'Cancel' : 'Edit Credit Customer'}
              </button> */}
            </div>
          </header>
          <main>
            <form>
              <div className="input-wrapper with-label">
                <label> Company name:</label>
                <input
                  type="text"
                  disabled={!this.state.edit}
                  value={profileList.basic_details?.company_name}
                  name="company_name"
                  onChange={this.handleChange}
                />
              </div>
              <div className="input-wrapper with-label">
                <label>Authorised contact no:</label>
                <input
                  disabled
                  value={profileList.basic_details?.mobile}
                  name="mobile"
                />
              </div>
              <div className="input-wrapper with-label">
                <label>Customer name:</label>
                <input
                  type="text"
                  disabled={!this.state.edit}
                  value={profileList.basic_details?.first_name}
                  name="first_name"
                  onChange={this.handleChange}
                />
              </div>
              <div className="input-wrapper with-label">
                <label>Login id:</label>
                <input
                  type="text"
                  disabled
                  value={profileList.basic_details?.mobile}
                  name="mobile"
                  onChange={this.handleChange}
                />
              </div>
              <div className="input-wrapper with-label">
                <label>GST </label>
                <input
                  type="text"
                  disabled={!this.state.edit}
                  value={profileList.gstn}
                  name="gstn"
                  onChange={this.handleChangeData}
                />
              </div>
              <div className="input-wrapper with-label ">
                <label>Pincode</label>
                <input
                  type="text"
                  disabled={!this.state.edit}
                  name="pincode"
                  onChange={this.handlePincodeChange}
                  value={profileList.address?.pincode}
                />
              </div>

              <div className="input-wrapper with-label ">
                <label>City</label>
                <input
                  type="text"
                  name="city"
                  value={profileList.address?.city}
                  disabled={!this.state.edit}
                  onChange={this.handleAddressChange}
                />
              </div>

              <div className="input-wrapper with-label ">
                <label>State</label>
                <input
                  type="text"
                  name="state"
                  value={profileList.address?.state}
                  onChange={this.handleAddressChange}
                  disabled={!this.state.edit}
                />
              </div>

              <div className="input-wrapper with-label ">
                <label>Country</label>
                <input
                  type="text"
                  name="country"
                  value={profileList.address?.country?.country}
                  disabled={!this.state.edit}
                  onChange={this.handleAddressChange}
                />
              </div>
            </form>
          </main>
          {this.state.edit && (
            <div className="widget-actions">
              <button
                className={`submit ${loading ? 'loading' : ''}`}
                onClick={this.updateCreditCustomer}
              >
                Submit
              </button>
            </div>
          )}
        </section>
      </>
    );
  }
}

export { MyProfileList };
