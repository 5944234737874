import React, { Component } from 'react';
import Select from 'react-select';

import services from './data';

let CategoryOptions;

class InvoiceList extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      options: [],
      vendor: props.vendor || '',
    };
  }
  componentDidMount() {
    services
      .getInvoiceList(this.state.vendor)
      .then((response) => {
        const tmp = response.data.data;
        CategoryOptions = [];
        for (let i = 0; i < tmp.length; i++) {
          CategoryOptions.push({
            value: tmp[i].uuid,
            label: `${tmp[i].invoice_number} (${tmp[i].invoice_type})`,
            pending: tmp[i].pending_amount,
            type: tmp[i].invoice_type,
          });
        }
        this.setState({ options: CategoryOptions });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleChange = (selectedOption) => {
    const id = this.props.did ? this.props.did : 0;
    this.props.onChange(selectedOption, id);
  };

  render() {
    const { options } = this.state;
    return (
      <Select
        onChange={this.handleChange}
        options={options}
        className="select-box-container"
        classNamePrefix="invoice-selector"
        placeholder="Select invoice"
        value={options.filter(({ value }) => value === this.props.value)}
        isDisabled={this.props.disabled || false}
      />
    );
  }
}

export { InvoiceList };
