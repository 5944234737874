import { BehaviorSubject, EMPTY } from 'rxjs';

import { handleResponse } from '@H';

const apiUrl = `${process.env.API_URL}`;
const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem('currentUser')),
);
const currentBunkSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem('currentBunk')),
);
const loginTimeSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem('lknoas')),
);

if (!localStorage.getItem('activeBunk')) {
  localStorage.setItem('activeBunk', '0');
}

export const authService = {
  login,
  logout,
  bunkDetails,
  currentUser: currentUserSubject.asObservable(),
  currentBunk: currentBunkSubject.asObservable(),
  loginTime: loginTimeSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
  get allBunks() {
    return currentBunkSubject?.value?.data;
  },
  get activeBunk() {
    return localStorage.getItem('activeBunk');
  },
  get currentUserRole() {
    return currentUserSubject.value?.data?.user_info?.role?.role;
  },
  get currentUserId() {
    return currentUserSubject.value?.data?.user_info?.uuid?.[
      localStorage.getItem('activeBunk')
    ]?.uuid;
  },
  get currentUserRoleCode() {
    return currentUserSubject.value?.data?.user_info?.role?.code;
  },
  get currentUserToken() {
    return currentUserSubject.value?.data?.token?.access_token;
  },
  get currentUserName() {
    const { first_name, last_name } = currentUserSubject.value?.data?.user_info;
    return `${first_name} ${last_name}`;
  },
  get currentOMC() {
    return currentBunkSubject.value?.data?.[localStorage.getItem('activeBunk')]
      ?.omc?.uuid;
  },
  get currentAccountID() {
    return currentUserSubject.value?.data?.user_info?.associateAccounts?.[
      localStorage.getItem('activeBunk')
    ]?.account_id;
  },
  get currentLoginValidity() {
    return currentUserSubject.value?.data?.token?.expires_in;
  },
  get currentOMCWebLogo() {
    return currentBunkSubject.value?.data?.[localStorage.getItem('activeBunk')]
      ?.omc?.logo?.web_logo;
  },
  get currentOMCMobileLogo() {
    return currentBunkSubject.value?.data?.[localStorage.getItem('activeBunk')]
      ?.omc?.logo?.mobile_logo;
  },
  get currentBunkName() {
    return currentBunkSubject.value?.data?.[localStorage.getItem('activeBunk')]
      ?.name;
  },
  get currentBunkAddress() {
    return currentBunkSubject.value?.data?.[localStorage.getItem('activeBunk')]
      ?.address;
  },
  get currentBunkCountry() {
    return currentBunkSubject.value?.data?.[localStorage.getItem('activeBunk')]
      ?.address?.country?.country;
  },
  get currentBunkCountryUUID() {
    return currentBunkSubject.value?.data?.[localStorage.getItem('activeBunk')]
      ?.address?.country?.uuid;
  },
  get currentBunkCurrency() {
    return currentBunkSubject?.value?.data?.[localStorage.getItem('activeBunk')]
      ?.address?.country?.currency;
  },
  get currentBunkFullAddress() {
    return currentBunkSubject.value?.data?.[localStorage.getItem('activeBunk')]
      ?.address?.full_address;
  },
  get currentUserGst() {
    return currentUserSubject.value?.data?.user_info?.uuid?.[
      localStorage.getItem('activeBunk')
    ]?.gst_no;
  },
  get currentUserMobileNumber() {
    return currentUserSubject.value?.data?.user_info?.uuid?.[
      localStorage.getItem('activeBunk')
    ]?.mobile_number;
  },
  get loginTime() {
    return loginTimeSubject.value;
  },
  activeBunk(bunk) {
    localStorage.setItem('activeBunk', bunk);
  },
};

export const authServiceCreditCustomer = {
  currentUser: currentUserSubject.asObservable(),
  loginTime: loginTimeSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
  get currentUserRole() {
    return currentUserSubject.value?.data?.user_info?.role?.role;
  },
  get currentUserRoleCode() {
    return currentUserSubject.value?.data?.user_info?.role?.code;
  },
  get currentUserToken() {
    return currentUserSubject.value?.data?.token?.access_token;
  },
  get currentUserName() {
    const { first_name, last_name } = currentUserSubject.value?.data?.user_info;
    return `${first_name} ${last_name}`;
  },
  get currentAccountID() {
    return currentUserSubject.value?.data?.user_info?.associateAccounts?.[0]
      ?.account_id;
  },
  get currentLoginValidity() {
    return currentUserSubject.value?.data?.token?.expires_in;
  },
  get currentOMCWebLogo() {
    return currentBunkSubject.value?.data?.[localStorage.getItem('activeBunk')]
      ?.omc?.logo?.web_logo;
  },
  get currentOMCMobileLogo() {
    return currentBunkSubject.value?.data?.[localStorage.getItem('activeBunk')]
      ?.omc?.logo?.mobile_logo;
  },
  get currentCCBunkName() {
    return currentUserSubject.value?.data?.user_info?.associateAccounts?.[
      localStorage.getItem('activeBunk')
    ]?.name;
  },
  get loginTime() {
    return loginTimeSubject.value;
  },
};

function login(email, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password }),
  };

  return fetch(`${apiUrl}/users/login/`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      localStorage.setItem('currentUser', JSON.stringify(user));
      localStorage.setItem('activeBunk', '0');
      localStorage.setItem('lknoas', Date.now());
      currentUserSubject.next(user);
      loginTimeSubject.next(Date.now());
      return user;
    });
}

function bunkDetails(token) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(`${apiUrl}/bunk/associate-bunks/`, requestOptions)
    .then(handleResponse)
    .then((bunk) => {
      localStorage.setItem('currentBunk', JSON.stringify(bunk));
      currentBunkSubject.next(bunk);
      return bunk;
    });
}

function logout() {
  localStorage.removeItem('currentUser');
  localStorage.removeItem('currentBunk');
  localStorage.removeItem('lknoas');
  return EMPTY;
}
