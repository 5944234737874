import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { TallyExport } from '@C/TallyExport/RouteComponents';

const RouteSetup = () => {
  return (
    <>
      <Switch>
        <Route path="/account/tally-export" component={TallyExport} />
        <Route path="/account/tally-export/*">
          <Redirect to={`/account/tally-export`} />
        </Route>
      </Switch>
    </>
  );
};

export { RouteSetup };
