import React, { Component } from 'react';

export class ShortageExcess extends Component {
  constructor() {
    super();
    this.state = {
      tab: 1,
    };
  }

  // calculate total and display in red if negative
  calculateTotal = (total) => {
    if (total < 0) {
      return (
        <td className="red">
          {new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
          }).format(total || 0)}
        </td>
      );
    }
    return (
      <td className="green">
        {new Intl.NumberFormat('en-IN', {
          style: 'currency',
          currency: 'INR',
        }).format(total || 0)}
      </td>
    );
  };

  render() {
    const summary = this.props.summary;
    return (
      <>
        <section className="widget dsr-table">
          <header className="title-wrapper"></header>
          <main>
            <table className="dsr" data-name="Shortage & Excess">
              <thead>
                <tr>
                  <th colSpan={4} className="alert-brand">
                    <label className="printCheckbox">
                      <input
                        type="checkbox"
                        name="enablePrint"
                        defaultChecked="true"
                      />
                    </label>
                    Shortage / Excess
                  </th>
                </tr>
                <tr data-header={true}>
                  <th>#</th>
                  <th>Staff</th>
                  <th>Shift</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {summary.map((item, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{`${item.staff.first_name} ${item.staff.last_name}`}</td>
                    <td>{item.staff.shift.shift}</td>
                    <td>
                      {new Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        currency: 'INR',
                      }).format(item.amount.toFixed(2) || 0)}
                    </td>
                  </tr>
                ))}
                <tr className="summary-row">
                  <td colSpan="3">Total </td>
                  {this.calculateTotal(
                    summary.reduce((a, b) => a + (b['amount'] || 0), 0),
                  )}
                </tr>
              </tbody>
            </table>
          </main>
        </section>
      </>
    );
  }
}
