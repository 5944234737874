import React, { Component } from 'react';
import Modal from 'react-modal';

import SubmitForm from './SubmitForm';

import './styles.scoped.scss';

class ContinueForm extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
    };
  }

  closeModal = () => {
    this.setState({ showModal: false });
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({ showModal: true });
  };
  render() {
    return (
      <>
        <section className="widget form-card form-length">
          <main className="form-content">
            <form className="inline">
              <div className="input-wrapper with-label">
                <span> Do you want to reset your account partially? </span>
              </div>
              <div className="cta-wrapper">
                <button
                  className="submit"
                  type="submit"
                  onClick={this.submitForm}
                >
                  Proceed
                </button>
                <button className="red-btn" onClick={this.cancelForm}>
                  Cancel
                </button>
              </div>
            </form>
          </main>
        </section>
        <Modal
          isOpen={this.state.showModal}
          onRequestClose={() => this.closeModal}
          contentLabel="Example Modal"
          shouldCloseOnOverlayClick={true}
          className="sub-xl-modal-container"
          overlayClassName="xl-modal-overlay"
        >
          <div className="modal-component">
            <SubmitForm onRequestClose={this.closeModal} />
          </div>
        </Modal>
      </>
    );
  }
}
export default ContinueForm;
