import React, { Component } from 'react';

import services from '@C/Utils/data';

import './styles-file-upload.scoped.scss';

const fileTypes = [
  'image/apng',
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/webp',
  'image/x-icon',
];

class FileUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      file: null,
      tmpFileUrl: this.props.imageURL || '/placeholder.jpg',
    };
  }

  handleFileUpload = (event) => {
    if (!this.state.file) {
      this.setState({ error: true });
      return;
    }
    this.setState({ error: false, loading: true });
    const formData = new FormData();
    formData.append('file', this.state.file);
    services
      .uploadImage(formData)
      .then((res) => {
        this.props.onUpload(res.data.data.path, res.data.data.uuid);
        this.setState({
          loading: false,
        });
      })
      .catch((err) => {
        console.log('error uploading the image', err);
        this.setState({ error: true, loading: false });
      });
  };

  handleChange = (e) => {
    const validFile = fileTypes.includes(e.target.files[0].type);
    if (!validFile) {
      this.setState({ error: true });
      return;
    }
    const fileUrl = URL.createObjectURL(e.target.files[0]);
    this.setState({
      file: e.target.files[0],
      error: false,
      tmpFileUrl: fileUrl,
    });
  };

  componentDidMount() {}

  render() {
    const { loading, error, tmpFileUrl } = this.state;
    const { edit } = this.props || true;
    return (
      <div className={`input-wrapper file-upload ${error ? 'error' : ''}`}>
        <div className="image-preview">
          <img src={tmpFileUrl} alt="preview" />
        </div>
        {edit ? (
          <div className="input-group">
            {' '}
            <input
              type="file"
              id="file-id"
              name="pmFile"
              onChange={this.handleChange}
              accept="image/png, image/jpeg"
            />
            <button
              className={`file-upload ${loading ? 'loading' : ''}`}
              onClick={this.handleFileUpload}
            >
              Upload
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

export { FileUpload };
