/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';

import { NavPills } from '@C';
import { AssignDuty } from '@C/CashiersOnDuty/RouteComponents';
import ErrorBoundary from '@C/Error';
import { useGlobalStore } from '@stores/globalStore';

import {
  CollectionSummary,
  CreditSaleSummary,
  Expenses,
  FuelSummary,
  LubeSummary,
  OtherIncome,
  TotalSummary,
} from './Steps';

const EndDuty = () => {
  const { setCODGrandTotal, syncCODCollectionData } = useGlobalStore(
    (state) => ({
      setCODGrandTotal: state.setCODGrandTotal,
      syncCODCollectionData: state.syncCODCollectionData,
    }),
  );

  const { duty, date, mode, staff } = useParams();

  if (!duty || !date || !staff) return null;

  const routes = [
    {
      name: 'Fuel Sales',
      path: `/cashiers-on-duty/end-duty/${duty}/${date}/${staff}/${mode}/fuel-sales`,
    },
    {
      name: 'Lube / Products Sales',
      path: `/cashiers-on-duty/end-duty/${duty}/${date}/${staff}/${mode}/lube-sales`,
    },
    {
      name: 'Credit Sales',
      path: `/cashiers-on-duty/end-duty/${duty}/${date}/${staff}/${mode}/credit-sales`,
    },
    {
      name: 'Other Income',
      path: `/cashiers-on-duty/end-duty/${duty}/${date}/${staff}/${mode}/other-income`,
    },
    {
      name: 'Expenses',
      path: `/cashiers-on-duty/end-duty/${duty}/${date}/${staff}/${mode}/expenses`,
    },
    {
      name: 'Collection Summary',
      path: `/cashiers-on-duty/end-duty/${duty}/${date}/${staff}/${mode}/collection-summary`,
    },
  ];

  useEffect(() => {
    setCODGrandTotal(duty, staff);
    syncCODCollectionData(duty, staff);
  }, []);

  return (
    <>
      <ErrorBoundary>
        <NavPills routes={routes} />
        <TotalSummary date={date} duty={duty} staff={staff} />
      </ErrorBoundary>
      <ErrorBoundary>
        <Switch>
          <Route
            exact
            path="/cashiers-on-duty/end-duty/:duty/:date/:staff/:mode/fuel-sales"
            component={FuelSummary}
          />
          <Route
            exact
            path="/cashiers-on-duty/end-duty/:duty/:date/:staff/:mode/lube-sales"
            component={LubeSummary}
          />
          <Route
            exact
            path="/cashiers-on-duty/end-duty/:duty/:date/:staff/:mode/credit-sales"
            component={CreditSaleSummary}
          />
          <Route
            exact
            path="/cashiers-on-duty/end-duty/:duty/:date/:staff/:mode/other-income"
            component={OtherIncome}
          />
          <Route
            exact
            path="/cashiers-on-duty/end-duty/:duty/:date/:staff/:mode/expenses"
            component={Expenses}
          />
          <Route
            exact
            path="/cashiers-on-duty/end-duty/:duty/:date/:staff/:mode/collection-summary"
            component={CollectionSummary}
          />
          <Route path="*" exact={true} component={AssignDuty} />
        </Switch>
      </ErrorBoundary>
    </>
  );
};

export { EndDuty };
