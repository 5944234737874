import React, { Component } from 'react';
import Select from 'react-select';

import services from './data';

let CategoryOptions;

class BowserList extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      options: [],
      selectedBowser: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    services
      .getBowserList()
      .then((response) => {
        const tmp = response.data.data;
        const options = tmp.map((tank) => ({
          value: tank.uuid,
          label: tank.name,
        }));
        this.setState({ options });
        if (options.length > 0) {
          this.handleChange(options[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange(selectedOption) {
    this.setState({ selectedBowser: selectedOption });
    this.props.onChange(selectedOption);
  }

  handleReset() {
    const { options } = this.state;
    if (options.length > 0) {
      this.handleChange(options[0]);
    }
  }


  render() {
    const { options, selectedBowser } = this.state;
    return (
      <Select
        onChange={this.handleChange}
        options={options}
        className="select-box-container"
        classNamePrefix="raw-select"
        name="bowsers"
        placeholder="Select Bowser"
        value={selectedBowser}
      />
    );
  }
}

export { BowserList };
