/* eslint-disable new-cap */
import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import { VehicleList } from '@C/CreditCustomers/_utils';
import { FuelList, QuotationList } from '@C/Utils';
import { authHeader } from '@H';
import { authService } from '@S';

import PurchaseOrderList from './PurchaseOrderList';

class PurchaseOrder extends Component {
  constructor() {
    super();
    this.state = {
      quotation_id: null,
      is_quotation_created_by_self: 'True',
      time_stamp: dayjs().format('YYYY-MM-DD'),
      credit_customer_uuid: authService.currentUserId,
      order_no: '',
      vehicle_id: '',
      vehicle: '',
      product_list: [
        {
          product_id: '',
          product_type: 'FUEL',
          amount: 0,
        },
      ],
      amount: 0,
      status: 'OPEN',
      notes: '',
      update: false,
      editMode: false,
      isSubmitting: false,
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
  }

  changeDate = (e) => {
    this.setState({ time_stamp: e.target.value });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleProductChange = (e, i) => {
    const list = [...this.state.product_list];
    list[i].product_id = e.value;
    this.setState({ product_list: list });
  };

  handleVehicleChange = (e) => {
    this.setState({ vehicle_id: e?.value, vehicle: e || '' });
  };

  handleQuotation = (e) => {
    this.setState({ quotation_id: e?.value || null });
  };

  submitForm = (e) => {
    e.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    const formData = this.state;
    const headers = authHeader(1);
    axios
      .post(`${process.env.API_URL}/credit-customer/bunk/indent/`, formData, {
        headers,
      })
      .then(() => {
        toast.success('Live purchase order added successfully');

        this.setState({
          quotation_id: null,
          time_stamp: dayjs().format('YYYY-MM-DD'),
          credit_customer_uuid: authService.currentUserId,
          is_quotation_created_by_self: 'True',
          order_no: '',
          vehicle_id: '',
          vehicle: '',
          product_list: [
            {
              product_id: '',
              product_type: 'FUEL',
              amount: 0,
            },
          ],
          amount: 0,
          status: 'OPEN',
          notes: '',
          updated: !this.state.updated,
          isSubmitting: false,
          editMode: false,
        });
        this.validator.hideMessages();
      })

      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <>
        <section className="widget full-width">
          <header>
            <h3> Purchase Orders</h3>
          </header>
          <main className="sub-wrapper">
            <form className="inline">
              <div className="input-wrapper with-label ">
                <label> PO Date:</label>
                {this.validator.message(
                  'time_stamp',
                  this.state.time_stamp,
                  'required',
                )}
                <input
                  type="date"
                  placeholder="Date"
                  name="selectDate"
                  onChange={this.changeDate}
                  value={this.state.time_stamp}
                />
              </div>
              <div className="input-wrapper with-label ">
                <label>Quote no: </label>
                <QuotationList
                  handleQuotation={this.handleQuotation}
                  value={this.state.quotation_id}
                  name="quotation_id"
                />
              </div>
              <div className="input-wrapper with-label ">
                <label> Indent/PO no: </label>
                {this.validator.message(
                  'order_no',
                  this.state.order_no,
                  'required',
                )}
                <input
                  type="text"
                  placeholder="Indent/PO no"
                  onChange={this.handleChange}
                  value={this.state.order_no}
                  name="order_no"
                />
              </div>
              <div className="input-wrapper with-label ">
                <label> Add product: </label>
                {this.validator.message(
                  'product_id',
                  this.state.product_list[0].product_id,
                  'required',
                )}
                <FuelList
                  onChange={this.handleProductChange}
                  value={this.state.product_list[0].product_id}
                />
              </div>
              <div className="input-wrapper with-label">
                <label> Vehicle no:</label>
                <VehicleList
                  handleDropdownChange={this.handleVehicleChange}
                  value={this.state.vehicle_id}
                  selectedCustomerId={this.state.credit_customer_uuid}
                />
              </div>
              <div className="input-wrapper with-label">
                <label> Amount </label>
                {this.validator.message(
                  'amount',
                  this.state.amount,
                  'required',
                )}
                <input
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.amount}
                  name="amount"
                  placeholder="Amount"
                />
              </div>
              <div className="input-wrapper with-label ">
                <label> Notes </label>
                <input
                  type="text"
                  value={this.state.notes}
                  name="notes"
                  onChange={this.handleChange}
                  placeholder="Notes"
                />
              </div>
              <div className="form-actions">
                <button className="submit" onClick={this.submitForm}>
                  Generate
                </button>
                <button className="red-btn" onClick={this.cancelEdit}>
                  Cancel
                </button>
              </div>
            </form>
          </main>
        </section>
        <PurchaseOrderList key={this.state.updated} />
      </>
    );
  }
}

export { PurchaseOrder };
