import React, { Component } from 'react';
import dayjs from 'dayjs';
import { confirmAlert } from 'react-confirm-alert';

import DropLoaderLarge from '@C/Utils/DropLoaderLarge';

let isSelected;

class CashierList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cashiersList: this.props.data,
      loading: false,
      date: this.props.date,
    };
  }

  handleEndDutyClick = (id, name, date) => {
    this.props.onDutyChange(id, name, date);
  };

  onSelect = (id, label, status) => {
    if (status != true) {
      return false;
    }
    isSelected = id;
    this.setState({ isSelected: isSelected, name: label }, function () {
      this.props.onChange(this.state.isSelected, label);
    });
  };

  confirmDate = (id, label, status) => {
    confirmAlert({
      title: 'Confirm date',
      message: `Selected date - ${dayjs(this.state.date).format('DD/MM/YYYY')}`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.onSelect(id, label, status),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  render() {
    const { cashiersList } = this.props;
    return (
      <section className="widget">
        <header>
          <h3>Select Cashier</h3>
        </header>
        <main>
          <div className="selectable-list-container">
            {(() => {
              if (this.state.loading) {
                return <DropLoaderLarge />;
              } else {
                return (
                  <div className="selectable-list-wrapper">
                    <ul className="child-node">
                      {cashiersList.map((item, i) => {
                        return (
                          <li key={i} className="c-list-item">
                            <div
                              className={
                                this.state.isSelected === item.uuid
                                  ? 'details-wrapper selected'
                                  : item.status != true
                                    ? 'details-wrapper disabled'
                                    : 'details-wrapper'
                              }
                              onClick={
                                !this.state.confirm
                                  ? () =>
                                      this.confirmDate(
                                        item.uuid,
                                        item.label,
                                        item.status,
                                      )
                                  : () =>
                                      this.onSelect(
                                        item.uuid,
                                        item.label,
                                        item.status,
                                      )
                              }
                            >
                              <span>{item.label}</span>
                              <span
                                className={
                                  item.status === true
                                    ? 'status green'
                                    : item.status === false
                                      ? 'status orange'
                                      : 'status red'
                                }
                              >
                                {item.status ? '' : 'On duty'}
                              </span>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              }
            })()}
          </div>
        </main>
      </section>
    );
  }
}

export { CashierList };
