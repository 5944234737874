import React, { Component } from 'react';
import dayjs from 'dayjs';

import { LubesCategoryList, ProductList } from '@C/Lubes/_utils/';
import services from '@C/Reports/_utils/data';
import { formatCurrency, TableGrid } from '@C/TableGrid';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';

const col = [
  {
    name: 'Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Product',
    key: 'lube_data',
    formatter(props) {
      return <>{props.name}</>;
    },
  },
  {
    name: 'Category',
    key: 'lube_data',
    formatter(props) {
      return <>{props.category}</>;
    },
  },
  {
    name: 'Selling price',
    key: 'all',
    type: 'currency',
    formatter(props) {
      return <>{formatCurrency(props.selling_price)}</>;
    },
  },
  {
    name: 'Purchase price',
    key: 'all',
    type: 'currency',
    formatter(props) {
      return <>{formatCurrency(props.purchse_price)}</>;
    },
  },
  {
    name: 'Staff',
    key: 'updated_by',
    formatter(props) {
      return <>{props.name}</>;
    },
  },
];

class LubePriceReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slipList: [],
      product_uuid: '',
      lube_category: '',
      start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
      loading: false,
      limit: 20,
      offset: 1,
      count: 0,
    };
    this.limitInput = React.createRef();
  }

  handleQuickSearch = (e) => {
    e.preventDefault();
    if (
      this.limitInput.current.value === '' ||
      this.limitInput.current.value === '0'
    ) {
      return;
    }
    this.setState(
      { limit: this.limitInput.current.value, offset: 1 },
      function () {
        this.getLubesPriceReport();
      },
    );
  };

  componentDidMount() {
    this.getLubesPriceReport();
  }

  handleProductChange = (e, i) => {
    this.setState({ product_uuid: e?.value || '' });
  };

  handleCategoryChange = (e) => {
    this.setState({ lube_category: e.value });
  };

  getLubesPriceReport = () => {
    services
      .getLubesPriceReport(this.state)
      .then((response) => {
        this.setState({
          slipList: response.data.data.results,
          count: response.data.data.count,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handlePagination = (e) => {
    this.setState({ offset: e }, function () {
      this.getLubesPriceReport();
    });
  };

  handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date' && dayjs(value).isAfter(this.state.end_date)) {
      this.setState({ [name]: value, end_date: value });
    } else if (
      name === 'end_date' &&
      dayjs(value).isBefore(this.state.start_date)
    ) {
      this.setState({ [name]: value, start_date: value });
    } else {
      this.setState({ [name]: value });
    }
  };

  resetFilter = () => {
    this.limitInput.current.value = 20;
    this.setState(
      {
        start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
        product_uuid: '',
        lube_category: '',
        loading: true,
        limit: 20,
        offset: 1,
      },
      function () {
        this.getLubesPriceReport();
      },
    );
  };
  submitForm = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, function () {
      this.getLubesPriceReport();
    });
  };

  render() {
    const { slipList } = this.state;

    return (
      <section className="widget full-width list-table inverted">
        <header>
          <h3>Lube / Products Price Report</h3>
        </header>
        <main>
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group">
                <label>From date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.start_date}
                  name="start_date"
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="filter-group">
                <label>To date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.end_date}
                  min={this.state.start_date}
                  onChange={this.handleDateChange}
                  name="end_date"
                />
              </div>
              <div className="filter-group">
                <label>Product:</label>
                <ProductList
                  handleDropdownChange={this.handleProductChange}
                  value={this.state.product_uuid}
                />
              </div>
              <div className="filter-group">
                <label>Category:</label>
                <LubesCategoryList
                  handleDropdownChange={this.handleCategoryChange}
                  value={this.state.lube_category}
                />
              </div>

              <div className="filter-group with-icon">
                <label>
                  Results to display- {this.state.limit || 20} (Total:{' '}
                  {this.state.count})
                </label>
                <form onSubmit={this.handleQuickSearch}>
                  <input type="text" name="limit" ref={this.limitInput} />
                  <button
                    type="submit"
                    className="search-icon"
                    onClick={this.handleQuickSearch}
                  />
                </form>
              </div>
              <div className="filter-group reset-filter">
                <button onClick={this.resetFilter}>Reset filters</button>
              </div>
              <div className="filter-group">
                <button
                  className="submit"
                  type="submit"
                  onClick={this.submitForm}
                >
                  Apply filter
                </button>
              </div>
            </div>
          </div>
          <div className="table-wrapper">
            {(() => {
              if (this.state.loading) {
                return <DropLoaderLarge />;
              } else {
                return (
                  <TableGrid
                    col={col}
                    row={slipList}
                    offset={this.state.offset}
                    count={this.state.count}
                    limit={this.state.limit}
                    handlePagination={this.handlePagination}
                    hideActions={true}
                    exportOptions={{
                      fileName: `Lube Price - ${this.state.start_date} to ${this.state.end_date}.pdf`,
                      reportName: 'Lube Price Report',
                      reportDate: `Date - ${dayjs(this.state.start_date).format(
                        'D MMM YY',
                      )} to ${dayjs(this.state.end_date).format('D MMM YY')}`,
                      reportTotal: '',
                      reportQuantity: '',
                      reportSales: '',
                      reportTesting: '',
                      reportBowser: '',
                      reportCustomerName: '',
                      reportGst: '',
                      reportOpeningBalance: '',
                      reportClosingBalance: '',
                      reportSaleTotal: '',
                      reportTCSTotal: '',
                    }}
                  />
                );
              }
            })()}
          </div>
        </main>
      </section>
    );
  }
}

export { LubePriceReport };
