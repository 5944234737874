import axios from 'axios';

import { authHeader } from '@H';

export default {
  getStaffList: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/staff/associate-staff/`,
    }),
  getDSRData: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/dsr/?dsr_date=${data.date}&staff_id=${data.staff}&shift=${data.shift}`,
    }),
  getDSRChartsData: (date) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/dsr/?dsr_date=${date}`,
    }),
  // genetateOtp: (body) => {
  //   const headers = authHeader(1);
  //   return axios.post(`${process.env.API_URL}/two-step-auth/`, body, {headers});
  // },

  getDSRDates: (month, year) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/dsr/dsr-date/?month=${month}&year=${year}`,
    }),

  generateOtp: () =>
    axios({
      body: {
        from_app: 'DSR',
      },
      method: 'POST',
      headers: authHeader(1),
      url: `${process.env.API_URL}/two-step-auth/ `,
    }),
};
