import React, { Component } from 'react';

import { TableGrid } from '@C/TableGrid';

const col = [
  {
    name: 'PO Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Indent/PO no.',
    key: '',
  },
  {
    name: 'Quote no.',
    key: '',
  },

  {
    name: 'Amount',
    key: '',
  },
  {
    name: 'Notes',
    key: 'notes',
  },

  {
    name: 'Status',
    key: '',
  },
];

export default class PurchaseOrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purchaseOrderList: [],
      offset: 1,
      count: 0,
      limit: 20,
    };
  }

  render() {
    const { purchaseOrderList } = this.state;

    return (
      <>
        <section className="widget full-width">
          <header>
            <h3> All Purchase Orders </h3>
          </header>
          <main className="content-wrapper">
            <TableGrid
              col={col}
              row={purchaseOrderList}
              count={this.state.count}
            />
          </main>
        </section>
      </>
    );
  }
}
