import React, { Component } from 'react';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';

import { AccessControl } from '@C/Utils';
import { authHeader } from '@H';

import services from './data';
import { TankDetails } from './TankDetails';

import './tank-list.scoped.scss';

class TankList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tankList: [],
      showModal: false,
      selectedId: '',
      expandDetails: false,
    };
  }
  componentDidMount() {
    this.getTankList();
  }

  getTankList = () => {
    services
      .getTankList()
      .then((response) => {
        this.setState({ tankList: response.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  confirmDelete = (id) => {
    confirmAlert({
      title: 'Confirm tank deletion',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteItem(id),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  deleteItem = (id) => {
    const headers = authHeader(1);
    axios
      .delete(
        `${process.env.API_URL}/bunk/tank-mapping/?delete_type=tank&uuid=${id}`,
        { headers },
      )
      .then(() => {
        toast.success('Tank deleted successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        this.getTankList();
      });
  };

  expandDetails = (selectedId) => {
    this.setState({ selectedId: selectedId, expandDetails: true });
  };

  collapseCard = () => {
    this.setState({ expandDetails: false, selectedId: '' });
  };

  render() {
    const { tankList, expandDetails, selectedId } = this.state;
    return (
      <>
        <section className="widget full-width list-card__large">
          <header>
            <h3>Existing Tanks</h3>
          </header>
          <main>
            <div className="cards-container">
              <ul>
                {tankList.map((item, i) => {
                  return (
                    <li
                      className={`data-card ${
                        expandDetails && selectedId == item.uuid
                          ? 'expanded'
                          : ''
                      }`}
                      key={i}
                    >
                      <div className="card-wrapper">
                        <div className="card-img">
                          {item.is_bowser && (
                            <div className="card-ribbon">Bowser</div>
                          )}
                        </div>
                        <div className="card-info">
                          <p>
                            <label>Name:</label> {item.name}
                          </p>
                          <p>
                            <label>Fuel:</label> {item.product.product}
                          </p>
                          <p>
                            <label>Capacity:</label> {item.capacity}
                          </p>
                          <p>
                            <label>No. of guns:</label> {item.number_of_guns}
                          </p>
                        </div>
                        <div className="card-actions">
                          <AccessControl allowedRoles={['Dealer']}>
                            <button
                              onClick={() => this.expandDetails(item.uuid)}
                            >
                              View Details
                            </button>
                          </AccessControl>
                        </div>
                      </div>
                      {expandDetails && selectedId === item.uuid && (
                        <div className="card-panel">
                          <TankDetails
                            tankId={item.uuid}
                            onUpdate={this.getTankList}
                            collapseCard={this.collapseCard}
                          />
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </main>
        </section>
      </>
    );
  }
}

export { TankList };
