/* eslint-disable new-cap */
import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import Geonames from 'geonames.js';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import { CountryList } from '@C/Utils';
import { authHeader } from '@H';
import { authService } from '@S';

import { VendorList } from './VendorList';

const geonames = Geonames({
  username: 'hectonetworks',
  lan: 'en',
  encoding: 'JSON',
});

class Vendors extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });

    this.state = {
      username: '',
      password: '',
      email: '',
      mobile: '',
      avatar_url: '',
      user_role: 106,
      first_name: '',
      last_name: '',
      vendor: {
        gstn: '',
        opening_balance: 0,
        start_date: dayjs().format('YYYY-MM-DD'),
        category: {
          uuid: '',
        },
        address: {
          city: '',
          country: '',
          pincode: '',
          state: '',
          country_code: '',
          full_address: '',
        },
      },
      isSubmitting: false,
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleStartDate = (e) => {
    const { vendor } = this.state;
    vendor.start_date = e.target.value;
    this.setState({ vendor });
  };

  handleVendorDataChange = (e) => {
    const { vendor } = this.state;
    if (e.target.name === 'opening_balance') {
      vendor[e.target.name] = e.target.value;
    } else if (e.target.name === 'gstn') {
      vendor[e.target.name] = e.target.value;
    } else {
      vendor.address[e.target.name] = e.target.value;
    }

    this.setState({ vendor });
  };

  handlePincodeChange = (e) => {
    const { vendor } = this.state;
    vendor.address.pincode = e.target.value;
    vendor.address.country_code = '';
    this.setState({ vendor: vendor });
    const postalcode = e.target.value;
    if (postalcode.length > 5) {
      this.setState({ loading: true });
      geonames
        .postalCodeSearch({ postalcode: `${postalcode}` })
        .then((response) => {
          if (response.postalCodes.length > 0) {
            vendor.address.city = response.postalCodes[0].adminName2;
            vendor.address.state = response.postalCodes[0].adminName1;
            (vendor.address.country_code = response.postalCodes[0].countryCode),
              this.setState({ vendor: vendor, loading: false });
          } else {
            toast.error('Invalid Pincode');
            this.setState({ loading: false });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ loading: false });
          toast.error('Invalid Pincode');
        });
    }
  };

  handleCategoryChange = (e) => {
    this.setState({ category: { uuid: e.value } });
  };

  handleCountryChange = (e) => {
    const { vendor } = this.state;
    vendor.address.country = e?.value;
    this.setState({ vendor: vendor });
  };

  handleFileUpload = (e) => {
    this.setState({ pimage: e });
  };

  editMode = (item) => {
    this.setState({ edit: true });

    window.scrollTo({
      behavior: 'smooth',
      top: this.myRef.current.offsetTop - 100,
    });
    const vendorData = { ...this.state.vendor };
    vendorData.address.country = item.country_uuid;
    vendorData.gstn = item.gstn;
    vendorData.uuid = item.uuid;
    vendorData.start_date = item.start_date;
    vendorData.opening_balance = item.opening_balance || 0;   
    vendorData.address.city = item.city;
    vendorData.address.state = item.state;
    vendorData.address.pincode = item.pincode;
    vendorData.address.full_address = item.full_address;

    this.setState({
      first_name: item.first_name,
      mobile: item.mobile,
      email: item.email,
      vendor: vendorData,
    });
  };

  cancelEdit = () => {
    this.setState({
      edit: false,
      isSubmitting: false,
      username: '',
      password: '',
      email: '',
      mobile: '',
      avatar_url: '',
      user_role: 106,
      first_name: '',
      last_name: '',
      vendor: {
        gstn: '',
        opening_balance: 0,
        start_date: dayjs().format('YYYY-MM-DD'),
        category: {
          uuid: '',
        },
        address: {
          city: '',
          country: '',
          pincode: '',
          state: '',
          full_address: '',
        },
      },
    });
  };

  submitForm = (e) => {
    e.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState({ isSubmitting: true });
    const formData = this.state;
    formData.username = formData.first_name;
    const headers = authHeader(authService.currentAccountID);

    let method = 'post';
    let url = `${process.env.API_URL}/users/register/`;

    if (this.state.edit) {
      method = 'put';
      url = `${process.env.API_URL}/lubes/bunk/vendor/`;
    }

    axios({
      method,
      url,
      data: formData,
      headers,
    })
      .then((response) => {
        toast.success('Vendor added successfully');
        window.scrollTo({
          behavior: 'smooth',
          top: this.myRef.current.offsetTop - 150,
        });
        this.validator.hideMessages();
        this.setState({
          updated: !this.state.updated,
          isSubmitting: false,
          username: '',
          password: '',
          email: '',
          mobile: '',
          avatar_url: '',
          user_role: 106,
          first_name: '',
          last_name: '',
          vendor: {
            gstn: '',
            opening_balance: 0,
            start_date: dayjs().format('YYYY-MM-DD'),
            category: {
              uuid: '',
            },
            address: {
              city: '',
              country: '',
              pincode: '',
              state: '',
              full_address: '',
            },
          },
        });
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        this.setState({ isSubmitting: false });
      });
  };

  render() {
    const { edit } = this.state;
    return (
      <>
        <section className="widget full-width" ref={this.myRef}>
          <header>
            <h3>{edit ? 'Edit' : 'Add New'} Vendor/Supplier</h3>
          </header>
          <main>
            <form className="inline">
              <div className="input-wrapper with-label">
                <label>Date</label>
                <input
                  type="date"
                  name="start_date"
                  onChange={this.handleStartDate}
                  value={this.state.vendor.start_date}
                />
              </div>
              <div className="input-wrapper with-label ">
                <label htmlFor="name">Name</label>
                {this.validator.message(
                  'Vendor Name',
                  this.state.first_name,
                  'required|alpha_num_space|max:25',
                )}
                <input
                  type="text"
                  id="name"
                  name="first_name"
                  onChange={this.handleChange}
                  value={this.state.first_name}
                />
              </div>
              <div className="input-wrapper with-label ">
                <label htmlFor="mobile">Mobile</label>
                {this.validator.message(
                  'Contact number',
                  this.state.mobile,
                  'required|numeric|phone',
                )}
                <input
                  type="text"
                  id="mobile"
                  name="mobile"
                  onChange={this.handleChange}
                  value={this.state.mobile}
                />
              </div>

              <div className="input-wrapper with-label ">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  onChange={this.handleChange}
                  value={this.state.email}
                />
              </div>
              <div className="input-wrapper with-label ">
                <label htmlFor="gst_no">GST No</label>
                <input
                  type="text"
                  id="gst_no"
                  name="gstn"
                  onChange={this.handleVendorDataChange}
                  value={this.state.vendor.gstn}
                />
              </div>

              {/* <div className="input-wrapper with-label ">
                <label htmlFor="opening_balance">Opening balance</label>
                {this.validator.message(
                  'opening_balance',
                  this.state.vendor.opening_balance,
                  'required|numeric',
                )}
                <input
                  type="text"
                  name="opening_balance"
                  onChange={this.handleVendorDataChange}
                  value={this.state.vendor.opening_balance || 0}
                />
              </div> */}

              <div className="input-wrapper with-label ">
                <label htmlFor="pincode">Pincode</label>
                {this.validator.message(
                  'pincode',
                  this.state.vendor.address.pincode,
                  'required|numeric',
                )}
                <input
                  type="text"
                  id="pincode"
                  name="pincode"
                  onChange={this.handlePincodeChange}
                  value={this.state.vendor.address.pincode}
                />
              </div>

              <div className="input-wrapper with-label ">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  onChange={this.handleVendorDataChange}
                  value={this.state.vendor.address.city}
                  disabled={this.state.loading}
                />
              </div>

              <div className="input-wrapper with-label ">
                <label htmlFor="state">State</label>
                {this.validator.message(
                  'State',
                  this.state.vendor.address.state,
                  'required|alpha_num_space',
                )}
                <input
                  type="text"
                  id="state"
                  name="state"
                  onChange={this.handleVendorDataChange}
                  value={this.state.vendor.address.state}
                  disabled={this.state.loading}
                />
              </div>

              <div className="input-wrapper with-label ">
                <label htmlFor="country">Country</label>
                {this.validator.message(
                  'Country',
                  this.state.vendor.address.country,
                  'required',
                )}
                <CountryList
                  handleCountryChange={this.handleCountryChange}
                  code={this.state.vendor.address.country_code}
                  value={this.state.vendor.address.country}
                />
              </div>
              <div className="input-wrapper with-label ">
                <label htmlFor="full_address"> Full address </label>
                <input
                  type="text"
                  id="full_address"
                  name="full_address"
                  onChange={this.handleVendorDataChange}
                  value={this.state.vendor.address.full_address}
                />
              </div>

              <div className="form-actions">
                <button
                  type="submit"
                  className={`submit ${
                    this.state.isSubmitting ? 'loading' : ''
                  }`}
                  onClick={this.submitForm}
                >
                  Submit
                </button>
                {edit && (
                  <button className="red-btn" onClick={this.cancelEdit}>
                    Cancel
                  </button>
                )}
              </div>
            </form>
          </main>
        </section>
        <VendorList key={this.state.updated} onEdit={this.editMode} />
      </>
    );
  }
}

export { Vendors };
