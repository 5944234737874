import React, { useEffect } from 'react';

const SMSList = (props) => {
  const [list, setList] = React.useState([]);

  useEffect(() => {});

  return (
    <>
      <section className="widget full-width list-table inverted">
        <header>
          <h3> All Request for SMS </h3>
        </header>
        <main>
          <table>
            <thead>
              <tr>
                <th>
                  <span> Date </span>
                </th>
                <th>
                  <span> Dealer name </span>
                </th>
                <th>
                  <span> Mobile no.</span>
                </th>
                <th>
                  <span> Package requested</span>
                </th>
                <th>
                  <span>Actions </span>
                </th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </main>
      </section>
    </>
  );
};

export default SMSList;
