import React, { Component } from 'react';
import Select from 'react-select';

import services from './data';

class TankList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      isDependent: this.props.isDependent || false,
    };
  }

  componentDidMount = () => {
    this.getTankList();
  };

  getTankList = () => {
    services
      .getTankList()
      .then((response) => {
        const tmp = response.data.data;
        const CategoryOptions = [];
        for (let i = 0; i < tmp.length; i++) {
          CategoryOptions.push({
            value: tmp[i].uuid,
            label: tmp[i].name,
            product_id: tmp[i].product.uuid,
          });
        }
        this.setState({
          options: CategoryOptions,
        });
      })

      .catch((error) => {
        console.log(error);
      });
  };

  handleChange = (selectedOption) => {
    const i = this.props.did ? this.props.did : 0;
    const j = this.props.jid ? this.props.jid : 0;
    let option = selectedOption;
    if (selectedOption == null) {
      option = { value: '', label: '', product_id: '' };
    }

    this.props.onChange(option, i, j);
  };

  render() {
    let { options } = this.state;
    const { isDependent } = this.props || false;
    if (isDependent) {
      options = options.filter((item) => {
        return item.product_id == isDependent;
      });
    }

    return (
      <>
        <Select
          onChange={this.handleChange}
          options={options}
          isClearable={true}
          className="select-box-container tank-selector-container"
          classNamePrefix="tank-selector"
          name="tank"
          placeholder="Select tank"
          value={options.filter(({ value }) => value === this.props.value)}
          escapeClearsValue={true}
        />
      </>
    );
  }
}
export { TankList };
