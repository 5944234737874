import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { authService } from '@S';

const Header = () => {
  const [time, setTime] = useState(
    dayjs().format('ddd, MMM D, YYYY -  hh:mm:ss A'),
  );
  const [bunks, setBunks] = useState([]);
  const [omcLogo, setOmcLogo] = useState('');
  const [role, setRole] = useState('');
  const [bunkName, setBunkName] = useState('');

  const currentTime = () => {
    setTime(dayjs().format('ddd, MMM D, YYYY -  hh:mm:ss A'));
  };

  const toggleSubmenu = (e) => {
    e.preventDefault();
    e.target.nextElementSibling.classList.toggle('show');
  };

  const handleBunkChange = (index) => {
    authService.activeBunk(index);
    window.location.href = '/dashboard';
  };

  const toggleNavbar = () => {
    const el = document.querySelector('body');
    el.classList.toggle('sidebar-collapsed');
  };

  const toggleDropdown = (e) => {
    e.preventDefault();
    const el = document.querySelector('.profile-img');
    el.classList.toggle('active');
  };

  useEffect(() => {
    let logo = '';
    let userRole = '';
    let bunk = '';
    const bunkList = authService.allBunks;
    setBunks(bunkList);
    const interval = setInterval(() => currentTime(), 1000);
    userRole = authService.currentUserRole;
    if (userRole === 'Site Admin') {
      bunk = 'Petrometer admin';
      logo =
        'https://res.cloudinary.com/hecto-networks/image/upload/v1617112778/Webp_net_resizeimage_b6fb9d01d3.png';
    } else {
      bunk = authService.currentBunkName;
      logo = authService.currentOMCWebLogo;
    }

    if (!logo) {
      logo = '/placeholder-logo.png';
    }

    setOmcLogo(logo);
    setRole(userRole);
    setBunkName(bunk);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <header id="page-header">
        <div className="header-navbar">
          <div className="header-navbar-left">
            <div className="app-logo" />
            <button className="toggle-sidebar" onClick={() => toggleNavbar()} />
            <div className="brand-wrapper">
              <div className="img-wrapper">
                <img src={omcLogo} alt="OMC Logo" />
              </div>
              <div className="title-wrapper">
                <h2>{bunkName}</h2>
              </div>
            </div>
          </div>
          <div className="header-navbar-right">
            <div className="current-date">{time}</div>
            <div className="user-profile">
              <div className="profile-info">
                <h5>{authService.currentUserName}</h5>
                <h6>{authService.currentUserRole}</h6>
              </div>
              <div className="profile-img dropdown-container">
                <img
                  src={`https://ui-avatars.com/api/?name=${authService.currentUserName}`}
                  onClick={toggleDropdown}
                  alt="Profile Avatar"
                />
                <ul className="profile-dropdown-menu">
                  {bunks.length > 1 && (
                    <li className="menu-level-2">
                      <a href="#" onClick={(e) => toggleSubmenu(e)}>
                        Switch Bunk
                      </a>
                      <ul className="sub-menu">
                        {bunks.map((bunk, index) => (
                          <li
                            role="button"
                            key={bunk.account_id}
                            onClick={() => handleBunkChange(index)}
                          >
                            <span className="bold">{bunk.name}</span>
                          </li>
                        ))}
                      </ul>
                    </li>
                  )}
                  {role === 'Dealer' && (
                    <li>
                      <a href="/account">My account</a>
                    </li>
                  )}
                  <li>
                    <a href="/logout">Logout</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export { Header };
