import React, { Component } from 'react';
import dayjs from 'dayjs';

import PhysicalStockList from '@C/Lubes/RouteComponents/PhysicalStockInspection/PhysicalStockList';

class PhysicalStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time_stamp: dayjs().format('YYYY-MM-DD'),
    };
  }

  changeDate = (e) => {
    this.setState({ time_stamp: e.target.value });
  };

  render() {
    return (
      <>
        <section className="widget full-width form-table" ref={this.myRef}>
          <header>
            <h3> Lubes Physical Stock Inspection </h3>
          </header>
          <main>
            <div className="form-header">
              <div className="input-wrapper with-label ">
                <label> Date:</label>
                <input
                  type="date"
                  placeholder="Date"
                  name="selectDate"
                  onChange={this.changeDate}
                  value={this.state.time_stamp}
                />
              </div>
              <div className="input-wrapper with-label ">
                <label> Inspection ref no: </label>
                <input
                  type="text"
                  placeholder="Inspection ref no "
                  onChange={this.handleChange}
                  value={this.state.inspection_ref_no}
                  name="inspection_ref_no"
                />
              </div>
            </div>
            <div className="form-content">
              <table>
                <thead>
                  <tr>
                    <th> # </th>
                    <th> Lubes name </th>
                    <th> Stock as per inventory </th>
                    <th> physical stock in bunk </th>
                    <th> Variation </th>
                    <th> Notes</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
            <div className="form-actions">
              <button
                className="submit"
                type="submit"
                onClick={this.submitForm}
              >
                Lubes physical Stock / Inspection Settlement
              </button>
              <button className="red-btn" onClick={this.cancelEdit}>
                Cancel
              </button>
            </div>
          </main>
        </section>
        <PhysicalStockList />
      </>
    );
  }
}
export { PhysicalStock };
