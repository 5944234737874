import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ReferralProgram } from '@C/ReferralProgram/RouteComponents';

const RouteSetup = () => {
  return (
    <Switch>
      <Route path="/account/referral-program" component={ReferralProgram} />
      <Route path="/account/referral-program*">
        <Redirect to={`/account/referral-program`} />
      </Route>
    </Switch>
  );
};

export { RouteSetup };
