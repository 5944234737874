import React from 'react';

import { Header, Navbar } from '@C';
import ErrorBoundary from '@C/Error';
import { RouteSetup } from '@C/Reports';

const Reports = () => {
  return (
    <main>
      <div className="page-wrapper">
        <Header title="Reports" />
        <Navbar />
        <div className="page-content">
          <section className="page-components">
            <div className="page-title">
              <h1>Reports</h1>
            </div>
            <ErrorBoundary>
              <RouteSetup />
            </ErrorBoundary>
          </section>
        </div>
      </div>
    </main>
  );
};

export { Reports };
