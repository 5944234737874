import axios from 'axios';

import { authHeader } from '@H';

export default {
  getLubesCategoryList: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/lubes/bunk/category/`,
    }),
  getVendorList: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/lubes/bunk/vendor/`,
    }),

  getVendorListItem: (id) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/lubes/bunk/vendor/?vendor_id=${id}`,
    }),
  getProductList: (isAll) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/lubes/bunk/lube/list/?is_all=${isAll ? 'true' : 'false'}`,
    }),
  getVendorInvoice: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/lubes/bunk/vendor/purchase/`,
    }),
  getAllLubes: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/lubes/bunk/lube/?limit=${data.limit}&offset=${data.offset}&date=${data.time_stamp}`,
    }),
  getSingleLubes: (id) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/lubes/bunk/lube/?lube_id=${id}`,
    }),
  getLubeSales: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/transactions/?product_type=Lubes&limit=${data.limit}&offset=${data.offset}&start_date=${data.start_date}&end_date=${data.end_date}&staff_id=${data.staff_id}&cashier_duty_id=${data.cashier_duty_id}&product_id=${data.product_uuid}&lube_category=${data.lube_category}&bill_number=${data.bill_number}`,
    }),
  getLubeSaleItem: (id) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/transactions/?transaction_id=${id}`,
    }),

  getVendorPayment: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/lubes/bunk/vendor/payments/?limit=${data.limit}&offset=${data.offset}&start_date=${data.start_date}&end_date=${data.end_date}&staff_id=${data.staff_id}&vendor_id=${data.vendor_id}`,
    }),

  getLubePurchaseList: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/lubes/bunk/vendor/purchase/?limit=${data.limit}&offset=${data.offset}&start_date=${data.start_date}&end_date=${data.end_date}&product_id=${data.product_id}&vendor_id=${data.vendor_id}&invoice_number=${data.invoice_id}`,
    }),
  getLubePurchaseItem: (id) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/lubes/bunk/vendor/purchase/?invoice_id=${id}`,
    }),
  deleteLube: (id) =>
    axios({
      method: 'DELETE',
      headers: authHeader(1),
      url: `${process.env.API_URL}/lubes/bunk/lube/?lube_id=${id}`,
    }),
  updateLube: (body) => {
    const headers = authHeader(1);
    return axios.put(`${process.env.API_URL}/lubes/bunk/lube/`, body, {
      headers,
    });
  },
};
