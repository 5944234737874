import React, { Component } from 'react';
import dayjs from 'dayjs';
import Modal from 'react-modal';
import Select from 'react-select';

import EditCashSummary from '@C/CashiersOnDuty/RouteComponents/EndDuty/Steps/CollectionSummary/EditCashSummary';
import services from '@C/Reports/_utils/data';
import { formatCurrency, TableGrid } from '@C/TableGrid';
import { ShiftsList, StaffList } from '@C/Utils';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';

const sortOptions = [
  { value: '1003', label: 'Total cash in hand' },
  { value: '1000', label: 'Cash received from office' },
  { value: '1002', label: 'Cash return to office' },
  { value: '1001', label: 'Cash given to manager' },
];

const col = [
  {
    name: 'Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Cashier name',
    key: 'all',
    formatter(props) {
      return (
        <>
          {props.cashier__user__first_name} {props.cashier__user__last_name}
        </>
      );
    },
  },
  {
    name: 'Shift',
    key: 'cashier_duty__shift__shift',
  },
  {
    name: 'Cash type',
    key: 'cash_management__cash_type',
  },
  {
    name: 'Amount',
    key: 'all',
    type: 'currency',
    formatter(props) {
      return <>{formatCurrency(props.amount)}</>; 
    },
  },
];

const enabledActions = [
  {
    action: 'Pay',
    roles: ['Manager', 'Dealer'],
  },
];

class DenominationReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      denominationList: [],
      cashier_duty_id: '',
      shift_id: '',
      date: dayjs().format('YYYY-MM-DD'),
      loading: true,
      limit: 20,
      offset: 1,
      count: 0,
      sortOrder: '1003',
      showModal: false,
    };
    this.limitInput = React.createRef();
  }

  componentDidMount() {
    this.getDenominationReport();
  }

  getDenominationReport = () => {
    services
      .getDenominationReport(this.state)
      .then((response) => {
        this.setState({
          denominationList: response.data.data.results,
          count: response.data.data.count,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleStaffChange = (e) => {
    this.setState({ cashier_duty_id: e.value });
  };

  handleDateChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleShiftChange = (e) => {
    this.setState({ shift_id: e.value });
  };

  handlePagination = (e) => {
    this.setState({ offset: e }, function () {
      this.getDenominationReport();
    });
  };

  handleSortOrderChange = (e) => {
    this.setState({ sortOrder: e.value }, function () {
      this.sortData();
    });
  };

  sortData = () => {
    const { denominationList, sortOrder } = this.state;
    const sortedData = denominationList.sort((a, b) => {
      return a[sortOrder] - b[sortOrder];
    });
    this.setState({ denominationList: sortedData }, function () {
      this.getDenominationReport();
    });
  };

  handleQuickSearch = (e) => {
    e.preventDefault();
    if (
      this.limitInput.current.value === '' ||
      this.limitInput.current.value === '0'
    ) {
      return;
    }
    this.setState(
      { limit: this.limitInput.current.value, offset: 1 },
      function () {
        this.getDenominationReport();
      },
    );
  };

  showDenomination = (cashier_duty_id, sortOrder) => {
    this.setState((prevState) => ({
      showModal: true,
      cashier_duty_id:
        cashier_duty_id?.cashier_duty__uuid || prevState.cashier_duty_id,
      sortOrder: sortOrder?.value || prevState.sortOrder,
    }));
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };
  resetFilter = () => {
    this.limitInput.current.value = 20;
    this.setState(
      {
        cashier_duty_id: '',
        date: dayjs().format('YYYY-MM-DD'),
        shift_id: '',
        loading: true,
        limit: 20,
        offset: 1,
      },
      function () {
        this.getDenominationReport();
      },
    );
  };
  submitForm = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, function () {
      this.getDenominationReport();
    });
  };

  render() {
    const { denominationList } = this.state;

    return (
      <>
        <section className="widget list-table full-width inverted">
          <header className="list-header">
            <h3> Denomination </h3>
          </header>
          <main>
            <div className="table-filters">
              <div className="filter-wrapper">
                <div className="filter-group">
                  <label>From date:</label>
                  <input
                    className="cstm-date-input"
                    type="date"
                    value={this.state.date}
                    name="date"
                    onChange={this.handleDateChange}
                  />
                </div>
                <div className="filter-group">
                  <label>Cashier:</label>
                  <StaffList
                    value={this.state.cashier_duty_id}
                    onChange={this.handleStaffChange}
                    staffType={['Cashiers', 'Managers', 'Dealer']}
                  />
                </div>
                <div className="filter-group">
                  <label> Shift :</label>
                  <ShiftsList
                    value={this.state.shift_id}
                    onChange={this.handleShiftChange}
                  />
                </div>
                <div className="filter-group">
                  <label>Sort order</label>
                  <Select
                    name="sort_order"
                    options={sortOptions}
                    onChange={this.handleSortOrderChange}
                    value={sortOptions.filter(
                      ({ value }) => value === this.state.sortOrder,
                    )}
                    defaultValue={{
                      value: '1003',
                      label: 'Total cash in hand',
                    }}
                  />
                </div>
                <div className="filter-group with-icon">
                  <label>
                    Results to display- {this.state.limit || 20} (Total:{' '}
                    {this.state.count})
                  </label>
                  <form onSubmit={this.handleQuickSearch}>
                    <input type="text" name="limit" ref={this.limitInput} />
                    <button
                      type="submit"
                      className="search-icon"
                      onClick={this.handleQuickSearch}
                    />
                  </form>
                </div>
                <div className="filter-group reset-filter">
                  <button onClick={this.resetFilter}>Reset filters</button>
                </div>
                <div className="filter-group">
                  <button
                    className="submit"
                    type="submit"
                    onClick={this.submitForm}
                  >
                    Apply filter
                  </button>
                </div>
              </div>
            </div>
            <div className="table-wrapper">
              <div className="mini-summary">
                <ul>
                  <li>
                    <span> Closing balance: </span>
                    <span>
                      {new Intl.NumberFormat('en-IN', {}).format(
                        denominationList
                          .reduce((a, b) => a + (b['amount'] || 0), 0)
                          .toFixed(2) || 0,
                      )}
                    </span>
                  </li>
                </ul>
              </div>
              {(() => {
                if (this.state.loading) {
                  return <DropLoaderLarge />;
                } else {
                  return (
                    <TableGrid
                      col={col}
                      row={denominationList}
                      offset={this.state.offset}
                      count={this.state.count}
                      limit={this.state.limit}
                      handlePagination={this.handlePagination}
                      onPay={(cashier_duty_id, sortOrder) =>
                        this.showDenomination(cashier_duty_id, sortOrder)
                      }
                      enabledActions={enabledActions}
                      exportOptions={{
                        fileName: `Denomination  - ${this.state.date}.pdf`,
                        reportName: 'Denomination Report',
                        reportDate: `Date - ${dayjs(this.state.date).format('D MMM YY')} `,
                        reportTotal: `Total amount -  ${denominationList
                            .reduce((a, b) => a + (b['amount'] || 0), 0)
                            .toFixed(2) || 0
                          }`,
                        reportQuantity: '',
                        reportSales: '',
                        reportSaleTotal: '',
                        reportTCSTotal: '',
                        reportTesting: '',
                        reportBowser: '',
                        reportCustomerName: '',
                        reportGst: '',
                        reportOpeningBalance: '',
                        reportClosingBalance: '',
                      }}
                    />
                  );
                }
              })()}
            </div>
          </main>
        </section>
        <Modal isOpen={this.state.showModal} onRequestClose={this.closeModal}>
          <div className="modal-component">
            <EditCashSummary
              cashier_duty_id={this.state.cashier_duty_id}
              cash_code={this.state.sortOrder}
              onRequestClose={this.closeModal}
              showModal={this.state.showModal}
            />
          </div>
        </Modal>
      </>
    );
  }
}

export { DenominationReport };
