import React from 'react';

import { authService } from '@S';

const AccessControl = ({ allowedRoles, children }) => {
  const currentUserRole = authService.currentUserRole;
  if (allowedRoles.includes(currentUserRole)) {
    return <> {children} </>;
  }
  return null;
};

export { AccessControl };
