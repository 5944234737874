import React, { Component } from 'react';

export class BusinessSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      summary: this.props.summary,
    };
  }

  render() {
    const summary = this.props.summary;

    return (
      <>
        <section className="widget dsr-table">
          <header className="title-wrapper"></header>
          <main className="content-wrapper">
            <table className="dsr" data-name="Business Summary">
              <thead>
                <tr data-header={true}>
                  <th className="alert-brand" colSpan="2">
                    <label className="printCheckbox">
                      <input
                        type="checkbox"
                        name="enablePrint"
                        defaultChecked="true"
                      />
                    </label>
                    Total business summary
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Total fuel sales</td>
                  <td>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(summary.fuel_sale || 0)}
                  </td>
                </tr>
                <tr>
                  <td>Total Lubes sales</td>
                  <td>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(summary.lube_sale || 0)}
                  </td>
                </tr>
                <tr>
                  <td>Other income</td>
                  <td>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(summary.other_income || 0)}
                  </td>
                </tr>
                <tr>
                  <td>Total credit collection</td>
                  <td>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(summary.payment_wise.credit_collection || 0)}
                  </td>
                </tr>
                <tr className="summary-row">
                  <td colSpan="1">Total </td>
                  <td className="">
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      summary.fuel_sale +
                        summary.lube_sale +
                        summary.other_income +
                        summary.payment_wise.credit_collection || 0,
                    )}
                  </td>
                </tr>
                <tr className="table-break" />
                <tr>
                  <td>Total cash sales</td>
                  <td>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(summary.payment_wise.cash_sale || 0)}
                  </td>
                </tr>
                <tr>
                  <td>Total credit sales</td>
                  <td>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(summary.credit_slip_sale || 0)}
                  </td>
                </tr>
                <tr>
                  <td>Other expenses</td>
                  <td>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(summary.other_expenses || 0)}
                  </td>
                </tr>
              </tbody>
            </table>
            <table border="0" className="dsr" data-name="POS Summary">
              <thead>
                <tr data-header={true}>
                  <th className="alert-brand" colSpan="2">
                    <label className="printCheckbox">
                      <input
                        type="checkbox"
                        name="enablePrint"
                        defaultChecked="true"
                      />
                    </label>
                    Total POS sales
                  </th>
                </tr>
              </thead>
              <tbody>
                {summary.payment_wise.cards.map((item, i) => (
                  <tr key={i}>
                    <td>{item.name}</td>
                    <td>
                      {new Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        currency: 'INR',
                      }).format(item.total_balance || 0)}
                    </td>
                  </tr>
                ))}
                <tr className="summary-row">
                  <td>Total </td>
                  <td className="">
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      summary.payment_wise.cards.reduce(
                        (a, b) => a + (b['total_balance'] || 0),
                        0,
                      ) || 0,
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="dsr" data-name="Wallet Summary">
              <thead data-header={true}>
                <tr>
                  <th className="alert-brand" colSpan="2">
                    <label className="printCheckbox">
                      <input
                        type="checkbox"
                        name="enablePrint"
                        defaultChecked="true"
                      />
                    </label>
                    Total wallet sales
                  </th>
                </tr>
              </thead>
              <tbody>
                {summary.payment_wise.wallets.map((item, i) => (
                  <tr key={i}>
                    <td>
                      {item.bank_name} - {item.settlement_bank}(
                      {item.account_type}){' '}
                    </td>
                    <td>
                      {new Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        currency: 'INR',
                      }).format(item.total_balance || 0)}
                    </td>
                  </tr>
                ))}
                <tr className="summary-row">
                  <td>Total </td>
                  <td className="">
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      summary.payment_wise.wallets.reduce(
                        (a, b) => a + (b['total_balance'] || 0),
                        0,
                      ) || 0,
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="dsr" data-name="Internet Banking Summary">
              <thead>
                <tr data-header={true}>
                  <th className="alert-brand" colSpan="2">
                    <label className="printCheckbox">
                      <input
                        type="checkbox"
                        name="enablePrint"
                        defaultChecked="true"
                      />
                    </label>
                    Internet banking sales
                  </th>
                </tr>
              </thead>
              <tbody>
                {summary.payment_wise.internet_banking.map((item, i) => (
                  <tr key={i}>
                    <td>
                      {item.bank_name} ({item.account_type}){' '}
                    </td>
                    <td>
                      {new Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        currency: 'INR',
                      }).format(item.total_balance || 0)}
                    </td>
                  </tr>
                ))}
                <tr className="summary-row">
                  <td>Total </td>
                  <td className="">
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      summary.payment_wise.internet_banking.reduce(
                        (a, b) => a + (b['total_balance'] || 0),
                        0,
                      ) || 0,
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </main>
        </section>
      </>
    );
  }
}
