import React, { Component } from 'react';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import {
  AccessControl,
  AllPosList,
  BankList,
  SettlementOptions,
} from '@C/Utils';
import { authHeader } from '@H';

import services from './data';

class PosSetup extends Component {
  constructor() {
    super();
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
    this.myRef = React.createRef();
  }

  state = {
    posList: [
      {
        index: Math.random(),
        name: '',
        swipe_or_wallet_uuid: '',
        settlement_bank: '',
        settlement_bank_name: '',
        settlement_options: 'TODAY',
      },
    ],
    idx: 0,
    name: 'Select POS machine',
    swipe_or_wallet_uuid: '',
    settlement_bank: '',
    settlement_bank_name: 'Select Bank',
    settlement_options: 'TODAY',
    updated: false,
    isSubmitting: false,
  };

  handlePOSChange = (e) => {
    const posList = [...this.state.posList];
    posList[this.state.idx]['name'] = e.label;
    posList[this.state.idx]['swipe_or_wallet_uuid'] = e.value;
    this.setState({ swipe_or_wallet_uuid: e.value, name: e.label });
  };

  handleBankChange = (e) => {
    const posList = [...this.state.posList];
    posList[this.state.idx]['settlement_bank'] = e.value;
    posList[this.state.idx]['settlement_bank_name'] = e.label;
    this.setState({ settlement_bank: e.value, settlement_bank_name: e.label });
  };

  handleSettlementChange = (e) => {
    const posList = [...this.state.posList];
    posList[this.state.idx]['settlement_options'] = e.value;
    this.setState({ settlement_options: e.value });
  };

  addItem = (e) => {
    window.scrollTo({
      behavior: 'smooth',
      top: this.myRef.current.offsetTop - 150,
    });
    e.preventDefault();
    this.setState({ isSubmitting: true });
    if (this.validator.allValid()) {
      this.validator.hideMessages();
      this.setState((prevState) => ({
        posList: [
          ...prevState.posList,
          {
            index: Math.random(),
            name: '',
            settlement_bank: '',
            swipe_or_wallet_uuid: '',
            settlement_bank_name: '',
            settlement_options: 'TODAY',
          },
        ],
        idx: this.state.idx + 1,
        name: 'Select POS machine',
        settlement_bank: '',
        swipe_or_wallet_uuid: '',
        settlement_bank_name: 'Select Bank',
        settlement_options: 'TODAY',
        updated: false,
        isSubmitting: false,
      }));
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
  };

  deleteItem = (index) => {
    this.setState({
      posList: this.state.posList.filter((s, sindex) => index !== sindex),
      idx: this.state.idx - 1,
    });
  };

  submitForm = () => {
    this.setState({ isSubmitting: true });
    const posData = this.state.posList;
    const formData = {
      swipe_machine_list: posData.filter((pos) => pos.swipe_or_wallet_uuid),
    };
    const headers = authHeader(1);
    axios
      .post(`${process.env.API_URL}/bunk/map-swipe-machines/`, formData, {
        headers,
      })
      .then(() => {
        toast.success('POS machines added successfully');
        window.scrollTo({
          behavior: 'smooth',
          top: this.myRef.current.offsetTop - 150,
        });
        this.setState({
          posList: [
            {
              index: Math.random(),
              name: 'Select POS machine',
              swipe_or_wallet_uuid: '',
              settlement_bank: '',
              settlement_bank_name: '',
              settlement_options: 'TODAY',
            },
          ],
          idx: 0,
          name: 'Select POS machine',
          swipe_or_wallet_uuid: '',
          settlement_bank: '',
          settlement_bank_name: 'Select Bank',
          settlement_options: 'TODAY',
          updated: !this.state.updated,
          isSubmitting: false,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        this.setState({ isSubmitting: false });
      });
  };

  render() {
    const { posList } = this.state;
    return (
      <>
        <AccessControl allowedRoles={['Dealer']}>
          <section className="widget form-card" ref={this.myRef}>
            <header>
              <h3>Add New POS Machine</h3>
            </header>
            <main>
              <form>
                <div className="input-wrapper with-label">
                  <label>POS</label>
                  {this.validator.message(
                    'tswipe_or_wallet_uuid',
                    this.state.swipe_or_wallet_uuid,
                    'required',
                    { messages: { required: 'Please select a POS machine' } },
                  )}
                  <AllPosList
                    id="swipe_or_wallet_uuid"
                    onChange={this.handlePOSChange}
                    value={{
                      value: this.state.swipe_or_wallet_uuid,
                      label: this.state.name,
                    }}
                  />
                </div>
                <div className="input-wrapper  with-label">
                  <label>Bank</label>
                  {this.validator.message(
                    'settlement_bank',
                    this.state.settlement_bank,
                    'required',
                    { messages: { required: 'Please select a bank account' } },
                  )}
                  <BankList
                    id="settlement_bank"
                    handleBankChange={this.handleBankChange}
                    value={this.state.settlement_bank}
                  />
                </div>
                <div className="input-wrapper with-label">
                  <label>Settlement option</label>
                  {this.validator.message(
                    'settlement_options',
                    this.state.settlement_options,
                    'required',
                  )}
                  <SettlementOptions
                    onChange={this.handleSettlementChange}
                    value={this.state.settlement_options}
                  />
                </div>
                <div className="cta-wrapper">
                  <button
                    className="submit"
                    disabled={this.state.isSubmitting}
                    onClick={this.addItem}
                  >
                    Add
                  </button>
                </div>
              </form>
            </main>
          </section>
          <section className="widget list-card list-card__minimal">
            <header>
              <h3>POS List</h3>
            </header>
            <main>
              <ul>
                {posList.map((item, i) => {
                  if (i < posList.length - 1) {
                    return (
                      <li key={i}>
                        <div className="card-title">
                          <label>Name:</label>
                          <h4>{item.name}</h4>
                        </div>
                        <div className="card-info">
                          <span>
                            <label>Settlement bank:</label>
                            {item.settlement_bank_name}
                          </span>
                        </div>
                        <div className="card-actions">
                          <button
                            className="actions __delete "
                            onClick={() => this.deleteItem(i)}
                          >
                            Delete
                          </button>
                        </div>
                      </li>
                    );
                  }
                })}
              </ul>
              {posList.length > 1 && (
                <div className="cta-wrapper">
                  <button
                    className="submit"
                    type="submit"
                    disabled={this.state.isSubmitting}
                    onClick={this.submitForm}
                  >
                    Submit
                  </button>
                </div>
              )}
            </main>
          </section>
        </AccessControl>
        <ExsitingPOSList key={this.state.updated} />
      </>
    );
  }
}

class ExsitingPOSList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      POSList: [],
    };
  }
  componentDidMount() {
    this.getPOSList();
  }

  getPOSList = () => {
    services
      .getMappedPOSList()
      .then((response) => {
        this.setState({ POSList: response.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  confirmDelete = (id, posName) => {
    confirmAlert({
      title: 'Confirm POS machine deletion',
      message: `Are you sure you want to delete "${posName}"?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteItem(id),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  deleteItem = (id) => {
    const headers = authHeader(1);
    axios
      .delete(
        `${process.env.API_URL}/bunk/map-swipe-machines/?bunk_bank_uuid=${id}`,
        {
          headers,
        },
      )
      .then(() => {
        toast.success('POS machine deleted successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        this.getPOSList();
      });
  };

  render() {
    const { POSList } = this.state;
    return (
      <section className="widget list-card list-card__minimal">
        <header>
          <h3>Existing POS machines</h3>
        </header>
        <main>
          <ul>
            {POSList.map((item, i) => {
              return (
                <li key={i}>
                  <div className="card-title">
                    <label>Name:</label>
                    <h4>{item.swipe_machine.bank_name}</h4>
                  </div>
                  <div className="card-info">
                    <span>
                      <label>Settlement bank:</label>
                      {item.settlement_bank.settlement_bank_name}
                    </span>
                  </div>
                  <div className="card-info">
                    <span>
                      <label>Pos name:</label>
                      {item.name}{' '}
                    </span>
                  </div>
                  <div className="card-info">
                    <span>
                      <label>Settlement day:</label>
                      {item.settlement_type}
                    </span>
                  </div>
                  <div className="card-actions">
                    <AccessControl allowedRoles={['Dealer']}>
                      <button
                        className="actions __delete "
                        onClick={() =>
                          this.confirmDelete(
                            item.bunk_card_uuid,
                            item.swipe_machine.bank_name,
                          )
                        }
                      >
                        Delete
                      </button>
                    </AccessControl>
                  </div>
                </li>
              );
            })}
          </ul>
        </main>
      </section>
    );
  }
}
export { PosSetup };
