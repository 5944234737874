import React, { Component } from 'react';

import { Header, Navbar } from '@C';
import ErrorBoundary from '@C/Error';
import { RouteSetup } from '@C/ReferralProgram';
import ReferralList from '@C/ReferralProgram/RouteComponents/ReferralProgram/ReferralList';
import { authService } from '@S';

class ReferralProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const role = authService.currentUserRole;
    return (
      <main>
        <div className="page-wrapper">
          <Header title="Referral program" />
          <Navbar />
          <div className="page-content">
            <section className="page-components">
              <div className="page-title">
                <h1>Referral Program </h1>
              </div>
              <ErrorBoundary>
                {(role === 'Dealer' || role === 'Manager') && <RouteSetup />}
                {role === 'Site Admin' && <ReferralList />}
              </ErrorBoundary>
            </section>
          </div>
        </div>
      </main>
    );
  }
}

export { ReferralProgram };
