import React, { Component } from 'react';
import dayjs from 'dayjs';

import WithdrawalList from '@C/Banking/RouteComponents/WithdrawalFromBunk/WithdrawalList';
import { BankList, StaffList } from '@C/Utils';

class WithdrawalFromBunk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time_stamp: dayjs().format('YYYY-MM-DD'),
      bank_id: '',
      staff_id: '',
    };
  }

  changeDate = (e) => {
    this.setState({ time_stamp: e.target.value });
  };
  handleBankChange = (e) => {
    this.setState({ bank_id: e?.value || '' });
  };
  handleStaffChange = (e) => {
    this.setState({ staff_id: e?.value || '' });
  };

  render() {
    return (
      <>
        <section className="widget full-width">
          <header>
            <h3> Add Cash Withdrawal From Bunk</h3>
          </header>
          <main className="sub-wrapper">
            <form className="inline">
              <div className="input-wrapper with-label ">
                <label> Date:</label>
                <input
                  type="date"
                  placeholder="Date"
                  name="selectDate"
                  onChange={this.changeDate}
                  value={this.state.time_stamp}
                />
              </div>
              <div className="input-wrapper with-label ">
                <label> Customer name: </label>
                <input
                  type="text"
                  placeholder="Customer name"
                  onChange={this.handleChange}
                  value={this.state.customer_name}
                  name="customer_name"
                />
              </div>
              <div className="input-wrapper with-label ">
                <label> Amount taken in cash: </label>
                <input
                  type="text"
                  placeholder="Taken amount"
                  onChange={this.handleChange}
                  value={this.state.taken_amount}
                  name="taken_amount"
                />
              </div>
              <div className="input-wrapper with-label ">
                <label> Transaction mode: </label>
                <input
                  type="text"
                  placeholder="Transaction mode"
                  onChange={this.handleChange}
                  value={this.state.transaction_mode}
                  name="transaction_mode"
                />
              </div>
              <div className="input-wrapper with-label ">
                <label>Ref no: </label>
                <input
                  type="text"
                  placeholder="ref no"
                  onChange={this.handleChange}
                  value={this.state.ref_no}
                  name="ref_no"
                />
              </div>
              <div className="input-wrapper with-label">
                <label> Bank name</label>
                <BankList
                  id="settlement_bank"
                  handleBankChange={this.handleBankChange}
                  value={this.state.bank_id}
                />
              </div>
              <div className="input-wrapper with-label">
                <label> staff name</label>
                <StaffList
                  value={this.state.staff_id}
                  onChange={this.handleStaffChange}
                />
              </div>
              <div className="form-actions">
                <button className="submit" onClick={this.submitForm}>
                  Submit
                </button>
                <button className="red-btn" onClick={this.cancelEdit}>
                  Cancel
                </button>
              </div>
            </form>
          </main>
        </section>
        <WithdrawalList key={this.state.updated} />
      </>
    );
  }
}

export { WithdrawalFromBunk };
