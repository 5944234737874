/* eslint-disable camelcase */
import React from 'react';
import dayjs from 'dayjs';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AssignDuty, EndDuty } from './RouteComponents';

const RouteSetup = () => {
  return (
    <Switch>
      <Route
        path="/cashiers-on-duty/assign-duty/:date"
        component={AssignDuty}
      />
      <Route
        path="/cashiers-on-duty/end-duty/:duty/:date/:staff/:mode"
        component={EndDuty}
      />
      <Route path="/cashiers-on-duty/">
        <Redirect
          to={`/cashiers-on-duty/assign-duty/${dayjs().format('YYYY-MM-DD')}`}
        />
      </Route>
    </Switch>
  );
};

export { RouteSetup };
