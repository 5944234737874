import React from 'react';

import { Header, Navbar, NavPills } from '@C';
import { RouteSetup } from '@C/DipReading';
import ErrorBoundary from '@C/Error';

const routes = [
  {
    name: 'Dip Reading',
    path: '/account/dip-reading/reading',
  },
  {
    name: 'Summary',
    path: '/account/dip-reading/summary',
  },
  {
    name: 'Summary Report',
    path: '/account/dip-reading/summary-report',
  },
  {
    name: 'Dip Chart',
    path: '/account/dip-reading/dip-chart',
  },
  // {
  //   name: 'Dip Calibration Chart',
  //   path: '/account/dip-reading/dip-calibration-chart',
  // },
];

const DipReading = () => {
  return (
    <main>
      <div className="page-wrapper">
        <Header title="Dip Reading" />
        <Navbar />
        <div className="page-content">
          <section className="page-components">
            <div className="page-title">
              <h1>Dip Reading</h1>
            </div>

            <ErrorBoundary>
              <NavPills routes={routes} />
            </ErrorBoundary>
            <ErrorBoundary>
              <RouteSetup />
            </ErrorBoundary>
          </section>
        </div>
      </div>
    </main>
  );
};

export { DipReading };
