import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import services from '@C/DipReading/_utils/data';
import { ToggleSwitch } from '@C/FormElements';
import { StaffList, TankList, TankSvg } from '@C/Utils';
import { calculateSVGTransformY } from '@C/Utils/calculateSVGTransformY';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { authHeader } from '@H';

import './styles.scoped.scss';

class DipReadingSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time_stamp: dayjs().format('YYYY-MM-DD'),
      tank_id: '',
      tankList: [],
      tankInfo: {},
      loading: true,
      is_dip_in_cm: false,
      staff_id: '',
      duty_id: '',
      dip_uuid: '',
      disabled: this.props.disabled ? this.props.disabled : false,
      isSubmitting: false,
    };
    this.myRef = React.createRef();
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
  }

  handleDate = (e) => {
    this.setState({ time_stamp: e.target.value, staff_id: null }, function () {
      this.getTankInfo();
    });
  };

  handleTankChange = (e) => {
    this.setState({ tank_id: e.value }, function () {
      this.getTankInfo();
    });
  };

  handleStaffChange = (e) => {
    this.setState({ staff_id: e.value });
  };

  componentDidMount() {
    this.getTankList();
  }

  getTankInfo = () => {
    services
      .getTankInfo(this.state.time_stamp, this.state.tank_id)
      .then((response) => {
        this.setState({ tankInfo: response.data.data, loading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getTankList = () => {
    services
      .getTankList()
      .then((response) => {
        const tmp = response.data.data;
        const CategoryOptions = [];
        for (let i = 0; i < tmp.length; i++) {
          CategoryOptions.push({
            value: tmp[i].uuid,
            label: tmp[i].name,
          });
        }
        this.setState(
          {
            tankList: CategoryOptions,
            tank_id: CategoryOptions[0].value,
          },
          function () {
            this.getTankInfo();
          },
        );
      })

      .catch((error) => {
        console.log(error);
      });
  };

  handleInput = (e) => {
    const list = this.state.tankInfo;
    list[e.target.name] = e.target.value;
    this.setState({ tankInfo: list });
  };

  changeToggle = () => {
    this.setState({ is_dip_in_cm: !this.state.is_dip_in_cm });
  };

  handleSubmit = () => {
    this.setState({ isSubmitting: true });
    const { tankInfo, is_dip_in_cm } = this.state;
    if (
      !is_dip_in_cm &&
      parseFloat(tankInfo.closing_stock) >
        parseFloat(tankInfo.tank.tank_capacity)
    ) {
      toast.error('Closing stock should not be more than tank capacity');
      this.setState({ isSubmitting: false });
      return;
    }

    if (
      is_dip_in_cm &&
      parseFloat(tankInfo.closing_stock_in_cm) >
        parseFloat(tankInfo.tank.tank_capacity)
    ) {
      toast.error('Closing stock should not be more than tank capacity');
      this.setState({ isSubmitting: false });
      return;
    }
    const formData = {
      time_stamp: this.state.time_stamp,
      staff_id: this.state.staff_id,
      // is_dip_in_cm: this.state.is_dip_in_cm,
      tank_list: [
        {
          dip_uuid: this.state.tankInfo.dip_uuid || null,
          tank_id: this.state.tankInfo.tank.uuid,
          is_dip_in_cm: this.state.is_dip_in_cm,
          closing_reading: parseFloat(this.state.tankInfo.closing_stock),
          dip_closing_reading_in_cm: parseFloat(
            this.state.tankInfo.closing_stock_in_cm,
          ),
          testing_litters_return: parseFloat(
            this.state.tankInfo.testing_litters_return,
          ),
        },
      ],
    };
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({ isSubmitting: false });
      return;
    }
    let method = 'post';
    if (this.state.tankInfo.dip_uuid) {
      method = 'put';
    }
    const headers = authHeader(1);
    axios({
      method: method,
      url: `${process.env.API_URL}/bunk/dip-readings/`,
      data: formData,
      headers: headers,
    })
      .then((response) => {
        toast.success('Dip reading updated successfully');
        window.scrollTo({
          behavior: 'smooth',
          top: this.myRef.current.offsetTop - 150,
        });
        this.getTankInfo();
        this.validator.hideMessages();
        this.setState({ isSubmitting: false });
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        this.validator.hideMessages();
        this.setState({ isSubmitting: false });
      });
  };

  render() {
    const { tankInfo } = this.state;
    if (this.state.loading) {
      return <DropLoaderLarge />;
    }
    return (
      <>
        <section className="widget single-card tank-card" ref={this.myRef}>
          <header>
            <h3>{tankInfo.tank.name}</h3>
            <div className="actions">
              <div className="input-wrapper no-margin">
                <TankList
                  onChange={this.handleTankChange}
                  value={this.state.tank_id}
                  options={this.state.tankList}
                />
              </div>
            </div>
          </header>
          <main>
            <div className="card-content">
              <div className="tank-bg">
                {(() => {
                  const a = calculateSVGTransformY(
                    tankInfo.opening_stock,
                    tankInfo.tank.tank_capacity,
                  );
                  return <TankSvg transformY={a} />;
                })()}
              </div>
              <div className="tank-capacity">
                <span>
                  {`${tankInfo.opening_stock.toFixed(
                    2,
                  )} L / ${tankInfo.tank.tank_capacity.toFixed(2)} L`}
                </span>
              </div>
            </div>
          </main>
        </section>
        <section className="widget form-table">
          <header>
            <h3>Tank Details</h3>
          </header>
          <main>
            <div className="form-header">
              <div className="input-wrapper">
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.time_stamp}
                  onChange={this.handleDate}
                />
              </div>
              <div className="input-wrapper">
                {this.validator.message(
                  'staff_id',
                  this.state.staff_id,
                  'required',
                )}
                <StaffList
                  onChange={this.handleStaffChange}
                  value={this.state.staff_id}
                  key={this.state.time_stamp}
                  staffType={['Cashiers', 'Managers', 'Dealer']}
                />
              </div>
            </div>
            <div className="form-content">
              <table>
                <tbody>
                  <tr>
                    <th>Closing stock</th>
                    <td className="colspan">
                      <div className="input-wrapper with-label no-margin">
                        <label>
                          {!this.state.is_dip_in_cm ? 'Liters' : "Cm's"}
                        </label>
                        {this.validator.message(
                          'closing_stock',
                          this.state.is_dip_in_cm
                            ? tankInfo.closing_stock_in_cm
                            : tankInfo.closing_stock,
                          'required',
                        )}
                        <input
                          type="text"
                          name={
                            this.state.is_dip_in_cm
                              ? 'closing_stock_in_cm'
                              : 'closing_stock'
                          }
                          value={
                            this.state.is_dip_in_cm
                              ? tankInfo.closing_stock_in_cm
                              : tankInfo.closing_stock
                          }
                          onChange={this.handleInput}
                        />
                      </div>
                      <div className="input-wrapper  toggle-element">
                        <ToggleSwitch
                          did={0}
                          id="is_dip_in_cm"
                          name="is_dip_in_cm"
                          optionLabels={["Cm's", 'Liters']}
                          checked={this.state.is_dip_in_cm}
                          onChange={this.changeToggle}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Testing liters returned</th>
                    <td>
                      <div className="input-wrapper no-margin">
                        <input
                          type="text"
                          name="testing_litters_return"
                          value={tankInfo.testing_litters_return}
                          onChange={this.handleInput}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Opening stock </th>
                    <td>{tankInfo.opening_stock.toFixed(2)}</td>
                  </tr>

                  <tr>
                    <th>Purchase </th>
                    <td>{tankInfo.purchase.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <th>Sales</th>
                    <td>{tankInfo.total_sale.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <th>Fuel transferred to bowser </th>
                    <td>{tankInfo.fuel_transferred_to_bowser.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <th>Bowser Filling </th>
                    <td>{tankInfo.bowser_litters.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <th> Testing Liters Taken</th>
                    <td>{tankInfo.testing_litters.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="form-actions">
              <button
                className="submit"
                type="submit"
                disabled={this.state.isSubmitting}
                onClick={this.handleSubmit}
              >
                Submit
              </button>
            </div>
          </main>
        </section>
      </>
    );
  }
}
export { DipReadingSummary };
