import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { useGlobalStore } from '@stores/globalStore';

const ProductList = (props) => {
  const productListOptions = useGlobalStore(
    (state) => state.productListOptions,
  );

  const handleChange = (selectedOption) => {
    const id = props.did ? props.did : 0;
    props.onProductChange(selectedOption, id);
  };

  return (
    <Select
      onChange={handleChange}
      isClearable={true}
      options={productListOptions}
      className="select-box-container product-selector-container"
      classNamePrefix="product-selector"
      placeholder="Select product"
      value={productListOptions.filter(({ value }) => value === props.value)}
    />
  );
};

const DynamicProductList = (props) => {
  const [productListOptions, setProductListOptions] = useState(props.options);
  const cod = props.cod ? props.cod : false;
  useEffect(() => {
    if (props.options.length > 0) {
      // remove fuel products if cod is false
      if (!cod) {
        // if product_type = fuel, add isDisabled: true
        const newOptions = props.options.map((option) => {
          if (option.product_type === 'Fuel') {
            return { ...option, isDisabled: true };
          } else {
            return option;
          }
        });

        setProductListOptions(newOptions);
      } else {
        setProductListOptions(props.options);
      }
    }
  }, [props.options]);

  const handleChange = (selectedOption) => {
    const id = props.did ? props.did : 0;
    let option = selectedOption;
    if (selectedOption == null) {
      option = { value: '', label: '', price: 0, product_type: '', stock: '' };
    }
    props.onProductChange(option, id);
  };

  return (
    <Select
      onChange={handleChange}
      isClearable={true}
      options={productListOptions}
      className="select-box-container product-selector-container"
      classNamePrefix="product-selector"
      placeholder="Select product"
      value={productListOptions.filter(({ value }) => value === props.value)}
      isDisabled={props.disabled || false}
    />
  );
};

export { DynamicProductList, ProductList };
