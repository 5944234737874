import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';

import services from '@C/Banking/_utils/data';
import { formatCurrency, TableGrid } from '@C/TableGrid';
import { BankList } from '@C/Utils';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { authHeader } from '@H';

const col = [
  {
    name: 'Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Deposit to bank name',
    key: 'bank.bank_name',
  },
  {
    name: 'Amount',
    key: 'all',
    formatter(props) {
      return <>{formatCurrency(props.after_tax)}</>;
    },
  },
  // {
  //   name: 'Staff name',
  //   key: 'cashier',
  //   formatter(props) {
  //     return (
  //       <>
  //         {props.first_name} {props.last_name}
  //       </>
  //     );
  //   },
  // },
  {
    name: 'Notes',
    key: 'notes',
  },
];

const enabledActions = [
  {
    action: 'Delete',
    roles: ['Manager', 'Dealer'],
  },
];

class CashDeposit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cashDepositList: [],
      start_date: this.props.cod
        ? this.props.date
        : dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: this.props.cod ? this.props.date : dayjs().format('YYYY-MM-DD'),
      loading: true,
      bank_uuid: '',
      limit: 20,
      offset: 1,
      count: 0,
    };
    this.limitInput = React.createRef();
  }

  componentDidMount() {
    this.getCashDepositList();
  }

  getCashDepositList = () => {
    services
      .getCashDepositList(this.state)
      .then((response) => {
        this.setState({
          cashDepositList: response.data.data.results,
          loading: false,
          count: response.data.data.count,
        });
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  };
  handleLimitChange = (e) => {
    this.setState({ limit: e.target.value });
  };
  handlePagination = (e) => {
    this.setState({ offset: e, loading: true }, function () {
      this.getCashDepositList();
    });
  };
  handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date' && dayjs(value).isAfter(this.state.end_date)) {
      this.setState({ [name]: value, end_date: value });
    } else if (
      name === 'end_date' &&
      dayjs(value).isBefore(this.state.start_date)
    ) {
      this.setState({ [name]: value, start_date: value });
    } else {
      this.setState({ [name]: value });
    }
  };
  handleBankChange = (e) => {
    this.setState({ bank_uuid: e?.value || '' });
  };

  resetFilter = () => {
    this.setState(
      {
        start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
        loading: true,
        bank_uuid: '',
      },
      function () {
        this.getCashDepositList();
      },
    );
  };
  handleQuickSearch = (e) => {
    e.preventDefault();
    if (
      this.limitInput.current.value === '' ||
      this.limitInput.current.value === '0'
    ) {
      return;
    }
    this.setState(
      { limit: this.limitInput.current.value, offset: 1 },
      function () {
        this.getCashDepositList();
      },
    );
  };

  confirmDelete = (item) => {
    confirmAlert({
      title: 'Confirm expense deletion',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteItem(item),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  deleteItem = (item) => {
    const headers = authHeader(1);
    axios
      .delete(
        `${process.env.API_URL}/payment/cash-deposit/?transaction_id=${item.uuid}`,
        {
          headers,
        },
      )
      .then(() => {
        toast.success('Cash deposit to bank deleted successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        this.getCashDepositList();
      });
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, function () {
      this.getCashDepositList();
    });
  };
  render() {
    const { cashDepositList } = this.state;
    return (
      <section
        className={`widget full-width list-table ${this.props.cod ? 'inverted' : ''}`}
      >
        <header>
          <h3> Cash deposit to bank </h3>
        </header>
        <main>
          {!this.props.cod && (
            <div className="table-filters">
              <div className="filter-wrapper">
                <div className="filter-group with-icon">
                  <label>
                    Results to display-{this.state.limit || 20} (Total:{' '}
                    {this.state.count})
                  </label>
                  <form onSubmit={this.handleQuickSearch}>
                    <input type="text" name="limit" ref={this.limitInput} />
                    <button
                      type="submit"
                      className="search-icon"
                      onClick={this.handleQuickSearch}
                    />
                  </form>
                </div>
                <div className="filter-group">
                  <label>From date:</label>
                  <input
                    className="cstm-date-input"
                    type="date"
                    value={this.state.start_date}
                    name="start_date"
                    onChange={this.handleDateChange}
                  />
                </div>
                <div className="filter-group">
                  <label>To date:</label>
                  <input
                    className="cstm-date-input"
                    type="date"
                    value={this.state.end_date}
                    onChange={this.handleDateChange}
                    name="end_date"
                  />
                </div>
                <div className="filter-group">
                  <label>Bank name:</label>
                  <BankList
                    id="settlement_bank"
                    handleBankChange={this.handleBankChange}
                    value={this.state.bank_uuid}
                  />
                </div>

                <div className="filter-group reset-filter">
                  <button onClick={this.resetFilter}>Reset filters</button>
                </div>
                <div className="filter-group">
                  <button
                    className="submit"
                    type="submit"
                    onClick={this.submitForm}
                  >
                    Apply filter
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="table-wrapper">
            {(() => {
              if (this.state.loading) {
                return <DropLoaderLarge />;
              } else {
                return (
                  <>
                    <TableGrid
                      col={col}
                      row={cashDepositList}
                      offset={this.state.offset}
                      count={this.state.count}
                      limit={this.state.limit}
                      handlePagination={this.handlePagination}
                      onDelete={this.confirmDelete}
                      enabledActions={enabledActions}
                    />
                  </>
                );
              }
            })()}
          </div>
        </main>
      </section>
    );
  }
}

export { CashDeposit };
