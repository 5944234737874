import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import { ToggleSwitch } from '@C/FormElements';
import services from '@C/Utils/data';
import { authHeader } from '@H';

import './styles.scoped.scss';

class DashboardFuelPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: new Date(),
      fuelList: [],
      edit: false,
      updated: false,
      date: this.props.date ? this.props.date : dayjs().format('YYYY-MM-DD'),
      disabled: this.props.disabled ? this.props.disabled : false,
      duty_price_change: this.props.duty_price_change || false,
      duty_price_change_done: false,
      enable_duty_price_change: false,
      isSubmitting: false,
    };
  }

  componentDidMount() {
    this.getFuelPrice();
  }

  getFuelPrice = () => {
    services
      .getFuelWithPrice(this.state.date)
      .then((response) => {
        this.setState({
          fuelList: response.data.data.fuel_prices,
          updated: response.data.data.is_all_product_prices_updated,
          duty_price_change_done:
            response.data.data.fuel_prices[0].is_price_updated_today,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  toggleDutyPriceChange = () => {
    this.setState({
      enable_duty_price_change: !this.state.enable_duty_price_change,
    });
  };

  handleChange = (e, i) => {
    const fuelList = [...this.state.fuelList];
    fuelList[i][e.target.name] = e.target.value;
    this.setState({ fuelList: fuelList });
  };

  handleCopy = (event) => {
    event.preventDefault();
    let fuelList = [...this.state.fuelList];
    fuelList = fuelList.map((o) =>
      Object.assign(o, { price: o.previous_price }),
    );
    this.setState({ fuelList: fuelList });
  };
  handleCancel = () => {
    this.setState({ edit: !this.state.edit });
  };

  updatePrice = (e) => {
    e.preventDefault();
    if (!this.state.edit) {
      this.setState({ edit: !this.state.edit });
    } else if (
      !this.state.updated ||
      this.state.duty_price_change ||
      this.state.enable_duty_price_change
    ) {
      const tmp = this.state.fuelList.map((item) => {
        return {
          product_uuid: item.product_uuid,
          price: parseFloat(item.price),
          datetime_stamp: this.state.date,
        };
      });
      this.setState({ isSubmitting: true });
      const formData = {
        product_list: tmp,
      };
      const headers = authHeader(1);
      axios
        .post(`${process.env.API_URL}/bunk/fuel-prices/`, formData, { headers })
        .then(() => {
          toast.success('Price updated successfully', {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          this.getFuelPrice();
          if (this.props.onRequestClose) {
            this.props.onRequestClose();
          }
        })
        .catch((e) => {
          console.log(e);
        });
      this.setState({
        edit: !this.state.edit,
        updated: true,
        isSubmitting: false,
      });
    } else {
      const formData = {
        product_list: this.state.fuelList,
      };
      const headers = authHeader(1);
      axios
        .put(`${process.env.API_URL}/bunk/fuel-prices/`, formData, { headers })
        .then((response) => {
          toast.success(response.data.message);
          this.getFuelPrice();
          if (this.props.onRequestClose) {
            this.props.onRequestClose();
          }
        })
        .catch((e) => {
          console.log(e);
          this.setState({ isSubmitting: false });
        });
      this.setState({ edit: !this.state.edit, updated: true });
    }
  };

  handleDate = (e) => {
    this.setState({ date: e.target.value }, function () {
      this.getFuelPrice();
    });
  };

  render() {
    const { fuelList } = this.state;

    return (
      <>
        <section className="widget form-table">
          <header>
            <h3>Fuel Price</h3>
          </header>
          <main>
            {!this.state.updated && (
              <div className="notification-alert notification-alert__error">
                Fuel price not updated for the selected date
              </div>
            )}

            {this.state.duty_price_change_done && (
              <div className="notification-alert notification-alert__success">
                Duty price change done for the selected date.
              </div>
            )}

            <form onSubmit={this.onUpdate}>
              <div className="form-header">
                <div className="input-wrapper no-margin">
                  <input
                    className="cstm-date-input"
                    type="date"
                    value={this.state.date}
                    onChange={this.handleDate}
                    disabled={this.state.disabled}
                  />
                </div>
                {(() => {
                  if (this.state.edit) {
                    return (
                      <>
                        <button
                          className="btn-with-icon btn-with-icon__copy white-btn"
                          type="button"
                          onClick={() => this.handleCopy(event)}
                        >
                          Copy price
                        </button>
                        <button
                          className="btn-with-icon btn-with-icon__cancel red-btn"
                          onClick={() => this.handleCancel()}
                        >
                          Cancel
                        </button>
                        <div className="input-wrapper  toggle-element">
                          <span className="label"> Is duty price change:</span>
                          <ToggleSwitch
                            did={0}
                            id="partialP"
                            name="partialP"
                            checked={this.state.enable_duty_price_change}
                            onChange={this.toggleDutyPriceChange}
                            disabled={!this.state.updated}
                          />
                        </div>
                      </>
                    );
                  }
                })()}
                <button
                  type={this.state.edit ? 'button' : 'submit'}
                  className={`btn-with-icon  ${
                    this.state.edit
                      ? 'btn-with-icon__save green-btn'
                      : 'btn-with-icon__edit white-btn'
                  }`}
                  disabled={this.state.isSubmitting}
                  onClick={() => this.updatePrice(event)}
                >
                  {this.state.edit ? 'Update price' : 'Edit price'}
                </button>
              </div>
              <div className="form-content">
                <table>
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Previous price</th>
                      <th>Current price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fuelList.map((item, i) => (
                      <tr key={i}>
                        <td className="item-title">{item.product_name}</td>
                        <td className="item-rate-prev">
                          {item.previous_price}
                        </td>
                        <td className="item-rate">
                          <div className="input-wrapper">
                            <input
                              type="text"
                              disabled={!this.state.edit}
                              value={item.price}
                              name="price"
                              onChange={(event) => this.handleChange(event, i)}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </form>
          </main>
        </section>
      </>
    );
  }
}

export { DashboardFuelPrice };
