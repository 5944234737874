import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  CalibrationChart,
  DipCalibration,
} from '@C/Admin/DipCalibration/RouteComponents';

const RouteSetup = () => {
  return (
    <Switch>
      <Route path="/admin/dip-calibration/list" component={DipCalibration} />
      <Route
        path="/admin/dip-calibration/chart/:uuid"
        component={CalibrationChart}
      />
      <Route path="/admin/dip-calibration*">
        <Redirect to={`/admin/dip-calibration/list`} />
      </Route>
    </Switch>
  );
};

export { RouteSetup };
