import axios from 'axios';

import { authHeader } from '@H';

export default {
  getPackageList: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(),
      url: `${process.env.API_URL}/utils/sms_package_list/?limit=${data.limit}&offset=${data.offset}&pack_id=${data.uuid}`,
    }),
};
