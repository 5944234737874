import axios from 'axios';

import { authHeader } from '@H';

export default {
  getSummaryStatus: (time_stamp, tank_id) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/dip/summary/?date=${time_stamp}&tank_id=${tank_id}`,
    }),

  getTankInfo: (time_stamp, tank_id) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/dip-readings/?date=${time_stamp}&tank_id=${tank_id}`,
    }),
  getTankList: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/associate-tanks/`,
    }),

  getFuelPurchase: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/fuel-purchase/?limit=${data.limit}&offset=${data.offset}&start_date=${data.start_date}&end_date=${data.end_date}&product_id=${data.product_id}&tank_id=${data.tank_id}&invoice_number=${data.invoice_number}`,
    }),

  getFuelPurchaseItem: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/fuel-purchase/?invoice_id=${data}`,
    }),

  getDipSummary: (time_stamp, tank_id) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/dip/summary/?date=${time_stamp}&tank_id=${tank_id}`,
    }),

  getDipSummaryReport: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/dip/summary/report/?tank_id=${data.tank_id}&start_date=${data.start_date}&end_date=${data.end_date}&limit=${data.limit}&offset=${data.offset}&product_id=${data.product_id}`,
    }),
  getDipCalibrationItem: (uuid) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/dip-calibration/?uuid=${uuid}`,
    }),
  getDipCalibrationList: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/dip-calibration/?omc_id=${data.omc_id}&limit=${data.limit}&offset=${data.offset}`,
    }),

  getTankDates: (month, year, tank_id) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/dip-readings-date-list/?month=${month}&year=${year}&tank_id=${tank_id}`,
    }),
};
