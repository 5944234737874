/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';

import { NavPills } from '@C';
import { AssignDuty } from '@C/CashiersOnDuty/RouteComponents';
import ErrorBoundary from '@C/Error';
import { useGlobalStore } from '@stores/globalStore';
import { useShallow } from 'zustand/react/shallow';

import {
  CollectionSummary,
  CreditSaleSummary,
  Expenses,
  FuelSummary,
  LubeSummary,
  OtherIncome,
  TotalSummary,
} from './Steps';

const EndDuty = () => {
  const { setCODGrandTotal, syncCODCollectionData } = useGlobalStore(
    useShallow((state) => ({
      setCODGrandTotal: state.setCODGrandTotal,
      syncCODCollectionData: state.syncCODCollectionData,
    })),
  );

  const { duty, date, mode, staff } = useParams();

  if (!duty || !date || !staff) return null;

  const routes = [
    {
      name: 'Fuel Sales',
      path: `/cashiers-on-duty/end-duty/${duty}/${date}/${staff}/${mode}/fuel-sales`,
    },
    {
      name: 'Lube / Products Sales',
      path: `/cashiers-on-duty/end-duty/${duty}/${date}/${staff}/${mode}/lube-sales`,
    },
    {
      name: 'Credit Sales',
      path: `/cashiers-on-duty/end-duty/${duty}/${date}/${staff}/${mode}/credit-sales`,
    },
    {
      name: 'Other Income',
      path: `/cashiers-on-duty/end-duty/${duty}/${date}/${staff}/${mode}/other-income`,
    },
    {
      name: 'Expenses',
      path: `/cashiers-on-duty/end-duty/${duty}/${date}/${staff}/${mode}/expenses`,
    },
    {
      name: 'Collection Summary',
      path: `/cashiers-on-duty/end-duty/${duty}/${date}/${staff}/${mode}/collection-summary`,
    },
  ];

  useEffect(() => {
    setCODGrandTotal(duty, staff);
    syncCODCollectionData(duty, staff);
  }, []);

  return (
    <>
      <ErrorBoundary>
        <NavPills routes={routes} />
        <TotalSummary date={date} duty={duty} staff={staff} />
      </ErrorBoundary>
      <ErrorBoundary>
        <Switch>
          <Route
            exact
            path="/cashiers-on-duty/end-duty/:duty/:date/:staff/:mode/fuel-sales"
            render={(props) => (
              <FuelSummary
                {...props}
                syncCODCollectionData={syncCODCollectionData}
              />
            )}
          />
          <Route
            exact
            path="/cashiers-on-duty/end-duty/:duty/:date/:staff/:mode/lube-sales"
            render={(props) => (
              <LubeSummary
                {...props}
                syncCODCollectionData={syncCODCollectionData}
              />
            )}
          />
          <Route
            exact
            path="/cashiers-on-duty/end-duty/:duty/:date/:staff/:mode/credit-sales"
            render={(props) => (
              <CreditSaleSummary
                {...props}
                syncCODCollectionData={syncCODCollectionData}
              />
            )}
          />
          <Route
            exact
            path="/cashiers-on-duty/end-duty/:duty/:date/:staff/:mode/other-income"
            render={(props) => (
              <OtherIncome
                {...props}
                syncCODCollectionData={syncCODCollectionData}
              />
            )}
          />
          <Route
            exact
            path="/cashiers-on-duty/end-duty/:duty/:date/:staff/:mode/expenses"
            render={(props) => (
              <Expenses
                {...props}
                syncCODCollectionData={syncCODCollectionData}
              />
            )}
          />
          <Route
            exact
            path="/cashiers-on-duty/end-duty/:duty/:date/:staff/:mode/collection-summary"
            render={(props) => (
              <CollectionSummary
                {...props}
                syncCODCollectionData={syncCODCollectionData}
              />
            )}
          />
          <Route path="*" exact={true} component={AssignDuty} />
        </Switch>
      </ErrorBoundary>
    </>
  );
};

export { EndDuty };
