import React, { Component } from 'react';
import dayjs from 'dayjs';

import { CustomerList } from '@C/CreditCustomers/_utils/';
import services from '@C/Reports/_utils/data';
import { formatCurrency, TableGrid } from '@C/TableGrid';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';

const col = [
  {
    name: 'Customer name',
    key: 'all',
    formatter(props) {
      return <>{`${props.customer_first_name} ${props.customer_last_name}`}</>;
    },
  },
  {
    name: 'Opening balance',
    key: 'all',
    type: 'currency',
    formatter(props) {
      return <>{formatCurrency(props.opening_balance)}</>; 
    },
  },
  {
    name: 'Credit sale',
    key: 'slips',
  },
  {
    name: 'Collection',
    key: 'all',
    type: 'currency',
    formatter(props) {
      return <>{formatCurrency(props.collection)}</>; 
    },
  },
  {
    name: 'Closing Balance',
    key: 'all',
    type: 'currency',
    formatter(props) {
      return <>{formatCurrency(props.closing_balance)}</>; 
    },
  },
];

class CumulativeCreditReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cumulativeList: [],
      start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
      customer_id: '',
      loading: false,
      limit: 20,
      offset: 1,
      count: 0,
    };
    this.limitInput = React.createRef();
  }

  componentDidMount() {
    this.getCumulativeReport();
  }

  getCumulativeReport = () => {
    services
      .getCumulativeReport(this.state)
      .then((response) => {
        this.setState({
          cumulativeList: response.data.data.results,
          count: response.data.data.count,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleCustomerChange = (e) => {
    this.setState({ customer_id: e?.value });
  };

  handleLimitChange = (e) => {
    this.setState({ limit: e.target.value });
  };
  handlePagination = (e) => {
    this.setState({ offset: e }, function () {
      this.getCumulativeReport();
    });
  };

  handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date' && dayjs(value).isAfter(this.state.end_date)) {
      this.setState({ [name]: value, end_date: value });
    } else if (
      name === 'end_date' &&
      dayjs(value).isBefore(this.state.start_date)
    ) {
      this.setState({ [name]: value, start_date: value });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleQuickSearch = (e) => {
    e.preventDefault();
    if (
      this.limitInput.current.value === '' ||
      this.limitInput.current.value === '0'
    ) {
      return;
    }
    this.setState(
      { limit: this.limitInput.current.value, offset: 1 },
      function () {
        this.getCumulativeReport();
      },
    );
  };

  resetFilter = () => {
    this.limitInput.current.value = 20;
    this.setState(
      {
        start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
        loading: true,
        customer_id: '',
        limit: 20,
        offset: 1,
      },
      function () {
        this.getCumulativeReport();
      },
    );
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, function () {
      this.getCumulativeReport();
    });
  };

  render() {
    const { cumulativeList } = this.state;
    const sortedCumulativeList = cumulativeList
      .slice()
      .sort((a, b) =>
        `${a.customer_first_name} ${a.customer_last_name}`.localeCompare(
          `${b.customer_first_name} ${b.customer_last_name}`,
        ),
      );
    return (
      <section className="widget list-table full-width inverted">
        <header>
          <h3> Cumulative Credit Report</h3>
        </header>
        <main>
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group">
                <label>From date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.start_date}
                  name="start_date"
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="filter-group">
                <label>To date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.end_date}
                  min={this.state.start_date}
                  onChange={this.handleDateChange}
                  name="end_date"
                />
              </div>
              <div className="filter-group">
                <label>Customer:</label>
                <CustomerList
                  onCustomerChange={this.handleCustomerChange}
                  value={this.state.customer_id}
                  options={this.props.customers}
                />
              </div>
              <div className="filter-group with-icon">
                <label>
                  Results to display- {this.state.limit || 20} (Total:{' '}
                  {this.state.count})
                </label>
                <form onSubmit={this.handleQuickSearch}>
                  <input type="text" name="limit" ref={this.limitInput} />
                  <button
                    type="submit"
                    className="search-icon"
                    onClick={this.handleQuickSearch}
                  ></button>
                </form>
              </div>
              <div className="filter-group reset-filter">
                <button onClick={this.resetFilter}>Reset filters</button>
              </div>
              <div className="filter-group">
                  <button
                    className="submit"
                    type="submit"
                    onClick={this.submitForm}
                  >
                    Apply filter
                  </button>
                </div>
            </div>
          </div>
          <div className="table-wrapper">
            {(() => {
              if (this.state.loading) {
                return <DropLoaderLarge />;
              } else {
                return (
                  <TableGrid
                    col={col}
                    row={sortedCumulativeList}
                    offset={this.state.offset}
                    count={this.state.count}
                    limit={this.state.limit}
                    handlePagination={this.handlePagination}
                    hideActions={true}
                    exportOptions={{
                      fileName: `Cumulative Credit - ${this.state.start_date} to ${this.state.end_date}.pdf`,
                      reportName: 'Cumulative Credit Report',
                      reportDate: `Date - ${dayjs(this.state.start_date).format('D MMM YY')} to ${dayjs(this.state.end_date).format('D MMM YY')}`,
                      reportTotal: '',
                      reportQuantity: '',
                      reportSales: '',
                      reportTesting: '',
                      reportBowser: '',
                      reportSaleTotal: '',
                      reportTCSTotal: '',
                      reportCustomerName: '',
                      reportGst: '',
                      reportOpeningBalance: '',
                      reportClosingBalance: '',
                    }}
                  />
                );
              }
            })()}
          </div>
        </main>
      </section>
    );
  }
}

export { CumulativeCreditReport };
