import React from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { confirmAlert } from 'react-confirm-alert';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { formatCurrency } from '@C/TableGrid';
import { authHeader } from '@H';
import { authService } from '@S';
import { useGlobalStore } from '@stores/globalStore';
import { useShallow } from 'zustand/react/shallow';

import './styles.scoped.scss';
const TotalSummary = (props) => {
  const duty = props.duty;
  const staff = props.staff;
  const date = props.date;
  const history = useHistory();
  const { mode } = useParams();

  const { codGrandTotal, codTotalCollected, codStaff, codShift, shortage } =
    useGlobalStore(
      useShallow((state) => ({
        codGrandTotal: state.codGrandTotal,
        codTotalCollected: state.codTotalCollected,
        codStaff: state.codStaff,
        codShift: state.codShift,
        shortage: state.shortage,
      })),
    );

  const confirmEndDuty = () => {
    confirmAlert({
      title: `${mode == 'edit' ? 'Confirm changes' : 'Confirm end of duty'}`,
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => endDuty(),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  const endDuty = () => {
    if (mode === 'edit') {
      history.push('/cashiers-on-duty');
      return;
    }
    const formData = {
      uuid: duty,
    };

    const headers = authHeader(1);
    axios
      .put(`${process.env.API_URL}/bunk/cashier-end-duty/`, formData, {
        headers,
      })
      .then((response) => {
        toast.success('Duty ended successfully');
        if (authService.currentUserRole === 'Cashier') {
          history.push(`/dashboard`);
        } else {
          history.push(`/cashiers-on-duty/assign-duty`);
        }
      });
  };

  return (
    <section className="widget full-width summary-widget">
      <header>
        <h3>Summary</h3>
      </header>
      <main>
        <div className="staff-info">
          <span>{codStaff} </span>
          <button className="red-btn" onClick={confirmEndDuty}>
            {mode == 'edit' ? 'Save changes' : 'End duty'}
          </button>
        </div>
        <div className="duty-info">
          <span>{dayjs(date).format('D MMM, YYYY')}</span>
          <span>{codShift}</span>
        </div>
        <div className="summary-info total-summary">
          <span>Grand Total</span>
          <span>{formatCurrency(codGrandTotal)}</span>
        </div>
        <div className="summary-info collection-summary">
          <span>Total Collected</span>
          <span>{formatCurrency(codTotalCollected)}</span>
        </div>
        <div className="summary-info shortage-sumamry">
          <span>Shortage/Excess</span>
          {(() => {
            const s = parseFloat(shortage);
            if (Math.abs(s) < 0.0001) {
              return <span className="--green"> No shortage/excess </span>;
            } else if (s > 0) {
              return <span className="--green"> {formatCurrency(s)}</span>;
            } else {
              return <span className="--red"> {formatCurrency(s)}</span>;
            }
          })()}
        </div>
      </main>
    </section>
  );
};

export { TotalSummary };
