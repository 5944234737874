import React, { Component } from 'react';

import { Header, Navbar } from '@C';
import { InvoiceList } from '@C/CreditCustomerProfile/Invoice';
import ErrorBoundary from '@C/Error';

class InvoicePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <main>
        <div className="page-wrapper">
          <Header title="Create dealer" />
          <Navbar />
          <div className="page-content">
            <section className="page-components">
              <ErrorBoundary>
                <InvoiceList />
              </ErrorBoundary>
            </section>
          </div>
        </div>
      </main>
    );
  }
}

export { InvoicePage };
