import React, { useState } from 'react';
import dayjs from 'dayjs';
import Select from 'react-select';

const TallyExport = () => {
  const [selectedReport, setSelectedReport] = React.useState('');
  const [tallyData, setTallyData] = useState({
    offset: 1,
    count: 0,
    limit: 20,
    from_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    to_date: dayjs().format('YYYY-MM-DD'),
  });

  const filteredOptions = [
    {
      value: 'bank-ledger',
      label: 'Bank Ledger',
    },
    {
      value: 'credit-sales',
      label: 'Credit Sales',
    },
    {
      value: 'credit-customer-ledger',
      label: 'Credit Customer Ledger',
    },
    {
      value: 'expense-report',
      label: 'Expense Report',
    },
    {
      value: 'income-report',
      label: 'Income Report',
    },
    {
      value: 'lubes-sales',
      label: 'Lubes Sales',
    },
    {
      value: 'lubes-purchase',
      label: 'Lubes Purchase',
    },
    {
      value: 'monthly-sales-report',
      label: 'Monthly Sales Report',
    },
    {
      value: 'temporary-credit-ledger',
      label: 'Temporary Credit Ledger',
    },
    {
      value: 'profit-loss-report',
      label: 'Profit & Loss Report',
    },
    {
      value: 'profit-loss-trading-account',
      label: 'Profit & Loss Trading Account',
    },
    {
      value: 'fuel-price',
      label: 'Fuel Price Change Report',
    },
    {
      value: 'fuel-purchase',
      label: 'Fuel Purchase Report',
    },
    {
      value: 'wallet-ledger',
      label: 'Wallet Ledger',
    },
    {
      value: 'POS-ledger',
      label: 'POS Ledger',
    },
    {
      value: 'cashier-shortage',
      label: 'Cashier Shortage Report',
    },
    {
      value: 'cash-book',
      label: 'Cash Book Report',
    },
    {
      value: 'fuels-sales-report',
      label: 'Fuels Sales Report',
    },
    {
      value: 'cash-book-shiftwise',
      label: 'Cash Book Shift Wise Report',
    },
    {
      value: 'lube-price',
      label: 'Lube Price',
    },
    {
      value: 'gst-income-report',
      label: 'GST Income Report',
    },
    {
      value: 'gst-expense-report',
      label: 'GST Expense Report',
    },
    {
      value: 'gstr-3b-report',
      label: 'GSTR 3B Report',
    },
    {
      value: 'sales-summary',
      label: 'Sales Summary Report',
    },
    {
      value: 'denomination-report',
      label: 'Denomination Report',
    },
    {
      value: 'business-summary-report',
      label: 'Business Summary Report',
    },
    {
      value: 'balance-sheet-report',
      label: 'Balance Sheet Report',
    },
    {
      value: 'vendor-payment-report',
      label: 'Vendor Payment Report',
    },
  ];

  const handleChange = (e) => {
    setSelectedReport(e.value);
  };
  const handleDateChange = (e) => {
    setTallyData({ ...tallyData, [e.target.name]: e.target.value });
  };

  const export2excel = () => {};

  const export2xml = () => {};

  return (
    <>
      <section className="widget full-width list-table ">
        <header>
          <h3> Select Report </h3>{' '}
        </header>{' '}
        <main>
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group">
                <label>From date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={tallyData.from_date}
                  name="from_date"
                  onChange={handleDateChange}
                />
              </div>
              <div className="filter-group">
                <label>To date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={tallyData.to_date}
                  onChange={handleDateChange}
                  name="to_date"
                />
              </div>
              <div className="filter-group">
                <label> Select Report </label>
                <Select
                  onChange={handleChange}
                  options={filteredOptions}
                  className="select-box-container report-selector"
                  placeholder="Select report"
                  value={filteredOptions.filter(
                    ({ value }) => value === selectedReport,
                  )}
                />{' '}
              </div>
              <div className="filter-group reset-filter ">
                <button onClick={export2xml}>XML</button>
              </div>
              <div className="filter-group reset-filter">
                <button onClick={export2excel}>Excel</button>
              </div>
            </div>
          </div>
        </main>
      </section>
    </>
  );
};

export { TallyExport };
