import React, { Component } from 'react';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';

import services from '@C/CreditCustomers/_utils/data';
import { TableGrid } from '@C/TableGrid';
import { authHeader } from '@H';

const col = [
  {
    name: 'Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Quote no.',
    key: 'quotation_no',
  },

  {
    name: 'Customer name',
    key: 'customer',
    formatter(props) {
      return <>{props.name}</>;
    },
  },
  {
    name: 'Notes',
    key: 'notes',
  },

  {
    name: 'Status',
    key: 'status',
  },
];

const enabledActions = [
  {
    action: 'Delete',
    roles: ['Manager', 'Dealer'],
  },
  {
    action: 'Edit',
    roles: ['Manager', 'Dealer'],
  },
];

export default class GenerateQuotationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      generateQuotationList: [],
      offset: 1,
      count: 0,
      limit: 20,
    };
  }

  componentDidMount() {
    this.getQuotation();
  }

  getQuotation = () => {
    services
      .getQuotation('')
      .then((response) => {
        this.setState({
          generateQuotationList: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  confirmDelete = (item) => {
    confirmAlert({
      title: 'Confirm quotation deletion',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteItem(item),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  deleteItem = (item) => {
    const headers = authHeader(1);
    axios
      .delete(
        `${process.env.API_URL}/credit-customer/bunk/quotation/?quotation_id=${item.uuid}`,
        {
          headers,
        },
      )
      .then(() => {
        toast.success('Quotation deleted successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        this.getQuotation();
      });
  };

  editItem = (item) => {
    this.props.onEdit(item.uuid);
  };

  render() {
    const { generateQuotationList } = this.state;

    return (
      <>
        <section className="widget full-width">
          <header>
            <h3> All Quotations </h3>
          </header>
          <main className="content-wrapper">
            <TableGrid
              col={col}
              row={generateQuotationList}
              count={this.state.count}
              onEdit={this.props.onEdit}
              onDelete={this.confirmDelete}
              enabledActions={enabledActions}
            />
          </main>
        </section>
      </>
    );
  }
}
