import axios from 'axios';

import { authHeader } from '@H';

export default {
  getCashDepositList: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/transactions/?product_type=CashDeposit&offset=${data.offset}&limit=${data.limit}&start_date=${data.start_date}&end_date=${data.end_date}&by_bank_uuid=${data.bank_uuid}`,
    }),
  getB2BTransferList: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/bank-to-bank-transfer/?offset=${data.offset}&limit=${data.limit}&from_date=${data.from_date}&end_date=${data.end_date}&credit_bank_id=${data.credit_bank}&debit_bank_id=${data.debit_bank}`,
    }),
  getSettlement: (time_stamp, txid) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/settlement/?date=${time_stamp}&tx_id=${txid.join(',')}`,
    }),
  getPOSLedger: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/list-to-settlement-transactions/?start_date=${data.start_date}&end_date=${data.end_date}&limit=${data.limit}&offset=${data.offset}&source=SWIPE&bank_id=${data.bank_id}`,
    }),

  getWalletLedger: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/list-to-settlement-transactions/?start_date=${data.start_date}&end_date=${data.end_date}&limit=${data.limit}&offset=${data.offset}&source=WALLET&bank_id=${data.bank_id}`,
    }),
  getMappedPOS: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/map-swipe-machines/list/`,
    }),
  getMappedWallets: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/mapped-wallets/list/`,
    }),
};
