import React, { useEffect, useState } from 'react';

import { authService } from '@S';

const Login = ({ history }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (authService.currentUserValue) {
      history.push('/dashboard');
    }
  }, [history]);

  const onChange = (field) => (event) => {
    const value = event.target.value;
    if (field === 'username') {
      setUsername(value);
    } else if (field === 'password') {
      setPassword(value);
    }
  };

  const onTogglePasswd = () => {
    setShowPassword((prevState) => !prevState);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!username || !password) {
      setError(true);
      setMsg('Please fill all the fields and try again');
      return;
    }
    setLoading(true);
    setMsg('');
    let token;
    authService.login(username, password).then(
      (user) => {
        token = authService.currentUserToken;
        authService.bunkDetails(token).then(
          (bunk) => {
            setLoading(false);
            history.push('/dashboard');
          },
          (error) => {
            setLoading(false);
          },
        );
      },
      (error) => {
        setError(true);
        setLoading(false);
        setMsg(error);
      },
    );
  };

  return (
    <section className="login">
      <div className="wave-container --bottom">
        <div className="wave" />
        <div className="wave" />
        <div className="wave" />
      </div>
      <div className="wrapper">
        <div className="login-container">
          <div className="logo-wrapper" />
          <div className="content-wrapper">
            <h1>Sign in to manage your outlet</h1>
            <div className="form-wrapper">
              <form onSubmit={onSubmit}>
                <div className="input-wrapper">
                  <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={onChange('username')}
                    className={error ? 'error' : ''}
                  />
                </div>
                <div
                  className={
                    showPassword ? 'input-wrapper visible' : 'input-wrapper'
                  }
                >
                  <input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    value={password}
                    onChange={onChange('password')}
                    className={error ? 'error' : ''}
                  />
                  <span className="show-psswd" onClick={onTogglePasswd} />
                </div>
                <div
                  className={
                    error ? 'error-wrapper --display' : 'error-wrapper'
                  }
                >
                  <p>{msg}</p>
                </div>
                <div className="input-submit-wrapper">
                  <button
                    className={loading ? 'loading' : ''}
                    type="submit"
                    value="Login"
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="wave-container --top">
        <div className="wave" />
        <div className="wave" />
        <div className="wave" />
      </div>
    </section>
  );
};

export { Login };
