import React from 'react';

import { LubeSales } from '@C/Lubes/RouteComponents';
import { useGlobalStore } from '@stores/globalStore';

const LubeSummary = (props) => {
  const { syncCODCollectionData } = props;

  const updateTotalSummary = () => {
    syncCODCollectionData(props.match.params.duty, props.match.params.staff);
  };

  return <LubeSales {...props} cod={true} onDataChange={updateTotalSummary} />;
};

export { LubeSummary };
