import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { LubeInventory, LubeSales, PhysicalStock } from './RouteComponents';

const RouteSetup = () => {
  return (
    <Switch>
      <Route path="/account/lubes/inventory" component={LubeInventory} />
      <Route path="/account/lubes/sales" component={LubeSales} />
      <Route
        path="/account/lubes/physical-stock-inspection"
        component={PhysicalStock}
      />
      <Route path="/account/lubes/*">
        <Redirect to={`/account/lubes/inventory`} />
      </Route>
    </Switch>
  );
};

export { RouteSetup };
