import React from 'react';

import { Header, Navbar, NavPills } from '@C';
import { RouteSetup } from '@C/CreditCustomers';
import ErrorBoundary from '@C/Error';

const routes = [
  {
    name: 'Customer List',
    path: '/account/credit-customers/list',
  },
  {
    name: 'Credit Slips',
    path: '/account/credit-customers/slips',
  },
  {
    name: 'Credit Collection',
    path: '/account/credit-customers/credit-collection',
  },
  {
    name: 'Customer Ledger',
    path: '/account/credit-customers/customer-ledger',
  },
  {
    name: 'Generate Quotation',
    path: '/account/credit-customers/generate-quotation',
  },
  {
    name: 'Live Purchase Orders',
    path: '/account/credit-customers/purchase-orders',
  },
  {
    name: 'Black List',
    path: '/account/credit-customers/black-list',
  },
  {
    name: 'Generate Invoice',
    path: '/account/credit-customers/generate-invoice',
  },
];

const CreditCustomers = () => {
  return (
    <main>
      <div className="page-wrapper">
        <Header title="Credit Customers" />
        <Navbar />
        <div className="page-content">
          <section className="page-components">
            <div className="page-title">
              <h1>Credit Customers</h1>
            </div>

            <ErrorBoundary>
              <NavPills routes={routes} />
            </ErrorBoundary>
            <ErrorBoundary>
              <RouteSetup />
            </ErrorBoundary>
          </section>
        </div>
      </div>
    </main>
  );
};

export { CreditCustomers };
