import React, { Component } from 'react';
import { loadable } from 'react-lazily/loadable';

import services from '@C/StaffManagement/_utils/data';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';

import './styles.scoped.scss';

const { StaffSetup } = loadable(
  () => import('@C/ManageBunk/RouteComponents/StaffSetup'),
  {
    fallback: <DropLoaderLarge />,
  },
);

const { StaffDetails } = loadable(
  () => import('@C/StaffManagement/RouteComponents/ManageStaff/StaffDetails'),
  {
    fallback: <DropLoaderLarge />,
  },
);

class ManageStaff extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display: false,
      updated: false,
    };
  }

  toggleDisplay = () => {
    this.setState({ display: !this.state.display });
  };

  updateList = () => {
    this.setState({ updated: !this.state.updated });
  };

  render() {
    const { display } = this.state;
    return (
      <>
        <section className="widget full-width widget-actions child-accordion">
          <header>
            <button
              className="modal-trigger blue-btn"
              onClick={this.toggleDisplay}
            >
              {display ? 'Cancel' : 'Add staff'}
            </button>
          </header>
          <main>{display && <StaffSetup onUpdate={this.updateList} />}</main>
        </section>
        <StaffList key={this.state.updated} />
      </>
    );
  }
}

class StaffList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      showEditModal: false,
      selectedId: '',
      expandDetails: false,
    };

    // create ref
    this.cardRef = React.createRef();
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    services
      .getStaffList()
      .then((response) => {
        const tmp = response.data.data;
        let newData = [];
        newData = [
          ...(tmp.cashiers || []),
          ...(tmp.managers || []),
          ...(tmp.other_staff || []),
        ];
        this.setState({ list: newData });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateList = () => {
    this.getList();
  };

  expandDetails = (selectedId) => {
    this.setState({ selectedId: selectedId, expandDetails: true });
    // window.scrollTo({ behavior: 'smooth', top: this.cardRef.current.offsetTop });
  };

  collapseDetails = () => {
    this.setState({ expandDetails: false, selectedId: '' });
  };

  render() {
    const { list, expandDetails, selectedId } = this.state;

    return (
      <>
        <section className="widget full-width list-card list-card__staff">
          <header>
            <h3>Staff List</h3>
          </header>
          <main>
            <div className="cards-container">
              <ul>
                {list.map((item, i) => {
                  return (
                    <li
                      className={`data-card ${expandDetails && selectedId == item.uuid ? 'expanded' : ''}`}
                      key={i}
                    >
                      <div className="card-wrapper">
                        <div className="card-image">
                          <img
                            className="img"
                            src={`https://ui-avatars.com/api/?name=${item.first_name} ${item.last_name}`}
                            alt={item.first_name}
                          />
                        </div>
                        <div className="card-info">
                          <span>
                            {item.first_name} {item.last_name}
                          </span>
                          <span>{item.role}</span>
                          <span>{item.mobile}</span>
                        </div>
                        <div className="card-actions">
                          <button onClick={() => this.expandDetails(item.uuid)}>
                            View Details
                          </button>
                        </div>
                      </div>
                      {expandDetails && selectedId === item.uuid && (
                        <div className="card-panel">
                          <StaffDetails
                            onUpdate={this.updateList}
                            ref={this.cardRef}
                            uuid={item.uuid}
                            collapseCard={this.collapseDetails}
                          />
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </main>
        </section>
      </>
    );
  }
}

export { ManageStaff };
