import React from 'react';
const TankSvg = (props) => {
  const transform = `translate(-18396, ${props.transformY || '-12048'})`;
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={487}
        height={276.305}
        viewBox="0 0 487 276.305"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            y1={0.5}
            x2={1}
            y2={0.5}
            gradientUnits="objectBoundingBox"
          >
            <stop offset={0} stopColor="#adadad" />
            <stop offset={1} stopColor="#696969" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            y1={-1.742}
            x2={1}
            y2={-1.766}
            gradientUnits="objectBoundingBox"
          >
            <stop offset={0} stopColor="#9d9d9d" />
            <stop offset={1} stopColor="#585858" />
          </linearGradient>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_5884"
              data-name="Rectangle 5884"
              width={452}
              height={300}
              transform={transform}
              fill="#fff"
              opacity={0.498}
            />
          </clipPath>
          <linearGradient
            id="linear-gradient-4"
            y1={0.467}
            x2={1}
            y2={0.466}
            gradientUnits="objectBoundingBox"
          >
            <stop offset={0} stopColor="#1676bb" />
            <stop offset={1} stopColor="#002c48" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-5"
            x1={0.5}
            x2={0.5}
            y2={1}
            gradientUnits="objectBoundingBox"
          >
            <stop offset={0} stopColor="#d8d8d8" />
            <stop offset={1} stopColor="#6c6c6c" />
          </linearGradient>
        </defs>
        <g
          id="Group_8792"
          data-name="Group 8792"
          transform="translate(18414 11866.304)"
        >
          <g
            id="Group_8767"
            data-name="Group 8767"
            transform="translate(-17943 -11827.43) rotate(90)"
          >
            <path
              id="Union_2"
              data-name="Union 2"
              d="M0,424.181H0V30.9H237v393.28h0C236.753,450.056,183.794,471,118.5,471S.246,450.056,0,424.181Z"
              transform="translate(0.43 0.001)"
              fill="url(#linear-gradient)"
            />
            <ellipse
              id="Ellipse_23"
              data-name="Ellipse 23"
              cx={118.5}
              cy={47}
              rx={118.5}
              ry={47}
              transform="translate(0.43 -16)"
              fill="url(#linear-gradient-2)"
            />
            <ellipse
              id="Ellipse_25"
              data-name="Ellipse 25"
              cx={118.5}
              cy={47}
              rx={118.5}
              ry={47}
              transform="translate(0.43 377)"
              fill="url(#linear-gradient-2)"
            />
          </g>
          <g
            id="Group_8769"
            data-name="Group 8769"
            transform="translate(0 340)"
          >
            <g
              id="Mask_Group_6"
              data-name="Mask Group 6"
              clipPath="url(#clip-path)"
            >
              <g
                id="Group_8757"
                data-name="Group 8757"
                transform="translate(-18818.818 -12504.365)"
              >
                <path
                  id="Union_2-2"
                  data-name="Union 2"
                  d="M.011,413.705H0V37.989H.072C2.091,16.876,46.6,0,101.215,0s99.124,16.876,101.143,37.989h.072V413.705h-.011c-.808,21.227-45.809,38.341-101.2,38.341S.82,434.932.011,413.705Z"
                  transform="translate(874.689 354.65) rotate(90)"
                  fill="url(#linear-gradient-4)"
                />
                <ellipse
                  id="Ellipse_18"
                  data-name="Ellipse 18"
                  cx={101}
                  cy={37.652}
                  rx={101}
                  ry={37.652}
                  transform="translate(497.947 355.08) rotate(90)"
                  fill="#002c48"
                  opacity={0.151}
                />
                <ellipse
                  id="Ellipse_22"
                  data-name="Ellipse 22"
                  cx={101}
                  cy={38}
                  rx={101}
                  ry={38}
                  transform="translate(874.689 355.08) rotate(90)"
                  fill="#002c48"
                  opacity={0.151}
                />
              </g>
            </g>
          </g>
          <g
            id="Group_8768"
            data-name="Group 8768"
            transform="translate(-1754.926 1117.696)"
          >
            <g
              id="Group_8713"
              data-name="Group 8713"
              transform="translate(-16281.189 -12983.393)"
            >
              <path
                id="Union_2-3"
                data-name="Union 2"
                d="M0,45.93V3.346H0c0-.017,0-.034,0-.051C0,1.475,6.965,0,15.558,0S31.115,1.475,31.115,3.3c0,.017,0,.034,0,.051h0V45.93c0,2.8-6.965,5.07-15.558,5.07S0,48.73,0,45.93Z"
                transform="translate(0)"
                fill="url(#linear-gradient-5)"
              />
              <ellipse
                id="Ellipse_17"
                data-name="Ellipse 17"
                cx={15.558}
                cy={3}
                rx={15.558}
                ry={3}
                transform="translate(0)"
                fill="none"
              />
            </g>
            <path
              id="Path_28576"
              data-name="Path 28576"
              d="M15.255,0C23.68,0,30.509,1.468,30.509,3.279S23.68,6.557,15.255,6.557,0,5.089,0,3.279,6.83,0,15.255,0Z"
              transform="translate(-16281 -12984)"
              fill="#fff"
              opacity={0.248}
            />
          </g>
          <g
            id="Group_8770"
            data-name="Group 8770"
            transform="translate(-2050.926 1117.696)"
          >
            <g
              id="Group_8713-2"
              data-name="Group 8713"
              transform="translate(-16281.189 -12983.393)"
            >
              <path
                id="Union_2-4"
                data-name="Union 2"
                d="M0,45.93V3.346H0c0-.017,0-.034,0-.051C0,1.475,6.965,0,15.558,0S31.115,1.475,31.115,3.3c0,.017,0,.034,0,.051h0V45.93c0,2.8-6.965,5.07-15.558,5.07S0,48.73,0,45.93Z"
                transform="translate(0)"
                fill="url(#linear-gradient-5)"
              />
              <ellipse
                id="Ellipse_17-2"
                data-name="Ellipse 17"
                cx={15.558}
                cy={3}
                rx={15.558}
                ry={3}
                transform="translate(0)"
                fill="none"
              />
            </g>
            <path
              id="Path_28576-2"
              data-name="Path 28576"
              d="M15.255,0C23.68,0,30.509,1.468,30.509,3.279S23.68,6.557,15.255,6.557,0,5.089,0,3.279,6.83,0,15.255,0Z"
              transform="translate(-16281 -12984)"
              fill="#fff"
              opacity={0.248}
            />
          </g>
          <g
            id="Group_8781"
            data-name="Group 8781"
            transform="translate(-24 -680.499)"
          >
            <line
              id="Line_68"
              data-name="Line 68"
              x2={20}
              transform="translate(-18340 -11128.5)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeWidth={3}
              opacity={0.5}
            />
            <line
              id="Line_69"
              data-name="Line 69"
              x2={20}
              transform="translate(-18365.5 -11078.5)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeWidth={3}
              opacity={0.5}
            />
            <line
              id="Line_70"
              data-name="Line 70"
              x2={50}
              transform="translate(-18368 -11027.5)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeWidth={5}
              opacity={0.5}
            />
            <line
              id="Line_71"
              data-name="Line 71"
              x2={20}
              transform="translate(-18365.5 -10977.5)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeWidth={3}
              opacity={0.5}
            />
            <line
              id="Line_72"
              data-name="Line 72"
              x2={20}
              transform="translate(-18340 -10927.5)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeWidth={3}
              opacity={0.5}
            />
          </g>
          <path
            id="Path_28599"
            data-name="Path 28599"
            d="M-18364.1-12489.077s-31.641,12.952-31.641,101.108,31.641,99.523,31.641,99.523"
            transform="translate(0 680.078)"
            fill="none"
            stroke="#fff"
            strokeWidth={3}
            opacity={0.5}
          />
        </g>
      </svg>
    </>
  );
};
export { TankSvg };
