/* eslint-disable new-cap */
import React, { Component } from 'react';
import dayjs from 'dayjs';
import SimpleReactValidator from 'simple-react-validator';

import PurchaseOrderList from './PurchaseOrderList';

class GeneratePurchaseOrder extends Component {
  constructor() {
    super();
    this.state = {
      time_stamp: dayjs().format('YYYY-MM-DD'),
      product_id: '',
      quote_no: '',
      indent: '',
      product_id: '',
      vehicle_no: '',
      amount: '',
      quantity: '',
      notes: '',
      updated: false,
      isSubmitting: false,
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
  }

  changeDate = (e) => {
    this.setState({ time_stamp: e.target.value });
  };

  render() {
    return (
      <>
        <section className="widget full-width">
          <header>
            <h3> Generate Purchase Order</h3>
          </header>
          <main className="sub-wrapper">
            <form className="inline">
              <div className="input-wrapper with-label ">
                <label> PO Date:</label>
                {this.validator.message(
                  'time_stamp',
                  this.state.time_stamp,
                  'required',
                )}
                <input
                  type="date"
                  placeholder="Date"
                  name="selectDate"
                  onChange={this.changeDate}
                  value={this.state.time_stamp}
                />
              </div>
              <div className="input-wrapper with-label ">
                <label>Quote no: </label>
                <input
                  type="text"
                  placeholder="quote no"
                  onChange={this.handleChange}
                  value={this.state.quote_no}
                  name="quote_no"
                />
              </div>
              <div className="input-wrapper with-label ">
                <label> Indent/PO no: </label>
                {this.validator.message(
                  'indent',
                  this.state.indent,
                  'required',
                )}
                <input
                  type="text"
                  placeholder="Indent/PO no"
                  onChange={this.handleChange}
                  value={this.state.indent}
                  name="indent"
                />
              </div>
              <div className="input-wrapper with-label ">
                <label> Add product: </label>
                <input
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.product_id}
                  name="vehicle_no"
                />
              </div>
              <div className="input-wrapper with-label">
                <label> Vehicle no:</label>
                <input
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.vehicle_no}
                  name="vehicle_no"
                />
              </div>
              <div className="input-wrapper with-label">
                <label> Amount </label>
                {this.validator.message(
                  'amount',
                  this.state.amount,
                  'required',
                )}
                <input
                  type="text"
                  onChange={this.handleChange}
                  value={this.state.amount}
                  name="amount"
                  placeholder="Amount"
                />
              </div>
              <div className="input-wrapper with-label">
                <label> Quantity</label>
                <input
                  type="text"
                  onChange={this.handleBunkChange}
                  value={this.state.quantity}
                  name="quantity"
                />
              </div>
              <div className="input-wrapper with-label ">
                <label> Notes </label>
                <input
                  type="text"
                  value={this.state.notes}
                  name="notes"
                  onChange={this.handleChange}
                  placeholder="Notes"
                />
              </div>
              <div className="form-actions">
                <button className="submit" onClick={this.submitForm}>
                  Generate
                </button>
                <button className="red-btn" onClick={this.cancelEdit}>
                  Cancel
                </button>
              </div>
            </form>
          </main>
        </section>
        <PurchaseOrderList key={this.state.updated} />
      </>
    );
  }
}

export { GeneratePurchaseOrder };
