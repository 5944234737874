import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { useGlobalStore } from '@stores/globalStore';

const StaffList = (props) => {
  const staffList = useGlobalStore((state) => state.staffList);
  const staffType = props.staffType || [
    'Cashiers',
    'Managers',
    'Dealer',
    'other_staff',
  ];
  const [staffListFiltered, setStaffListFiltered] = useState([]);

  useEffect(() => {
    const a = staffList.filter((item) => staffType.includes(item.label));
    setStaffListFiltered(a);
  }, [props.staffType]);

  const handleChange = (selectedOption) => {
    let option = selectedOption;
    if (selectedOption == null) {
      option = { value: '', label: '' };
    }
    props.onChange(option);
  };

  return (
    <Select
      onChange={handleChange}
      options={staffListFiltered}
      isClearable={true}
      className="select-box-container staff-selector-container"
      classNamePrefix="staff-selector"
      placeholder="Select staff"
      isDisabled={props.disabled || false}
      value={staffListFiltered
        .flatMap((ind) => ind.options)
        .filter((item) => item.value === props.value)}
    />
  );
};

export { StaffList };
