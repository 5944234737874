import React, { Component } from 'react';

class CreditCollection extends Component {
  constructor() {
    super();
  }

  render() {
    const summary = this.props.summary;
    return (
      <>
        <section className="widget dsr-table">
          <header></header>
          <main>
            <table className="dsr" data-name="Credit Collection">
              <thead>
                <tr>
                  <th colSpan={2} className="alert-brand">
                    {' '}
                    Credit Collection
                  </th>
                </tr>
                <tr data-header={true}>
                  <th> Credit customer name </th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {summary.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td>
                        {' '}
                        {`${item.credit_customer__first_name} ${item.credit_customer__last_name}`}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-IN', {
                          style: 'currency',
                          currency: 'INR',
                        }).format(item.total.toFixed(2) || 0)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </main>
        </section>
      </>
    );
  }
}

export default CreditCollection;
