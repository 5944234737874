import React from 'react';

import { Header, Navbar, NavPills } from '@C';
import ErrorBoundary from '@C/Error';
import { RouteSetup } from '@C/LiveMemo';

const routes = [
  {
    name: 'Live Memo',
    path: '/account/live-memo',
  },
];

const LiveMemo = () => {
  return (
    <main>
      <div className="page-wrapper">
        <Header title="Credit Customers" />
        <Navbar />
        <div className="page-content">
          <section className="page-components">
            <div className="page-title">
              <h1> Live Memo</h1>
            </div>

            <ErrorBoundary>
              <NavPills routes={routes} />
            </ErrorBoundary>
            <ErrorBoundary>
              <RouteSetup />
            </ErrorBoundary>
          </section>
        </div>
      </div>
    </main>
  );
};

export { LiveMemo };
