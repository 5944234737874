import React, { useState } from 'react';
import dayjs from 'dayjs';

import { Fields, Modes } from '@C/Banking/';
import { ToggleSwitch } from '@C/FormElements';
import ReturnList from '@C/Loans/RouteComponents/Lend/ReturnList';
import { StaffList } from '@C/Utils';

const Lend = () => {
  const [state, setState] = useState({
    time_stamp: dayjs().format('YYYY-MM-DD'),
    notes: '',
    given_by: '',
    party_name: '',
    outstanding: '',
    staff_id: '',
    amount: '',
    interest: '',
    is_partial: false,
    payment: {
      bank_id: null,
      payment_method: 'OFFLINE',
      source: 'CASH',
      check_date: '',
      check_number: '',
      mode: 'cash',
    },
    editMode: false,
    updated: false,
    isSubmitting: false,
  });

  const cancelEdit = () => {
    setState({
      time_stamp: dayjs().format('YYYY-MM-DD'),
      given_by: '',
      party_name: '',
      return_by: '',
      outstanding: '',
      is_partial: false,
      amount: '',
      interest: '',
      notes: '',
      payment: {
        bank_id: null,
        payment_method: 'OFFLINE',
        source: 'CASH',
        check_date: '',
        check_number: '',
        mode: 'cash',
      },
      updated: false,
      editMode: false,
      isSubmitting: false,
    });
  };

  const changePartial = () => {
    setState({ ...state, is_partial: !state.is_partial });
  };

  const submitForm = (e) => {
    // Add your form submission logic here
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleStaffChange = (selectedStaff) => {
    setState({
      ...state,
      staff_id: selectedStaff,
    });
  };

  const handlePaymentSelect = (selectedMode) => {
    setState({
      ...state,
      payment: {
        ...state.payment,
        mode: selectedMode,
      },
    });
  };

  const onDataChange = (data) => {
    setState({
      ...state,
      payment: {
        ...state.payment,
        ...data,
      },
    });
  };

  return (
    <>
      <section className="widget full-width">
        <header>
          <h3> Add Loan </h3>
        </header>
        <main>
          <form className="inline">
            <div className="input-wrapper toggle-element">
              <span className="label"> Repayment : </span>
              <ToggleSwitch
                did={0}
                id="partialP"
                name="partialP"
                checked={state.is_partial}
                onChange={changePartial}
              />
            </div>
            <div className="input-wrapper with-label ">
              <label> Date</label>
              <input
                type="date"
                name="time_stamp"
                onChange={handleChange}
                value={state.time_stamp}
              />
            </div>
            <div className="input-wrapper with-label ">
              <label> Select party </label>
              <input
                type="text"
                onChange={handleChange}
                value={state.party_name}
                name="party_name"
              />
            </div>
            <div className="input-wrapper with-label ">
              <label> Current outstanding </label>
              <input
                type="text"
                onChange={handleChange}
                value={state.outstanding}
                name="given_by"
                disabled
              />
            </div>
            <div className="input-wrapper with-label ">
              <label> Select staff:</label>
              <StaffList
                value={state.staff_id}
                onChange={handleStaffChange}
                staffType={['Cashiers', 'Managers', 'Dealer']}
              />
            </div>
            <div className="input-wrapper with-label ">
              <label> Principal amount </label>
              <input
                type="text"
                value={state.amount}
                name="amount"
                onChange={handleChange}
              />
            </div>
            <div className="input-wrapper with-label ">
              <label> Interest </label>
              <input
                type="text"
                value={state.interest}
                name="interest"
                onChange={handleChange}
                disabled={!state.is_partial}
              />
            </div>
            <div className="input-group">
              <div className="input-wrapper with-label">
                <label>Payment mode</label>
                <Modes
                  handleModeChange={handlePaymentSelect}
                  value={state.payment.mode || 'cash'}
                  disabled={state.editMode}
                />
              </div>
              <Fields
                method={state.payment.mode}
                onDataChange={onDataChange}
                bank={state.payment.bank}
                bank_reference_id={state.payment.bank_reference_id}
                check_date={state.payment.check_date}
                disabled={state.editMode}
              />
            </div>
            <div className="input-wrapper with-label ">
              <label> Received amount </label>
              <input
                type="text"
                value={state.return_by}
                name="return_by"
                onChange={handleChange}
                disabled={!state.is_partial}
              />
            </div>
            <div className="input-wrapper with-label ">
              <label> Notes </label>
              <input
                type="text"
                value={state.notes}
                name="notes"
                onChange={handleChange}
              />
            </div>
            <div className="form-actions">
              <button className="submit" onClick={submitForm}>
                Submit
              </button>
              {state.editMode && (
                <button
                  className="red-btn"
                  disabled={state.isSubmitting}
                  onClick={cancelEdit}
                >
                  Cancel
                </button>
              )}
            </div>
          </form>
        </main>
      </section>
      <ReturnList onEdit={state.editMode} key={state.updated} />
    </>
  );
};

export { Lend };
