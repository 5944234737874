import React, { Component } from 'react';
import Select from 'react-select';

import services from './data';

let CategoryOptions;
class POSList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      selectedPOS: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }
  componentDidMount() {
    services
      .getMappedPOS()
      .then((response) => {
        const tmp = response.data.data;
        const options = tmp.map((bank) => ({
          value: bank.bunk_card_uuid,
          label: bank.name,
        }));
        this.setState({ options });
        if (options.length > 0) {
          this.handleChange(options[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange(selectedOption) {
    this.setState({ selectedPOS: selectedOption });
    this.props.handleBankChange(selectedOption);
  }

  handleReset() {
    const { options } = this.state;
    if (options.length > 0) {
      this.handleChange(options[0]);
    }
  }

  render() {
    const { options, selectedPOS } = this.state;
    return (
      <Select
        onChange={this.handleChange}
        isClearable={false}
        options={options}
        className="select-box-container"
        classNamePrefix="raw-select"
        name="payment_mode"
        placeholder="Select POS"
        value={selectedPOS}
        isDisabled={this.props.disabled || false}
      />
    );
  }
}

export { POSList };
