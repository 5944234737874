import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ActivateSMS, ManagePackages } from '@C/Admin/SmsSetup/RouteComponents';

const RouteSetup = () => {
  return (
    <Switch>
      <Route path="/admin/sms-setup/activate-sms" component={ActivateSMS} />
      <Route
        path="/admin/sms-setup/manage-packages"
        component={ManagePackages}
      />
      <Route path="/admin/sms-setup*">
        <Redirect to={`/admin/sms-setup/activate-sms`} />
      </Route>
    </Switch>
  );
};

export { RouteSetup };
