import React, { Component } from 'react';
import dayjs from 'dayjs';

import services from '@C/DipReading/_utils/data';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';

import './styles.scoped.scss';

class SummaryStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time_stamp: dayjs().format('YYYY-MM-DD'),
      tank_id: '',
      tankList: [],
      activeTab: '',
      dipInfo: {},
      loading: false,
      activeTab: 0,
      dataAvailable: false,
    };
  }
  handleDate = (e) => {
    this.setState({ time_stamp: e.target.value }, function () {
      this.getSummaryStatus();
    });
  };

  componentDidMount() {
    this.getTankList();
  }

  getSummaryStatus = () => {
    services
      .getSummaryStatus(this.state.time_stamp, this.state.tank_id)
      .then((response) => {
        this.setState({
          dipInfo: response.data.data,
          loading: false,
          dataAvailable: true,
        });
      })
      .catch((error) => {
        const dipInfo = {
          opening_stock: error.response.data.data.opening_stock,
          purchase: error.response.data.data.purchase,
          closing_stock: error.response.data.data.closing_stock,
          sales_per_tank_dip: error.response.data.data.sales_per_tank_dip,
          total_sale_from_all_guns:
            error.response.data.data.total_sale_from_all_guns,
          testing_litters_from_all_guns:
            error.response.data.data.testing_litters_from_all_guns,
          testing_litters_return:
            error.response.data.data.testing_litters_return,
          variation: error.response.data.data.variation,
        };
        this.setState({
          dipInfo: dipInfo,
          loading: false,
          dataAvailable: false,
        });
      });
  };

  getTankList = () => {
    services
      .getTankList()
      .then((response) => {
        const tmp = response.data.data;
        this.setState(
          {
            tankList: tmp,
            tank_id: tmp[0].uuid,
          },
          function () {
            this.getSummaryStatus();
          },
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleTabChange = (id, i) => {
    this.setState({ tank_id: id, activeTab: i }, function () {
      this.getSummaryStatus();
    });
  };

  render() {
    const { tankList, dipInfo, activeTab } = this.state;

    if (this.state.loading) {
      return <DropLoaderLarge />;
    }
    return (
      <>
        <section className="widget full-width dip-summary-status">
          <header>
            <h3>Dip Summary Status</h3>
          </header>
          <main>
            <div className="form-tabs full-width">
              <ul className="tab-pills">
                {tankList.map((item, i) => (
                  <li
                    onClick={() => this.handleTabChange(item.uuid, i)}
                    key={i}
                    className={`${
                      activeTab === i ? 'pill-item active' : 'pill-item'
                    }`}
                  >
                    {item.name}
                  </li>
                ))}
              </ul>
            </div>
            <div className="form-header">
              <div className="input-wrapper">
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.time_stamp}
                  onChange={this.handleDate}
                />
              </div>
            </div>
            {!this.state.dataAvailable && (
              <div className="notification-alert notification-alert__error">
                Dip reading not available for this date
              </div>
            )}
            <div className="form-content full-width">
              <table>
                <tbody>
                  <tr>
                    <td>Opening stock </td>
                    <td>{dipInfo.opening_stock?.toFixed(2)}</td>
                  </tr>

                  <tr>
                    <td>Purchase </td>
                    <td>{dipInfo.purchase?.toFixed(2)}</td>
                  </tr>

                  <tr>
                    <td>Closing stock</td>
                    <td>{dipInfo.closing_stock?.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
              <table>
                <tbody>
                  <tr>
                    <td>Sales Per Tank Dip </td>
                    <td>{dipInfo.sales_per_tank_dip?.toFixed(2)}</td>
                  </tr>

                  <tr>
                    <td>Sales From All Guns </td>
                    <td>{dipInfo.total_sale_from_all_guns?.toFixed(2)}</td>
                  </tr>

                  <tr>
                    <td>Testing From All Guns </td>
                    <td>{dipInfo.testing_litters_from_all_guns?.toFixed(2)}</td>
                  </tr>

                  <tr>
                    <td>{dipInfo.tank?.product} Testing Return </td>
                    <td>{dipInfo.testing_litters_return?.toFixed(2)}</td>
                  </tr>

                  <tr>
                    <td>{dipInfo.tank?.product} Variation </td>
                    <td>{dipInfo.variation?.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </main>
        </section>
      </>
    );
  }
}
export { SummaryStatus };
