import React, { Component } from 'react';
import dayjs from 'dayjs';
import { formatCurrency, TableGrid } from '@C/TableGrid';
import { VendorList } from '@C/Utils';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';

const col = [
    {
        name: 'Date',
        key: '',
    },
    {
        name: 'Invoice #',
        key: '',
    },
    {
        name: 'Purchase Amount',
        key: '',
    },
    {
        name: 'Paid Amount',
        key: '',
    },
    {
        name: 'Outstanding Amount',
        key: '',
    },
    {
        name: 'Notes',
        key: '',
    },
];

class VendorLedger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vendorList: [],
            start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
            end_date: dayjs().format('YYYY-MM-DD'),
            limit: 20,
            offset: 1,
            count: 0,
            vendor_id: '',
            loading: true,
        };
        this.limitInput = React.createRef();
    }

    componentDidMount() {

    }

    handleQuickSearch = (e) => {
        e.preventDefault();
    };


    handleDateChange = (e) => {
        const { name, value } = e.target;
        if (name === 'start_date' && dayjs(value).isAfter(this.state.end_date)) {
            this.setState({ [name]: value, end_date: value });
        } else if (
            name === 'end_date' &&
            dayjs(value).isBefore(this.state.start_date)
        ) {
            this.setState({ [name]: value, start_date: value });
        } else {
            this.setState({ [name]: value });
        }
    };

    handlePagination = (e) => {

    };

    handleVendorChange = (e) => {
        this.setState({ vendor_id: e.value });
    };

    resetFilter = () => {
        this.setState(
            {
                start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
                end_date: dayjs().format('YYYY-MM-DD'),
                limit: 20,
                offset: 1,
                count: 0,
                vendor_id: '',
            },
        );
    };

    submitForm = (e) => {
        e.preventDefault();

    };

    render() {
        const { vendorList } = this.state;

        return (
            <section className="widget list-table full-width inverted">
                <header>
                    <h3>Vendor Ledger</h3>
                </header>
                <main>
                    <div className="table-filters">
                        <div className="filter-wrapper">
                            <div className="filter-group">
                                <label>From date:</label>
                                <input
                                    className="cstm-date-input"
                                    type="date"
                                    value={this.state.start_date}
                                    name="start_date"
                                    onChange={this.handleDateChange}
                                />
                            </div>
                            <div className="filter-group">
                                <label>To date:</label>
                                <input
                                    className="cstm-date-input"
                                    type="date"
                                    value={this.state.end_date}
                                    min={this.state.start_date}
                                    onChange={this.handleDateChange}
                                    name="end_date"
                                />
                            </div>
                            <div className="filter-group">
                                <label>Vendor:</label>
                                <VendorList
                                    value={this.state.vendor_id}
                                    onChange={this.handleVendorChange}
                                />
                            </div>
                            <div className="filter-group with-icon">
                                <label>
                                    Results to display- {this.state.limit || 20} (Total:{' '}
                                    {this.state.count})
                                </label>
                                <form onSubmit={this.handleQuickSearch}>
                                    <input type="text" name="limit" ref={this.limitInput} />
                                    <button
                                        type="submit"
                                        className="search-icon"
                                        onClick={this.handleQuickSearch}
                                    ></button>
                                </form>
                            </div>
                            <div className="filter-group reset-filter">
                                <button onClick={this.resetFilter}>Reset filters</button>
                            </div>
                            <div className="filter-group">
                                <button
                                    className="submit"
                                    type="submit"
                                    onClick={this.submitForm}
                                >
                                    Apply filter
                                </button>
                            </div>
                        </div>
                    </div>
                    <main className="content-wrapper">
                        <TableGrid
                            col={col}
                            row={this.state.vendorList}
                            count={this.state.count}
                            hideActions={true}
                        />
                    </main>
                </main>
            </section>
        );
    }
}

export { VendorLedger };
