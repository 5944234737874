import axios from 'axios';

import { authHeader } from '@H';

export default {
  getStaffList: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/staff-management/bunk/staff/`,
    }),

  getStaffDetails: (staff_id) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/staff-management/bunk/staff/?staff_id=${staff_id}`,
    }),

  getStaffListItem: (id) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/staff-management/bunk/staff/?staff_id=${id}`,
    }),

  generateOtp: () =>
    axios({
      body: {
        from_app: 'RESET',
      },
      method: 'POST',
      headers: authHeader(1),
      url: `${process.env.API_URL}/two-step-auth/ `,
    }),
  getComponentList: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/bunk_account_reset/`,
    }),
  getProfitMargin: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/fuel-profit-margin/`,
    }),
};
