import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import './styles.scoped.scss';

const NavPills = ({ ...props }) => {
  const [routes, setRoutes] = useState([]);
  useEffect(() => {
    setRoutes(props.routes);
  }, []);

  return (
    <>
      <div className="navpills-container">
        <div className="navpills-wrapper">
          <ul className="navpill-list">
            {routes.map((item, i) => {
              if (item.display != false) {
                return (
                  <li key={`nav-live-${i}`} className="navpill-item">
                    <NavLink
                      activeClassName="active"
                      to={item.path}
                      className="navpill-link"
                    >
                      {item.name}
                    </NavLink>
                  </li>
                );
              } else {
                return <React.Fragment key={`nav-null-${i}`}></React.Fragment>;
              }
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export { NavPills };
