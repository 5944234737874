import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import services from '@C/DipReading/_utils/data';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';

const DipCalibrationChart = () => {
  const [uuid, setUUID] = useState(useParams().uuid);
  const [dipList, setDipList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getDipCalibrationItem();
  }, [setUUID]);

  const getDipCalibrationItem = () => {
    services
      .getDipCalibrationItem(uuid)
      .then((response) => {
        const tmp = response.data.data;
        const dipList = tmp;
        setDipList(tmp);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <section className="widget full-width  list-table">
        <header>
          <h3> User display</h3>
        </header>
        <main>
          <div className="cards-container">
            <ul>
              <li className="data-card">
                <div className="card-info">
                  <p>
                    <label>OMC name:</label> {dipList.omc?.name}
                  </p>
                  <p>
                    <label>Chart name:</label> {dipList.name}
                  </p>
                  <p>
                    <label>Diameter:</label> {dipList.diameter}
                  </p>
                  <p>
                    <label>Int length:</label> {dipList.internal_length}
                  </p>
                </div>
              </li>
              <li>
                <table className="dsr">
                  <thead>
                    <tr>
                      <th>Dip</th>
                      <th>Volume</th>
                      <th>Difference</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(() => {
                      if (loading) {
                        return <DropLoaderLarge />;
                      } else {
                        return (
                          <>
                            {dipList?.dip_volumes_list.map((item, i) => (
                              <tr key={i}>
                                <td>{item?.dip_in_cm}</td>
                                <td>{item?.volume}</td>
                                <td>{item?.difference}</td>
                              </tr>
                            ))}
                          </>
                        );
                      }
                    })()}
                  </tbody>
                </table>
              </li>
            </ul>
          </div>
        </main>
      </section>
    </>
  );
};
export { DipCalibrationChart };
