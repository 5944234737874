import React, { Component } from 'react';
import dayjs from 'dayjs';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

import services from '@C/Reports/_utils/data';
import { InterFont } from '@C/TableGrid';
import TableToExcelExport from '@C/TableToExcelExport';
import { ShiftsList, StaffList } from '@C/Utils';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { getBase64FromUrl } from '@C/Utils/ExportUtils';
import { authService } from '@S';

import CashBook from './CashBook';
import Collection from './Collections';
import CreditCollection from './CreditCollection';
import CreditSales from './CreditSales';
import FuelPurchase from './FuelPurchase';
import FuelSales from './FuelSales';
import LubePurchase from './LubePurchase';
import LubeSales from './LubesSales';
import OtherExpense from './OtherExpense';
import OtherIncome from './OtherIncome';
import VendorPayment from './VendorPayment';

import './styles.scoped.scss';

class BusinessSummaryReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
      staff_id: '',
      shift_id: '',
      fuel_sales: [],
      fuel_purchase: [],
      lube_sales: [],
      lube_purchase: [],
      credit_sales: [],
      credit_collection: [],
      collection: [],
      vendor_payments: [],
      other_income: [],
      other_expense: [],
      cash_book: [],
      loading: true,
    };
    this.limitInput = React.createRef();
  }

  handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date' && dayjs(value).isAfter(this.state.end_date)) {
      this.setState({ [name]: value, end_date: value });
    } else if (
      name === 'end_date' &&
      dayjs(value).isBefore(this.state.start_date)
    ) {
      this.setState({ [name]: value, start_date: value });
    } else {
      this.setState({ [name]: value });
    }
  };
  handleStaffChange = (e) => {
    this.setState({ staff_id: e.value });
  };

  handleShiftChange = (e) => {
    this.setState({ shift_id: e.value });
  };

  resetFilter = () => {
    this.setState(
      {
        start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
        staff_id: '',
        shift_id: '',
        loading: true,
      },
      function () {
        this.getBusinessSummaryReport();
      },
    );
  };

  export2PDF = () => {
    const doc = new jsPDF();
    const start_date = this.state.start_date;
    const end_date = this.state.end_date;
    doc.addFileToVFS('Inter-Regular.ttf', InterFont);
    doc.addFont('Inter-Regular.ttf', 'Inter', 'normal');
    doc.setFont('Inter');

    const exportOptions = {
      fileName: `Business Summary- ${start_date} to ${end_date} Report.pdf`,
      reportName: 'Business Summary Report',
      reportDate: `Date - ${dayjs(start_date).format('D MMM YY')} to ${dayjs(
        end_date,
      ).format('D MMM YY')}`,
    };
    const omcLogo = getBase64FromUrl(authService.currentOMCWebLogo);
    const bunkAddress = authService.currentBunkAddress;

    omcLogo.then((result) => {
      const img = new Image();
      img.onload = function () {
        doc.addFileToVFS('Inter-Regular.ttf', InterFont);
        doc.addFont('Inter-Regular.ttf', 'Inter', 'normal');
        doc.setFont('Inter');
        const aspectRatio = img.width / img.height;
        const width = 25;
        const height = width / aspectRatio;
        try {
          doc.addImage(result, 'PNG', 13, 10, width, height);
          autoTable(doc, {
            body: [
              [
                {
                  content: authService.currentBunkName,
                  styles: {
                    halign: 'right',
                    fontSize: 15,
                    textColor: '#000000',
                  },
                },
              ],
              [
                {
                  content:
                    bunkAddress.city +
                    ', ' +
                    bunkAddress.location +
                    ',' +
                    bunkAddress.country.country +
                    ' - ' +
                    bunkAddress.pincode,
                  styles: {
                    halign: 'right',
                    fontSize: 12,
                    textColor: '#000000',
                  },
                },
              ],
            ],
            theme: 'plain',
          });
          autoTable(doc, {
            body: [
              [
                {
                  content: 'Business Summary Report',
                  styles: {
                    halign: 'left',
                    fontSize: 15,
                    textColor: '#000000',
                    fontStyle: 'bold',
                  },
                },
                {
                  content: `${dayjs(start_date).format('D MMM YY')} to ${dayjs(end_date).format('D MMM YY')}`,
                  styles: {
                    halign: 'right',
                    fontSize: 12,
                    textColor: '#000000',
                    fontStyle: 'bold',
                  },
                },
              ],
            ],
            theme: 'plain',
          });

          const tables = document.querySelectorAll('table');
          tables.forEach((table, i) => {
            autoTable(doc, {
              html: table,
              startY: doc.lastAutoTable.finalY + 10,
              pageBreak: 'avoid',

              didParseCell: function (data) {
                const hClass = data.row.raw[0]._element.classList;
                if (hClass.length > 0 && hClass.contains('alert-brand')) {
                  data.cell.styles.fillColor = [0, 0, 0];
                  data.cell.styles.textColor = [255, 255, 255];
                  data.cell.styles.halign = 'center';
                }

                // check if text contains currency symbol and remove the character
              },
              theme: 'grid',
              styles: {
                font: 'Inter',
                overflow: 'linebreak',
                fontSize: 9,
                cellWidth: 'auto',
              },
              margin: { top: 15, right: 5, bottom: 5, left: 5 },
            });
          });
          doc.save(exportOptions.fileName);
        } catch (e) {
          console.log(e);
        }
      };
      img.src = result;
    });
  };

  export2Excel = () => {
    const start_date = this.state.start_date;
    const end_date = this.state.end_date;
    let book;
    const tables = document.querySelectorAll('table');
    tables.forEach((table, i) => {
      const sheetName = table.getAttribute('data-name');

      if (i === 0) {
        book = TableToExcelExport.tableToBook(table, {
          sheet: { name: sheetName },
        });
      } else {
        TableToExcelExport.tableToSheet(book, table, {
          sheet: { name: sheetName },
        });
      }
    });

    TableToExcelExport.save(
      book,
      `${authService.currentBunkName} - Business Summary - ${start_date} to ${end_date}.xlsx`,
    );
  };

  componentDidMount() {
    this.getBusinessSummaryReport();
  }

  getBusinessSummaryReport = () => {
    this.setState({ loading: true });
    services
      .getBusinessSummaryReport(this.state)
      .then((response) => {
        const tmp = response.data.data.business_summary;
        this.setState({
          fuel_sales: tmp.fuel_sales,
          fuel_purchase: tmp.fuel_purchase,
          lube_sales: tmp.lube_sales,
          lube_purchase: tmp.lube_purchase,
          credit_sales: tmp.credit_sales,
          credit_collection: tmp.credit_collection,
          collection: tmp.collection,
          vendor_payments: tmp.vendor_payments,
          other_income: tmp.other_income,
          other_expense: tmp.other_expense,
          cash_book: tmp.cash_book,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, function () {
      this.getBusinessSummaryReport();
    });
  };

  render() {
    return (
      <section className="widget list-table full-width inverted">
        <header className="list-header">
          <h3> Business Summary Report </h3>
        </header>
        <main>
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group">
                <label>From date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.start_date}
                  name="start_date"
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="filter-group">
                <label>To date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.end_date}
                  min={this.state.start_date}
                  onChange={this.handleDateChange}
                  name="end_date"
                />
              </div>
              <div className="filter-group">
                <label>Staff:</label>
                <StaffList
                  value={this.state.staff_id}
                  onChange={this.handleStaffChange}
                  staffType={['Cashiers', 'Managers', 'Dealer']}
                />
              </div>
              <div className="filter-group">
                <label>Shift:</label>
                <ShiftsList
                  value={this.state.shift_id}
                  onChange={this.handleShiftChange}
                />
              </div>
              <div className="filter-group reset-filter">
                <button onClick={this.resetFilter}>Reset filters</button>
              </div>
              <div className="filter-group">
                  <button
                    className="submit"
                    type="submit"
                    onClick={this.submitForm}
                  >
                    Apply filter
                  </button>
                </div>
              <div className="filter-group">
                <button
                  className="blue-btn btn-with-icon btn-with-icon__excel "
                  onClick={() => this.export2Excel()}
                >
                  Export to Excel
                </button>
              </div>
              <div className="filter-group">
                <button
                  className="blue-btn btn-with-icon btn-with-icon__pdf "
                  onClick={() => this.export2PDF()}
                >
                  Export to PDF
                </button>
              </div>
            </div>
          </div>
          <div className="table-grid">
            {(() => {
              if (this.state.loading) {
                return <DropLoaderLarge />;
              } else {
                return (
                  <>
                    <FuelSales summary={this.state.fuel_sales} />
                    <FuelPurchase summary={this.state.fuel_purchase} />
                    <LubeSales summary={this.state.lube_sales} />
                    <LubePurchase summary={this.state.lube_purchase} />
                    <CreditSales summary={this.state.credit_sales} />
                    <CreditCollection summary={this.state.credit_collection} />
                    <VendorPayment summary={this.state.vendor_payments} />
                    <Collection summary={this.state.collection} />
                    <OtherIncome summary={this.state.other_income} />
                    <OtherExpense summary={this.state.other_expense} />
                    <CashBook summary={this.state.cash_book} />
                  </>
                );
              }
            })()}
          </div>
        </main>
      </section>
    );
  }
}

export { BusinessSummaryReport };
