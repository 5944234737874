import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  AdvanceReturn,
  AdvanceSalary,
  Attendance,
  ManageStaff,
  Salary,
} from '@C/StaffManagement/RouteComponents';

const RouteSetup = () => {
  return (
    <Switch>
      <Route path="/account/staff-management/manage" component={ManageStaff} />
      <Route
        path="/account/staff-management/advancesalary"
        component={AdvanceSalary}
      />
      <Route path="/account/staff-management/salary" component={Salary} />
      <Route
        path="/account/staff-management/attendance"
        component={Attendance}
      />
      <Route
        path="/account/staff-management/advancereturn"
        component={AdvanceReturn}
      />
      <Route path="/account/staff-management/*">
        <Redirect to={`/account/staff-management/manage`} />
      </Route>
    </Switch>
  );
};

export { RouteSetup };
