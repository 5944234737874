import React, { Component } from 'react';
import Select from 'react-select';

import services from './data';

const CategoryOptions = [];

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    if (!CategoryOptions.length > 0) {
      services
        .getProductList()
        .then((response) => {
          const tmp = response.data.data;

          for (let i = 0; i < tmp.length; i++) {
            CategoryOptions.push({
              value: tmp[i].uuid,
              label: tmp[i].product,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  handleChange = (selectedOption) => {
    const id = this.props.did ? this.props.did : 0;
    this.props.handleProductChange(selectedOption, id);
  };

  render() {
    return (
      <Select
        onChange={this.handleChange}
        options={CategoryOptions}
        className="select-box-container"
        classNamePrefix="raw-select"
        name="oml"
        placeholder="Select fuel"
        value={CategoryOptions.filter(
          ({ value }) => value === this.props.value,
        )}
        isDisabled={this.props.disabled}
      />
    );
  }
}

export default ProductList;
