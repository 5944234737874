import React, { Component } from 'react';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';

import services from '@C/CreditCustomers/_utils/data';
import { TableGrid } from '@C/TableGrid';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { authHeader } from '@H';
import { authService } from '@S';

const col = [
  {
    name: 'PO Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Indent/PO no.',
    key: 'order_no',
  },
  {
    name: 'Quote no.',
    key: 'quotation',
    formatter(props) {
      return <>{props.quotation_number}</>;
    },
  },
  {
    name: 'Product',
    key: 'product_list',
    formatter(props) {
      return (
        <>
          {props.map((item, i) => (
            <tr key={i}>
              <td>{item?.product?.product}</td>
            </tr>
          ))}
        </>
      );
    },
  },
  {
    name: 'vehicle no.',
    key: 'vehicle',
    formatter(props) {
      return <>{props.vehicle_number}</>;
    },
  },

  {
    name: 'Amount',
    key: 'amount',
  },
  {
    name: 'Notes',
    key: 'notes',
  },

  {
    name: 'Status',
    key: 'status',
  },
];

const enabledActions = [
  {
    action: 'Delete',
    roles: ['Credit Customer'],
  },
];

export default class PurchaseOrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purchaseOrderList: [],
      credit_customer_id: authService.currentUserId,
      offset: 1,
      count: 0,
      limit: 20,
      loading: true,
    };
  }

  componentDidMount() {
    this.getLivePurchaseOrder();
  }

  getLivePurchaseOrder = () => {
    services
      .getLivePurchaseOrder(this.state)
      .then((response) => {
        this.setState({
          purchaseOrderList: response.data.data.results,
          count: response.data.data.count,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handlePagination = (e) => {
    this.setState({ offset: e, loading: true }, function () {
      this.getLivePurchaseOrder();
    });
  };

  confirmDelete = (item) => {
    confirmAlert({
      title: 'Confirm purchase order deletion',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteItem(item),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  deleteItem = (item) => {
    const headers = authHeader(1);
    axios
      .delete(
        `${process.env.API_URL}/credit-customer/bunk/indent/?purchase_order_id=${item.uuid}`,
        {
          headers,
        },
      )
      .then(() => {
        toast.success('Purchase order deleted successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        this.getLivePurchaseOrder();
      });
  };

  render() {
    const { purchaseOrderList } = this.state;

    return (
      <>
        <section className="widget full-width list-table">
          <header>
            <h3> All Purchase Orders </h3>
          </header>
          <main>
            <div className="table-wrapper">
              {(() => {
                if (this.state.loading) {
                  return <DropLoaderLarge />;
                } else {
                  return (
                    <TableGrid
                      col={col}
                      row={purchaseOrderList}
                      offset={this.state.offset}
                      count={this.state.count}
                      limit={this.state.limit}
                      handlePagination={this.handlePagination}
                      onDelete={this.confirmDelete}
                      enabledActions={enabledActions}
                    />
                  );
                }
              })()}
            </div>
          </main>
        </section>
      </>
    );
  }
}
