import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import { Fields, Modes } from '@C/Banking/';
import services from '@C/CashiersOnDuty/_utils/data';
import { StaffList } from '@C/Utils';
import { authHeader } from '@H';

import './styles.scoped.scss';

const salaryTypeOptions = [
  { value: 'adv_salary', label: 'Advance salary' },
  { value: 'adv_return', label: 'Advance return' },
];

const CODSalary = (props) => {
  const editRef = useRef(null);
  const [, forceUpdate] = useState();
  const [salaryType, setSalaryType] = useState('adv_salary'); // ['adv_salary', 'adv_return']
  const [disabledPaymentOptions, setDisabledPaymentOptions] = useState([
    'pos',
    'wallet',
    'NEFT',
    'RTGS',
    'IMPS',
    'cheque',
  ]);
  const [state, setState] = useState({
    cashier_duty_id: props.match.params.duty,
    disabledPaymentModes: ['pos', 'wallet', 'NEFT', 'RTGS', 'IMPS', 'cheque'],
    time_stamp: props.match.params.date,
    staff_id: '',
    amount: 0,
    given_by_id: props.match.params.staff,
    notes: '',
    payment: {
      bank_id: null,
      payment_method: 'OFFLINE',
      source: 'CASH',
      check_date: '',
      check_number: '',
      mode: 'cash',
    },
    updated: false,
    isSubmitting: false,
    salaryList: [],
  });

  const simpleValidator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }),
  );

  useEffect(() => {
    getSalaryList();
  }, []);

  useEffect(() => {
    if (salaryType === 'adv_salary') {
      setDisabledPaymentOptions([
        'pos',
        'wallet',
        'NEFT',
        'RTGS',
        'IMPS',
        'cheque',
      ]);
      setState((prevState) => ({
        ...prevState,
        payment: { ...prevState.payment, mode: 'cash' },
      }));
    } else {
      setDisabledPaymentOptions([]);
    }
  }, [salaryType]);

  const getSalaryList = () => {
    services
      .getSalaryList(state.cashier_duty_id)
      .then((res) => {
        setState((prevState) => ({ ...prevState, salaryList: res.data.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const confirmDelete = (id) => {
    confirmAlert({
      title: 'Confirm transaction deletion',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteItem(id),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  const deleteItem = (id) => {
    const headers = authHeader(1);
    axios
      .delete(
        `${process.env.API_URL}/staff-management/bunk/cashier-duty-advance/?uuid=${id}`,
        {
          headers,
        },
      )
      .then(() => {
        toast.success('Transaction deleted successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        getSalaryList();
        props.onDataChange();
        props.onSalaryUpdate();
      });
  };

  const handleStaffChange = (e) => {
    setState((prevState) => ({ ...prevState, staff_id: e.value }));
  };

  const handleSalaryType = (e) => {
    setSalaryType(e.value);
  };

  const handlePaymentSelect = (mode, i) => {
    const list = { ...state.payment };
    list.mode = mode;
    if (mode === 'cash') {
      list.payment_method = 'OFFLINE';
      list.source = 'CASH';
    }
    setState((prevState) => ({ ...prevState, payment: list }));
  };

  const onDataChange = (e, i) => {
    const list = { ...state.payment };
    list.bank_id = e.bank_id;
    list.check_number = e.bank_reference_id;
    list.check_date = e.check_date;
    list.payment_method = e.payment_method;
    list.source = e.transaction_source;
    setState((prevState) => ({ ...prevState, payment: list }));
  };

  const handleChange = (e, i) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const formData = state;

    const headers = authHeader(1);

    if (salaryType === 'adv_salary') {
      axios
        .post(
          `${process.env.API_URL}/staff-management/bunk/advance-salary/`,
          formData,
          {
            headers,
          },
        )
        .then(() => {
          toast.success('Advance salary added successfully');
          window.scrollTo({
            behavior: 'smooth',
            top: editRef.current.offsetTop - 150,
          });
          setState({
            ...state,
            time_stamp: props.match.params.date,
            amount: 0,
            given_by_id: props.match.params.staff,
            notes: '',
            payment: {
              bank_id: null,
              payment_method: 'OFFLINE',
              source: 'CASH',
              check_date: '',
              check_number: '',
              mode: 'cash',
            },
            updated: false,
            isSubmitting: false,
          });
          getSalaryList();
          props.onDataChange();
          props.onSalaryUpdate();
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          setState({ ...state, isSubmitting: false });
        });
    } else {
      axios
        .post(
          `${process.env.API_URL}/staff-management/bunk/advance-repayment/`,
          formData,
          {
            headers,
          },
        )
        .then(() => {
          toast.success('Advance return added successfully');
          setState({
            ...state,
            time_stamp: props.match.params.date,
            staff_id: '',
            amount: 0,
            given_by_id: props.match.params.staff,
            notes: '',
            payment: {
              bank_id: null,
              payment_method: 'OFFLINE',
              source: 'CASH',
              check_date: '',
              check_number: '',
              mode: 'cash',
            },
            updated: !state.updated,
            isSubmitting: false,
          });
          getSalaryList();
          props.onDataChange();
          props.onSalaryUpdate();
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.response.data.message);
          setState({ ...state, isSubmitting: false });
        });
    }
  };

  return (
    <>
      <section className="widget form-card " ref={editRef}>
        <header>
          <h3> Salary </h3>
        </header>
        <main>
          <div className="form-content form-contents">
            <div className="input-wrapper with-label">
              <label> Staff name</label>
              {simpleValidator.current.message(
                'Staff',
                state.staff_id,
                'required',
              )}
              <StaffList
                value={state.staff_id}
                onChange={handleStaffChange}
                staffType={['Cashiers', 'Managers', 'other_staff']}
              />
            </div>
            <div className="input-wrapper with-label content">
              <label> Type</label>
              {simpleValidator.current.message(
                'Salary Type',
                salaryType,
                'required',
              )}
              <Select
                name="salary_type"
                className="select-box-container"
                classNamePrefix="raw-select"
                options={salaryTypeOptions}
                onChange={handleSalaryType}
                value={salaryTypeOptions.filter(
                  ({ value }) => value === salaryType,
                )}
              />
            </div>
            <div className="input-group">
              <div className="input-wrapper with-label">
                <label> Payment mode</label>
                <Modes
                  handleModeChange={handlePaymentSelect}
                  value={state.payment.mode || 'cash'}
                  disabledOptions={disabledPaymentOptions}
                />
              </div>
              <Fields
                method={state.payment.mode}
                onDataChange={onDataChange}
                bank={state.payment.bank}
                bank_reference_id={state.payment.bank_reference_id}
                check_date={state.payment.check_date}
              />
            </div>

            <div className="input-wrapper with-label">
              <label>Amount</label>
              {simpleValidator.current.message(
                'Amount',
                state.amount,
                'required',
              )}
              <input
                type="text"
                name="amount"
                onChange={handleChange}
                value={state.amount}
              />
            </div>
            <div className="input-wrapper with-label ">
              <label> Notes </label>
              <input
                type="text"
                value={state.notes}
                name="notes"
                onChange={handleChange}
              />
            </div>
            <div className="form-actions action">
              <button className="submit" onClick={submitForm}>
                Submit
              </button>
            </div>
          </div>
          <div className="table-wrapper mt-20 wt-20">
            <table>
              <thead>
                <tr>
                  <th>
                    <span> Staff name</span>
                  </th>
                  <th>
                    <span> Type </span>
                  </th>
                  <th>
                    <span> Amount </span>
                  </th>
                  <th>
                    <span> notes </span>
                  </th>
                  <th>
                    <span>Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {state.salaryList.map((item, i) => (
                  <tr key={i}>
                    <td>
                      {' '}
                      {item.staff.first_name}
                      {item.staff.last_name}{' '}
                    </td>
                    <td> {item.payment_for} </td>
                    <td> {item.after_tax} </td>
                    <td> {item.notes} </td>
                    <td className="item-actions">
                      <button
                        className="actions __delete"
                        onClick={() => confirmDelete(item.uuid)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </main>
      </section>
    </>
  );
};

export { CODSalary };
