import React, { Component } from 'react';
import dayjs from 'dayjs';

import services from '@C/Reports/_utils/data';
import { formatCurrency, TableGrid } from '@C/TableGrid';
import { StaffList } from '@C/Utils';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';

const col = [
  {
    name: 'Date',
    key: 'shortage',
    formatter(props) {
      return <>{dayjs(props.time_stamp).format('DD/MM/YYYY')}</>;
    },
    sortable: true,
  },
  {
    name: 'Cashier name',
    key: 'cashier',
    formatter(props) {
      return (
        <>
          {props.first_name} {props.last_name}
        </>
      );
    },
  },
  {
    name: 'Shift',
    key: 'cashier',
    formatter(props) {
      return <>{props.shift.shift}</>;
    },
  },
  {
    name: 'Shortage/ Excess',
    key: 'shortage',
    formatter(props) {
      return <>{formatCurrency(props.shortage)}</>;
    },
  },
  {
    name: 'Cumulative Shortage',
    key: 'shortage',
    formatter(props) {
      return <>{formatCurrency(props.total_shortage)}</>;
    },
  },
];

class CashierShortageReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shortageList: [],
      staff_id: '',
      start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
      loading: false,
      limit: 20,
      offset: 1,
      count: 0,
    };
    this.limitInput = React.createRef();
  }

  componentDidMount() {
    this.getShortageReport();
    const observer = new IntersectionObserver(this.handleObserver.bind(this), {
      threshold: 0.5,
    });
    this.setState({ observer });
    observer.observe(this.loader);
  }

  componentWillUnmount() {
    const { observer } = this.state;
    if (observer) {
      observer.disconnect();
    }
  }

  handleObserver(entities) {
    const { loading, count, limit, offset } = this.state;
    const loadMore = entities[0];
    if (loadMore.isIntersecting && !loading && count > offset * limit) {
      this.setState(
        (prevState) => ({ offset: prevState.offset + 1, loading: true }),
        () => this.getShortageReport(),
      );
    }
  }

  handleStaffChange = (e) => {
    this.setState({ staff_id: e.value });
  };

  handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date' && dayjs(value).isAfter(this.state.end_date)) {
      this.setState({ [name]: value, end_date: value });
    } else if (
      name === 'end_date' &&
      dayjs(value).isBefore(this.state.start_date)
    ) {
      this.setState({ [name]: value, start_date: value });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleQuickSearch = (e) => {
    e.preventDefault();
    if (
      this.limitInput.current.value === '' ||
      this.limitInput.current.value === '0'
    ) {
      return;
    }
    this.setState({ limit: this.limitInput.current.value }, () => {
      this.getShortageReport();
    });
  };

  resetFilter = () => {
    this.limitInput.current.value = 20;
    this.setState(
      {
        staff_id: '',
        start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
        limit: 20,
        offset: 1,
      },
      () => {
        this.getShortageReport();
      },
    );
  };

  getShortageReport = () => {
    const { staff_id, start_date, end_date, limit, offset } = this.state;
    this.setState({ loading: true });
    services
      .getCashierShortageReport({
        staff_id,
        start_date,
        end_date,
        limit,
        offset,
      })
      .then((response) => {
        this.setState((prevState) => ({
          shortageList:
            offset === 1
              ? response.data.data.results
              : [...prevState.shortageList, ...response.data.data.results],
          count: response.data.data.count,
          loading: false,
        }));
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };
  submitForm = (e) => {
    e.preventDefault();
    this.setState({ offset: 1 }, function () {
      this.getShortageReport();
    });
  };

  render() {
    const { shortageList } = this.state;

    return (
      <section className="widget list-table full-width inverted">
        <header>
          <h3> Cashier Shortage Report</h3>
        </header>
        <main>
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group">
                <label>From date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.start_date}
                  name="start_date"
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="filter-group">
                <label>To date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.end_date}
                  min={this.state.start_date}
                  onChange={this.handleDateChange}
                  name="end_date"
                />
              </div>

              <div className="filter-group">
                <label>Staff name:</label>
                <StaffList
                  value={this.state.staff_id}
                  onChange={this.handleStaffChange}
                  staffType={['Cashiers', 'Managers', 'Dealer']}
                />
              </div>
              <div className="filter-group with-icon">
                <label>
                  Results to display- {this.state.limit || 20} (Total:{' '}
                  {this.state.count})
                </label>
                <form onSubmit={this.handleQuickSearch}>
                  <input type="text" name="limit" ref={this.limitInput} />
                  <button
                    type="submit"
                    className="search-icon"
                    onClick={this.handleQuickSearch}
                  ></button>
                </form>
              </div>
              <div className="filter-group reset-filter">
                <button onClick={this.resetFilter}>Reset filters</button>
              </div>
              <div className="filter-group">
                  <button
                    className="submit"
                    type="submit"
                    onClick={this.submitForm}
                  >
                    Apply filter
                  </button>
                </div>
            </div>
          </div>
          <div className="table-wrapper">
            <div className="mini-summary">
              <ul>
                <li>
                  <span> Total shortage: </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {}).format(
                      shortageList[
                        shortageList.length - 1
                      ]?.shortage?.total_shortage.toFixed(2) || 0,
                    )}
                  </span>
                </li>
              </ul>
            </div>
            {(() => {
              if (this.state.loading) {
                return <DropLoaderLarge />;
              } else {
                return (
                  <TableGrid
                    col={col}
                    row={shortageList}
                    offset={this.state.offset}
                    count={this.state.count}
                    limit={this.state.limit}
                    hideActions={true}
                    pagination="disabled"
                    exportOptions={{
                      fileName: ` Cashier Shortage - ${this.state.start_date} to ${this.state.end_date}.pdf`,
                      reportName: 'Cashier Shortage Report',
                      reportDate: `Date - ${dayjs(this.state.start_date).format('D MMM YY')} to ${dayjs(this.state.end_date).format('D MMM YY')}`,
                      reportTotal: `Total shortage -  ${shortageList[shortageList.length - 1]?.shortage?.total_shortage.toFixed(2) || 0}`,
                      reportQuantity: '',
                      reportSaleTotal: '',
                      reportTCSTotal: '',
                      reportSales: '',
                      reportTesting: '',
                      reportBowser: '',
                      reportCustomerName: '',
                      reportGst: '',
                      reportOpeningBalance: '',
                      reportClosingBalance: '',
                    }}
                  />
                );
              }
            })()}
            <div
              ref={(ref) => {
                this.loader = ref;
              }}
            ></div>
          </div>
        </main>
      </section>
    );
  }
}

export { CashierShortageReport };
