import React from 'react';

import { Header, Navbar, NavPills } from '@C';
import ErrorBoundary from '@C/Error';
import { RouteSetup } from '@C/Loans';

const routes = [
  {
    name: 'Parties',
    path: '/account/loan/parties',
  },
  {
    name: 'Amount given/Lend',
    path: '/account/loan/lend',
  },
  {
    name: 'Amount received/Borrow',
    path: '/account/loan/borrow',
  },
  {
    name: 'Loan Ledger',
    path: '/account/loan/loan-ledger',
  },
];

const Loans = () => {
  return (
    <main>
      <div className="page-wrapper">
        <Header title=" Loan" />
        <Navbar />
        <div className="page-content">
          <section className="page-components">
            <div className="page-title">
              <h1> Loan Parties </h1>
            </div>
            <ErrorBoundary>
              <NavPills routes={routes} />
            </ErrorBoundary>
            <ErrorBoundary>
              <RouteSetup />
            </ErrorBoundary>
          </section>
        </div>
      </div>
    </main>
  );
};

export { Loans };
