import React, { Component } from 'react';

import { TableGrid } from '@C/TableGrid';

const col = [
  {
    name: 'Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Slip number',
    key: '',
  },
  {
    name: 'Vehicle',
    key: '',
  },

  {
    name: 'Product',
    key: '',
  },
  {
    name: 'Quantity',
    key: '',
  },

  {
    name: 'Amount',
    key: '',
  },
];

class InvoiceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceList: [],
      offset: 1,
      count: 0,
      limit: 20,
    };
  }

  render() {
    const { invoiceList } = this.state;

    return (
      <>
        <section className="widget full-width">
          <header>
            <h3> Invoice List </h3>
          </header>
          <main className="content-wrapper">
            <TableGrid
              col={col}
              row={invoiceList}
              count={this.state.count}
              hideActions={true}
            />
          </main>
        </section>
      </>
    );
  }
}
export { InvoiceList };
