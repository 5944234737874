import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import { Fields, Modes } from '@C/Banking/';
import { DynamicProductList } from '@C/CreditCustomers/_utils';
import services from '@C/CreditCustomers/_utils/data';
import GeneratedBillList from '@C/LiveMemo/RouteComponents/LiveMemo/GeneratedBillList';
import { TaxList } from '@C/Utils';
import { authHeader } from '@H';

class LiveMemo extends Component {
  constructor() {
    super();
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });

    this.myRef = React.createRef();
  }

  state = {
    bill_number: '',
    customer_name: '',
    mobile: '',
    email: '',
    notes: '',
    address: '',
    total: 0,
    gst: '',
    vehicle: '',
    time_stamp: dayjs().format('YYYY-MM-DD'),
    discount: 0,
    productList: [],
    product_list: [
      {
        product_id: '',
        product_type: '',
        price: 0,
        quantity: 0,
        discount: 0,
        tax: [],
        taxName: '',
        total_amount: 0,
        taxMultiplier: 0,
        amount: 0,
        unalteredAmount: 0,
      },
    ],
    payment_list: {
      bank_id: null,
      mode: '',
      payment_method: 'OFFLINE',
      bank_reference_id: '',
      check_date: '',
      transaction_source: 'CASH',
    },
    updated: false,
    editMode: false,
    isSubmitting: false,
  };

  addRow = () => {
    this.setState((prevState) => ({
      product_list: [
        ...prevState.product_list,
        {
          product_id: '',
          product_type: '',
          price: 0,
          quantity: 0,
          discount: 0,
          tax: [],
          taxName: '',
          total_amount: 0,
          taxMultiplier: 0,
          amount: 0,
          unalteredAmount: 0,
        },
      ],
    }));
  };

  handleCustomerChange = (e) => {
    this.setState({ customer_name: e?.value });
  };

  handleTaxChange = (e, l, i, m) => {
    const list = [...this.state.product_list];
    list[i].tax = e;
    list[i].taxName = l;
    list[i].taxMultiplier = m;
    this.setState({ product_list: list }, function () {
      this.reCalculateAmount(i);
    });
  };

  onInputChange = (e, i) => {
    const list = [...this.state.product_list];
    if (e.target.name == 'amount') {
      list[i][e.target.name] = e.target.value;
      list[i].unalteredAmount = e.target.value;
      list[i].discount = 0;
      list[i].quantity = (list[i].amount / list[i].price).toFixed(2);
    } else if (e.target.name == 'quantity' || e.target.name == 'price') {
      list[i][e.target.name] = e.target.value;
      list[i].amount = (
        list[i].quantity * list[i].price -
        list[i].discount
      ).toFixed(2);
      list[i].unalteredAmount = list[i].amount;
    } else {
      list[i][e.target.name] = e.target.value;
      list[i].amount = (
        list[i].unalteredAmount - (list[i].discount || 0)
      ).toFixed(2);
    }

    this.setState({ product_list: list }, function () {
      this.reCalculateAmount(i);
    });
  };

  handleChange = (e) => {
    if (e.target.type == 'number') {
      this.setState({ [e.target.name]: parseFloat(e.target.value) });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  deleteRow = (i) => {
    this.setState({
      product_list: this.state.product_list.filter((s, sindex) => i !== sindex),
    });
  };

  reCalculateAmount = (i) => {
    const list = [...this.state.product_list];
    const amount = parseFloat(list[i].amount);
    const taxMultiplier = parseFloat(list[i].taxMultiplier || 0);

    const tax = amount * (taxMultiplier / 100);
    const total_amount = parseFloat(amount + tax).toFixed(2);
    list[i].total_amount = total_amount;
    this.setState({ product_list: list });
  };

  handleProductChange = (e, i) => {
    const list = [...this.state.product_list];
    if (e.product_type == 'FUEL') {
      list[i].taxName = '';
    }
    list[i].product_id = e?.value;
    list[i].product_type = e?.product_type;
    this.setState({ product_list: list }, function () {
      this.reCalculateAmount(i);
    });
  };

  componentDidMount() {
    this.getProductList();
  }

  getProductList = () => {
    services
      .getProductList(this.state.time_stamp)
      .then((response) => {
        const tmp = response.data.data;
        const CategoryOptions = [];

        for (let i = 0; i < tmp.length; i++) {
          CategoryOptions.push({
            value: tmp[i].uuid,
            label: tmp[i].product || tmp[i].name,
            product_type: tmp[i].hasOwnProperty('number_of_units')
              ? 'LUBE'
              : 'FUEL',
          });
        }
        const isLube = (element) => element.product_type == 'LUBE';
        CategoryOptions.splice(CategoryOptions.findIndex(isLube), 0, {
          value: '',
          label: '------',
          isDisabled: true,
        });
        this.setState({ productList: CategoryOptions });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handlePaymentSelect = (mode, i) => {
    const list = { ...this.state.payment_list };
    list.mode = mode;
    if (mode == 'cash') {
      list.payment_type = 'OFFLINE';
      list.transaction_source = 'CASH';
    }
    this.setState({ payment_list: list });
  };

  onDataChange = (e, i) => {
    const list = { ...this.state.payment_list };
    list.bank_id = e.bank_id;
    list.check_number = e.bank_reference_id;
    list.check_date = e.check_date;
    list.payment_type = e.payment_method;
    list.transaction_source = e.transaction_source;
    this.setState({ payment_list: list });
  };
  submitForm = () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      const firstInvalidElement = document.querySelectorAll('.input-error')[0];

      if (firstInvalidElement) {
        firstInvalidElement.scrollIntoView({
          behavior: 'smooth',
          top: this.myRef.current.offsetTop - 100,
        });
      }
      return;
    }
    this.setState({ isSubmitting: true });
    const { productList, ...formData } = this.state;
    const headers = authHeader(1);
    if (this.state.editMode) {
      axios
        .put(
          `${process.env.API_URL}/credit-customer/bunk/live-memo/`,
          formData,
          {
            headers,
          },
        )
        .then(() => {
          toast.success('Live memo updated successfully');
          window.scrollTo({
            behavior: 'smooth',
            top: this.myRef.current.offsetTop - 150,
          });
          this.setState({
            bill_number: '',
            customer_name: '',
            mobile: '',
            email: '',
            notes: '',
            address: '',
            total: 0,
            gst: '',
            vehicle: '',
            time_stamp: dayjs().format('YYYY-MM-DD'),
            discount: 0,
            product_list: [
              {
                product_id: '',
                product_type: '',
                price: 0,
                quantity: 0,
                discount: 0,
                tax: [],
                taxName: '',
                total_amount: 0,
                taxMultiplier: 0,
                amount: 0,
              },
            ],
            payment_list: {
              bank_id: null,
              mode: '',
              payment_method: 'OFFLINE',
              bank_reference_id: '',
              check_date: '',
              transaction_source: 'CASH',
            },
            updated: !this.state.updated,
            isSubmitting: false,
          });
          this.validator.hideMessages();
        })
        .catch((error) => {
          toast.error(error.response.data.error);
          this.setState({ isSubmitting: false });
        });
    } else {
      axios
        .post(
          `${process.env.API_URL}/credit-customer/bunk/live-memo/`,
          formData,
          {
            headers,
          },
        )
        .then(() => {
          toast.success('Live memo added successfully');
          window.scrollTo({
            behavior: 'smooth',
            top: this.myRef.current.offsetTop - 150,
          });
          this.setState({
            bill_number: '',
            customer_name: '',
            mobile: '',
            email: '',
            notes: '',
            address: '',
            total: 0,
            gst: '',
            vehicle: '',
            time_stamp: dayjs().format('YYYY-MM-DD'),
            discount: 0,
            product_list: [
              {
                product_id: '',
                product_type: '',
                price: 0,
                quantity: 0,
                discount: 0,
                tax: [],
                taxName: '',
                total_amount: 0,
                taxMultiplier: 0,
                amount: 0,
              },
            ],
            payment_list: {
              bank_id: null,
              mode: '',
              payment_method: 'OFFLINE',
              bank_reference_id: '',
              check_date: '',
              transaction_source: 'CASH',
            },
            updated: !this.state.updated,
            isSubmitting: false,
          });
          this.validator.hideMessages();
        })
        .catch((error) => {
          toast.error(error.response.data.error);
          this.setState({ isSubmitting: false });
        });
    }
  };

  render() {
    const productLists = this.state.product_list;
    const liveData = this.state;
    const { editMode } = this.state;

    return (
      <>
        <section className="widget full-width grid-form" ref={this.myRef}>
          <header className="form-header">
            <h3> {editMode ? 'Edit' : ''} Live Memo Details </h3>
          </header>
          <main>
            <div className="form-content">
              <div className="input-wrapper with-label ">
                <label>Date </label>
                <input
                  type="date"
                  name="time_stamp"
                  value={liveData.time_stamp}
                  onChange={this.handleChange}
                />
              </div>
              <div className="input-wrapper with-label">
                <label>Invoice number</label>
                {this.validator.message(
                  'invoice_number',
                  liveData.bill_number,
                  'required',
                )}
                <input
                  type="text"
                  value={liveData.bill_number}
                  name="bill_number"
                  onChange={this.handleChange}
                />
              </div>
              <div className="input-wrapper with-label">
                <label>Customer name </label>
                {this.validator.message(
                  'customer',
                  liveData.customer_name,
                  'required|alpha_num_space|max:30,string',
                )}
                <input
                  type="text"
                  value={liveData.customer_name}
                  name="customer_name"
                  onChange={this.handleChange}
                />
              </div>
              <div className="input-wrapper with-label">
                <label>Email</label>
                {this.validator.message(
                  'email',
                  liveData.email,
                  'email',
                )}
                <input
                  type="text"
                  value={liveData.email}
                  name="email"
                  onChange={this.handleChange}
                />
              </div>
              <div className="input-wrapper with-label">
                <label>GST no</label>
                <input
                  type="text"
                  value={liveData.gst}
                  name="gst"
                  onChange={this.handleChange}
                />
              </div>
              <div className="input-wrapper with-label">
                <label>Mobile</label>
                {this.validator.message(
                  'mobile',
                  liveData.mobile,
                  'mobile|numeric|min:10,array|max:15,array',
                )}
                <input
                  type="text"
                  value={liveData.mobile}
                  name="mobile"
                  onChange={this.handleChange}
                />
              </div>
              <div className="input-wrapper with-label">
                <label> Address </label>
                <input
                  type="text"
                  value={liveData.address}
                  name="address"
                  onChange={this.handleChange}
                />
              </div>
              <div className="input-wrapper with-label">
                <label>Vehicle no</label>
                <input
                  type="text"
                  value={liveData.vehicle}
                  name="vehicle"
                  onChange={this.handleChange}
                />
              </div>
              <div className="input-group">
                <div className="input-wrapper with-label">
                  <label> Payment mode</label>
                  <Modes
                    did={0}
                    handleModeChange={this.handlePaymentSelect}
                    value={liveData.payment_list.mode || 'cash'}
                  />
                </div>
                <Fields
                  did={0}
                  method={liveData.payment_list.mode}
                  onDataChange={this.onDataChange}
                  bank={liveData.payment_list.bank}
                  bank_reference_id={liveData.payment_list.bank_reference_id}
                  check_date={liveData.payment_list.check_date}
                />
              </div>
              <div className="input-wrapper with-label">
                <label> Notes</label>
                <input
                  type="text"
                  name="notes"
                  value={liveData.notes}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </main>
        </section>
        <section className="widget full-width form-table">
          <header>
            <h3>Product Details</h3>
            <div className="actions">
              <button className="add-row" onClick={() => this.addRow()}>
                Add row
              </button>
            </div>
          </header>
          <main>
            <div className="form-content">
              <table>
                <thead>
                  <tr>
                    <th> Product </th>
                    <th>Price </th>
                    <th> Quantity</th>
                    <th>Discount</th>
                    <th>Amount</th>
                    <th>Tax</th>
                    <th>Total</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {productLists.map((item, i) => (
                    <tr key={i}>
                      <td>
                        <div className="input-wrapper">
                          {this.validator.message(
                            'product',
                            item.product_id,
                            'required',
                          )}
                          <DynamicProductList
                            onProductChange={this.handleProductChange}
                            value={item.product_id}
                            did={i}
                            options={this.state.productList}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper">
                          {this.validator.message(
                            'product_price',
                            item.price,
                            'required|numeric|min:0.1,num',
                          )}
                          <input
                            type="text"
                            value={item.price}
                            placeholder="price"
                            name="price"
                            onChange={() => this.onInputChange(event, i)}
                          />
                        </div>
                      </td>

                      <td>
                        <div className="input-wrapper">
                          {this.validator.message(
                            'product_quantity',
                            item.quantity,
                            'required|numeric|min:0.1,num',
                          )}
                          <input
                            type="text"
                            value={item.quantity}
                            placeholder="quantity"
                            name="quantity"
                            onChange={() => this.onInputChange(event, i)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper">
                          {this.validator.message(
                            'product_discount',
                            item.discount,
                            'required|numeric|num',
                          )}
                          <input
                            type="text"
                            value={item.discount}
                            placeholder="discount"
                            name="discount"
                            onChange={() => this.onInputChange(event, i)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper">
                          {this.validator.message(
                            'product_amount',
                            item.amount,
                            'required',
                          )}
                          <input
                            type="text"
                            value={item.amount}
                            placeholder="Amount"
                            name="amount"
                            onChange={() => this.onInputChange(event, i)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper">
                          <TaxList
                            did={i}
                            onChange={this.handleTaxChange}
                            value={item.taxName}
                          />
                        </div>
                      </td>

                      <td>
                        <div className="input-wrapper">
                          <input
                            type="text"
                            value={item.total_amount}
                            placeholder="Total"
                            disabled
                          />
                        </div>
                      </td>
                      <td>
                        {' '}
                        <div className="input-wrapper _actions">
                          {i > 0 ? (
                            <button
                              className="delete-row icon-only"
                              onClick={() => this.deleteRow(i)}
                            >
                              Delete row
                            </button>
                          ) : (
                            ''
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <ul className="table-summary">
                <li>
                  <label>Discount: </label>
                  <span>
                    {productLists.reduce((total, item) => {
                      return +total + +item.discount;
                    }, 0)}
                  </span>
                </li>
                <li>
                  <label>Total: </label>
                  <span>
                    {productLists.reduce((total, item) => {
                      return +total + +item.total_amount;
                    }, 0)}
                  </span>
                </li>
              </ul>
            </div>
            <div className="form-actions">
              <button
                className="submit"
                type="submit"
                onClick={this.submitForm}
              >
                Submit
              </button>
            </div>
          </main>
        </section>
        <GeneratedBillList key={this.state.updated} onEdit={this.editMode} />
      </>
    );
  }
}
export { LiveMemo };
