import React, { Component } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import services from '@C/CashiersOnDuty/_utils/data';
import { authHeader } from '@H';

import EditCashSummary from './EditCashSummary';

import './styles.scoped.scss';

class CashSummary extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      cashier_id: this.props.match.params.staff,
      cashier_duty_id: this.props.match.params.duty,
      time_stamp: props.match.params.date,
      cash_list: [
        {
          amount: 0,
          cash_code: 1000,
        },
        {
          amount: 0,
          cash_code: 1001,
        },
        {
          amount: 0,
          cash_code: 1002,
        },
        {
          amount: 0,
          cash_code: 1003,
        },
      ],
      selectedId: '',
      showModal: false,
      cash_code: '',
      isSubmitting: false,
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
  }
  editDetails = (selectedId, cash_code, cashier_duty_id) => {
    this.setState({ selectedId, showModal: true, cash_code });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  componentDidMount() {
    this.getCashSummary();
  }

  getCashSummary = () => {
    services
      .getCashSummary(
        this.props.match.params.staff,
        this.props.match.params.date,
        this.state.cashier_duty_id,
      )
      .then((response) => {
        this.setState({ cash_list: response.data.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onInputChange = (e, i) => {
    const cash_list = [...this.state.cash_list];
    cash_list[i].amount = e.target.value;
    this.setState({ cash_list: cash_list });
  };

  updateDenomination = () => {
    this.getCashSummary();
    this.props.onDataChange();
  };

  handleKeyDown = (e, i) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.submitForm();
    }
  };

  submitForm = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState({ isSubmitting: true });
    const formData = this.state;
    const headers = authHeader(1);
    axios
      .post(`${process.env.API_URL}/bunk/cash-management/`, formData, {
        headers,
      })
      .then(() => {
        toast.success('Cash details added successfully');
        window.scrollTo({
          behavior: 'smooth',
          top: this.myRef.current.offsetTop - 150,
        });
        this.props.onDataChange();
        this.setState({
          updated: !this.state.updated,
          isSubmitting: false,
        });
        this.validator.hideMessages();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        this.setState({ isSubmitting: false });
      });
  };

  render() {
    const cash_list = this.state.cash_list;
    return (
      <>
        <section className="widget form-card" ref={this.myRef}>
          <header>
            <h3>Cash Summary</h3>
          </header>
          <main>
            <form>
              <div className="input-wrapper with-label">
                <label>Total cash in hand</label>
                {this.validator.message(
                  'cash_list[3].amount',
                  cash_list[3].amount,
                  'required|numeric',
                )}
                <input
                  name="amount"
                  type="text"
                  onChange={() => this.onInputChange(event, 3)}
                  onKeyDown={(e) => this.handleKeyDown(e, 3)}
                  value={cash_list[3].amount}
                />
                <button
                  className="actions __pay"
                  onClick={(e) => {
                    e.preventDefault();
                    this.editDetails(
                      3,
                      cash_list[3].cash_code,
                      this.state.cashier_duty_id,
                    );
                  }}
                >
                  Edit
                </button>
              </div>
              <div className="input-wrapper with-label">
                <label>Cash received from office</label>
                {this.validator.message(
                  'cash_list[0].amount',
                  cash_list[0].amount,
                  'required|numeric',
                )}
                <input
                  name="amount"
                  type="text"
                  onChange={() => this.onInputChange(event, 0)}
                  onKeyDown={(e) => this.handleKeyDown(e, 0)}
                  value={cash_list[0].amount}
                />
                <button
                  className="actions __pay"
                  onClick={(e) => {
                    e.preventDefault();
                    this.editDetails(
                      0,
                      cash_list[0].cash_code,
                      this.state.cashier_duty_id,
                    );
                  }}
                >
                  Edit
                </button>
              </div>
              <div className="input-wrapper with-label">
                <label>Cash returned to office</label>
                {this.validator.message(
                  'cash_list[2].amount',
                  cash_list[2].amount,
                  'required|numeric',
                )}
                <input
                  name="amount"
                  type="text"
                  onChange={() => this.onInputChange(event, 2)}
                  onKeyDown={(e) => this.handleKeyDown(e, 2)}
                  value={cash_list[2].amount}
                />
                <button
                  className="actions __pay"
                  onClick={(e) => {
                    e.preventDefault();
                    this.editDetails(
                      2,
                      cash_list[2].cash_code,
                      this.state.cashier_duty_id,
                    );
                  }}
                >
                  Edit
                </button>
              </div>
              <div className="input-wrapper with-label">
                <label>Cash given to manager</label>
                {this.validator.message(
                  'cash_list[1].amount',
                  cash_list[1].amount,
                  'required|numeric',
                )}
                <input
                  name="amount"
                  type="text"
                  onChange={() => this.onInputChange(event, 1)}
                  onKeyDown={(e) => this.handleKeyDown(e, 1)}
                  value={cash_list[1].amount}
                />
                <button
                  className="actions __pay"
                  onClick={(e) => {
                    e.preventDefault();
                    this.editDetails(
                      1,
                      cash_list[1].cash_code,
                      this.state.cashier_duty_id,
                    );
                  }}
                >
                  Edit
                </button>
              </div>
              <div className="cta-wrapper">
                <button
                  className={`submit ${this.state.isSubmitting ? 'loading' : ''}`}
                  onClick={this.submitForm}
                >
                  Submit
                </button>
              </div>
            </form>
          </main>
        </section>
        <Modal isOpen={this.state.showModal} onRequestClose={this.closeModal}>
          <div className="modal-component">
            <EditCashSummary
              selectedId={this.state.selectedId}
              onRequestClose={this.closeModal}
              cash_code={this.state.cash_code}
              cashier_id={this.state.cashier_id}
              cashier_duty_id={this.state.cashier_duty_id}
              time_stamp={this.state.time_stamp}
              updateDenomination={this.updateDenomination}
            />
          </div>
        </Modal>
      </>
    );
  }
}

export default CashSummary;
