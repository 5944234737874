import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import Modal from 'react-modal';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import services from '@C/CashiersOnDuty/_utils/data';
import {
  CashierList,
  GunsList,
  OnDuty,
  PastDuty,
} from '@C/CashiersOnDuty/RouteComponents/AssignDuty';
import { DashboardFuelPrice } from '@C/Dashboard/Components';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { ShiftsList } from '@C/Utils/ShiftsList';
import { authHeader } from '@H';
import { authService } from '@S';

import './styles.scoped.scss';

let c_list;
let g_list;

const AssignDuty = (props) => {
  const history = useHistory();
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } }),
  );
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const gunsListRef = useRef();
  const [date, setDate] = useState(
    dayjs(useParams().date).format('YYYY-MM-DD'),
  );
  const [state, setState] = useState({
    cashiersList: [],
    staff_name: 'Select cashier',
    staff_id: '',
    gun_list: [],
    step: 2,
    gunsList: [],
    dutyStatus: Math.random().toString(36).substr(2, 5),
    showModal: false,
  });

  useEffect(() => {
    setLoading(true);
    history.push(`/cashiers-on-duty/assign-duty/${date}`);
    getDSRDates(date);
    getStaffList();
    getGunsList();
  }, [date]);

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const closeModalAndReload = () => {
    setShowModal(false);
    window.location.reload();
  };

  const getDSRDates = (date) => {
    services
      .getDSRDates(dayjs(date).month() + 1, dayjs(date).year())
      .then((response) => {
        const tmp = response.data.data;
        const eventsArr = [
          {
            dsr_locked: [],
          },
          {
            dsr_unlocked: [],
          },
        ];
        tmp.forEach((item) => {
          if (item.lock_status) {
            eventsArr[0]['dsr_locked'].push(new Date(item.time_stamp));
          } else {
            eventsArr[1]['dsr_unlocked'].push(new Date(item.time_stamp));
          }
        });
        setState((state) => ({
          ...state,
          highlightedDates: eventsArr,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStaffList = () => {
    services
      .getStaffList(date)
      .then((response) => {
        const tmp = response.data.data;
        c_list = [];
        for (let i = 0; i < tmp.length; i++) {
          c_list.push({
            uuid: tmp[i].uuid,
            label: `${tmp[i].staff_details.first_name} ${tmp[i].staff_details.last_name}`,
            status: tmp[i].is_available,
            duty_date: props.date,
          });
        }
        setState((state) => ({
          ...state,
          cashiersList: c_list,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getGunsList = () => {
    services
      .getGunsList(date)
      .then((response) => {
        const tmp = response.data.data;
        g_list = [];
        for (let i = 0; i < tmp.length; i++) {
          g_list.push({
            uuid: tmp[i].uuid,
            label: tmp[i].name,
            opening: tmp[i].readings.opening_reading,
            tank: tmp[i].tank.uuid,
            status:
              tmp[i].is_available === true &&
              tmp[i].tank.product.is_last_updated === true
                ? true
                : false,
            tank_name: tmp[i].tank.name,
            price_updated: tmp[i].tank.product.is_last_updated,
          });
        }
        setState((state) => ({
          ...state,
          gunsList: g_list,
        }));
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleShiftChange = (value) => {
    setState((state) => ({
      ...state,
      shift_id: value.value,
    }));
  };

  const handleCashierChange = (id, name) => {
    setState((state) => ({
      ...state,
      staff_id: id,
      staff_name: name,
    }));
  };

  const handleGunsChange = (e) => {
    setState((state) => ({
      ...state,
      gun_list: e,
    }));
  };

  const handleDate = (e) => {
    setDate(dayjs(e).format('YYYY-MM-DD'));
  };

  const resetFields = () => {
    setState((state) => ({
      ...state,
      cashierSelected: Math.random().toString(36).substr(2, 5),
      dutyStatus: Math.random().toString(36).substr(2, 5),
      gunStatus: !state.gunStatus,
    }));
  };

  const assignDuty = () => {
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    if (state.gun_list.some((item) => item.hasError === true)) {
      // scroll to gunsListRef
      // scroll to the first input with class 'has-error' in the gunsListRef
      gunsListRef.current
        .querySelector('.has-error')
        .scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }

    if (
      state.gun_list.length < 1 ||
      state.staff_id === '' ||
      state.shift_id === 'Select shift'
    ) {
      toast.error('Please select cashier/shift/guns before assigning duty');

      return;
    }

    const formData = {
      staff_id: state.staff_id,
      shift_id: state.shift_id,
      gun_list: state.gun_list,
      cashier_duty_time: date,
    };

    const headers = authHeader(1);
    axios
      .put(`${process.env.API_URL}/bunk/allocate-cashier/`, formData, {
        headers,
      })
      .then(() => {
        toast.success('Duty assigned successfully');
        window.location.reload();
        simpleValidator.current.hideMessages();
      })
      .catch((error) => {
        toast.error(error.response.data.error);
      });
  };

  const { gun_list, gunsList, cashiersList, staff_name } = state;

  if (authService.currentUserRole === 'Cashier') {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <section className="widget full-width actions-widget">
        <main>
          <div className="input-wrapper">
            <DatePicker
              selected={new Date(date)}
              dateFormat="d MMM yyyy"
              onSelect={handleDate}
              maxDate={new Date()}
              highlightDates={state.highlightedDates}
              onMonthChange={getDSRDates}
              popperPlacement="bottom-start"
            />
            <button className="blue-btn" onClick={openModal}>
              Update fuel price
            </button>
          </div>
          <div className="staff-info-container">
            <span className="user-avatar" />
            <span>{staff_name}</span>
            <div className="set-duty-wrapper">
              <button className="green-btn" onClick={() => assignDuty()}>
                Assign duty
              </button>
            </div>
          </div>
          <div className="input-wrapper">
            {simpleValidator.current.message(
              'shift_id',
              state.shift_id,
              'required',
            )}
            <ShiftsList value={state.shift_id} onChange={handleShiftChange} />
          </div>
          <div className="list-container">
            <ul>
              {gun_list.map((item, i) => {
                return (
                  <li key={i}>
                    {item.name} ({item.opening_reading})
                  </li>
                );
              })}
            </ul>
          </div>
        </main>
      </section>
      {(() => {
        if (loading) {
          return <DropLoaderLarge />;
        } else {
          return (
            <>
              <PastDuty key={date} date={date} />

              <OnDuty key={state.dutyStatus} />

              <CashierList
                onChange={handleCashierChange}
                date={date}
                cashiersList={cashiersList}
              />
              <GunsList
                onChange={handleGunsChange}
                gunsList={gunsList}
                key={state.gunStatus}
                cstmRef={gunsListRef}
              />
            </>
          );
        }
      })()}

      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel="Fule price modal"
        shouldCloseOnOverlayClick={true}
        className="sub-xl-modal-container"
        overlayClassName="xl-modal-overlay"
        parentSelector={() => document.querySelector('.page-components')}
      >
        <div className="modal-component">
          <DashboardFuelPrice
            date={date}
            disabled={true}
            onRequestClose={closeModalAndReload}
          />
        </div>
      </Modal>
    </>
  );
};

export { AssignDuty };
