import axios from 'axios';

import { authHeader } from '@H';

export default {
  getStaffList: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/staff-management/bunk/staff/`,
    }),

  getStaffDetails: (staff_id) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/staff-management/bunk/staff/?staff_id=${staff_id}`,
    }),

  getStaffListItem: (id) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/staff-management/bunk/staff/?staff_id=${id}`,
    }),

  getAttendance: (staff_id, start_date, end_date) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/staff-management/bunk/attendance/?staff_id=${staff_id}&start_date=${start_date}&end_date=${end_date}`,
    }),
  getAdvanceRepaymentList: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/staff-management/bunk/advance-repayment/?staff_id=${data.staff_id}&start_date=${data.start_date}&end_date=${data.end_date}&limit=${data.limit}&offset=${data.offset}&received_by_id=${data.given_by}`,
    }),
  getAdvanceSalaryList: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/staff-management/bunk/advance-salary/?staff_id=${data.staff_id}&start_date=${data.start_date}&end_date=${data.end_date}&limit=${data.limit}&offset=${data.offset}&given_by=${data.given_by}`,
    }),
  getStaffSalaryDetails: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/staff-management/bunk/salary/?staff_id=${data.staff_id}&month=${data.month}&year=${data.year}`,
    }),
  getSalaryList: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/staff-management/bunk/salary/?start_date=${data.start_date}&end_date=${data.end_date}&limit=${data.limit}&offset=${data.offset}&staff_id=${data.staff_id}&received_by_id=${data.given_by}&is_page=1`,
    }),
  getLatestAdvancePending: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/staff-management/bunk/advance-repayment/?staff_id=${data.staff_id}&is_pending_amount=1`,
    }),
};
