import React from 'react';

import { Income } from '@C/ManageIE/RouteComponents/';
import { useGlobalStore } from '@stores/globalStore';

const OtherIncome = (props) => {
  const { syncCODCollectionData } = props;

  const updateTotalSummary = () => {
    syncCODCollectionData(props.match.params.duty, props.match.params.staff);
  };

  return (
    <>
      <Income {...props} cod={true} onDataChange={updateTotalSummary} />
    </>
  );
};

export { OtherIncome };
