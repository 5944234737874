export function calculateSVGTransformY(currentFuelLevel, maxCapacity) {
  const fullFuelTransformY = -12150;
  const emptyFuelTransformY = -11948;
  const fuelPercentage = currentFuelLevel / maxCapacity;
  const transformY =
    emptyFuelTransformY -
    fuelPercentage * (emptyFuelTransformY - fullFuelTransformY);

  return transformY;
}
