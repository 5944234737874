import React from 'react';

const PaymentMethodInfo = ({ source, method, bank }) => {
  if (source === 'CASH') {
    return <span>Cash</span>;
  } else if (source === 'SWIPE') {
    return <span>POS - {bank?.bank_name || '--'}</span>;
  } else if (source === 'WALLET') {
    return (
      <span>
        Wallet - {bank?.name || '--'} - {bank?.settlement_bank}
      </span>
    );
  } else if (source === 'SELF' && method === 'CHECK') {
    return <span>Cheque - {bank?.bank_name || '--'}</span>;
  } else {
    return (
      <span>
        Internet Banking - {`${method || ''} - ${bank?.bank_name || ''}`}
      </span>
    );
  }
};

export { PaymentMethodInfo };
