import React, { Component } from 'react';
import Geonames from 'geonames.js';

import ReferralList from '@C/ReferralProgram/RouteComponents/ReferralProgram/ReferralList';

import './styles.scoped.scss';

const geonames = Geonames({
  username: 'hectonetworks',
  lan: 'en',
  encoding: 'JSON',
});

class ReferralProgram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: {
        city: '',
        country: '',
        pincode: '',
        state: '',
      },
    };
  }

  handlePincodeChange = (e) => {
    const { vendor } = this.state;
    vendor.address.pincode = +e.target.value;
    this.setState({ vendor: vendor });
    const postalcode = e.target.value;
    if (postalcode.length == 6) {
      this.setState({ loading: true });
      geonames
        .postalCodeSearch({ postalcode: `${postalcode}` })
        .then((response) => {
          vendor.address.city = response.postalCodes[0].adminName2;
          vendor.address.state = response.postalCodes[0].adminName1;
          vendor.address.country = 'India';
          this.setState({ vendor: vendor, loading: false });
        });
    }
  };

  render() {
    return (
      <>
        <section className="widget full-width">
          <header>
            <h3> Refer Your Friend </h3>
          </header>
          <main>
            <form className="inline">
              <div className="input-wrapper with-label">
                <label> Bunk name </label>
                <input
                  type="text"
                  value={this.state.bunk_name}
                  name="bunk_name"
                  placeholder="Bunk Name"
                  onChange={this.handleChange}
                />
              </div>
              <div className="input-wrapper with-label">
                <label> Dealer name</label>
                <input
                  type="text"
                  value={this.state.dealer_name}
                  name="dealer_name"
                  placeholder="Dealer name"
                  onChange={this.handleChange}
                />
              </div>
              <div className="input-wrapper with-label">
                <label> OMC </label>
                <input
                  type="text"
                  value={this.state.omc}
                  name="omc"
                  placeholder="OMC"
                  onChange={this.handleChange}
                />
              </div>
              <div className="input-wrapper with-label">
                <label> Contact number</label>
                <input
                  type="text"
                  value={this.state.contact_number}
                  name="contact_number"
                  placeholder="Contact number"
                  onChange={this.handleChange}
                />
              </div>
              <div className="input-wrapper with-label ">
                <label htmlFor="pincode">Pincode</label>
                <input
                  type="text"
                  id="pincode"
                  name="pincode"
                  placeholder="Pincode"
                  onChange={this.handlePincodeChange}
                  value={this.state.pincode}
                />
              </div>

              <div className="input-wrapper with-label ">
                <label htmlFor="District">District</label>
                <input
                  type="text"
                  id="district"
                  name="district"
                  placeholder="Disctrict"
                  onChange={this.handleChange}
                  value={this.state.disctrict}
                />
              </div>

              <div className="input-wrapper with-label ">
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  id="state"
                  name="state"
                  placeholder="State"
                  onChange={this.handleChange}
                  value={this.state.state}
                />
              </div>
              <div className="input-wrapper with-label ">
                <label> Remark / Notes </label>
                <textarea
                  value={this.state.notes}
                  name="notes"
                  onChange={this.handleChange}
                  placeholder="Notes"
                />
              </div>
              <div className="form-actions">
                <button
                  type="submit"
                  className="submit"
                  onClick={this.submitForm}
                >
                  Submit
                </button>
              </div>
            </form>
          </main>
        </section>
        <ReferralList key={this.state.updated} onEdit={this.editMode} />
      </>
    );
  }
}
export { ReferralProgram };
