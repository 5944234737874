import React, { Component } from 'react';

class VendorPayment extends Component {
  constructor() {
    super();
  }

  render() {
    const summary = this.props.summary;
    return (
      <>
        <section className="widget dsr-table">
          <header></header>
          <main>
            <table className="dsr" data-name="Vendor Payment">
              <thead>
                <tr>
                  <th colSpan={2} className="alert-brand">
                    {' '}
                    Vendor Payment
                  </th>
                </tr>
                <tr data-header={true}>
                  <th> Vendor name </th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {summary.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td>
                        {' '}
                        {`${item.vendor_first_name} ${item.vendor_last_name}`}
                      </td>
                      <td>
                        {new Intl.NumberFormat('en-IN', {
                          style: 'currency',
                          currency: 'INR',
                        }).format(item.total_paid_amount.toFixed(2) || 0)}
                      </td>
                    </tr>
                  );
                })}
                <tr className="summary-row">
                  <td colSpan="1">Total </td>
                  <td>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      summary.reduce(
                        (a, b) => a + (b['total_paid_amount'] || 0),
                        0,
                      ) || 0,
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </main>
        </section>
      </>
    );
  }
}

export default VendorPayment;
