import React from 'react';

import { Header, Navbar, NavPills } from '@C';
import { RouteSetup } from '@C/Banking';
import ErrorBoundary from '@C/Error';

const routes = [
  {
    name: 'Cash Deposit',
    path: '/account/banking/cash-deposit',
  },
  {
    name: 'B2B Trasfer',
    path: '/account/banking/b2b-transfer',
  },
  {
    name: 'Withdrawal From Bunk',
    path: '/account/banking/withdrawal-from-bunk',
    display: false,
  },
  {
    name: 'POS Settlement',
    path: '/account/banking/pos-settlement',
  },
  {
    name: 'Wallet Settlement',
    path: '/account/banking/wallet-settlement',
  },
];

const Banking = () => {
  return (
    <main>
      <div className="page-wrapper">
        <Header title=" Banking" />
        <Navbar />
        <div className="page-content">
          <section className="page-components">
            <ErrorBoundary>
              <NavPills routes={routes} />
            </ErrorBoundary>
            <ErrorBoundary>
              <RouteSetup />
            </ErrorBoundary>
          </section>
        </div>
      </div>
    </main>
  );
};

export { Banking };
