import React, { Component } from 'react';
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';

import { BankList, POSList, WalletList } from '@C/Utils';

const getPaymentModes = (source, method) => {
  if (source === 'WALLET') {
    return 'wallet';
  } else if (source == 'SWIPE') {
    return 'pos';
  } else if (source == 'CASH') {
    return 'cash';
  } else if (source == 'SELF' && method == 'CHECK') {
    return 'cheque';
  } else {
    switch (method) {
      case 'RTGS':
        return 'RTGS';
      case 'NEFT':
        return 'NEFT';
      default:
        return 'IMPS';
    }
  }
};

class Modes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentType: [
        {
          value: 'cash',
          label: 'Cash',
          isDisabled:
            (props.disabledOptions && props.disabledOptions.includes('cash')) ||
            false,
        },
        {
          value: 'pos',
          label: 'POS',
          isDisabled:
            (props.disabledOptions && props.disabledOptions.includes('pos')) ||
            false,
        },
        {
          value: 'wallet',
          label: 'Wallet',
          isDisabled:
            (props.disabledOptions &&
              props.disabledOptions.includes('wallet')) ||
            false,
        },
        {
          value: 'NEFT',
          label: 'NEFT',
          isDisabled:
            (props.disabledOptions && props.disabledOptions.includes('NEFT')) ||
            false,
        },
        {
          value: 'RTGS',
          label: 'RTGS',
          isDisabled:
            (props.disabledOptions && props.disabledOptions.includes('RTGS')) ||
            false,
        },
        {
          value: 'IMPS',
          label: 'IMPS',
          isDisabled:
            (props.disabledOptions && props.disabledOptions.includes('IMPS')) ||
            false,
        },
        {
          value: 'cheque',
          label: 'Cheque',
          isDisabled:
            (props.disabledOptions &&
              props.disabledOptions.includes('cheque')) ||
            false,
        },
      ],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.disabledOptions !== this.props.disabledOptions) {
      this.setState({
        paymentType: [
          {
            value: 'cash',
            label: 'Cash',
            isDisabled:
              (this.props.disabledOptions &&
                this.props.disabledOptions.includes('cash')) ||
              false,
          },
          {
            value: 'pos',
            label: 'POS',
            isDisabled:
              (this.props.disabledOptions &&
                this.props.disabledOptions.includes('pos')) ||
              false,
          },
          {
            value: 'wallet',
            label: 'Wallet',
            isDisabled:
              (this.props.disabledOptions &&
                this.props.disabledOptions.includes('wallet')) ||
              false,
          },
          {
            value: 'NEFT',
            label: 'NEFT',
            isDisabled:
              (this.props.disabledOptions &&
                this.props.disabledOptions.includes('NEFT')) ||
              false,
          },
          {
            value: 'RTGS',
            label: 'RTGS',
            isDisabled:
              (this.props.disabledOptions &&
                this.props.disabledOptions.includes('RTGS')) ||
              false,
          },
          {
            value: 'IMPS',
            label: 'IMPS',
            isDisabled:
              (this.props.disabledOptions &&
                this.props.disabledOptions.includes('IMPS')) ||
              false,
          },
          {
            value: 'cheque',
            label: 'Cheque',
            isDisabled:
              (this.props.disabledOptions &&
                this.props.disabledOptions.includes('cheque')) ||
              false,
          },
        ],
      });
    }
  }

  handleChange = (selectedOption) => {
    this.props.handleModeChange(selectedOption.value, this.props.did);
  };

  render() {
    const options = this.state.paymentType;
    return (
      <Select
        onChange={this.handleChange}
        options={options}
        className="select-box-container"
        classNamePrefix="raw-select"
        name="payment_mode"
        placeholder="Select payment mode"
        defaultValue={{ label: 'Cash', value: 'cash' }}
        value={options.filter(({ value }) => value === this.props.value)}
        isDisabled={this.props.disabled || false}
      />
    );
  }
}

class Fields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      method: props.method,
      payment_info: {},
    };
  }

  handleData = (e) => {
    this.setState({ payment_info: e }, function () {
      this.props.onDataChange(this.state.payment_info, this.props.did);
    });
  };

  componentDidMount() {}

  render() {
    const method = this.props.method;
    switch (method) {
      case 'wallet':
        return (
          <div className="nested-input-wrapper">
            <WalletPayments
              {...this.props}
              handleWalletChange={this.handleData}
            />
          </div>
        );
      case 'pos':
        return (
          <div className="nested-input-wrapper">
            <PosPayments {...this.props} handlePosChange={this.handleData} />
          </div>
        );
      case 'NEFT':
      case 'RTGS':
      case 'IMPS':
        return (
          <div className="nested-input-wrapper">
            <IBPayments
              {...this.props}
              method={method}
              handleBankChange={this.handleData}
              key={method}
            />
          </div>
        );
      case 'cheque':
        return (
          <div className="nested-input-wrapper">
            <ChequePayments
              {...this.props}
              handleCheckChange={this.handleData}
            />
          </div>
        );
      default:
        return <></>;
    }
  }
}

class PosPayments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      PosList: '',
      payment_info: {
        bank_id: props.bank?.uuid || '',
        bank_reference_id: props.bank_reference_id || '',
        check_date: props.check_date || '',
        payment_method: 'IMPS',
        transaction_source: 'SWIPE',
      },
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
  }

  handleDropChange = (selectedOption) => {
    const i = this.state.payment_info;
    i.bank_id = selectedOption.value;
    this.setState({ payment_info: i }, function () {
      this.props.handlePosChange(this.state.payment_info);
    });
  };

  render() {
    return (
      <div className="input-wrapper with-label">
        <label>POS</label>
        <POSList
          handleBankChange={this.handleDropChange}
          value={this.state.payment_info.bank_id}
          disabled={this.props.disabled || false}
        />
      </div>
    );
  }
}

class WalletPayments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      WalletList: '',
      payment_info: {
        bank_id: props.bank?.uuid || '',
        bank_reference_id: props.bank_reference_id || '',
        check_date: props.check_date || '',
        payment_method: 'IMPS',
        transaction_source: 'WALLET',
      },
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
  }

  handleDropChange = (selectedOption) => {
    const i = this.state.payment_info;
    i.bank_id = selectedOption.value;
    this.setState({ payment_info: i }, function () {
      this.props.handleWalletChange(this.state.payment_info);
    });
  };

  render() {
    return (
      <div className="input-wrapper with-label">
        <label>Wallet</label>
        <WalletList
          handleBankChange={this.handleDropChange}
          value={this.state.payment_info.bank_id}
          disabled={this.props.disabled || false}
        />
      </div>
    );
  }
}

class IBPayments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      BankList: '',
      payment_info: {
        bank_id: props.bank?.uuid || '',
        bank_reference_id: props.bank_reference_id || '',
        check_date: '',
        payment_method: props.method,
        transaction_source: 'SELF',
      },
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
  }

  handleDropChange = (selectedOption) => {
    const i = this.state.payment_info;
    i.bank_id = selectedOption.value;
    this.setState({ payment_info: i }, function () {
      this.props.handleBankChange(this.state.payment_info);
    });
  };

  handleChange = (e) => {
    const val = e.target.value;
    const i = this.state.payment_info;
    i[e.target.name] = val;
    this.setState({ payment_info: i }, function () {
      this.props.handleBankChange(this.state.payment_info);
    });
  };

  render() {
    return (
      <>
        <div className="input-wrapper with-label">
          <label>Bank</label>
          <BankList
            handleBankChange={this.handleDropChange}
            value={this.state.payment_info.bank_id}
            disabled={this.props.disabled || false}
          />
        </div>
        <div className="input-wrapper with-label">
          <label>Reference ID</label>
          <input
            name="bank_reference_id"
            type="text"
            value={this.state.payment_info.bank_reference_id}
            onChange={this.handleChange}
            disabled={this.props.disabled || false}
          />
        </div>
      </>
    );
  }
}

class ChequePayments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      BankList: '',
      payment_info: {
        bank_id: props.bank?.uuid || '',
        bank_reference_id: props.bank_reference_id || '',
        check_date: props.check_date || '',
        payment_method: 'CHECK',
        transaction_source: 'SELF',
      },
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
  }

  handleDropChange = (selectedOption) => {
    const i = this.state.payment_info;
    i.bank_id = selectedOption.value;
    this.setState({ payment_info: i }, function () {
      this.props.handleCheckChange(this.state.payment_info);
    });
  };

  handleChange = (e) => {
    const val = e.target.value;
    const i = this.state.payment_info;
    i[e.target.name] = val;
    this.setState({ payment_info: i }, function () {
      this.props.handleCheckChange(this.state.payment_info);
    });
  };

  validateFields = () => {
    if (this.validator.allValid()) {
      this.validator.hideMessages();
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
  };

  render() {
    const validation = this.state.payment_info;
    const options = this.state.BankList;
    return (
      <>
        <div className="input-wrapper with-label">
          <label>Bank</label>
          {this.validator.message('bank_id', validation.bank_id, 'required', {
            messages: { required: 'Please select a bank' },
          })}
          <BankList
            handleBankChange={this.handleDropChange}
            value={this.state.payment_info.bank_id}
            disabled={this.props.disabled || false}
          />
        </div>
        <div className="input-wrapper with-label">
          <label>Reference ID</label>
          {this.validator.message(
            'bank_reference_id',
            validation.bank_reference_id,
            'required',
            { messages: { required: 'Please enter cheque number' } },
          )}
          <input
            name="bank_reference_id"
            type="text"
            value={this.state.payment_info.bank_reference_id}
            onChange={this.handleChange}
            onBlur={this.validateFields}
            disabled={this.props.disabled || false}
          />
        </div>
        <div className="input-wrapper with-label">
          <label>Cheque Date</label>
          {this.validator.message(
            'check_date',
            validation.check_date,
            'required',
            { messages: { required: 'Please enter cheque date' } },
          )}
          <input
            name="check_date"
            type="date"
            value={this.state.payment_info.check_date}
            onChange={this.handleChange}
            onBlur={this.validateFields}
            disabled={this.props.disabled || false}
          />
        </div>
      </>
    );
  }
}

export { Fields, getPaymentModes, Modes };
