import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Borrow, Lend, LoanLedger, Parties } from '@C/Loans/RouteComponents';

const RouteSetup = () => {
  return (
    <Switch>
      <Route path="/account/loan/parties" component={Parties} />
      <Route path="/account/loan/lend" component={Lend} />
      <Route path="/account/loan/borrow" component={Borrow} />
      <Route path="/account/loan/loan-ledger" component={LoanLedger} />
      <Route path="/account/loan/*">
        <Redirect to={`/account/loan/parties`} />
      </Route>
    </Switch>
  );
};

export { RouteSetup };
