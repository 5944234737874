import React, { Component } from 'react';
import Modal from 'react-modal';

import services from '@C/Settings/_utils/data';

import ContinueForm from './ContinueForm';
import PartialForm from './PartialForm';

import './styles.scoped.scss';

class ResetAccount extends Component {
  render() {
    return (
      <>
        <PartialComponent />
        <CompleteComponent />
        <ComponentList />
      </>
    );
  }
}

export class PartialComponent extends Component {
  constructor() {
    super();
    this.state = {
      partialModal: false,
    };
  }

  openPartialModal = () => {
    this.setState({ partialModal: true });
  };

  closePartialModal = () => {
    this.setState({ partialModal: false });
  };

  render() {
    return (
      <>
        <section className="widget form-card">
          <header>
            <h3> Partial Reset </h3>
          </header>
          <main>
            <div>
              <p> Partial reset deletes all your bunks data except, </p>
              <p> 1. Credit Customet List; Reset outstanding to "0"</p>
              <p> 2. Lubes Inventory; Reset stock to "0"</p>
              <p> 3. Income Source </p>
              <p> 4. Expense Source </p>
              <p> 5. Staff List </p>
              <p> 6. Vendors/ Suppliers List; Reset outstanding to "0"</p>
              <p> 7. Tank Setup </p>
              <p> 8. Bank Setup </p>
              <p> 9. Wallet Setup </p>
              <p> 10. POS Setup </p>
              <p> 11. Staff Setup </p>
              <p>
                {' '}
                And reset the opening balances and outstanding amounts to "Zero"
              </p>
              <div className="cta-wrapper">
                <button
                  className="submit"
                  type="submit"
                  onClick={this.openPartialModal}
                >
                  Continue
                </button>
              </div>
            </div>
          </main>
        </section>
        <Modal
          isOpen={this.state.partialModal}
          onRequestClose={() => this.closePartialModal}
          contentLabel="Example Modal"
          shouldCloseOnOverlayClick={true}
          className="sub-xl-modal-container"
          overlayClassName="xl-modal-overlay"
        >
          <div className="modal-component">
            <PartialForm onRequestClose={this.closePartialModal} />
          </div>
        </Modal>
      </>
    );
  }
}

class CompleteComponent extends Component {
  constructor() {
    super();
    this.state = {
      completeModal: false,
    };
  }

  openCompleteModal = () => {
    this.setState({ completeModal: true });
  };

  closeCompleteModal = () => {
    this.setState({ completeModal: false });
  };

  render() {
    return (
      <>
        <section className="widget form-card">
          <header>
            <h3> Complete Reset </h3>
          </header>
          <main>
            <div>
              <p>
                {' '}
                Complete reset delets all your bunks data except the dealer
                creation details{' '}
              </p>
              <div className="cta-wrapper">
                <button
                  className="submit"
                  type="submit"
                  onClick={this.openCompleteModal}
                >
                  Continue
                </button>
              </div>
            </div>
          </main>
        </section>
        <Modal
          isOpen={this.state.completeModal}
          onRequestClose={() => this.closeCompleteModal}
          contentLabel="Example Modal"
          shouldCloseOnOverlayClick={true}
          className="sub-xl-modal-container"
          overlayClassName="xl-modal-overlay"
        >
          <div className="modal-component">
            <ContinueForm onRequestClose={this.closeCompleteModal} />
          </div>
        </Modal>
      </>
    );
  }
}

class ComponentList extends Component {
  constructor() {
    super();
    this.state = {
      componentList: [],
    };
  }

  componentDidMount() {
    this.getComponentList();
  }

  getComponentList = () => {
    services
      .getComponentList(this.state)
      .then((response) => {
        this.setState({
          componentList: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const componentList = this.state.componentList;

    return (
      <>
        <section className="widget full-width dsr-table">
          <header>
            <h3>History List</h3>
          </header>
          <main className="content-wrapper">
            <table className="dsr">
              <thead>
                <tr>
                  <th> SI.No</th>
                  <th> History</th>
                </tr>
              </thead>
              <tbody>
                {componentList.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.history}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </main>
        </section>
      </>
    );
  }
}

export { ResetAccount };
