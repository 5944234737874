import React, { Component } from 'react';

export class TempCreditDsr extends Component {
  constructor() {
    super();
  }

  render() {
    const summary = this.props.summary;
    return (
      <>
        <section className="widget dsr-table">
          <header></header>
          <main>
            <table className="dsr" data-name="Temp Credit Collection">
              <thead>
                <tr>
                  <th colSpan={7} className="alert-brand">
                    <label className="printCheckbox">
                      <input
                        type="checkbox"
                        name="enablePrint"
                        defaultChecked="true"
                      />
                    </label>
                    Temp Credit
                  </th>
                </tr>
                <tr data-header={true}>
                  <th>#</th>
                  <th>Cashier</th>
                  <th>Customer </th>
                  <th> Amount given </th>
                  <th> Amount received </th>
                  <th> Pending amount </th>
                  <th> Payment mode </th>
                </tr>
              </thead>
              <tbody>
                {summary.map((val, key) => {
                  return (
                    <tr key={key}>
                      <td>{key + 1}</td>
                      <td>
                        {' '}
                        {val.cashier.first_name + val.cashier.last_name}{' '}
                      </td>
                      <td>{val.customer_name}</td>
                      <td>{val.amount_given}</td>
                      <td> {val.amount_received} </td>
                      <td> {val.pending_amount} </td>
                      <td> {val.payment_mode} </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </main>
        </section>
      </>
    );
  }
}
