import React, { Component } from 'react';

export class ProductSalesSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const summary = this.props.summary;
    return (
      <>
        <section className="widget dsr-table">
          <header></header>
          <main className="content-wrapper">
            <table className="dsr" data-name="Product Sales">
              <thead>
                <tr data-header={true}>
                  <th colSpan={4} className="alert-brand">
                    <label className="printCheckbox">
                      <input
                        type="checkbox"
                        name="enablePrint"
                        defaultChecked="true"
                      />
                    </label>
                    Product Sales Summary
                  </th>
                </tr>
              </thead>
              <tbody>
                {summary.map((item, i) => (
                  <tr key={i}>
                    <td>Total {item.product} sales </td>
                    <td>
                      {`${item.product == 'lube' ? 'Qty' : 'Litres'}`}:{' '}
                      {item.total_sale_in_liters.toFixed(2)}
                    </td>
                    <td>
                      {new Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        currency: 'INR',
                      }).format(item.total_sale_value || 0)}
                    </td>
                  </tr>
                ))}
                {/* <tr className="summary-row">
                  <td colSpan="2">Total litres sold </td>
                  <td className="">
                    {summary.reduce(
                      (a, b) => a + (b['total_sale_in_liters'] || 0),
                      0,
                    ).toFixed(2) || 0.00}
                  </td>
                </tr> */}
                <tr className="summary-row">
                  <td colSpan="2">Total sale amount </td>
                  <td className="">
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      summary.reduce(
                        (a, b) => a + (b['total_sale_value'] || 0),
                        0,
                      ) || 0,
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </main>
        </section>
      </>
    );
  }
}
