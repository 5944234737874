import React from 'react';

import { Header, Navbar } from '@C';
import { RouteSetup } from '@C/CashiersOnDuty';
import ErrorBoundary from '@C/Error';

const routes = [
  {
    name: 'Asign duty',
    path: '/cashiers-on-duty/assign-duty',
  },
  {
    name: 'End duty',
    path: '/cashiers-on-duty/end-duty',
  },
];

const CashiersOnDuty = () => {
  return (
    <main>
      <div className="page-wrapper">
        <Header title="Credit Customers" />
        <Navbar />
        <div className="page-content">
          <section className="page-components">
            <div className="page-title">
              <h1>Cashiers On Duty</h1>
            </div>

            <ErrorBoundary>
              <RouteSetup />
            </ErrorBoundary>
          </section>
        </div>
      </div>
    </main>
  );
};

export { CashiersOnDuty };
