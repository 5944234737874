import React, { useEffect, useState } from 'react';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import ColorHash from 'color-hash';
import dayjs from 'dayjs';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';

import services from '@C/Dashboard/Components/Charts/data';

import './styles.scoped.scss';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const DashboardCharts = () => {
  const colorHash = new ColorHash({ hue: { min: 180, max: 400 } });
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [highlightedDates, setHighlightedDates] = useState([]);
  const [chartType, setChartType] = useState(1);

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (data) {
            return `${data.dataset.label} for ${data.label}: ${data.formattedValue}`;
          },
        },
      },
    },
  };

  useEffect(() => {
    getDSRDates(date);
    getDSRChartsData();
  }, [date]);

  const handleDate = (e) => {
    setDate(dayjs(e).format('YYYY-MM-DD'));
  };

  const changeChartType = (e) => {
    setChartType(e);
  };

  const getDSRDates = (arg) => {
    services
      .getDSRDates(dayjs(arg).month() + 1, dayjs(arg).year())
      .then((response) => {
        const tmp = response.data.data;
        const eventsArr = [
          {
            dsr_locked: [],
          },
          {
            dsr_unlocked: [],
          },
        ];
        tmp.forEach((item) => {
          if (item.lock_status) {
            eventsArr[0]['dsr_locked'].push(new Date(item.time_stamp));
          } else {
            eventsArr[1]['dsr_unlocked'].push(new Date(item.time_stamp));
          }
        });
        setHighlightedDates(eventsArr);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDSRChartsData = () => {
    setLoading(true);
    services
      .getDSRChart(date)
      .then((response) => {
        const labelsData = response.data.data.product_wise.map((item) =>
          item.product === 'Lube' ? 'Lube / Products' : item.product,
        );
        const dataSets = [
          {
            label: 'Sales by liters',
            data: response.data.data.product_wise.map((item) =>
              item.total_sale_in_liters.toFixed(2),
            ),
            backgroundColor: labelsData.map((name) =>
              colorHash.hex(name.toUpperCase()),
            ),
          },
          {
            label: 'Sales by amount',
            data: response.data.data.product_wise.map((item) =>
              item.total_sale_value.toFixed(2),
            ),
            backgroundColor: labelsData.map((name) =>
              colorHash.hex(name.toLowerCase()),
            ),
          },
        ];
        setData({
          labels: labelsData,
          datasets: dataSets,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <section className="widget full-width charts-widget">
      <header>
        <h3>Statistics</h3>
      </header>
      <main>
        <div className="actions-wrapper">
          <div className="input-wrapper with-label no-margin">
            <label>Date</label>
            <DatePicker
              selected={new Date(date)}
              dateFormat="d MMM yyyy"
              onSelect={handleDate}
              maxDate={new Date()}
              highlightDates={highlightedDates}
              onMonthChange={getDSRDates}
              popperPlacement="bottom-start"
            />
          </div>
          <div className="chart-type-wrapper">
            <ul>
              <li>
                <button className="pie" onClick={() => changeChartType(1)}>
                  Pie Chart
                </button>
              </li>
              <li>
                <button className="bar" onClick={() => changeChartType(2)}>
                  Bar Chart
                </button>
              </li>
              <li>
                <button className="line" onClick={() => changeChartType(3)}>
                  Line Chart
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="chart-wrapper">
          {(() => {
            switch (chartType) {
              case 1:
                return (
                  <Doughnut
                    options={options}
                    data={data}
                    height={400}
                    datasetIdKey={'label'}
                  />
                );
              case 2:
                return <Bar options={options} data={data} height={400} />;
              case 3:
                return <Line options={options} data={data} height={400} />;
              default:
                console.log('PetroMeter');
            }
          })()}
        </div>
      </main>
    </section>
  );
};

export { DashboardCharts };
