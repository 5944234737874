import React from 'react';

import { Header, Navbar, NavPills } from '@C';
import ErrorBoundary from '@C/Error';
import { RouteSetup } from '@C/ManageIE';

const OtherIE = () => {
  const routes = [
    {
      name: 'Add Income',
      path: '/account/other-income-expense/add/income',
    },
    {
      name: 'Add Expense',
      path: '/account/other-income-expense/add/expense',
    },
    {
      name: 'Manage Source',
      path: '/account/other-income-expense/source',
    },
    {
      name: 'Withdrawal From Bunk',
      path: '/account/other-income-expense/withdrawal-from-bunk',
      display: false,
    },
  ];

  return (
    <main>
      <div className="page-wrapper">
        <Header title="Manage Income & Expense" />
        <Navbar />
        <div className="page-content">
          <section className="page-components">
            <div className="page-title">
              <h1>Manage Other Income & Expense</h1>
            </div>
            <ErrorBoundary>
              <NavPills routes={routes} />
            </ErrorBoundary>
            <ErrorBoundary>
              <RouteSetup />
            </ErrorBoundary>
          </section>
        </div>
      </div>
    </main>
  );
};

export { OtherIE };
