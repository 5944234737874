import React, { Component } from 'react';
import Select from 'react-select';

import services from '../../Utils/data';

let CategoryOptions;

class IncomeList extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      options: [],
    };
  }
  componentDidMount() {
    const { isShowDeleted } = this.props;
    services
      .getIncomeList(isShowDeleted)
      .then((response) => {
        const tmp = response.data.data;
        CategoryOptions = [];
        for (let i = 0; i < tmp.length; i++) {
          CategoryOptions.push({
            value: tmp[i].uuid,
            label: tmp[i].other_income,
          });
        }
        this.setState({ options: CategoryOptions });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  handleChange = (selectedOption) => {
    const id = this.props.did ? this.props.did : 0;
    this.props.onChange(selectedOption, id);
  };

  render() {
    const { options } = this.state;

    return (
      <Select
        onChange={this.handleChange}
        options={options}
        isClearable={true}
        className="income-selector-container"
        classNamePrefix="income-selector"
        name="income_source"
        placeholder="Select income"
        value={options.filter(({ value }) => value === this.props.value)}
      />
    );
  }
}

export default IncomeList;
