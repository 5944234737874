import React, { Component } from 'react';
import Select from 'react-select';

let CategoryOptions = [
  {
    value: 103,
    label: 'Cashier',
  },
  {
    value: 102,
    label: 'Manager',
  },
  // {
  //   value: 101,
  //   label: 'Dealer',
  // },
  {
    value: 107,
    label: 'Other Staff',
  },
];

class RoleList extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      options: CategoryOptions,
      role: this.props.role,
    };
  }
  componentDidMount() {
    if (this.state.role) {
      CategoryOptions = CategoryOptions.filter(
        (item) => item.value > this.state.role,
      );
      this.setState({ options: CategoryOptions });
    }
  }
  handleChange = (selectedOption) => {
    const val = selectedOption.value;
    this.props.handleRoleChange(val);
  };

  render() {
    const options = this.state.options;
    return (
      <Select
        onChange={this.handleChange}
        options={options}
        classNamePrefix="raw-select"
        name="oml"
        placeholder="Select role"
      />
    );
  }
}

export default RoleList;
