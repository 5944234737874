import axios from 'axios';

import { authHeader } from '@H';
import { authService } from '@S';

export default {
  getCountries: () =>
    axios({
      method: 'GET',
      headers: authHeader(),
      url: `${process.env.API_URL}/utils/countries/`,
    }),
  getOMCList: () =>
    axios({
      method: 'GET',
      headers: authHeader(),
      url: `${process.env.API_URL}/bunk/omc/omc-list`,
    }),
  getTaxDetails: () =>
    axios({
      method: 'GET',
      headers: authHeader(),
      url: `${process.env.API_URL}/utils/tax/${authService.currentBunkCountryUUID}/?by_group=1`,
    }),
  getFuelWithPrice: (date) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/fuel-prices/?date=${date}`,
    }),
  getChartList: (omc_id) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/dip-calibration/?omc_id=${omc_id}`,
    }),
  getShiftsList: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/shifts/view-shifts/`,
    }),
  getBowserList: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/associate-tanks/?is_bowser=1`,
    }),
  getAllPos: () =>
    axios({
      method: 'GET',
      headers: authHeader(),
      url: `${process.env.API_URL}/utils/swipe-machines/`,
    }),
  getAllWallets: () =>
    axios({
      method: 'GET',
      headers: authHeader(),
      url: `${process.env.API_URL}/utils/wallets/`,
    }),
  getMappedBanks: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/mapped-banks/list/`,
    }),
  getIncomeList: (isAll) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/other-income-setup/?is_all=${isAll ? 'true' : 'false'}`,
    }),
  getExpenseList: (isAll) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/expenses-setup/?is_all=${isAll ? 'true' : 'false'}`,
    }),
  getMappedWallets: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/mapped-wallets/list/`,
    }),
  getMappedPOS: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/map-swipe-machines/list/`,
    }),
  getStaffList: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/staff-management/bunk/staff/`,
    }),
  getDutyList: (date) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/cashier/cashier-duties/?date=${date}&is_end_duty=0&is_staff=1`,
    }),
  getVendorList: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/lubes/bunk/vendor/`,
    }),
  getInvoiceList: (vendor) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/vendor/bunk/invoice-list/?vendor_id=${vendor}`,
    }),

  getTankList: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/associate-tanks/`,
    }),

  getSalaryType: () =>
    axios({
      method: 'GET',
      headers: authHeader(),
      url: `${process.env.API_URL}/utils/salary-types/`,
    }),

  uploadImage: (body) => {
    const headers = authHeader(1);
    return axios.post(`${process.env.API_URL}/utils/upload/image/`, body, {
      headers: {
        ...headers,
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  getFilteredStaffList: (date) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/cashier/cashier-duties/?is_end_duty=1&is_staff=1&date=${date}`,
    }),
  getQuotationList: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/credit-customer/bunk/quotation/`,
    }),
};
