import React, { Component } from 'react';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import { BowserList } from '@C/CashiersOnDuty/_utils/BowserList';
import services from '@C/CashiersOnDuty/_utils/data';
import { formatCurrency } from '@C/TableGrid';
import { authHeader } from '@H';
import { authService } from '@S';

class SalesSummary extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      staff_id: this.props.match.params.staff,
      cashier_duty_id: this.props.match.params.duty,
      reading_list: [],
      total: 0,
      update: false,
      showModal: false,
      showPriceModal: false,
      isSubmitting: false,
      bowsers_tmp: [],
      index: null,
      bowser_total: 0,
      edit:
        this.props.match.params.mode == 'edit'
          ? this.props.match.params.mode
          : false,
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
      validators: {
        closing_reading: {
          message:
            'The closing reading must be greater than or equal to opening reading',
          rule: (val) => {
            return val[0] >= val[1];
          },
          required: true,
        },
      },
    });
  }

  componentDidMount() {
    this.getAllocatedGuns();
  }

  handlePriceChange = () => {
    this.setState({ showPriceModal: true });
  };

  handleBowserData = (data) => {
    const list = [...this.state.reading_list];
    const { index, bowsers } = data;
    const totalBowserQuantity = bowsers.reduce(
      (total, bowser) => total + parseFloat(bowser.litters || 0),
      0,
    );
    if (
      totalBowserQuantity >
      list[index].closing_reading - list[index].opening_reading
    ) {
      toast.error(
        'Bowser filling should not be greater than total sold quantity',
      );
      return;
    }

    list[index].bowsers = bowsers;

    this.setState({ reading_list: list }, function () {
      this.calculateSale(index);
    });
  };

  updateBowserReadings = (i, data) => {
    this.setState({ showModal: true, bowsers_tmp: data, index: i });
  };

  closeModal = () => {
    this.setState({ showModal: false, showPriceModal: false, bowsers_tmp: [] });
  };

  getAllocatedGuns = () => {
    services
      .getAllocatedGuns(this.state.staff_id, this.state.cashier_duty_id)
      .then((response) => {
        const item = response.data.data;
        const tmpList = [];
        for (let i = 0; i < item.length; i++) {
          tmpList.push({
            update_uuid: item[i].assigned_gun.readings.update_uuid,
            gun_id: item[i].assigned_gun.uuid,
            tank_id: item[i].assigned_gun.tank.uuid,
            closing_reading: item[i].assigned_gun.readings.closing_reading,
            opening_reading: item[i].assigned_gun.readings.opening_reading,
            bowsers: item[i].assigned_gun.readings.bowsers,
            testing: item[i].assigned_gun.readings.testing,
            total: item[i].assigned_gun.readings.total,
            sold_liters: item[i].assigned_gun.readings.sold_liters,
            gun_name: item[i].assigned_gun.name,
            price: item[i].assigned_gun.tank.product.price,
            is_active: item[i].assigned_gun.readings.is_active,
            product_id: item[i].assigned_gun.tank.product.product_id,
          });
          this.setState(
            {
              reading_list: tmpList,
            },
            function () {
              this.calculateSale(i);
            },
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleChange = (e, i) => {
    const { name, value } = e.target;
    const reading_list = [...this.state.reading_list];
    reading_list[i][name] = value;

    this.setState({ reading_list }, function () {
      this.validator.showMessageFor(`closing_reading.${i}`);
      this.calculateSale(i);
    });
  };

  calculateSale = (i) => {
    const reading_list = [...this.state.reading_list];
    const a =
      reading_list[i].bowsers.reduce(
        (a, b) => a + (parseFloat(b['litters']) || 0),
        0,
      ) || 0;
    reading_list[i]['sold_liters'] = parseFloat(
      reading_list[i]['closing_reading'] -
        reading_list[i]['opening_reading'] -
        reading_list[i]['testing'] -
        a,
    ).toFixed(2);
    reading_list[i]['total'] = parseFloat(
      reading_list[i]['sold_liters'] * reading_list[i].price,
    );
    this.setState({ reading_list: reading_list });
  };

  onSave = () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }

    this.setState({ isSubmitting: true });

    const formData = {
      staff_id: this.props.match.params.staff_id,
      cashier_duty_id: this.props.match.params.duty,
      reading_list: this.state.reading_list,
    };
    const headers = authHeader(1);
    axios
      .post(`${process.env.API_URL}/bunk/gun-readings/`, formData, { headers })
      .then((response) => {
        toast.success(response.data.message);
        this.scrollToTop();
        this.getAllocatedGuns();
        this.props.onDataChange();
        this.setState({ isSubmitting: false });
        this.validator.hideMessages();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        this.setState({ isSubmitting: false });
      });
  };

  scrollToTop = () => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  };

  confirmReallocate = (item) => {
    confirmAlert({
      title: 'Confirm gun reallocation',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.reallocateCashier(),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };

  reallocateCashier = () => {
    const filteredList = this.state.reading_list.filter((item) => {
      return item.is_active == true;
    });
    this.setState({ isSubmitting: true });
    const formData = {
      staff_id: this.props.match.params.staff_id,
      cashier_duty_id: this.props.match.params.duty,
      gun_list: filteredList,
    };
    const headers = authHeader(1);
    axios
      .post(`${process.env.API_URL}/bunk/gun/re-allocate-cashier/`, formData, {
        headers,
      })
      .then((response) => {
        toast.success('Guns reallocated successfully');
        this.getAllocatedGuns();
        this.props.onDataChange();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        this.setState({ isSubmitting: false });
      });
  };

  render() {
    const { reading_list } = this.state;
    const total_sale_amount = reading_list.reduce(
      (a, b) => a + parseFloat(b['total'] > 0 ? b['total'] : 0),
      0,
    );

    let total_bowser_amount = 0;
    const role = authService.currentUserRole;

    return (
      <>
        <section className="widget full-width form-table" ref={this.myRef}>
          <header>
            <h3>Gun readings</h3>
            <div className="actions">
              <button className="blue-btn" onClick={this.confirmReallocate}>
                Reallocate cashier
              </button>
            </div>
          </header>
          <main>
            <div className="form-content">
              <table>
                <thead>
                  <tr>
                    <th>Guns</th>
                    <th>Price</th>
                    <th>Opening reading</th>
                    <th>Closing reading</th>
                    <th>Testing</th>
                    <th>Sold</th>
                    <th>Bowser</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {reading_list.map((item, i) => (
                    <tr key={i}>
                      <td className="item-title with-icon --gun">
                        <span>{item.gun_name}</span>
                      </td>

                      <td>
                        <span>{item.price}</span>
                      </td>

                      <td>
                        <div className="input-wrapper">
                          <input
                            type="text"
                            placeholder="opening reading"
                            value={reading_list[i].opening_reading}
                            name="opening_reading"
                            disabled={role !== 'Dealer' || !this.state.edit}
                            onChange={(event) =>
                              this.handleChange(event, i, item.price)
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper">
                          {this.validator.message(
                            `closing_reading.${i}`,
                            [
                              reading_list[i].closing_reading,
                              reading_list[i].opening_reading,
                            ],
                            'closing_reading',
                          )}
                          <input
                            type="text"
                            placeholder="closing reading"
                            value={reading_list[i].closing_reading}
                            name="closing_reading"
                            onChange={(e) => this.handleChange(e, i)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper">
                          {this.validator.message(
                            'closing_reading',
                            reading_list[i].testing,
                            'numeric|required',
                          )}
                          <input
                            type="text"
                            onChange={(event) =>
                              this.handleChange(event, i, item.price)
                            }
                            name="testing"
                            id="testing"
                            value={reading_list[i].testing}
                            placeholder="testing"
                          />
                        </div>
                      </td>
                      <td>
                        <span>
                          {reading_list[i].sold_liters > 0
                            ? reading_list[i].sold_liters
                            : 0}
                        </span>
                      </td>
                      <td className="item-input">
                        {(() => {
                          total_bowser_amount =
                            reading_list[i].bowsers.reduce(
                              (a, b) => a + (parseFloat(b['litters']) || 0),
                              0,
                            ) || 0;
                        })()}
                        <span>{total_bowser_amount}</span>
                        <button
                          className="add-row-alt"
                          onClick={() =>
                            this.updateBowserReadings(
                              i,
                              reading_list[i].bowsers,
                            )
                          }
                        >
                          Add readings
                        </button>
                      </td>
                      <td>
                        <span>
                          {formatCurrency(
                            reading_list[i].total > 0
                              ? reading_list[i].total
                              : 0 || 0,
                          )}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <ul className="table-summary">
                <li>
                  <label>Total sale amount: </label>
                  <span>
                    {formatCurrency(
                      total_sale_amount > 0 ? total_sale_amount : 0 || 0,
                    )}
                  </span>
                </li>
              </ul>
            </div>
            <div className="form-actions">
              <button className="submit" onClick={() => this.onSave()}>
                Save
              </button>
            </div>
          </main>
        </section>
        <Modal
          isOpen={this.state.showModal}
          onRequestClose={this.closeModal}
          contentLabel="Bowser Summary"
          shouldCloseOnOverlayClick={true}
          className="sub-xl-modal-container"
          overlayClassName="xl-modal-overlay"
          parentSelector={() => document.querySelector('.page-components')}
        >
          <div className="modal-component">
            <BowserSummary
              onRequestClose={() => this.closeModal()}
              bowsers={this.state.bowsers_tmp}
              id={this.state.index}
              handleBowserData={this.handleBowserData}
              parentProduct={
                this.state.reading_list[this.state.index]?.product_id
              }
            />
          </div>
        </Modal>
      </>
    );
  }
}

class BowserSummary extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
    this.state = {
      bowsers: this.props.bowsers,
      index: this.props.id,
    };
  }

  componentDidMount() {
    let bowserReadings = [...this.state.bowsers];
    if (bowserReadings.length < 1) {
      bowserReadings = [
        {
          litters: 0,
          bowser_uuid: '',
        },
      ];
    }
    this.setState({ bowsers: bowserReadings });
  }

  onInputChange = (e, i) => {
    const list = [...this.state.bowsers];
    list[i].litters = e.target.value;
    this.setState({ bowsers: list });
  };

  handleBowserChange = (e, i) => {
    const list = [...this.state.bowsers];
    list[i].bowser_uuid = e;
    this.setState({ bowsers: list }, function () {});
  };

  addRow = () => {
    this.setState((prevState) => ({
      bowsers: [
        ...prevState.bowsers,
        {
          litters: 0,
          bowser_uuid: '',
        },
      ],
    }));
  };

  deleteRow = (e, i) => {
    e.preventDefault();
    this.setState({
      bowsers: this.state.bowsers.filter((s, sindex) => i !== sindex),
    });
  };

  submitForm = (e) => {
    e.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.props.handleBowserData(this.state);
    this.props.onRequestClose();
  };

  render() {
    const bowserReadings = this.state.bowsers;

    return (
      <>
        <section className="widget bowser-modal">
          <header>
            <h3>Update bowser liters</h3>
            <div className="actions">
              <button className="add-row" onClick={this.addRow}>
                Add row
              </button>
            </div>
          </header>
          <main>
            <form className="inline">
              {bowserReadings.map((item, i) => (
                <>
                  <div className="input-wrapper with-label" key={i}>
                    <label>Bowser</label>
                    {this.validator.message(
                      'bowserReadings.bowser_uuid',
                      item.bowser_uuid,
                      'required',
                    )}
                    <BowserList
                      did={i}
                      onChange={this.handleBowserChange}
                      value={item.bowser_uuid}
                      parentProduct={this.props.parentProduct}
                    />
                  </div>
                  <div className="input-wrapper with-label">
                    <label>Liters</label>
                    {this.validator.message(
                      'item.litters',
                      item.litters,
                      'numeric',
                    )}
                    <input
                      name="litters"
                      type="text"
                      onChange={() => this.onInputChange(event, i)}
                      value={item.litters}
                    />
                  </div>
                  <div className="actions-wrapper">
                    {i > 0 ? (
                      <button
                        className="delete-row icon-only"
                        onClick={() => this.deleteRow(event, i)}
                      >
                        Delete row
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                </>
              ))}

              <div className="cta-wrapper">
                <button className="submit" onClick={this.submitForm}>
                  Submit
                </button>
              </div>
            </form>
          </main>
        </section>
      </>
    );
  }
}

export default SalesSummary;
