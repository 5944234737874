import axios from 'axios';

import { authHeader } from '@H';

export default {
  getBankList: () =>
    axios({
      method: 'GET',
      headers: authHeader(),
      url: `${process.env.API_URL}/utils/banks/`,
    }),
  getMappedBankList: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/mapped-banks/list/`,
    }),
};
