import React, { Component } from 'react';

import services from '@C/CreditCustomers/_utils/data';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { authService } from '@S';

import './styles.scoped.scss';

export default class CreditCustomerDsr extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileList: {},
      loading: true,
      customer_id: authService.currentUserId,
    };
  }

  componentDidMount() {
    this.getCreditCustomer();
  }
  getCreditCustomer = () => {
    services
      .getCreditCustomer(this.state)
      .then((response) => {
        this.setState({
          profileList: response.data.data,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { profileList, loading } = this.state;
    if (loading) {
      return <DropLoaderLarge />;
    }
    return (
      <>
        <section className="widget full-width form-card">
          <main>
            <form>
              <div className="input-wrapper">
                <span className="bold">
                  {' '}
                  {profileList.basic_details?.company_name}{' '}
                </span>
              </div>
              <div className="input-wrapper">
                <span className="bold">
                  {' '}
                  {profileList.basic_details?.first_name}{' '}
                </span>
              </div>
            </form>
          </main>
          <main className="two-col center">
            <div className="col">
              <div className="input-wrapper with-label">
                <label className="size">Contact no:</label>
                <input
                  disabled
                  className="size"
                  value={profileList.basic_details?.mobile}
                  name="mobile"
                />
              </div>

              <div className="input-wrapper with-label">
                <label className="size">GST </label>
                <input
                  className="size"
                  type="text"
                  disabled
                  value={profileList.gstn}
                  name="gstn"
                />
              </div>
              <div className="input-wrapper with-label ">
                <label className="size">Pincode</label>
                <input
                  type="text"
                  className="size"
                  disabled
                  name="pincode"
                  value={profileList.address?.pincode}
                />
              </div>
            </div>
            <div className="col">
              <div className="input-wrapper with-label ">
                <label className="size">City</label>
                <input
                  type="text"
                  className="size"
                  name="city"
                  value={profileList.address?.city}
                  disabled
                />
              </div>

              <div className="input-wrapper with-label ">
                <label className="size">State</label>
                <input
                  className="size"
                  type="text"
                  name="state"
                  value={profileList.address?.state}
                  disabled
                />
              </div>

              <div className="input-wrapper with-label ">
                <label className="size">Country</label>
                <input
                  className="size"
                  type="text"
                  name="country"
                  value={profileList.address?.country?.country}
                  disabled
                />
              </div>
            </div>
          </main>
        </section>
      </>
    );
  }
}
