import axios from 'axios';

import { authHeader } from '@H';

export default {
  getIncomeList: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/transactions/?product_type=Other&offset=${data.offset}&limit=${data.limit}&start_date=${data.start_date}&end_date=${data.end_date}&cashier_duty_id=${data.cashier_duty_id}&staff_id=${data.staff_id}&product_id=${data.product_uuid}`,
    }),

  getExpenseList: (data) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/transactions/?product_type=Expenses&offset=${data.offset}&limit=${data.limit}&start_date=${data.start_date}&end_date=${data.end_date}&staff_id=${data.staff_id}&cashier_duty_id=${data.cashier_duty_id}&product_id=${data.product_uuid}`,
    }),

  getIncomeSource: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/other-income-setup/?other_income_list=${other_income_source}`,
    }),
  getExpenseSource: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/expenses-setup/`,
    }),
  getEditIncomeItem: (id) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/transactions/?transaction_id=${id}`,
    }),

  getEditExpenseItem: (id) =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/payment/transactions/?transaction_id=${id}`,
    }),
};
