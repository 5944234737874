import React from 'react';

import './styles.scoped.scss';

const ServerMaintenance = () => {
  return (
    <main>
      <div className="page-wrapper no-access">
        <div className="page-content">
          <section className="page-components">
            <div className="access-forbidden">
              <h1>Server Maintenance</h1>
              <p>
                Sorry for the inconvenience but we&rsquo;re performing some
                maintenance at the moment.
              </p>
              <button
                className="submit mt-20"
                onClick={() => window.location.reload()}
              >
                Reload page
              </button>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
};

export { ServerMaintenance };
