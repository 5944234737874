import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { authHeader } from '@H';

import services from './data';

class CashierActiveDuty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: new Date(),
      dutyList: [],
    };
  }

  componentDidMount() {
    this.getDuties();
  }

  getDuties = () => {
    services
      .getActiveCashierDuty(dayjs().format('YYYY-MM-DD'), this.props.uuid)
      .then((response) => {
        this.setState({ dutyList: response.data.data.duties_list });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  endDuty = (id, staff, name, date) => {
    this.props.onEndDuty(id, staff, name, date);
  };

  confirmCancel = (id, duty) => {
    confirmAlert({
      title: 'Confirm duty cancellation',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.cancelDuty(id, duty),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
    // this.getDuties();
    // this.getStaffList();
  };

  cancelDuty = (id, duty) => {
    const formData = {
      staff_id: id,
      cashier_duty_id: duty,
    };
    // this.getDuties();

    const headers = authHeader(1);
    axios
      .put(`${process.env.API_URL}/bunk/cancel-cashier-duty/`, formData, {
        headers,
      })
      .then(() => {
        toast.success('Duty cancelled successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        window.location.reload();
        // window.location.reload();
        // this.getStaffList();
        // this.getGunsList();
      })
      .catch(() => {
        toast.error(response.data.message, {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    // this.getDuties();
    //   this.getStaffList();
    //   this.getGunsList();
  };

  render() {
    const { dutyList } = this.state;

    return (
      <>
        <section className="widget full-width duty-list">
          <header>
            <h3>Active Duty</h3>
          </header>
          <main>
            <ul>
              {dutyList.map((item, i) => {
                return (() => {
                  if (item.is_active === false) {
                    return <React.Fragment key={i} />;
                  } else {
                    return (
                      <li key={i}>
                        <div className="item-info">
                          <a href="#">{`${item.first_name} ${item.last_name}`}</a>
                          <span
                            title="Cashier is already on duty"
                            className="status --on-duty"
                          >
                            On duty
                          </span>
                          <span className="shift-status --on-duty">
                            {item.shift_name}
                          </span>
                          <span
                            title={`Duty date - ${dayjs(item.duty_time_stamp).format('YYYY-MM-DD')}`}
                            className="shift-status --on-duty"
                          >
                            {new Date(item.duty_time_stamp).toDateString()}
                          </span>
                        </div>
                        <div className="cta-wrapper">
                          <Link
                            to={`/cashiers-on-duty/end-duty/${item.cashier_duty_id}/${dayjs(item.duty_time_stamp).format('YYYY-MM-DD')}/${item.staff_uuid}/update/fuel-sales`}
                            className="end-cta"
                          >
                            End Duty
                          </Link>
                        </div>
                      </li>
                    );
                  }
                })();
              })}
            </ul>
          </main>
        </section>
      </>
    );
  }
}

export { CashierActiveDuty };
