import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import services from '@C/CreditCustomers/_utils/data';
import { formatCurrency, TableGrid } from '@C/TableGrid';
import { authHeader } from '@H';

const col = [
  {
    name: 'Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Customer',
    key: 'quotation.customer.name',
  },
  {
    name: 'Indent #',
    key: 'order_no',
  },
  {
    name: 'Vehicle #',
    key: 'vehicle.vehicle_number',
  },
  {
    name: 'Product',
    key: 'product_obj.product_name',
  },
  {
    name: 'Value',
    key: 'all',
    type: 'currency',
    formatter(props) {
      return <>{formatCurrency(props.amount)}</>; 
    },
  },
  {
    name: 'Cashier',
    key: 'assigned_cashier',
    formatter(props) {
      return (
        <>
          {props.first_name} {props.last_name}
        </>
      );
    },
  },
  {
    name: 'Notes',
    key: 'notes',
  },
  {
    name: 'Status',
    key: 'status',
  },
];

const AcceptedPurchaseOrdersList = () => {
  const [list, setList] = useState([]);
  const [count, setCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);

  const handlePagination = (offset, limit) => {
    setOffset(offset);
    setLimit(limit);
  };

  const getIndentList = () => {
    services
      .getIndentList('')
      .then((response) => {
        const data = response?.data?.data.results;
        const list = data.filter((item) => item.status !== 'OPEN');
        setList(list);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getIndentList();
  }, []);

  const updatePendingStatus = (item, status) => {
    const headers = authHeader(1);
    const formData = {
      uuid: item.uuid,
      status: status,
      amount: item.amount,
      order_no: item.order_no,
      quotation_id: item.quotation.uuid,
      product_list: [
        {
          product_id: item.product_obj.product_uuid,
        },
      ],
    };

    axios({
      method: 'put',
      url: `${process.env.API_URL}/credit-customer/bunk/indent/`,
      data: formData,
      headers: headers,
    })
      .then((response) => {
        toast.success('Order marked as pending successfully');
        getIndentList();
        window.location.reload();
        props.onUpdate();
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const updateStatus = (item, status) => {
    const headers = authHeader(1);
    const formData = {
      uuid: item.uuid,
      status: status,
      amount: item.amount,
      order_no: item.order_no,
      quotation_id: item.quotation.uuid,
      product_list: [
        {
          product_id: item.product_obj.product_uuid,
        },
      ],
    };

    axios({
      method: 'put',
      url: `${process.env.API_URL}/credit-customer/bunk/indent/`,
      data: formData,
      headers: headers,
    })
      .then((response) => {
        toast.success('Order marked as complete successfully');
        getIndentList();
        props.onUpdate();
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  const enabledActions = [
    {
      action: 'Mark as complete',
      roles: ['Manager', 'Dealer'],
      icon: 'check-mark',
      callback: (item) => updateStatus(item, 'COMPLETED'),
    },
    {
      action: 'Mark as pending',
      roles: ['Manager', 'Dealer'],
      icon: 'pending',
      callback: (item) => updatePendingStatus(item, 'OPEN'),
    },
  ];

  return (
    <>
      <section className="widget full-width">
        <header className="title-wrapper">
          <h3>Accepted/Rejected Purchase Orders</h3>
        </header>
        <main className="content-wrapper">
          <TableGrid
            col={col}
            row={list}
            offset={offset}
            count={count}
            limit={limit}
            handlePagination={handlePagination}
            enabledActions={enabledActions}
          />
        </main>
      </section>
    </>
  );
};

export default AcceptedPurchaseOrdersList;
