import React, { Component } from 'react';

class GSTRReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gstList: [],
      limit: 20,
      offset: 1,
      count: 0,
    };
    this.limitInput = React.createRef();
  }

  handlePagination = () => {};

  handleQuickSearch = () => {};

  resetFilter = () => {};
  render() {
    const { gstList } = this.state;

    return (
      <section className="widget full-width list-table inverted">
        <header>
          <h3>GSTR 3B Report</h3>
        </header>
        <main>
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group">
                <label>From date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.start_date}
                  name="start_date"
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="filter-group">
                <label>To date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.end_date}
                  min={this.state.start_date}
                  onChange={this.handleDateChange}
                  name="end_date"
                />
              </div>
              <div className="filter-group with-icon">
                <label>
                  Results to display- {this.state.limit || 20} (Total:{' '}
                  {this.state.count})
                </label>
                <form onSubmit={this.handleQuickSearch}>
                  <input type="text" name="limit" ref={this.limitInput} />
                  <button
                    type="submit"
                    className="search-icon"
                    onClick={this.handleQuickSearch}
                  />
                </form>
              </div>
              <div className="filter-group reset-filter">
                <button onClick={this.resetFilter}>Reset filters</button>
              </div>
            </div>
          </div>
        </main>
        <main className="content-wrapper">
          <table className="dsr" data-name="gstr 3b report">
            <thead>
              <tr>
                <th> Particular </th>
                <th> Taxable </th>
                <th> IGST </th>
                <th> CGST </th>
                <th> SGST/UTGST </th>
                <th> CESS </th>
                <th> Total Tax </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {' '}
                <td>
                  <ul>
                    <li> (a) Outward Taxable </li>
                    <li> --- Reg Dealer </li>
                    <li> ---Non GST outward </li>
                    <li> --- Outward or Dealer </li>
                    <li> --- Outward Composition Dealer </li>
                  </ul>
                </td>
              </tr>
              <tr>
                {' '}
                <td>
                  {' '}
                  <ul>
                    {' '}
                    <li> (b) Outward Exempted </li>
                  </ul>
                </td>{' '}
              </tr>
              <tr>
                {' '}
                <td>
                  {' '}
                  <ul>
                    {' '}
                    <li> (c) Advance receipt tax </li>{' '}
                  </ul>{' '}
                </td>
              </tr>
              <tr>
                {' '}
                <td>
                  {' '}
                  <ul>
                    {' '}
                    <li> (d) Advance Loss </li>
                  </ul>{' '}
                </td>{' '}
              </tr>
              <tr>
                {' '}
                <td>
                  {' '}
                  <ul>
                    {' '}
                    <li> (e) Inward reserved tax </li>
                  </ul>{' '}
                </td>{' '}
              </tr>
              <tr>
                {' '}
                <td>
                  {' '}
                  <ul>
                    {' '}
                    <li> (f) Inward taxable </li>
                  </ul>{' '}
                </td>{' '}
              </tr>
              <tr>
                {' '}
                <td>
                  {' '}
                  <ul>
                    {' '}
                    <li> (g) Inward Exempted </li>
                  </ul>{' '}
                </td>
              </tr>
              <tr>
                {' '}
                <td>
                  {' '}
                  <ul>
                    {' '}
                    <li> (h) Total tax payable </li>
                  </ul>{' '}
                </td>{' '}
              </tr>
              <tr>
                {' '}
                <td>
                  {' '}
                  <ul>
                    {' '}
                    <li> (i) Total paid ITC </li>
                  </ul>{' '}
                </td>{' '}
              </tr>
            </tbody>
          </table>
        </main>
      </section>
    );
  }
}

export { GSTRReport };
