import axios from 'axios';

import { authHeader } from '@H';

export default {
  getNavigationData: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/utils/navigation_bar/`,
    }),
};
