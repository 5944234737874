import React, { Component } from 'react';

class OtherIncome extends Component {
  constructor() {
    super();
  }

  render() {
    const summary = this.props.summary;
    return (
      <>
        <section className="widget dsr-table">
          <header></header>
          <main>
            <table className="dsr" data-name="Other Income">
              <thead>
                <tr>
                  <th colSpan={2} className="alert-brand">
                    {' '}
                    Other Income
                  </th>
                </tr>
                <tr data-header={true}>
                  <th> Description </th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {summary.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td>{item.income_type}</td>
                      <td>
                        {new Intl.NumberFormat('en-IN', {
                          style: 'currency',
                          currency: 'INR',
                        }).format(item.amount.toFixed(2) || 0)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </main>
        </section>
      </>
    );
  }
}

export default OtherIncome;
