import React, { Component } from 'react';
import dayjs from 'dayjs';

import services from '@C/Reports/_utils/data';
import { POSList } from '@C/Reports/_utils/POSList';
import { formatCurrency, TableGrid } from '@C/TableGrid';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';

const col = [
  {
    name: 'Date',
    key: 'date',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Narration',
    key: 'narration',
  },
  {
    name: 'Credit',
    key: 'all',
    formatter(props) {
      return <>{formatCurrency(props.credit)}</>;
    },
  },
  {
    name: 'Debit',
    key: 'all',
    formatter(props) {
      return <>{formatCurrency(props.debit)}</>;
    },
  },
  {
    name: 'Balance',
    key: 'all',
    formatter(props) {
      return <>{formatCurrency(props.outstanding)}</>;
    },
  },
];

class POSLedger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      swipeList: [],
      bank_id: '',
      start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
      loading: false,
      limit: 20,
      offset: 1,
      count: 0,
    };
    this.limitInput = React.createRef();
  }

  componentDidMount() {
    this.getMappedPOS();
    const observer = new IntersectionObserver(this.handleObserver.bind(this), {
      threshold: 0.5,
    });
    this.setState({ observer });
    observer.observe(this.loader);
  }

  componentWillUnmount() {
    const { observer } = this.state;
    if (observer) {
      observer.disconnect();
    }
  }

  getMappedPOS = () => {
    services
      .getMappedPOS()
      .then((response) => {
        const tmp = response.data.data;
        const CategoryOptions = [];
        for (let i = 0; i < tmp.length; i++) {
          CategoryOptions.push({
            value: tmp[i].bunk_card_uuid,
            label: tmp[i].name,
          });
        }
        this.setState(
          {
            swipeList: CategoryOptions,
            bank_id: CategoryOptions[0].value,
          },
          function () {
            this.getPOSLedger();
          },
        );
      })

      .catch((error) => {
        console.log(error);
      });
  };


  handleObserver(entities) {
    const { loading, count, limit, offset } = this.state;
    const loadMore = entities[0];
    if (loadMore.isIntersecting && !loading && count > offset * limit) {
      this.setState(
        (prevState) => ({ offset: prevState.offset + 1, loading: true }),
        () => this.getPOSLedger(),
      );
    }
  }

  handleQuickSearch = (e) => {
    e.preventDefault();
    if (
      this.limitInput.current.value === '' ||
      this.limitInput.current.value === '0'
    ) {
      return;
    }
    this.setState(
      { limit: this.limitInput.current.value, offset: 1 },
      function () {
        this.getPOSLedger();
      },
    );
  };

  handleDropChange = (e) => {
    this.setState({ bank_id: e.value });
  };

  getPOSLedger = () => {
    const { bank_id, start_date, end_date, limit, offset } = this.state;
    this.setState({ loading: true });
    services
      .getPOSLedger({ bank_id, start_date, end_date, limit, offset })
      .then((response) => {
        this.setState((prevState) => ({
          swipeList:
            offset === 1
              ? response.data.data.results
              : [...prevState.swipeList, ...response.data.data.results],
          count: response.data.data.count,
          loading: false,
        }));
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date' && dayjs(value).isAfter(this.state.end_date)) {
      this.setState({ [name]: value, end_date: value });
    } else if (
      name === 'end_date' &&
      dayjs(value).isBefore(this.state.start_date)
    ) {
      this.setState({ [name]: value, start_date: value });
    } else {
      this.setState({ [name]: value });
    }
  };

  resetFilter = () => {
    const { swipeList } = this.state;
    const firstPOS = swipeList.length > 0 ? swipeList[0].value : '';
    this.limitInput.current.value = 20;
    this.setState(
      {
        start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
        loading: true,
        bank_id: '',
        limit: 20,
        offset: 1,
      },
      function () {
        this.getPOSLedger();
        if (this.POSListRef) {
          this.POSListRef.handleReset();
        }
      },
    );
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({ offset: 1 }, function () {
      this.getPOSLedger();
    });
  };

  render() {
    const { swipeList } = this.state;
    return (
      <section className="widget list-table full-width inverted">
        <header>
          <h3>POS Ledger</h3>
        </header>
        <main>
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group">
                <label>From date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.start_date}
                  name="start_date"
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="filter-group">
                <label>To date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.end_date}
                  min={this.state.start_date}
                  onChange={this.handleDateChange}
                  name="end_date"
                />
              </div>

              <div className="filter-group">
                <label>Select POS machine:</label>
                <POSList
                  handleBankChange={this.handleDropChange}
                  value={this.state.bank_id}
                  ref={(ref) => { this.POSListRef = ref; }}
                />
              </div>
              <div className="filter-group with-icon">
                <label>
                  Results to display- {this.state.limit || 20} (Total:{' '}
                  {this.state.count})
                </label>
                <form onSubmit={this.handleQuickSearch}>
                  <input type="text" name="limit" ref={this.limitInput} />
                  <button
                    type="submit"
                    className="search-icon"
                    onClick={this.handleQuickSearch}
                  ></button>
                </form>
              </div>
              <div className="filter-group reset-filter">
                <button onClick={this.resetFilter}>Reset filters</button>
              </div>
              <div className="filter-group">
                  <button
                    className="submit"
                    type="submit"
                    onClick={this.submitForm}
                  >
                    Apply filter
                  </button>
                </div>
            </div>
          </div>
          <div className="table-wrapper">
            <div className="mini-summary">
              <ul>
                <li>
                  <span> Closing balance: </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {}).format(
                      swipeList[0]?.outstanding?.toFixed(2) || 0,
                    )}
                  </span>
                </li>
              </ul>
            </div>
            {(() => {
              if (this.state.loading) {
                return <DropLoaderLarge />;
              } else {
                return (
                  <TableGrid
                    col={col}
                    row={swipeList}
                    offset={this.state.offset}
                    count={this.state.count}
                    limit={this.state.limit}
                    pagination="disabled"
                    hideActions={true}
                    exportOptions={{
                      fileName: `Swipe Ledger - ${this.state.start_date} to ${this.state.end_date}.pdf`,
                      reportName: 'Swipe Ledger Report',
                      reportDate: `Date - ${dayjs(this.state.start_date).format('D MMM YY')} to ${dayjs(this.state.end_date).format('D MMM YY')}`,
                      reportTotal: `Closing balance -  ${
                        swipeList[0]?.outstanding?.toFixed(2) || 0
                      }`,
                      reportQuantity: '',
                      reportSales: '',
                      reportTesting: `Total amount credited -  ${swipeList
                        .reduce((a, b) => a + (b['credit'] || 0), 0)
                        .toFixed(2) || 0
                        }`,
                      reportBowser: `Total amount debited -  ${swipeList
                        .reduce((a, b) => a + (b['debit'] || 0), 0)
                        .toFixed(2) || 0
                        }`,
                      reportCustomerName: '',
                      reportGst: '',
                      reportOpeningBalance: '',
                      reportClosingBalance: '',
                      reportSaleTotal: '',
                      reportTCSTotal: '',
                    }}
                  />
                );
              }
            })()}
            <div
              ref={(ref) => {
                this.loader = ref;
              }}
            ></div>
             <div className="mini-summary">
              <ul>
                <li>
                  <span>Total amount credited: </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      swipeList.reduce((a, b) => a + (b['credit'] || 0), 0) || 0
                    )}
                  </span>

                </li>
                <li>
                  <span>Total amount debited : </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      swipeList.reduce((a, b) => a + (b['debit'] || 0), 0) ||
                      0,
                    )}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </main>
      </section>
    );
  }
}

export { POSLedger };
