import React, { Component } from 'react';
import dayjs from 'dayjs';
import SimpleReactValidator from 'simple-react-validator';

import { BackupList } from './backupList';

class DataBackUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: dayjs().format('YYYY'),
      file_list: [
        {
          file_description: '',
          file: null,
        },
      ],
      totalFilesUploaded: 0,
    };
    this.myRef = React.createRef();
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
  }

  addRow = () => {
    const { file_list, totalFilesUploaded } = this.state;
    if (file_list.length < 5 && totalFilesUploaded < 10) {
      this.setState((prevState) => ({
        file_list: [
          ...prevState.file_list,
          {
            file_description: '',
            file: null,
          },
        ],
      }));
    }
  };

  deleteRow = (index) => {
    const { file_list, totalFilesUploaded } = this.state;
    const updatedFileList = file_list.filter((_, i) => i !== index);
    this.setState({
      file_list: updatedFileList,
      totalFilesUploaded: totalFilesUploaded - 1,
    });
  };

  handleDateChange = (e) => {
    this.setState({
      year: e.target.value,
    });
  };

  handleFileChange = (index, e) => {
    const file = e.target.files[0];
    const { file_list, totalFilesUploaded } = this.state;

    if (file.size > 1048576) {
      alert('File size should be less than 1MB');
      return;
    }

    if (file.type !== 'application/pdf') {
      alert('File should be in PDF format');
      return;
    }

    const updatedFileList = [...file_list];
    updatedFileList[index].file = file;

    this.setState({
      file_list: updatedFileList,
      totalFilesUploaded: totalFilesUploaded + 1,
    });
  };

  renderYearOptions = () => {
    const currentYear = dayjs().year();
    const yearOptions = [];
    for (let i = currentYear; i >= 1900; i--) {
      yearOptions.push(
        <option key={i} value={i}>
          {i}
        </option>,
      );
    }
    return yearOptions;
  };

  render() {
    const { file_list } = this.state;

    return (
      <>
        <section className="widget full-width form-table">
          <main>
            <div className="form-header">
              <div className="input-wrapper with-label">
                <label> Select year </label>
                <input
                  className="cstm-date-input"
                  type="year"
                  value={this.state.year}
                  max={dayjs().format('YYYY')}
                  name="year"
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="actions">
                <button
                  className="add-row"
                  onClick={this.addRow}
                  disabled={
                    this.state.file_list.length >= 5 ||
                    this.state.totalFilesUploaded >= 10
                  }
                >
                  Add row
                </button>
              </div>
            </div>
            <div className="form-content">
              <table>
                <thead>
                  <tr>
                    <th>File description</th>
                    <th>File Location</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {file_list.map((item, i) => (
                    <tr key={i}>
                      <td>
                        <div className="input-wrapper">
                          <input
                            type="text"
                            value={item.file_description}
                            placeholder="File description"
                            name="file_description"
                            onChange={(e) => {
                              const updatedList = [...file_list];
                              updatedList[i].file_description = e.target.value;
                              this.setState({ file_list: updatedList });
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper">
                          <input
                            type="file"
                            id={`file_${i}`}
                            name="file"
                            onChange={(e) => this.handleFileChange(i, e)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="input-wrapper _actions">
                          {i > 0 ? (
                            <button
                              className="delete-row icon-only"
                              onClick={() => this.deleteRow(i)}
                            >
                              Delete row
                            </button>
                          ) : (
                            ''
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="form-actions">
              <button
                className="submit"
                type="submit"
                disabled={this.state.isSubmitting}
                onClick={this.submitForm}
              >
                Upload
              </button>
            </div>
          </main>
        </section>
        <BackupList />
      </>
    );
  }
}

export { DataBackUp };
