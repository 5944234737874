import React, { useState } from 'react';

import { useGlobalStore } from '@stores/globalStore';

import CashSummary from './CashSummary';
import { CODSalary } from './CODSalary';
import CreditCollection from './CreditCollection';
import NeftSummary from './NeftSummary';
import SwipeSummary from './SwipeSummary';
import TempCredit from './TempCredit';
import WalletsSummary from './WalletsSummary';

const CollectionSummary = (props) => {
  const { syncCODCollectionData } = useGlobalStore((state) => ({
    syncCODCollectionData: state.syncCODCollectionData,
  }));

  const updateTotalSummary = () => {
    syncCODCollectionData(props.match.params.duty, props.match.params.staff);
  };

  const env = process.env.ENVIRONMENT;

  const [data, setData] = useState({
    updateWallet: randomKey(),
    updateSwipe: randomKey(),
    updateNeft: randomKey(),
  });

  function randomKey() {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 10) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  const updateCollectionSummary = () => {
    setData(() => ({
      updateWallet: randomKey(),
      updateSwipe: randomKey(),
      updateNeft: randomKey(),
    }));
  };

  return (
    <>
      <CashSummary {...props} onDataChange={updateTotalSummary} />
      <WalletsSummary
        {...props}
        onDataChange={updateTotalSummary}
        key={data.updateWallet}
      />
      <SwipeSummary
        {...props}
        onDataChange={updateTotalSummary}
        key={data.updateSwipe}
      />
      <NeftSummary
        {...props}
        onDataChange={updateTotalSummary}
        key={data.updateNeft}
      />
      <CreditCollection
        {...props}
        onDataChange={updateTotalSummary}
        onCreditCollectionUpdate={updateCollectionSummary}
      />
      <TempCredit {...props} onDataChange={updateTotalSummary} />
      <CODSalary
        {...props}
        onDataChange={updateTotalSummary}
        onSalaryUpdate={updateCollectionSummary}
      />
    </>
  );
};

export { CollectionSummary };
