import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import { CustomerList } from '@C/CreditCustomers/_utils';
import services from '@C/CreditCustomers/_utils/data';
import { TableGrid } from '@C/TableGrid';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { authHeader } from '@H';

const col = [
  {
    name: 'Customer',
    key: 'customer',
    formatter(props) {
      return <>{props.name} </>;
    },
  },
  {
    name: 'Mobile',
    key: 'customer',
    formatter(props) {
      return <>{props.mobile} </>;
    },
  },
  {
    name: 'Blacklisted by',
    key: 'blocked_by',
    formatter(props) {
      return <>{props.bunk} </>;
    },
  },
  {
    name: 'City',
    key: 'blocked_by',
    formatter(props) {
      return <>{props.city} </>;
    },
  },
  {
    name: 'State',
    key: 'blocked_by',
    formatter(props) {
      return <>{props.state} </>;
    },
  },
  {
    name: 'Notes',
    key: 'details',
    formatter(props) {
      return <>{props.notes} </>;
    },
  },
];

class BlackList extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
    this.state = {
      list: [],
      uuid: '',
      notes: '',
      offset: 1,
      limit: 20,
      count: 0,
      updated: false,
      loading: true,
      city: '',
      state: '',
      isSubmitting: false,
    };
    this.limitInput = React.createRef();
  }
  componentDidMount() {
    this.getBlackList();
  }

  getBlackList = () => {
    services
      .getBlackList(this.state)
      .then((response) => {
        this.setState({
          list: response.data.data.results,
          count: response.data.data.count,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleCustomerChange = (e) => {
    this.setState({ uuid: e.value });
  };

  handleNotesChange = (e) => {
    this.setState({ notes: e.target.value });
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  applyForm = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, function () {
      this.getBlackList();
    });
  };

  resetFilter = () => {
    this.setState(
      {
        loading: true,
        city: '',
        state: '',
      },
      function () {
        this.getBlackList();
      },
    );
  };
  handlePagination = (e) => {
    this.setState({ offset: e, loading: true }, function () {
      this.getBlackList();
    });
  };

  handleQuickSearch = (e) => {
    e.preventDefault();
    if (
      this.limitInput.current.value === '' ||
      this.limitInput.current.value === '0'
    ) {
      return;
    }
    this.setState(
      { limit: this.limitInput.current.value, offset: 1 },
      function () {
        this.getBlackList();
      },
    );
  };

  submitForm = () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState({ isSubmitting: true });
    const formData = this.state;
    const headers = authHeader(1);
    axios
      .post(
        `${process.env.API_URL}/credit-customer/bunk/block-list/`,
        formData,
        {
          headers,
        },
      )
      .then(() => {
        toast.success('Customer blacklisted successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.getBlackList();
        this.setState({
          uuid: '',
          notes: '',
          updated: !this.state.updated,
          isSubmitting: false,
        });
      })
      .catch((error) => {
        toast.error(error.response.data.error, {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      });
    this.setState({ isSubmitting: false });
  };

  render() {
    const { list } = this.state;
    return (
      <>
        <section className="widget full-width actions-widget">
          <main>
            <div className="input-wrapper">
              {this.validator.message('customer', this.state.uuid, 'required')}
              <CustomerList
                onCustomerChange={this.handleCustomerChange}
                value={this.state.uuid}
              />
            </div>
            <div className="input-wrapper">
              {this.validator.message('notes', this.state.notes, 'required')}
              <textarea
                value={this.state.notes}
                name="notes"
                onChange={this.handleNotesChange}
              />
            </div>
            <div className="input-wrapper">
              <button
                className="submit"
                type="submit"
                disabled={this.state.isSubmitting}
                onClick={this.submitForm}
              >
                Submit
              </button>
            </div>
          </main>
        </section>
        <section className="widget full-width list-table">
          <header className="list-header">
            <h3>Black Listed Customers</h3>
          </header>
          <main>
            <div className="table-filters">
              <div className="filter-wrapper">
                <div className="filter-group with-icon">
                  <label>
                    Results to display -{this.state.limit || 20} (Total:{' '}
                    {this.state.count})
                  </label>
                  <form onSubmit={this.handleQuickSearch}>
                    <input type="text" name="limit" ref={this.limitInput} />
                    <button
                      type="submit"
                      className="search-icon"
                      onClick={this.handleQuickSearch}
                    />
                  </form>
                </div>
                <div className="filter-group">
                  <label> City</label>
                  <input
                    type="text"
                    value={this.state.city}
                    name="city"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="filter-group">
                  <label> State</label>
                  <input
                    type="text"
                    value={this.state.state}
                    name="state"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="filter-group reset-filter">
                  <button onClick={this.resetFilter}>Reset filters</button>
                </div>
                <div className="filter-group">
                  <button
                    className="submit"
                    type="submit"
                    onClick={this.applyForm}
                  >
                    Apply filter
                  </button>
                </div>
              </div>
            </div>
            <div className="table-wrapper">
              {(() => {
                if (this.state.loading) {
                  return <DropLoaderLarge />;
                } else {
                  return (
                    <>
                      <TableGrid
                        col={col}
                        row={list}
                        offset={this.state.offset}
                        count={this.state.count}
                        limit={this.state.limit}
                        handlePagination={this.handlePagination}
                        hideActions={true}
                      />
                    </>
                  );
                }
              })()}
            </div>
          </main>
        </section>
      </>
    );
  }
}

export { BlackList };
