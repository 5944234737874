import React, { Component } from 'react';
import Select from 'react-select';

const CategoryOptions = [
  {
    value: 0,
    label: 'Today',
  },
  {
    value: 1,
    label: 'Next day',
  },
];

class SettlementOptions extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      options: CategoryOptions,
    };
  }

  handleChange = (selectedOption) => {
    this.props.onChange(selectedOption);
  };

  render() {
    const options = this.state.options;
    return (
      <Select
        onChange={this.handleChange}
        options={options}
        className="select-box-container"
        classNamePrefix="raw-select"
        placeholder="Select settlement option"
        defaultValue={{ value: 'TODAY', label: 'Today' }}
        value={options.filter((option) => option.value === this.props.value)}
      />
    );
  }
}

export { SettlementOptions };
