import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import { Fields, getPaymentModes, Modes } from '@C/Banking/';
import { ToggleSwitch } from '@C/FormElements';
import PaymentList from '@C/Purchases/RouteComponents/VendorPayments/PaymentList';
import { FilteredStaffList, InvoiceList, VendorList } from '@C/Utils';
import { authHeader } from '@H';

class VendorPayments extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
    });
    this.myRef = React.createRef();
  }

  state = {
    invoice_number: '',
    invoice_uuid: '',
    invoice_type: '',
    cashier_id: null,
    staff_id: '',
    time_stamp: dayjs().format('YYYY-MM-DD'),
    is_partial: false,
    amount: '',
    pending_amount: 0,
    supplier: {
      uuid: '',
    },
    payment: {
      bank_uuid: '',
      payment_type: 'OFFLINE',
      source: 'CASH',
      check_date: '',
      check_number: '',
      mode: 'cash',
      notes: '',
    },
    updated: false,
    editMode: false,
    disabled: true,
    isSubmitting: false,
  };

  changeDate = (e) => {
    this.setState({ time_stamp: e.target.value });
  };

  handleStaffChange = (e) => {
    this.setState({
      staff_id: e.value,
    });
  };

  changePartial = () => {
    this.setState({ is_partial: !this.state.is_partial });
  };

  handleVendorChange = (value) => {
    const supplier = { ...this.state.supplier };
    supplier.uuid = value.value;
    let disabled = false;
    let invoice_uuid = this.state.invoice_uuid;
    let pending_amount = this.state.pending_amount;
    let invoice_type = this.state.invoice_type;
    if (value.value === '') {
      disabled = true;
      invoice_uuid = '';
      pending_amount = 0;
      invoice_type = '';
    }
    this.setState({ supplier, disabled, invoice_uuid, pending_amount });
  };

  handleBankChange = (e, f) => {
    const list = { ...this.state.payment };
    list.bank_uuid = e.value;
    this.setState({ payment: list });
  };
  handleInvoiceChange = (e) => {
    this.setState({
      invoice_uuid: e.value,
      invoice_number: e.label,
      pending_amount: e.pending,
      invoice_type: e.type,
    });
  };

  handlePaymentSelect = (mode, i) => {
    const list = { ...this.state.payment };
    list.mode = mode;
    if (mode == 'cash') {
      list.payment_type = 'OFFLINE';
      list.source = 'CASH';
    }
    this.setState({ payment: list });
  };

  onDataChange = (e, i) => {
    const list = { ...this.state.payment };
    list.bank_uuid = e.bank_id;
    list.check_number = e.bank_reference_id;
    list.check_date = e.check_date;
    list.payment_type = e.payment_method;
    list.source = e.transaction_source;
    this.setState({ payment: list });
  };

  handleAmount = (e) => {
    this.setState({ amount: e.target.value });
  };

  handleNotes = (e) => {
    const list = { ...this.state.payment };
    list[e.target.name] = e.target.value;
    this.setState({ payment: list });
  };

  editMode = (item) => {
    const paymentMode = getPaymentModes(
      item.payment.source,
      item.payment.payment_method,
    );
    this.setState({
      invoice_number: item.invoice.invoice_number,
      invoice_uuid: item.invoice.uuid,
      staff_id: item.payment.staff.uuid,
      time_stamp: dayjs(item.payment.time_stamp).format('YYYY-MM-DD'),
      amount: item.payment.amount,
      supplier: {
        uuid: item.invoice.vendor.uuid,
      },
      payment: {
        bank_reference_id: item.payment.bank_reference_id,
        bank: item.payment.bank || null,
        bank_uuid: item.payment.bank?.uuid || null,
        payment_type: item.payment.payment_method || 'OFFLINE',
        source: item.payment.source || 'CASH',
        check_date: item.payment.check_date || '',
        check_number: item.payment.bank_reference_id || '',
        notes: item.payment.notes,
        mode: paymentMode,
      },
      editMode: true,
      transaction_id: item.uuid,
    });
  };

  cancelEdit = () => {
    this.setState({
      invoice_number: '',
      invoice_uuid: '',
      staff_id: '',
      time_stamp: dayjs().format('YYYY-MM-DD'),
      is_partial: false,
      amount: '',
      pending_amount: '',
      supplier: {
        uuid: '',
      },
      payment: {
        bank_uuid: '',
        payment_type: 'OFFLINE',
        source: 'CASH',
        check_date: '',
        check_number: '',
        mode: 'cash',
        notes: '',
      },
      updated: false,
      editMode: false,
      isSubmitting: false,
    });
  };

  submitForm = () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState({ isSubmitting: true });
    const formData = this.state;
    formData.cashier_id = formData.staff_id;

    const headers = authHeader(1);

    let method = 'post';

    if (this.state.editMode) {
      method = 'put';
      formData.uuid = this.state.transaction_id;
    }

    axios({
      method: method,
      url: `${process.env.API_URL}/lubes/bunk/vendor/payments/`,
      data: formData,
      headers: headers,
    })
      .then((response) => {
        toast.success('Payment added successfully');
        window.scrollTo({
          behavior: 'smooth',
          top: this.myRef.current.offsetTop - 150,
        });
        this.setState({
          invoice_number: '',
          invoice_uuid: '',
          invoice_type: '',
          cashier_id: null,
          staff_id: null,
          time_stamp: dayjs().format('YYYY-MM-DD'),
          is_partial: true,
          pending_amount: '',
          amount: '',
          supplier: {
            uuid: '',
          },
          payment: {
            bank_uuid: '',
            payment_type: 'OFFLINE',
            source: 'CASH',
            check_date: '',
            check_number: '',
            notes: '',
          },
          updated: !this.state.updated,
          isSubmitting: false,
        });
        this.validator.hideMessages();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
        this.setState({ isSubmitting: false });
      });
  };

  render() {
    const editMode = this.state.editMode;
    return (
      <>
        <section className="widget full-width grid-form" ref={this.myRef}>
          <header>
            <h3>{editMode ? 'Edit' : 'Add'} payment</h3>
          </header>
          <main>
            <div className="form-content">
              <div className="input-wrapper with-label ">
                <label className="label">Date</label>
                <input
                  type="date"
                  name="selectDate"
                  onChange={this.changeDate}
                  value={this.state.time_stamp}
                />
              </div>
              <div className="input-wrapper with-label">
                <label className="label">Staff</label>
                {this.validator.message(
                  'staff_id',
                  this.state.staff_id,
                  'required',
                )}
                <FilteredStaffList
                  onChange={this.handleStaffChange}
                  value={this.state.staff_id}
                />
              </div>
              <div className="input-wrapper with-label">
                <label className="label">Vendor</label>
                {this.validator.message(
                  'supplier.uuid',
                  this.state.supplier.uuid,
                  'required',
                )}
                <VendorList
                  onChange={this.handleVendorChange}
                  value={this.state.supplier.uuid}
                />
              </div>
              <div className="input-wrapper with-label">
                <label className="label">Invoice</label>
                {this.validator.message(
                  'invoice_uuid',
                  this.state.invoice_uuid,
                  'required',
                )}
                <InvoiceList
                  onChange={this.handleInvoiceChange}
                  value={this.state.invoice_uuid}
                  vendor={this.state.supplier.uuid}
                  key={this.state.supplier.uuid}
                  disabled={this.state.disabled}
                />
              </div>
              <div className="input-wrapper with-label">
                <label className="label">Pending amount</label>

                <input
                  type="text"
                  value={parseFloat(this.state.pending_amount).toFixed(2)}
                  disabled
                />
              </div>
              <div className="input-wrapper with-label">
                <label className="label">Amount</label>
                {this.validator.message(
                  'Amount',
                  this.state.amount,
                  'required',
                )}
                <input
                  type="number"
                  onChange={this.handleAmount}
                  value={this.state.amount}
                  id="amount"
                  name="amount"
                />
              </div>
              <div className="input-group">
                <div className="input-wrapper with-label">
                  <label className="label">Payment mode</label>
                  {this.validator.message(
                    'payment.mode',
                    this.state.payment.mode,
                    'required',
                  )}
                  <Modes
                    handleModeChange={this.handlePaymentSelect}
                    value={this.state.payment.mode || 'cash'}
                    bank={this.state.payment.bank}
                    bank_reference_id={this.state.payment.bank_reference_id}
                    check_date={this.state.payment.check_date}
                  />
                </div>
                <Fields
                  method={this.state.payment.mode}
                  onDataChange={this.onDataChange}
                />
              </div>
              {/* <div className="input-wrapper  toggle-element">
                <span className="label"> Mark as partial payment: </span>
                <ToggleSwitch
                  did={0}
                  id="partialP"
                  name="partialP"
                  checked={this.state.is_partial}
                  onChange={this.changePartial}
                />
              </div> */}
              <div className="input-wrapper with-label">
                <label className="label"> Notes</label>
                <input
                  type="text"
                  name="notes"
                  value={this.state.payment.notes}
                  onChange={this.handleNotes}
                />
              </div>
            </div>
            <div className="form-actions">
              <button
                className="submit"
                onClick={this.submitForm}
                disabled={this.state.isSubmitting}
              >
                Save
              </button>
              {editMode && (
                <button
                  className="red-btn"
                  disabled={this.state.isSubmitting}
                  onClick={this.cancelEdit}
                >
                  Cancel
                </button>
              )}
            </div>
          </main>
        </section>
        <PaymentList onEdit={this.editMode} key={this.state.updated} />
      </>
    );
  }
}
export { VendorPayments };
