/* eslint-disable new-cap */
import dayjs from 'dayjs';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import jsPDFInvoiceTemplate, { OutputType } from 'jspdf-invoice-template';

import { formatCurrency, InterFont } from '@C/TableGrid';
import { authService } from '@S';

export const getBase64FromUrl = async (url) => {
  const data = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      Accept: 'image/png',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export const generateInvoiceCCNew = async (data, resolve, reject) => { };

export async function generateInvoiceCC(data) {
  const bunkAddress = authService.currentBunkAddress;
  const slipData = data;
  const pdfTax = {
    value: 0,
    label: '',
  };
  if (slipData.tax.length > 0) {
    slipData.tax.map((item) => {
      pdfTax.value += item.percentage;
      pdfTax.label += `${item.name}/`;
    });
  }
  const pdfData = {
    outputType: OutputType.Save,
    returnJsPDFDocObject: true,
    fileName: `Invoice - ${slipData.credit_customer.name} - ${dayjs(
      slipData.time_stamp,
    ).format('DD-MM-YYYY')}`,
    orientationLandscape: false,
    compress: true,
    logo: {
      src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a3/Indian_Oil_Logo.svg/512px-Indian_Oil_Logo.svg.png',
      type: 'PNG', // optional, when src= data:uri (nodejs case)
      width: 25, // aspect ratio = width/height
      height: 30,
      margin: {
        top: 0, // negative or positive num, from the current position
        left: 0, // negative or positive num, from the current position
      },
    },
    stamp: {
      inAllPages: true, // by default = false, just in the last page
      src: 'https://res.cloudinary.com/hecto-networks/image/upload/v1659939986/Webp_net_resizeimage_b6fb9d01d3.png',
      type: 'JPG', // optional, when src= data:uri (nodejs case)
      width: 15, // aspect ratio = width/height
      height: 20,
      margin: {
        top: 0, // negative or positive num, from the current position
        left: 0, // negative or positive num, from the current position
      },
    },
    business: {
      name: authService.currentBunkName,
      address: `${bunkAddress.city}, ${bunkAddress.location}, ${bunkAddress.country.country} - ${bunkAddress.pincode}`,
    },
    contact: {
      label: 'Invoice issued for:',
      name: `${slipData.credit_customer.name.replace(/(^|\s)\S/g, function (t) {
        return t.toUpperCase();
      })}`,
    },
    invoice: {
      label: 'Invoice #: ',
      num: slipData.slip_no || 'NA',
      invGenDate: `Invoice Date: ${dayjs(slipData.time_stamp).format(
        'D MMMM YYYY',
      )}`,
      headerBorder: false,
      tableBodyBorder: false,
      header: [
        {
          title: '#',
          style: {
            width: 10,
          },
        },
        {
          title: 'Product',
          style: {
            width: 60,
          },
        },
        {
          title: 'Rate',
        },
        { title: 'Quantity' },
        { title: 'Amount (excl tax)' },
        { title: 'Amount (incl tax)' },
      ],
      table: [
        [
          1,
          `${slipData.product.name}`,
          `${slipData.product.price.toFixed(2)}`,
          `${slipData.quantity}`,
          `${slipData.after_discount.toFixed(2)}`,
          `${slipData.after_tax.toFixed(2)}`,
        ],
      ],
      additionalRows: [
        {
          col1: 'Total:',
          col2: `${slipData.after_discount.toFixed(2)}`,
          style: {
            fontSize: 10, // optional, default 12
          },
        },
        {
          col1: `Discount`,
          col2: `${slipData.discount || 0.0}`,
          style: {
            fontSize: 10, // optional, default 12
          },
        },
        {
          col1: `Tax: ${pdfTax.label || '--'}`,
          col2: ` ${pdfTax.value}%`,
          style: {
            fontSize: 10, // optional, default 12
          },
        },
        {
          col1: 'Grand Total:',
          col2: `${slipData.after_tax.toFixed(2)}`,
          style: {
            fontSize: 14, // optional, default 12
          },
        },
      ],
    },
    footer: {
      text: 'The invoice is created on a computer and is valid without the signature and stamp.',
    },
    pageEnable: true,
    pageLabel: 'Page ',
  };
  jsPDFInvoiceTemplate(pdfData);
}

export async function generateCompleteInvoiceCC(
  data,
  dataBalance,
  additionalData,
  resolve,
  reject,
  start_date,
  end_date,
  gst_no,
) {
  const bunkLogo = getBase64FromUrl(authService.currentOMCWebLogo);
  const upiQr = additionalData.upi_qr;
  // read promise value
  bunkLogo.then((result) => {
    // calcualte image width and height based on aspect ratio
    const img = new Image();
    img.onload = function () {
      const doc = new jsPDF();
      const pageWidth = doc.internal.pageSize.getWidth();
      const textWidth = pageWidth - 40;
      doc.addFileToVFS('Inter-Regular.ttf', InterFont);
      doc.addFont('Inter-Regular.ttf', 'Inter', 'normal');
      doc.setFont('Inter');
      const slipData = data;
      const balanceData = dataBalance;
      const bunkAddress = authService.currentBunkFullAddress;
      const tableData = [];
      const total = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
      }).format(slipData.reduce((a, b) => a + (b['after_tax'] || 0), 0) || 0);
      const discount = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
      }).format(slipData.reduce((a, b) => a + (b['discount'] || 0), 0) || 0);

      slipData.map((item, i) => {
        tableData.push([
          i + 1,
          dayjs(item.time_stamp).format('D MMMM YYYY'),
          item.slip_no || '--',
          item.vehicle?.vehicle_no || '--',
          item.product?.name || 'Cash given',
          item.quantity.toFixed(2),
          item.product?.price,
          item.tax.tax_group_name,
          Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
          }).format(item.discount.toFixed(2)),
          Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
          }).format(item.after_tax.toFixed(2)),
        ]);
      });

      const tableData3 = [];
      balanceData.map((item, i) => {
        tableData3.push([
          // `${item.opening_date}: ${item.opening_balance_on_date.toFixed(2)}`,
            `${item.closing_date}: ${item.closing_balance_on_date.toFixed(2)}`,
        ]);
      });

      // group slipData by product.name and sum the quantity and make it as an array of arrays
      const groupedData = slipData.reduce((result, item) => {
        const foundItem = result.find(
          (group) => group[0] === item.product?.name,
        );
        if (foundItem) {
          foundItem[1] += +item.quantity;
          foundItem[2] += +item.after_tax;
        } else {
          result.push([
            item.product?.name || 'Cash given',
            item.quantity,
            item.after_tax,
          ]);
        }
        return result;
      }, []);

      // map thourgh grouped data
      const tableData2 = [];
      groupedData.map((item, i) => {
        tableData2.push([
          i + 1,
          item[0],
          item[1].toFixed(2),
          formatCurrency(item[2]),
        ]);
      });
      const aspectRatio = img.width / img.height;
      const width = 25;
      const height = width / aspectRatio;
      try {
        doc.addImage(result, 'PNG', 13, 10, width, height);
        autoTable(doc, {
          body: [
            [
              {
                content: authService.currentBunkName,
                styles: {
                  halign: 'right',
                  fontSize: 18,
                  textColor: '#000000',
                },
              },
            ],
            [
              {
                content: `GST: ${authService.currentUserGst}`,
                styles: {
                  halign: 'right',
                  fontSize: 12,
                  textColor: '#000000',
                },
              },
            ],
            [
              {
                content: bunkAddress || 'Full address',
                styles: {
                  halign: 'right',
                  fontSize: 9,
                  textColor: '#000000',
                },
              },
            ],
            [
              {
                content: `Mobile no: ${authService.currentUserMobileNumber}`,
                styles: {
                  halign: 'right',
                  fontSize: 10,
                  textColor: '#000000',
                },
              },
            ],
          ],
          theme: 'plain',
        });
        autoTable(doc, {
          body: [
            [
              {
                content: slipData[0].credit_customer.name.toUpperCase(),
                styles: {
                  halign: 'left',
                  fontSize: 13,
                  textColor: '#ffffff',
                },
              },
              {
                content: `INVOICE`,
                styles: {
                  halign: 'right',
                  fontSize: 13,
                  textColor: '#ffffff',
                },
              },
            ],
          ],
          theme: 'plain',
          styles: {
            fillColor: '#000000',
          },
        });

        autoTable(doc, {
          body: [
            [
              {
                content: `GST: ${gst_no || '--'}`,
                styles: {
                  halign: 'left',
                  fontSize: 10,
                  textColor: '#000000',
                },
              },
              {
                content: `Invoice Date: ${dayjs(additionalData.invoice_date !== '' ? additionalData.invoice_date : '').format('D MMM YY')}`,
                styles: {
                  halign: 'right',
                  fontSize: 10,
                  textColor: '#000000',
                },
              },
            ],
            [
              {
                content: `Invoice No: ${additionalData.invoice_number !== '' ? additionalData.invoice_number : ''}`,
                styles: {
                  halign: 'left',
                  fontSize: 10,
                  textColor: '#000000',
                },
              },
              {
                content: `From : ${dayjs(start_date).format('D MMM YY')} to: ${dayjs(end_date).format('D MMM YY')}`,
                styles: {
                  halign: 'right',
                  fontSize: 10,
                },
              },
            ],
          ],
          theme: 'plain',
        });

        autoTable(doc, {
          head: [
            [
              '#',
              'Date',
              'Slip number',
              'Vehicle',
              'Product',
              'Quantity',
              'Rate',
              'Tax',
              'Discount',
              'Amt (incl tax)',
            ],
          ],
          body: tableData,
          theme: 'striped',
          headStyles: {
            fillColor: '#000000',
          },
          styles: {
            font: 'Inter',
            fontStyle: 'normal',
            fontSize: 9,
          },
        });
        autoTable(doc, {
          body: [
            [
              {
                content: ``,
                styles: {
                  halign: 'right',
                  fontSize: 0.1,
                },
              },
            ],
          ],
          theme: 'plain',
          // styles: {
          //   fillColor: '#000000',
          // },
        });
        autoTable(doc, {
          head: [['#', 'Product', 'Quantity', 'Amt (incl tax)']],
          body: tableData2,
          theme: 'striped',
          headStyles: {
            fillColor: '#000000',
          },
          styles: {
            font: 'Inter',
            fontStyle: 'normal',
            fontSize: 9,
          },
        });

        autoTable(doc, {
          head: [['', '', '', '']],
          body: tableData3,
          theme: 'striped',
          headStyles: {
            fillColor: '',
          },
          styles: {
            font: 'Inter',
            fontStyle: 'normal',
            fontSize: 9,
          },
          styles: {
            fontStyle: 'bold',
            halign: 'right',
          },
        });
        autoTable(doc, {
          body: [
            [
              {
                content: `Billed Amount: ${total}`,
                styles: {
                  halign: 'right',
                  font: 'Inter',
                  fontStyle: 'normal',
                  fontSize: 11,
                },
              },
            ],
            [
              {
                content: `Total discount: ${discount}`,
                styles: {
                  halign: 'right',
                  font: 'Inter',
                  fontStyle: 'normal',
                  fontSize: 11,
                },
              },
            ],
          ],
          theme: 'plain',
        });
        autoTable(doc, {
          body: [
            [
              {
                content: ``,
                styles: {
                  halign: 'right',
                  fontSize: 0.1,
                },
              },
            ],
          ],
          theme: 'plain',
          styles: {
            fillColor: '#000000',
          },
        });
        autoTable(doc, {
          body: [
            [
              {
                content: `Authorized Signatory:`,
                styles: {
                  halign: 'right',
                  fontSize: 12,
                },
              },
            ],
          ],
          theme: 'plain',
        });
        autoTable(doc, {
          body: [
            [
              {
                content: ``,
                styles: {
                  halign: 'right',
                  fontSize: 0.1,
                },
              },
            ],
          ],
          theme: 'plain',
          // styles: {
          //   fillColor: '#000000',
          // },
        });

        autoTable(doc, {
          body: [
            [
              {
                content: `${additionalData.notes !== '' ? additionalData.notes : ''}`,
                styles: {
                  halign: 'left',
                  fontSize: 9,
                  cellWidth: textWidth / 2,
                },
              },
              {
                content: `${additionalData.upi_id !== '' ? 'Scan the below QR code to pay via UPI' : ''}`,
                styles: {
                  halign: 'right',
                  fontSize: 12,
                },
              },
            ],
          ],
          theme: 'plain',
          styles: {
            overflow: 'linebreak',
          },
        });

        if (additionalData.upi_id !== '') {
          doc.addImage(
            upiQr,
            'PNG',
            doc.internal.pageSize.getWidth() - 45,
            doc.autoTable.previous.finalY + 5,
            20,
            20,
          );
        }


        if (
          doc.save(
            `Invoice - ${slipData[0].credit_customer.name} - ${dayjs(
              start_date,
            ).format('DD-MM-YYYY')} - ${dayjs(end_date).format('DD-MM-YYYY')}`,
          )
        ) {
          resolve(true);
        } else {
          reject('Error in generating invoice');
        }
      } catch (error) {
        reject('There is no data to generate invoice');
      }
    };
    img.src = result;
  });
}

export async function generateLiveMemo(data, resolve, reject) {
  const bunkLogo = getBase64FromUrl(authService.currentOMCWebLogo);

  // read promise value
  bunkLogo.then((result) => {
    // calcualte image width and height based on aspect ratio
    const img = new Image();
    img.onload = function () {
      const doc = new jsPDF({
        orientation: 'landscape'
      });
      const pageWidth = doc.internal.pageSize.getWidth();
      doc.addFileToVFS('Inter-Regular.ttf', InterFont);
      doc.addFont('Inter-Regular.ttf', 'Inter', 'normal');
      doc.setFont('Inter');
      const bunkAddress = authService.currentBunkAddress;
      const tableData = [];
      const total = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
      }).format(data.after_tax || 0);

      data.product_list.map((item, i) => {
        tableData.push([
          item.product.name,
          item.price,
          item.quantity.toFixed(2),
          Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
          }).format(item.amount.toFixed(2)),
          Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
          }).format(item.after_tax.toFixed(2)),
        ]);
      });
      const aspectRatio = img.width / img.height;
      const width = 15;
      const height = width / aspectRatio;
      const tableWidth = pageWidth - 33;
      try {
        doc.addImage(result, 'PNG', 13, 10, width, height);
        autoTable(doc, {
          body: [
            [
              {
                content: authService.currentBunkName,
                styles: {
                  halign: 'left',
                  fontSize: 12,
                  textColor: '#000000',
                },
              },
            ],
            [
              {
                content: `GST: ${authService.currentUserGst}`,
                styles: {
                  halign: 'left',
                  fontSize: 8,
                  textColor: '#000000',
                },
              },
            ],
            [
              {
                content:
                  bunkAddress.city +
                  ', ' +
                  bunkAddress.location +
                  ',' +
                  bunkAddress.country.country +
                  ' - ' +
                  bunkAddress.pincode,
                styles: {
                  halign: 'left',
                  fontSize: 8,
                  textColor: '#000000',
                },
              },
            ],
            [
              {
                content: `Date: ${dayjs(data.time_stamp).format('DD-MM-YYYY')} `,
                styles: {
                  halign: 'left',
                  fontSize: 8,
                  textColor: '#000000',
                },
              },
            ],
            [
              {
                content: `Invoice no: ${data.bill_number} `,
                styles: {
                  halign: 'left',
                  fontSize: 8,
                  textColor: '#000000',
                },
              },
            ],
          ],
          theme: 'plain',
          startY: 28,
        });
        autoTable(doc, {
          body: [
            [
              {
                content: `Customer name: ${data.customer_name}`,
                styles: {
                  halign: 'right',
                  fontSize: 10,
                  textColor: '#000000',
                },
              },
            ],
            [
              {
                content: `GST: ${data.gst || 'N/A'}`,
                styles: {
                  halign: 'right',
                  fontSize: 8,
                  textColor: '#000000',
                },
              },

            ],
            [
              {
                content: `Vehicle no: ${data.vehicle}`,
                styles: {
                  halign: 'right',
                  fontSize: 8,
                  textColor: '#000000',
                },
              },
            ],
            [
              {
                content: `Mobile: ${data.mobile}`,
                styles: {
                  halign: 'right',
                  fontSize: 8,
                  textColor: '#000000',
                },
              },
            ],
            [
              {
                content: `Email: ${data.email}`,
                styles: {
                  halign: 'right',
                  fontSize: 8,
                  textColor: '#000000',
                },
              },
            ],
          ],
          theme: 'plain',
          startY: 28,
          margin: { right: pageWidth / 2 + 8.5 },
        });
        autoTable(doc, {
          body: [
            [
              {
                content: `Address: ${data.address}`,
                styles: {
                  halign: 'right',
                  fontSize: 8,
                  textColor: '#000000',
                  cellWidth: 'wrap',
                  overFlow: 'linebreak',
                },
              },
            ],
          ],
          theme: 'plain',
          margin: { right: pageWidth / 2 + 8 },
          startY: doc.autoTable.previous.finalY - 1,
        });
        doc.setLineWidth(0.3);
        doc.setDrawColor(0, 0, 0);
        doc.line(
          13,
          doc.autoTable.previous.finalY + 1,
          pageWidth - 13,
          doc.autoTable.previous.finalY + 1,
        );

        autoTable(doc, {
          head: [['Product', 'Rate', 'Quantity', 'Amount', 'Amount(incl tax)']],
          body: tableData,
          theme: 'striped',
          headStyles: {
            fillColor: '#000000',
          },
          styles: {
            font: 'Inter',
            fontStyle: 'normal',
            fontSize: 8,
          },
          columnStyles: {
            // Adjudsting column widths
            0: { columnWidth: tableWidth * 0.1 },
            1: { columnWidth: tableWidth * 0.1 },
            2: { columnWidth: tableWidth * 0.1 },
            3: { columnWidth: tableWidth * 0.1 },
            4: { columnWidth: tableWidth * 0.1 },
          },
          margin: { left: 10 },
        });
        doc.setLineWidth(0.3);
        doc.setDrawColor(0, 0, 0);
        doc.line(
          13,
          doc.autoTable.previous.finalY + 5,
          pageWidth - 13,
          doc.autoTable.previous.finalY + 5,
        );
        autoTable(doc, {
          body: [
            [
              {
                content: `Payment mode: ${data.source}`,
                styles: {
                  halign: 'center',
                  fontSize: 7,
                },
              },
            ],
            [
              {
                content: `Total amount: ${total}`,
                styles: {
                  halign: 'center',
                  font: 'Inter',
                  fontStyle: 'normal',
                  fontSize: 7,
                },
              },
            ],
          ],
          theme: 'plain',
          margin: { left: -20 },
        });
        doc.setLineWidth(0.3);
        doc.setDrawColor(0, 0, 0);
        doc.line(
          13,
          doc.autoTable.previous.finalY + 1,
          pageWidth - 13,
          doc.autoTable.previous.finalY + 1,
        );
        autoTable(doc, {
          body: [
            [
              {
                content: `Notes - ${data.notes}`,
                styles: {
                  halign: 'left',
                  fontSize: 7,
                  textColor: '#000000',
                  cellWidth: 'wrap',
                },
              },
            ],
          ],
          theme: 'plain',
          margin: { left: 13, right: pageWidth / 2 + 10 },
          startY: doc.autoTable.previous.finalY + 10,
        });

        // Save current Y position for bunk copy
        const bunkCopyY = doc.autoTable.previous.finalY + 10;

        // Draw a vertical line to separate customer and bunk copies
        doc.setLineWidth(0.3);
        doc.setDrawColor(0, 0, 0);
        doc.line(pageWidth / 2, 10, pageWidth / 2, bunkCopyY);

        // Draw Bunk copy
        doc.addImage(
          result,
          'PNG',
          pageWidth / 2 + 10,
          10,
          width,
          height,
        );
        autoTable(doc, {
          body: [
            [
              {
                content: `Customer name: ${data.customer_name}`,
                styles: {
                  halign: 'right',
                  fontSize: 10,
                  textColor: '#000000',
                },
              },
            ],
            [
              {
                content: `GST: ${data.gst || 'N/A'}`,
                styles: {
                  halign: 'right',
                  fontSize: 8,
                  textColor: '#000000',
                },
              },
            ],
            [
              {
                content: `Vehicle no: ${data.vehicle}`,
                styles: {
                  halign: 'right',
                  fontSize: 8,
                  textColor: '#000000',
                },
              },
            ],
            [
              {
                content: `Mobile: ${data.mobile}`,
                styles: {
                  halign: 'right',
                  fontSize: 8,
                  textColor: '#000000',
                },
              },
            ],
            [
              {
                content: `Email: ${data.email}`,
                styles: {
                  halign: 'right',
                  fontSize: 8,
                  textColor: '#000000',
                },
              },
            ],
          ],
          theme: 'plain',
          startY: 28,
          margin: { left: pageWidth / 2 + 8.5 },
        });
        autoTable(doc, {
          body: [
            [
              {
                content: `Address: ${data.address}`,
                styles: {
                  halign: 'right',
                  fontSize: 8,
                  textColor: '#000000',
                  cellWidth: 'wrap',
                  overFlow: 'linebreak',
                },
              },
            ],
          ],
          theme: 'plain',
          margin: { left: pageWidth / 2 + 8 },
          startY: doc.autoTable.previous.finalY - 1,
        });
        autoTable(doc, {
          startY: bunkCopyY,
          body: [
            [
              {
                content: authService.currentBunkName,
                styles: {
                  fontSize: 12,
                  textColor: '#000000',
                },
              },
            ],
            [
              {
                content: `GST: ${authService.currentUserGst}`,
                styles: {
                  fontSize: 8,
                  textColor: '#000000',
                },
              },
            ],
            [
              {
                content:
                  bunkAddress.city +
                  ', ' +
                  bunkAddress.location +
                  ',' +
                  bunkAddress.country.country +
                  ' - ' +
                  bunkAddress.pincode,
                styles: {
                  fontSize: 8,
                  textColor: '#000000',
                },
              },
            ],
            [
              {
                content: `Date: ${dayjs(data.time_stamp).format('DD-MM-YYYY')} `,
                styles: {
                  fontSize: 8,
                  textColor: '#000000',
                },
              },
            ],
            [
              {
                content: `Invoice no: ${data.bill_number} `,
                styles: {
                  fontSize: 8,
                  textColor: '#000000',
                },
              },
            ],
          ],
          theme: 'plain',
          margin: { left: 160 },
          // startY: 22,
          startY: 28,
        });

        autoTable(doc, {
          startY: bunkCopyY + 7,
          head: [['Product', 'Rate', 'Quantity', 'Amount', 'Amount(incl tax)']],
          body: tableData,
          theme: 'striped',
          headStyles: {
            fillColor: '#000000',
          },
          styles: {
            font: 'Inter',
            fontStyle: 'normal',
            fontSize: 8,
          },
          columnStyles: {
            // Adjusting column widths
            0: { columnWidth: tableWidth * 0.1 },
            1: { columnWidth: tableWidth * 0.1 },
            2: { columnWidth: tableWidth * 0.1 },
            3: { columnWidth: tableWidth * 0.1 },
            4: { columnWidth: tableWidth * 0.1 },
          },
          margin: { left: 155 },
          startY: 75,
        });
        autoTable(doc, {
          body: [
            [
              {
                content: `Payment mode: ${data.source}`,
                styles: {
                  halign: 'right',
                  fontSize: 7,
                },
              },
            ],
            [
              {
                content: `Total amount: ${total}`,
                styles: {
                  halign: 'right',
                  font: 'Inter',
                  fontStyle: 'normal',
                  fontSize: 7,
                },
              },
            ],
          ],
          theme: 'plain',
        });
        autoTable(doc, {
          body: [
            [
              {
                content: `Notes - ${data.notes}`,
                styles: {
                  fontSize: 7,
                  textColor: '#000000',
                  cellWidth: 'wrap',
                },
              },
            ],
          ],
          theme: 'plain',
          margin: { left: pageWidth / 2 + 10 },
          startY: doc.autoTable.previous.finalY + 10,
        });


        if (
          doc.save(
            `Invoice - ${data.customer_name} - ${dayjs(data.time_stamp).format('DD-MM-YYYY')}`,
          )
        ) {
          resolve(true);
        } else {
          reject('Error generating memo');
        }
      } catch (error) {
        reject(error);
      }
    };
    img.src = result;
  });
}