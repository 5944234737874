import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  FuelPurchase,
  LubePurchases,
  VendorPayments,
  Vendors,
} from '@C/Purchases/RouteComponents';

const RouteSetup = () => {
  return (
    <Switch>
      <Route path="/account/purchases/vendors" component={Vendors} />
      <Route path="/account/purchases/fuel" component={FuelPurchase} />
      <Route path="/account/purchases/lubes" component={LubePurchases} />
      <Route
        path="/account/purchases/vendor-payments"
        component={VendorPayments}
      />
      <Route path="/account/purchases/*">
        <Redirect to={`/account/purchases/vendors`} />
      </Route>
    </Switch>
  );
};

export { RouteSetup };
