import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import services from '@C/CashiersOnDuty/_utils/data';
import { authHeader } from '@H';

class PastDuty extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      duties: [],
      lock: false,
    };
  }

  componentDidMount() {
    this.getDuties();
  }

  getDuties = () => {
    services
      .getPastDutyList(this.props.date)
      .then((response) => {
        this.setState({
          duties: response.data.data.duties_list,
          lock: response.data.data.dsr_lock,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  endDuty = (id, staff, name, date) => {
    this.props.onEndDuty(id, staff, name, date, true);
  };

  confirmCancel = (id, duty) => {
    confirmAlert({
      title: 'Confirm duty cancellation',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.cancelDuty(id, duty),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
    // this.getDuties();
    // this.getStaffList();
  };

  cancelDuty = (id, duty) => {
    const formData = {
      staff_id: id,
      cashier_duty_id: duty,
    };
    // this.getDuties();

    const headers = authHeader(1);
    axios
      .put(`${process.env.API_URL}/bunk/cancel-cashier-duty/`, formData, {
        headers,
      })
      .then(() => {
        toast.success('Duty cancelled successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        window.location.reload();
      })
      .catch(() => {
        toast.error(response.data.message, {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    // this.getDuties();
    //   this.getStaffList();
    //   this.getGunsList();
  };

  render() {
    const { duties } = this.state;

    return (
      <>
        <section className="widget full-width duty-list">
          <header>
            <h3>Past Duties</h3>
          </header>
          <main>
            <ul>
              {duties.map((item, i) => {
                return (
                  <li key={i}>
                    <div className="item-info">
                      <a href="#">{`${item.first_name} ${item.last_name}`}</a>
                      <span className="shift-status --on-duty">
                        {item.shift_name}
                      </span>
                      <span
                        title={`Duty date - ${dayjs(item.duty_date).format('YYYY-MM-DD')}`}
                      >
                        {new Date(item.duty_time_stamp).toDateString()}
                      </span>
                    </div>
                    <div className="cta-wrapper">
                      {!this.state.lock ? (
                        <>
                          <Link
                            to={`/cashiers-on-duty/end-duty/${
                              item.cashier_duty_id
                            }/${dayjs(item.duty_time_stamp).format('YYYY-MM-DD')}/${
                              item.staff_uuid
                            }/edit/fuel-sales`}
                            className="end-cta"
                          >
                            Edit Duty
                          </Link>
                          <button
                            className="cancel-cta"
                            onClick={() =>
                              this.confirmCancel(
                                item.staff_uuid,
                                item.cashier_duty_id,
                              )
                            }
                          >
                            Delete Duty
                          </button>
                        </>
                      ) : (
                        <span className="disabled-error">DSR Locked</span>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </main>
        </section>
      </>
    );
  }
}

export { PastDuty };
